import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ExportToCsv } from 'export-to-csv';
import { environment } from 'src/environments/environment';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { ExportService } from './export.service';
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {

  constructor(
    private firestore: AngularFirestore,
    private exportservice: ExportService
    ) { }
  ngOnInit() {
    
  }
  ref = this.firestore.collection(environment.firestorePath)

  // options = { 
  //   fieldSeparator: ',',
  //   quoteStrings: '"',
  //   decimalSeparator: '.',
  //   showLabels: true, 
  //   showTitle: true,
  //   title: 'My Awesome CSV',
  //   useTextFile: false,
  //   useBom: true,
  //   useKeysAsHeaders: true,
  // };
  // exportar() {
  //   this.firestore.collection<any>("afiliaciones").valueChanges().subscribe(
  //     value =>{
  //       const csvExporter = new ExportToCsv(this.options);
 
  //       csvExporter.generateCsv(value);
  //     }
  //   )
  // }
  dataForExcel = [];

  exportar(){
    this.firestore.collection<any>("afiliaciones").valueChanges().subscribe(
          value =>{
            value.forEach((row: any) => {
              this.dataForExcel.push(Object.values(row))
            })
        
            let reportData = {
              title: 'Afiliados PTP',
              data: this.dataForExcel,
              headers: Object.keys(value[0])
            }
        
            this.exportservice.exportExcel(reportData);
          }
        )
    
  }

  
}
