import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalidadesService {

  constructor() { }

  getLocalidades(){
    return {
      "localidades":[
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"ALBERTI",
            "id":"06021010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Alberti",
               "id":"06021010"
            },
            "centroide":{
               "lat":-35.0330734347841,
               "lon":-60.2806197287099
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"CORONEL SEGUI",
            "id":"06021020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Seguí",
               "id":"06021020"
            },
            "centroide":{
               "lat":-34.8681189984321,
               "lon":-60.3939708823404
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"MECHITA",
            "id":"06021030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mechita",
               "id":"06021030"
            },
            "centroide":{
               "lat":-35.068013673391,
               "lon":-60.4025971632697
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"PLA",
            "id":"06021040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pla",
               "id":"06021040"
            },
            "centroide":{
               "lat":-35.1243819752343,
               "lon":-60.2200612615259
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"VILLA GRISOLIA",
            "id":"06021050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Grisolía",
               "id":"06021050"
            },
            "centroide":{
               "lat":-35.1096178332808,
               "lon":-60.070551324606
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"VILLA MARIA",
            "id":"06021060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa María",
               "id":"06021060"
            },
            "centroide":{
               "lat":-34.8881255447686,
               "lon":-60.3469385614883
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alberti",
               "id":"060021"
            },
            "departamento":{
               "nombre":"Alberti",
               "id":"06021"
            },
            "nombre":"VILLA ORTIZ",
            "id":"06021070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Ortiz",
               "id":"06021070"
            },
            "centroide":{
               "lat":-34.8435329449862,
               "lon":-60.3048498633642
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"ADROGUE",
            "id":"06028010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8015580737331,
               "lon":-58.3914677191541
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"BURZACO",
            "id":"06028010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8315415891783,
               "lon":-58.3986874871405
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"CLAYPOLE",
            "id":"06028010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8044759080477,
               "lon":-58.3447825531042
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"DON ORIONE",
            "id":"06028010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8210751643113,
               "lon":-58.3447457989957
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"GLEW",
            "id":"06028010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8860114180413,
               "lon":-58.3818301842512
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"JOSE MARMOL",
            "id":"06028010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.7851442510713,
               "lon":-58.3681475603787
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"LONGCHAMPS",
            "id":"06028010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8609450255718,
               "lon":-58.3891476922426
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"MALVINAS ARGENTINAS",
            "id":"06028010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8189583169642,
               "lon":-58.4235610606239
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"MINISTRO RIVADAVIA",
            "id":"06028010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.8556895005541,
               "lon":-58.361646542791
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"RAFAEL CALZADA",
            "id":"06028010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.7911367513318,
               "lon":-58.350598184222
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"SAN FRANCISCO SOLANO",
            "id":"06028010011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.7866743578473,
               "lon":-58.3219363521574
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almirante Brown",
               "id":"060028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"06028"
            },
            "nombre":"SAN JOSE",
            "id":"06028010012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Almirante Brown",
               "id":"06028010"
            },
            "centroide":{
               "lat":-34.7673771924196,
               "lon":-58.3432771404358
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"AREA CINTURON ECOLOGICO",
            "id":"06035010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6750056478488,
               "lon":-58.3105634248142
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"AVELLANEDA",
            "id":"06035010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6626508059016,
               "lon":-58.3656990232718
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"CRUCESITA",
            "id":"06035010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.66910587351,
               "lon":-58.3574544412345
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"DOCK SUD",
            "id":"06035010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6491266928534,
               "lon":-58.3411774769136
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"GERLI",
            "id":"06035010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6847637540553,
               "lon":-58.3726025503412
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"PIÑEYRO",
            "id":"06035010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6685497926645,
               "lon":-58.3886029123689
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"SARANDI",
            "id":"06035010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6843716640855,
               "lon":-58.348101313379
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"VILLA DOMINICO",
            "id":"06035010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.6986315148924,
               "lon":-58.3363602646664
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"060035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"06035"
            },
            "nombre":"WILDE",
            "id":"06035010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"06035010"
            },
            "centroide":{
               "lat":-34.7061588356333,
               "lon":-58.3218349208501
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ayacucho",
               "id":"060042"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"06042"
            },
            "nombre":"AYACUCHO",
            "id":"06042010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ayacucho",
               "id":"06042010"
            },
            "centroide":{
               "lat":-37.1536695670417,
               "lon":-58.4895476662462
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ayacucho",
               "id":"060042"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"06042"
            },
            "nombre":"LA CONSTANCIA",
            "id":"06042020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Constancia",
               "id":"06042020"
            },
            "centroide":{
               "lat":-37.2286256057071,
               "lon":-58.7603890048806
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ayacucho",
               "id":"060042"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"06042"
            },
            "nombre":"SOLANET",
            "id":"06042030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Solanet",
               "id":"06042030"
            },
            "centroide":{
               "lat":-36.8444984439739,
               "lon":-58.5071421689017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ayacucho",
               "id":"060042"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"06042"
            },
            "nombre":"UDAQUIOLA",
            "id":"06042040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Udaquiola",
               "id":"06042040"
            },
            "centroide":{
               "lat":-36.5637513540211,
               "lon":-58.5333344196536
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azul",
               "id":"060049"
            },
            "departamento":{
               "nombre":"Azul",
               "id":"06049"
            },
            "nombre":"ARIEL",
            "id":"06049010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ariel",
               "id":"06049010"
            },
            "centroide":{
               "lat":-36.5312544192466,
               "lon":-59.9200651844348
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azul",
               "id":"060049"
            },
            "departamento":{
               "nombre":"Azul",
               "id":"06049"
            },
            "nombre":"AZUL",
            "id":"06049020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Azul",
               "id":"06049020"
            },
            "centroide":{
               "lat":-36.7795144970313,
               "lon":-59.8586331707414
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azul",
               "id":"060049"
            },
            "departamento":{
               "nombre":"Azul",
               "id":"06049"
            },
            "nombre":"CACHARI",
            "id":"06049030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cacharí",
               "id":"06049030"
            },
            "centroide":{
               "lat":-36.3805626332947,
               "lon":-59.5030645507265
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azul",
               "id":"060049"
            },
            "departamento":{
               "nombre":"Azul",
               "id":"06049"
            },
            "nombre":"CHILLAR",
            "id":"06049040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chillar",
               "id":"06049040"
            },
            "centroide":{
               "lat":-37.3150113247344,
               "lon":-59.9853181679139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azul",
               "id":"060049"
            },
            "departamento":{
               "nombre":"Azul",
               "id":"06049"
            },
            "nombre":"16 DE JULIO",
            "id":"06049050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"16 de Julio",
               "id":"06049050"
            },
            "centroide":{
               "lat":-37.2020524773603,
               "lon":-60.1652139181813
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"BAHIA BLANCA",
            "id":"06056010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bahía Blanca",
               "id":"06056010"
            },
            "centroide":{
               "lat":-38.7114037284555,
               "lon":-62.2569089501864
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"GRÜNBEIN",
            "id":"06056010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bahía Blanca",
               "id":"06056010"
            },
            "centroide":{
               "lat":-38.7471079502848,
               "lon":-62.1891166907456
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"INGENIERO WHITE",
            "id":"06056010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bahía Blanca",
               "id":"06056010"
            },
            "centroide":{
               "lat":-38.7794670416666,
               "lon":-62.262951248824
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"VILLA BORDEAU",
            "id":"06056010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bahía Blanca",
               "id":"06056010"
            },
            "centroide":{
               "lat":-38.6472605094596,
               "lon":-62.3200223419024
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"VILLA ESPORA",
            "id":"06056010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bahía Blanca",
               "id":"06056010"
            },
            "centroide":{
               "lat":-38.7765069529222,
               "lon":-62.1851833537179
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"CABILDO",
            "id":"06056020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cabildo",
               "id":"06056020"
            },
            "centroide":{
               "lat":-38.4838572081944,
               "lon":-61.8926015349487
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bahía Blanca",
               "id":"060056"
            },
            "departamento":{
               "nombre":"Bahía Blanca",
               "id":"06056"
            },
            "nombre":"GENERAL DANIEL CERRI",
            "id":"06056030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Daniel Cerri",
               "id":"06056030"
            },
            "centroide":{
               "lat":-38.7136524854986,
               "lon":-62.3924221653991
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balcarce",
               "id":"060063"
            },
            "departamento":{
               "nombre":"Balcarce",
               "id":"06063"
            },
            "nombre":"BALCARCE",
            "id":"06063010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Balcarce",
               "id":"06063010"
            },
            "centroide":{
               "lat":-37.8482779294345,
               "lon":-58.2551665841248
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balcarce",
               "id":"060063"
            },
            "departamento":{
               "nombre":"Balcarce",
               "id":"06063"
            },
            "nombre":"LOS PINOS",
            "id":"06063020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Pinos",
               "id":"06063020"
            },
            "centroide":{
               "lat":-37.9412057603,
               "lon":-58.3225920150442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balcarce",
               "id":"060063"
            },
            "departamento":{
               "nombre":"Balcarce",
               "id":"06063"
            },
            "nombre":"NAPALEOFU",
            "id":"06063030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Napaleofú",
               "id":"06063030"
            },
            "centroide":{
               "lat":-37.6254980210026,
               "lon":-58.7461862359423
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balcarce",
               "id":"060063"
            },
            "departamento":{
               "nombre":"Balcarce",
               "id":"06063"
            },
            "nombre":"RAMOS OTERO",
            "id":"06063040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ramos Otero",
               "id":"06063040"
            },
            "centroide":{
               "lat":-37.5426353712017,
               "lon":-58.3407185507274
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balcarce",
               "id":"060063"
            },
            "departamento":{
               "nombre":"Balcarce",
               "id":"06063"
            },
            "nombre":"SAN AGUSTIN",
            "id":"06063050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Agustín",
               "id":"06063050"
            },
            "centroide":{
               "lat":-38.0122838564587,
               "lon":-58.355377761682
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balcarce",
               "id":"060063"
            },
            "departamento":{
               "nombre":"Balcarce",
               "id":"06063"
            },
            "nombre":"VILLA LAGUNA LA BRAVA",
            "id":"06063060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Laguna La Brava",
               "id":"06063060"
            },
            "centroide":{
               "lat":-37.8596285282151,
               "lon":-57.9806008736401
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Baradero",
               "id":"060070"
            },
            "departamento":{
               "nombre":"Baradero",
               "id":"06070"
            },
            "nombre":"BARADERO",
            "id":"06070010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Baradero",
               "id":"06070010"
            },
            "centroide":{
               "lat":-33.8128781547156,
               "lon":-59.5043068852807
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Baradero",
               "id":"060070"
            },
            "departamento":{
               "nombre":"Baradero",
               "id":"06070"
            },
            "nombre":"IRINEO PORTELA",
            "id":"06070020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Irineo Portela",
               "id":"06070020"
            },
            "centroide":{
               "lat":-33.9810489198561,
               "lon":-59.6715601606315
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Baradero",
               "id":"060070"
            },
            "departamento":{
               "nombre":"Baradero",
               "id":"06070"
            },
            "nombre":"SANTA COLOMA",
            "id":"06070030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Coloma",
               "id":"06070030"
            },
            "centroide":{
               "lat":-34.062154134736,
               "lon":-59.5585420642846
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Baradero",
               "id":"060070"
            },
            "departamento":{
               "nombre":"Baradero",
               "id":"06070"
            },
            "nombre":"VILLA ALSINA",
            "id":"06070040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Alsina",
               "id":"06070040"
            },
            "centroide":{
               "lat":-33.9096038045591,
               "lon":-59.3882017132282
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrecifes",
               "id":"060077"
            },
            "departamento":{
               "nombre":"Arrecifes",
               "id":"06077"
            },
            "nombre":"ARRECIFES",
            "id":"06077010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arrecifes",
               "id":"06077010"
            },
            "centroide":{
               "lat":-34.064591121211,
               "lon":-60.1025564443537
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrecifes",
               "id":"060077"
            },
            "departamento":{
               "nombre":"Arrecifes",
               "id":"06077"
            },
            "nombre":"TODD",
            "id":"06077020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Todd",
               "id":"06077020"
            },
            "centroide":{
               "lat":-34.0328240475348,
               "lon":-60.1562270065746
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrecifes",
               "id":"060077"
            },
            "departamento":{
               "nombre":"Arrecifes",
               "id":"06077"
            },
            "nombre":"VI¥A",
            "id":"06077030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Viña",
               "id":"06077030"
            },
            "centroide":{
               "lat":-33.9922765156087,
               "lon":-60.2263211205477
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benito Juárez",
               "id":"060084"
            },
            "departamento":{
               "nombre":"Benito Juárez",
               "id":"06084"
            },
            "nombre":"BARKER",
            "id":"06084010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barker",
               "id":"06084010"
            },
            "centroide":{
               "lat":-37.6424111302365,
               "lon":-59.3889380312927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benito Juárez",
               "id":"060084"
            },
            "departamento":{
               "nombre":"Benito Juárez",
               "id":"06084"
            },
            "nombre":"BENITO JUAREZ",
            "id":"06084020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Benito Juárez",
               "id":"06084020"
            },
            "centroide":{
               "lat":-37.6766410210105,
               "lon":-59.8057677109444
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benito Juárez",
               "id":"060084"
            },
            "departamento":{
               "nombre":"Benito Juárez",
               "id":"06084"
            },
            "nombre":"LOPEZ",
            "id":"06084030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"López",
               "id":"06084030"
            },
            "centroide":{
               "lat":-37.5545120854221,
               "lon":-59.6278461536299
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benito Juárez",
               "id":"060084"
            },
            "departamento":{
               "nombre":"Benito Juárez",
               "id":"06084"
            },
            "nombre":"TEDIN URIBURU",
            "id":"06084040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tedín Uriburu",
               "id":"06084040"
            },
            "centroide":{
               "lat":-37.3683686253208,
               "lon":-59.7630391604187
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benito Juárez",
               "id":"060084"
            },
            "departamento":{
               "nombre":"Benito Juárez",
               "id":"06084"
            },
            "nombre":"VILLA CACIQUE",
            "id":"06084050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Cacique",
               "id":"06084050"
            },
            "centroide":{
               "lat":-37.6704778893557,
               "lon":-59.4003533386514
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"BERAZATEGUI",
            "id":"06091010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.7597493264937,
               "lon":-58.2019645234278
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"BERAZATEGUI OESTE",
            "id":"06091010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.7804201858899,
               "lon":-58.2415010261207
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"CARLOS TOMAS SOURIGUES",
            "id":"06091010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.8021192214631,
               "lon":-58.2188212494897
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"EL PATO",
            "id":"06091010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.9067557072114,
               "lon":-58.1966995021239
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"GUILLERMO ENRIQUE HUDSON",
            "id":"06091010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.7897594857633,
               "lon":-58.1439692638183
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"JUAN MARIA GUTIERREZ",
            "id":"06091010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.8348420760566,
               "lon":-58.1819061125702
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"PEREYRA",
            "id":"06091010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.8471945391094,
               "lon":-58.1392765205344
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"PLATANOS",
            "id":"06091010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.7674201820877,
               "lon":-58.1652686003148
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"RANELAGH",
            "id":"06091010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.7941468501172,
               "lon":-58.1989815634425
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berazategui",
               "id":"060091"
            },
            "departamento":{
               "nombre":"Berazategui",
               "id":"06091"
            },
            "nombre":"VILLA ESPAÑA",
            "id":"06091010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berazategui",
               "id":"06091010"
            },
            "centroide":{
               "lat":-34.776063416904,
               "lon":-58.2002532983623
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"BARRIO BANCO PROVINCIA",
            "id":"06098010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.8752589900973,
               "lon":-57.8550856662876
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"BARRIO EL CARMEN (ESTE)",
            "id":"06098010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.9293102150655,
               "lon":-57.8853552505795
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"BARRIO UNIVERSITARIO",
            "id":"06098010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.9027528834433,
               "lon":-57.9270884333304
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"BERISSO",
            "id":"06098010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.8746353679645,
               "lon":-57.8947472316799
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"LOS TALAS",
            "id":"06098010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.9190209219847,
               "lon":-57.7909954962894
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA ARGÜELLO",
            "id":"06098010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.9053981881099,
               "lon":-57.9180815078165
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA DOLORES",
            "id":"06098010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.8802085383888,
               "lon":-57.8663036732616
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA INDEPENDENCIA",
            "id":"06098010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.8829519743895,
               "lon":-57.8614892695719
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA NUEVA",
            "id":"06098010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.8957079362353,
               "lon":-57.9108816644737
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA PORTEÑA",
            "id":"06098010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.891043310448,
               "lon":-57.8963773978494
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA PROGRESO",
            "id":"06098010011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.9308766123633,
               "lon":-57.8468799885622
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA SAN CARLOS",
            "id":"06098010012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.8838130805,
               "lon":-57.87701595642
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berisso",
               "id":"060098"
            },
            "departamento":{
               "nombre":"Berisso",
               "id":"06098"
            },
            "nombre":"VILLA ZULA",
            "id":"06098010013",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berisso",
               "id":"06098010"
            },
            "centroide":{
               "lat":-34.9221646298322,
               "lon":-57.8009664840662
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"HALE",
            "id":"06105010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hale",
               "id":"06105010"
            },
            "centroide":{
               "lat":-36.0010429767568,
               "lon":-60.8534495072374
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"JUAN F. IBARRA",
            "id":"06105020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan F. Ibarra",
               "id":"06105020"
            },
            "centroide":{
               "lat":-36.3498770527922,
               "lon":-61.2552591224518
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"PAULA",
            "id":"06105040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Paula",
               "id":"06105040"
            },
            "centroide":{
               "lat":-36.5053183545527,
               "lon":-61.0243287245345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"PIROVANO",
            "id":"06105050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pirovano",
               "id":"06105050"
            },
            "centroide":{
               "lat":-36.5109170215402,
               "lon":-61.5545516558692
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"SAN CARLOS DE BOLIVAR",
            "id":"06105060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Carlos de Bolívar",
               "id":"06105060"
            },
            "centroide":{
               "lat":-36.2295602208798,
               "lon":-61.1131898679983
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"URDAMPILLETA",
            "id":"06105070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Urdampilleta",
               "id":"06105070"
            },
            "centroide":{
               "lat":-36.4329292363695,
               "lon":-61.4191160192421
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bolívar",
               "id":"060105"
            },
            "departamento":{
               "nombre":"Bolívar",
               "id":"06105"
            },
            "nombre":"VILLA LYNCH PUEYRREDON",
            "id":"06105080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Lynch Pueyrredón",
               "id":"06105080"
            },
            "centroide":{
               "lat":-36.6025793254646,
               "lon":-61.3618625494725
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"ASAMBLEA",
            "id":"06112005000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Asamblea",
               "id":"06112005"
            },
            "centroide":{
               "lat":-35.2267581272748,
               "lon":-60.4168952837295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"BRAGADO",
            "id":"06112010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bragado",
               "id":"06112010"
            },
            "centroide":{
               "lat":-35.118942299763,
               "lon":-60.4879147568209
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"COMODORO PY",
            "id":"06112020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Comodoro Py",
               "id":"06112020"
            },
            "centroide":{
               "lat":-35.3233100374631,
               "lon":-60.5217314911689
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"GENERAL O'BRIEN",
            "id":"06112030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General O'Brien",
               "id":"06112030"
            },
            "centroide":{
               "lat":-34.9067979091956,
               "lon":-60.7597838238054
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"IRALA",
            "id":"06112040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Irala",
               "id":"06112040"
            },
            "centroide":{
               "lat":-34.7718230311122,
               "lon":-60.6916912010278
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"LA LIMPIA",
            "id":"06112050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Limpia",
               "id":"06112050"
            },
            "centroide":{
               "lat":-35.0797272650156,
               "lon":-60.5928546665185
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"MAXIMO FERNANDEZ",
            "id":"06112060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan F. Salaberry",
               "id":"06112060"
            },
            "centroide":{
               "lat":-35.0615246745825,
               "lon":-60.7060741424473
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"MECHITA",
            "id":"06112070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mechita",
               "id":"06112070"
            },
            "centroide":{
               "lat":-35.0699378566803,
               "lon":-60.4084937925362
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"OLASCOAGA",
            "id":"06112080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Olascoaga",
               "id":"06112080"
            },
            "centroide":{
               "lat":-35.2375277927366,
               "lon":-60.6115341696178
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bragado",
               "id":"060112"
            },
            "departamento":{
               "nombre":"Bragado",
               "id":"06112"
            },
            "nombre":"WARNES",
            "id":"06112090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Warnes",
               "id":"06112090"
            },
            "centroide":{
               "lat":-34.9098734445057,
               "lon":-60.5381681629003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"ALTAMIRANO",
            "id":"06119010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Altamirano",
               "id":"06119010"
            },
            "centroide":{
               "lat":-35.3615828022139,
               "lon":-58.1504868159157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"BARRIO EL MIRADOR",
            "id":"06119015000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio El Mirador",
               "id":"06119015"
            },
            "centroide":{
               "lat":-35.3145370547125,
               "lon":-58.0484420796345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"BARRIO LAS GOLONDRINAS",
            "id":"06119020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Las Golondrinas",
               "id":"06119020"
            },
            "centroide":{
               "lat":-35.0335777783556,
               "lon":-58.1935382681817
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"BARRIO LOS BOSQUECITOS",
            "id":"06119030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Los Bosquecitos",
               "id":"06119030"
            },
            "centroide":{
               "lat":-35.1050389949725,
               "lon":-58.1835007859607
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"BARRIO PARQUE LAS ACACIAS",
            "id":"06119040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Parque Las Acacias",
               "id":"06119040"
            },
            "centroide":{
               "lat":-35.1030329461442,
               "lon":-58.2730405375653
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"CAMPOS DE ROCA",
            "id":"06119045000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Campos de Roca",
               "id":"06119045"
            },
            "centroide":{
               "lat":-35.1149772603157,
               "lon":-58.0953896541907
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"CORONEL BRANDSEN",
            "id":"06119050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Brandsen",
               "id":"06119050"
            },
            "centroide":{
               "lat":-35.1690983002919,
               "lon":-58.2373529446643
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"CLUB DE CAMPO LAS MALVINAS",
            "id":"06119055000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Club de Campo Las Malvinas",
               "id":"06119055"
            },
            "centroide":{
               "lat":-35.2139978115731,
               "lon":-58.2433030920635
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"GOMEZ",
            "id":"06119060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gómez",
               "id":"06119060"
            },
            "centroide":{
               "lat":-35.0693655332834,
               "lon":-58.1656899882306
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"JEPPENER",
            "id":"06119070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Jeppener",
               "id":"06119070"
            },
            "centroide":{
               "lat":-35.2802043920968,
               "lon":-58.1996649431989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"OLIDEN",
            "id":"06119080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Oliden",
               "id":"06119080"
            },
            "centroide":{
               "lat":-35.1842584334678,
               "lon":-57.9479623248001
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"POSADA DE LOS LAGOS",
            "id":"06119085000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Posada de los Lagos",
               "id":"06119085"
            },
            "centroide":{
               "lat":-35.1499383981993,
               "lon":-58.0504528667351
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brandsen",
               "id":"060119"
            },
            "departamento":{
               "nombre":"Brandsen",
               "id":"06119"
            },
            "nombre":"SAMBOROMBON",
            "id":"06119090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Samborombón",
               "id":"06119090"
            },
            "centroide":{
               "lat":-35.2206242719966,
               "lon":-58.2805297949826
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campana",
               "id":"060126"
            },
            "departamento":{
               "nombre":"Campana",
               "id":"06126"
            },
            "nombre":"ALTO LOS CARDALES",
            "id":"06126010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Cardales",
               "id":"06126010"
            },
            "centroide":{
               "lat":-34.3160243688815,
               "lon":-58.9784574815339
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campana",
               "id":"060126"
            },
            "departamento":{
               "nombre":"Campana",
               "id":"06126"
            },
            "nombre":"BARRIO LOS PIONEROS",
            "id":"06126020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Los Pioneros (Barrio Tavella)",
               "id":"06126020"
            },
            "centroide":{
               "lat":-34.2530406367633,
               "lon":-58.9584400368589
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campana",
               "id":"060126"
            },
            "departamento":{
               "nombre":"Campana",
               "id":"06126"
            },
            "nombre":"CAMPANA",
            "id":"06126030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Campana",
               "id":"06126030"
            },
            "centroide":{
               "lat":-34.1639618118269,
               "lon":-58.9588741035355
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campana",
               "id":"060126"
            },
            "departamento":{
               "nombre":"Campana",
               "id":"06126"
            },
            "nombre":"CHACRAS DEL RIO LUJAN",
            "id":"06126035000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chacras del Río Luján",
               "id":"06126035"
            },
            "centroide":{
               "lat":-34.2934153226239,
               "lon":-58.921199044246
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campana",
               "id":"060126"
            },
            "departamento":{
               "nombre":"Campana",
               "id":"06126"
            },
            "nombre":"LOMAS DEL RIO LUJAN",
            "id":"06126040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Río Luján",
               "id":"06126040"
            },
            "centroide":{
               "lat":-34.2816770641806,
               "lon":-58.8911906524052
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"ALEJANDRO PETION",
            "id":"06134010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Alejandro Petión",
               "id":"06134010"
            },
            "centroide":{
               "lat":-34.9793768508134,
               "lon":-58.6749430768989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"BARRIO EL TALADRO",
            "id":"06134020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio El Taladro",
               "id":"06134020"
            },
            "centroide":{
               "lat":-35.0742486164225,
               "lon":-58.8632420603475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"CA¥UELAS",
            "id":"06134030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cañuelas",
               "id":"06134030"
            },
            "centroide":{
               "lat":-35.0527140350539,
               "lon":-58.7583856965375
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"GOBERNADOR UDAONDO",
            "id":"06134040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gobernador Udaondo",
               "id":"06134040"
            },
            "centroide":{
               "lat":-35.3003271130454,
               "lon":-58.5943053657455
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"BARRIO BELGRANO",
            "id":"06134050001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Máximo Paz",
               "id":"06134050"
            },
            "centroide":{
               "lat":-34.9022941561353,
               "lon":-58.6479010170021
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"MAXIMO PAZ",
            "id":"06134050002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Máximo Paz",
               "id":"06134050"
            },
            "centroide":{
               "lat":-34.9364563092157,
               "lon":-58.6143957995956
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"SANTA ROSA",
            "id":"06134060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa",
               "id":"06134060"
            },
            "centroide":{
               "lat":-34.9611839371811,
               "lon":-58.7308918275978
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"URIBELARREA",
            "id":"06134070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Uribelarrea",
               "id":"06134070"
            },
            "centroide":{
               "lat":-35.1227364407356,
               "lon":-58.8904871463421
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañuelas",
               "id":"060134"
            },
            "departamento":{
               "nombre":"Cañuelas",
               "id":"06134"
            },
            "nombre":"VICENTE CASARES",
            "id":"06134080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente Casares",
               "id":"06134080"
            },
            "centroide":{
               "lat":-34.9653526702416,
               "lon":-58.6504979594296
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capitán Sarmiento",
               "id":"060140"
            },
            "departamento":{
               "nombre":"Capitán Sarmiento",
               "id":"06140"
            },
            "nombre":"CAPITAN SARMIENTO",
            "id":"06140010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Capitán Sarmiento",
               "id":"06140010"
            },
            "centroide":{
               "lat":-34.1723674255219,
               "lon":-59.789349139091
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capitán Sarmiento",
               "id":"060140"
            },
            "departamento":{
               "nombre":"Capitán Sarmiento",
               "id":"06140"
            },
            "nombre":"LA LUISA",
            "id":"06140020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Luisa",
               "id":"06140020"
            },
            "centroide":{
               "lat":-34.1285572110784,
               "lon":-59.9240058312679
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"BELLOCQ",
            "id":"06147010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bellocq",
               "id":"06147010"
            },
            "centroide":{
               "lat":-35.9189705554631,
               "lon":-61.5312630222769
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"CADRET",
            "id":"06147020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cadret",
               "id":"06147020"
            },
            "centroide":{
               "lat":-35.7727531397053,
               "lon":-61.3353879836968
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"CARLOS CASARES",
            "id":"06147030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carlos Casares",
               "id":"06147030"
            },
            "centroide":{
               "lat":-35.623543502046,
               "lon":-61.3653159121918
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"COLONIA MAURICIO",
            "id":"06147040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia Mauricio",
               "id":"06147040"
            },
            "centroide":{
               "lat":-35.5249595511909,
               "lon":-61.4378670824782
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"HORTENSIA",
            "id":"06147050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hortensia",
               "id":"06147050"
            },
            "centroide":{
               "lat":-35.9277092575349,
               "lon":-61.262290153739
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"LA SOFIA",
            "id":"06147060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Sofía",
               "id":"06147060"
            },
            "centroide":{
               "lat":-35.7003907357414,
               "lon":-61.1702865161871
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"MAURICIO HIRSCH",
            "id":"06147070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mauricio Hirsch",
               "id":"06147070"
            },
            "centroide":{
               "lat":-35.583017649873,
               "lon":-61.5244457086778
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"MOCTEZUMA",
            "id":"06147080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moctezuma",
               "id":"06147080"
            },
            "centroide":{
               "lat":-35.4774725641963,
               "lon":-61.4930734927677
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"ORDOQUI",
            "id":"06147090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ordoqui",
               "id":"06147090"
            },
            "centroide":{
               "lat":-35.8831262095535,
               "lon":-61.1594280439031
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"SANTO TOMAS",
            "id":"06147095000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santo Tomás",
               "id":"06147095"
            },
            "centroide":{
               "lat":-35.6740915009624,
               "lon":-61.5066664301675
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Casares",
               "id":"060147"
            },
            "departamento":{
               "nombre":"Carlos Casares",
               "id":"06147"
            },
            "nombre":"SMITH",
            "id":"06147100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Smith",
               "id":"06147100"
            },
            "centroide":{
               "lat":-35.4946290260766,
               "lon":-61.5937811081594
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Tejedor",
               "id":"060154"
            },
            "departamento":{
               "nombre":"Carlos Tejedor",
               "id":"06154"
            },
            "nombre":"CARLOS TEJEDOR",
            "id":"06154010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carlos Tejedor",
               "id":"06154010"
            },
            "centroide":{
               "lat":-35.3925733302196,
               "lon":-62.4193023144118
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Tejedor",
               "id":"060154"
            },
            "departamento":{
               "nombre":"Carlos Tejedor",
               "id":"06154"
            },
            "nombre":"COLONIA SERE",
            "id":"06154020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia Seré",
               "id":"06154020"
            },
            "centroide":{
               "lat":-35.4388157700903,
               "lon":-62.7252382146864
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Tejedor",
               "id":"060154"
            },
            "departamento":{
               "nombre":"Carlos Tejedor",
               "id":"06154"
            },
            "nombre":"CURARU",
            "id":"06154030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Curarú",
               "id":"06154030"
            },
            "centroide":{
               "lat":-35.6403503028113,
               "lon":-62.1924372294866
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Tejedor",
               "id":"060154"
            },
            "departamento":{
               "nombre":"Carlos Tejedor",
               "id":"06154"
            },
            "nombre":"TIMOTE",
            "id":"06154040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Timote",
               "id":"06154040"
            },
            "centroide":{
               "lat":-35.3477615165229,
               "lon":-62.2246532791856
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Tejedor",
               "id":"060154"
            },
            "departamento":{
               "nombre":"Carlos Tejedor",
               "id":"06154"
            },
            "nombre":"TRES ALGARROBOS",
            "id":"06154050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres Algarrobos",
               "id":"06154050"
            },
            "centroide":{
               "lat":-35.1979815144911,
               "lon":-62.7730814064669
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carmen de Areco",
               "id":"060161"
            },
            "departamento":{
               "nombre":"Carmen de Areco",
               "id":"06161"
            },
            "nombre":"CARMEN DE ARECO",
            "id":"06161010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carmen de Areco",
               "id":"06161010"
            },
            "centroide":{
               "lat":-34.3776987251735,
               "lon":-59.8229019801033
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carmen de Areco",
               "id":"060161"
            },
            "departamento":{
               "nombre":"Carmen de Areco",
               "id":"06161"
            },
            "nombre":"PUEBLO GOUIN",
            "id":"06161020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pueblo Gouin",
               "id":"06161020"
            },
            "centroide":{
               "lat":-34.4951191416005,
               "lon":-59.8029737383366
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carmen de Areco",
               "id":"060161"
            },
            "departamento":{
               "nombre":"Carmen de Areco",
               "id":"06161"
            },
            "nombre":"TRES SARGENTOS",
            "id":"06161030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres Sargentos",
               "id":"06161030"
            },
            "centroide":{
               "lat":-34.4664778301466,
               "lon":-60.0008697422009
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castelli",
               "id":"060168"
            },
            "departamento":{
               "nombre":"Castelli",
               "id":"06168"
            },
            "nombre":"CASTELLI",
            "id":"06168010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Castelli",
               "id":"06168010"
            },
            "centroide":{
               "lat":-36.091694119436,
               "lon":-57.8071801619355
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castelli",
               "id":"060168"
            },
            "departamento":{
               "nombre":"Castelli",
               "id":"06168"
            },
            "nombre":"CENTRO GUERRERO",
            "id":"06168020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Centro Guerrero",
               "id":"06168020"
            },
            "centroide":{
               "lat":-36.0570621940227,
               "lon":-57.8235422591576
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castelli",
               "id":"060168"
            },
            "departamento":{
               "nombre":"Castelli",
               "id":"06168"
            },
            "nombre":"CERRO DE LA GLORIA (CANAL 15)",
            "id":"06168030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cerro de la Gloria",
               "id":"06168030"
            },
            "centroide":{
               "lat":-35.9760530599951,
               "lon":-57.4487354447874
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colón",
               "id":"060175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"06175"
            },
            "nombre":"COLON",
            "id":"06175010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colón",
               "id":"06175010"
            },
            "centroide":{
               "lat":-33.8978633606345,
               "lon":-61.099560506382
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colón",
               "id":"060175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"06175"
            },
            "nombre":"EL ARBOLITO",
            "id":"06175020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Manuel Pomar",
               "id":"06175020"
            },
            "centroide":{
               "lat":-33.9147838019682,
               "lon":-60.9438081845998
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colón",
               "id":"060175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"06175"
            },
            "nombre":"PEARSON",
            "id":"06175030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pearson",
               "id":"06175030"
            },
            "centroide":{
               "lat":-33.6518963027432,
               "lon":-60.8922239880462
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colón",
               "id":"060175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"06175"
            },
            "nombre":"SARASA",
            "id":"06175040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sarasa",
               "id":"06175040"
            },
            "centroide":{
               "lat":-34.0523353092344,
               "lon":-61.2019040914739
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"BAJO HONDO",
            "id":"06182010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bajo Hondo",
               "id":"06182010"
            },
            "centroide":{
               "lat":-38.7648159927502,
               "lon":-61.9184051093309
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"BALNEARIO PEHUEN CO",
            "id":"06182020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Balneario Pehuen Co",
               "id":"06182020"
            },
            "centroide":{
               "lat":-38.9961963948629,
               "lon":-61.5471990087107
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"PAGO CHICO",
            "id":"06182025000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pago Chico",
               "id":"06182025"
            },
            "centroide":{
               "lat":-38.7839402799391,
               "lon":-62.1195194756714
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"PUNTA ALTA",
            "id":"06182030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Punta Alta",
               "id":"06182030"
            },
            "centroide":{
               "lat":-38.8813527346955,
               "lon":-62.0749536088688
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"PUNTA ALTA",
            "id":"06182030001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Punta Alta",
               "id":"06182030"
            },
            "centroide":{
               "lat":-38.8823547797498,
               "lon":-62.0626706347168
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"VILLA DEL MAR",
            "id":"06182030002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Punta Alta",
               "id":"06182030"
            },
            "centroide":{
               "lat":-38.8532242630709,
               "lon":-62.1164752039925
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"060182"
            },
            "departamento":{
               "nombre":"Coronel de Marina Leonardo Rosales",
               "id":"06182"
            },
            "nombre":"VILLA GENERAL ARIAS",
            "id":"06182050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa General Arias",
               "id":"06182050"
            },
            "centroide":{
               "lat":-38.8069001411051,
               "lon":-62.09498539538
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"APARICIO",
            "id":"06189010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Aparicio",
               "id":"06189010"
            },
            "centroide":{
               "lat":-38.6204949120776,
               "lon":-60.8809603426407
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"BALNEARIO MARISOL",
            "id":"06189020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Marisol",
               "id":"06189020"
            },
            "centroide":{
               "lat":-38.9224906889726,
               "lon":-60.5329774471942
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"CORONEL DORREGO",
            "id":"06189030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Dorrego",
               "id":"06189030"
            },
            "centroide":{
               "lat":-38.7166239148323,
               "lon":-61.2884948879698
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"EL PERDIDO",
            "id":"06189040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Perdido",
               "id":"06189040"
            },
            "centroide":{
               "lat":-38.6757752787604,
               "lon":-61.0884421797928
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"FARO",
            "id":"06189050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Faro",
               "id":"06189050"
            },
            "centroide":{
               "lat":-38.7966781095909,
               "lon":-61.0688772134763
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"IRENE",
            "id":"06189060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Irene",
               "id":"06189060"
            },
            "centroide":{
               "lat":-38.5543426342658,
               "lon":-60.6954938748393
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"ORIENTE",
            "id":"06189070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Oriente",
               "id":"06189070"
            },
            "centroide":{
               "lat":-38.7388491145692,
               "lon":-60.6092238150829
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"LA RUTA",
            "id":"06189075000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Paraje La Ruta",
               "id":"06189075"
            },
            "centroide":{
               "lat":-38.6534164106777,
               "lon":-60.8608557685895
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Dorrego",
               "id":"060189"
            },
            "departamento":{
               "nombre":"Coronel Dorrego",
               "id":"06189"
            },
            "nombre":"SAN ROMAN",
            "id":"06189080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Román",
               "id":"06189080"
            },
            "centroide":{
               "lat":-38.7415550885108,
               "lon":-61.5377200112301
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Pringles",
               "id":"060196"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"06196"
            },
            "nombre":"CORONEL PRINGLES",
            "id":"06196010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Pringles",
               "id":"06196010"
            },
            "centroide":{
               "lat":-37.9865210556901,
               "lon":-61.3540715661069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Pringles",
               "id":"060196"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"06196"
            },
            "nombre":"EL DIVISORIO",
            "id":"06196020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Divisorio",
               "id":"06196020"
            },
            "centroide":{
               "lat":-38.3235636030787,
               "lon":-61.4450578127822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Pringles",
               "id":"060196"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"06196"
            },
            "nombre":"EL PENSAMIENTO",
            "id":"06196030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Pensamiento",
               "id":"06196030"
            },
            "centroide":{
               "lat":-38.2154094275446,
               "lon":-61.3145847771451
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Pringles",
               "id":"060196"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"06196"
            },
            "nombre":"INDIO RICO",
            "id":"06196040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Indio Rico",
               "id":"06196040"
            },
            "centroide":{
               "lat":-38.3293770967784,
               "lon":-60.8866340266383
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Pringles",
               "id":"060196"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"06196"
            },
            "nombre":"LARTIGAU",
            "id":"06196050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lartigau",
               "id":"06196050"
            },
            "centroide":{
               "lat":-38.44591903663,
               "lon":-61.566096860456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"CASCADAS",
            "id":"06203010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cascada",
               "id":"06203010"
            },
            "centroide":{
               "lat":-37.2899615825436,
               "lon":-62.2971682457111
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"CORONEL SUAREZ",
            "id":"06203020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Suárez",
               "id":"06203020"
            },
            "centroide":{
               "lat":-37.4596224938744,
               "lon":-61.9317530113989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"CURA MALAL",
            "id":"06203030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Curamalal",
               "id":"06203030"
            },
            "centroide":{
               "lat":-37.4843018699441,
               "lon":-62.10361959864
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"D'ORBIGNY",
            "id":"06203040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"D'Orbigny",
               "id":"06203040"
            },
            "centroide":{
               "lat":-37.6772293087783,
               "lon":-61.7052608546557
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"HUANGUELEN",
            "id":"06203050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Huanguelén",
               "id":"06203050"
            },
            "centroide":{
               "lat":-37.0622288209544,
               "lon":-61.9297529087938
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"PASMAN",
            "id":"06203060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pasman",
               "id":"06203060"
            },
            "centroide":{
               "lat":-37.2234351404125,
               "lon":-62.1602259170472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"SAN JOSE",
            "id":"06203070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"06203070"
            },
            "centroide":{
               "lat":-37.5077480260109,
               "lon":-61.9211473085303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"SANTA MARIA",
            "id":"06203080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa María",
               "id":"06203080"
            },
            "centroide":{
               "lat":-37.5565856774998,
               "lon":-61.872634363442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"SANTA TRINIDAD",
            "id":"06203090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Trinidad",
               "id":"06203090"
            },
            "centroide":{
               "lat":-37.4891036510029,
               "lon":-61.9252761219778
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Suárez",
               "id":"060203"
            },
            "departamento":{
               "nombre":"Coronel Suárez",
               "id":"06203"
            },
            "nombre":"VILLA LA ARCADIA",
            "id":"06203100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa La Arcadia",
               "id":"06203100"
            },
            "centroide":{
               "lat":-38.1345025284944,
               "lon":-61.7885553500206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chacabuco",
               "id":"060210"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"06210"
            },
            "nombre":"CASTILLA",
            "id":"06210010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Castilla",
               "id":"06210010"
            },
            "centroide":{
               "lat":-34.6131247014799,
               "lon":-59.9005194580559
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chacabuco",
               "id":"060210"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"06210"
            },
            "nombre":"CHACABUCO",
            "id":"06210020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chacabuco",
               "id":"06210020"
            },
            "centroide":{
               "lat":-34.6429843999409,
               "lon":-60.4701843504346
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chacabuco",
               "id":"060210"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"06210"
            },
            "nombre":"LOS ANGELES",
            "id":"06210030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Angeles",
               "id":"06210030"
            },
            "centroide":{
               "lat":-34.4575064000002,
               "lon":-60.1801845132051
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chacabuco",
               "id":"060210"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"06210"
            },
            "nombre":"O'HIGGINS",
            "id":"06210040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"O'Higgins",
               "id":"06210040"
            },
            "centroide":{
               "lat":-34.5854707868254,
               "lon":-60.6986325840689
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chacabuco",
               "id":"060210"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"06210"
            },
            "nombre":"RAWSON",
            "id":"06210050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rawson",
               "id":"06210050"
            },
            "centroide":{
               "lat":-34.6086374337309,
               "lon":-60.0677106761007
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chascomús",
               "id":"060218"
            },
            "departamento":{
               "nombre":"Chascomús",
               "id":"06217"
            },
            "nombre":"BARRIO LOMAS ALTAS",
            "id":"06217003000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Lomas Altas",
               "id":"06217003"
            },
            "centroide":{
               "lat":-35.5739889229165,
               "lon":-58.0680436475186
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chascomús",
               "id":"060218"
            },
            "departamento":{
               "nombre":"Chascomús",
               "id":"06217"
            },
            "nombre":"CHASCOMUS",
            "id":"06217010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chascomús",
               "id":"06217010"
            },
            "centroide":{
               "lat":-35.5743009068199,
               "lon":-58.0005532563664
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chascomús",
               "id":"060218"
            },
            "departamento":{
               "nombre":"Chascomús",
               "id":"06217"
            },
            "nombre":"BARRIO SAN CAYETANO",
            "id":"06217010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chascomús",
               "id":"06217010"
            },
            "centroide":{
               "lat":-35.5466815668936,
               "lon":-58.0013258753826
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chascomús",
               "id":"060218"
            },
            "departamento":{
               "nombre":"Chascomús",
               "id":"06217"
            },
            "nombre":"LAGUNA VITEL",
            "id":"06217015000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Laguna Vitel",
               "id":"06217015"
            },
            "centroide":{
               "lat":-35.5404169288645,
               "lon":-58.1349194201315
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lezama",
               "id":"060466"
            },
            "departamento":{
               "nombre":"Chascomús",
               "id":"06217"
            },
            "nombre":"MANUEL J. COBO",
            "id":"06217020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Manuel J. Cobo",
               "id":"06217020"
            },
            "centroide":{
               "lat":-35.8748248734085,
               "lon":-57.8964226760932
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chascomús",
               "id":"060218"
            },
            "departamento":{
               "nombre":"Chascomús",
               "id":"06217"
            },
            "nombre":"VILLA PARQUE GIRADO",
            "id":"06217030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Parque Girado",
               "id":"06217030"
            },
            "centroide":{
               "lat":-35.6282696386352,
               "lon":-58.0145524400059
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"BENITEZ",
            "id":"06224005000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Benitez",
               "id":"06224005"
            },
            "centroide":{
               "lat":-34.9660329017519,
               "lon":-60.1236385563996
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"CHIVILCOY",
            "id":"06224010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chivilcoy",
               "id":"06224010"
            },
            "centroide":{
               "lat":-34.8980163772726,
               "lon":-60.0188841030321
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"EMILIO AYARZA",
            "id":"06224020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Emilio Ayarza",
               "id":"06224020"
            },
            "centroide":{
               "lat":-34.746060108407,
               "lon":-60.0392314192743
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"GOROSTIAGA",
            "id":"06224030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gorostiaga",
               "id":"06224030"
            },
            "centroide":{
               "lat":-34.839127302807,
               "lon":-59.8646671984292
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"LA RICA",
            "id":"06224040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Rica",
               "id":"06224040"
            },
            "centroide":{
               "lat":-34.9739189538858,
               "lon":-59.8639302070489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"MOQUEHUA",
            "id":"06224050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moquehuá",
               "id":"06224050"
            },
            "centroide":{
               "lat":-35.0926582854313,
               "lon":-59.7745302971612
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"RAMON BIAUS",
            "id":"06224060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ramón Biaus",
               "id":"06224060"
            },
            "centroide":{
               "lat":-35.0858455213162,
               "lon":-59.9236682591744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chivilcoy",
               "id":"060224"
            },
            "departamento":{
               "nombre":"Chivilcoy",
               "id":"06224"
            },
            "nombre":"SAN SEBASTIAN",
            "id":"06224070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Sebastián",
               "id":"06224070"
            },
            "centroide":{
               "lat":-34.9443783815263,
               "lon":-59.7018597369347
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Daireaux",
               "id":"060231"
            },
            "departamento":{
               "nombre":"Daireaux",
               "id":"06231"
            },
            "nombre":"ANDANT",
            "id":"06231010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Andant",
               "id":"06231010"
            },
            "centroide":{
               "lat":-36.5640579804658,
               "lon":-62.1324009778233
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Daireaux",
               "id":"060231"
            },
            "departamento":{
               "nombre":"Daireaux",
               "id":"06231"
            },
            "nombre":"ARBOLEDAS",
            "id":"06231020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arboledas",
               "id":"06231020"
            },
            "centroide":{
               "lat":-36.8827708539235,
               "lon":-61.4878179060517
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Daireaux",
               "id":"060231"
            },
            "departamento":{
               "nombre":"Daireaux",
               "id":"06231"
            },
            "nombre":"DAIREAUX",
            "id":"06231030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Daireaux",
               "id":"06231030"
            },
            "centroide":{
               "lat":-36.6001749213746,
               "lon":-61.7450132654369
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Daireaux",
               "id":"060231"
            },
            "departamento":{
               "nombre":"Daireaux",
               "id":"06231"
            },
            "nombre":"LA LARGA",
            "id":"06231040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Larga",
               "id":"06231040"
            },
            "centroide":{
               "lat":-36.6749256386405,
               "lon":-61.9291952273289
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Daireaux",
               "id":"060231"
            },
            "departamento":{
               "nombre":"Daireaux",
               "id":"06231"
            },
            "nombre":"SALAZAR",
            "id":"06231060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Salazar",
               "id":"06231060"
            },
            "centroide":{
               "lat":-36.307117163487,
               "lon":-62.2003365899
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dolores",
               "id":"060238"
            },
            "departamento":{
               "nombre":"Dolores",
               "id":"06238"
            },
            "nombre":"DOLORES",
            "id":"06238010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Dolores",
               "id":"06238010"
            },
            "centroide":{
               "lat":-36.3161215739926,
               "lon":-57.6752657333964
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dolores",
               "id":"060238"
            },
            "departamento":{
               "nombre":"Dolores",
               "id":"06238"
            },
            "nombre":"SEVIGNE",
            "id":"06238020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sevigne",
               "id":"06238020"
            },
            "centroide":{
               "lat":-36.2067284269712,
               "lon":-57.7413707296745
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ensenada",
               "id":"060245"
            },
            "departamento":{
               "nombre":"Ensenada",
               "id":"06245"
            },
            "nombre":"DIQUE Nº 1",
            "id":"06245010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ensenada",
               "id":"06245010"
            },
            "centroide":{
               "lat":-34.8758485548313,
               "lon":-57.9336298993983
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ensenada",
               "id":"060245"
            },
            "departamento":{
               "nombre":"Ensenada",
               "id":"06245"
            },
            "nombre":"ENSENADA",
            "id":"06245010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ensenada",
               "id":"06245010"
            },
            "centroide":{
               "lat":-34.8679770208454,
               "lon":-57.9214379246659
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ensenada",
               "id":"060245"
            },
            "departamento":{
               "nombre":"Ensenada",
               "id":"06245"
            },
            "nombre":"ISLA SANTIAGO (OESTE)",
            "id":"06245010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ensenada",
               "id":"06245010"
            },
            "centroide":{
               "lat":-34.8370461990756,
               "lon":-57.8999620408998
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ensenada",
               "id":"060245"
            },
            "departamento":{
               "nombre":"Ensenada",
               "id":"06245"
            },
            "nombre":"PUNTA LARA",
            "id":"06245010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ensenada",
               "id":"06245010"
            },
            "centroide":{
               "lat":-34.8346689394776,
               "lon":-58.0010481870988
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ensenada",
               "id":"060245"
            },
            "departamento":{
               "nombre":"Ensenada",
               "id":"06245"
            },
            "nombre":"VILLA CATELA",
            "id":"06245010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ensenada",
               "id":"06245010"
            },
            "centroide":{
               "lat":-34.884600148052,
               "lon":-57.9511290255284
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"BELEN DE ESCOBAR",
            "id":"06252010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.3516704130246,
               "lon":-58.7832526742638
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"EL CAZADOR",
            "id":"06252010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.3034904902207,
               "lon":-58.7811274560113
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"GARIN",
            "id":"06252010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.428785229178,
               "lon":-58.7361732596546
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"INGENIERO MASCHWITZ",
            "id":"06252010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.3790006332322,
               "lon":-58.7303342412363
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"LOMA VERDE",
            "id":"06252010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.3285234890863,
               "lon":-58.8506997728362
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"MATHEU",
            "id":"06252010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.3688779666182,
               "lon":-58.8402291878847
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Escobar",
               "id":"060252"
            },
            "departamento":{
               "nombre":"Escobar",
               "id":"06252"
            },
            "nombre":"MAQUINISTA F. SAVIO ESTE",
            "id":"06252010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escobar",
               "id":"06252010"
            },
            "centroide":{
               "lat":-34.3964888336318,
               "lon":-58.7629458829717
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esteban Echeverría",
               "id":"060260"
            },
            "departamento":{
               "nombre":"Esteban Echeverría",
               "id":"06260"
            },
            "nombre":"CANNING",
            "id":"06260010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Esteban Echeverría",
               "id":"06260010"
            },
            "centroide":{
               "lat":-34.8849791351051,
               "lon":-58.4778099372334
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esteban Echeverría",
               "id":"060260"
            },
            "departamento":{
               "nombre":"Esteban Echeverría",
               "id":"06260"
            },
            "nombre":"EL JAGÜEL",
            "id":"06260010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Esteban Echeverría",
               "id":"06260010"
            },
            "centroide":{
               "lat":-34.8304505041023,
               "lon":-58.494869419897
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esteban Echeverría",
               "id":"060260"
            },
            "departamento":{
               "nombre":"Esteban Echeverría",
               "id":"06260"
            },
            "nombre":"LUIS GUILLON",
            "id":"06260010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Esteban Echeverría",
               "id":"06260010"
            },
            "centroide":{
               "lat":-34.800196435999,
               "lon":-58.4530964052639
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esteban Echeverría",
               "id":"060260"
            },
            "departamento":{
               "nombre":"Esteban Echeverría",
               "id":"06260"
            },
            "nombre":"MONTE GRANDE",
            "id":"06260010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Esteban Echeverría",
               "id":"06260010"
            },
            "centroide":{
               "lat":-34.8191368630736,
               "lon":-58.4726947603508
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esteban Echeverría",
               "id":"060260"
            },
            "departamento":{
               "nombre":"Esteban Echeverría",
               "id":"06260"
            },
            "nombre":"9 DE ABRIL",
            "id":"06260010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Esteban Echeverría",
               "id":"06260010"
            },
            "centroide":{
               "lat":-34.7565075768115,
               "lon":-58.4902923509723
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"ARROYO DE LA CRUZ",
            "id":"06266010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arroyo de la Cruz",
               "id":"06266010"
            },
            "centroide":{
               "lat":-34.3363786908945,
               "lon":-59.1094149717708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"CAPILLA DEL SE¥OR",
            "id":"06266020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Capilla del Señor",
               "id":"06266020"
            },
            "centroide":{
               "lat":-34.2912108668035,
               "lon":-59.1015232724441
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"DIEGO GAYNOR",
            "id":"06266030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Diego Gaynor",
               "id":"06266030"
            },
            "centroide":{
               "lat":-34.288973975279,
               "lon":-59.222781222713
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"LOS CARDALES",
            "id":"06266040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Cardales",
               "id":"06266040"
            },
            "centroide":{
               "lat":-34.3300736993853,
               "lon":-58.9885651588167
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"PARADA ORLANDO",
            "id":"06266050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Parada Orlando",
               "id":"06266050"
            },
            "centroide":{
               "lat":-34.3273003551547,
               "lon":-59.0759905460338
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"EL REMANSO",
            "id":"06266060001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Parada Robles - Pavón",
               "id":"06266060"
            },
            "centroide":{
               "lat":-34.4149477393736,
               "lon":-59.086281182388
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"PARADA ROBLES",
            "id":"06266060002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Parada Robles - Pavón",
               "id":"06266060"
            },
            "centroide":{
               "lat":-34.3736266959435,
               "lon":-59.139354021597
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Exaltación de la Cruz",
               "id":"060266"
            },
            "departamento":{
               "nombre":"Exaltación de la Cruz",
               "id":"06266"
            },
            "nombre":"PAVON",
            "id":"06266060003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Parada Robles - Pavón",
               "id":"06266060"
            },
            "centroide":{
               "lat":-34.3581118951493,
               "lon":-59.0310010709452
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ezeiza",
               "id":"060270"
            },
            "departamento":{
               "nombre":"José M. Ezeiza",
               "id":"06270"
            },
            "nombre":"AEROPUERTO INTERNACIONAL EZEIZA",
            "id":"06270010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ezeiza",
               "id":"06270010"
            },
            "centroide":{
               "lat":-34.8128216283518,
               "lon":-58.5521650062885
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ezeiza",
               "id":"060270"
            },
            "departamento":{
               "nombre":"José M. Ezeiza",
               "id":"06270"
            },
            "nombre":"CANNING",
            "id":"06270010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ezeiza",
               "id":"06270010"
            },
            "centroide":{
               "lat":-34.8962283957338,
               "lon":-58.5087006002723
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ezeiza",
               "id":"060270"
            },
            "departamento":{
               "nombre":"José M. Ezeiza",
               "id":"06270"
            },
            "nombre":"CARLOS SPEGAZZINI",
            "id":"06270010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ezeiza",
               "id":"06270010"
            },
            "centroide":{
               "lat":-34.9338489869515,
               "lon":-58.5780017334776
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ezeiza",
               "id":"060270"
            },
            "departamento":{
               "nombre":"José M. Ezeiza",
               "id":"06270"
            },
            "nombre":"JOSE MARIA EZEIZA",
            "id":"06270010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ezeiza",
               "id":"06270010"
            },
            "centroide":{
               "lat":-34.8536100103068,
               "lon":-58.5195521653909
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ezeiza",
               "id":"060270"
            },
            "departamento":{
               "nombre":"José M. Ezeiza",
               "id":"06270"
            },
            "nombre":"LA UNION",
            "id":"06270010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ezeiza",
               "id":"06270010"
            },
            "centroide":{
               "lat":-34.8503859677023,
               "lon":-58.5558695462288
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ezeiza",
               "id":"060270"
            },
            "departamento":{
               "nombre":"José M. Ezeiza",
               "id":"06270"
            },
            "nombre":"TRISTAN SUAREZ",
            "id":"06270010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ezeiza",
               "id":"06270010"
            },
            "centroide":{
               "lat":-34.8851527149847,
               "lon":-58.5700335845498
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"BOSQUES",
            "id":"06274010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.827580702831,
               "lon":-58.2229120039487
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"ESTANISLAO SEVERO ZEBALLOS",
            "id":"06274010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.812285887223,
               "lon":-58.2426532633495
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"FLORENCIO VARELA",
            "id":"06274010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.7960544308506,
               "lon":-58.2748588953342
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"GOBERNADOR JULIO A. COSTA",
            "id":"06274010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.8152397261388,
               "lon":-58.3082334245906
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"INGENIERO JUAN ALLAN",
            "id":"06274010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.8648075260046,
               "lon":-58.2110437322304
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"VILLA BROWN",
            "id":"06274010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.8790424725562,
               "lon":-58.2898092496787
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"VILLA SAN LUIS",
            "id":"06274010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.8627929258513,
               "lon":-58.2553232274346
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"VILLA SANTA ROSA",
            "id":"06274010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.8380276482598,
               "lon":-58.2888899071764
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"VILLA VATTEONE",
            "id":"06274010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.8303193176345,
               "lon":-58.2635239901185
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"EL TROPEZON",
            "id":"06274010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.9564048574127,
               "lon":-58.2787454299742
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencio Varela",
               "id":"060274"
            },
            "departamento":{
               "nombre":"Florencio Varela",
               "id":"06274"
            },
            "nombre":"LA CAPILLA",
            "id":"06274010011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florencio Varela",
               "id":"06274010"
            },
            "centroide":{
               "lat":-34.9437330109546,
               "lon":-58.2632550914348
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florentino Ameghino",
               "id":"060277"
            },
            "departamento":{
               "nombre":"Florentino Ameghino",
               "id":"06277"
            },
            "nombre":"BLAQUIER",
            "id":"06277010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Blaquier",
               "id":"06277010"
            },
            "centroide":{
               "lat":-34.6356616332748,
               "lon":-62.4786821321875
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florentino Ameghino",
               "id":"060277"
            },
            "departamento":{
               "nombre":"Florentino Ameghino",
               "id":"06277"
            },
            "nombre":"FLORENTINO AMEGHINO",
            "id":"06277020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Florentino Ameghino",
               "id":"06277020"
            },
            "centroide":{
               "lat":-34.846677699169,
               "lon":-62.4671575805384
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florentino Ameghino",
               "id":"060277"
            },
            "departamento":{
               "nombre":"Florentino Ameghino",
               "id":"06277"
            },
            "nombre":"PORVENIR",
            "id":"06277030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Porvenir",
               "id":"06277030"
            },
            "centroide":{
               "lat":-34.9522292183733,
               "lon":-62.2174229886622
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvarado",
               "id":"060280"
            },
            "departamento":{
               "nombre":"General Alvarado",
               "id":"06280"
            },
            "nombre":"CENTINELA DEL MAR",
            "id":"06280005000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Centinela del Mar",
               "id":"06280005"
            },
            "centroide":{
               "lat":-38.4348806367321,
               "lon":-58.216863731472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvarado",
               "id":"060280"
            },
            "departamento":{
               "nombre":"General Alvarado",
               "id":"06280"
            },
            "nombre":"COMANDANTE NICANOR OTAMENDI",
            "id":"06280010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Comandante Nicanor Otamendi",
               "id":"06280010"
            },
            "centroide":{
               "lat":-38.1119335118514,
               "lon":-57.8415353121543
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvarado",
               "id":"060280"
            },
            "departamento":{
               "nombre":"General Alvarado",
               "id":"06280"
            },
            "nombre":"MAR DEL SUR",
            "id":"06280020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar del Sur",
               "id":"06280020"
            },
            "centroide":{
               "lat":-38.3446881533109,
               "lon":-57.9920393065731
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvarado",
               "id":"060280"
            },
            "departamento":{
               "nombre":"General Alvarado",
               "id":"06280"
            },
            "nombre":"MECHONGUE",
            "id":"06280030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mechongué",
               "id":"06280030"
            },
            "centroide":{
               "lat":-38.1486545138318,
               "lon":-58.2230121065801
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvarado",
               "id":"060280"
            },
            "departamento":{
               "nombre":"General Alvarado",
               "id":"06280"
            },
            "nombre":"MIRAMAR",
            "id":"06280040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Miramar",
               "id":"06280040"
            },
            "centroide":{
               "lat":-38.2707429318083,
               "lon":-57.8404714577536
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvear",
               "id":"060287"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"06287"
            },
            "nombre":"GENERAL ALVEAR",
            "id":"06287010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Alvear",
               "id":"06287010"
            },
            "centroide":{
               "lat":-36.0229384341367,
               "lon":-60.0147935726886
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"ARRIBE¥OS",
            "id":"06294010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arribeños",
               "id":"06294010"
            },
            "centroide":{
               "lat":-34.2101542565966,
               "lon":-61.3548577979495
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"ASCENSION",
            "id":"06294020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ascensión",
               "id":"06294020"
            },
            "centroide":{
               "lat":-34.2368758979793,
               "lon":-61.103613881133
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"ESTACION ARENALES",
            "id":"06294030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Estación Arenales",
               "id":"06294030"
            },
            "centroide":{
               "lat":-34.2698455922697,
               "lon":-61.2926718130401
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"FERRE",
            "id":"06294040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ferré",
               "id":"06294040"
            },
            "centroide":{
               "lat":-34.1250383813873,
               "lon":-61.132654886464
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"GENERAL ARENALES",
            "id":"06294050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Arenales",
               "id":"06294050"
            },
            "centroide":{
               "lat":-34.3044679711077,
               "lon":-61.3056277788216
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"LA ANGELITA",
            "id":"06294060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Angelita",
               "id":"06294060"
            },
            "centroide":{
               "lat":-34.2608740726731,
               "lon":-60.9685988224111
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Arenales",
               "id":"060294"
            },
            "departamento":{
               "nombre":"General Arenales",
               "id":"06294"
            },
            "nombre":"LA TRINIDAD",
            "id":"06294070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Trinidad",
               "id":"06294070"
            },
            "centroide":{
               "lat":-34.1068549430168,
               "lon":-61.1317020714357
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"060301"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"06301"
            },
            "nombre":"GENERAL BELGRANO",
            "id":"06301010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Belgrano",
               "id":"06301010"
            },
            "centroide":{
               "lat":-35.7694577358026,
               "lon":-58.4944615102033
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"060301"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"06301"
            },
            "nombre":"GORCHS",
            "id":"06301020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gorchs",
               "id":"06301020"
            },
            "centroide":{
               "lat":-35.6733729629116,
               "lon":-58.9582999568816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Guido",
               "id":"060308"
            },
            "departamento":{
               "nombre":"General Guido",
               "id":"06308"
            },
            "nombre":"GENERAL GUIDO",
            "id":"06308010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Guido",
               "id":"06308010"
            },
            "centroide":{
               "lat":-36.6431651777017,
               "lon":-57.7905019037041
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Guido",
               "id":"060308"
            },
            "departamento":{
               "nombre":"General Guido",
               "id":"06308"
            },
            "nombre":"LABARDEN",
            "id":"06308020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Labardén",
               "id":"06308020"
            },
            "centroide":{
               "lat":-36.9489668508723,
               "lon":-58.1035671819428
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Juan Madariaga",
               "id":"060315"
            },
            "departamento":{
               "nombre":"General Juan Madariaga",
               "id":"06315"
            },
            "nombre":"BARRIO KENNEDY",
            "id":"06315010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Juan Madariaga",
               "id":"06315010"
            },
            "centroide":{
               "lat":-37.0062217702401,
               "lon":-57.1417255856769
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Juan Madariaga",
               "id":"060315"
            },
            "departamento":{
               "nombre":"General Juan Madariaga",
               "id":"06315"
            },
            "nombre":"GENERAL JUAN MADARIAGA",
            "id":"06315010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Juan Madariaga",
               "id":"06315010"
            },
            "centroide":{
               "lat":-36.9967730012079,
               "lon":-57.1374769071298
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General la Madrid",
               "id":"060322"
            },
            "departamento":{
               "nombre":"General La Madrid",
               "id":"06322"
            },
            "nombre":"GENERAL LA MADRID",
            "id":"06322010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General La Madrid",
               "id":"06322010"
            },
            "centroide":{
               "lat":-37.2503992475804,
               "lon":-61.2595794125711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General la Madrid",
               "id":"060322"
            },
            "departamento":{
               "nombre":"General La Madrid",
               "id":"06322"
            },
            "nombre":"LA COLINA",
            "id":"06322020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Colina",
               "id":"06322020"
            },
            "centroide":{
               "lat":-37.3607690235166,
               "lon":-61.5348487870647
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General la Madrid",
               "id":"060322"
            },
            "departamento":{
               "nombre":"General La Madrid",
               "id":"06322"
            },
            "nombre":"LAS MARTINETAS",
            "id":"06322030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Martinetas",
               "id":"06322030"
            },
            "centroide":{
               "lat":-37.1980813542863,
               "lon":-61.1220424031557
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General la Madrid",
               "id":"060322"
            },
            "departamento":{
               "nombre":"General La Madrid",
               "id":"06322"
            },
            "nombre":"LIBANO",
            "id":"06322040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Líbano",
               "id":"06322040"
            },
            "centroide":{
               "lat":-37.53362138441,
               "lon":-61.2865750087495
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General la Madrid",
               "id":"060322"
            },
            "departamento":{
               "nombre":"General La Madrid",
               "id":"06322"
            },
            "nombre":"PONTAUT",
            "id":"06322050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pontaut",
               "id":"06322050"
            },
            "centroide":{
               "lat":-37.731769903374,
               "lon":-61.3230957170244
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General las Heras",
               "id":"060329"
            },
            "departamento":{
               "nombre":"General Las Heras",
               "id":"06329"
            },
            "nombre":"GENERAL HORNOS",
            "id":"06329010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Hornos",
               "id":"06329010"
            },
            "centroide":{
               "lat":-34.8937763345299,
               "lon":-58.9172215319327
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General las Heras",
               "id":"060329"
            },
            "departamento":{
               "nombre":"General Las Heras",
               "id":"06329"
            },
            "nombre":"GENERAL LAS HERAS",
            "id":"06329020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Las Heras",
               "id":"06329020"
            },
            "centroide":{
               "lat":-34.9267739074255,
               "lon":-58.9453407851291
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General las Heras",
               "id":"060329"
            },
            "departamento":{
               "nombre":"General Las Heras",
               "id":"06329"
            },
            "nombre":"LA CHOZA",
            "id":"06329030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Choza",
               "id":"06329030"
            },
            "centroide":{
               "lat":-34.7829908276155,
               "lon":-59.1095647693247
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General las Heras",
               "id":"060329"
            },
            "departamento":{
               "nombre":"General Las Heras",
               "id":"06329"
            },
            "nombre":"PLOMER",
            "id":"06329050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Plomer",
               "id":"06329050"
            },
            "centroide":{
               "lat":-34.7941193667757,
               "lon":-59.0279756935744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General las Heras",
               "id":"060329"
            },
            "departamento":{
               "nombre":"General Las Heras",
               "id":"06329"
            },
            "nombre":"VILLARS",
            "id":"06329060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villars",
               "id":"06329060"
            },
            "centroide":{
               "lat":-34.8300241949658,
               "lon":-58.9422589402025
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Lavalle",
               "id":"060336"
            },
            "departamento":{
               "nombre":"General Lavalle",
               "id":"06336"
            },
            "nombre":"GENERAL LAVALLE",
            "id":"06336020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Lavalle",
               "id":"06336020"
            },
            "centroide":{
               "lat":-36.4080851872455,
               "lon":-56.9433553335054
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Lavalle",
               "id":"060336"
            },
            "departamento":{
               "nombre":"General Lavalle",
               "id":"06336"
            },
            "nombre":"PAVON",
            "id":"06336030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pavón",
               "id":"06336030"
            },
            "centroide":{
               "lat":-36.7089537726649,
               "lon":-56.7598433617574
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Paz",
               "id":"060343"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"06343"
            },
            "nombre":"BARRIO RIO SALADO",
            "id":"06343010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Río Salado",
               "id":"06343010"
            },
            "centroide":{
               "lat":-35.6936377582584,
               "lon":-58.447313612508
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Paz",
               "id":"060343"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"06343"
            },
            "nombre":"LOMA VERDE",
            "id":"06343020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Loma Verde",
               "id":"06343020"
            },
            "centroide":{
               "lat":-35.2747490125694,
               "lon":-58.4041701894263
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Paz",
               "id":"060343"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"06343"
            },
            "nombre":"RANCHOS",
            "id":"06343030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ranchos",
               "id":"06343030"
            },
            "centroide":{
               "lat":-35.5173520233652,
               "lon":-58.3184247148653
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Paz",
               "id":"060343"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"06343"
            },
            "nombre":"VILLANUEVA",
            "id":"06343040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villanueva",
               "id":"06343040"
            },
            "centroide":{
               "lat":-35.6776271050909,
               "lon":-58.4350923167562
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinto",
               "id":"060351"
            },
            "departamento":{
               "nombre":"General Pinto",
               "id":"06351"
            },
            "nombre":"COLONIA SAN RICARDO",
            "id":"06351010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia San Ricardo",
               "id":"06351010"
            },
            "centroide":{
               "lat":-34.4162137301573,
               "lon":-61.9280141749282
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinto",
               "id":"060351"
            },
            "departamento":{
               "nombre":"General Pinto",
               "id":"06351"
            },
            "nombre":"GENERAL PINTO",
            "id":"06351020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Pinto",
               "id":"06351020"
            },
            "centroide":{
               "lat":-34.764155990724,
               "lon":-61.8900674920122
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinto",
               "id":"060351"
            },
            "departamento":{
               "nombre":"General Pinto",
               "id":"06351"
            },
            "nombre":"GERMANIA",
            "id":"06351030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Germania",
               "id":"06351030"
            },
            "centroide":{
               "lat":-34.576111833277,
               "lon":-62.0518306894073
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinto",
               "id":"060351"
            },
            "departamento":{
               "nombre":"General Pinto",
               "id":"06351"
            },
            "nombre":"GUNTHER",
            "id":"06351035000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gunther",
               "id":"06351035"
            },
            "centroide":{
               "lat":-34.5994995308259,
               "lon":-61.9164989081536
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinto",
               "id":"060351"
            },
            "departamento":{
               "nombre":"General Pinto",
               "id":"06351"
            },
            "nombre":"VILLA FRANCIA",
            "id":"06351040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Francia",
               "id":"06351040"
            },
            "centroide":{
               "lat":-34.7913160212074,
               "lon":-62.2011090686366
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinto",
               "id":"060351"
            },
            "departamento":{
               "nombre":"General Pinto",
               "id":"06351"
            },
            "nombre":"VILLA ROTH",
            "id":"06351050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Roth",
               "id":"06351050"
            },
            "centroide":{
               "lat":-34.5798302278832,
               "lon":-62.1710489234118
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BARRIO EL BOQUERON",
            "id":"06357020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio El Boquerón",
               "id":"06357020"
            },
            "centroide":{
               "lat":-38.0297229162982,
               "lon":-57.7995485049706
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BARRIO LA GLORIA",
            "id":"06357050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio La Gloria",
               "id":"06357050"
            },
            "centroide":{
               "lat":-37.9068268303117,
               "lon":-57.7858860640391
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BARRIO SANTA PAULA",
            "id":"06357060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Santa Paula",
               "id":"06357060"
            },
            "centroide":{
               "lat":-37.9317157840639,
               "lon":-57.683162032898
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BATAN",
            "id":"06357070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Batán",
               "id":"06357070"
            },
            "centroide":{
               "lat":-38.0086211557001,
               "lon":-57.7085500402678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"CHAPADMALAL",
            "id":"06357080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chapadmalal",
               "id":"06357080"
            },
            "centroide":{
               "lat":-38.1757779275595,
               "lon":-57.6513390003209
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"EL MARQUESADO",
            "id":"06357090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Marquesado",
               "id":"06357090"
            },
            "centroide":{
               "lat":-38.2369312519788,
               "lon":-57.763418077922
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"ESTACION CHAPADMALAL",
            "id":"06357100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Estación Chapadmalal",
               "id":"06357100"
            },
            "centroide":{
               "lat":-38.036727731373,
               "lon":-57.7129897398616
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"CAMET",
            "id":"06357110001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar del Plata",
               "id":"06357110"
            },
            "centroide":{
               "lat":-37.9130763908367,
               "lon":-57.552463851894
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"ESTACION CAMET",
            "id":"06357110002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar del Plata",
               "id":"06357110"
            },
            "centroide":{
               "lat":-37.888665236683,
               "lon":-57.6000121501773
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"MAR DEL PLATA",
            "id":"06357110003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar del Plata",
               "id":"06357110"
            },
            "centroide":{
               "lat":-38.0120914785136,
               "lon":-57.6064947153173
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"PUNTA MOGOTES",
            "id":"06357110004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar del Plata",
               "id":"06357110"
            },
            "centroide":{
               "lat":-38.0833774287933,
               "lon":-57.5859350936487
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BARRIO EL CASAL",
            "id":"06357110005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar del Plata",
               "id":"06357110"
            },
            "centroide":{
               "lat":-37.8324145231571,
               "lon":-57.6311155465986
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"SIERRA DE LOS PADRES",
            "id":"06357120000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierra de los Padres",
               "id":"06357120"
            },
            "centroide":{
               "lat":-37.954166706805,
               "lon":-57.7715971022228
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BARRIO COLINAS VERDES",
            "id":"06357120001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierra de los Padres",
               "id":"06357120"
            },
            "centroide":{
               "lat":-37.8931024789245,
               "lon":-57.8261645643114
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"BARRIO EL COYUNCO",
            "id":"06357120002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierra de los Padres",
               "id":"06357120"
            },
            "centroide":{
               "lat":-37.9050810135269,
               "lon":-57.7391824086455
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pueyrredón",
               "id":"060357"
            },
            "departamento":{
               "nombre":"General Pueyrredón",
               "id":"06357"
            },
            "nombre":"SIERRA DE LOS PADRES",
            "id":"06357120004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierra de los Padres",
               "id":"06357120"
            },
            "centroide":{
               "lat":-37.9504850690528,
               "lon":-57.7774696627876
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Rodríguez",
               "id":"060364"
            },
            "departamento":{
               "nombre":"General Rodríguez",
               "id":"06364"
            },
            "nombre":"GENERAL RODRIGUEZ",
            "id":"06364030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rodríguez",
               "id":"06364030"
            },
            "centroide":{
               "lat":-34.6079239021063,
               "lon":-58.950282039134
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Rodríguez",
               "id":"060364"
            },
            "departamento":{
               "nombre":"General Rodríguez",
               "id":"06364"
            },
            "nombre":"BARRIO MORABO",
            "id":"06364030001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rodríguez",
               "id":"06364030"
            },
            "centroide":{
               "lat":-34.5497197440161,
               "lon":-58.9467785534585
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Rodríguez",
               "id":"060364"
            },
            "departamento":{
               "nombre":"General Rodríguez",
               "id":"06364"
            },
            "nombre":"BARRIO RUTA 24 KM. 10",
            "id":"06364030002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rodríguez",
               "id":"06364030"
            },
            "centroide":{
               "lat":-34.6764462958288,
               "lon":-58.9704188625706
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Rodríguez",
               "id":"060364"
            },
            "departamento":{
               "nombre":"General Rodríguez",
               "id":"06364"
            },
            "nombre":"C.C. BOSQUE REAL",
            "id":"06364030003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rodríguez",
               "id":"06364030"
            },
            "centroide":{
               "lat":-34.5601388485008,
               "lon":-58.9182614196703
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Rodríguez",
               "id":"060364"
            },
            "departamento":{
               "nombre":"General Rodríguez",
               "id":"06364"
            },
            "nombre":"GENERAL RODRGUEZ",
            "id":"06364030004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rodríguez",
               "id":"06364030"
            },
            "centroide":{
               "lat":-34.6278603432415,
               "lon":-58.9532533538878
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"BARRIO PARQUE GENERAL SAN MARTIN",
            "id":"06371010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5673433766504,
               "lon":-58.5843112459936
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"BILLINGHURST",
            "id":"06371010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5751071711249,
               "lon":-58.5745828938046
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"CIUDAD DEL LIBERTADOR GENERAL SAN MARTIN",
            "id":"06371010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5796986848297,
               "lon":-58.5420144816587
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"CIUDAD JARDIN EL LIBERTADOR",
            "id":"06371010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5416520781805,
               "lon":-58.5959093634496
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA AYACUCHO",
            "id":"06371010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5904132636755,
               "lon":-58.5530430923958
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA BALLESTER",
            "id":"06371010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5422846822324,
               "lon":-58.5583177179822
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA BERNARDO MONTEAGUDO",
            "id":"06371010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5905899097986,
               "lon":-58.5396606594168
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA CHACABUCO",
            "id":"06371010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5796744853293,
               "lon":-58.523036970803
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA CORONEL JOSE M. ZAPIOLA",
            "id":"06371010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5595169115142,
               "lon":-58.5772769308184
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GENERAL ANTONIO J. DE SUCRE",
            "id":"06371010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5470506011052,
               "lon":-58.5694391894093
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GENERAL EUGENIO NECOCHEA",
            "id":"06371010011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.51253320696,
               "lon":-58.5801478288493
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GENERAL JOSE TOMAS GUIDO",
            "id":"06371010012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.553116323608,
               "lon":-58.5669976603922
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GENERAL JUAN G. LAS HERAS",
            "id":"06371010013",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5612567076697,
               "lon":-58.5553936643491
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GODOY CRUZ",
            "id":"06371010014",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5433989252454,
               "lon":-58.5755928423118
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GRANADEROS DE SAN MARTIN",
            "id":"06371010015",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5507646909435,
               "lon":-58.5284854572959
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA GREGORIA MATORRAS",
            "id":"06371010016",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5383846467918,
               "lon":-58.5491721010066
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA JOSE LEON SUAREZ",
            "id":"06371010017",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5253711653351,
               "lon":-58.5802550661988
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA JUAN MARTIN DE PUEYRREDON",
            "id":"06371010018",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5474839124216,
               "lon":-58.5750972559389
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA LIBERTAD",
            "id":"06371010019",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5846960052521,
               "lon":-58.5623334028073
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA LYNCH",
            "id":"06371010020",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.590146441043,
               "lon":-58.5234377223288
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA MAIPU",
            "id":"06371010021",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5682131696117,
               "lon":-58.5245451491949
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA MARIA IRENE DE LOS REMEDIOS DE ESCALADA",
            "id":"06371010022",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5646397095177,
               "lon":-58.5922548425316
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA MARQUES ALEJANDRO MARIA DE AGUADO",
            "id":"06371010023",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5551356608044,
               "lon":-58.5383771255925
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA PARQUE PRESIDENTE FIGUEROA ALCORTA",
            "id":"06371010024",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5939487574993,
               "lon":-58.5321739553925
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA PARQUE SAN LORENZO",
            "id":"06371010025",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5618730613851,
               "lon":-58.5336624494541
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA SAN ANDRES",
            "id":"06371010026",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5659932905453,
               "lon":-58.5448069225448
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"060371"
            },
            "departamento":{
               "nombre":"Ciudad Libertador San Martín",
               "id":"06371"
            },
            "nombre":"VILLA YAPEYU",
            "id":"06371010027",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"06371010"
            },
            "centroide":{
               "lat":-34.5716953020123,
               "lon":-58.5491073994839
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Viamonte",
               "id":"060385"
            },
            "departamento":{
               "nombre":"General Viamonte",
               "id":"06385"
            },
            "nombre":"BAIGORRITA",
            "id":"06385010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Baigorrita",
               "id":"06385010"
            },
            "centroide":{
               "lat":-34.7472686783062,
               "lon":-60.9889526585307
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Viamonte",
               "id":"060385"
            },
            "departamento":{
               "nombre":"General Viamonte",
               "id":"06385"
            },
            "nombre":"LA DELFINA",
            "id":"06385020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Delfina",
               "id":"06385020"
            },
            "centroide":{
               "lat":-34.9437720137007,
               "lon":-61.1588643543904
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Viamonte",
               "id":"060385"
            },
            "departamento":{
               "nombre":"General Viamonte",
               "id":"06385"
            },
            "nombre":"LOS TOLDOS",
            "id":"06385030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Toldos",
               "id":"06385030"
            },
            "centroide":{
               "lat":-35.0010365651503,
               "lon":-61.0381497866181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Viamonte",
               "id":"060385"
            },
            "departamento":{
               "nombre":"General Viamonte",
               "id":"06385"
            },
            "nombre":"SAN EMILIO",
            "id":"06385040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Emilio",
               "id":"06385040"
            },
            "centroide":{
               "lat":-35.0319914044113,
               "lon":-60.8647685857183
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Viamonte",
               "id":"060385"
            },
            "departamento":{
               "nombre":"General Viamonte",
               "id":"06385"
            },
            "nombre":"ZAVALIA",
            "id":"06385050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Zavalía",
               "id":"06385050"
            },
            "centroide":{
               "lat":-34.8949208760254,
               "lon":-61.0044538243489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"BANDERALO",
            "id":"06392010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Banderaló",
               "id":"06392010"
            },
            "centroide":{
               "lat":-35.0124666610174,
               "lon":-63.3741563457186
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"CA¥ADA SECA",
            "id":"06392020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cañada Seca",
               "id":"06392020"
            },
            "centroide":{
               "lat":-34.4155054560621,
               "lon":-62.9618553806295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"CORONEL CHARLONE",
            "id":"06392030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Charlone",
               "id":"06392030"
            },
            "centroide":{
               "lat":-34.672994561958,
               "lon":-63.3724454840572
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"EMILIO V. BUNGE",
            "id":"06392040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Emilio V. Bunge",
               "id":"06392040"
            },
            "centroide":{
               "lat":-34.7798328261907,
               "lon":-63.1960964862342
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"GENERAL VILLEGAS",
            "id":"06392050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Villegas",
               "id":"06392050"
            },
            "centroide":{
               "lat":-35.0338419886306,
               "lon":-63.0146637107531
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"MASSEY",
            "id":"06392060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Massey",
               "id":"06392060"
            },
            "centroide":{
               "lat":-35.0490892294374,
               "lon":-63.1218876844046
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"PICHINCHA",
            "id":"06392070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pichincha",
               "id":"06392070"
            },
            "centroide":{
               "lat":-34.5804516676438,
               "lon":-62.3516395240412
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"PIEDRITAS",
            "id":"06392080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Piedritas",
               "id":"06392080"
            },
            "centroide":{
               "lat":-34.771104540922,
               "lon":-62.9846965760316
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"SANTA ELEODORA",
            "id":"06392090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Eleodora",
               "id":"06392090"
            },
            "centroide":{
               "lat":-34.6921180067125,
               "lon":-62.6957932681386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"SANTA REGINA",
            "id":"06392100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Regina",
               "id":"06392100"
            },
            "centroide":{
               "lat":-34.548335663977,
               "lon":-63.1732728724567
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"VILLA SABOYA",
            "id":"06392110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Saboya",
               "id":"06392110"
            },
            "centroide":{
               "lat":-34.4607916334895,
               "lon":-62.649344456796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Villegas",
               "id":"060392"
            },
            "departamento":{
               "nombre":"General Villegas",
               "id":"06392"
            },
            "nombre":"VILLA SAUZE",
            "id":"06392120000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Sauze",
               "id":"06392120"
            },
            "centroide":{
               "lat":-35.2858298200472,
               "lon":-63.3682055476167
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaminí",
               "id":"060399"
            },
            "departamento":{
               "nombre":"Guaminí",
               "id":"06399"
            },
            "nombre":"ARROYO VENADO",
            "id":"06399010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arroyo Venado",
               "id":"06399010"
            },
            "centroide":{
               "lat":-37.0864662845331,
               "lon":-62.5391482291111
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaminí",
               "id":"060399"
            },
            "departamento":{
               "nombre":"Guaminí",
               "id":"06399"
            },
            "nombre":"CASBAS",
            "id":"06399020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Casbas",
               "id":"06399020"
            },
            "centroide":{
               "lat":-36.7580953396186,
               "lon":-62.5017754084549
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaminí",
               "id":"060399"
            },
            "departamento":{
               "nombre":"Guaminí",
               "id":"06399"
            },
            "nombre":"GARRE",
            "id":"06399030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Garré",
               "id":"06399030"
            },
            "centroide":{
               "lat":-36.5604378772819,
               "lon":-62.5981120377825
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaminí",
               "id":"060399"
            },
            "departamento":{
               "nombre":"Guaminí",
               "id":"06399"
            },
            "nombre":"GUAMINI",
            "id":"06399040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Guaminí",
               "id":"06399040"
            },
            "centroide":{
               "lat":-37.0120881308673,
               "lon":-62.4166857296556
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaminí",
               "id":"060399"
            },
            "departamento":{
               "nombre":"Guaminí",
               "id":"06399"
            },
            "nombre":"LAGUNA ALSINA",
            "id":"06399050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Laguna Alsina",
               "id":"06399050"
            },
            "centroide":{
               "lat":-36.80905243808,
               "lon":-62.2451480754422
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Yrigoyen",
               "id":"060406"
            },
            "departamento":{
               "nombre":"Hipólito Yrigoyen",
               "id":"06406"
            },
            "nombre":"HENDERSON",
            "id":"06406010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Henderson",
               "id":"06406010"
            },
            "centroide":{
               "lat":-36.2994630370091,
               "lon":-61.7178401805261
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Yrigoyen",
               "id":"060406"
            },
            "departamento":{
               "nombre":"Hipólito Yrigoyen",
               "id":"06406"
            },
            "nombre":"HERRERA VEGAS",
            "id":"06406020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Herrera Vegas",
               "id":"06406020"
            },
            "centroide":{
               "lat":-36.0883254041477,
               "lon":-61.4112500767203
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hurlingham",
               "id":"060408"
            },
            "departamento":{
               "nombre":"Hurlingham",
               "id":"06408"
            },
            "nombre":"HURLINGHAM",
            "id":"06408010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hurlingham",
               "id":"06408010"
            },
            "centroide":{
               "lat":-34.5930931356257,
               "lon":-58.6357209509749
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hurlingham",
               "id":"060408"
            },
            "departamento":{
               "nombre":"Hurlingham",
               "id":"06408"
            },
            "nombre":"VILLA SANTOS TESEI",
            "id":"06408010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hurlingham",
               "id":"06408010"
            },
            "centroide":{
               "lat":-34.6123944448227,
               "lon":-58.6538103691925
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hurlingham",
               "id":"060408"
            },
            "departamento":{
               "nombre":"Hurlingham",
               "id":"06408"
            },
            "nombre":"WILLIAM C. MORRIS",
            "id":"06408010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hurlingham",
               "id":"06408010"
            },
            "centroide":{
               "lat":-34.5815622830288,
               "lon":-58.6583005365543
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ituzaingó",
               "id":"060410"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"06410"
            },
            "nombre":"ITUZAINGO CENTRO",
            "id":"06410010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ituzaingó",
               "id":"06410010"
            },
            "centroide":{
               "lat":-34.6440551161203,
               "lon":-58.688489332955
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ituzaingó",
               "id":"060410"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"06410"
            },
            "nombre":"ITUZAINGO SUR",
            "id":"06410010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ituzaingó",
               "id":"06410010"
            },
            "centroide":{
               "lat":-34.6699430265699,
               "lon":-58.6733044157835
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ituzaingó",
               "id":"060410"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"06410"
            },
            "nombre":"VILLA GOBERNADOR UDAONDO",
            "id":"06410010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ituzaingó",
               "id":"06410010"
            },
            "centroide":{
               "lat":-34.6168695808944,
               "lon":-58.693287500009
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"José C. Paz",
               "id":"060412"
            },
            "departamento":{
               "nombre":"José C. Paz",
               "id":"06412"
            },
            "nombre":"DEL VISO",
            "id":"06412010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"José C. Paz",
               "id":"06412010"
            },
            "centroide":{
               "lat":-34.480495794913,
               "lon":-58.8042588983994
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"José C. Paz",
               "id":"060412"
            },
            "departamento":{
               "nombre":"José C. Paz",
               "id":"06412"
            },
            "nombre":"JOSE C. PAZ",
            "id":"06412010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"José C. Paz",
               "id":"06412010"
            },
            "centroide":{
               "lat":-34.5211611965464,
               "lon":-58.770881112578
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"José C. Paz",
               "id":"060412"
            },
            "departamento":{
               "nombre":"José C. Paz",
               "id":"06412"
            },
            "nombre":"TORTUGUITAS",
            "id":"06412010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"José C. Paz",
               "id":"06412010"
            },
            "centroide":{
               "lat":-34.4918483987206,
               "lon":-58.7863780316191
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"AGUSTIN ROCA",
            "id":"06413010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Agustín Roca",
               "id":"06413010"
            },
            "centroide":{
               "lat":-34.508084406003,
               "lon":-60.8648956546314
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"AGUSTINA",
            "id":"06413020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Agustina",
               "id":"06413020"
            },
            "centroide":{
               "lat":-34.4607068571169,
               "lon":-61.067072329925
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"BALNEARIO LAGUNA DE GOMEZ",
            "id":"06413030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Balneario Laguna de Gómez",
               "id":"06413030"
            },
            "centroide":{
               "lat":-34.6606435780718,
               "lon":-61.0183645929666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"FORTIN TIBURCIO",
            "id":"06413040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Fortín Tiburcio",
               "id":"06413040"
            },
            "centroide":{
               "lat":-34.3467322372427,
               "lon":-61.1310781728463
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"JUNIN",
            "id":"06413050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Junín",
               "id":"06413050"
            },
            "centroide":{
               "lat":-34.5838316271397,
               "lon":-60.9472651715327
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"LA AGRARIA",
            "id":"06413055000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Paraje La Agraria",
               "id":"06413055"
            },
            "centroide":{
               "lat":-34.6564145153102,
               "lon":-60.8466681579607
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"LAPLACETTE",
            "id":"06413060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Laplacette",
               "id":"06413060"
            },
            "centroide":{
               "lat":-34.7245238530675,
               "lon":-61.1553171474145
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"MORSE",
            "id":"06413070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morse",
               "id":"06413070"
            },
            "centroide":{
               "lat":-34.7608898130707,
               "lon":-60.8419122487126
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"060413"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"06413"
            },
            "nombre":"SAFORCADA",
            "id":"06413080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Saforcada",
               "id":"06413080"
            },
            "centroide":{
               "lat":-34.5752591285936,
               "lon":-61.0747308195452
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"LAS TONINAS",
            "id":"06420010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Toninas",
               "id":"06420010"
            },
            "centroide":{
               "lat":-36.4883976285615,
               "lon":-56.7004352579533
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"AGUAS VERDES",
            "id":"06420020001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Ajó - San Bernardo",
               "id":"06420020"
            },
            "centroide":{
               "lat":-36.6552111489067,
               "lon":-56.6949520678334
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"LUCILA DEL MAR",
            "id":"06420020002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Ajó - San Bernardo",
               "id":"06420020"
            },
            "centroide":{
               "lat":-36.6627491358367,
               "lon":-56.6817136067571
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"MAR DE AJO",
            "id":"06420020003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Ajó - San Bernardo",
               "id":"06420020"
            },
            "centroide":{
               "lat":-36.7468693750233,
               "lon":-56.6852641972249
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"MAR DE AJO NORTE",
            "id":"06420020004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Ajó - San Bernardo",
               "id":"06420020"
            },
            "centroide":{
               "lat":-36.7065977594674,
               "lon":-56.6904987226994
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"SAN BERNARDO",
            "id":"06420020005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Ajó - San Bernardo",
               "id":"06420020"
            },
            "centroide":{
               "lat":-36.6874110140403,
               "lon":-56.6841400639982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"SAN CLEMENTE DEL TUYU",
            "id":"06420030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Clemente del Tuyú",
               "id":"06420030"
            },
            "centroide":{
               "lat":-36.3532388310754,
               "lon":-56.723944139417
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"MAR DEL TUYU",
            "id":"06420040001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Teresita - Mar del Tuyú",
               "id":"06420040"
            },
            "centroide":{
               "lat":-36.5861168542894,
               "lon":-56.6990062189009
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Costa",
               "id":"060420"
            },
            "departamento":{
               "nombre":"La Costa",
               "id":"06420"
            },
            "nombre":"SANTA TERESITA",
            "id":"06420040002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Teresita - Mar del Tuyú",
               "id":"06420040"
            },
            "centroide":{
               "lat":-36.5409111808415,
               "lon":-56.705867443875
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"ALDO BONZI",
            "id":"06427010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7141728890679,
               "lon":-58.5089043765674
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"CIUDAD EVITA",
            "id":"06427010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7245358500775,
               "lon":-58.5370063768546
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"GONZALEZ CATAN",
            "id":"06427010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7724652485211,
               "lon":-58.6404861634561
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"GREGORIO DE LAFERRERE",
            "id":"06427010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7468379962284,
               "lon":-58.5925328858169
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"ISIDRO CASANOVA",
            "id":"06427010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7184291570686,
               "lon":-58.577751528605
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"LA TABLADA",
            "id":"06427010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.6870536689657,
               "lon":-58.5256123986254
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"LOMAS DEL MIRADOR",
            "id":"06427010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.6656949723622,
               "lon":-58.5331553311677
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"RAFAEL CASTILLO",
            "id":"06427010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7132054583486,
               "lon":-58.6258365121679
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"RAMOS MEJIA",
            "id":"06427010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.6523081823718,
               "lon":-58.5592114732944
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"SAN JUSTO",
            "id":"06427010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.6881604248706,
               "lon":-58.5626785715398
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"TAPIALES",
            "id":"06427010011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7108317770604,
               "lon":-58.495008488146
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"20 DE JUNIO",
            "id":"06427010012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.7855336508384,
               "lon":-58.7171850019077
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"VILLA EDUARDO MADERO",
            "id":"06427010013",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.6981156880684,
               "lon":-58.4877575961419
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"VILLA LUZURIAGA",
            "id":"06427010014",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.6730866446433,
               "lon":-58.5938792359541
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Matanza",
               "id":"060427"
            },
            "departamento":{
               "nombre":"La Matanza",
               "id":"06427"
            },
            "nombre":"VIRREY DEL PINO",
            "id":"06427010015",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Matanza",
               "id":"06427010"
            },
            "centroide":{
               "lat":-34.8475213469043,
               "lon":-58.686067635918
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanús",
               "id":"060434"
            },
            "departamento":{
               "nombre":"Lanús",
               "id":"06434"
            },
            "nombre":"GERLI",
            "id":"06434010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lanús",
               "id":"06434010"
            },
            "centroide":{
               "lat":-34.6909571502715,
               "lon":-58.3838628492162
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanús",
               "id":"060434"
            },
            "departamento":{
               "nombre":"Lanús",
               "id":"06434"
            },
            "nombre":"LANUS ESTE",
            "id":"06434010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lanús",
               "id":"06434010"
            },
            "centroide":{
               "lat":-34.7108609216651,
               "lon":-58.3725047463731
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanús",
               "id":"060434"
            },
            "departamento":{
               "nombre":"Lanús",
               "id":"06434"
            },
            "nombre":"LANUS OESTE",
            "id":"06434010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lanús",
               "id":"06434010"
            },
            "centroide":{
               "lat":-34.6957815314992,
               "lon":-58.416253274019
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanús",
               "id":"060434"
            },
            "departamento":{
               "nombre":"Lanús",
               "id":"06434"
            },
            "nombre":"MONTE CHINGOLO",
            "id":"06434010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lanús",
               "id":"06434010"
            },
            "centroide":{
               "lat":-34.7302098940207,
               "lon":-58.3561741850066
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanús",
               "id":"060434"
            },
            "departamento":{
               "nombre":"Lanús",
               "id":"06434"
            },
            "nombre":"REMEDIOS ESCALADA DE SAN MARTIN",
            "id":"06434010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lanús",
               "id":"06434010"
            },
            "centroide":{
               "lat":-34.723808815713,
               "lon":-58.397669711018
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanús",
               "id":"060434"
            },
            "departamento":{
               "nombre":"Lanús",
               "id":"06434"
            },
            "nombre":"VALENTIN ALSINA",
            "id":"06434010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lanús",
               "id":"06434010"
            },
            "centroide":{
               "lat":-34.672035526127,
               "lon":-58.4134851675076
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"COUNTRY CLUB EL RODEO",
            "id":"06441010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Country Club El Rodeo",
               "id":"06441010"
            },
            "centroide":{
               "lat":-35.0794688053886,
               "lon":-58.1393275732941
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"IGNACIO CORREAS",
            "id":"06441020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ignacio Correas",
               "id":"06441020"
            },
            "centroide":{
               "lat":-35.0345600341406,
               "lon":-57.8440249586726
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"ABASTO",
            "id":"06441030001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-35.0103681960184,
               "lon":-58.1151848585184
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"ANGEL ETCHEVERRY",
            "id":"06441030002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-35.0261580911328,
               "lon":-58.0959060056615
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"ARANA",
            "id":"06441030003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-35.0300556104917,
               "lon":-57.8865250010855
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"ARTURO SEGUI",
            "id":"06441030004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8907397135389,
               "lon":-58.132711650613
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"BARRIO EL CARMEN (OESTE)",
            "id":"06441030005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9707544223488,
               "lon":-57.8108950283044
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"BARRIO GAMBIER",
            "id":"06441030006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9359594527479,
               "lon":-57.9837630521994
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"BARRIO LAS MALVINAS",
            "id":"06441030007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9374880163466,
               "lon":-58.0093954169858
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"BARRIO LAS QUINTAS",
            "id":"06441030008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9257210987549,
               "lon":-58.0393403036748
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"CITY BELL",
            "id":"06441030009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8879382744371,
               "lon":-58.0582328038691
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"EL RETIRO",
            "id":"06441030010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9476175562489,
               "lon":-57.9957432212034
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"JOAQUIN GORINA",
            "id":"06441030011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9017817335757,
               "lon":-58.039185928571
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"JOSE HERNANDEZ",
            "id":"06441030012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8985896635727,
               "lon":-58.0143972026636
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"JOSE MELCHOR ROMERO",
            "id":"06441030013",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9455092438623,
               "lon":-58.0362904746611
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"LA CUMBRE",
            "id":"06441030014",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9235917717359,
               "lon":-58.0019478733944
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"LA PLATA",
            "id":"06441030015",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.920863104693,
               "lon":-57.9540560062469
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"LISANDRO OLMOS",
            "id":"06441030016",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9976086009747,
               "lon":-58.043377425691
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"LOS HORNOS",
            "id":"06441030017",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.973715513981,
               "lon":-57.9850539062724
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"MANUEL B. GONNET",
            "id":"06441030018",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8819511933447,
               "lon":-58.0189915342036
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"RINGUELET",
            "id":"06441030019",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8910544941568,
               "lon":-57.9913781921596
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"RUFINO DE ELIZALDE",
            "id":"06441030020",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9710900774179,
               "lon":-57.9524351288235
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"TOLOSA",
            "id":"06441030021",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9007699307169,
               "lon":-57.9821293495972
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"TRANSRADIO",
            "id":"06441030022",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8790055541958,
               "lon":-58.081733864461
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"VILLA ELISA",
            "id":"06441030023",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.8776205187118,
               "lon":-58.0907139194439
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"VILLA ELVIRA",
            "id":"06441030024",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9406437624757,
               "lon":-57.9214984675578
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"VILLA GARIBALDI",
            "id":"06441030025",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9986517196678,
               "lon":-57.8404966243711
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"VILLA MONTORO",
            "id":"06441030026",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9634490535296,
               "lon":-57.9025001308298
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"VILLA PARQUE SICARDI",
            "id":"06441030027",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Plata",
               "id":"06441030"
            },
            "centroide":{
               "lat":-34.9830288461923,
               "lon":-57.8674653074052
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"LOMAS DE COPELLO",
            "id":"06441040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Copello",
               "id":"06441040"
            },
            "centroide":{
               "lat":-34.9520385659845,
               "lon":-57.8409385980167
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Plata",
               "id":"060441"
            },
            "departamento":{
               "nombre":"La Plata",
               "id":"06441"
            },
            "nombre":"BARRIO RUTA SOL",
            "id":"06441050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ruta Sol",
               "id":"06441050"
            },
            "centroide":{
               "lat":-34.9437333541431,
               "lon":-58.1732652389706
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laprida",
               "id":"060448"
            },
            "departamento":{
               "nombre":"Laprida",
               "id":"06448"
            },
            "nombre":"LAPRIDA",
            "id":"06448010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Laprida",
               "id":"06448010"
            },
            "centroide":{
               "lat":-37.5467976434903,
               "lon":-60.7970535965801
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laprida",
               "id":"060448"
            },
            "departamento":{
               "nombre":"Laprida",
               "id":"06448"
            },
            "nombre":"PUEBLO NUEVO",
            "id":"06448020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pueblo Nuevo",
               "id":"06448020"
            },
            "centroide":{
               "lat":-37.3263227,
               "lon":-60.9098409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laprida",
               "id":"060448"
            },
            "departamento":{
               "nombre":"Laprida",
               "id":"06448"
            },
            "nombre":"PUEBLO SAN JORGE",
            "id":"06448030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pueblo San Jorge",
               "id":"06448030"
            },
            "centroide":{
               "lat":-37.2298626892886,
               "lon":-60.9621658450441
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Flores",
               "id":"060455"
            },
            "departamento":{
               "nombre":"Las Flores",
               "id":"06455"
            },
            "nombre":"CORONEL BOERR",
            "id":"06455010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Boerr",
               "id":"06455010"
            },
            "centroide":{
               "lat":-35.9414440334215,
               "lon":-59.0693547287839
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Flores",
               "id":"060455"
            },
            "departamento":{
               "nombre":"Las Flores",
               "id":"06455"
            },
            "nombre":"EL TRIGO",
            "id":"06455020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Trigo",
               "id":"06455020"
            },
            "centroide":{
               "lat":-35.8815483511482,
               "lon":-59.4063369903389
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Flores",
               "id":"060455"
            },
            "departamento":{
               "nombre":"Las Flores",
               "id":"06455"
            },
            "nombre":"LAS FLORES",
            "id":"06455030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Flores",
               "id":"06455030"
            },
            "centroide":{
               "lat":-36.0154969145348,
               "lon":-59.1004659218434
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Flores",
               "id":"060455"
            },
            "departamento":{
               "nombre":"Las Flores",
               "id":"06455"
            },
            "nombre":"PARDO",
            "id":"06455040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pardo",
               "id":"06455040"
            },
            "centroide":{
               "lat":-36.2437580460508,
               "lon":-59.3662916338311
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"060462"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"06462"
            },
            "nombre":"ALBERDI VIEJO",
            "id":"06462010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Alberdi Viejo",
               "id":"06462010"
            },
            "centroide":{
               "lat":-34.4418003349,
               "lon":-61.8448787838164
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"060462"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"06462"
            },
            "nombre":"EL DORADO",
            "id":"06462020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Dorado",
               "id":"06462020"
            },
            "centroide":{
               "lat":-34.6528608747523,
               "lon":-61.5829085058874
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"060462"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"06462"
            },
            "nombre":"FORTIN ACHA",
            "id":"06462030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Fortín Acha",
               "id":"06462030"
            },
            "centroide":{
               "lat":-34.3430420274499,
               "lon":-61.5156655267073
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"060462"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"06462"
            },
            "nombre":"JUAN BAUTISTA ALBERDI",
            "id":"06462040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan Bautista Alberdi",
               "id":"06462040"
            },
            "centroide":{
               "lat":-34.4387919326349,
               "lon":-61.8121893821736
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"060462"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"06462"
            },
            "nombre":"LEANDRO N. ALEM",
            "id":"06462050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Leandro N. Alem",
               "id":"06462050"
            },
            "centroide":{
               "lat":-34.5217246234597,
               "lon":-61.391182899484
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"060462"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"06462"
            },
            "nombre":"VEDIA",
            "id":"06462060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vedia",
               "id":"06462060"
            },
            "centroide":{
               "lat":-34.4973984349932,
               "lon":-61.5453447631479
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"ARENAZA",
            "id":"06469010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arenaza",
               "id":"06469010"
            },
            "centroide":{
               "lat":-34.9846043002155,
               "lon":-61.7729116988591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"BAYAUCA",
            "id":"06469020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bayauca",
               "id":"06469020"
            },
            "centroide":{
               "lat":-34.8710511337121,
               "lon":-61.2895451446375
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"BERMUDEZ",
            "id":"06469030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bermúdez",
               "id":"06469030"
            },
            "centroide":{
               "lat":-34.6966047108596,
               "lon":-61.3250128810539
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"CARLOS SALAS",
            "id":"06469040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carlos Salas",
               "id":"06469040"
            },
            "centroide":{
               "lat":-35.3913662871435,
               "lon":-61.9949102331933
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"CORONEL MARTINEZ DE HOZ",
            "id":"06469050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Martínez de Hoz",
               "id":"06469050"
            },
            "centroide":{
               "lat":-35.3321817215807,
               "lon":-61.6140851684372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"EL TRIUNFO",
            "id":"06469060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Triunfo",
               "id":"06469060"
            },
            "centroide":{
               "lat":-35.0882210775847,
               "lon":-61.5163333232822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"LAS TOSCAS",
            "id":"06469070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Toscas",
               "id":"06469070"
            },
            "centroide":{
               "lat":-35.3649873001768,
               "lon":-61.8055485519284
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"LINCOLN",
            "id":"06469080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lincoln",
               "id":"06469080"
            },
            "centroide":{
               "lat":-34.869042222818,
               "lon":-61.5291649669285
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"PASTEUR",
            "id":"06469090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pasteur",
               "id":"06469090"
            },
            "centroide":{
               "lat":-35.1426386337075,
               "lon":-62.2439028944602
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"ROBERTS",
            "id":"06469100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Roberts",
               "id":"06469100"
            },
            "centroide":{
               "lat":-35.1440875676884,
               "lon":-61.9707859541264
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lincoln",
               "id":"060469"
            },
            "departamento":{
               "nombre":"Lincoln",
               "id":"06469"
            },
            "nombre":"TRIUNVIRATO",
            "id":"06469110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Triunvirato",
               "id":"06469110"
            },
            "centroide":{
               "lat":-34.6758379792473,
               "lon":-61.4657087059048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobería",
               "id":"060476"
            },
            "departamento":{
               "nombre":"Lobería",
               "id":"06476"
            },
            "nombre":"ARENAS VERDES",
            "id":"06476010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arenas Verdes",
               "id":"06476010"
            },
            "centroide":{
               "lat":-38.5463782386967,
               "lon":-58.557322112625
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobería",
               "id":"060476"
            },
            "departamento":{
               "nombre":"Lobería",
               "id":"06476"
            },
            "nombre":"LICENCIADO MATIENZO",
            "id":"06476020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Licenciado Matienzo",
               "id":"06476020"
            },
            "centroide":{
               "lat":-37.9097927118145,
               "lon":-58.9120965943062
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobería",
               "id":"060476"
            },
            "departamento":{
               "nombre":"Lobería",
               "id":"06476"
            },
            "nombre":"LOBERIA",
            "id":"06476030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lobería",
               "id":"06476030"
            },
            "centroide":{
               "lat":-38.165273387122,
               "lon":-58.7822303314999
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobería",
               "id":"060476"
            },
            "departamento":{
               "nombre":"Lobería",
               "id":"06476"
            },
            "nombre":"PIERES",
            "id":"06476040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pieres",
               "id":"06476040"
            },
            "centroide":{
               "lat":-38.3962306181995,
               "lon":-58.670580006548
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobería",
               "id":"060476"
            },
            "departamento":{
               "nombre":"Lobería",
               "id":"06476"
            },
            "nombre":"SAN MANUEL",
            "id":"06476050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Manuel",
               "id":"06476050"
            },
            "centroide":{
               "lat":-37.7892829007834,
               "lon":-58.8486476710495
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobería",
               "id":"060476"
            },
            "departamento":{
               "nombre":"Lobería",
               "id":"06476"
            },
            "nombre":"TAMANGUEYU",
            "id":"06476060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tamangueyú",
               "id":"06476060"
            },
            "centroide":{
               "lat":-38.2010112434137,
               "lon":-58.7373143964601
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobos",
               "id":"060483"
            },
            "departamento":{
               "nombre":"Lobos",
               "id":"06483"
            },
            "nombre":"ANTONIO CARBONI",
            "id":"06483010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Antonio Carboni",
               "id":"06483010"
            },
            "centroide":{
               "lat":-35.2033088915799,
               "lon":-59.3449562581802
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobos",
               "id":"060483"
            },
            "departamento":{
               "nombre":"Lobos",
               "id":"06483"
            },
            "nombre":"ELVIRA",
            "id":"06483020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Elvira",
               "id":"06483020"
            },
            "centroide":{
               "lat":-35.243272141682,
               "lon":-59.4860397931491
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobos",
               "id":"060483"
            },
            "departamento":{
               "nombre":"Lobos",
               "id":"06483"
            },
            "nombre":"LAGUNA DE LOBOS",
            "id":"06483030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Laguna de Lobos",
               "id":"06483030"
            },
            "centroide":{
               "lat":-35.2747624905943,
               "lon":-59.1339060011927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobos",
               "id":"060483"
            },
            "departamento":{
               "nombre":"Lobos",
               "id":"06483"
            },
            "nombre":"LOBOS",
            "id":"06483040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lobos",
               "id":"06483040"
            },
            "centroide":{
               "lat":-35.1858677983922,
               "lon":-59.0957115706922
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lobos",
               "id":"060483"
            },
            "departamento":{
               "nombre":"Lobos",
               "id":"06483"
            },
            "nombre":"SALVADOR MARIA",
            "id":"06483050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Salvador María",
               "id":"06483050"
            },
            "centroide":{
               "lat":-35.3028071422894,
               "lon":-59.1696841440203
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"BANFIELD",
            "id":"06490010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.7379027155314,
               "lon":-58.440675368556
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"LLAVALLOL",
            "id":"06490010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.7946182344111,
               "lon":-58.4320908524909
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"LOMAS DE ZAMORA",
            "id":"06490010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.7627676410061,
               "lon":-58.4267903899666
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"TEMPERLEY",
            "id":"06490010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.767486625425,
               "lon":-58.3780461337408
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"TURDERA",
            "id":"06490010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.789560716095,
               "lon":-58.4041056486523
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"VILLA CENTENARIO",
            "id":"06490010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.7289932955803,
               "lon":-58.4283436419093
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Zamora",
               "id":"060490"
            },
            "departamento":{
               "nombre":"Lomas de Zamora",
               "id":"06490"
            },
            "nombre":"VILLA FIORITO",
            "id":"06490010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lomas de Zamora",
               "id":"06490010"
            },
            "centroide":{
               "lat":-34.7067104032516,
               "lon":-58.4451304500011
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"CARLOS KEEN",
            "id":"06497020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carlos Keen",
               "id":"06497020"
            },
            "centroide":{
               "lat":-34.4862819813638,
               "lon":-59.2180861330698
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"CLUB DE CAMPO LOS PUENTES",
            "id":"06497025000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Club de Campo Los Puentes",
               "id":"06497025"
            },
            "centroide":{
               "lat":-34.5760126891975,
               "lon":-59.0214105976263
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"LUJAN",
            "id":"06497060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.5706550661632,
               "lon":-59.109540176033
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"BARRIO LAS CASUARINAS",
            "id":"06497060001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.5607457195455,
               "lon":-59.1700837097016
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"CORTINES",
            "id":"06497060002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.5590633814317,
               "lon":-59.2044266157478
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"LEZICA Y TORREZURI",
            "id":"06497060003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.5806997364467,
               "lon":-59.0582308683017
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"LUJAN",
            "id":"06497060004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.5646845021423,
               "lon":-59.1320945387741
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"VILLA FLANDRIA NORTE (PUEBLO NUEVO)",
            "id":"06497060005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.570714539607,
               "lon":-59.1826103383073
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"VILLA FLANDRIA SUR (EST. JAUREGUI)",
            "id":"06497060006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.5993969541802,
               "lon":-59.1757636437956
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"COUNTRY CLUB LAS PRADERAS",
            "id":"06497060007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.4902656696955,
               "lon":-59.1324863072423
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"OPEN DOOR",
            "id":"06497060008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"06497060"
            },
            "centroide":{
               "lat":-34.4890162386569,
               "lon":-59.0617361279716
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"OLIVERA",
            "id":"06497070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Olivera",
               "id":"06497070"
            },
            "centroide":{
               "lat":-34.6265041710141,
               "lon":-59.2533805145177
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján",
               "id":"060497"
            },
            "departamento":{
               "nombre":"Luján",
               "id":"06497"
            },
            "nombre":"TORRES",
            "id":"06497090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Torres",
               "id":"06497090"
            },
            "centroide":{
               "lat":-34.4318215080317,
               "lon":-59.1287739002816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Magdalena",
               "id":"060505"
            },
            "departamento":{
               "nombre":"Magdalena",
               "id":"06505"
            },
            "nombre":"ATALAYA",
            "id":"06505010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Atalaya",
               "id":"06505010"
            },
            "centroide":{
               "lat":-35.0240363457416,
               "lon":-57.5340253600961
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Magdalena",
               "id":"060505"
            },
            "departamento":{
               "nombre":"Magdalena",
               "id":"06505"
            },
            "nombre":"GENERAL MANSILLA",
            "id":"06505020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Mansilla",
               "id":"06505020"
            },
            "centroide":{
               "lat":-35.0816834306862,
               "lon":-57.7469762592144
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Magdalena",
               "id":"060505"
            },
            "departamento":{
               "nombre":"Magdalena",
               "id":"06505"
            },
            "nombre":"LOS NARANJOS",
            "id":"06505030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Naranjos",
               "id":"06505030"
            },
            "centroide":{
               "lat":-34.99655185849,
               "lon":-57.7036642458572
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Magdalena",
               "id":"060505"
            },
            "departamento":{
               "nombre":"Magdalena",
               "id":"06505"
            },
            "nombre":"MAGDALENA",
            "id":"06505040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Magdalena",
               "id":"06505040"
            },
            "centroide":{
               "lat":-35.0806853032506,
               "lon":-57.5172585839912
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Magdalena",
               "id":"060505"
            },
            "departamento":{
               "nombre":"Magdalena",
               "id":"06505"
            },
            "nombre":"ROBERTO J. PAYRO",
            "id":"06505050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Roberto J. Payró",
               "id":"06505050"
            },
            "centroide":{
               "lat":-35.1799267057869,
               "lon":-57.651986019808
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Magdalena",
               "id":"060505"
            },
            "departamento":{
               "nombre":"Magdalena",
               "id":"06505"
            },
            "nombre":"VIEYTES",
            "id":"06505060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vieytes",
               "id":"06505060"
            },
            "centroide":{
               "lat":-35.269568396303,
               "lon":-57.5757117447539
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"060511"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"06511"
            },
            "nombre":"LAS ARMAS",
            "id":"06511010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Armas",
               "id":"06511010"
            },
            "centroide":{
               "lat":-37.0860540989,
               "lon":-57.8285752273883
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"060511"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"06511"
            },
            "nombre":"MAIPU",
            "id":"06511020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Maipú",
               "id":"06511020"
            },
            "centroide":{
               "lat":-36.8648514645038,
               "lon":-57.8829166198472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"060511"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"06511"
            },
            "nombre":"SANTO DOMINGO",
            "id":"06511030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santo Domingo",
               "id":"06511030"
            },
            "centroide":{
               "lat":-36.7137597811284,
               "lon":-57.5860126519926
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"AREA DE PROMOCION EL TRIANGULO",
            "id":"06515010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.4544550576034,
               "lon":-58.7062417407487
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"GRAND BOURG",
            "id":"06515010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.4869935800875,
               "lon":-58.7286750953502
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"INGENIERO ADOLFO SOURDEAUX",
            "id":"06515010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.5004325972609,
               "lon":-58.6618050648717
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"INGENIERO PABLO NOGUES",
            "id":"06515010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.4790933125467,
               "lon":-58.6989741628196
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"LOS POLVORINES",
            "id":"06515010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.5078746726212,
               "lon":-58.7013439131882
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"TORTUGUITAS",
            "id":"06515010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.4746661471823,
               "lon":-58.7513344097631
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"060515"
            },
            "departamento":{
               "nombre":"Malvinas Argentinas",
               "id":"06515"
            },
            "nombre":"VILLA DE MAYO",
            "id":"06515010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"06515010"
            },
            "centroide":{
               "lat":-34.5061507591135,
               "lon":-58.6793063506777
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"CORONEL VIDAL",
            "id":"06518010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Coronel Vidal",
               "id":"06518010"
            },
            "centroide":{
               "lat":-37.452214188593,
               "lon":-57.7299500229319
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"GENERAL PIRAN",
            "id":"06518020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Pirán",
               "id":"06518020"
            },
            "centroide":{
               "lat":-37.2778606745914,
               "lon":-57.7740148170475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"LA ARMONIA",
            "id":"06518030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Armonía",
               "id":"06518030"
            },
            "centroide":{
               "lat":-37.774854850113,
               "lon":-57.6351210747907
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"MAR CHIQUITA",
            "id":"06518040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar Chiquita",
               "id":"06518040"
            },
            "centroide":{
               "lat":-37.7462581727647,
               "lon":-57.4274479339348
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"LA BALIZA",
            "id":"06518050001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Cobo",
               "id":"06518050"
            },
            "centroide":{
               "lat":-37.7697388275396,
               "lon":-57.450820092514
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"LA CALETA",
            "id":"06518050002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Cobo",
               "id":"06518050"
            },
            "centroide":{
               "lat":-37.7834384978567,
               "lon":-57.4647863685704
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"MAR DE COBO",
            "id":"06518050003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar de Cobo",
               "id":"06518050"
            },
            "centroide":{
               "lat":-37.7768000427976,
               "lon":-57.455658983424
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"ATLANTIDA",
            "id":"06518060001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Clara del Mar",
               "id":"06518060"
            },
            "centroide":{
               "lat":-37.8505519250944,
               "lon":-57.5134595632837
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"CAMET NORTE",
            "id":"06518060002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Clara del Mar",
               "id":"06518060"
            },
            "centroide":{
               "lat":-37.8224325083139,
               "lon":-57.4974692762018
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"FRENTE MAR",
            "id":"06518060003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Clara del Mar",
               "id":"06518060"
            },
            "centroide":{
               "lat":-37.857770246553,
               "lon":-57.5154242437478
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"PLAYA DORADA",
            "id":"06518060004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Clara del Mar",
               "id":"06518060"
            },
            "centroide":{
               "lat":-37.8730952780707,
               "lon":-57.5218898754931
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"SANTA CLARA DEL MAR",
            "id":"06518060005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Clara del Mar",
               "id":"06518060"
            },
            "centroide":{
               "lat":-37.834833606977,
               "lon":-57.5079487213859
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"SANTA ELENA",
            "id":"06518060006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Clara del Mar",
               "id":"06518060"
            },
            "centroide":{
               "lat":-37.8649003626945,
               "lon":-57.5169016362882
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mar Chiquita",
               "id":"060518"
            },
            "departamento":{
               "nombre":"Mar Chiquita",
               "id":"06518"
            },
            "nombre":"VIVORATA",
            "id":"06518070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vivoratá",
               "id":"06518070"
            },
            "centroide":{
               "lat":-37.6627994476991,
               "lon":-57.6670967441538
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Marcos Paz",
               "id":"060525"
            },
            "departamento":{
               "nombre":"Marcos Paz",
               "id":"06525"
            },
            "nombre":"BARRIO SANTA ROSA",
            "id":"06525010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Barrio Santa Rosa",
               "id":"06525010"
            },
            "centroide":{
               "lat":-34.953080822173,
               "lon":-58.78292863718
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Marcos Paz",
               "id":"060525"
            },
            "departamento":{
               "nombre":"Marcos Paz",
               "id":"06525"
            },
            "nombre":"BARRIOS LISANDRO DE LA TORRE Y SANTA MARTA",
            "id":"06525020001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Marcos Paz",
               "id":"06525020"
            },
            "centroide":{
               "lat":-34.854330073065,
               "lon":-58.7844436414942
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Marcos Paz",
               "id":"060525"
            },
            "departamento":{
               "nombre":"Marcos Paz",
               "id":"06525"
            },
            "nombre":"MARCOS PAZ",
            "id":"06525020002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Marcos Paz",
               "id":"06525020"
            },
            "centroide":{
               "lat":-34.7965436888808,
               "lon":-58.8778211205753
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mercedes",
               "id":"060532"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"06532"
            },
            "nombre":"GOLDNEY",
            "id":"06532005000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Goldney",
               "id":"06532005"
            },
            "centroide":{
               "lat":-34.6029546709308,
               "lon":-59.288213732192
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mercedes",
               "id":"060532"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"06532"
            },
            "nombre":"GOWLAND",
            "id":"06532010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gowland",
               "id":"06532010"
            },
            "centroide":{
               "lat":-34.652429281715,
               "lon":-59.3523186300108
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mercedes",
               "id":"060532"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"06532"
            },
            "nombre":"MERCEDES",
            "id":"06532020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mercedes",
               "id":"06532020"
            },
            "centroide":{
               "lat":-34.6521715354886,
               "lon":-59.4295981722689
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mercedes",
               "id":"060532"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"06532"
            },
            "nombre":"TOMAS JOFRE",
            "id":"06532030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Jorge Born",
               "id":"06532030"
            },
            "centroide":{
               "lat":-34.698955554941,
               "lon":-59.3194428313109
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"060539"
            },
            "departamento":{
               "nombre":"Merlo",
               "id":"06539"
            },
            "nombre":"LIBERTAD",
            "id":"06539010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Merlo",
               "id":"06539010"
            },
            "centroide":{
               "lat":-34.7085422423529,
               "lon":-58.6795867252566
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"060539"
            },
            "departamento":{
               "nombre":"Merlo",
               "id":"06539"
            },
            "nombre":"MARIANO ACOSTA",
            "id":"06539010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Merlo",
               "id":"06539010"
            },
            "centroide":{
               "lat":-34.7154808073462,
               "lon":-58.7976740413845
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"060539"
            },
            "departamento":{
               "nombre":"Merlo",
               "id":"06539"
            },
            "nombre":"MERLO",
            "id":"06539010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Merlo",
               "id":"06539010"
            },
            "centroide":{
               "lat":-34.6823277014068,
               "lon":-58.7436432079669
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"060539"
            },
            "departamento":{
               "nombre":"Merlo",
               "id":"06539"
            },
            "nombre":"PONTEVEDRA",
            "id":"06539010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Merlo",
               "id":"06539010"
            },
            "centroide":{
               "lat":-34.7467959489481,
               "lon":-58.7060431536772
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"060539"
            },
            "departamento":{
               "nombre":"Merlo",
               "id":"06539"
            },
            "nombre":"SAN ANTONIO DE PADUA",
            "id":"06539010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Merlo",
               "id":"06539010"
            },
            "centroide":{
               "lat":-34.6698581359268,
               "lon":-58.697930436646
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte",
               "id":"060547"
            },
            "departamento":{
               "nombre":"Monte",
               "id":"06547"
            },
            "nombre":"ABBOTT",
            "id":"06547010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Abbott",
               "id":"06547010"
            },
            "centroide":{
               "lat":-35.2825062106661,
               "lon":-58.8042619084922
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte",
               "id":"060547"
            },
            "departamento":{
               "nombre":"Monte",
               "id":"06547"
            },
            "nombre":"SAN MIGUEL DEL MONTE",
            "id":"06547020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Miguel del Monte",
               "id":"06547020"
            },
            "centroide":{
               "lat":-35.4391643240479,
               "lon":-58.8094598355672
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte",
               "id":"060547"
            },
            "departamento":{
               "nombre":"Monte",
               "id":"06547"
            },
            "nombre":"ZENON VIDELA DORNA",
            "id":"06547030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Zenón Videla Dorna",
               "id":"06547030"
            },
            "centroide":{
               "lat":-35.5447734044526,
               "lon":-58.8855911917932
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Hermoso",
               "id":"060553"
            },
            "departamento":{
               "nombre":"Monte Hermoso",
               "id":"06553"
            },
            "nombre":"BALNEARIO SAUCE GRANDE",
            "id":"06553010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Balneario Sauce Grande",
               "id":"06553010"
            },
            "centroide":{
               "lat":-38.995357691335,
               "lon":-61.2152015930805
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Hermoso",
               "id":"060553"
            },
            "departamento":{
               "nombre":"Monte Hermoso",
               "id":"06553"
            },
            "nombre":"MONTE HERMOSO",
            "id":"06553020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Monte Hermoso",
               "id":"06553020"
            },
            "centroide":{
               "lat":-38.9815065384254,
               "lon":-61.3005457486605
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moreno",
               "id":"060560"
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"06560"
            },
            "nombre":"CUARTEL V",
            "id":"06560010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moreno",
               "id":"06560010"
            },
            "centroide":{
               "lat":-34.5586595722532,
               "lon":-58.8163010886698
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moreno",
               "id":"060560"
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"06560"
            },
            "nombre":"FRANCISCO ALVAREZ",
            "id":"06560010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moreno",
               "id":"06560010"
            },
            "centroide":{
               "lat":-34.6073137077479,
               "lon":-58.859942785434
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moreno",
               "id":"060560"
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"06560"
            },
            "nombre":"LA REJA",
            "id":"06560010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moreno",
               "id":"06560010"
            },
            "centroide":{
               "lat":-34.6452391234366,
               "lon":-58.8380252692761
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moreno",
               "id":"060560"
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"06560"
            },
            "nombre":"MORENO",
            "id":"06560010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moreno",
               "id":"06560010"
            },
            "centroide":{
               "lat":-34.637632238148,
               "lon":-58.7921362740593
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moreno",
               "id":"060560"
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"06560"
            },
            "nombre":"PASO DEL REY",
            "id":"06560010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moreno",
               "id":"06560010"
            },
            "centroide":{
               "lat":-34.6353876829627,
               "lon":-58.7503180890016
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moreno",
               "id":"060560"
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"06560"
            },
            "nombre":"TRUJUI",
            "id":"06560010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Moreno",
               "id":"06560010"
            },
            "centroide":{
               "lat":-34.5941439978594,
               "lon":-58.7557346559769
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morón",
               "id":"060568"
            },
            "departamento":{
               "nombre":"Morón",
               "id":"06568"
            },
            "nombre":"CASTELAR",
            "id":"06568010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morón",
               "id":"06568010"
            },
            "centroide":{
               "lat":-34.6644131034283,
               "lon":-58.6456427123911
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morón",
               "id":"060568"
            },
            "departamento":{
               "nombre":"Morón",
               "id":"06568"
            },
            "nombre":"EL PALOMAR",
            "id":"06568010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morón",
               "id":"06568010"
            },
            "centroide":{
               "lat":-34.617707172718,
               "lon":-58.601533043415
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morón",
               "id":"060568"
            },
            "departamento":{
               "nombre":"Morón",
               "id":"06568"
            },
            "nombre":"HAEDO",
            "id":"06568010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morón",
               "id":"06568010"
            },
            "centroide":{
               "lat":-34.6440661183216,
               "lon":-58.5978176171897
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morón",
               "id":"060568"
            },
            "departamento":{
               "nombre":"Morón",
               "id":"06568"
            },
            "nombre":"MORON",
            "id":"06568010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morón",
               "id":"06568010"
            },
            "centroide":{
               "lat":-34.6652227151167,
               "lon":-58.6190557007973
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morón",
               "id":"060568"
            },
            "departamento":{
               "nombre":"Morón",
               "id":"06568"
            },
            "nombre":"VILLA SARMIENTO",
            "id":"06568010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morón",
               "id":"06568010"
            },
            "centroide":{
               "lat":-34.6346596523214,
               "lon":-58.5715757135423
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Navarro",
               "id":"060574"
            },
            "departamento":{
               "nombre":"Navarro",
               "id":"06574"
            },
            "nombre":"JOSE JUAN ALMEYRA",
            "id":"06574010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"José Juan Almeyra",
               "id":"06574010"
            },
            "centroide":{
               "lat":-34.9232643360691,
               "lon":-59.5422519447044
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Navarro",
               "id":"060574"
            },
            "departamento":{
               "nombre":"Navarro",
               "id":"06574"
            },
            "nombre":"LAS MARIANAS",
            "id":"06574020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Marianas",
               "id":"06574020"
            },
            "centroide":{
               "lat":-35.0539263781559,
               "lon":-59.5131011825281
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Navarro",
               "id":"060574"
            },
            "departamento":{
               "nombre":"Navarro",
               "id":"06574"
            },
            "nombre":"NAVARRO",
            "id":"06574030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Navarro",
               "id":"06574030"
            },
            "centroide":{
               "lat":-35.0036066018879,
               "lon":-59.2774163163898
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Navarro",
               "id":"060574"
            },
            "departamento":{
               "nombre":"Navarro",
               "id":"06574"
            },
            "nombre":"VILLA MOLL",
            "id":"06574040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Moll",
               "id":"06574040"
            },
            "centroide":{
               "lat":-35.0782490606759,
               "lon":-59.651945539681
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"CLARAZ",
            "id":"06581010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Claraz",
               "id":"06581010"
            },
            "centroide":{
               "lat":-37.8918269607856,
               "lon":-59.2866977775565
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"ENERGIA",
            "id":"06581025000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Energía",
               "id":"06581025"
            },
            "centroide":{
               "lat":-38.5580125335608,
               "lon":-59.3364566671065
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"JUAN N. FERNANDEZ",
            "id":"06581030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan N. Fernández",
               "id":"06581030"
            },
            "centroide":{
               "lat":-38.0091787783017,
               "lon":-59.2634988347821
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"NECOCHEA",
            "id":"06581040001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Necochea - Quequén",
               "id":"06581040"
            },
            "centroide":{
               "lat":-38.5711702285697,
               "lon":-58.7702419838751
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"QUEQUEN",
            "id":"06581040002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Necochea - Quequén",
               "id":"06581040"
            },
            "centroide":{
               "lat":-38.5375051784084,
               "lon":-58.6952024694735
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"COSTA BONITA",
            "id":"06581040003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Necochea - Quequén",
               "id":"06581040"
            },
            "centroide":{
               "lat":-38.5589732600706,
               "lon":-58.6585544269181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"NICANOR OLIVERA",
            "id":"06581050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Nicanor Olivera",
               "id":"06581050"
            },
            "centroide":{
               "lat":-38.2846361959919,
               "lon":-59.202754253612
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Necochea",
               "id":"060581"
            },
            "departamento":{
               "nombre":"Necochea",
               "id":"06581"
            },
            "nombre":"RAMON SANTAMARINA",
            "id":"06581060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ramón Santamarina",
               "id":"06581060"
            },
            "centroide":{
               "lat":-38.4503897333336,
               "lon":-59.3311154416146
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"ALFREDO DEMARCHI",
            "id":"06588010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Alfredo Demarchi",
               "id":"06588010"
            },
            "centroide":{
               "lat":-35.2929819536873,
               "lon":-61.4072542128
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"CARLOS MARIA NAON",
            "id":"06588020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carlos María Naón",
               "id":"06588020"
            },
            "centroide":{
               "lat":-35.239499381663,
               "lon":-60.8251358750153
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"12 DE OCTUBRE",
            "id":"06588030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"12 de Octubre",
               "id":"06588030"
            },
            "centroide":{
               "lat":-35.607730780146,
               "lon":-60.9182929927645
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"DUDIGNAC",
            "id":"06588040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Dudignac",
               "id":"06588040"
            },
            "centroide":{
               "lat":-35.6511928511123,
               "lon":-60.7098962799094
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"LA AURORA",
            "id":"06588050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Aurora",
               "id":"06588050"
            },
            "centroide":{
               "lat":-35.4079467934432,
               "lon":-61.2105668204871
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"MANUEL B. GONNET",
            "id":"06588060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Manuel B. Gonnet",
               "id":"06588060"
            },
            "centroide":{
               "lat":-35.5201414507378,
               "lon":-60.9989487976537
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"MARCELINO UGARTE",
            "id":"06588070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Marcelino Ugarte",
               "id":"06588070"
            },
            "centroide":{
               "lat":-35.3462662593318,
               "lon":-60.7453446826815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"MOREA",
            "id":"06588080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Morea",
               "id":"06588080"
            },
            "centroide":{
               "lat":-35.5563880098445,
               "lon":-60.5605940553161
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"NORUMBEGA",
            "id":"06588090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Norumbega",
               "id":"06588090"
            },
            "centroide":{
               "lat":-35.5357392707567,
               "lon":-60.7928143632315
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"9 DE JULIO",
            "id":"06588100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"9 de Julio",
               "id":"06588100"
            },
            "centroide":{
               "lat":-35.4447435053964,
               "lon":-60.8843433362719
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"PATRICIOS",
            "id":"06588110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Patricios",
               "id":"06588110"
            },
            "centroide":{
               "lat":-35.4395526972374,
               "lon":-60.7174384712323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"060588"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"06588"
            },
            "nombre":"VILLA GENERAL FOURNIER",
            "id":"06588120000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Fournier",
               "id":"06588120"
            },
            "centroide":{
               "lat":-35.4989239490928,
               "lon":-60.8647207106461
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"BLANCAGRANDE",
            "id":"06595010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Blancagrande",
               "id":"06595010"
            },
            "centroide":{
               "lat":-36.5328887082639,
               "lon":-60.8824549132602
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"COLONIA NIEVAS",
            "id":"06595030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia Nievas",
               "id":"06595030"
            },
            "centroide":{
               "lat":-36.8641370257955,
               "lon":-60.0816424704
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"COLONIA SAN MIGUEL",
            "id":"06595040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia San Miguel",
               "id":"06595040"
            },
            "centroide":{
               "lat":-36.9495659791886,
               "lon":-60.1108954083182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"ESPIGAS",
            "id":"06595050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Espigas",
               "id":"06595050"
            },
            "centroide":{
               "lat":-36.4122732396335,
               "lon":-60.6730694647
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"HINOJO",
            "id":"06595060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hinojo",
               "id":"06595060"
            },
            "centroide":{
               "lat":-36.8805120719062,
               "lon":-60.1771940245473
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"COLONIA HINOJO",
            "id":"06595060001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hinojo",
               "id":"06595060"
            },
            "centroide":{
               "lat":-36.8800694499223,
               "lon":-60.1780552970611
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"HINOJO",
            "id":"06595060002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hinojo",
               "id":"06595060"
            },
            "centroide":{
               "lat":-36.8758995951211,
               "lon":-60.1473196906487
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"OLAVARRIA",
            "id":"06595070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Olavarría",
               "id":"06595070"
            },
            "centroide":{
               "lat":-36.8920935788859,
               "lon":-60.3180046559577
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"RECALDE",
            "id":"06595080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Recalde",
               "id":"06595080"
            },
            "centroide":{
               "lat":-36.6515016172821,
               "lon":-61.0845040269815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"SANTA LUISA",
            "id":"06595090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Luisa",
               "id":"06595090"
            },
            "centroide":{
               "lat":-37.1289653693521,
               "lon":-60.4099303899854
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"SIERRA CHICA",
            "id":"06595100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierra Chica",
               "id":"06595100"
            },
            "centroide":{
               "lat":-36.843238222524,
               "lon":-60.2234271381196
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"SIERRAS BAYAS",
            "id":"06595110001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierras Bayas",
               "id":"06595110"
            },
            "centroide":{
               "lat":-36.9225238148372,
               "lon":-60.2157925414657
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"VILLA ARRIETA",
            "id":"06595110002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierras Bayas",
               "id":"06595110"
            },
            "centroide":{
               "lat":-36.9436605372421,
               "lon":-60.1578271825148
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"VILLA ALFREDO FORTABAT",
            "id":"06595120000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Alfredo Fortabat",
               "id":"06595120"
            },
            "centroide":{
               "lat":-36.9802714637124,
               "lon":-60.2790828437139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olavarría",
               "id":"060595"
            },
            "departamento":{
               "nombre":"Olavarría",
               "id":"06595"
            },
            "nombre":"VILLA LA SERRANIA",
            "id":"06595130000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa La Serranía",
               "id":"06595130"
            },
            "centroide":{
               "lat":-36.9903632120511,
               "lon":-60.3108081181748
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"BAHIA SAN BLAS",
            "id":"06602010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bahía San Blas",
               "id":"06602010"
            },
            "centroide":{
               "lat":-40.5602246137406,
               "lon":-62.2380643077459
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"CARDENAL CAGLIERO",
            "id":"06602020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cardenal Cagliero",
               "id":"06602020"
            },
            "centroide":{
               "lat":-40.6522000086693,
               "lon":-62.7575972084464
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"CARMEN DE PATAGONES",
            "id":"06602030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carmen de Patagones",
               "id":"06602030"
            },
            "centroide":{
               "lat":-40.797298324401,
               "lon":-62.984754269904
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"JOSE B. CASAS",
            "id":"06602040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"José B. Casas",
               "id":"06602040"
            },
            "centroide":{
               "lat":-40.4363643935514,
               "lon":-62.5449343513868
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"JUAN A. PRADERE",
            "id":"06602050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan A. Pradere",
               "id":"06602050"
            },
            "centroide":{
               "lat":-39.5993801239416,
               "lon":-62.6510470779021
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"STROEDER",
            "id":"06602060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Stroeder",
               "id":"06602060"
            },
            "centroide":{
               "lat":-40.1854869993736,
               "lon":-62.6205163469862
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Patagones",
               "id":"060602"
            },
            "departamento":{
               "nombre":"Patagones",
               "id":"06602"
            },
            "nombre":"VILLALONGA",
            "id":"06602070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villalonga",
               "id":"06602070"
            },
            "centroide":{
               "lat":-39.9151387867695,
               "lon":-62.6188702678852
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"CAPITAN CASTRO",
            "id":"06609010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Capitán Castro",
               "id":"06609010"
            },
            "centroide":{
               "lat":-35.913692884071,
               "lon":-62.2240579143551
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"CHICLANA",
            "id":"06609020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Esteban",
               "id":"06609020"
            },
            "centroide":{
               "lat":-35.7327373193414,
               "lon":-61.7439272931387
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"FRANCISCO MADERO",
            "id":"06609030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Francisco Madero",
               "id":"06609030"
            },
            "centroide":{
               "lat":-35.8483065194137,
               "lon":-62.0693918423117
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"INOCENCIO SOSA",
            "id":"06609035000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Inocencio Sosa",
               "id":"06609035"
            },
            "centroide":{
               "lat":-35.7184196474894,
               "lon":-62.1142777365421
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"JUAN JOSE PASO",
            "id":"06609040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan José Paso",
               "id":"06609040"
            },
            "centroide":{
               "lat":-35.8524740557321,
               "lon":-62.2962123802194
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"MAGDALA",
            "id":"06609050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Magdala",
               "id":"06609050"
            },
            "centroide":{
               "lat":-36.0846707903374,
               "lon":-61.7254747841859
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"MONES CAZON",
            "id":"06609060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mones Cazón",
               "id":"06609060"
            },
            "centroide":{
               "lat":-36.2301776476091,
               "lon":-62.0069944091925
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"NUEVA PLATA",
            "id":"06609070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Nueva Plata",
               "id":"06609070"
            },
            "centroide":{
               "lat":-35.9199117438484,
               "lon":-61.8133995164977
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"PEHUAJO",
            "id":"06609080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pehuajó",
               "id":"06609080"
            },
            "centroide":{
               "lat":-35.8123030689596,
               "lon":-61.8988207561037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pehuajó",
               "id":"060609"
            },
            "departamento":{
               "nombre":"Pehuajó",
               "id":"06609"
            },
            "nombre":"SAN BERNARDO",
            "id":"06609090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Bernardo",
               "id":"06609090"
            },
            "centroide":{
               "lat":-35.7137365347985,
               "lon":-61.6477830181101
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pellegrini",
               "id":"060616"
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"06616"
            },
            "nombre":"BOCAYUVA",
            "id":"06616010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bocayuva",
               "id":"06616010"
            },
            "centroide":{
               "lat":-36.2065730139324,
               "lon":-63.0761554731554
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pellegrini",
               "id":"060616"
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"06616"
            },
            "nombre":"DE BARY",
            "id":"06616020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"De Bary",
               "id":"06616020"
            },
            "centroide":{
               "lat":-36.3416806655424,
               "lon":-63.2611533171506
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pellegrini",
               "id":"060616"
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"06616"
            },
            "nombre":"PELLEGRINI",
            "id":"06616030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pellegrini",
               "id":"06616030"
            },
            "centroide":{
               "lat":-36.2697352282386,
               "lon":-63.1652861097017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"ACEVEDO",
            "id":"06623010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Acevedo",
               "id":"06623010"
            },
            "centroide":{
               "lat":-33.755665348987,
               "lon":-60.4408373865386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"FONTEZUELA",
            "id":"06623020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Fontezuela",
               "id":"06623020"
            },
            "centroide":{
               "lat":-33.9138392371386,
               "lon":-60.4628650932084
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"GUERRICO",
            "id":"06623030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Guerrico",
               "id":"06623030"
            },
            "centroide":{
               "lat":-33.6745849638169,
               "lon":-60.4006865938611
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"JUAN A. DE LA PE¥A",
            "id":"06623040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan A. de la Peña",
               "id":"06623040"
            },
            "centroide":{
               "lat":-33.8322767381075,
               "lon":-60.4864895356948
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"JUAN ANCHORENA",
            "id":"06623050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan Anchorena",
               "id":"06623050"
            },
            "centroide":{
               "lat":-33.9263378592898,
               "lon":-60.3829034809436
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"LA VIOLETA",
            "id":"06623060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Violeta",
               "id":"06623060"
            },
            "centroide":{
               "lat":-33.7347851403163,
               "lon":-60.1701773086428
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"MANUEL OCAMPO",
            "id":"06623070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Manuel Ocampo",
               "id":"06623070"
            },
            "centroide":{
               "lat":-33.7647232673599,
               "lon":-60.6492429333859
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"MARIANO BENITEZ",
            "id":"06623080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mariano Benítez",
               "id":"06623080"
            },
            "centroide":{
               "lat":-33.7090270411103,
               "lon":-60.5842599045876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"MARIANO H. ALFONZO",
            "id":"06623090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mariano H. Alfonzo",
               "id":"06623090"
            },
            "centroide":{
               "lat":-33.9149979004605,
               "lon":-60.8383550657724
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"PERGAMINO",
            "id":"06623100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pergamino",
               "id":"06623100"
            },
            "centroide":{
               "lat":-33.8949900563191,
               "lon":-60.5716400794952
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"PINZON",
            "id":"06623110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pinzón",
               "id":"06623110"
            },
            "centroide":{
               "lat":-33.9947211174052,
               "lon":-60.7316480040989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"RANCAGUA",
            "id":"06623120000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rancagua",
               "id":"06623120"
            },
            "centroide":{
               "lat":-34.0303722475767,
               "lon":-60.5042327001336
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"VILLA ANGELICA",
            "id":"06623130000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Angélica",
               "id":"06623130"
            },
            "centroide":{
               "lat":-33.6647037443081,
               "lon":-60.7084372635626
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pergamino",
               "id":"060623"
            },
            "departamento":{
               "nombre":"Pergamino",
               "id":"06623"
            },
            "nombre":"VILLA SAN JOSE",
            "id":"06623140000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa San José",
               "id":"06623140"
            },
            "centroide":{
               "lat":-34.0906695795004,
               "lon":-60.4162716627136
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pila",
               "id":"060630"
            },
            "departamento":{
               "nombre":"Pila",
               "id":"06630"
            },
            "nombre":"CASALINS",
            "id":"06630010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Casalins",
               "id":"06630010"
            },
            "centroide":{
               "lat":-36.3181419923689,
               "lon":-58.5525025522873
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pila",
               "id":"060630"
            },
            "departamento":{
               "nombre":"Pila",
               "id":"06630"
            },
            "nombre":"PILA",
            "id":"06630020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pila",
               "id":"06630020"
            },
            "centroide":{
               "lat":-36.0014746137723,
               "lon":-58.1427778873885
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"DEL VISO",
            "id":"06638040001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4494009456502,
               "lon":-58.8009119728306
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"FATIMA",
            "id":"06638040002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4072575836787,
               "lon":-58.974691291144
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"LA LONJA",
            "id":"06638040003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4448149403448,
               "lon":-58.8393730861805
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"LOS CACHORROS",
            "id":"06638040004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4250028270043,
               "lon":-58.7950003996087
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"MANZANARES",
            "id":"06638040005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.452630932571,
               "lon":-59.0136606624887
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"MANZONE",
            "id":"06638040006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.5287308766458,
               "lon":-58.8775995091498
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"MAQUINISTA F. SAVIO (OESTE)",
            "id":"06638040007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.410617254736,
               "lon":-58.7821701089413
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"PILAR",
            "id":"06638040008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4663318396358,
               "lon":-58.9227197887079
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"PRESIDENTE DERQUI",
            "id":"06638040009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4928616935261,
               "lon":-58.8416985218723
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"ROBERTO DE VICENZO",
            "id":"06638040010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4369652784326,
               "lon":-58.7669941785147
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"SANTA TERESA",
            "id":"06638040011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4366914158447,
               "lon":-58.7573470164393
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"TORTUGUITAS",
            "id":"06638040012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4578061717121,
               "lon":-58.76209072062
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"VILLA ASTOLFI",
            "id":"06638040013",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4858983446269,
               "lon":-58.8768335703823
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"VILLA ROSA",
            "id":"06638040014",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.4077327133185,
               "lon":-58.8539345779841
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"060638"
            },
            "departamento":{
               "nombre":"Pilar",
               "id":"06638"
            },
            "nombre":"ZELAYA",
            "id":"06638040015",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"06638040"
            },
            "centroide":{
               "lat":-34.3657182841794,
               "lon":-58.893696088678
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pinamar",
               "id":"060644"
            },
            "departamento":{
               "nombre":"Pinamar",
               "id":"06644"
            },
            "nombre":"CARILO",
            "id":"06644010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pinamar",
               "id":"06644010"
            },
            "centroide":{
               "lat":-37.1641593745618,
               "lon":-56.9027914378095
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pinamar",
               "id":"060644"
            },
            "departamento":{
               "nombre":"Pinamar",
               "id":"06644"
            },
            "nombre":"OSTENDE",
            "id":"06644010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pinamar",
               "id":"06644010"
            },
            "centroide":{
               "lat":-37.1300477581888,
               "lon":-56.8833984697828
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pinamar",
               "id":"060644"
            },
            "departamento":{
               "nombre":"Pinamar",
               "id":"06644"
            },
            "nombre":"PINAMAR",
            "id":"06644010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pinamar",
               "id":"06644010"
            },
            "centroide":{
               "lat":-37.0777448860202,
               "lon":-56.8437227435213
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pinamar",
               "id":"060644"
            },
            "departamento":{
               "nombre":"Pinamar",
               "id":"06644"
            },
            "nombre":"VALERIA DEL MAR",
            "id":"06644010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pinamar",
               "id":"06644010"
            },
            "centroide":{
               "lat":-37.1445557771803,
               "lon":-56.890291998877
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidente Perón",
               "id":"060648"
            },
            "departamento":{
               "nombre":"Presidente Perón",
               "id":"06648"
            },
            "nombre":"BARRIO AMERICA UNIDA",
            "id":"06648010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Presidente Perón",
               "id":"06648010"
            },
            "centroide":{
               "lat":-34.886197581853,
               "lon":-58.41733483734
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidente Perón",
               "id":"060648"
            },
            "departamento":{
               "nombre":"Presidente Perón",
               "id":"06648"
            },
            "nombre":"GUERNICA",
            "id":"06648010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Presidente Perón",
               "id":"06648010"
            },
            "centroide":{
               "lat":-34.9230592108536,
               "lon":-58.3650272662643
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"AZOPARDO",
            "id":"06651010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Azopardo",
               "id":"06651010"
            },
            "centroide":{
               "lat":-37.7017920523646,
               "lon":-62.9006764415683
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"BORDENAVE",
            "id":"06651020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Bordenave",
               "id":"06651020"
            },
            "centroide":{
               "lat":-37.8028181597411,
               "lon":-63.0425144041162
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"DARREGUEIRA",
            "id":"06651030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Darregueira",
               "id":"06651030"
            },
            "centroide":{
               "lat":-37.6857562622736,
               "lon":-63.1595663902141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"17 DE AGOSTO",
            "id":"06651040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"17 de Agosto",
               "id":"06651040"
            },
            "centroide":{
               "lat":-37.9086924428976,
               "lon":-62.9360284730803
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"ESTELA",
            "id":"06651050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Estela",
               "id":"06651050"
            },
            "centroide":{
               "lat":-38.1080950084855,
               "lon":-62.9129041375994
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"FELIPE SOLA",
            "id":"06651060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Felipe Solá",
               "id":"06651060"
            },
            "centroide":{
               "lat":-38.005992834739,
               "lon":-62.8186936682637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"LOPEZ LECUBE",
            "id":"06651070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"López Lecube",
               "id":"06651070"
            },
            "centroide":{
               "lat":-38.1171190117962,
               "lon":-62.7254849373447
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"PUAN",
            "id":"06651080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Puán",
               "id":"06651080"
            },
            "centroide":{
               "lat":-37.542613502113,
               "lon":-62.7652275731166
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"SAN GERMAN",
            "id":"06651090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Germán",
               "id":"06651090"
            },
            "centroide":{
               "lat":-38.2995183581309,
               "lon":-62.9821877089624
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"VILLA CASTELAR",
            "id":"06651100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Castelar",
               "id":"06651100"
            },
            "centroide":{
               "lat":-37.3905464925651,
               "lon":-62.8051325654187
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puán",
               "id":"060651"
            },
            "departamento":{
               "nombre":"Puán",
               "id":"06651"
            },
            "nombre":"VILLA IRIS",
            "id":"06651110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Iris",
               "id":"06651110"
            },
            "centroide":{
               "lat":-38.1693097057753,
               "lon":-63.2320511809102
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Punta Indio",
               "id":"060655"
            },
            "departamento":{
               "nombre":"Punta Indio",
               "id":"06655"
            },
            "nombre":"ALVAREZ JONTE",
            "id":"06655010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Alvarez Jonte",
               "id":"06655010"
            },
            "centroide":{
               "lat":-35.3227800092544,
               "lon":-57.4490768504218
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Punta Indio",
               "id":"060655"
            },
            "departamento":{
               "nombre":"Punta Indio",
               "id":"06655"
            },
            "nombre":"PIPINAS",
            "id":"06655030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pipinas",
               "id":"06655030"
            },
            "centroide":{
               "lat":-35.53126706917,
               "lon":-57.3285887617048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Punta Indio",
               "id":"060655"
            },
            "departamento":{
               "nombre":"Punta Indio",
               "id":"06655"
            },
            "nombre":"PUNTA INDIO",
            "id":"06655040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Punta Indio",
               "id":"06655040"
            },
            "centroide":{
               "lat":-35.2808352898089,
               "lon":-57.2360072391524
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Punta Indio",
               "id":"060655"
            },
            "departamento":{
               "nombre":"Punta Indio",
               "id":"06655"
            },
            "nombre":"VERONICA",
            "id":"06655050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Verónica",
               "id":"06655050"
            },
            "centroide":{
               "lat":-35.3881552142501,
               "lon":-57.3371601623743
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"BERNAL",
            "id":"06658010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.700378406023,
               "lon":-58.2766427817003
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"BERNAL OESTE",
            "id":"06658010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7269644246033,
               "lon":-58.3182795621594
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"DON BOSCO",
            "id":"06658010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7032130159372,
               "lon":-58.2984533178872
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"EZPELETA",
            "id":"06658010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7435644425127,
               "lon":-58.2281408361207
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"EZPELETA OESTE",
            "id":"06658010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7634442077448,
               "lon":-58.2638314351646
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"QUILMES",
            "id":"06658010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7241344748224,
               "lon":-58.2492205541766
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"QUILMES OESTE",
            "id":"06658010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7477989892391,
               "lon":-58.2999440151876
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"SAN FRANCISCO SOLANO",
            "id":"06658010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7777730726874,
               "lon":-58.3101561253692
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes",
               "id":"060658"
            },
            "departamento":{
               "nombre":"Quilmes",
               "id":"06658"
            },
            "nombre":"VILLA LA FLORIDA",
            "id":"06658010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quilmes",
               "id":"06658010"
            },
            "centroide":{
               "lat":-34.7688841339243,
               "lon":-58.2957092489115
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramallo",
               "id":"060665"
            },
            "departamento":{
               "nombre":"Ramallo",
               "id":"06665"
            },
            "nombre":"EL PARAISO",
            "id":"06665010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"El Paraíso",
               "id":"06665010"
            },
            "centroide":{
               "lat":-33.5679152654445,
               "lon":-59.9791123483107
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramallo",
               "id":"060665"
            },
            "departamento":{
               "nombre":"Ramallo",
               "id":"06665"
            },
            "nombre":"LAS BAHAMAS",
            "id":"06665020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Bahamas",
               "id":"06665020"
            },
            "centroide":{
               "lat":-33.6366100825527,
               "lon":-59.9895563465816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramallo",
               "id":"060665"
            },
            "departamento":{
               "nombre":"Ramallo",
               "id":"06665"
            },
            "nombre":"PEREZ MILLAN",
            "id":"06665030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pérez Millán",
               "id":"06665030"
            },
            "centroide":{
               "lat":-33.7674497804832,
               "lon":-60.0927343667154
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramallo",
               "id":"060665"
            },
            "departamento":{
               "nombre":"Ramallo",
               "id":"06665"
            },
            "nombre":"RAMALLO",
            "id":"06665040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ramallo",
               "id":"06665040"
            },
            "centroide":{
               "lat":-33.4877021169888,
               "lon":-60.0072209741607
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramallo",
               "id":"060665"
            },
            "departamento":{
               "nombre":"Ramallo",
               "id":"06665"
            },
            "nombre":"VILLA GENERAL SAVIO",
            "id":"06665050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa General Savio",
               "id":"06665050"
            },
            "centroide":{
               "lat":-33.4352672179447,
               "lon":-60.1451316522293
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramallo",
               "id":"060665"
            },
            "departamento":{
               "nombre":"Ramallo",
               "id":"06665"
            },
            "nombre":"VILLA RAMALLO",
            "id":"06665060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Ramallo",
               "id":"06665060"
            },
            "centroide":{
               "lat":-33.502293213764,
               "lon":-60.0650138035308
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rauch",
               "id":"060672"
            },
            "departamento":{
               "nombre":"Rauch",
               "id":"06672"
            },
            "nombre":"RAUCH",
            "id":"06672010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rauch",
               "id":"06672010"
            },
            "centroide":{
               "lat":-36.7755035485732,
               "lon":-59.0871114029954
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"AMERICA",
            "id":"06679010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"América",
               "id":"06679010"
            },
            "centroide":{
               "lat":-35.4903412429492,
               "lon":-62.9763759840765
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"FORTIN OLAVARRIA",
            "id":"06679020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Fortín Olavarría",
               "id":"06679020"
            },
            "centroide":{
               "lat":-35.7048848499519,
               "lon":-63.0230332752501
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"GONZALEZ MORENO",
            "id":"06679030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"González Moreno",
               "id":"06679030"
            },
            "centroide":{
               "lat":-35.5576529256075,
               "lon":-63.3815800402317
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"MIRA PAMPA",
            "id":"06679040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mira Pampa",
               "id":"06679040"
            },
            "centroide":{
               "lat":-35.8703867348478,
               "lon":-63.3742010687724
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"ROOSEVELT",
            "id":"06679050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Roosevelt",
               "id":"06679050"
            },
            "centroide":{
               "lat":-35.8466320155134,
               "lon":-63.2898118317476
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"SAN MAURICIO",
            "id":"06679060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Mauricio",
               "id":"06679060"
            },
            "centroide":{
               "lat":-35.5118022821456,
               "lon":-63.1882154861262
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"SANSINENA",
            "id":"06679070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sansinena",
               "id":"06679070"
            },
            "centroide":{
               "lat":-35.2750295400617,
               "lon":-63.2135436741919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"060679"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"06679"
            },
            "nombre":"SUNDBLAD",
            "id":"06679080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sundblad",
               "id":"06679080"
            },
            "centroide":{
               "lat":-35.7656416636683,
               "lon":-63.1386100973601
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"LA BEBA",
            "id":"06686010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Beba",
               "id":"06686010"
            },
            "centroide":{
               "lat":-34.1544387870912,
               "lon":-61.0128711169321
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"LAS CARABELAS",
            "id":"06686020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Carabelas",
               "id":"06686020"
            },
            "centroide":{
               "lat":-34.0381002651925,
               "lon":-60.8685264174218
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"LOS INDIOS",
            "id":"06686030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Los Indios",
               "id":"06686030"
            },
            "centroide":{
               "lat":-34.3735696107802,
               "lon":-60.6523710265627
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"RAFAEL OBLIGADO",
            "id":"06686040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rafael Obligado",
               "id":"06686040"
            },
            "centroide":{
               "lat":-34.3588132488617,
               "lon":-60.7845693256007
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"ROBERTO CANO",
            "id":"06686050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Roberto Cano",
               "id":"06686050"
            },
            "centroide":{
               "lat":-34.0876515179763,
               "lon":-60.6672564006968
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"ROJAS",
            "id":"06686060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rojas",
               "id":"06686060"
            },
            "centroide":{
               "lat":-34.1961600446015,
               "lon":-60.7332636467146
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"BARRIO LAS MARGARITAS",
            "id":"06686060001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rojas",
               "id":"06686060"
            },
            "centroide":{
               "lat":-34.1932374145653,
               "lon":-60.6820264999894
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"ROJAS",
            "id":"06686060002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rojas",
               "id":"06686060"
            },
            "centroide":{
               "lat":-34.1872292263523,
               "lon":-60.7179411923956
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"VILLA PARQUE CECIR",
            "id":"06686060003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rojas",
               "id":"06686060"
            },
            "centroide":{
               "lat":-34.1707175989305,
               "lon":-60.7438336816558
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"ESTACION SOL DE MAYO",
            "id":"06686070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sol de Mayo",
               "id":"06686070"
            },
            "centroide":{
               "lat":-34.2689448512236,
               "lon":-60.8717773420981
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rojas",
               "id":"060686"
            },
            "departamento":{
               "nombre":"Rojas",
               "id":"06686"
            },
            "nombre":"VILLA MANUEL POMAR",
            "id":"06686080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Manuel Pomar",
               "id":"06686080"
            },
            "centroide":{
               "lat":-33.9159508805463,
               "lon":-60.9379380238883
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Roque Pérez",
               "id":"060693"
            },
            "departamento":{
               "nombre":"Roque Pérez",
               "id":"06693"
            },
            "nombre":"CARLOS BEGUERIE",
            "id":"06693010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carlos Beguerie",
               "id":"06693010"
            },
            "centroide":{
               "lat":-35.4854690457283,
               "lon":-59.1017155978911
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Roque Pérez",
               "id":"060693"
            },
            "departamento":{
               "nombre":"Roque Pérez",
               "id":"06693"
            },
            "nombre":"ROQUE PEREZ",
            "id":"06693020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Roque Pérez",
               "id":"06693020"
            },
            "centroide":{
               "lat":-35.4016375824204,
               "lon":-59.3346857845207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"ARROYO CORTO",
            "id":"06700010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arroyo Corto",
               "id":"06700010"
            },
            "centroide":{
               "lat":-37.512917973477,
               "lon":-62.3116837747426
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"COLONIA SAN MARTIN",
            "id":"06700020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia San Martín",
               "id":"06700020"
            },
            "centroide":{
               "lat":-37.9769152091143,
               "lon":-62.3326095560459
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"DUFAUR",
            "id":"06700030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Dufaur",
               "id":"06700030"
            },
            "centroide":{
               "lat":-37.9428223013646,
               "lon":-62.284864272698
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"ESPARTILLAR (E)",
            "id":"06700040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Espartillar",
               "id":"06700040"
            },
            "centroide":{
               "lat":-37.3604343942367,
               "lon":-62.4297386825705
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"GOYENA",
            "id":"06700050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Goyena",
               "id":"06700050"
            },
            "centroide":{
               "lat":-37.7192518502325,
               "lon":-62.6071296330023
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"LAS ENCADENADAS",
            "id":"06700055000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Las Encadenadas",
               "id":"06700055"
            },
            "centroide":{
               "lat":-38.0361082716296,
               "lon":-62.4704140736323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"PIGUE",
            "id":"06700060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pigüé",
               "id":"06700060"
            },
            "centroide":{
               "lat":-37.6063896033626,
               "lon":-62.4057728759143
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saavedra",
               "id":"060700"
            },
            "departamento":{
               "nombre":"Saavedra",
               "id":"06700"
            },
            "nombre":"SAAVEDRA",
            "id":"06700070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Saavedra",
               "id":"06700070"
            },
            "centroide":{
               "lat":-37.7636402199499,
               "lon":-62.3506328041255
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"060707"
            },
            "departamento":{
               "nombre":"Saladillo",
               "id":"06707"
            },
            "nombre":"ALVAREZ DE TOLEDO",
            "id":"06707010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Álvarez de Toledo",
               "id":"06707010"
            },
            "centroide":{
               "lat":-35.6391609182127,
               "lon":-59.6292383440582
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"060707"
            },
            "departamento":{
               "nombre":"Saladillo",
               "id":"06707"
            },
            "nombre":"CAZON",
            "id":"06707030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cazón",
               "id":"06707030"
            },
            "centroide":{
               "lat":-35.576803202395,
               "lon":-59.6645037628687
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"060707"
            },
            "departamento":{
               "nombre":"Saladillo",
               "id":"06707"
            },
            "nombre":"DEL CARRIL",
            "id":"06707040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Del Carril",
               "id":"06707040"
            },
            "centroide":{
               "lat":-35.5127172285926,
               "lon":-59.5158202035334
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"060707"
            },
            "departamento":{
               "nombre":"Saladillo",
               "id":"06707"
            },
            "nombre":"POLVAREDAS",
            "id":"06707050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Polvaredas",
               "id":"06707050"
            },
            "centroide":{
               "lat":-35.593907231155,
               "lon":-59.5079734849201
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"060707"
            },
            "departamento":{
               "nombre":"Saladillo",
               "id":"06707"
            },
            "nombre":"SALADILLO",
            "id":"06707060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Saladillo",
               "id":"06707060"
            },
            "centroide":{
               "lat":-35.6404298805989,
               "lon":-59.7790589845354
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto",
               "id":"060714"
            },
            "departamento":{
               "nombre":"Salto",
               "id":"06714"
            },
            "nombre":"ARROYO DULCE",
            "id":"06714010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Arroyo Dulce",
               "id":"06714010"
            },
            "centroide":{
               "lat":-34.1024332833136,
               "lon":-60.4061551794069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto",
               "id":"060714"
            },
            "departamento":{
               "nombre":"Salto",
               "id":"06714"
            },
            "nombre":"BERDIER",
            "id":"06714020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berdier",
               "id":"06714020"
            },
            "centroide":{
               "lat":-34.3987726587976,
               "lon":-60.2605725409335
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto",
               "id":"060714"
            },
            "departamento":{
               "nombre":"Salto",
               "id":"06714"
            },
            "nombre":"GAHAN",
            "id":"06714030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gahan",
               "id":"06714030"
            },
            "centroide":{
               "lat":-34.3380744009945,
               "lon":-60.0992736191527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto",
               "id":"060714"
            },
            "departamento":{
               "nombre":"Salto",
               "id":"06714"
            },
            "nombre":"INES INDART",
            "id":"06714040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Inés Indart",
               "id":"06714040"
            },
            "centroide":{
               "lat":-34.3994163305262,
               "lon":-60.5436825925267
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto",
               "id":"060714"
            },
            "departamento":{
               "nombre":"Salto",
               "id":"06714"
            },
            "nombre":"LA INVENCIBLE",
            "id":"06714050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Invencible",
               "id":"06714050"
            },
            "centroide":{
               "lat":-34.2687422037239,
               "lon":-60.3853645216588
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto",
               "id":"060714"
            },
            "departamento":{
               "nombre":"Salto",
               "id":"06714"
            },
            "nombre":"SALTO",
            "id":"06714060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Salto",
               "id":"06714060"
            },
            "centroide":{
               "lat":-34.2921598652878,
               "lon":-60.2546244496406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salliqueló",
               "id":"060721"
            },
            "departamento":{
               "nombre":"Salliqueló",
               "id":"06721"
            },
            "nombre":"QUENUMA",
            "id":"06721010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Quenumá",
               "id":"06721010"
            },
            "centroide":{
               "lat":-36.5689908284906,
               "lon":-63.0876229573465
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salliqueló",
               "id":"060721"
            },
            "departamento":{
               "nombre":"Salliqueló",
               "id":"06721"
            },
            "nombre":"SALLIQUELO",
            "id":"06721020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Salliqueló",
               "id":"06721020"
            },
            "centroide":{
               "lat":-36.7511653813281,
               "lon":-62.9599573443115
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"AZCUENAGA",
            "id":"06728010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Azcuénaga",
               "id":"06728010"
            },
            "centroide":{
               "lat":-34.3637910907784,
               "lon":-59.3745991110996
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"CULULLU",
            "id":"06728020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Cucullú",
               "id":"06728020"
            },
            "centroide":{
               "lat":-34.4460870678,
               "lon":-59.3622553297801
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"FRANKLIN",
            "id":"06728030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Franklin",
               "id":"06728030"
            },
            "centroide":{
               "lat":-34.6103908719397,
               "lon":-59.6301795182439
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"SAN ANDRES DE GILES",
            "id":"06728040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Andrés de Giles",
               "id":"06728040"
            },
            "centroide":{
               "lat":-34.4459080814748,
               "lon":-59.4451673563949
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"SOLIS",
            "id":"06728050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Solís",
               "id":"06728050"
            },
            "centroide":{
               "lat":-34.2989712949513,
               "lon":-59.3250547278241
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"VILLA ESPIL",
            "id":"06728060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Espil",
               "id":"06728060"
            },
            "centroide":{
               "lat":-34.5066814044811,
               "lon":-59.3372661316763
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andrés de Giles",
               "id":"060728"
            },
            "departamento":{
               "nombre":"San Andrés de Giles",
               "id":"06728"
            },
            "nombre":"VILLA RUIZ",
            "id":"06728070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Ruiz",
               "id":"06728070"
            },
            "centroide":{
               "lat":-34.4352021828488,
               "lon":-59.260395880426
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Areco",
               "id":"060735"
            },
            "departamento":{
               "nombre":"San Antonio de Areco",
               "id":"06735"
            },
            "nombre":"DUGGAN",
            "id":"06735010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Duggan",
               "id":"06735010"
            },
            "centroide":{
               "lat":-34.2071973030208,
               "lon":-59.6357055338083
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Areco",
               "id":"060735"
            },
            "departamento":{
               "nombre":"San Antonio de Areco",
               "id":"06735"
            },
            "nombre":"SAN ANTONIO DE ARECO",
            "id":"06735020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Antonio de Areco",
               "id":"06735020"
            },
            "centroide":{
               "lat":-34.2503763113877,
               "lon":-59.4708634797005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Areco",
               "id":"060735"
            },
            "departamento":{
               "nombre":"San Antonio de Areco",
               "id":"06735"
            },
            "nombre":"VILLA LIA",
            "id":"06735030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Lía",
               "id":"06735030"
            },
            "centroide":{
               "lat":-34.1237740718192,
               "lon":-59.4313725724126
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Cayetano",
               "id":"060742"
            },
            "departamento":{
               "nombre":"San Cayetano",
               "id":"06742"
            },
            "nombre":"BALNEARIO SAN CAYETANO",
            "id":"06742010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Balneario San Cayetano",
               "id":"06742010"
            },
            "centroide":{
               "lat":-38.7481815016452,
               "lon":-59.429204589717
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Cayetano",
               "id":"060742"
            },
            "departamento":{
               "nombre":"San Cayetano",
               "id":"06742"
            },
            "nombre":"OCHANDIO",
            "id":"06742020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ochandío",
               "id":"06742020"
            },
            "centroide":{
               "lat":-38.3598244506044,
               "lon":-59.7935349347064
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Cayetano",
               "id":"060742"
            },
            "departamento":{
               "nombre":"San Cayetano",
               "id":"06742"
            },
            "nombre":"SAN CAYETANO",
            "id":"06742030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Cayetano",
               "id":"06742030"
            },
            "centroide":{
               "lat":-38.346970946847,
               "lon":-59.6063826058006
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Fernando",
               "id":"060749"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"06749"
            },
            "nombre":"SAN FERNANDO",
            "id":"06749010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Fernando",
               "id":"06749010"
            },
            "centroide":{
               "lat":-34.4473601745411,
               "lon":-58.5690015916776
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Fernando",
               "id":"060749"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"06749"
            },
            "nombre":"VICTORIA",
            "id":"06749010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Fernando",
               "id":"06749010"
            },
            "centroide":{
               "lat":-34.4619820050413,
               "lon":-58.5595970877081
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Fernando",
               "id":"060749"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"06749"
            },
            "nombre":"VIRREYES",
            "id":"06749010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Fernando",
               "id":"06749010"
            },
            "centroide":{
               "lat":-34.4636240092728,
               "lon":-58.5761941633194
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Isidro",
               "id":"060756"
            },
            "departamento":{
               "nombre":"San Isidro",
               "id":"06756"
            },
            "nombre":"ACASSUSO",
            "id":"06756010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"06756010"
            },
            "centroide":{
               "lat":-34.4794018120083,
               "lon":-58.5074043996961
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Isidro",
               "id":"060756"
            },
            "departamento":{
               "nombre":"San Isidro",
               "id":"06756"
            },
            "nombre":"BECCAR",
            "id":"06756010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"06756010"
            },
            "centroide":{
               "lat":-34.4674159462571,
               "lon":-58.5400343502635
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Isidro",
               "id":"060756"
            },
            "departamento":{
               "nombre":"San Isidro",
               "id":"06756"
            },
            "nombre":"BOULOGNE SUR MER",
            "id":"06756010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"06756010"
            },
            "centroide":{
               "lat":-34.4983312888498,
               "lon":-58.5700137209729
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Isidro",
               "id":"060756"
            },
            "departamento":{
               "nombre":"San Isidro",
               "id":"06756"
            },
            "nombre":"MARTINEZ",
            "id":"06756010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"06756010"
            },
            "centroide":{
               "lat":-34.4944230772436,
               "lon":-58.5105732755943
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Isidro",
               "id":"060756"
            },
            "departamento":{
               "nombre":"San Isidro",
               "id":"06756"
            },
            "nombre":"SAN ISIDRO",
            "id":"06756010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"06756010"
            },
            "centroide":{
               "lat":-34.4770412162141,
               "lon":-58.52719519996
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Isidro",
               "id":"060756"
            },
            "departamento":{
               "nombre":"San Isidro",
               "id":"06756"
            },
            "nombre":"VILLA ADELINA",
            "id":"06756010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"06756010"
            },
            "centroide":{
               "lat":-34.5151197673508,
               "lon":-58.5457579220177
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Miguel",
               "id":"060760"
            },
            "departamento":{
               "nombre":"San Miguel",
               "id":"06760"
            },
            "nombre":"BELLA VISTA",
            "id":"06760010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"06760010"
            },
            "centroide":{
               "lat":-34.5751401519414,
               "lon":-58.6960409885544
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Miguel",
               "id":"060760"
            },
            "departamento":{
               "nombre":"San Miguel",
               "id":"06760"
            },
            "nombre":"CAMPO DE MAYO",
            "id":"06760010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"06760010"
            },
            "centroide":{
               "lat":-34.5346818189868,
               "lon":-58.6513860167431
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Miguel",
               "id":"060760"
            },
            "departamento":{
               "nombre":"San Miguel",
               "id":"06760"
            },
            "nombre":"MUÑIZ",
            "id":"06760010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"06760010"
            },
            "centroide":{
               "lat":-34.5557351629874,
               "lon":-58.7080227572424
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Miguel",
               "id":"060760"
            },
            "departamento":{
               "nombre":"San Miguel",
               "id":"06760"
            },
            "nombre":"SAN MIGUEL",
            "id":"06760010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"06760010"
            },
            "centroide":{
               "lat":-34.5536228677773,
               "lon":-58.7347875195953
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"CONESA",
            "id":"06763010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Conesa",
               "id":"06763010"
            },
            "centroide":{
               "lat":-33.5962124822607,
               "lon":-60.3541264450858
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"EREZCANO",
            "id":"06763020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Erezcano",
               "id":"06763020"
            },
            "centroide":{
               "lat":-33.5232449410318,
               "lon":-60.3174454958625
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"GENERAL ROJO",
            "id":"06763030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rojo",
               "id":"06763030"
            },
            "centroide":{
               "lat":-33.4758683523604,
               "lon":-60.2874270636731
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"LA EMILIA",
            "id":"06763040001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Emilia",
               "id":"06763040"
            },
            "centroide":{
               "lat":-33.3514852802661,
               "lon":-60.3139573529074
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"VILLA CAMPI",
            "id":"06763040002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Emilia",
               "id":"06763040"
            },
            "centroide":{
               "lat":-33.3599587304228,
               "lon":-60.3000892882903
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"VILLA CANTO",
            "id":"06763040003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Emilia",
               "id":"06763040"
            },
            "centroide":{
               "lat":-33.3708479748,
               "lon":-60.2954116362263
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"VILLA RICCIO",
            "id":"06763040004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Emilia",
               "id":"06763040"
            },
            "centroide":{
               "lat":-33.3630498059477,
               "lon":-60.2953990898749
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"CAMPOS SALLES",
            "id":"06763050001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Nicolás de los Arroyos",
               "id":"06763050"
            },
            "centroide":{
               "lat":-33.4010837029564,
               "lon":-60.2846972878391
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"SAN NICOLAS DE LOS ARROYOS",
            "id":"06763050002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Nicolás de los Arroyos",
               "id":"06763050"
            },
            "centroide":{
               "lat":-33.3487082868277,
               "lon":-60.231333927581
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Nicolás",
               "id":"060763"
            },
            "departamento":{
               "nombre":"San Nicolás",
               "id":"06763"
            },
            "nombre":"VILLA ESPERANZA",
            "id":"06763060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Esperanza",
               "id":"06763060"
            },
            "centroide":{
               "lat":-33.422326147095,
               "lon":-60.2605874904382
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"GOBERNADOR CASTRO",
            "id":"06770010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gobernador Castro",
               "id":"06770010"
            },
            "centroide":{
               "lat":-33.6605787290919,
               "lon":-59.8663408291494
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"INGENIERO MONETA",
            "id":"06770015000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Moneta",
               "id":"06770015"
            },
            "centroide":{
               "lat":-33.9314631330704,
               "lon":-59.7482330053997
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"OBLIGADO",
            "id":"06770020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Obligado",
               "id":"06770020"
            },
            "centroide":{
               "lat":-33.5965558617631,
               "lon":-59.8199792397796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"PUEBLO DOYLE",
            "id":"06770030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pueblo Doyle",
               "id":"06770030"
            },
            "centroide":{
               "lat":-33.9050057189811,
               "lon":-59.8187532271261
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"RIO TALA",
            "id":"06770040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Río Tala",
               "id":"06770040"
            },
            "centroide":{
               "lat":-33.769589927701,
               "lon":-59.6383708591324
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"SAN PEDRO",
            "id":"06770050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"06770050"
            },
            "centroide":{
               "lat":-33.6791252253366,
               "lon":-59.6668951027895
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"060770"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"06770"
            },
            "nombre":"SANTA LUCIA",
            "id":"06770060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Santa Lucía",
               "id":"06770060"
            },
            "centroide":{
               "lat":-33.8794559527188,
               "lon":-59.8753284459435
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"060778"
            },
            "departamento":{
               "nombre":"San Vicente",
               "id":"06778"
            },
            "nombre":"ALEJANDRO KORN",
            "id":"06778020001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Vicente",
               "id":"06778020"
            },
            "centroide":{
               "lat":-35.0135125285778,
               "lon":-58.3865346894091
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"060778"
            },
            "departamento":{
               "nombre":"San Vicente",
               "id":"06778"
            },
            "nombre":"SAN VICENTE",
            "id":"06778020002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Vicente",
               "id":"06778020"
            },
            "centroide":{
               "lat":-35.010404143813,
               "lon":-58.4699705982043
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"060778"
            },
            "departamento":{
               "nombre":"San Vicente",
               "id":"06778"
            },
            "nombre":"DOMSELAAR",
            "id":"06778020003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Vicente",
               "id":"06778020"
            },
            "centroide":{
               "lat":-35.048692550243,
               "lon":-58.3145745868708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Suipacha",
               "id":"060784"
            },
            "departamento":{
               "nombre":"Suipacha",
               "id":"06784"
            },
            "nombre":"GENERAL RIVAS",
            "id":"06784010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rivas",
               "id":"06784010"
            },
            "centroide":{
               "lat":-34.6100370680434,
               "lon":-59.7504234207746
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Suipacha",
               "id":"060784"
            },
            "departamento":{
               "nombre":"Suipacha",
               "id":"06784"
            },
            "nombre":"SUIPACHA",
            "id":"06784020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Suipacha",
               "id":"06784020"
            },
            "centroide":{
               "lat":-34.7712617839883,
               "lon":-59.6879181821818
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tandil",
               "id":"060791"
            },
            "departamento":{
               "nombre":"Tandil",
               "id":"06791"
            },
            "nombre":"DE LA CANAL",
            "id":"06791010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"De la Canal",
               "id":"06791010"
            },
            "centroide":{
               "lat":-37.1293125663244,
               "lon":-59.105380206456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tandil",
               "id":"060791"
            },
            "departamento":{
               "nombre":"Tandil",
               "id":"06791"
            },
            "nombre":"GARDEY",
            "id":"06791030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gardey",
               "id":"06791030"
            },
            "centroide":{
               "lat":-37.2823323375205,
               "lon":-59.3630004748452
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tandil",
               "id":"060791"
            },
            "departamento":{
               "nombre":"Tandil",
               "id":"06791"
            },
            "nombre":"MARIA IGNACIA",
            "id":"06791040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"María Ignacia",
               "id":"06791040"
            },
            "centroide":{
               "lat":-37.4029073074266,
               "lon":-59.5093354449092
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tandil",
               "id":"060791"
            },
            "departamento":{
               "nombre":"Tandil",
               "id":"06791"
            },
            "nombre":"TANDIL",
            "id":"06791050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tandil",
               "id":"06791050"
            },
            "centroide":{
               "lat":-37.3238849060878,
               "lon":-59.1310691770429
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tapalqué",
               "id":"060798"
            },
            "departamento":{
               "nombre":"Tapalqué",
               "id":"06798"
            },
            "nombre":"CROTTO",
            "id":"06798010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Crotto",
               "id":"06798010"
            },
            "centroide":{
               "lat":-36.5774103919485,
               "lon":-60.1711185359919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tapalqué",
               "id":"060798"
            },
            "departamento":{
               "nombre":"Tapalqué",
               "id":"06798"
            },
            "nombre":"TAPALQUE",
            "id":"06798020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tapalqué",
               "id":"06798020"
            },
            "centroide":{
               "lat":-36.3573669882462,
               "lon":-60.0247443487218
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tapalqué",
               "id":"060798"
            },
            "departamento":{
               "nombre":"Tapalqué",
               "id":"06798"
            },
            "nombre":"VELLOSO",
            "id":"06798030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Velloso",
               "id":"06798030"
            },
            "centroide":{
               "lat":-36.1215309084723,
               "lon":-59.6527146246682
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"BENAVIDEZ",
            "id":"06805010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4104347356392,
               "lon":-58.6887919689512
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"DIQUE LUJAN",
            "id":"06805010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.3717750147952,
               "lon":-58.6917925096496
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"DON TORCUATO ESTE",
            "id":"06805010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4869370480479,
               "lon":-58.6159410299812
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"DON TORCUATO OESTE",
            "id":"06805010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.5035354582885,
               "lon":-58.6275286549289
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"EL TALAR",
            "id":"06805010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4713213368481,
               "lon":-58.6570301927479
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"GENERAL PACHECO",
            "id":"06805010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4523088119909,
               "lon":-58.6448936162046
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"LOS TRONCOS DEL TALAR",
            "id":"06805010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4425672031974,
               "lon":-58.6174364127772
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"RICARDO ROJAS",
            "id":"06805010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4563339674448,
               "lon":-58.6806670320976
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"RINCON DE MILBERG",
            "id":"06805010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4097532043357,
               "lon":-58.6304827023614
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tigre",
               "id":"060805"
            },
            "departamento":{
               "nombre":"Tigre",
               "id":"06805"
            },
            "nombre":"TIGRE",
            "id":"06805010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tigre",
               "id":"06805010"
            },
            "centroide":{
               "lat":-34.4266925110065,
               "lon":-58.5779717541318
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tordillo",
               "id":"060812"
            },
            "departamento":{
               "nombre":"Tordillo",
               "id":"06812"
            },
            "nombre":"GENERAL CONESA",
            "id":"06812010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Conesa",
               "id":"06812010"
            },
            "centroide":{
               "lat":-36.5208587437818,
               "lon":-57.3249371025508
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"CHASICO",
            "id":"06819010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Chasicó",
               "id":"06819010"
            },
            "centroide":{
               "lat":-38.3352258409739,
               "lon":-62.6424099899733
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"SALDUNGARAY",
            "id":"06819020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Saldungaray",
               "id":"06819020"
            },
            "centroide":{
               "lat":-38.2028131038038,
               "lon":-61.7678925154653
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"SIERRA DE LA VENTANA",
            "id":"06819030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Sierra de la Ventana",
               "id":"06819030"
            },
            "centroide":{
               "lat":-38.1366716139365,
               "lon":-61.7956860399239
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"TORNQUIST",
            "id":"06819040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tornquist",
               "id":"06819040"
            },
            "centroide":{
               "lat":-38.0989983131157,
               "lon":-62.2218091270044
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"TRES PICOS",
            "id":"06819050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres Picos",
               "id":"06819050"
            },
            "centroide":{
               "lat":-38.2867749310339,
               "lon":-62.2073117423093
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"VILLA SERRANA LA GRUTA",
            "id":"06819060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Gruta",
               "id":"06819060"
            },
            "centroide":{
               "lat":-38.0568266332177,
               "lon":-62.0782618310425
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tornquist",
               "id":"060819"
            },
            "departamento":{
               "nombre":"Tornquist",
               "id":"06819"
            },
            "nombre":"VILLA VENTANA",
            "id":"06819070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Ventana",
               "id":"06819070"
            },
            "centroide":{
               "lat":-38.0795765714478,
               "lon":-61.9311383483155
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trenque Lauquen",
               "id":"060826"
            },
            "departamento":{
               "nombre":"Trenque Lauquen",
               "id":"06826"
            },
            "nombre":"BERUTTI",
            "id":"06826010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Berutti",
               "id":"06826010"
            },
            "centroide":{
               "lat":-35.8580311359835,
               "lon":-62.5126328995628
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Daireaux",
               "id":"060231"
            },
            "departamento":{
               "nombre":"Trenque Lauquen",
               "id":"06826"
            },
            "nombre":"GIRODIAS",
            "id":"06826020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Girodias",
               "id":"06826020"
            },
            "centroide":{
               "lat":-36.367451804132,
               "lon":-62.3569660936558
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trenque Lauquen",
               "id":"060826"
            },
            "departamento":{
               "nombre":"Trenque Lauquen",
               "id":"06826"
            },
            "nombre":"LA CARRETA",
            "id":"06826030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Carreta",
               "id":"06826030"
            },
            "centroide":{
               "lat":-36.1964811594442,
               "lon":-62.2245641400875
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trenque Lauquen",
               "id":"060826"
            },
            "departamento":{
               "nombre":"Trenque Lauquen",
               "id":"06826"
            },
            "nombre":"30 DE AGOSTO",
            "id":"06826040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"30 de Agosto",
               "id":"06826040"
            },
            "centroide":{
               "lat":-36.2779720057322,
               "lon":-62.5453088654651
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trenque Lauquen",
               "id":"060826"
            },
            "departamento":{
               "nombre":"Trenque Lauquen",
               "id":"06826"
            },
            "nombre":"TRENQUE LAUQUEN",
            "id":"06826050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Trenque Lauquen",
               "id":"06826050"
            },
            "centroide":{
               "lat":-35.9746951559846,
               "lon":-62.7323099655579
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trenque Lauquen",
               "id":"060826"
            },
            "departamento":{
               "nombre":"Trenque Lauquen",
               "id":"06826"
            },
            "nombre":"TRONGE",
            "id":"06826060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Trongé",
               "id":"06826060"
            },
            "centroide":{
               "lat":-36.4603092402658,
               "lon":-62.4871229040303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"BALNEARIO ORENSE",
            "id":"06833010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Balneario Orense",
               "id":"06833010"
            },
            "centroide":{
               "lat":-38.8079580951783,
               "lon":-59.7382989446997
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"CLAROMECO",
            "id":"06833020001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Claromecó",
               "id":"06833020"
            },
            "centroide":{
               "lat":-38.7973744243016,
               "lon":-60.107623035402
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"DUNAMAR",
            "id":"06833020002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Claromecó",
               "id":"06833020"
            },
            "centroide":{
               "lat":-38.8586780962346,
               "lon":-60.0873319312173
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"COPETONAS",
            "id":"06833030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Copetonas",
               "id":"06833030"
            },
            "centroide":{
               "lat":-38.7227403438549,
               "lon":-60.4519655195991
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"LIN CALEL",
            "id":"06833040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lin Calel",
               "id":"06833040"
            },
            "centroide":{
               "lat":-38.7081778337076,
               "lon":-60.2417246463467
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"MICAELA CASCALLARES",
            "id":"06833050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Micaela Cascallares",
               "id":"06833050"
            },
            "centroide":{
               "lat":-38.4926790060303,
               "lon":-60.4684232796744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"ORENSE",
            "id":"06833060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Orense",
               "id":"06833060"
            },
            "centroide":{
               "lat":-38.6855169253543,
               "lon":-59.7764287092023
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"RETA",
            "id":"06833070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Reta",
               "id":"06833070"
            },
            "centroide":{
               "lat":-38.8970805514765,
               "lon":-60.343409791669
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"SAN FRANCISCO DE BELLOCQ",
            "id":"06833080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Francisco de Bellocq",
               "id":"06833080"
            },
            "centroide":{
               "lat":-38.6931705173547,
               "lon":-60.0141786926695
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"SAN MAYOL",
            "id":"06833090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Mayol",
               "id":"06833090"
            },
            "centroide":{
               "lat":-38.3177365227324,
               "lon":-60.0258421214843
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"TRES ARROYOS",
            "id":"06833100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres Arroyos",
               "id":"06833100"
            },
            "centroide":{
               "lat":-38.3771785795114,
               "lon":-60.2755588939396
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Arroyos",
               "id":"060833"
            },
            "departamento":{
               "nombre":"Tres Arroyos",
               "id":"06833"
            },
            "nombre":"VILLA RODRIGUEZ",
            "id":"06833110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Rodríguez",
               "id":"06833110"
            },
            "centroide":{
               "lat":-38.3125817693893,
               "lon":-60.2324995777758
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"CASEROS",
            "id":"06840010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.608872950993,
               "lon":-58.5636644572416
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"CHURRUCA",
            "id":"06840010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5589664800215,
               "lon":-58.6228016204112
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"CIUDAD JARDIN LOMAS DEL PALOMAR",
            "id":"06840010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5961197253917,
               "lon":-58.5965998962097
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"CIUDADELA",
            "id":"06840010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.6326965166188,
               "lon":-58.5424067827037
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"EL LIBERTADOR",
            "id":"06840010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5564986200599,
               "lon":-58.622337837699
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"JOSE INGENIEROS",
            "id":"06840010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.6171528691842,
               "lon":-58.5342186326877
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"LOMA HERMOSA",
            "id":"06840010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5684066633166,
               "lon":-58.603081885803
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"MARTIN CORONADO",
            "id":"06840010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5852038063892,
               "lon":-58.5918755543704
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"11 DE SEPTIEMBRE",
            "id":"06840010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.566314345456,
               "lon":-58.6183600679169
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"PABLO PODESTA",
            "id":"06840010010",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5758246152249,
               "lon":-58.6202497472316
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"REMEDIOS DE ESCALADA",
            "id":"06840010011",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5705752444729,
               "lon":-58.620118067161
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"SAENZ PEÑA",
            "id":"06840010012",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5992886088748,
               "lon":-58.5320796402641
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"SANTOS LUGARES",
            "id":"06840010013",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5991533496562,
               "lon":-58.5478835120962
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"VILLA BOSCH (EST. JUAN MARIA BOSCH)",
            "id":"06840010014",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.5822981982887,
               "lon":-58.5802932642206
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres de Febrero",
               "id":"060840"
            },
            "departamento":{
               "nombre":"Tres de Febrero",
               "id":"06840"
            },
            "nombre":"VILLA RAFFO",
            "id":"06840010015",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres de Febrero",
               "id":"06840010"
            },
            "centroide":{
               "lat":-34.6083090753398,
               "lon":-58.5319718083201
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Lomas",
               "id":"060847"
            },
            "departamento":{
               "nombre":"Tres Lomas",
               "id":"06847"
            },
            "nombre":"INGENIERO THOMPSON",
            "id":"06847010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Thompson",
               "id":"06847010"
            },
            "centroide":{
               "lat":-36.6102541131892,
               "lon":-62.9109612139671
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Lomas",
               "id":"060847"
            },
            "departamento":{
               "nombre":"Tres Lomas",
               "id":"06847"
            },
            "nombre":"TRES LOMAS",
            "id":"06847020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Tres Lomas",
               "id":"06847020"
            },
            "centroide":{
               "lat":-36.458109497037,
               "lon":-62.8621355091327
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"AGUSTIN MOSCONI",
            "id":"06854010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Agustín Mosconi",
               "id":"06854010"
            },
            "centroide":{
               "lat":-35.7392169542026,
               "lon":-60.5590634621789
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"DEL VALLE",
            "id":"06854020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Del Valle",
               "id":"06854020"
            },
            "centroide":{
               "lat":-35.8973453887603,
               "lon":-60.7316089512944
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"ERNESTINA",
            "id":"06854030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Ernestina",
               "id":"06854030"
            },
            "centroide":{
               "lat":-35.2702340519789,
               "lon":-59.5575034078873
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"GOBERNADOR UGARTE",
            "id":"06854040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Gobernador Ugarte",
               "id":"06854040"
            },
            "centroide":{
               "lat":-35.1643973648093,
               "lon":-60.0813577815069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"LUCAS MONTEVERDE",
            "id":"06854050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lucas Monteverde",
               "id":"06854050"
            },
            "centroide":{
               "lat":-35.4838272164734,
               "lon":-59.9880647932831
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"NORBERTO DE LA RIESTRA",
            "id":"06854060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Norberto de la Riestra",
               "id":"06854060"
            },
            "centroide":{
               "lat":-35.2727788695321,
               "lon":-59.7719327250509
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"PEDERNALES",
            "id":"06854070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pedernales",
               "id":"06854070"
            },
            "centroide":{
               "lat":-35.2666085074294,
               "lon":-59.6306574695384
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"SAN ENRIQUE",
            "id":"06854080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"San Enrique",
               "id":"06854080"
            },
            "centroide":{
               "lat":-35.7785028686085,
               "lon":-60.3546454811901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"VALDES",
            "id":"06854090000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Valdés",
               "id":"06854090"
            },
            "centroide":{
               "lat":-35.6488128268597,
               "lon":-60.4672591673156
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"060854"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"06854"
            },
            "nombre":"25 DE MAYO",
            "id":"06854100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"25 de Mayo",
               "id":"06854100"
            },
            "centroide":{
               "lat":-35.4339385892588,
               "lon":-60.1731209454056
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"CARAPACHAY",
            "id":"06861010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.5259149691463,
               "lon":-58.5357639427634
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"FLORIDA",
            "id":"06861010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.5327432636609,
               "lon":-58.4910979599198
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"FLORIDA OESTE",
            "id":"06861010003",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.5394223366496,
               "lon":-58.5148137943065
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"LA LUCILA",
            "id":"06861010004",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.4985433329471,
               "lon":-58.486582489457
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"MUNRO",
            "id":"06861010005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.5272623078248,
               "lon":-58.5255060588714
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"OLIVOS",
            "id":"06861010006",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.512161433984,
               "lon":-58.4985444232342
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"VICENTE LOPEZ",
            "id":"06861010007",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.525536745172,
               "lon":-58.4736495025234
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"VILLA ADELINA",
            "id":"06861010008",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.5287596487564,
               "lon":-58.5460242623901
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicente López",
               "id":"060861"
            },
            "departamento":{
               "nombre":"Vicente López",
               "id":"06861"
            },
            "nombre":"VILLA MARTELLI",
            "id":"06861010009",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vicente López",
               "id":"06861010"
            },
            "centroide":{
               "lat":-34.5525979527695,
               "lon":-58.5091432644227
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Gesell",
               "id":"060868"
            },
            "departamento":{
               "nombre":"Villa Gesell",
               "id":"06868"
            },
            "nombre":"MAR AZUL",
            "id":"06868010001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar Azul",
               "id":"06868010"
            },
            "centroide":{
               "lat":-37.3366731576801,
               "lon":-57.032501468863
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Gesell",
               "id":"060868"
            },
            "departamento":{
               "nombre":"Villa Gesell",
               "id":"06868"
            },
            "nombre":"MAR DE LAS PAMPAS",
            "id":"06868010002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mar Azul",
               "id":"06868010"
            },
            "centroide":{
               "lat":-37.3281312355181,
               "lon":-57.0399070097774
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Gesell",
               "id":"060868"
            },
            "departamento":{
               "nombre":"Villa Gesell",
               "id":"06868"
            },
            "nombre":"VILLA GESELL",
            "id":"06868020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Gesell",
               "id":"06868020"
            },
            "centroide":{
               "lat":-37.2464704698501,
               "lon":-56.9707267624406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"ARGERICH",
            "id":"06875010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Argerich",
               "id":"06875010"
            },
            "centroide":{
               "lat":-38.7686560837266,
               "lon":-62.6025276771033
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"COLONIA SAN ADOLFO",
            "id":"06875020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia San Adolfo",
               "id":"06875020"
            },
            "centroide":{
               "lat":-39.3983952518232,
               "lon":-62.5711721088672
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"COUNTRY LOS MEDANOS",
            "id":"06875025000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Country Los Medanos",
               "id":"06875025"
            },
            "centroide":{
               "lat":-38.8468445915571,
               "lon":-62.738741885831
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"HILARIO ASCASUBI",
            "id":"06875030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Hilario Ascasubi",
               "id":"06875030"
            },
            "centroide":{
               "lat":-39.3758602632663,
               "lon":-62.6477900877351
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"JUAN COUSTE",
            "id":"06875040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan Cousté",
               "id":"06875040"
            },
            "centroide":{
               "lat":-38.8942604188728,
               "lon":-63.1361581918897
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"MAYOR BURATOVICH",
            "id":"06875050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Mayor Buratovich",
               "id":"06875050"
            },
            "centroide":{
               "lat":-39.2590946793617,
               "lon":-62.6157889589915
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"MEDANOS",
            "id":"06875060002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Médanos",
               "id":"06875060"
            },
            "centroide":{
               "lat":-38.8293145512181,
               "lon":-62.6943321574343
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"PEDRO LURO",
            "id":"06875070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Pedro Luro",
               "id":"06875070"
            },
            "centroide":{
               "lat":-39.5007767274615,
               "lon":-62.6874317159849
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villarino",
               "id":"060875"
            },
            "departamento":{
               "nombre":"Villarino",
               "id":"06875"
            },
            "nombre":"TENIENTE ORIGONE",
            "id":"06875080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Teniente Origone",
               "id":"06875080"
            },
            "centroide":{
               "lat":-39.0580586668564,
               "lon":-62.5697137402023
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zárate",
               "id":"060882"
            },
            "departamento":{
               "nombre":"Zárate",
               "id":"06882"
            },
            "nombre":"COUNTRY CLUB EL CASCO",
            "id":"06882020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Country Club El Casco",
               "id":"06882020"
            },
            "centroide":{
               "lat":-34.1275885141615,
               "lon":-59.0834596008166
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zárate",
               "id":"060882"
            },
            "departamento":{
               "nombre":"Zárate",
               "id":"06882"
            },
            "nombre":"ESCALADA",
            "id":"06882030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Escalada",
               "id":"06882030"
            },
            "centroide":{
               "lat":-34.160933188334,
               "lon":-59.1125399836881
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zárate",
               "id":"060882"
            },
            "departamento":{
               "nombre":"Zárate",
               "id":"06882"
            },
            "nombre":"LIMA",
            "id":"06882040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Lima",
               "id":"06882040"
            },
            "centroide":{
               "lat":-34.0436335939636,
               "lon":-59.1973339595539
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zárate",
               "id":"060882"
            },
            "departamento":{
               "nombre":"Zárate",
               "id":"06882"
            },
            "nombre":"ZARATE",
            "id":"06882050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Zárate",
               "id":"06882050"
            },
            "centroide":{
               "lat":-34.0998630672257,
               "lon":-59.024557255152
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zárate",
               "id":"060882"
            },
            "departamento":{
               "nombre":"Zárate",
               "id":"06882"
            },
            "nombre":"BARRIO SAAVEDRA",
            "id":"06882050001",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Zárate",
               "id":"06882050"
            },
            "centroide":{
               "lat":-34.1263843372848,
               "lon":-59.020156759664
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zárate",
               "id":"060882"
            },
            "departamento":{
               "nombre":"Zárate",
               "id":"06882"
            },
            "nombre":"ZARATE",
            "id":"06882050002",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Zárate",
               "id":"06882050"
            },
            "centroide":{
               "lat":-34.1115306481926,
               "lon":-59.0483426856883
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"CHUCHUCARUANA",
            "id":"10007010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Chuchucaruana",
               "id":"10007010"
            },
            "centroide":{
               "lat":-27.9072844349135,
               "lon":-65.8265242094263
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Puerta",
               "id":"100014"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"COLPES",
            "id":"10007020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Colpes",
               "id":"10007020"
            },
            "centroide":{
               "lat":-28.056472497625,
               "lon":-65.8260844153652
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"EL BOLSON",
            "id":"10007030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Bolsón",
               "id":"10007030"
            },
            "centroide":{
               "lat":-27.9038743420396,
               "lon":-65.8884829262542
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Rodeo",
               "id":"100007"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"EL RODEO",
            "id":"10007040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Rodeo",
               "id":"10007040"
            },
            "centroide":{
               "lat":-28.2141516424861,
               "lon":-65.8738600634115
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Puerta",
               "id":"100014"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"HUAYCAMA",
            "id":"10007050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Huaycama",
               "id":"10007050"
            },
            "centroide":{
               "lat":-28.0988458342305,
               "lon":-65.8147464336478
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Puerta",
               "id":"100014"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"LA PUERTA",
            "id":"10007060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Puerta",
               "id":"10007060"
            },
            "centroide":{
               "lat":-28.1764379052117,
               "lon":-65.7863037835082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"LAS CHACRITAS",
            "id":"10007070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Chacritas",
               "id":"10007070"
            },
            "centroide":{
               "lat":-27.6464168267027,
               "lon":-65.9533446470458
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Juntas",
               "id":"100021"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"LAS JUNTAS",
            "id":"10007080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Juntas",
               "id":"10007080"
            },
            "centroide":{
               "lat":-28.1038836314671,
               "lon":-65.8996513641899
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"LOS CASTILLOS",
            "id":"10007090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Castillos",
               "id":"10007090"
            },
            "centroide":{
               "lat":-27.9582295650792,
               "lon":-65.820685723313
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"LOS TALAS",
            "id":"10007100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Talas",
               "id":"10007100"
            },
            "centroide":{
               "lat":-27.94999754917,
               "lon":-65.875829814164
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"LOS VARELA",
            "id":"10007110000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Varela",
               "id":"10007110"
            },
            "centroide":{
               "lat":-27.9279942328057,
               "lon":-65.8822302327591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Varela",
               "id":"100028"
            },
            "departamento":{
               "nombre":"Ambato",
               "id":"10007"
            },
            "nombre":"SINGUIL",
            "id":"10007120000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Singuil",
               "id":"10007120"
            },
            "centroide":{
               "lat":-27.8142168571341,
               "lon":-65.8670660017181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ancasti",
               "id":"100035"
            },
            "departamento":{
               "nombre":"Ancasti",
               "id":"10014"
            },
            "nombre":"ANCASTI",
            "id":"10014010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Ancasti",
               "id":"10014010"
            },
            "centroide":{
               "lat":-28.809829766706,
               "lon":-65.5021019996524
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ancasti",
               "id":"100035"
            },
            "departamento":{
               "nombre":"Ancasti",
               "id":"10014"
            },
            "nombre":"ANQUINCILA",
            "id":"10014020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Anquincila",
               "id":"10014020"
            },
            "centroide":{
               "lat":-28.7545936474404,
               "lon":-65.5495102803337
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ancasti",
               "id":"100035"
            },
            "departamento":{
               "nombre":"Ancasti",
               "id":"10014"
            },
            "nombre":"LA CANDELARIA",
            "id":"10014030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Candelaria",
               "id":"10014030"
            },
            "centroide":{
               "lat":-28.7207622901373,
               "lon":-65.4106917370235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ancasti",
               "id":"100035"
            },
            "departamento":{
               "nombre":"Ancasti",
               "id":"10014"
            },
            "nombre":"LA MAJADA",
            "id":"10014040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Majada",
               "id":"10014040"
            },
            "centroide":{
               "lat":-29.0295107636719,
               "lon":-65.5499567687055
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andalgalá",
               "id":"100049"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"AMANAO",
            "id":"10021010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Amanao",
               "id":"10021010"
            },
            "centroide":{
               "lat":-27.5275895579827,
               "lon":-66.5156761677031
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andalgalá",
               "id":"100049"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"ANDALGALA",
            "id":"10021020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Andalgalá",
               "id":"10021020"
            },
            "centroide":{
               "lat":-27.5732225954972,
               "lon":-66.3235466394645
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andalgalá",
               "id":"100049"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"CHAQUIAGO",
            "id":"10021030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Chaquiago",
               "id":"10021030"
            },
            "centroide":{
               "lat":-27.5368955481985,
               "lon":-66.3350568927423
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andalgalá",
               "id":"100049"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"CHOYA",
            "id":"10021040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Choya",
               "id":"10021040"
            },
            "centroide":{
               "lat":-27.5210749343604,
               "lon":-66.4034742805443
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aconquija",
               "id":"100042"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"BUENA VISTA",
            "id":"10021050001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Alamito",
               "id":"10021050"
            },
            "centroide":{
               "lat":-27.3391922396844,
               "lon":-66.0241892155379
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aconquija",
               "id":"100042"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"EL ALAMITO",
            "id":"10021050002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Alamito",
               "id":"10021050"
            },
            "centroide":{
               "lat":-27.333537875981,
               "lon":-66.022985174122
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aconquija",
               "id":"100042"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"ACONQUIJA",
            "id":"10021060001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Lindero",
               "id":"10021060"
            },
            "centroide":{
               "lat":-27.4743798884533,
               "lon":-66.0266884349829
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aconquija",
               "id":"100042"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"ALTO DE LAS JUNTAS",
            "id":"10021060002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Lindero",
               "id":"10021060"
            },
            "centroide":{
               "lat":-27.4511959792163,
               "lon":-66.0199688270559
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aconquija",
               "id":"100042"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"EL LINDERO",
            "id":"10021060003",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Lindero",
               "id":"10021060"
            },
            "centroide":{
               "lat":-27.4743798884533,
               "lon":-66.0266884349829
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aconquija",
               "id":"100042"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"LA MESADA",
            "id":"10021060004",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Lindero",
               "id":"10021060"
            },
            "centroide":{
               "lat":-27.4511959792163,
               "lon":-66.0199688270559
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andalgalá",
               "id":"100049"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"EL POTRERO",
            "id":"10021070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Potrero",
               "id":"10021070"
            },
            "centroide":{
               "lat":-27.5162322380357,
               "lon":-66.3434280762552
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andalgalá",
               "id":"100049"
            },
            "departamento":{
               "nombre":"Andalgalá",
               "id":"10021"
            },
            "nombre":"LA AGUADA",
            "id":"10021080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Aguada",
               "id":"10021080"
            },
            "centroide":{
               "lat":-27.534089032104,
               "lon":-66.3117750484602
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Antofagasta de la Sierra",
               "id":"100056"
            },
            "departamento":{
               "nombre":"Antofagasta de la Sierra",
               "id":"10028"
            },
            "nombre":"ANTOFAGASTA DE LA SIERRA",
            "id":"10028010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Antofagasta de la Sierra",
               "id":"10028010"
            },
            "centroide":{
               "lat":-26.0632957365737,
               "lon":-67.4116706391736
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Antofagasta de la Sierra",
               "id":"100056"
            },
            "departamento":{
               "nombre":"Antofagasta de la Sierra",
               "id":"10028"
            },
            "nombre":"ANTOFALLA",
            "id":"10028020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Antofalla",
               "id":"10028020"
            },
            "centroide":{
               "lat":-25.445326397371,
               "lon":-67.6583954684648
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Antofagasta de la Sierra",
               "id":"100056"
            },
            "departamento":{
               "nombre":"Antofagasta de la Sierra",
               "id":"10028"
            },
            "nombre":"EL PEÑON",
            "id":"10028030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Peñón",
               "id":"10028030"
            },
            "centroide":{
               "lat":-26.4377795455164,
               "lon":-67.2590953594946
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Antofagasta de la Sierra",
               "id":"100056"
            },
            "departamento":{
               "nombre":"Antofagasta de la Sierra",
               "id":"10028"
            },
            "nombre":"LOS NACIMIENTOS",
            "id":"10028040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Nacimientos",
               "id":"10028040"
            },
            "centroide":{
               "lat":-25.7592665755338,
               "lon":-67.3912671398198
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Vil",
               "id":"100119"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"BARRANCA LARGA",
            "id":"10035010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Barranca Larga",
               "id":"10035010"
            },
            "centroide":{
               "lat":-26.9366725902001,
               "lon":-66.7473736733687
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Belén",
               "id":"100063"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"BELEN",
            "id":"10035020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Belén",
               "id":"10035020"
            },
            "centroide":{
               "lat":-27.6337582271366,
               "lon":-67.0181224346383
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pozo de Piedra",
               "id":"100091"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"CONDOR HUASI",
            "id":"10035030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Cóndor Huasi",
               "id":"10035030"
            },
            "centroide":{
               "lat":-27.4800253698881,
               "lon":-67.1034365555174
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corral Quemado",
               "id":"100070"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"CORRAL QUEMADO",
            "id":"10035040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Corral Quemado",
               "id":"10035040"
            },
            "centroide":{
               "lat":-27.1458266170631,
               "lon":-66.9418772140389
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerta de Corral Quemado",
               "id":"100098"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"EL DURAZNO",
            "id":"10035050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Durazno",
               "id":"10035050"
            },
            "centroide":{
               "lat":-27.2352160693093,
               "lon":-67.0644302349354
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hualfín",
               "id":"100077"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"FARALLON NEGRO",
            "id":"10035060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Farallón Negro",
               "id":"10035060"
            },
            "centroide":{
               "lat":-27.297991160481,
               "lon":-66.6526535881206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hualfín",
               "id":"100077"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"HUALFIN",
            "id":"10035070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Hualfín",
               "id":"10035070"
            },
            "centroide":{
               "lat":-27.2251089488391,
               "lon":-66.8257494663378
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerta de Corral Quemado",
               "id":"100098"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"JACIPUNCO",
            "id":"10035080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Jacipunco",
               "id":"10035080"
            },
            "centroide":{
               "lat":-27.2232502935304,
               "lon":-67.0189560597067
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Belén",
               "id":"100063"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"LA PUNTILLA",
            "id":"10035090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Puntilla",
               "id":"10035090"
            },
            "centroide":{
               "lat":-27.6687999477098,
               "lon":-66.9831854181017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pozo de Piedra",
               "id":"100091"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"LAS JUNTAS",
            "id":"10035100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Juntas",
               "id":"10035100"
            },
            "centroide":{
               "lat":-27.5255267279244,
               "lon":-67.1230327103174
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Londres",
               "id":"100084"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"LONDRES",
            "id":"10035110000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Londres",
               "id":"10035110"
            },
            "centroide":{
               "lat":-27.7091441699136,
               "lon":-67.1521288574758
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hualfín",
               "id":"100077"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"LOS NACIMIENTOS",
            "id":"10035120000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Nacimientos",
               "id":"10035120"
            },
            "centroide":{
               "lat":-27.1276313861506,
               "lon":-66.7125336324121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerta de Corral Quemado",
               "id":"100098"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"PUERTA DE CORRAL QUEMADO",
            "id":"10035130000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Puerta de Corral Quemado",
               "id":"10035130"
            },
            "centroide":{
               "lat":-27.2142919344438,
               "lon":-66.9263178640934
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerta de San José",
               "id":"100105"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"PUERTA DE SAN JOSE",
            "id":"10035140000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Puerta de San José",
               "id":"10035140"
            },
            "centroide":{
               "lat":-27.5397412070942,
               "lon":-67.0153310519752
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Vil",
               "id":"100119"
            },
            "departamento":{
               "nombre":"Belén",
               "id":"10035"
            },
            "nombre":"VILLA VIL",
            "id":"10035150000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Villa Vil",
               "id":"10035150"
            },
            "centroide":{
               "lat":-27.0710806751114,
               "lon":-66.8307696967369
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"ADOLFO E. CARRANZA",
            "id":"10042010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Adolfo E. Carranza",
               "id":"10042010"
            },
            "centroide":{
               "lat":-29.0269775235925,
               "lon":-65.9719315369666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"BALDE DE LA PUNTA",
            "id":"10042020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Balde de la Punta",
               "id":"10042020"
            },
            "centroide":{
               "lat":-29.5589484234071,
               "lon":-65.5807240749041
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"CAPAYAN",
            "id":"10042030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Capayán",
               "id":"10042030"
            },
            "centroide":{
               "lat":-28.7807106449765,
               "lon":-66.0386455444458
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"CHUMBICHA",
            "id":"10042040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Chumbicha",
               "id":"10042040"
            },
            "centroide":{
               "lat":-28.8475652676601,
               "lon":-66.2413567840968
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"COLONIA DEL VALLE",
            "id":"10042050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Colonia del Valle",
               "id":"10042050"
            },
            "centroide":{
               "lat":-28.6659572317315,
               "lon":-65.8752053335172
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"COLONIA NUEVA CONETA",
            "id":"10042060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Colonia Nueva Coneta",
               "id":"10042060"
            },
            "centroide":{
               "lat":-28.5859218596167,
               "lon":-65.8384330489801
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"CONCEPCION",
            "id":"10042070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Concepción",
               "id":"10042070"
            },
            "centroide":{
               "lat":-28.7027878127673,
               "lon":-66.0684404425234
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"CONETA",
            "id":"10042080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Coneta",
               "id":"10042080"
            },
            "centroide":{
               "lat":-28.5824940471212,
               "lon":-65.8832899522771
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"EL BAÑADO",
            "id":"10042090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Bañado",
               "id":"10042090"
            },
            "centroide":{
               "lat":-28.6492910442287,
               "lon":-65.8184083876597
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"HUILLAPIMA",
            "id":"10042100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Huillapima",
               "id":"10042100"
            },
            "centroide":{
               "lat":-28.7326533523263,
               "lon":-65.9692671337206
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"LOS ANGELES",
            "id":"10042110000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Angeles",
               "id":"10042110"
            },
            "centroide":{
               "lat":-28.4757282890296,
               "lon":-65.9584714095267
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"LOS ANGELES NORTE",
            "id":"10042110001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Angeles",
               "id":"10042110"
            },
            "centroide":{
               "lat":-28.4130524181894,
               "lon":-65.9974963114274
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"LOS ANGELES SUR",
            "id":"10042110002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Angeles",
               "id":"10042110"
            },
            "centroide":{
               "lat":-28.5096259939006,
               "lon":-66.0134501995358
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"MIRAFLORES",
            "id":"10042120000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Miraflores",
               "id":"10042120"
            },
            "centroide":{
               "lat":-28.61156437771,
               "lon":-65.9045510608673
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"SAN MARTIN",
            "id":"10042130000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Martín",
               "id":"10042130"
            },
            "centroide":{
               "lat":-29.2514238184412,
               "lon":-65.7966971627176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huillapima",
               "id":"100133"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"SAN PABLO",
            "id":"10042140000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Pablo",
               "id":"10042140"
            },
            "centroide":{
               "lat":-28.7185211619112,
               "lon":-66.0406031753627
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capayán",
               "id":"100126"
            },
            "departamento":{
               "nombre":"Capayán",
               "id":"10042"
            },
            "nombre":"SAN PEDRO",
            "id":"10042150000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"10042150"
            },
            "centroide":{
               "lat":-28.7717177596284,
               "lon":-66.1240600986815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Fernando del Valle de Catamarca",
               "id":"100140"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"10049"
            },
            "nombre":"EL PANTANILLO",
            "id":"10049020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Pantanillo",
               "id":"10049020"
            },
            "centroide":{
               "lat":-28.5416893432334,
               "lon":-65.8026632730873
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Fernando del Valle de Catamarca",
               "id":"100140"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"10049"
            },
            "nombre":"SAN FERNANDO DEL VALLE DE CATAMARCA",
            "id":"10049030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Fernando del Valle de Catamarca",
               "id":"10049030"
            },
            "centroide":{
               "lat":-28.4846581947085,
               "lon":-65.7867892937631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alto",
               "id":"100147"
            },
            "departamento":{
               "nombre":"El Alto",
               "id":"10056"
            },
            "nombre":"EL ALTO",
            "id":"10056010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Alto",
               "id":"10056010"
            },
            "centroide":{
               "lat":-28.3027477561138,
               "lon":-65.3693767192491
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alto",
               "id":"100147"
            },
            "departamento":{
               "nombre":"El Alto",
               "id":"10056"
            },
            "nombre":"GUAYAMBA",
            "id":"10056020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Guayamba",
               "id":"10056020"
            },
            "centroide":{
               "lat":-28.3441266642037,
               "lon":-65.4127476684157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alto",
               "id":"100147"
            },
            "departamento":{
               "nombre":"El Alto",
               "id":"10056"
            },
            "nombre":"INFANZON",
            "id":"10056030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Infanzón",
               "id":"10056030"
            },
            "centroide":{
               "lat":-28.5973244077887,
               "lon":-65.4116602658918
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alto",
               "id":"100147"
            },
            "departamento":{
               "nombre":"El Alto",
               "id":"10056"
            },
            "nombre":"LOS CORRALES",
            "id":"10056040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Corrales",
               "id":"10056040"
            },
            "centroide":{
               "lat":-28.5108290239161,
               "lon":-65.3308772218931
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tapso",
               "id":"100154"
            },
            "departamento":{
               "nombre":"El Alto",
               "id":"10056"
            },
            "nombre":"TAPSO",
            "id":"10056050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Tapso",
               "id":"10056050"
            },
            "centroide":{
               "lat":-28.4159041977159,
               "lon":-65.1086409589416
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alto",
               "id":"100147"
            },
            "departamento":{
               "nombre":"El Alto",
               "id":"10056"
            },
            "nombre":"VILISMAN",
            "id":"10056060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Vilismán",
               "id":"10056060"
            },
            "centroide":{
               "lat":-28.5021133153073,
               "lon":-65.4385489995235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"COLLAGASTA",
            "id":"10063010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Collagasta",
               "id":"10063010"
            },
            "centroide":{
               "lat":-28.3616776397898,
               "lon":-65.7282690165025
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"POMANCILLO ESTE",
            "id":"10063020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Pomancillo Este",
               "id":"10063020"
            },
            "centroide":{
               "lat":-28.3087173740113,
               "lon":-65.7222098439933
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"POMANCILLO OESTE",
            "id":"10063030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Pomancillo Oeste",
               "id":"10063030"
            },
            "centroide":{
               "lat":-28.3178432457188,
               "lon":-65.7423447192215
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"EL HUECO",
            "id":"10063040001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10063040"
            },
            "centroide":{
               "lat":-28.4127884506849,
               "lon":-65.7175369854892
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"LA CARRERA",
            "id":"10063040002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10063040"
            },
            "centroide":{
               "lat":-28.3495576813874,
               "lon":-65.7091195877043
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"LA FALDA DE SAN ANTONIO",
            "id":"10063040003",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10063040"
            },
            "centroide":{
               "lat":-28.4198487342191,
               "lon":-65.6988377960377
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"LA TERCENA",
            "id":"10063040004",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10063040"
            },
            "centroide":{
               "lat":-28.3734473479931,
               "lon":-65.7081292515981
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"SAN ANTONIO",
            "id":"10063040005",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10063040"
            },
            "centroide":{
               "lat":-28.4237766788262,
               "lon":-65.7062134320732
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"SAN JOSE",
            "id":"10063040006",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10063040"
            },
            "centroide":{
               "lat":-28.3887321458271,
               "lon":-65.7062701247999
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"100161"
            },
            "departamento":{
               "nombre":"Fray Mamerto Esquiú",
               "id":"10063"
            },
            "nombre":"VILLA LAS PIRQUITAS",
            "id":"10063050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Villa Las Pirquitas",
               "id":"10063050"
            },
            "centroide":{
               "lat":-28.2754665210832,
               "lon":-65.733431477509
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"CASA DE PIEDRA",
            "id":"10070010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Casa de Piedra",
               "id":"10070010"
            },
            "centroide":{
               "lat":-29.649289010725,
               "lon":-65.5171151597056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"EL AYBAL",
            "id":"10070020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Aybal",
               "id":"10070020"
            },
            "centroide":{
               "lat":-29.1066140404485,
               "lon":-65.339695207396
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"EL BAÑADO",
            "id":"10070030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Bañado",
               "id":"10070030"
            },
            "centroide":{
               "lat":-29.1832354089391,
               "lon":-65.4161459216901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"EL DIVISADERO",
            "id":"10070040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Divisadero",
               "id":"10070040"
            },
            "centroide":{
               "lat":-29.193718415728,
               "lon":-65.4233403523442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"EL QUIMILO",
            "id":"10070050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Quimilo",
               "id":"10070050"
            },
            "centroide":{
               "lat":-29.9534214435134,
               "lon":-65.3926406151323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"ESQUIU",
            "id":"10070060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Esquiú",
               "id":"10070060"
            },
            "centroide":{
               "lat":-29.3790661944991,
               "lon":-65.2898287859815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Icaño",
               "id":"100168"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"ICAÑO",
            "id":"10070070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Icaño",
               "id":"10070070"
            },
            "centroide":{
               "lat":-28.9308886124085,
               "lon":-65.2902158081112
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"LA DORADA",
            "id":"10070080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Dorada",
               "id":"10070080"
            },
            "centroide":{
               "lat":-29.2804387843954,
               "lon":-65.4766321943102
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"LA GUARDIA",
            "id":"10070090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Guardia",
               "id":"10070090"
            },
            "centroide":{
               "lat":-29.5496731497758,
               "lon":-65.4504857993817
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Icaño",
               "id":"100168"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"LAS ESQUINAS",
            "id":"10070100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Esquinas",
               "id":"10070100"
            },
            "centroide":{
               "lat":-28.7644628061471,
               "lon":-65.1120210167305
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"LAS PALMITAS",
            "id":"10070110000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Palmitas",
               "id":"10070110"
            },
            "centroide":{
               "lat":-28.6402396947153,
               "lon":-64.9870716926629
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Icaño",
               "id":"100168"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"QUIROS",
            "id":"10070120000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Quirós",
               "id":"10070120"
            },
            "centroide":{
               "lat":-28.7883056058069,
               "lon":-65.1007302335708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"RAMBLONES",
            "id":"10070130000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Ramblones",
               "id":"10070130"
            },
            "centroide":{
               "lat":-29.1582844511715,
               "lon":-65.374945475853
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"100175"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"RECREO",
            "id":"10070140000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Recreo",
               "id":"10070140"
            },
            "centroide":{
               "lat":-29.2768611237227,
               "lon":-65.0565641552447
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Icaño",
               "id":"100168"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"10070"
            },
            "nombre":"SAN ANTONIO",
            "id":"10070150000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"10070150"
            },
            "centroide":{
               "lat":-28.9331053161662,
               "lon":-65.0949655869377
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"AMADORES",
            "id":"10077010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Amadores",
               "id":"10077010"
            },
            "centroide":{
               "lat":-28.2680363798746,
               "lon":-65.6462108146466
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"EL ROSARIO",
            "id":"10077020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Rosario",
               "id":"10077020"
            },
            "centroide":{
               "lat":-27.9877541032226,
               "lon":-65.688606939529
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"LA BAJADA",
            "id":"10077030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Bajada",
               "id":"10077030"
            },
            "centroide":{
               "lat":-28.3920524907261,
               "lon":-65.6282649899935
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"LA HIGUERA",
            "id":"10077040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Higuera",
               "id":"10077040"
            },
            "centroide":{
               "lat":-27.9352828616507,
               "lon":-65.699367489363
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"LA MERCED",
            "id":"10077050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Merced",
               "id":"10077050"
            },
            "centroide":{
               "lat":-28.1537649054231,
               "lon":-65.6696062414473
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"LA VIÑA",
            "id":"10077060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Viña",
               "id":"10077060"
            },
            "centroide":{
               "lat":-28.044445782746,
               "lon":-65.6079383578395
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"LAS LAJAS",
            "id":"10077070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Lajas",
               "id":"10077070"
            },
            "centroide":{
               "lat":-27.826913357423,
               "lon":-65.7401079267206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"MONTE POTRERO",
            "id":"10077080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Monte Potrero",
               "id":"10077080"
            },
            "centroide":{
               "lat":-28.19124607288,
               "lon":-65.670675013192
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"PALO LABRADO",
            "id":"10077090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Palo Labrado",
               "id":"10077090"
            },
            "centroide":{
               "lat":-28.3360934480518,
               "lon":-65.6274299147885
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"SAN ANTONIO",
            "id":"10077100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"10077100"
            },
            "centroide":{
               "lat":-28.0073083693979,
               "lon":-65.726850392729
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paclín",
               "id":"100182"
            },
            "departamento":{
               "nombre":"Paclín",
               "id":"10077"
            },
            "nombre":"VILLA DE BALCOZNA",
            "id":"10077110000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Villa de Balcozna",
               "id":"10077110"
            },
            "centroide":{
               "lat":-27.87835755933,
               "lon":-65.7190758944755
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mutquín",
               "id":"100185"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"APOYACO",
            "id":"10084010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Apoyaco",
               "id":"10084010"
            },
            "centroide":{
               "lat":-28.3006337911705,
               "lon":-66.1659791753183
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mutquín",
               "id":"100185"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"COLANA",
            "id":"10084020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Colana",
               "id":"10084020"
            },
            "centroide":{
               "lat":-28.3432877302321,
               "lon":-66.1222559102194
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saujil",
               "id":"100196"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"COLPES",
            "id":"10084030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Colpes",
               "id":"10084030"
            },
            "centroide":{
               "lat":-28.0606503909839,
               "lon":-66.2065039006705
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pomán",
               "id":"100189"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"EL PAJONAL",
            "id":"10084040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Pajonal",
               "id":"10084040"
            },
            "centroide":{
               "lat":-28.3773148491306,
               "lon":-66.3017092655099
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saujil",
               "id":"100196"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"JOYANGO",
            "id":"10084050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Joyango",
               "id":"10084050"
            },
            "centroide":{
               "lat":-28.0731019653889,
               "lon":-66.1435569766782
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mutquín",
               "id":"100185"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"MUTQUIN",
            "id":"10084060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Mutquin",
               "id":"10084060"
            },
            "centroide":{
               "lat":-28.3178455729042,
               "lon":-66.1420642866296
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pomán",
               "id":"100189"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"POMAN",
            "id":"10084070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Pomán",
               "id":"10084070"
            },
            "centroide":{
               "lat":-28.3927801149204,
               "lon":-66.2220823999264
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saujil",
               "id":"100196"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"RINCON",
            "id":"10084080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Rincón",
               "id":"10084080"
            },
            "centroide":{
               "lat":-28.2022631098292,
               "lon":-66.148799132888
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saujil",
               "id":"100196"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"SAN MIGUEL",
            "id":"10084090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"10084090"
            },
            "centroide":{
               "lat":-28.1297413013666,
               "lon":-66.2019565174868
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saujil",
               "id":"100196"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"SAUJIL",
            "id":"10084100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Saujil",
               "id":"10084100"
            },
            "centroide":{
               "lat":-28.173337080009,
               "lon":-66.2145148644181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saujil",
               "id":"100196"
            },
            "departamento":{
               "nombre":"Pomán",
               "id":"10084"
            },
            "nombre":"SIJAN",
            "id":"10084110000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Siján",
               "id":"10084110"
            },
            "centroide":{
               "lat":-28.2623558875079,
               "lon":-66.2209299175292
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"ANDALHUALA",
            "id":"10091010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Andalhualá",
               "id":"10091010"
            },
            "centroide":{
               "lat":-26.8479427341705,
               "lon":-66.0244068592756
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"CASPICHANGO",
            "id":"10091020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Caspichango",
               "id":"10091020"
            },
            "centroide":{
               "lat":-26.6859290047178,
               "lon":-65.970794986953
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"CHAÑAR PUNCO",
            "id":"10091030001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Chañar Punco",
               "id":"10091030"
            },
            "centroide":{
               "lat":-26.7588342868363,
               "lon":-66.0493067195716
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"LAMPACITO",
            "id":"10091030002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Chañar Punco",
               "id":"10091030"
            },
            "centroide":{
               "lat":-26.7265981975072,
               "lon":-66.0436979128184
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"MEDANITOS",
            "id":"10091030003",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Chañar Punco",
               "id":"10091030"
            },
            "centroide":{
               "lat":-26.7588342868363,
               "lon":-66.0493067195716
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"EL CAJON",
            "id":"10091040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Cajón",
               "id":"10091040"
            },
            "centroide":{
               "lat":-26.3955476915097,
               "lon":-66.2641930164042
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"EL DESMONTE",
            "id":"10091050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Desmonte",
               "id":"10091050"
            },
            "centroide":{
               "lat":-26.9077168676094,
               "lon":-66.020423153794
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"EL PUESTO",
            "id":"10091060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Puesto",
               "id":"10091060"
            },
            "centroide":{
               "lat":-26.6307679478768,
               "lon":-66.0140418967533
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"FAMATANCA",
            "id":"10091070001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Famatanca",
               "id":"10091070"
            },
            "centroide":{
               "lat":-26.8101195558413,
               "lon":-66.0595221384282
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"SAN JOSE BANDA",
            "id":"10091070002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Famatanca",
               "id":"10091070"
            },
            "centroide":{
               "lat":-26.8101195558413,
               "lon":-66.0595221384282
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"FUERTE QUEMADO",
            "id":"10091080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Fuerte Quemado",
               "id":"10091080"
            },
            "centroide":{
               "lat":-26.6304336405934,
               "lon":-66.0644373734469
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"LA HOYADA",
            "id":"10091090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Hoyada",
               "id":"10091090"
            },
            "centroide":{
               "lat":-26.5189083514476,
               "lon":-66.3686109740362
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"LA LOMA",
            "id":"10091100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"La Loma",
               "id":"10091100"
            },
            "centroide":{
               "lat":-26.7574990827117,
               "lon":-66.0331064865258
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"EL CERRITO",
            "id":"10091110001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Mojarras",
               "id":"10091110"
            },
            "centroide":{
               "lat":-26.6988604981977,
               "lon":-66.0387334407325
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"LAS MOJARRAS",
            "id":"10091110002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Mojarras",
               "id":"10091110"
            },
            "centroide":{
               "lat":-26.6988604981977,
               "lon":-66.0387334407325
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"LORO HUASI",
            "id":"10091120000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Loro Huasi",
               "id":"10091120"
            },
            "centroide":{
               "lat":-26.7361383729613,
               "lon":-66.0217845814201
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"PUNTA DE BALASTO",
            "id":"10091130000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Punta de Balasto",
               "id":"10091130"
            },
            "centroide":{
               "lat":-26.9300943985158,
               "lon":-66.1487294264287
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"CASA DE PIEDRA",
            "id":"10091140001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10091140"
            },
            "centroide":{
               "lat":-26.8274550484771,
               "lon":-66.0506274507575
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"LA PUNTILLA",
            "id":"10091140002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10091140"
            },
            "centroide":{
               "lat":-26.8274550484771,
               "lon":-66.0506274507575
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"PALO SECO",
            "id":"10091140003",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10091140"
            },
            "centroide":{
               "lat":-26.8274550484771,
               "lon":-66.0506274507575
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"SAN JOSE NORTE",
            "id":"10091140004",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10091140"
            },
            "centroide":{
               "lat":-26.7745985033315,
               "lon":-66.0362576892132
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"SAN JOSE VILLA",
            "id":"10091140005",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"10091140"
            },
            "centroide":{
               "lat":-26.7919331343022,
               "lon":-66.0378776334838
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"100210"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"SANTA MARIA",
            "id":"10091150000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Santa María",
               "id":"10091150"
            },
            "centroide":{
               "lat":-26.6891167120231,
               "lon":-66.018884441311
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"100203"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"10091"
            },
            "nombre":"YAPES",
            "id":"10091160000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Yapes",
               "id":"10091160"
            },
            "centroide":{
               "lat":-26.8289862929732,
               "lon":-66.0179166770413
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Altos",
               "id":"100213"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"ALIJILAN",
            "id":"10098010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Alijilán",
               "id":"10098010"
            },
            "centroide":{
               "lat":-28.1771812692249,
               "lon":-65.4915924205872
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"100217"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"BAÑADO DE OVANTA",
            "id":"10098020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Bañado de Ovanta",
               "id":"10098020"
            },
            "centroide":{
               "lat":-28.1034003361447,
               "lon":-65.3076846046355
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"100217"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"LAS CAÑAS",
            "id":"10098030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Cañas",
               "id":"10098030"
            },
            "centroide":{
               "lat":-28.2098696025062,
               "lon":-65.2230167049514
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"100217"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"LAVALLE",
            "id":"10098040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Lavalle",
               "id":"10098040"
            },
            "centroide":{
               "lat":-28.1946338127178,
               "lon":-65.1137806266473
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Altos",
               "id":"100213"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"LOS ALTOS",
            "id":"10098050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Altos",
               "id":"10098050"
            },
            "centroide":{
               "lat":-28.0488590493489,
               "lon":-65.4973640431533
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Altos",
               "id":"100213"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"MANANTIALES",
            "id":"10098060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Manantiales",
               "id":"10098060"
            },
            "centroide":{
               "lat":-28.1469656455994,
               "lon":-65.5073235747446
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"100217"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"10098"
            },
            "nombre":"SAN PEDRO",
            "id":"10098070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"10098070"
            },
            "centroide":{
               "lat":-27.9608485883022,
               "lon":-65.1680076958397
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"ANILLACO",
            "id":"10105010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Anillaco",
               "id":"10105010"
            },
            "centroide":{
               "lat":-27.9001528461069,
               "lon":-67.6142838741591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"ANTINACO",
            "id":"10105020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Antinaco",
               "id":"10105020"
            },
            "centroide":{
               "lat":-27.231445564254,
               "lon":-67.6080549409746
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"BANDA DE LUCERO",
            "id":"10105030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Banda de Lucero",
               "id":"10105030"
            },
            "centroide":{
               "lat":-28.0687850626962,
               "lon":-67.50770242056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"CERRO NEGRO",
            "id":"10105040000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Cerro Negro",
               "id":"10105040"
            },
            "centroide":{
               "lat":-28.2432405441597,
               "lon":-67.144273179127
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"COPACABANA",
            "id":"10105050001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Copacabana",
               "id":"10105050"
            },
            "centroide":{
               "lat":-28.1243914662106,
               "lon":-67.4981013989976
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"LA PUNTILLA",
            "id":"10105050002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Copacabana",
               "id":"10105050"
            },
            "centroide":{
               "lat":-28.1243914662106,
               "lon":-67.4981013989976
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"CORDOBITA",
            "id":"10105060000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Cordobita",
               "id":"10105060"
            },
            "centroide":{
               "lat":-28.2979798704965,
               "lon":-67.1670904449903
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"COSTA DE REYES",
            "id":"10105070000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Costa de Reyes",
               "id":"10105070"
            },
            "centroide":{
               "lat":-28.294959923009,
               "lon":-67.702902490024
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"EL PUEBLITO",
            "id":"10105080000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Pueblito",
               "id":"10105080"
            },
            "centroide":{
               "lat":-28.2888183254668,
               "lon":-67.1234522983543
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"EL PUESTO",
            "id":"10105090000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Puesto",
               "id":"10105090"
            },
            "centroide":{
               "lat":-27.9267621470675,
               "lon":-67.6304002049274
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"EL SALADO",
            "id":"10105100000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Salado",
               "id":"10105100"
            },
            "centroide":{
               "lat":-28.3120206339513,
               "lon":-67.250879321266
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"FIAMBALA",
            "id":"10105110001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Fiambalá",
               "id":"10105110"
            },
            "centroide":{
               "lat":-27.69316213413,
               "lon":-67.608574901701
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"LA RAMADITA",
            "id":"10105110002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Fiambalá",
               "id":"10105110"
            },
            "centroide":{
               "lat":-27.6442427673373,
               "lon":-67.6139458012753
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"PAMPA BLANCA",
            "id":"10105110003",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Fiambalá",
               "id":"10105110"
            },
            "centroide":{
               "lat":-27.6354340349652,
               "lon":-67.6168389660358
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"LOS BALVERDIS",
            "id":"10105120000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Los Balverdis",
               "id":"10105120"
            },
            "centroide":{
               "lat":-28.2769854029709,
               "lon":-67.1074444099184
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"MEDANITOS",
            "id":"10105130000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Medanitos",
               "id":"10105130"
            },
            "centroide":{
               "lat":-27.5239130706008,
               "lon":-67.5891477326484
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"PALO BLANCO",
            "id":"10105140000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Palo Blanco",
               "id":"10105140"
            },
            "centroide":{
               "lat":-27.3395852802531,
               "lon":-67.7592295432626
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"PUNTA DEL AGUA",
            "id":"10105150000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Punta del Agua",
               "id":"10105150"
            },
            "centroide":{
               "lat":-27.2106668219369,
               "lon":-67.7317928557182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"SAUJIL",
            "id":"10105160000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Saujil",
               "id":"10105160"
            },
            "centroide":{
               "lat":-27.5632127581426,
               "lon":-67.6355130611556
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fiambalá",
               "id":"100224"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"TATON",
            "id":"10105170000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Tatón",
               "id":"10105170"
            },
            "centroide":{
               "lat":-27.3270122428844,
               "lon":-67.4747701838904
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinogasta",
               "id":"100231"
            },
            "departamento":{
               "nombre":"Tinogasta",
               "id":"10105"
            },
            "nombre":"TINOGASTA",
            "id":"10105180000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Tinogasta",
               "id":"10105180"
            },
            "centroide":{
               "lat":-28.0637510698675,
               "lon":-67.5802695760226
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"EL PORTEZUELO",
            "id":"10112010000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"El Portezuelo",
               "id":"10112010"
            },
            "centroide":{
               "lat":-28.4818680419511,
               "lon":-65.6351326652418
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"HUAYCAMA",
            "id":"10112020000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Huaycama",
               "id":"10112020"
            },
            "centroide":{
               "lat":-28.5334483576648,
               "lon":-65.6821279512165
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"LAS TEJAS",
            "id":"10112030000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Las Tejas",
               "id":"10112030"
            },
            "centroide":{
               "lat":-28.6468889609952,
               "lon":-65.7889935499028
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"EL BAÑADO",
            "id":"10112040001",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.4594352492075,
               "lon":-65.7113717209433
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"POLCOS",
            "id":"10112040002",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.4333815878035,
               "lon":-65.7202973350365
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"POZO DEL MISTOL",
            "id":"10112040003",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.4689789857859,
               "lon":-65.7195047318519
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"SAN ISIDRO",
            "id":"10112040004",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.4583109666735,
               "lon":-65.726757673457
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"SANTA ROSA",
            "id":"10112040005",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.447802287271,
               "lon":-65.7055328995515
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"SUMALAO",
            "id":"10112040006",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.4746455518307,
               "lon":-65.7433480027028
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"VILLA DOLORES",
            "id":"10112040007",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"10112040"
            },
            "centroide":{
               "lat":-28.4434739220039,
               "lon":-65.7215360640125
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Viejo",
               "id":"100238"
            },
            "departamento":{
               "nombre":"Valle Viejo",
               "id":"10112"
            },
            "nombre":"SANTA CRUZ",
            "id":"10112050000",
            "provincia":{
               "nombre":"Catamarca",
               "id":"10"
            },
            "localidad_censal":{
               "nombre":"Santa Cruz",
               "id":"10112050"
            },
            "centroide":{
               "lat":-28.4928946549812,
               "lon":-65.6744465587279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Amboy",
               "id":"142007"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"AMBOY",
            "id":"14007010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Amboy",
               "id":"14007010"
            },
            "centroide":{
               "lat":-32.1759165218621,
               "lon":-64.5765483175138
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"ARROYO SAN ANTONIO",
            "id":"14007020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Arroyo San Antonio",
               "id":"14007020"
            },
            "centroide":{
               "lat":-32.26205146004,
               "lon":-64.5937253212731
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada del Sauce",
               "id":"142021"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"CAÑADA DEL SAUCE",
            "id":"14007030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cañada del Sauce",
               "id":"14007030"
            },
            "centroide":{
               "lat":-32.3679991604313,
               "lon":-64.6425223427633
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa General Belgrano",
               "id":"140049"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"CAPILLA VIEJA",
            "id":"14007040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Capilla Vieja",
               "id":"14007040"
            },
            "centroide":{
               "lat":-31.942027416529,
               "lon":-64.6165406145348
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Rumipal",
               "id":"140063"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"EL CORCOVADO - EL TORREON",
            "id":"14007050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Corcovado - El Torreón",
               "id":"14007050"
            },
            "centroide":{
               "lat":-32.149759071844,
               "lon":-64.5015914694579
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Yacanto",
               "id":"140070"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"EL DURAZNO",
            "id":"14007055000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Durazno",
               "id":"14007055"
            },
            "centroide":{
               "lat":-32.1695656120552,
               "lon":-64.7747710529989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embalse",
               "id":"140007"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"EMBALSE",
            "id":"14007060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Embalse",
               "id":"14007060"
            },
            "centroide":{
               "lat":-32.2065076875762,
               "lon":-64.4006226825819
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cruz",
               "id":"140014"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LA CRUZ",
            "id":"14007070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Cruz",
               "id":"14007070"
            },
            "centroide":{
               "lat":-32.3034055718452,
               "lon":-64.4831089740345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cumbrecita",
               "id":"143015"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LA CUMBRECITA",
            "id":"14007080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Cumbrecita",
               "id":"14007080"
            },
            "centroide":{
               "lat":-31.8969643877578,
               "lon":-64.7751589433589
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Bajadas",
               "id":"142028"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LAS BAJADAS",
            "id":"14007090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Bajadas",
               "id":"14007090"
            },
            "centroide":{
               "lat":-32.0954063583092,
               "lon":-64.3310535546374
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Caleras",
               "id":"142035"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LAS CALERAS",
            "id":"14007100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Caleras",
               "id":"14007100"
            },
            "centroide":{
               "lat":-32.3893612457104,
               "lon":-64.5185923418535
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Cóndores",
               "id":"140021"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LOS CONDORES",
            "id":"14007110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cóndores",
               "id":"14007110"
            },
            "centroide":{
               "lat":-32.3211277679392,
               "lon":-64.2810080327514
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Molinos",
               "id":"142042"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LOS MOLINOS",
            "id":"14007120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Molinos",
               "id":"14007120"
            },
            "centroide":{
               "lat":-31.8568223164309,
               "lon":-64.3779521586489
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Molinos",
               "id":"142042"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LOS MOLINOS",
            "id":"14007120001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Molinos",
               "id":"14007120"
            },
            "centroide":{
               "lat":-31.8582901636165,
               "lon":-64.3750577906576
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Molinos",
               "id":"142042"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA SAN MIGUEL",
            "id":"14007120002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Molinos",
               "id":"14007120"
            },
            "centroide":{
               "lat":-31.8582901636165,
               "lon":-64.3750577906576
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Reartes",
               "id":"142049"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LOS REARTES",
            "id":"14007130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Reartes",
               "id":"14007130"
            },
            "centroide":{
               "lat":-31.919856077953,
               "lon":-64.5755037073336
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lutti",
               "id":"142056"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"LUTTI",
            "id":"14007140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Lutti",
               "id":"14007140"
            },
            "centroide":{
               "lat":-32.2989441032587,
               "lon":-64.7254379234594
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calmayo",
               "id":"142014"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"PARQUE CALMAYO",
            "id":"14007160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Calmayo",
               "id":"14007160"
            },
            "centroide":{
               "lat":-32.0237443074993,
               "lon":-64.4641636844138
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río de los Sauces",
               "id":"140028"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"RIO DE LOS SAUCES",
            "id":"14007170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río de los Sauces",
               "id":"14007170"
            },
            "centroide":{
               "lat":-32.5265595269976,
               "lon":-64.5869530802431
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Agustín",
               "id":"140056"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SAN AGUSTIN",
            "id":"14007180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Agustín",
               "id":"14007180"
            },
            "centroide":{
               "lat":-31.9763155149128,
               "lon":-64.3733067164283
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Ignacio",
               "id":"142063"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SAN IGNACIO (LOTEO SAN JAVIER)",
            "id":"14007190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Ignacio (Loteo San Javier)",
               "id":"14007190"
            },
            "centroide":{
               "lat":-32.1667856733263,
               "lon":-64.516397206098
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"140035"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SANTA ROSA DE CALAMUCHITA",
            "id":"14007210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"14007210"
            },
            "centroide":{
               "lat":-32.0700578177618,
               "lon":-64.537633731877
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"140035"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SANTA MONICA",
            "id":"14007210001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"14007210"
            },
            "centroide":{
               "lat":-32.0665951444652,
               "lon":-64.5791018557419
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"140035"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SANTA ROSA DE CALAMUCHITA",
            "id":"14007210002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"14007210"
            },
            "centroide":{
               "lat":-32.0731573746398,
               "lon":-64.5447323761644
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"140035"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SAN IGNACIO (LOTEO VELEZ CRESPO)",
            "id":"14007210003",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Calamuchita",
               "id":"14007210"
            },
            "centroide":{
               "lat":-32.0994512671733,
               "lon":-64.5337591760097
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Segunda Usina",
               "id":"142070"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SEGUNDA USINA",
            "id":"14007220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Segunda Usina",
               "id":"14007220"
            },
            "centroide":{
               "lat":-32.1650425977053,
               "lon":-64.3784225873001
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad Parque los Reartes",
               "id":"142084"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"SOLAR DE LOS MOLINOS",
            "id":"14007230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Solar de los Molinos",
               "id":"14007230"
            },
            "centroide":{
               "lat":-31.8262216703829,
               "lon":-64.5197802668561
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA ALPINA",
            "id":"14007240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Alpina",
               "id":"14007240"
            },
            "centroide":{
               "lat":-31.9532611007669,
               "lon":-64.8132541353652
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Amancay",
               "id":"142077"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA AMANCAY",
            "id":"14007250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Amancay",
               "id":"14007250"
            },
            "centroide":{
               "lat":-32.1863928332037,
               "lon":-64.570452981186
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cumbrecita",
               "id":"143015"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA BERNA",
            "id":"14007260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Berna",
               "id":"14007260"
            },
            "centroide":{
               "lat":-31.9052382568315,
               "lon":-64.7428823583405
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad Parque los Reartes",
               "id":"142084"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA CIUDAD PARQUE LOS REARTES (1a.SECC",
            "id":"14007270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad Parque Los Reartes (1a. Sección)",
               "id":"14007270"
            },
            "centroide":{
               "lat":-31.9118704947274,
               "lon":-64.5279613493097
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad Parque los Reartes",
               "id":"142084"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA CIUDAD PARQUE LOS REARTES",
            "id":"14007270001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad Parque Los Reartes (1a. Sección)",
               "id":"14007270"
            },
            "centroide":{
               "lat":-31.9091576117526,
               "lon":-64.5456887371347
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad Parque los Reartes",
               "id":"142084"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA CIUDAD PARQUES LOS REARTES (1° SECCION)",
            "id":"14007270002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad Parque Los Reartes (1a. Sección)",
               "id":"14007270"
            },
            "centroide":{
               "lat":-31.9091576117526,
               "lon":-64.5456887371347
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad Parque los Reartes",
               "id":"142084"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA CIUDAD PARQUE LOS REARTES (3° SECCION)",
            "id":"14007270003",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad Parque Los Reartes (1a. Sección)",
               "id":"14007270"
            },
            "centroide":{
               "lat":-31.9091576117526,
               "lon":-64.5456887371347
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Dique",
               "id":"140042"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA DEL DIQUE",
            "id":"14007290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Dique",
               "id":"14007290"
            },
            "centroide":{
               "lat":-32.1696837164743,
               "lon":-64.4567952849232
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA EL TALA",
            "id":"14007300000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa El Tala",
               "id":"14007300"
            },
            "centroide":{
               "lat":-32.2542673612565,
               "lon":-64.5850039184628
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa General Belgrano",
               "id":"140049"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA GENERAL BELGRANO",
            "id":"14007310000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa General Belgrano",
               "id":"14007310"
            },
            "centroide":{
               "lat":-31.9808236449718,
               "lon":-64.5606191505919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Quillinzo",
               "id":"142091"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA LA RIVERA",
            "id":"14007320000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa La Rivera",
               "id":"14007320"
            },
            "centroide":{
               "lat":-32.2667329359208,
               "lon":-64.5204928765463
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Quillinzo",
               "id":"142091"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA QUILLINZO",
            "id":"14007330000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Quillinzo",
               "id":"14007330"
            },
            "centroide":{
               "lat":-32.2384365356542,
               "lon":-64.5210498971139
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Rumipal",
               "id":"140063"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA RUMIPAL",
            "id":"14007340000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Rumipal",
               "id":"14007340"
            },
            "centroide":{
               "lat":-32.1893813417324,
               "lon":-64.4792063354191
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Yacanto",
               "id":"140070"
            },
            "departamento":{
               "nombre":"Calamuchita",
               "id":"14007"
            },
            "nombre":"VILLA YACANTO",
            "id":"14007360000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Yacanto",
               "id":"14007360"
            },
            "centroide":{
               "lat":-32.1037825310006,
               "lon":-64.7541028295247
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Córdoba",
               "id":"140077"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"14014"
            },
            "nombre":"CORDOBA",
            "id":"14014010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Córdoba",
               "id":"14014010"
            },
            "centroide":{
               "lat":-31.4138166206931,
               "lon":-64.1833384346292
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Córdoba",
               "id":"140077"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"14014"
            },
            "nombre":"JARDIN ARENALES",
            "id":"14014010001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Córdoba",
               "id":"14014010"
            },
            "centroide":{
               "lat":-31.3686406343815,
               "lon":-64.0603031964104
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Córdoba",
               "id":"140077"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"14014"
            },
            "nombre":"LA FLORESTA",
            "id":"14014010002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Córdoba",
               "id":"14014010"
            },
            "centroide":{
               "lat":-31.3961494977945,
               "lon":-64.0609143527134
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Córdoba",
               "id":"140077"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"14014"
            },
            "nombre":"CORDOBA",
            "id":"14014010003",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Córdoba",
               "id":"14014010"
            },
            "centroide":{
               "lat":-31.4177432635316,
               "lon":-64.189601351685
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Agua de Oro",
               "id":"140084"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"AGUA DE ORO",
            "id":"14021010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Agua de Oro",
               "id":"14021010"
            },
            "centroide":{
               "lat":-31.058228127494,
               "lon":-64.2955793576311
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Granja",
               "id":"140126"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"ASCOCHINGA",
            "id":"14021020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ascochinga",
               "id":"14021020"
            },
            "centroide":{
               "lat":-30.9594645513035,
               "lon":-64.2754165610863
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Ceballos",
               "id":"140147"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"BARRIO NUEVO RIO CEBALLOS",
            "id":"14021025000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Barrio Nuevo Río Ceballos",
               "id":"14021025"
            },
            "centroide":{
               "lat":-31.1980230004722,
               "lon":-64.2887924252949
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Manzano",
               "id":"142105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"CANTERAS EL SAUCE",
            "id":"14021030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Canteras El Sauce",
               "id":"14021030"
            },
            "centroide":{
               "lat":-31.0864140054075,
               "lon":-64.3145341004157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Calera",
               "id":"140119"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"CASA BAMBA",
            "id":"14021040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Casa Bamba",
               "id":"14021040"
            },
            "centroide":{
               "lat":-31.3429017095306,
               "lon":-64.3992718801553
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Caroya",
               "id":"140091"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"COLONIA CAROYA",
            "id":"14021050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Caroya",
               "id":"14021050"
            },
            "centroide":{
               "lat":-31.0172322466264,
               "lon":-64.066728610829
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Tirolesa",
               "id":"140098"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"COLONIA TIROLESA",
            "id":"14021060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Tirolesa",
               "id":"14021060"
            },
            "centroide":{
               "lat":-31.2357859283773,
               "lon":-64.0672541009396
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Vicente Agüero",
               "id":"142098"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"COLONIA VICENTE AGUERO",
            "id":"14021070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Vicente Agüero",
               "id":"14021070"
            },
            "centroide":{
               "lat":-31.0277849308523,
               "lon":-64.019045653731
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"140133"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA CORAZON DE MARIA",
            "id":"14021075000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Corazón de María",
               "id":"14021075"
            },
            "centroide":{
               "lat":-31.4435294401245,
               "lon":-63.9963793951592
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"CORRAL QUEMADO",
            "id":"14021080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Corral Quemado",
               "id":"14021080"
            },
            "centroide":{
               "lat":-30.9890353478112,
               "lon":-64.3778448899044
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Allende",
               "id":"140175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"COUNTRY CHACRAS DE LA VILLA - COUNTRY SA",
            "id":"14021090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Country San Isidro - Country Chacras de la Villa",
               "id":"14021090"
            },
            "centroide":{
               "lat":-31.3015080091569,
               "lon":-64.2382229714889
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Allende",
               "id":"140175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"COUNTRY CHACRAS DE LA VILLA",
            "id":"14021090001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Country San Isidro - Country Chacras de la Villa",
               "id":"14021090"
            },
            "centroide":{
               "lat":-31.2982310728982,
               "lon":-64.2371416516445
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Allende",
               "id":"140175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"COUNTRY SAN ISIDRO",
            "id":"14021090002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Country San Isidro - Country Chacras de la Villa",
               "id":"14021090"
            },
            "centroide":{
               "lat":-31.3055336354525,
               "lon":-64.2480169288216
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Manzano",
               "id":"142105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"EL MANZANO",
            "id":"14021110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Manzano",
               "id":"14021110"
            },
            "centroide":{
               "lat":-31.0817617139175,
               "lon":-64.2998324164847
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Tirolesa",
               "id":"140098"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"ESTACION COLONIA TIROLESA",
            "id":"14021120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Estación Colonia Tirolesa",
               "id":"14021120"
            },
            "centroide":{
               "lat":-31.2735550500587,
               "lon":-64.0150545730464
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación General Paz",
               "id":"142112"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"GENERAL PAZ",
            "id":"14021130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Paz",
               "id":"14021130"
            },
            "centroide":{
               "lat":-31.1341189126982,
               "lon":-64.1410056588479
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jesús María",
               "id":"140112"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"JESUS MARIA",
            "id":"14021140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Jesús María",
               "id":"14021140"
            },
            "centroide":{
               "lat":-30.9811937384049,
               "lon":-64.095771286873
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Calera",
               "id":"140119"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LA CALERA",
            "id":"14021150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Calera",
               "id":"14021150"
            },
            "centroide":{
               "lat":-31.3441541784042,
               "lon":-64.3368093736089
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Calera",
               "id":"140119"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"DUMESNIL",
            "id":"14021150001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Calera",
               "id":"14021150"
            },
            "centroide":{
               "lat":-31.3321162961153,
               "lon":-64.3336598560636
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Calera",
               "id":"140119"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LA CALERA",
            "id":"14021150002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Calera",
               "id":"14021150"
            },
            "centroide":{
               "lat":-31.3430963762885,
               "lon":-64.3302275470709
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Calera",
               "id":"140119"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"EL DIQUECITO",
            "id":"14021150003",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Calera",
               "id":"14021150"
            },
            "centroide":{
               "lat":-31.3589143636745,
               "lon":-64.3650932467319
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Granja",
               "id":"140126"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LA GRANJA",
            "id":"14021160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Granja",
               "id":"14021160"
            },
            "centroide":{
               "lat":-31.0054374122172,
               "lon":-64.2616783510445
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Allende",
               "id":"140175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LA MORADA",
            "id":"14021165000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Morada",
               "id":"14021165"
            },
            "centroide":{
               "lat":-31.3050726647191,
               "lon":-64.2625844071662
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Tirolesa",
               "id":"140098"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LA PUERTA",
            "id":"14021170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Puerta",
               "id":"14021170"
            },
            "centroide":{
               "lat":-31.1419300602666,
               "lon":-64.0402188172603
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Unquillo",
               "id":"140168"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LAS CORZUELAS",
            "id":"14021175000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Corzuelas",
               "id":"14021175"
            },
            "centroide":{
               "lat":-31.240896079449,
               "lon":-64.2618872007094
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Granja",
               "id":"140126"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"LOS MOLLES",
            "id":"14021180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Molles",
               "id":"14021180"
            },
            "centroide":{
               "lat":-31.0167070305726,
               "lon":-64.225388080483
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malvinas Argentinas",
               "id":"140133"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"MALVINAS ARGENTINAS",
            "id":"14021190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Malvinas Argentinas",
               "id":"14021190"
            },
            "centroide":{
               "lat":-31.3677025435362,
               "lon":-64.0503196448495
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mendiolaza",
               "id":"140140"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"MENDIOLAZA",
            "id":"14021200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mendiolaza",
               "id":"14021200"
            },
            "centroide":{
               "lat":-31.2634679794013,
               "lon":-64.3038336466706
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mi Granja",
               "id":"142119"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"MI GRANJA",
            "id":"14021210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mi Granja",
               "id":"14021210"
            },
            "centroide":{
               "lat":-31.3501106609981,
               "lon":-63.9996450992295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Ceballos",
               "id":"140147"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"PAJAS BLANCAS",
            "id":"14021220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pajas Blancas",
               "id":"14021220"
            },
            "centroide":{
               "lat":-31.2146356684786,
               "lon":-64.2768259906649
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Ceballos",
               "id":"140147"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"RIO CEBALLOS",
            "id":"14021230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río Ceballos",
               "id":"14021230"
            },
            "centroide":{
               "lat":-31.1748535571499,
               "lon":-64.3096761754849
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saldán",
               "id":"140154"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"SALDAN",
            "id":"14021240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Saldán",
               "id":"14021240"
            },
            "centroide":{
               "lat":-31.3142696814132,
               "lon":-64.3129043036137
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salsipuedes",
               "id":"140161"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"SALSIPUEDES",
            "id":"14021250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Salsipuedes",
               "id":"14021250"
            },
            "centroide":{
               "lat":-31.1388344121698,
               "lon":-64.2906610433299
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salsipuedes",
               "id":"140161"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"EL PUEBLITO",
            "id":"14021250001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Salsipuedes",
               "id":"14021250"
            },
            "centroide":{
               "lat":-31.1065564116392,
               "lon":-64.2971945109864
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salsipuedes",
               "id":"140161"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"SALSIPUEDES",
            "id":"14021250002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Salsipuedes",
               "id":"14021250"
            },
            "centroide":{
               "lat":-31.1349269310989,
               "lon":-64.2873331074367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Tirolesa",
               "id":"140098"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"SANTA ELENA",
            "id":"14021260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Elena",
               "id":"14021260"
            },
            "centroide":{
               "lat":-31.2593058004875,
               "lon":-64.0753189644633
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tinoco",
               "id":"142728"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"TINOCO",
            "id":"14021270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tinoco",
               "id":"14021270"
            },
            "centroide":{
               "lat":-31.1238790204144,
               "lon":-63.8923777817115
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Unquillo",
               "id":"140168"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"UNQUILLO",
            "id":"14021280000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Unquillo",
               "id":"14021280"
            },
            "centroide":{
               "lat":-31.2319725034056,
               "lon":-64.3177425598673
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Allende",
               "id":"140175"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA ALLENDE",
            "id":"14021290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Allende",
               "id":"14021290"
            },
            "centroide":{
               "lat":-31.2922067561376,
               "lon":-64.2950077746202
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Cerro Azul",
               "id":"142126"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA CERRO AZUL",
            "id":"14021300000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Cerro Azul",
               "id":"14021300"
            },
            "centroide":{
               "lat":-31.0702139869894,
               "lon":-64.3197333591217
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Córdoba",
               "id":"140077"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA EL FACHINAL - PARQUE NORTE - GUIÑA",
            "id":"14021310000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.3101307241869,
               "lon":-64.1805270805742
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"GUIÑAZU NORTE",
            "id":"14021310001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.3067152892491,
               "lon":-64.1703253616993
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"PARQUE NORTE",
            "id":"14021310002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.3000754366056,
               "lon":-64.1767428650772
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"1 DE AGOSTO",
            "id":"14021310004",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.306907347736,
               "lon":-64.1785314598263
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"ALMIRANTE BROWN",
            "id":"14021310005",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.3075672143524,
               "lon":-64.1814827461648
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"CIUDAD DE LOS NIÑOS",
            "id":"14021310006",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.2957196524063,
               "lon":-64.1768685276575
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA PASTORA",
            "id":"14021310007",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
               "id":"14021310"
            },
            "centroide":{
               "lat":-31.306907347736,
               "lon":-64.1785314598263
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA LOS LLANOS",
            "id":"14021320000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Los Llanos - Juárez Celman",
               "id":"14021320"
            },
            "centroide":{
               "lat":-31.2742571242585,
               "lon":-64.1641142793009
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"JUAREZ CELMAN",
            "id":"14021320001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Los Llanos - Juárez Celman",
               "id":"14021320"
            },
            "centroide":{
               "lat":-31.2570227263756,
               "lon":-64.1651661967333
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Juárez Celman",
               "id":"140105"
            },
            "departamento":{
               "nombre":"Colón",
               "id":"14021"
            },
            "nombre":"VILLA LOS LLANOS",
            "id":"14021320002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Los Llanos - Juárez Celman",
               "id":"14021320"
            },
            "centroide":{
               "lat":-31.2757010937435,
               "lon":-64.1626835394284
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alto de los Quebrachos",
               "id":"142133"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"ALTO DE LOS QUEBRACHOS",
            "id":"14028010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alto de los Quebrachos",
               "id":"14028010"
            },
            "centroide":{
               "lat":-30.5409658147192,
               "lon":-65.0384387721916
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bañado de Soto",
               "id":"142140"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"BAÑADO DE SOTO",
            "id":"14028020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bañado de Soto",
               "id":"14028020"
            },
            "centroide":{
               "lat":-30.8044357869451,
               "lon":-65.0436620185917
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"CANTERAS QUILPO",
            "id":"14028030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Canteras Quilpo",
               "id":"14028030"
            },
            "centroide":{
               "lat":-30.8666932853441,
               "lon":-64.6829010647536
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cruz de Caña",
               "id":"142147"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"CRUZ DE CAÑA",
            "id":"14028040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cruz de Caña",
               "id":"14028040"
            },
            "centroide":{
               "lat":-31.0662466251544,
               "lon":-64.9425321672363
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cruz del Eje",
               "id":"140182"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"CRUZ DEL EJE",
            "id":"14028050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cruz del Eje",
               "id":"14028050"
            },
            "centroide":{
               "lat":-30.7218592173102,
               "lon":-64.8077377312137
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Brete",
               "id":"142154"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"EL BRETE",
            "id":"14028060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Brete",
               "id":"14028060"
            },
            "centroide":{
               "lat":-30.6722100581192,
               "lon":-64.8699070292303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"EL RINCON",
            "id":"14028070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Rincón",
               "id":"14028070"
            },
            "centroide":{
               "lat":-30.7397207125925,
               "lon":-64.6484924752741
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guanaco Muerto",
               "id":"142161"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"GUANACO MUERTO",
            "id":"14028080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Guanaco Muerto",
               "id":"14028080"
            },
            "centroide":{
               "lat":-30.4794131800612,
               "lon":-65.0597627738017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Marcos Sierras",
               "id":"140189"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"LA BANDA",
            "id":"14028090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Banda",
               "id":"14028090"
            },
            "centroide":{
               "lat":-30.7628592235779,
               "lon":-64.6459839334075
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"LA BATEA",
            "id":"14028100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Batea",
               "id":"14028100"
            },
            "centroide":{
               "lat":-30.4417646532675,
               "lon":-65.4238386532105
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Higuera",
               "id":"142175"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"LA HIGUERA",
            "id":"14028110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Higuera",
               "id":"14028110"
            },
            "centroide":{
               "lat":-31.0139548417914,
               "lon":-65.1020313666951
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"LAS CAÑADAS",
            "id":"14028120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Cañadas",
               "id":"14028120"
            },
            "centroide":{
               "lat":-30.9332479488458,
               "lon":-64.7189758691612
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Playas",
               "id":"142189"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"LAS PLAYAS",
            "id":"14028130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Playas",
               "id":"14028130"
            },
            "centroide":{
               "lat":-30.6895900139602,
               "lon":-64.8508831281017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Chañaritos",
               "id":"142819"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"LOS CHAÑARITOS",
            "id":"14028140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Chañaritos",
               "id":"14028140"
            },
            "centroide":{
               "lat":-30.5724823864554,
               "lon":-64.9410778222467
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Media Naranja",
               "id":"142203"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"MEDIA NARANJA",
            "id":"14028150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Media Naranja",
               "id":"14028150"
            },
            "centroide":{
               "lat":-30.6233104657952,
               "lon":-64.9515406139404
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"PASO VIEJO",
            "id":"14028160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Paso Viejo",
               "id":"14028160"
            },
            "centroide":{
               "lat":-30.7686835768001,
               "lon":-65.1864700189142
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Marcos Sierras",
               "id":"140189"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"SAN MARCOS SIERRA",
            "id":"14028170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Marcos Sierra",
               "id":"14028170"
            },
            "centroide":{
               "lat":-30.7803420622583,
               "lon":-64.6468515131076
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Serrezuela",
               "id":"140196"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"SERREZUELA",
            "id":"14028180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Serrezuela",
               "id":"14028180"
            },
            "centroide":{
               "lat":-30.6392026300348,
               "lon":-65.3813009621884
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tuclame",
               "id":"142217"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"TUCLAME",
            "id":"14028190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tuclame",
               "id":"14028190"
            },
            "centroide":{
               "lat":-30.7485284753417,
               "lon":-65.2375410689391
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de Soto",
               "id":"140203"
            },
            "departamento":{
               "nombre":"Cruz del Eje",
               "id":"14028"
            },
            "nombre":"VILLA DE SOTO",
            "id":"14028200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de Soto",
               "id":"14028200"
            },
            "centroide":{
               "lat":-30.852977256889,
               "lon":-64.9924997261449
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Del Campillo",
               "id":"140217"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"DEL CAMPILLO",
            "id":"14035010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Del Campillo",
               "id":"14035010"
            },
            "centroide":{
               "lat":-34.3760847098309,
               "lon":-64.4945399243505
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"ESTACION LECUEDER",
            "id":"14035020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Estación Lecueder",
               "id":"14035020"
            },
            "centroide":{
               "lat":-34.4964110409998,
               "lon":-64.8094621973323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Buchardo",
               "id":"140210"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"HIPOLITO BOUCHARD",
            "id":"14035030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Hipólito Bouchard",
               "id":"14035030"
            },
            "centroide":{
               "lat":-34.7223142041085,
               "lon":-63.508013061936
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huinca Renancó",
               "id":"140224"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"HUINCA RENANCO",
            "id":"14035040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Huinca Renancó",
               "id":"14035040"
            },
            "centroide":{
               "lat":-34.8397251237332,
               "lon":-64.3724876795015
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Italó",
               "id":"140231"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"ITALO",
            "id":"14035050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Italó",
               "id":"14035050"
            },
            "centroide":{
               "lat":-34.7911820710534,
               "lon":-63.781208388865
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mattaldi",
               "id":"140245"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"MATTALDI",
            "id":"14035060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mattaldi",
               "id":"14035060"
            },
            "centroide":{
               "lat":-34.4803867338234,
               "lon":-64.1695033049234
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nicolás Bruzzone",
               "id":"142224"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"NICOLAS BRUZZONE",
            "id":"14035070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Nicolás Bruzzone",
               "id":"14035070"
            },
            "centroide":{
               "lat":-34.4382295005707,
               "lon":-64.3383707319069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Onagoity",
               "id":"142231"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"ONAGOITY",
            "id":"14035080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Onagoity",
               "id":"14035080"
            },
            "centroide":{
               "lat":-34.7703021224105,
               "lon":-63.67032307176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pincen",
               "id":"142238"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"PINCEN",
            "id":"14035090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pincén",
               "id":"14035090"
            },
            "centroide":{
               "lat":-34.8379291437488,
               "lon":-63.9155804696192
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ranqueles",
               "id":"142245"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"RANQUELES",
            "id":"14035100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ranqueles",
               "id":"14035100"
            },
            "centroide":{
               "lat":-34.8436937843761,
               "lon":-64.0987740916452
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jovita",
               "id":"140238"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"SANTA MAGDALENA",
            "id":"14035110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Magdalena",
               "id":"14035110"
            },
            "centroide":{
               "lat":-34.514736598064,
               "lon":-63.941510935076
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Huidobro",
               "id":"140252"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"VILLA HUIDOBRO",
            "id":"14035120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Huidobro",
               "id":"14035120"
            },
            "centroide":{
               "lat":-34.8371817717375,
               "lon":-64.5833571665395
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Sarmiento (S. A.)",
               "id":"141120"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"VILLA SARMIENTO",
            "id":"14035130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Sarmiento",
               "id":"14035130"
            },
            "centroide":{
               "lat":-34.1216195744231,
               "lon":-64.7240499364674
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Valeria",
               "id":"140259"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"14035"
            },
            "nombre":"VILLA VALERIA",
            "id":"14035140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Valeria",
               "id":"14035140"
            },
            "centroide":{
               "lat":-34.3408530354994,
               "lon":-64.917844943457
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"ARROYO ALGODON",
            "id":"14042010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Arroyo Algodón",
               "id":"14042010"
            },
            "centroide":{
               "lat":-32.2019665986816,
               "lon":-63.1627861860009
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Cabral",
               "id":"140273"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"ARROYO CABRAL",
            "id":"14042020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Arroyo Cabral",
               "id":"14042020"
            },
            "centroide":{
               "lat":-32.4889824965456,
               "lon":-63.4014794487713
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ausonia",
               "id":"140280"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"AUSONIA",
            "id":"14042030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ausonia",
               "id":"14042030"
            },
            "centroide":{
               "lat":-32.6613825801545,
               "lon":-63.2447973807791
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chazón",
               "id":"140287"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"CHAZON",
            "id":"14042040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chazón",
               "id":"14042040"
            },
            "centroide":{
               "lat":-33.0772598274988,
               "lon":-63.275435286184
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Etruria",
               "id":"140294"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"ETRURIA",
            "id":"14042050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Etruria",
               "id":"14042050"
            },
            "centroide":{
               "lat":-32.9405157092885,
               "lon":-63.2472565147
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Laguna",
               "id":"140301"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"LA LAGUNA",
            "id":"14042060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Laguna",
               "id":"14042060"
            },
            "centroide":{
               "lat":-32.8014149822474,
               "lon":-63.2440045658431
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Palestina",
               "id":"140308"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"LA PALESTINA",
            "id":"14042070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Palestina",
               "id":"14042070"
            },
            "centroide":{
               "lat":-32.6155884790714,
               "lon":-63.4092352057166
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Playosa",
               "id":"140315"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"LA PLAYOSA",
            "id":"14042080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Playosa",
               "id":"14042080"
            },
            "centroide":{
               "lat":-32.099694860079,
               "lon":-63.0325289648087
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"LAS MOJARRAS",
            "id":"14042090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Mojarras",
               "id":"14042090"
            },
            "centroide":{
               "lat":-32.3029687109385,
               "lon":-63.2328555816575
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luca",
               "id":"140322"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"LUCA",
            "id":"14042100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Luca",
               "id":"14042100"
            },
            "centroide":{
               "lat":-32.5401979602741,
               "lon":-63.4762536378135
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pasco",
               "id":"140329"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"PASCO",
            "id":"14042110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pasco",
               "id":"14042110"
            },
            "centroide":{
               "lat":-32.7470044961114,
               "lon":-63.3411254262064
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ausonia",
               "id":"140280"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"SANABRIA",
            "id":"14042120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sanabria",
               "id":"14042120"
            },
            "centroide":{
               "lat":-32.527560043626,
               "lon":-63.2479616144958
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Silvio Pellico",
               "id":"140336"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"SILVIO PELLICO",
            "id":"14042130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Silvio Pellico",
               "id":"14042130"
            },
            "centroide":{
               "lat":-32.2506338091324,
               "lon":-62.931650118919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ticino",
               "id":"140343"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"TICINO",
            "id":"14042140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ticino",
               "id":"14042140"
            },
            "centroide":{
               "lat":-32.6919271380136,
               "lon":-63.4353044375428
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tío Pujio",
               "id":"140350"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"TIO PUJIO",
            "id":"14042150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tío Pujio",
               "id":"14042150"
            },
            "centroide":{
               "lat":-32.2863739057236,
               "lon":-63.3540179546817
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa María",
               "id":"140357"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"VILLA ALBERTINA",
            "id":"14042160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Albertina",
               "id":"14042160"
            },
            "centroide":{
               "lat":-32.4342175966013,
               "lon":-63.1844586471364
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa María",
               "id":"140357"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"VILLA MARIA",
            "id":"14042170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa María",
               "id":"14042170"
            },
            "centroide":{
               "lat":-32.4120836110321,
               "lon":-63.2499951238659
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Nueva",
               "id":"140364"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"VILLA NUEVA",
            "id":"14042180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Nueva",
               "id":"14042180"
            },
            "centroide":{
               "lat":-32.4288251783807,
               "lon":-63.249359249877
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Nueva",
               "id":"140364"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"14042"
            },
            "nombre":"VILLA OESTE",
            "id":"14042190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Oeste",
               "id":"14042190"
            },
            "centroide":{
               "lat":-32.4343319670367,
               "lon":-63.2875591644345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"142259"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"AVELLANEDA",
            "id":"14049010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"14049010"
            },
            "centroide":{
               "lat":-30.5946762022718,
               "lon":-64.2078393373412
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada de Río Pinto",
               "id":"142266"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"CAÑADA DE RIO PINTO",
            "id":"14049020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cañada de Río Pinto",
               "id":"14049020"
            },
            "centroide":{
               "lat":-30.7759183214925,
               "lon":-64.2182971976615
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chuña",
               "id":"142273"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"CHUÑA",
            "id":"14049030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chuña",
               "id":"14049030"
            },
            "centroide":{
               "lat":-30.4687274606224,
               "lon":-64.6712287665168
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"COPACABANA",
            "id":"14049040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Copacabana",
               "id":"14049040"
            },
            "centroide":{
               "lat":-30.6113486219317,
               "lon":-64.5555688574614
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Deán Funes",
               "id":"140371"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"DEAN FUNES",
            "id":"14049050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Deán Funes",
               "id":"14049050"
            },
            "centroide":{
               "lat":-30.4229409365773,
               "lon":-64.3516697664768
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olivares de San Nicolás",
               "id":"142294"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"ESQUINA DEL ALAMBRE",
            "id":"14049060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Esquina del Alambre",
               "id":"14049060"
            },
            "centroide":{
               "lat":-30.6016118281727,
               "lon":-64.9074033309966
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Pozos",
               "id":"142287"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"LOS POZOS",
            "id":"14049080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Pozos",
               "id":"14049080"
            },
            "centroide":{
               "lat":-30.5154516695053,
               "lon":-64.2664449882016
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olivares de San Nicolás",
               "id":"142294"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"OLIVARES DE SAN NICOLAS",
            "id":"14049090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Olivares de San Nicolás",
               "id":"14049090"
            },
            "centroide":{
               "lat":-30.6020503748658,
               "lon":-64.8433653744314
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilino",
               "id":"140378"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"QUILINO",
            "id":"14049100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Quilino",
               "id":"14049100"
            },
            "centroide":{
               "lat":-30.2159552214688,
               "lon":-64.5029777045333
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"SAN PEDRO DE TOYOS",
            "id":"14049110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Pedro de Toyos",
               "id":"14049110"
            },
            "centroide":{
               "lat":-30.4541583004868,
               "lon":-64.4475688737827
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"VILLA GUTIERREZ",
            "id":"14049120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Gutiérrez",
               "id":"14049120"
            },
            "centroide":{
               "lat":-30.6569511707803,
               "lon":-64.18195908099
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilino",
               "id":"140378"
            },
            "departamento":{
               "nombre":"Ischilín",
               "id":"14049"
            },
            "nombre":"VILLA QUILINO",
            "id":"14049130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Quilino",
               "id":"14049130"
            },
            "centroide":{
               "lat":-30.2101157475483,
               "lon":-64.4775674155262
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alejandro Roca",
               "id":"140385"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"ALEJANDRO ROCA",
            "id":"14056010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alejandro Roca",
               "id":"14056010"
            },
            "centroide":{
               "lat":-33.3564514451723,
               "lon":-63.7205503922441
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Assunta",
               "id":"142308"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"ASSUNTA",
            "id":"14056020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Assunta",
               "id":"14056020"
            },
            "centroide":{
               "lat":-33.6332962011646,
               "lon":-63.2259060913548
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bengolea",
               "id":"140392"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"BENGOLEA",
            "id":"14056030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bengolea",
               "id":"14056030"
            },
            "centroide":{
               "lat":-33.0274173144801,
               "lon":-63.6719277875696
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carnerillo",
               "id":"140399"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"CARNERILLO",
            "id":"14056040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Carnerillo",
               "id":"14056040"
            },
            "centroide":{
               "lat":-32.9153142058652,
               "lon":-64.0256580552867
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charras",
               "id":"140406"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"CHARRAS",
            "id":"14056050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Charras",
               "id":"14056050"
            },
            "centroide":{
               "lat":-33.0241677315378,
               "lon":-64.0458262411649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Rastreador",
               "id":"142315"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"EL RASTREADOR",
            "id":"14056060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Rastreador",
               "id":"14056060"
            },
            "centroide":{
               "lat":-33.6644787319553,
               "lon":-63.5392368036406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Cabrera",
               "id":"140413"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"GENERAL CABRERA",
            "id":"14056070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Cabrera",
               "id":"14056070"
            },
            "centroide":{
               "lat":-32.8118910840567,
               "lon":-63.8736965709556
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Deheza",
               "id":"140420"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"GENERAL DEHEZA",
            "id":"14056080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Deheza",
               "id":"14056080"
            },
            "centroide":{
               "lat":-32.7549291159098,
               "lon":-63.7850383518255
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huanchilla",
               "id":"140427"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"HUANCHILLAS",
            "id":"14056090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Huanchillas",
               "id":"14056090"
            },
            "centroide":{
               "lat":-33.6655227252595,
               "lon":-63.6372618363763
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Carlota",
               "id":"140434"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"LA CARLOTA",
            "id":"14056100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Carlota",
               "id":"14056100"
            },
            "centroide":{
               "lat":-33.4201679372505,
               "lon":-63.2969802619056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Cisnes",
               "id":"140441"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"LOS CISNES",
            "id":"14056110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cisnes",
               "id":"14056110"
            },
            "centroide":{
               "lat":-33.400060095449,
               "lon":-63.4716515559202
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olaeta",
               "id":"140448"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"OLAETA",
            "id":"14056120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Olaeta",
               "id":"14056120"
            },
            "centroide":{
               "lat":-33.0434821211836,
               "lon":-63.9085957407616
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pacheco de Melo",
               "id":"142322"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"PACHECO DE MELO",
            "id":"14056130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pacheco de Melo",
               "id":"14056130"
            },
            "centroide":{
               "lat":-33.7600575092431,
               "lon":-63.4898589767857
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso del Durazno",
               "id":"142329"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"PASO DEL DURAZNO",
            "id":"14056140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Paso del Durazno",
               "id":"14056140"
            },
            "centroide":{
               "lat":-33.1704554487519,
               "lon":-64.0478670082552
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Eufemia",
               "id":"140462"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"SANTA EUFEMIA",
            "id":"14056150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Eufemia",
               "id":"14056150"
            },
            "centroide":{
               "lat":-33.1767529632621,
               "lon":-63.2820358699256
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ucacha",
               "id":"140469"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"UCACHA",
            "id":"14056160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ucacha",
               "id":"14056160"
            },
            "centroide":{
               "lat":-33.0334392795817,
               "lon":-63.5082571588932
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Reducción",
               "id":"140455"
            },
            "departamento":{
               "nombre":"Juárez Celman",
               "id":"14056"
            },
            "nombre":"VILLA REDUCCION",
            "id":"14056170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Reducción",
               "id":"14056170"
            },
            "centroide":{
               "lat":-33.2014556304775,
               "lon":-63.8622207209792
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alejo Ledesma",
               "id":"140476"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"ALEJO LEDESMA",
            "id":"14063010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alejo Ledesma",
               "id":"14063010"
            },
            "centroide":{
               "lat":-33.6076125942499,
               "lon":-62.6261405718946
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arias",
               "id":"140483"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"ARIAS",
            "id":"14063020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Arias",
               "id":"14063020"
            },
            "centroide":{
               "lat":-33.6411632781096,
               "lon":-62.402789011452
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Camilo Aldao",
               "id":"140490"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"CAMILO ALDAO",
            "id":"14063030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Camilo Aldao",
               "id":"14063030"
            },
            "centroide":{
               "lat":-33.123740825534,
               "lon":-62.0965954602144
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capitán General B. O'Higgins",
               "id":"140497"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"CAPITAN GENERAL BERNARDO O`HIGGINS",
            "id":"14063040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Capitán General Bernardo O'Higgins",
               "id":"14063040"
            },
            "centroide":{
               "lat":-33.247202805036,
               "lon":-62.269738358968
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cavanagh",
               "id":"140504"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"CAVANAGH",
            "id":"14063050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cavanagh",
               "id":"14063050"
            },
            "centroide":{
               "lat":-33.478334898944,
               "lon":-62.3393464555145
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Barge",
               "id":"142336"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"COLONIA BARGE",
            "id":"14063060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Barge",
               "id":"14063060"
            },
            "centroide":{
               "lat":-33.2585411549016,
               "lon":-62.6081628269792
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Italiana",
               "id":"140511"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"COLONIA ITALIANA",
            "id":"14063070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Italiana",
               "id":"14063070"
            },
            "centroide":{
               "lat":-33.3119180942558,
               "lon":-62.1807987939012
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"COLONIA VEINTICINCO",
            "id":"14063080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Veinticinco",
               "id":"14063080"
            },
            "centroide":{
               "lat":-32.8883679292315,
               "lon":-61.9347825911775
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corral de Bustos",
               "id":"140518"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"CORRAL DE BUSTOS",
            "id":"14063090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Corral de Bustos",
               "id":"14063090"
            },
            "centroide":{
               "lat":-33.2816219895104,
               "lon":-62.1854508942409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cruz Alta",
               "id":"140525"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"CRUZ ALTA",
            "id":"14063100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cruz Alta",
               "id":"14063100"
            },
            "centroide":{
               "lat":-33.0069947278727,
               "lon":-61.8109726038419
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Baldissera",
               "id":"140532"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"GENERAL BALDISSERA",
            "id":"14063110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Baldissera",
               "id":"14063110"
            },
            "centroide":{
               "lat":-33.1220171765357,
               "lon":-62.3037212558104
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Roca",
               "id":"140539"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"GENERAL ROCA",
            "id":"14063120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Roca",
               "id":"14063120"
            },
            "centroide":{
               "lat":-32.7305115296671,
               "lon":-61.917138712509
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guatimozín",
               "id":"140546"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"GUATIMOZIN",
            "id":"14063130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Guatimozín",
               "id":"14063130"
            },
            "centroide":{
               "lat":-33.4617411292085,
               "lon":-62.4391434502619
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Inriville",
               "id":"140553"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"INRIVILLE",
            "id":"14063140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Inriville",
               "id":"14063140"
            },
            "centroide":{
               "lat":-32.9438405235327,
               "lon":-62.2305487525417
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Isla Verde",
               "id":"140560"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"ISLA VERDE",
            "id":"14063150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Isla Verde",
               "id":"14063150"
            },
            "centroide":{
               "lat":-33.2401071501959,
               "lon":-62.404426547367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leones",
               "id":"140567"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"LEONES",
            "id":"14063160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Leones",
               "id":"14063160"
            },
            "centroide":{
               "lat":-32.658391411409,
               "lon":-62.299914625293
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Surgentes",
               "id":"140574"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"LOS SURGENTES",
            "id":"14063170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Surgentes",
               "id":"14063170"
            },
            "centroide":{
               "lat":-32.9843826304567,
               "lon":-62.0237039883189
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Marcos Juárez",
               "id":"140581"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"MARCOS JUAREZ",
            "id":"14063180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Marcos Juárez",
               "id":"14063180"
            },
            "centroide":{
               "lat":-32.6913099811557,
               "lon":-62.1057946726991
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Buey",
               "id":"140588"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"MONTE BUEY",
            "id":"14063190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Monte Buey",
               "id":"14063190"
            },
            "centroide":{
               "lat":-32.9175280341379,
               "lon":-62.4576576002351
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saira",
               "id":"140595"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"SAIRA",
            "id":"14063210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Saira",
               "id":"14063210"
            },
            "centroide":{
               "lat":-32.406411636801,
               "lon":-62.1029831790036
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"142343"
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"SALADILLO",
            "id":"14063220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Saladillo",
               "id":"14063220"
            },
            "centroide":{
               "lat":-32.9331604883654,
               "lon":-62.3428774336317
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Marcos Juárez",
               "id":"14063"
            },
            "nombre":"VILLA ELISA",
            "id":"14063230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Elisa",
               "id":"14063230"
            },
            "centroide":{
               "lat":-32.7487775757302,
               "lon":-62.3306744044274
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ciénaga del Coro",
               "id":"142357"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"CIENAGA DEL CORO",
            "id":"14070010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ciénaga del Coro",
               "id":"14070010"
            },
            "centroide":{
               "lat":-31.0381142705456,
               "lon":-65.2775888503175
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Chacho",
               "id":"142364"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"EL CHACHO",
            "id":"14070020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Chacho",
               "id":"14070020"
            },
            "centroide":{
               "lat":-30.8017974058313,
               "lon":-65.6440521440399
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estancia de Guadalupe",
               "id":"142371"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"ESTANCIA DE GUADALUPE",
            "id":"14070030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Estancia de Guadalupe",
               "id":"14070030"
            },
            "centroide":{
               "lat":-31.1242101609074,
               "lon":-65.2269002056273
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guasapampa",
               "id":"142378"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"GUASAPAMPA",
            "id":"14070040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Guasapampa",
               "id":"14070040"
            },
            "centroide":{
               "lat":-31.0975357099584,
               "lon":-65.3281875063158
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Playa",
               "id":"142385"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"LA PLAYA",
            "id":"14070050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Playa",
               "id":"14070050"
            },
            "centroide":{
               "lat":-31.0350257440514,
               "lon":-65.3453122247049
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos Minas",
               "id":"140602"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"SAN CARLOS MINAS",
            "id":"14070060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Carlos Minas",
               "id":"14070060"
            },
            "centroide":{
               "lat":-31.175590415787,
               "lon":-65.1017054267171
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Talaini",
               "id":"142392"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"TALAINI",
            "id":"14070070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Talaini",
               "id":"14070070"
            },
            "centroide":{
               "lat":-31.2496924733149,
               "lon":-65.1672000423356
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tosno",
               "id":"142399"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"14070"
            },
            "nombre":"TOSNO",
            "id":"14070080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tosno",
               "id":"14070080"
            },
            "centroide":{
               "lat":-30.9499425576932,
               "lon":-65.3099277411768
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chancaní",
               "id":"142406"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"CHANCANI",
            "id":"14077010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chancani",
               "id":"14077010"
            },
            "centroide":{
               "lat":-31.4172820195639,
               "lon":-65.4453421341012
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"LAS PALMAS",
            "id":"14077020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Palmas",
               "id":"14077020"
            },
            "centroide":{
               "lat":-31.3800076236386,
               "lon":-65.2899757237495
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tala Cañada",
               "id":"142434"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"LOS TALARES",
            "id":"14077030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Talares",
               "id":"14077030"
            },
            "centroide":{
               "lat":-31.3754115021879,
               "lon":-65.0386011461094
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salsacate",
               "id":"140609"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"SALSACATE",
            "id":"14077040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Salsacate",
               "id":"14077040"
            },
            "centroide":{
               "lat":-31.3151616749444,
               "lon":-65.0910260595287
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Gerónimo",
               "id":"142427"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"SAN GERONIMO",
            "id":"14077050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Gerónimo",
               "id":"14077050"
            },
            "centroide":{
               "lat":-31.3409519996631,
               "lon":-64.9131949666644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tala Cañada",
               "id":"142434"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"TALA CAÑADA",
            "id":"14077060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tala Cañada",
               "id":"14077060"
            },
            "centroide":{
               "lat":-31.3570289681593,
               "lon":-64.9764519289305
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salsacate",
               "id":"140609"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"TANINGA",
            "id":"14077070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Taninga",
               "id":"14077070"
            },
            "centroide":{
               "lat":-31.3454000529726,
               "lon":-65.0801168738369
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de Pocho",
               "id":"142441"
            },
            "departamento":{
               "nombre":"Pocho",
               "id":"14077"
            },
            "nombre":"VILLA DE POCHO",
            "id":"14077080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de Pocho",
               "id":"14077080"
            },
            "centroide":{
               "lat":-31.4880894840309,
               "lon":-65.2830353856188
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Levalle",
               "id":"140616"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"GENERAL LEVALLE",
            "id":"14084010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Levalle",
               "id":"14084010"
            },
            "centroide":{
               "lat":-34.0098324798955,
               "lon":-63.9225367502507
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cesira",
               "id":"140623"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"LA CESIRA",
            "id":"14084020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Cesira",
               "id":"14084020"
            },
            "centroide":{
               "lat":-33.9518611160055,
               "lon":-62.9744894136507
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laboulaye",
               "id":"140630"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"LABOULAYE",
            "id":"14084030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Laboulaye",
               "id":"14084030"
            },
            "centroide":{
               "lat":-34.1236122063832,
               "lon":-63.388646306069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leguizamón",
               "id":"142448"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"LEGUIZAMON",
            "id":"14084040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Leguizamón",
               "id":"14084040"
            },
            "centroide":{
               "lat":-34.2040788458157,
               "lon":-62.9768889543714
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Melo",
               "id":"140637"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"MELO",
            "id":"14084050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Melo",
               "id":"14084050"
            },
            "centroide":{
               "lat":-34.3483191246018,
               "lon":-63.4471714447308
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Bamba",
               "id":"142455"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"RIO BAMBA",
            "id":"14084060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río Bamba",
               "id":"14084060"
            },
            "centroide":{
               "lat":-34.0511211181075,
               "lon":-63.7321532881296
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosales",
               "id":"140644"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"ROSALES",
            "id":"14084070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Rosales",
               "id":"14084070"
            },
            "centroide":{
               "lat":-34.171790725852,
               "lon":-63.1534798469172
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Joaquín",
               "id":"142462"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"SAN JOAQUIN",
            "id":"14084080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Joaquín",
               "id":"14084080"
            },
            "centroide":{
               "lat":-34.5106395562101,
               "lon":-63.7132746964365
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Serrano",
               "id":"140651"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"SERRANO",
            "id":"14084090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Serrano",
               "id":"14084090"
            },
            "centroide":{
               "lat":-34.4699818959404,
               "lon":-63.5375595280644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Rossi",
               "id":"140658"
            },
            "departamento":{
               "nombre":"Presidente Roque Sáenz Peña",
               "id":"14084"
            },
            "nombre":"VILLA ROSSI",
            "id":"14084100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Rossi",
               "id":"14084100"
            },
            "centroide":{
               "lat":-34.297937327836,
               "lon":-63.2685397142969
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capilla del Monte",
               "id":"140672"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"BARRIO SANTA ISABEL",
            "id":"14091010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Barrio Santa Isabel",
               "id":"14091010"
            },
            "centroide":{
               "lat":-30.8375267087623,
               "lon":-64.5367004570805
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bialet Massé",
               "id":"140665"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"BIALET MASSE",
            "id":"14091020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bialet Massé",
               "id":"14091020"
            },
            "centroide":{
               "lat":-31.3123081567618,
               "lon":-64.4629696370028
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bialet Massé",
               "id":"140665"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"BIALET MASSE",
            "id":"14091020001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bialet Massé",
               "id":"14091020"
            },
            "centroide":{
               "lat":-31.3214285741587,
               "lon":-64.4818703245544
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bialet Massé",
               "id":"140665"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"SAN ROQUE DEL LAGO",
            "id":"14091020002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bialet Massé",
               "id":"14091020"
            },
            "centroide":{
               "lat":-31.3266874438793,
               "lon":-64.4544689502096
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cabalango",
               "id":"142469"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"CABALANGO",
            "id":"14091030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cabalango",
               "id":"14091030"
            },
            "centroide":{
               "lat":-31.3931049014855,
               "lon":-64.5635652642982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capilla del Monte",
               "id":"140672"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"CAPILLA DEL MONTE",
            "id":"14091040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Capilla del Monte",
               "id":"14091040"
            },
            "centroide":{
               "lat":-30.8563966713395,
               "lon":-64.526268881497
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Casa Grande",
               "id":"142476"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"CASA GRANDE",
            "id":"14091050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Casa Grande",
               "id":"14091050"
            },
            "centroide":{
               "lat":-31.1697129911083,
               "lon":-64.4776214321437
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charbonier",
               "id":"142483"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"CHARBONIER",
            "id":"14091060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Charbonier",
               "id":"14091060"
            },
            "centroide":{
               "lat":-30.7720869099195,
               "lon":-64.5441595530858
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cosquín",
               "id":"140679"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"COSQUIN",
            "id":"14091070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cosquín",
               "id":"14091070"
            },
            "centroide":{
               "lat":-31.240571224569,
               "lon":-64.4655312574209
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cuesta Blanca",
               "id":"142490"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"CUESTA BLANCA",
            "id":"14091080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cuesta Blanca",
               "id":"14091080"
            },
            "centroide":{
               "lat":-31.4787781890782,
               "lon":-64.5773466914484
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estancia Vieja",
               "id":"142497"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"ESTANCIA VIEJA",
            "id":"14091090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Estancia Vieja",
               "id":"14091090"
            },
            "centroide":{
               "lat":-31.3842597063571,
               "lon":-64.517330819559
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huerta Grande",
               "id":"140686"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"HUERTA GRANDE",
            "id":"14091100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Huerta Grande",
               "id":"14091100"
            },
            "centroide":{
               "lat":-31.0600927199176,
               "lon":-64.484304266589
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cumbre",
               "id":"140693"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"LA CUMBRE",
            "id":"14091110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Cumbre",
               "id":"14091110"
            },
            "centroide":{
               "lat":-30.9792461523826,
               "lon":-64.4909087135044
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Falda",
               "id":"140700"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"LA FALDA",
            "id":"14091120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Falda",
               "id":"14091120"
            },
            "centroide":{
               "lat":-31.0919649906581,
               "lon":-64.4864417754562
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Arredondo",
               "id":"142511"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"LAS JARILLAS",
            "id":"14091130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Jarillas",
               "id":"14091130"
            },
            "centroide":{
               "lat":-31.5182256602189,
               "lon":-64.5345928843013
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Cocos",
               "id":"140707"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"LOS COCOS",
            "id":"14091140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cocos",
               "id":"14091140"
            },
            "centroide":{
               "lat":-30.9269774865861,
               "lon":-64.5026123921004
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María de Punilla",
               "id":"140721"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"MALLIN",
            "id":"14091150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mallín",
               "id":"14091150"
            },
            "centroide":{
               "lat":-31.2976747453985,
               "lon":-64.5749813483232
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mayu Sumaj",
               "id":"142504"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"MAYU SUMAJ",
            "id":"14091160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mayu Sumaj",
               "id":"14091160"
            },
            "centroide":{
               "lat":-31.4668646402709,
               "lon":-64.5427634762868
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cosquín",
               "id":"140679"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"QUEBRADA DE LUNA",
            "id":"14091170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Quebrada de Luna",
               "id":"14091170"
            },
            "centroide":{
               "lat":-30.7949898871398,
               "lon":-64.5210978856737
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Arredondo",
               "id":"142511"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"SAN ANTONIO DE ARREDONDO",
            "id":"14091180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Antonio de Arredondo",
               "id":"14091180"
            },
            "centroide":{
               "lat":-31.4782264688487,
               "lon":-64.5289350434399
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Esteban",
               "id":"140714"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"SAN ESTEBAN",
            "id":"14091190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Esteban",
               "id":"14091190"
            },
            "centroide":{
               "lat":-30.9199784063317,
               "lon":-64.5360036651851
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Roque",
               "id":"142518"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"SAN ROQUE",
            "id":"14091200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Roque",
               "id":"14091200"
            },
            "centroide":{
               "lat":-31.3496904364626,
               "lon":-64.4599293921174
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María de Punilla",
               "id":"140721"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"SANTA MARIA DE PUNILLA",
            "id":"14091210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa María de Punilla",
               "id":"14091210"
            },
            "centroide":{
               "lat":-31.2703319860361,
               "lon":-64.4653357678432
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tala Huasi",
               "id":"142525"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"TALA HUASI",
            "id":"14091220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tala Huasi",
               "id":"14091220"
            },
            "centroide":{
               "lat":-31.472446933222,
               "lon":-64.5651507693433
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tanti",
               "id":"140728"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"TANTI",
            "id":"14091230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tanti",
               "id":"14091230"
            },
            "centroide":{
               "lat":-31.3549072437345,
               "lon":-64.5909558305817
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Hermoso",
               "id":"140735"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VALLE HERMOSO",
            "id":"14091240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Valle Hermoso",
               "id":"14091240"
            },
            "centroide":{
               "lat":-31.1172888298585,
               "lon":-64.484252831532
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Carlos Paz",
               "id":"140742"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA CARLOS PAZ",
            "id":"14091250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Carlos Paz",
               "id":"14091250"
            },
            "centroide":{
               "lat":-31.4182380208919,
               "lon":-64.4933448063141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tanti",
               "id":"140728"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA FLOR SERRANA",
            "id":"14091260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Flor Serrana",
               "id":"14091260"
            },
            "centroide":{
               "lat":-31.3864690388309,
               "lon":-64.6003659348122
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Giardino",
               "id":"140749"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA GIARDINO",
            "id":"14091270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Giardino",
               "id":"14091270"
            },
            "centroide":{
               "lat":-31.0362773938945,
               "lon":-64.4928899121054
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Santa Cruz del Lago",
               "id":"142539"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA LAGO AZUL",
            "id":"14091280000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Lago Azul",
               "id":"14091280"
            },
            "centroide":{
               "lat":-31.3740257221359,
               "lon":-64.4831122938584
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Parque Siquiman",
               "id":"142532"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA PARQUE SIQUIMAN",
            "id":"14091290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Parque Siquimán",
               "id":"14091290"
            },
            "centroide":{
               "lat":-31.3457170881093,
               "lon":-64.4802571053108
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Río Icho Cruz",
               "id":"142546"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA RIO ICHO CRUZ",
            "id":"14091300000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Río Icho Cruz",
               "id":"14091300"
            },
            "centroide":{
               "lat":-31.4791065590312,
               "lon":-64.562747406585
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María de Punilla",
               "id":"140721"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA SAN JOSE",
            "id":"14091310000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa San José",
               "id":"14091310"
            },
            "centroide":{
               "lat":-31.279612799499,
               "lon":-64.5653436907589
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Santa Cruz del Lago",
               "id":"142539"
            },
            "departamento":{
               "nombre":"Punilla",
               "id":"14091"
            },
            "nombre":"VILLA SANTA CRUZ DEL LAGO",
            "id":"14091320000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Santa Cruz del Lago",
               "id":"14091320"
            },
            "centroide":{
               "lat":-31.369322851246,
               "lon":-64.5205370832482
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Achiras",
               "id":"140756"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"ACHIRAS",
            "id":"14098010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Achiras",
               "id":"14098010"
            },
            "centroide":{
               "lat":-33.1770175916926,
               "lon":-64.9944684969757
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adelia María",
               "id":"140763"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"ADELIA MARIA",
            "id":"14098020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Adelia María",
               "id":"14098020"
            },
            "centroide":{
               "lat":-33.631620222358,
               "lon":-64.0208372956378
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alcira",
               "id":"140770"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"ALCIRA",
            "id":"14098030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alcira Gigena",
               "id":"14098030"
            },
            "centroide":{
               "lat":-32.757065291399,
               "lon":-64.3378599293347
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alpa Corral",
               "id":"140777"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"ALPA CORRAL",
            "id":"14098040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alpa Corral",
               "id":"14098040"
            },
            "centroide":{
               "lat":-32.6904036665231,
               "lon":-64.721171054893
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berrotarán",
               "id":"140784"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"BERROTARAN",
            "id":"14098050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Berrotarán",
               "id":"14098050"
            },
            "centroide":{
               "lat":-32.4528669215341,
               "lon":-64.3854404675021
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bulnes",
               "id":"140791"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"BULNES",
            "id":"14098060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bulnes",
               "id":"14098060"
            },
            "centroide":{
               "lat":-33.5032818212674,
               "lon":-64.6755735163666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chaján",
               "id":"140798"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"CHAJAN",
            "id":"14098070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chaján",
               "id":"14098070"
            },
            "centroide":{
               "lat":-33.5563361347485,
               "lon":-65.0052699166312
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chucul",
               "id":"142553"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"CHUCUL",
            "id":"14098080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chucul",
               "id":"14098080"
            },
            "centroide":{
               "lat":-33.0089546768665,
               "lon":-64.1712493406755
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Baigorria",
               "id":"140805"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"CORONEL BAIGORRIA",
            "id":"14098090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Coronel Baigorria",
               "id":"14098090"
            },
            "centroide":{
               "lat":-32.8495478948519,
               "lon":-64.3598591374051
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Moldes",
               "id":"140812"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"CORONEL MOLDES",
            "id":"14098100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Coronel Moldes",
               "id":"14098100"
            },
            "centroide":{
               "lat":-33.6230868735031,
               "lon":-64.5971033510391
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Elena",
               "id":"140819"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"ELENA",
            "id":"14098110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Elena",
               "id":"14098110"
            },
            "centroide":{
               "lat":-32.5720684109952,
               "lon":-64.3929518423935
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Carolina \"El Potosi\"",
               "id":"142560"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LA CAROLINA",
            "id":"14098120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Carolina",
               "id":"14098120"
            },
            "centroide":{
               "lat":-33.1868433713045,
               "lon":-64.7251129655857
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cautiva",
               "id":"140826"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LA CAUTIVA",
            "id":"14098130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Cautiva",
               "id":"14098130"
            },
            "centroide":{
               "lat":-33.97859138783,
               "lon":-64.0842989461185
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LA GILDA",
            "id":"14098140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Gilda",
               "id":"14098140"
            },
            "centroide":{
               "lat":-33.2082959166527,
               "lon":-64.2584448995097
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Acequias",
               "id":"140833"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LAS ACEQUIAS",
            "id":"14098150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Acequias",
               "id":"14098150"
            },
            "centroide":{
               "lat":-33.2822486969948,
               "lon":-63.9758763865889
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Albahacas",
               "id":"142567"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LAS ALBAHACAS",
            "id":"14098160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Albahacas",
               "id":"14098160"
            },
            "centroide":{
               "lat":-32.8924933762193,
               "lon":-64.8435915248656
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Higueras",
               "id":"140840"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LAS HIGUERAS",
            "id":"14098170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Higueras",
               "id":"14098170"
            },
            "centroide":{
               "lat":-33.0892422210207,
               "lon":-64.2868227686533
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Peñas Sud",
               "id":"142574"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LAS PEÑAS",
            "id":"14098180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Peñas",
               "id":"14098180"
            },
            "centroide":{
               "lat":-32.5348511864127,
               "lon":-64.1052291964295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Vertientes",
               "id":"140847"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"LAS VERTIENTES",
            "id":"14098190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Vertientes",
               "id":"14098190"
            },
            "centroide":{
               "lat":-33.2839463852241,
               "lon":-64.5781462257606
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malena",
               "id":"142581"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"MALENA",
            "id":"14098200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Malena",
               "id":"14098200"
            },
            "centroide":{
               "lat":-33.4893410861497,
               "lon":-64.4320008051115
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte de los Gauchos",
               "id":"140854"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"MONTE DE LOS GAUCHOS",
            "id":"14098210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Monte de los Gauchos",
               "id":"14098210"
            },
            "centroide":{
               "lat":-33.636887956776,
               "lon":-63.8900068799972
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"PASO DEL DURAZNO",
            "id":"14098220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Paso del Durazno",
               "id":"14098220"
            },
            "centroide":{
               "lat":-33.170068435327,
               "lon":-64.049630767807
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Cuarto",
               "id":"140861"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"RIO CUARTO",
            "id":"14098230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río Cuarto",
               "id":"14098230"
            },
            "centroide":{
               "lat":-33.1242631220064,
               "lon":-64.3487377033754
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sampacho",
               "id":"140868"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"SAMPACHO",
            "id":"14098240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sampacho",
               "id":"14098240"
            },
            "centroide":{
               "lat":-33.3845382952352,
               "lon":-64.7219849539761
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Basilio",
               "id":"140875"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"SAN BASILIO",
            "id":"14098250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Basilio",
               "id":"14098250"
            },
            "centroide":{
               "lat":-33.4978542515606,
               "lon":-64.3151828240087
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Catalina",
               "id":"140882"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"SANTA CATALINA",
            "id":"14098260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Catalina Holmberg",
               "id":"14098260"
            },
            "centroide":{
               "lat":-33.2059384521037,
               "lon":-64.4348164483493
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Suco",
               "id":"142588"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"SUCO",
            "id":"14098270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Suco",
               "id":"14098270"
            },
            "centroide":{
               "lat":-33.4394729739475,
               "lon":-64.8316610855999
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tosquita",
               "id":"140889"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"TOSQUITAS",
            "id":"14098280000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tosquitas",
               "id":"14098280"
            },
            "centroide":{
               "lat":-33.8188248224493,
               "lon":-64.4569327410668
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vicuña Mackenna",
               "id":"140896"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"VICUÑA MACKENNA",
            "id":"14098290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Vicuña Mackenna",
               "id":"14098290"
            },
            "centroide":{
               "lat":-33.917235746816,
               "lon":-64.3902222715013
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa el Chacay",
               "id":"142595"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"VILLA EL CHACAY",
            "id":"14098300000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa El Chacay",
               "id":"14098300"
            },
            "centroide":{
               "lat":-32.893726660666,
               "lon":-64.8675140784316
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alpa Corral",
               "id":"140777"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"VILLA SANTA EUGENIA",
            "id":"14098310000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Santa Eugenia",
               "id":"14098310"
            },
            "centroide":{
               "lat":-32.6651066386532,
               "lon":-64.741501810378
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Washington",
               "id":"142602"
            },
            "departamento":{
               "nombre":"Río Cuarto",
               "id":"14098"
            },
            "nombre":"WASHINGTON",
            "id":"14098320000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Washington",
               "id":"14098320"
            },
            "centroide":{
               "lat":-33.8733676407196,
               "lon":-64.6886182213475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"ATAHONA",
            "id":"14105010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Atahona",
               "id":"14105010"
            },
            "centroide":{
               "lat":-30.9035316228673,
               "lon":-63.7055651181425
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada de Machado",
               "id":"142616"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"CAÑADA DE MACHADO",
            "id":"14105020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cañada de Machado",
               "id":"14105020"
            },
            "centroide":{
               "lat":-31.4289242714312,
               "lon":-63.5870264507761
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capilla de los Remedios",
               "id":"142623"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"CAPILLA DE LOS REMEDIOS",
            "id":"14105030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Capilla de los Remedios",
               "id":"14105030"
            },
            "centroide":{
               "lat":-31.4302065756542,
               "lon":-63.8321496909141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"CHALACEA",
            "id":"14105040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chalacea",
               "id":"14105040"
            },
            "centroide":{
               "lat":-30.7545707267715,
               "lon":-63.5682265940856
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"COLONIA LAS CUATRO ESQUINAS",
            "id":"14105050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Las Cuatro Esquinas",
               "id":"14105050"
            },
            "centroide":{
               "lat":-31.1735851610445,
               "lon":-63.3375921895433
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Diego de Rojas",
               "id":"142651"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"DIEGO DE ROJAS",
            "id":"14105060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Diego de Rojas",
               "id":"14105060"
            },
            "centroide":{
               "lat":-31.0286404171341,
               "lon":-63.3402795519709
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"EL ALCALDE",
            "id":"14105070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Alcalde",
               "id":"14105070"
            },
            "centroide":{
               "lat":-31.1161396833417,
               "lon":-63.6017410478522
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Crispín",
               "id":"142658"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"EL CRISPIN",
            "id":"14105080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Crispín",
               "id":"14105080"
            },
            "centroide":{
               "lat":-31.017971601362,
               "lon":-63.6051650588702
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esquina",
               "id":"142665"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"ESQUINA",
            "id":"14105090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Esquina",
               "id":"14105090"
            },
            "centroide":{
               "lat":-31.0772911263196,
               "lon":-63.7935627786301
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Kilómetro 658",
               "id":"142672"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"KILOMETRO 658",
            "id":"14105100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Kilómetro 658",
               "id":"14105100"
            },
            "centroide":{
               "lat":-31.3701809412977,
               "lon":-63.5326025508152
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Para",
               "id":"140903"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"LA PARA",
            "id":"14105110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Para",
               "id":"14105110"
            },
            "centroide":{
               "lat":-30.8925359716793,
               "lon":-63.0010662681495
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Posta",
               "id":"142679"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"LA POSTA",
            "id":"14105120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Posta",
               "id":"14105120"
            },
            "centroide":{
               "lat":-30.5653596004713,
               "lon":-63.5163053686029
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Puerta",
               "id":"140910"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"LA PUERTA",
            "id":"14105130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Puerta",
               "id":"14105130"
            },
            "centroide":{
               "lat":-30.8926051154777,
               "lon":-63.2532712015227
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Quinta",
               "id":"142686"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"LA QUINTA",
            "id":"14105140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Quinta",
               "id":"14105140"
            },
            "centroide":{
               "lat":-31.0622588456023,
               "lon":-63.1501281830965
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"LAS GRAMILLAS",
            "id":"14105150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Gramillas",
               "id":"14105150"
            },
            "centroide":{
               "lat":-31.0882555128663,
               "lon":-63.2418444621471
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Saladas",
               "id":"142700"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"LAS SALADAS",
            "id":"14105160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Saladas",
               "id":"14105160"
            },
            "centroide":{
               "lat":-30.7594681012857,
               "lon":-63.2035027286926
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maquinista Gallini",
               "id":"142707"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"MAQUINISTA GALLINI",
            "id":"14105170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Maquinista Gallini",
               "id":"14105170"
            },
            "centroide":{
               "lat":-30.9074419738178,
               "lon":-63.532118614378
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"MONTE DEL ROSARIO",
            "id":"14105180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Monte del Rosario",
               "id":"14105180"
            },
            "centroide":{
               "lat":-30.9826026339688,
               "lon":-63.6017850424963
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Cristo",
               "id":"140917"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"MONTECRISTO",
            "id":"14105190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Montecristo",
               "id":"14105190"
            },
            "centroide":{
               "lat":-31.3438538707604,
               "lon":-63.9457735584091
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Obispo Trejo",
               "id":"140924"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"OBISPO TREJO",
            "id":"14105200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Obispo Trejo",
               "id":"14105200"
            },
            "centroide":{
               "lat":-30.7811924488335,
               "lon":-63.4130494343907
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Piquillín",
               "id":"140931"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"PIQUILLIN",
            "id":"14105210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Piquillín",
               "id":"14105210"
            },
            "centroide":{
               "lat":-31.2995844445842,
               "lon":-63.7591050718367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Plaza de Mercedes",
               "id":"142714"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"PLAZA DE MERCEDES",
            "id":"14105220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Plaza de Mercedes",
               "id":"14105220"
            },
            "centroide":{
               "lat":-30.9780514540426,
               "lon":-63.2596192028417
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comechingones",
               "id":"142644"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"PUEBLO COMECHINGONES",
            "id":"14105230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pueblo Comechingones",
               "id":"14105230"
            },
            "centroide":{
               "lat":-31.1685485898925,
               "lon":-63.6075820921855
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Primero",
               "id":"140938"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"RIO PRIMERO",
            "id":"14105240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río Primero",
               "id":"14105240"
            },
            "centroide":{
               "lat":-31.3291838493004,
               "lon":-63.621866727412
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"SAGRADA FAMILIA",
            "id":"14105250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sagrada Familia",
               "id":"14105250"
            },
            "centroide":{
               "lat":-31.2897282303946,
               "lon":-63.445471703469
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Río Primero",
               "id":"140945"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"SANTA ROSA DE RIO PRIMERO",
            "id":"14105260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Río Primero",
               "id":"14105260"
            },
            "centroide":{
               "lat":-31.1514772066717,
               "lon":-63.4015912555355
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Fontana",
               "id":"140952"
            },
            "departamento":{
               "nombre":"Río Primero",
               "id":"14105"
            },
            "nombre":"VILLA FONTANA",
            "id":"14105270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Fontana",
               "id":"14105270"
            },
            "centroide":{
               "lat":-30.8940088267342,
               "lon":-63.1159181990178
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerro Colorado",
               "id":"142735"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"CERRO COLORADO",
            "id":"14112010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cerro Colorado",
               "id":"14112010"
            },
            "centroide":{
               "lat":-30.0960599330651,
               "lon":-63.9287731727735
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"CHAÑAR VIEJO",
            "id":"14112020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chañar Viejo",
               "id":"14112020"
            },
            "centroide":{
               "lat":-30.0139606979963,
               "lon":-63.8503752152119
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eufrasio Loza",
               "id":"142749"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"EUFRASIO LOZA",
            "id":"14112030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Eufrasio Loza",
               "id":"14112030"
            },
            "centroide":{
               "lat":-29.9247226351086,
               "lon":-63.5889152129175
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gütemberg",
               "id":"142756"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"GUTEMBERG",
            "id":"14112040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Gütemberg",
               "id":"14112040"
            },
            "centroide":{
               "lat":-29.7227092489555,
               "lon":-63.5149951918634
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"LA RINCONADA",
            "id":"14112050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Rinconada",
               "id":"14112050"
            },
            "centroide":{
               "lat":-30.1846537889428,
               "lon":-62.8301426342643
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Hoyos",
               "id":"142770"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"LOS HOYOS",
            "id":"14112060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Hoyos",
               "id":"14112060"
            },
            "centroide":{
               "lat":-29.802434071812,
               "lon":-63.6273622842684
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"PUESTO DE CASTRO",
            "id":"14112070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Puesto de Castro",
               "id":"14112070"
            },
            "centroide":{
               "lat":-30.2390823848323,
               "lon":-63.4916854103812
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rayo Cortado",
               "id":"142784"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"RAYO CORTADO",
            "id":"14112080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Rayo Cortado",
               "id":"14112080"
            },
            "centroide":{
               "lat":-30.0742593288829,
               "lon":-63.8249818238142
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gütemberg",
               "id":"142756"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"SAN PEDRO DE GUTEMBERG",
            "id":"14112090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Pedro de Gütemberg",
               "id":"14112090"
            },
            "centroide":{
               "lat":-29.698228417088,
               "lon":-63.5610809474438
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Elena",
               "id":"142791"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"SANTA ELENA",
            "id":"14112100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Elena",
               "id":"14112100"
            },
            "centroide":{
               "lat":-30.1174756551011,
               "lon":-63.8438054710488
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sebastián Elcano",
               "id":"140959"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"SEBASTIAN ELCANO",
            "id":"14112110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sebastián Elcano",
               "id":"14112110"
            },
            "centroide":{
               "lat":-30.1614462636479,
               "lon":-63.5934503863753
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Candelaria Norte",
               "id":"142798"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"VILLA CANDELARIA NORTE",
            "id":"14112120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Candelaria",
               "id":"14112120"
            },
            "centroide":{
               "lat":-29.8162963199276,
               "lon":-63.3548391354924
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de María",
               "id":"140966"
            },
            "departamento":{
               "nombre":"Río Seco",
               "id":"14112"
            },
            "nombre":"VILLA DE MARIA",
            "id":"14112130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de María",
               "id":"14112130"
            },
            "centroide":{
               "lat":-29.9043583402484,
               "lon":-63.722318985926
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calchín",
               "id":"140973"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"CALCHIN",
            "id":"14119010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Calchín",
               "id":"14119010"
            },
            "centroide":{
               "lat":-31.6679359912419,
               "lon":-63.2006870937546
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calchín Oeste",
               "id":"140980"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"CALCHIN OESTE",
            "id":"14119020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Calchín Oeste",
               "id":"14119020"
            },
            "centroide":{
               "lat":-31.8633868408967,
               "lon":-63.278168375262
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capilla del Carmen",
               "id":"140987"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"CAPILLA DEL CARMEN",
            "id":"14119030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Capilla del Carmen",
               "id":"14119030"
            },
            "centroide":{
               "lat":-31.5081678166164,
               "lon":-63.3421354529106
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carrilobo",
               "id":"140994"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"CARRILOBO",
            "id":"14119040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Carrilobo",
               "id":"14119040"
            },
            "centroide":{
               "lat":-31.8716468802323,
               "lon":-63.1165074420857
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colazo",
               "id":"141001"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"COLAZO",
            "id":"14119050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colazo",
               "id":"14119050"
            },
            "centroide":{
               "lat":-31.964293093898,
               "lon":-63.3834011052136
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Videla",
               "id":"142805"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"COLONIA VIDELA",
            "id":"14119060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Videla",
               "id":"14119060"
            },
            "centroide":{
               "lat":-31.9175044736895,
               "lon":-63.5047159461534
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Costasacate",
               "id":"141008"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"COSTASACATE",
            "id":"14119070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Costasacate",
               "id":"14119070"
            },
            "centroide":{
               "lat":-31.6470984739179,
               "lon":-63.7598955842163
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Impira",
               "id":"142812"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"IMPIRA",
            "id":"14119080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Impira",
               "id":"14119080"
            },
            "centroide":{
               "lat":-31.7965929244084,
               "lon":-63.6511207667299
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Larga",
               "id":"141015"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"LAGUNA LARGA",
            "id":"14119090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Laguna Larga",
               "id":"14119090"
            },
            "centroide":{
               "lat":-31.7783987221778,
               "lon":-63.8027369015332
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Junturas",
               "id":"141022"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"LAS JUNTURAS",
            "id":"14119100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Junturas",
               "id":"14119100"
            },
            "centroide":{
               "lat":-31.8313972998615,
               "lon":-63.4506364545889
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Chañaritos (C.D. Eje)",
               "id":"142196"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"LOS CHAÑARITOS",
            "id":"14119110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Chañaritos",
               "id":"14119110"
            },
            "centroide":{
               "lat":-31.4021377541587,
               "lon":-63.3321030427196
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luque",
               "id":"141029"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"LUQUE",
            "id":"14119120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Luque",
               "id":"14119120"
            },
            "centroide":{
               "lat":-31.6468736294787,
               "lon":-63.344798299585
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Manfredi",
               "id":"141036"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"MANFREDI",
            "id":"14119130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Manfredi",
               "id":"14119130"
            },
            "centroide":{
               "lat":-31.8440585633394,
               "lon":-63.7460746070431
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Matorrales",
               "id":"141043"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"MATORRALES",
            "id":"14119140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Matorrales",
               "id":"14119140"
            },
            "centroide":{
               "lat":-31.7144720415401,
               "lon":-63.5118521462999
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Oncativo",
               "id":"141050"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"ONCATIVO",
            "id":"14119150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Oncativo",
               "id":"14119150"
            },
            "centroide":{
               "lat":-31.912835805369,
               "lon":-63.6836820317482
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"141057"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"PILAR",
            "id":"14119160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"14119160"
            },
            "centroide":{
               "lat":-31.6804516863719,
               "lon":-63.8814979795567
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pozo del Molle",
               "id":"141064"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"POZO DEL MOLLE",
            "id":"14119170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pozo del Molle",
               "id":"14119170"
            },
            "centroide":{
               "lat":-32.0194389864602,
               "lon":-62.9199182599484
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rincón",
               "id":"142826"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"RINCON",
            "id":"14119180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Rincón",
               "id":"14119180"
            },
            "centroide":{
               "lat":-31.595385474428,
               "lon":-63.6155414282074
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Segundo",
               "id":"141071"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"RIO SEGUNDO",
            "id":"14119190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río Segundo",
               "id":"14119190"
            },
            "centroide":{
               "lat":-31.650153972407,
               "lon":-63.9118630162229
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santiago Temple",
               "id":"141078"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"SANTIAGO TEMPLE",
            "id":"14119200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santiago Temple",
               "id":"14119200"
            },
            "centroide":{
               "lat":-31.3871253007234,
               "lon":-63.4182078006181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Rosario",
               "id":"141085"
            },
            "departamento":{
               "nombre":"Río Segundo",
               "id":"14119"
            },
            "nombre":"VILLA DEL ROSARIO",
            "id":"14119210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Rosario",
               "id":"14119210"
            },
            "centroide":{
               "lat":-31.5540113344661,
               "lon":-63.5341489014562
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ambul",
               "id":"142833"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"AMBUL",
            "id":"14126010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ambul",
               "id":"14126010"
            },
            "centroide":{
               "lat":-31.489683021508,
               "lon":-65.0566540377037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo los Patos",
               "id":"142840"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"ARROYO LOS PATOS",
            "id":"14126020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Arroyo Los Patos",
               "id":"14126020"
            },
            "centroide":{
               "lat":-31.7500816583302,
               "lon":-65.004300188015
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Calles",
               "id":"142847"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"EL HUAYCO",
            "id":"14126030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Huayco",
               "id":"14126030"
            },
            "centroide":{
               "lat":-31.845848320458,
               "lon":-64.9560921756071
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"LA CORTADERA",
            "id":"14126040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Cortadera",
               "id":"14126040"
            },
            "centroide":{
               "lat":-31.668668399569,
               "lon":-65.37239422356
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Calles",
               "id":"142847"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"LAS CALLES",
            "id":"14126050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Calles",
               "id":"14126050"
            },
            "centroide":{
               "lat":-31.8206855464004,
               "lon":-64.9717608825205
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"LAS OSCURAS",
            "id":"14126060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Oscuras",
               "id":"14126060"
            },
            "centroide":{
               "lat":-31.6633718662216,
               "lon":-65.3190950145101
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Rabonas",
               "id":"142854"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"LAS RABONAS",
            "id":"14126070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Rabonas",
               "id":"14126070"
            },
            "centroide":{
               "lat":-31.8544494669466,
               "lon":-64.9870970596913
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"141106"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"LOS CALLEJONES",
            "id":"14126080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Callejones",
               "id":"14126080"
            },
            "centroide":{
               "lat":-31.9322689128265,
               "lon":-65.2330653421552
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mina Clavero",
               "id":"141092"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"MINA CLAVERO",
            "id":"14126090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mina Clavero",
               "id":"14126090"
            },
            "centroide":{
               "lat":-31.7267843574619,
               "lon":-65.0055332639059
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"MUSSI",
            "id":"14126100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Mussi",
               "id":"14126100"
            },
            "centroide":{
               "lat":-31.4273241562001,
               "lon":-65.0756894821312
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nono",
               "id":"141099"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"NONO",
            "id":"14126110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Nono",
               "id":"14126110"
            },
            "centroide":{
               "lat":-31.795570031823,
               "lon":-65.0034036260171
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Panaholma",
               "id":"142861"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"PANAHOLMA",
            "id":"14126120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Panaholma",
               "id":"14126120"
            },
            "centroide":{
               "lat":-31.626892878253,
               "lon":-65.058665894714
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Calles",
               "id":"142847"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"SAN HUBERTO",
            "id":"14126130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Huberto",
               "id":"14126130"
            },
            "centroide":{
               "lat":-31.832529380563,
               "lon":-64.9856729137672
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"142868"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"SAN LORENZO",
            "id":"14126140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Lorenzo",
               "id":"14126140"
            },
            "centroide":{
               "lat":-31.6822276756771,
               "lon":-65.0255685400222
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"SAN MARTIN",
            "id":"14126150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Martín",
               "id":"14126150"
            },
            "centroide":{
               "lat":-31.8969863219692,
               "lon":-65.5246625976026
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"141106"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"SAN PEDRO",
            "id":"14126160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"14126160"
            },
            "centroide":{
               "lat":-31.9368244076607,
               "lon":-65.2192402223011
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"142875"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"SAN VICENTE",
            "id":"14126170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Vicente",
               "id":"14126170"
            },
            "centroide":{
               "lat":-31.8549846523947,
               "lon":-65.4304499825783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sauce Arriba",
               "id":"142882"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"SAUCE ARRIBA",
            "id":"14126180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sauce Arriba",
               "id":"14126180"
            },
            "centroide":{
               "lat":-31.9143833881487,
               "lon":-65.1687928322254
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"TASNA",
            "id":"14126190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tasna",
               "id":"14126190"
            },
            "centroide":{
               "lat":-31.6303406921333,
               "lon":-65.1483381584252
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Cura Brochero",
               "id":"141113"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"VILLA CURA BROCHERO",
            "id":"14126200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Cura Brochero",
               "id":"14126200"
            },
            "centroide":{
               "lat":-31.7063690825109,
               "lon":-65.0191109777329
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Sarmiento (G.R.)",
               "id":"142252"
            },
            "departamento":{
               "nombre":"San Alberto",
               "id":"14126"
            },
            "nombre":"VILLA SARMIENTO",
            "id":"14126210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Sarmiento",
               "id":"14126210"
            },
            "centroide":{
               "lat":-31.9366572471396,
               "lon":-65.191796702404
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Conlara",
               "id":"142889"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"CONLARA",
            "id":"14133010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Conlara",
               "id":"14133010"
            },
            "centroide":{
               "lat":-32.0788609958395,
               "lon":-65.2312586416728
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"141127"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"CRUZ CAÑA",
            "id":"14133030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cruz Caña",
               "id":"14133030"
            },
            "centroide":{
               "lat":-32.2779029026256,
               "lon":-65.0273777504727
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Hornillos",
               "id":"142917"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"DOS ARROYOS",
            "id":"14133040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Dos Arroyos",
               "id":"14133040"
            },
            "centroide":{
               "lat":-31.8716867538421,
               "lon":-64.992749639447
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Hornillos",
               "id":"142917"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"EL PANTANILLO",
            "id":"14133050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Pantanillo",
               "id":"14133050"
            },
            "centroide":{
               "lat":-31.8862653496977,
               "lon":-64.990651569992
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"141127"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LA PAZ",
            "id":"14133060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Paz",
               "id":"14133060"
            },
            "centroide":{
               "lat":-32.2172752387548,
               "lon":-65.0511157108409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Población",
               "id":"142896"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LA POBLACION",
            "id":"14133070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Población",
               "id":"14133070"
            },
            "centroide":{
               "lat":-32.0660031804969,
               "lon":-65.0309086009182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"141127"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LA RAMADA",
            "id":"14133080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Ramada",
               "id":"14133080"
            },
            "centroide":{
               "lat":-32.2996521283095,
               "lon":-65.0361512187387
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luyaba",
               "id":"142924"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LA TRAVESIA",
            "id":"14133090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Travesía",
               "id":"14133090"
            },
            "centroide":{
               "lat":-32.1066758248056,
               "lon":-65.063758957899
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"141127"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LAS CHACRAS",
            "id":"14133095000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Chacras",
               "id":"14133095"
            },
            "centroide":{
               "lat":-32.2418447787663,
               "lon":-65.0340964238205
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Tapias",
               "id":"142903"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LAS TAPIAS",
            "id":"14133100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Tapias",
               "id":"14133100"
            },
            "centroide":{
               "lat":-31.9522279195169,
               "lon":-65.1014219744873
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"141127"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LOMA BOLA",
            "id":"14133105000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Loma Bola",
               "id":"14133105"
            },
            "centroide":{
               "lat":-32.2189295558758,
               "lon":-65.0285126897579
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Cerrillos",
               "id":"142910"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LOS CERRILLOS",
            "id":"14133110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cerrillos",
               "id":"14133110"
            },
            "centroide":{
               "lat":-31.9737899960039,
               "lon":-65.4376442516279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Hornillos",
               "id":"142917"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LOS HORNILLOS",
            "id":"14133120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Hornillos",
               "id":"14133120"
            },
            "centroide":{
               "lat":-31.9017353099654,
               "lon":-64.9900890034519
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LOS MOLLES",
            "id":"14133130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Molles",
               "id":"14133130"
            },
            "centroide":{
               "lat":-31.9620954005051,
               "lon":-65.0383138664304
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luyaba",
               "id":"142924"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LUYABA",
            "id":"14133150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Luyaba",
               "id":"14133150"
            },
            "centroide":{
               "lat":-32.1483422464594,
               "lon":-65.056618698571
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"141127"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"QUEBRACHO LADEADO",
            "id":"14133155000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Quebracho Ladeado",
               "id":"14133155"
            },
            "centroide":{
               "lat":-32.2570957550243,
               "lon":-65.0309211927709
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"QUEBRADA DE LOS POZOS",
            "id":"14133160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Quebrada de los Pozos",
               "id":"14133160"
            },
            "centroide":{
               "lat":-31.9074953420069,
               "lon":-65.0344580207268
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Javier y Yacanto",
               "id":"141134"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"SAN JAVIER Y YACANTO",
            "id":"14133170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Javier y Yacanto",
               "id":"14133170"
            },
            "centroide":{
               "lat":-32.0266518585481,
               "lon":-65.027552227227
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"141141"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"SAN JOSE",
            "id":"14133180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"14133180"
            },
            "centroide":{
               "lat":-31.9573259005881,
               "lon":-65.3152083482463
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"VILLA DE LAS ROSAS",
            "id":"14133190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de las Rosas",
               "id":"14133190"
            },
            "centroide":{
               "lat":-31.9484733205417,
               "lon":-65.0550181621034
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"ALTO RESBALOSO - EL BARRIAL",
            "id":"14133190001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de las Rosas",
               "id":"14133190"
            },
            "centroide":{
               "lat":-31.9620894324618,
               "lon":-65.0677903537373
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"EL PUEBLITO",
            "id":"14133190002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de las Rosas",
               "id":"14133190"
            },
            "centroide":{
               "lat":-31.951097322144,
               "lon":-65.0721507920682
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"EL VALLE",
            "id":"14133190003",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de las Rosas",
               "id":"14133190"
            },
            "centroide":{
               "lat":-31.951097322144,
               "lon":-65.0721507920682
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"LAS CHACRAS",
            "id":"14133190004",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de las Rosas",
               "id":"14133190"
            },
            "centroide":{
               "lat":-31.9402016355042,
               "lon":-65.0211563854361
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"VILLA DE LAS ROSAS",
            "id":"14133190005",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa de las Rosas",
               "id":"14133190"
            },
            "centroide":{
               "lat":-31.9521742409328,
               "lon":-65.053914111613
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Dolores",
               "id":"141155"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"VILLA DOLORES",
            "id":"14133200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Dolores",
               "id":"14133200"
            },
            "centroide":{
               "lat":-31.9440434116077,
               "lon":-65.1961395608591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de las Rosas",
               "id":"141148"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"14133"
            },
            "nombre":"VILLA LA VIÑA",
            "id":"14133210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa La Viña",
               "id":"14133210"
            },
            "centroide":{
               "lat":-31.8727711509831,
               "lon":-65.0372746344972
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alicia",
               "id":"141162"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"ALICIA",
            "id":"14140010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alicia",
               "id":"14140010"
            },
            "centroide":{
               "lat":-31.9387491680744,
               "lon":-62.4657945680095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Altos de Chipión",
               "id":"141169"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"ALTOS DE CHIPION",
            "id":"14140020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Altos de Chipión",
               "id":"14140020"
            },
            "centroide":{
               "lat":-30.9563507625723,
               "lon":-62.3378881220879
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyito",
               "id":"141176"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"ARROYITO",
            "id":"14140030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Arroyito",
               "id":"14140030"
            },
            "centroide":{
               "lat":-31.4193908735633,
               "lon":-63.0503444103203
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balnearia",
               "id":"141183"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"BALNEARIA",
            "id":"14140040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Balnearia",
               "id":"14140040"
            },
            "centroide":{
               "lat":-31.0107075696975,
               "lon":-62.6671044005114
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Brinkmann",
               "id":"141190"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"BRINCKMANN",
            "id":"14140050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Brinkmann",
               "id":"14140050"
            },
            "centroide":{
               "lat":-30.8665250177702,
               "lon":-62.0356522438995
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA ANITA",
            "id":"14140060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Anita",
               "id":"14140060"
            },
            "centroide":{
               "lat":-31.1438629464364,
               "lon":-62.2135862922774
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA 10 DE JULIO",
            "id":"14140070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia 10 de Julio",
               "id":"14140070"
            },
            "centroide":{
               "lat":-30.5273759974763,
               "lon":-62.114218229406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia las Pichanas",
               "id":"142945"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA LAS PICHANAS",
            "id":"14140080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Las Pichanas",
               "id":"14140080"
            },
            "centroide":{
               "lat":-31.2454066292387,
               "lon":-62.9256664887207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Marina",
               "id":"141197"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA MARINA",
            "id":"14140090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Marina",
               "id":"14140090"
            },
            "centroide":{
               "lat":-31.2485066335845,
               "lon":-62.3639645596989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Prosperidad",
               "id":"141204"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA PROSPERIDAD",
            "id":"14140100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Prosperidad",
               "id":"14140100"
            },
            "centroide":{
               "lat":-31.6304966252155,
               "lon":-62.3663613505205
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia San Bartolomé",
               "id":"141211"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA SAN BARTOLOME",
            "id":"14140110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia San Bartolomé",
               "id":"14140110"
            },
            "centroide":{
               "lat":-31.528322298444,
               "lon":-62.7244933856141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia San Pedro",
               "id":"142952"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA SAN PEDRO",
            "id":"14140120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia San Pedro",
               "id":"14140120"
            },
            "centroide":{
               "lat":-30.7836021597954,
               "lon":-61.9175643185455
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Prosperidad",
               "id":"141204"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA SANTA MARIA",
            "id":"14140130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Santa María",
               "id":"14140130"
            },
            "centroide":{
               "lat":-31.6057747904501,
               "lon":-62.4278882105043
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Valtelina",
               "id":"142959"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA VALTELINA",
            "id":"14140140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Valtelina",
               "id":"14140140"
            },
            "centroide":{
               "lat":-31.0529548297979,
               "lon":-62.235437860737
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Vignaud",
               "id":"141218"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA VIGNAUD",
            "id":"14140150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Vignaud",
               "id":"14140150"
            },
            "centroide":{
               "lat":-30.8416242298498,
               "lon":-61.9558386203663
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Devoto",
               "id":"141225"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"DEVOTO",
            "id":"14140160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Devoto",
               "id":"14140160"
            },
            "centroide":{
               "lat":-31.403980918137,
               "lon":-62.3061393858824
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Arañado",
               "id":"141232"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"EL ARAÑADO",
            "id":"14140170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Arañado",
               "id":"14140170"
            },
            "centroide":{
               "lat":-31.7412330671687,
               "lon":-62.8933443705701
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Fortín",
               "id":"141239"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"EL FORTIN",
            "id":"14140180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Fortín",
               "id":"14140180"
            },
            "centroide":{
               "lat":-31.9672502352148,
               "lon":-62.3026285942112
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyito",
               "id":"141176"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"EL FUERTECITO",
            "id":"14140190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Fuertecito",
               "id":"14140190"
            },
            "centroide":{
               "lat":-31.4039349300198,
               "lon":-62.9733657676524
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Tío",
               "id":"141246"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"EL TIO",
            "id":"14140200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Tío",
               "id":"14140200"
            },
            "centroide":{
               "lat":-31.3845486215618,
               "lon":-62.8284737646901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Plaza Luxardo",
               "id":"142966"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"ESTACION LUXARDO",
            "id":"14140210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Estación Luxardo",
               "id":"14140210"
            },
            "centroide":{
               "lat":-31.3047488843392,
               "lon":-62.1333023360613
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Iturraspe",
               "id":"142938"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"COLONIA ITURRASPE",
            "id":"14140215000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Iturraspe",
               "id":"14140215"
            },
            "centroide":{
               "lat":-31.2071433242951,
               "lon":-62.1099182033011
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Freyre",
               "id":"141253"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"FREYRE",
            "id":"14140220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Freyre",
               "id":"14140220"
            },
            "centroide":{
               "lat":-31.1647319320813,
               "lon":-62.0976333908888
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Francia",
               "id":"141260"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"LA FRANCIA",
            "id":"14140230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Francia",
               "id":"14140230"
            },
            "centroide":{
               "lat":-31.406437919327,
               "lon":-62.6343581010891
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paquita",
               "id":"141267"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"LA PAQUITA",
            "id":"14140240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Paquita",
               "id":"14140240"
            },
            "centroide":{
               "lat":-30.9070545771133,
               "lon":-62.2154361817056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Tordilla",
               "id":"141274"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"LA TORDILLA",
            "id":"14140250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Tordilla",
               "id":"14140250"
            },
            "centroide":{
               "lat":-31.2352452196552,
               "lon":-63.0599042713799
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Varas",
               "id":"141281"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"LAS VARAS",
            "id":"14140260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Varas",
               "id":"14140260"
            },
            "centroide":{
               "lat":-31.801292158779,
               "lon":-62.6171047243489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Varillas",
               "id":"141288"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"LAS VARILLAS",
            "id":"14140270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Varillas",
               "id":"14140270"
            },
            "centroide":{
               "lat":-31.8731135581313,
               "lon":-62.7187948274612
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Marull",
               "id":"141295"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"MARULL",
            "id":"14140280000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Marull",
               "id":"14140280"
            },
            "centroide":{
               "lat":-30.9945226321227,
               "lon":-62.8258401081139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Miramar",
               "id":"141302"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"MIRAMAR",
            "id":"14140290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Miramar",
               "id":"14140290"
            },
            "centroide":{
               "lat":-30.9145163516027,
               "lon":-62.6749183796469
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morteros",
               "id":"141309"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"MORTEROS",
            "id":"14140300000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Morteros",
               "id":"14140300"
            },
            "centroide":{
               "lat":-30.7107058083835,
               "lon":-62.0044437379159
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Plaza Luxardo",
               "id":"142966"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"PLAZA LUXARDO",
            "id":"14140310000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Plaza Luxardo",
               "id":"14140310"
            },
            "centroide":{
               "lat":-31.301747140591,
               "lon":-62.2291672872465
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Francisco",
               "id":"141337"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"PLAZA SAN FRANCISCO",
            "id":"14140320000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Plaza San Francisco",
               "id":"14140320"
            },
            "centroide":{
               "lat":-31.3698057980989,
               "lon":-62.0980386090508
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Porteña",
               "id":"141316"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"PORTEÑA",
            "id":"14140330000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Porteña",
               "id":"14140330"
            },
            "centroide":{
               "lat":-31.0139211475279,
               "lon":-62.0633288002735
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quebracho Herrado",
               "id":"141323"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"QUEBRACHO HERRADO",
            "id":"14140340000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Quebracho Herrado",
               "id":"14140340"
            },
            "centroide":{
               "lat":-31.5496022220548,
               "lon":-62.2257312576183
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sacanta",
               "id":"141330"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"SACANTA",
            "id":"14140350000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sacanta",
               "id":"14140350"
            },
            "centroide":{
               "lat":-31.6633035739729,
               "lon":-63.0453212093259
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Francisco",
               "id":"141337"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"SAN FRANCISCO",
            "id":"14140360000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Francisco",
               "id":"14140360"
            },
            "centroide":{
               "lat":-31.4276088869788,
               "lon":-62.0866336146078
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saturnino María Laspiur",
               "id":"141344"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"SATURNINO MARIA LASPIUR",
            "id":"14140370000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Saturnino María Laspiur",
               "id":"14140370"
            },
            "centroide":{
               "lat":-31.7029319509565,
               "lon":-62.4840180855351
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Seeber",
               "id":"141351"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"SEEBER",
            "id":"14140380000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Seeber",
               "id":"14140380"
            },
            "centroide":{
               "lat":-30.9239726451413,
               "lon":-61.9737374276269
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Toro Pujio",
               "id":"142973"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"TORO PUJIO",
            "id":"14140390000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Toro Pujio",
               "id":"14140390"
            },
            "centroide":{
               "lat":-31.1090401829574,
               "lon":-62.9876506224993
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tránsito",
               "id":"141358"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"TRANSITO",
            "id":"14140400000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tránsito",
               "id":"14140400"
            },
            "centroide":{
               "lat":-31.4251109532295,
               "lon":-63.1954038579264
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Concepción del Tío",
               "id":"141365"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"VILLA CONCEPCION DEL TIO",
            "id":"14140420000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Concepción del Tío",
               "id":"14140420"
            },
            "centroide":{
               "lat":-31.3224689015774,
               "lon":-62.8144745758859
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tránsito",
               "id":"141358"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"VILLA DEL TRANSITO",
            "id":"14140430000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Tránsito",
               "id":"14140430"
            },
            "centroide":{
               "lat":-31.4474037687006,
               "lon":-63.1940139744669
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa San Esteban",
               "id":"142980"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"14140"
            },
            "nombre":"VILLA SAN ESTEBAN",
            "id":"14140440000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa San Esteban",
               "id":"14140440"
            },
            "centroide":{
               "lat":-31.6335474004986,
               "lon":-62.896766816574
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alta Gracia",
               "id":"141372"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"ALTA GRACIA",
            "id":"14147010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alta Gracia",
               "id":"14147010"
            },
            "centroide":{
               "lat":-31.6576798267691,
               "lon":-64.4288214061936
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Anisacate",
               "id":"142987"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"ANISACATE",
            "id":"14147020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Anisacate",
               "id":"14147020"
            },
            "centroide":{
               "lat":-31.7231378713901,
               "lon":-64.4144863418681
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"BARRIO GILBERT",
            "id":"14147030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Barrio Gilbert (1º de Mayo) - Tejas Tres",
               "id":"14147030"
            },
            "centroide":{
               "lat":-31.4426508165936,
               "lon":-64.3178623084579
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"BARRIO GILBERT (1° DE MAYO)",
            "id":"14147030001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Barrio Gilbert (1º de Mayo) - Tejas Tres",
               "id":"14147030"
            },
            "centroide":{
               "lat":-31.4406013293568,
               "lon":-64.3177468950882
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"TEJAS TRES",
            "id":"14147030002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Barrio Gilbert (1º de Mayo) - Tejas Tres",
               "id":"14147030"
            },
            "centroide":{
               "lat":-31.4661292884087,
               "lon":-64.3670949660514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bouwer",
               "id":"142994"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"BOUWER",
            "id":"14147050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bouwer",
               "id":"14147050"
            },
            "centroide":{
               "lat":-31.557666956067,
               "lon":-64.194465844878
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Parque Santa Ana",
               "id":"143106"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"CAMPOS DEL VIRREY",
            "id":"14147055000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Campos del Virrey",
               "id":"14147055"
            },
            "centroide":{
               "lat":-31.5854354142154,
               "lon":-64.3439770074277
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bouwer",
               "id":"142994"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"CASEROS CENTRO",
            "id":"14147060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Caseros Centro",
               "id":"14147060"
            },
            "centroide":{
               "lat":-31.5292561486201,
               "lon":-64.1688666057858
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"CAUSANA",
            "id":"14147065000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Causana",
               "id":"14147065"
            },
            "centroide":{
               "lat":-31.4637911559576,
               "lon":-64.4124514041777
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Anisacate",
               "id":"142987"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"COSTA AZUL",
            "id":"14147070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Costa Azul",
               "id":"14147070"
            },
            "centroide":{
               "lat":-31.7247289371172,
               "lon":-64.3939272186065
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Despeñaderos",
               "id":"141379"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"DESPEÑADEROS",
            "id":"14147080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Despeñaderos",
               "id":"14147080"
            },
            "centroide":{
               "lat":-31.8170515932731,
               "lon":-64.2889894059973
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dique Chico",
               "id":"143001"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"DIQUE CHICO",
            "id":"14147090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Dique Chico",
               "id":"14147090"
            },
            "centroide":{
               "lat":-31.751852051978,
               "lon":-64.3627643049016
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alta Gracia",
               "id":"141372"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"EL POTRERILLO",
            "id":"14147095000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Potrerillo",
               "id":"14147095"
            },
            "centroide":{
               "lat":-31.6456181955204,
               "lon":-64.481617153406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"FALDA DEL CAÑETE",
            "id":"14147100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Falda del Cañete",
               "id":"14147100"
            },
            "centroide":{
               "lat":-31.5345237877237,
               "lon":-64.4559553621678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Falda del Carmen",
               "id":"143008"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"FALDA DEL CARMEN",
            "id":"14147110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Falda del Carmen",
               "id":"14147110"
            },
            "centroide":{
               "lat":-31.5854632680747,
               "lon":-64.4548814536225
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa San Isidro",
               "id":"143113"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"JOSE DE LA QUINTANA",
            "id":"14147115000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"José de la Quintana",
               "id":"14147115"
            },
            "centroide":{
               "lat":-31.8034930461757,
               "lon":-64.418856525597
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa San Isidro",
               "id":"143113"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA BOCA DEL RIO",
            "id":"14147120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Boca del Río",
               "id":"14147120"
            },
            "centroide":{
               "lat":-31.8374172963205,
               "lon":-64.4336411746815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA CARBONADA",
            "id":"14147130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Carbonada",
               "id":"14147130"
            },
            "centroide":{
               "lat":-31.5285694623422,
               "lon":-64.0712556356294
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paisanita",
               "id":"143022"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA PAISANITA",
            "id":"14147150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Paisanita",
               "id":"14147150"
            },
            "centroide":{
               "lat":-31.7194206060414,
               "lon":-64.4779150030479
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA PERLA",
            "id":"14147160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Perla",
               "id":"14147160"
            },
            "centroide":{
               "lat":-31.4425579240341,
               "lon":-64.3484859265831
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa San Isidro",
               "id":"143113"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA RANCHERITA",
            "id":"14147170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Rancherita y Las Cascadas",
               "id":"14147170"
            },
            "centroide":{
               "lat":-31.7574675409558,
               "lon":-64.4486902777249
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Serranita",
               "id":"143036"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA SERRANITA",
            "id":"14147180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Serranita",
               "id":"14147180"
            },
            "centroide":{
               "lat":-31.7331340814564,
               "lon":-64.4564154524522
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Cedros",
               "id":"143043"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LOS CEDROS - LAS QUINTAS",
            "id":"14147190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cedros",
               "id":"14147190"
            },
            "centroide":{
               "lat":-31.5262509877113,
               "lon":-64.2847584167114
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Falda del Carmen",
               "id":"143008"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"BARRIO LAS QUINTAS",
            "id":"14147190001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cedros",
               "id":"14147190"
            },
            "centroide":{
               "lat":-31.5502925188794,
               "lon":-64.4334256167366
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LOS CEDROS",
            "id":"14147190002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Cedros",
               "id":"14147190"
            },
            "centroide":{
               "lat":-31.5571765800385,
               "lon":-64.4028321480993
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lozada",
               "id":"141386"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LOZADA",
            "id":"14147200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Lozada",
               "id":"14147200"
            },
            "centroide":{
               "lat":-31.6484545317081,
               "lon":-64.0899796115028
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"MALAGUEÑO",
            "id":"14147210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Malagueño",
               "id":"14147210"
            },
            "centroide":{
               "lat":-31.4648051043666,
               "lon":-64.3584261973277
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"MILENICA",
            "id":"14147217000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Milenica",
               "id":"14147217"
            },
            "centroide":{
               "lat":-31.4572735024583,
               "lon":-64.4057106148379
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Ralo",
               "id":"141400"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"MONTE RALO",
            "id":"14147220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Monte Ralo",
               "id":"14147220"
            },
            "centroide":{
               "lat":-31.9115749813288,
               "lon":-64.2399918461068
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Potrero de Garay",
               "id":"143050"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"POTRERO DE GARAY",
            "id":"14147230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Potrero de Garay",
               "id":"14147230"
            },
            "centroide":{
               "lat":-31.7824159665775,
               "lon":-64.5424068778316
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rafael García",
               "id":"143057"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"RAFAEL GARCIA",
            "id":"14147240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Rafael García",
               "id":"14147240"
            },
            "centroide":{
               "lat":-31.6465373949134,
               "lon":-64.2584787728338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Clemente",
               "id":"143064"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"SAN CLEMENTE",
            "id":"14147250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Clemente",
               "id":"14147250"
            },
            "centroide":{
               "lat":-31.7166546515515,
               "lon":-64.6265869046185
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"SAN NICOLAS",
            "id":"14147260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Nicolás - Tierra Alta",
               "id":"14147260"
            },
            "centroide":{
               "lat":-31.435151387072,
               "lon":-64.4492154097951
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"SAN NICOLAS",
            "id":"14147260001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Nicolás - Tierra Alta",
               "id":"14147260"
            },
            "centroide":{
               "lat":-31.4364876977282,
               "lon":-64.4538955732489
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"TIERRA ALTA",
            "id":"14147260002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Nicolás - Tierra Alta",
               "id":"14147260"
            },
            "centroide":{
               "lat":-31.4723029527477,
               "lon":-64.4707945856239
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"SOCAVONES",
            "id":"14147270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Socavones",
               "id":"14147270"
            },
            "centroide":{
               "lat":-31.5300866657118,
               "lon":-64.1457832241514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Toledo",
               "id":"141407"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"TOLEDO",
            "id":"14147280000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Toledo",
               "id":"14147280"
            },
            "centroide":{
               "lat":-31.5560967583723,
               "lon":-64.0080543306962
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Falda del Carmen",
               "id":"143008"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VALLE ALEGRE",
            "id":"14147290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Valle Alegre",
               "id":"14147290"
            },
            "centroide":{
               "lat":-31.608213303048,
               "lon":-64.4403684727464
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle de Anisacate",
               "id":"143071"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VALLE DE ANISACATE",
            "id":"14147300000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Valle de Anisacate",
               "id":"14147300"
            },
            "centroide":{
               "lat":-31.7314776926852,
               "lon":-64.4129622125593
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad de América",
               "id":"143078"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA CIUDAD DE AMERICA (LOTEO DIEGO DE",
            "id":"14147310000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad de América",
               "id":"14147310"
            },
            "centroide":{
               "lat":-31.7944839463173,
               "lon":-64.5122651994167
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad de América",
               "id":"143078"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"BARRIO VILLA DEL PARQUE",
            "id":"14147310001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad de América",
               "id":"14147310"
            },
            "centroide":{
               "lat":-31.7772193262534,
               "lon":-64.532077974826
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ciudad de América",
               "id":"143078"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA CIUDAD DE AMERICA",
            "id":"14147310002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ciudad de América",
               "id":"14147310"
            },
            "centroide":{
               "lat":-31.7932195162073,
               "lon":-64.5139255175446
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Prado",
               "id":"143085"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA DEL PRADO",
            "id":"14147320000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Prado",
               "id":"14147320"
            },
            "centroide":{
               "lat":-31.6179397578028,
               "lon":-64.3924925248834
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Prado",
               "id":"143085"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA DEL PRADO",
            "id":"14147320001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Prado",
               "id":"14147320"
            },
            "centroide":{
               "lat":-31.6174543235156,
               "lon":-64.391062873902
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alta Gracia",
               "id":"141372"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"LA DONOSA",
            "id":"14147320002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Prado",
               "id":"14147320"
            },
            "centroide":{
               "lat":-31.6430306930417,
               "lon":-64.3453826897825
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa la Bolsa",
               "id":"143092"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA LA BOLSA",
            "id":"14147330000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa La Bolsa",
               "id":"14147330"
            },
            "centroide":{
               "lat":-31.7287879568356,
               "lon":-64.4324174982752
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa los Aromos",
               "id":"143099"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA LOS AROMOS",
            "id":"14147340000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Los Aromos",
               "id":"14147340"
            },
            "centroide":{
               "lat":-31.7359598057037,
               "lon":-64.4389279396961
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Parque Santa Ana",
               "id":"143106"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA PARQUE SANTA ANA",
            "id":"14147350000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Parque Santa Ana",
               "id":"14147350"
            },
            "centroide":{
               "lat":-31.5893075812486,
               "lon":-64.3536920462417
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Parque Santa Ana",
               "id":"143106"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"MI VALLE",
            "id":"14147350001",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Parque Santa Ana",
               "id":"14147350"
            },
            "centroide":{
               "lat":-31.5725913753684,
               "lon":-64.3341910295503
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Parque Santa Ana",
               "id":"143106"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA PARQUE SANTA ANA",
            "id":"14147350002",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Parque Santa Ana",
               "id":"14147350"
            },
            "centroide":{
               "lat":-31.5917319016207,
               "lon":-64.3503003378206
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa San Isidro",
               "id":"143113"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA SAN ISIDRO - JOSE DE LA QUINTANA",
            "id":"14147360000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa San Isidro",
               "id":"14147360"
            },
            "centroide":{
               "lat":-31.8208264119355,
               "lon":-64.3935275639835
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"VILLA SIERRAS DE ORO",
            "id":"14147370000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Sierras De Oro",
               "id":"14147370"
            },
            "centroide":{
               "lat":-31.4579361723038,
               "lon":-64.4217153267522
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malagueño",
               "id":"141393"
            },
            "departamento":{
               "nombre":"Santa María",
               "id":"14147"
            },
            "nombre":"YOCSINA",
            "id":"14147380000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Yocsina",
               "id":"14147380"
            },
            "centroide":{
               "lat":-31.4470577243833,
               "lon":-64.3660748415952
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caminiaga",
               "id":"143120"
            },
            "departamento":{
               "nombre":"Sobremonte",
               "id":"14154"
            },
            "nombre":"CAMINIAGA",
            "id":"14154010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Caminiaga",
               "id":"14154010"
            },
            "centroide":{
               "lat":-30.0682250779624,
               "lon":-64.052875898278
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Sobremonte",
               "id":"14154"
            },
            "nombre":"CHUÑA HUASI",
            "id":"14154030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chuña Huasi",
               "id":"14154030"
            },
            "centroide":{
               "lat":-29.9138372593106,
               "lon":-64.1299817960277
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pozo Nuevo",
               "id":"143134"
            },
            "departamento":{
               "nombre":"Sobremonte",
               "id":"14154"
            },
            "nombre":"POZO NUEVO",
            "id":"14154040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pozo Nuevo",
               "id":"14154040"
            },
            "centroide":{
               "lat":-29.5775456904692,
               "lon":-64.1070996245776
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Francisco del Chañar",
               "id":"141414"
            },
            "departamento":{
               "nombre":"Sobremonte",
               "id":"14154"
            },
            "nombre":"SAN FRANCISCO DEL CHAÑAR",
            "id":"14154050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Francisco del Chañar",
               "id":"14154050"
            },
            "centroide":{
               "lat":-29.7882609409722,
               "lon":-63.9430703616826
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almafuerte",
               "id":"141421"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"ALMAFUERTE",
            "id":"14161010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Almafuerte",
               "id":"14161010"
            },
            "centroide":{
               "lat":-32.1934604986786,
               "lon":-64.2543871859511
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Almada",
               "id":"141428"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"COLONIA ALMADA",
            "id":"14161020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Almada",
               "id":"14161020"
            },
            "centroide":{
               "lat":-32.033345652856,
               "lon":-63.8628846933814
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corralito",
               "id":"141435"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"CORRALITO",
            "id":"14161030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Corralito",
               "id":"14161030"
            },
            "centroide":{
               "lat":-32.0252572445237,
               "lon":-64.1931588929625
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dalmacio Vélez",
               "id":"141442"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"DALMACIO VELEZ",
            "id":"14161040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Dalmacio Vélez",
               "id":"14161040"
            },
            "centroide":{
               "lat":-32.6108732524704,
               "lon":-63.5798837158318
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Fotheringham",
               "id":"143141"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"GENERAL FOTHERINGHAM",
            "id":"14161050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"General Fotheringham",
               "id":"14161050"
            },
            "centroide":{
               "lat":-32.3234295842043,
               "lon":-63.8704257430147
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hernando",
               "id":"141449"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"HERNANDO",
            "id":"14161060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Hernando",
               "id":"14161060"
            },
            "centroide":{
               "lat":-32.4276148348373,
               "lon":-63.7330119093142
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"James Craik",
               "id":"141456"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"JAMES CRAIK",
            "id":"14161070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"James Craik",
               "id":"14161070"
            },
            "centroide":{
               "lat":-32.1613056379775,
               "lon":-63.4651919500633
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Isletillas",
               "id":"143148"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"LAS ISLETILLAS",
            "id":"14161080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Isletillas",
               "id":"14161080"
            },
            "centroide":{
               "lat":-32.5100979867632,
               "lon":-63.9290229356551
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Perdices",
               "id":"141463"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"LAS PERDICES",
            "id":"14161090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Perdices",
               "id":"14161090"
            },
            "centroide":{
               "lat":-32.6981884459632,
               "lon":-63.7083103176214
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Zorros",
               "id":"141470"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"LOS ZORROS",
            "id":"14161100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Zorros",
               "id":"14161100"
            },
            "centroide":{
               "lat":-32.045928188462,
               "lon":-63.2017981667251
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Oliva",
               "id":"141477"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"OLIVA",
            "id":"14161110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Oliva",
               "id":"14161110"
            },
            "centroide":{
               "lat":-32.0413174021447,
               "lon":-63.5693820664827
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampayasta Norte",
               "id":"143155"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"PAMPAYASTA NORTE",
            "id":"14161120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pampayasta Norte",
               "id":"14161120"
            },
            "centroide":{
               "lat":-32.2416827296709,
               "lon":-63.6421868735368
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampayasta Sud",
               "id":"141484"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"PAMPAYASTA SUR",
            "id":"14161130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pampayasta Sud",
               "id":"14161130"
            },
            "centroide":{
               "lat":-32.2507581145055,
               "lon":-63.6510140335346
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Punta del Agua",
               "id":"143162"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"PUNTA DEL AGUA",
            "id":"14161140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Punta del Agua",
               "id":"14161140"
            },
            "centroide":{
               "lat":-32.5754281748334,
               "lon":-63.8101580899516
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Tercero",
               "id":"141491"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"RIO TERCERO",
            "id":"14161150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Río Tercero",
               "id":"14161150"
            },
            "centroide":{
               "lat":-32.173100971278,
               "lon":-64.1130560562283
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tancacha",
               "id":"141498"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"TANCACHA",
            "id":"14161160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Tancacha",
               "id":"14161160"
            },
            "centroide":{
               "lat":-32.2402070251544,
               "lon":-63.9799466045503
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ascasubi",
               "id":"141505"
            },
            "departamento":{
               "nombre":"Tercero Arriba",
               "id":"14161"
            },
            "nombre":"VILLA ASCASUBI",
            "id":"14161170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Ascasubi",
               "id":"14161170"
            },
            "centroide":{
               "lat":-32.1643583032298,
               "lon":-63.892558867184
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Candelaria Sud",
               "id":"143169"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"CANDELARIA SUR",
            "id":"14168010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Candelaria Sur",
               "id":"14168010"
            },
            "centroide":{
               "lat":-30.8392305968427,
               "lon":-63.8008465946487
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada de Luque",
               "id":"141512"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"CAÑADA DE LUQUE",
            "id":"14168020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cañada de Luque",
               "id":"14168020"
            },
            "centroide":{
               "lat":-30.7370875150032,
               "lon":-63.723661877713
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capilla del Sitón",
               "id":"143176"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"CAPILLA DE SITON",
            "id":"14168030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Capilla de Sitón",
               "id":"14168030"
            },
            "centroide":{
               "lat":-30.5729640848008,
               "lon":-63.6517416910945
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Pampa",
               "id":"143183"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"LA PAMPA",
            "id":"14168040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"La Pampa",
               "id":"14168040"
            },
            "centroide":{
               "lat":-30.943220474797,
               "lon":-64.2765349210302
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Peñas",
               "id":"141519"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"LAS PEÑAS",
            "id":"14168060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Peñas",
               "id":"14168060"
            },
            "centroide":{
               "lat":-30.5617525961006,
               "lon":-64.0019842072006
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Mistoles",
               "id":"143190"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"LOS MISTOLES",
            "id":"14168070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Los Mistoles",
               "id":"14168070"
            },
            "centroide":{
               "lat":-30.6259742943516,
               "lon":-63.886727301445
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"SANTA CATALINA",
            "id":"14168080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Santa Catalina",
               "id":"14168080"
            },
            "centroide":{
               "lat":-30.8726236468488,
               "lon":-64.2313503284229
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"141526"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"SARMIENTO",
            "id":"14168090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sarmiento",
               "id":"14168090"
            },
            "centroide":{
               "lat":-30.7741941000543,
               "lon":-64.1089868115808
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Simbolar",
               "id":"143197"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"SIMBOLAR",
            "id":"14168100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Simbolar",
               "id":"14168100"
            },
            "centroide":{
               "lat":-30.4752563998131,
               "lon":-63.9850395218446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sinsacate",
               "id":"141533"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"SINSACATE",
            "id":"14168110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Sinsacate",
               "id":"14168110"
            },
            "centroide":{
               "lat":-30.9428574787407,
               "lon":-64.0884974374818
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Totoral",
               "id":"141540"
            },
            "departamento":{
               "nombre":"Totoral",
               "id":"14168"
            },
            "nombre":"VILLA DEL TOTORAL",
            "id":"14168120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa del Totoral",
               "id":"14168120"
            },
            "centroide":{
               "lat":-30.7038437425366,
               "lon":-64.0682991778579
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"CHURQUI CAÑADA",
            "id":"14175020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Churqui Cañada",
               "id":"14175020"
            },
            "centroide":{
               "lat":-30.1681571983317,
               "lon":-63.9295889316832
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Rodeo",
               "id":"143211"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"EL RODEO",
            "id":"14175030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Rodeo",
               "id":"14175030"
            },
            "centroide":{
               "lat":-30.176079345889,
               "lon":-63.8679636143828
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"EL TUSCAL",
            "id":"14175040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"El Tuscal",
               "id":"14175040"
            },
            "centroide":{
               "lat":-29.7574368770199,
               "lon":-64.5298988550679
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Arrias",
               "id":"141547"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"LAS ARRIAS",
            "id":"14175050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Las Arrias",
               "id":"14175050"
            },
            "centroide":{
               "lat":-30.3812826841011,
               "lon":-63.5966635615372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lucio V. Mansilla",
               "id":"141554"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"LUCIO V. MANSILLA",
            "id":"14175060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Lucio V. Mansilla",
               "id":"14175060"
            },
            "centroide":{
               "lat":-29.8063717196573,
               "lon":-64.7065412644276
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"ROSARIO DEL SALADILLO",
            "id":"14175070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Rosario del Saladillo",
               "id":"14175070"
            },
            "centroide":{
               "lat":-30.4293369124895,
               "lon":-63.4478615181188
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José de la Dormida",
               "id":"141561"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"SAN JOSE DE LA DORMIDA",
            "id":"14175080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San José de la Dormida",
               "id":"14175080"
            },
            "centroide":{
               "lat":-30.3546018393412,
               "lon":-63.9466529209876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José de las Salinas",
               "id":"141568"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"SAN JOSE DE LAS SALINAS",
            "id":"14175090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San José de las Salinas",
               "id":"14175090"
            },
            "centroide":{
               "lat":-30.0090773891027,
               "lon":-64.6251344233407
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro Norte",
               "id":"141575"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"SAN PEDRO NORTE",
            "id":"14175100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Pedro Norte",
               "id":"14175100"
            },
            "centroide":{
               "lat":-30.0887589979373,
               "lon":-64.1559536726827
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Tulumba",
               "id":"141582"
            },
            "departamento":{
               "nombre":"Tulumba",
               "id":"14175"
            },
            "nombre":"VILLA TULUMBA",
            "id":"14175110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Tulumba",
               "id":"14175110"
            },
            "centroide":{
               "lat":-30.397481563929,
               "lon":-64.1231771567642
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aldea Santa María",
               "id":"143225"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"ALDEA SANTA MARIA",
            "id":"14182010000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Aldea Santa María",
               "id":"14182010"
            },
            "centroide":{
               "lat":-33.6948816087071,
               "lon":-62.9114758842793
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alto Alegre",
               "id":"141589"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"ALTO ALEGRE",
            "id":"14182020000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Alto Alegre",
               "id":"14182020"
            },
            "centroide":{
               "lat":-32.3460951978736,
               "lon":-62.8853485991764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ana Zumarán",
               "id":"143232"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"ANA ZUMARAN",
            "id":"14182030000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ana Zumarán",
               "id":"14182030"
            },
            "centroide":{
               "lat":-32.3908244034289,
               "lon":-63.0574835050696
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ballesteros",
               "id":"141596"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"BALLESTEROS",
            "id":"14182040000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ballesteros",
               "id":"14182040"
            },
            "centroide":{
               "lat":-32.5453815879853,
               "lon":-62.9833005213374
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ballesteros Sud",
               "id":"141603"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"BALLESTEROS SUR",
            "id":"14182050000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ballesteros Sud",
               "id":"14182050"
            },
            "centroide":{
               "lat":-32.5885971215357,
               "lon":-63.027038782482
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bell Ville",
               "id":"141610"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"BELL VILLE",
            "id":"14182060000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Bell Ville",
               "id":"14182060"
            },
            "centroide":{
               "lat":-32.6285600750708,
               "lon":-62.6891149083321
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benjamín Gould",
               "id":"141617"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"BENJAMIN GOULD",
            "id":"14182070000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Benjamín Gould",
               "id":"14182070"
            },
            "centroide":{
               "lat":-33.5901195408333,
               "lon":-62.7303890735446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Canals",
               "id":"141624"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"CANALS",
            "id":"14182080000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Canals",
               "id":"14182080"
            },
            "centroide":{
               "lat":-33.562382718352,
               "lon":-62.8855974037259
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilibroste",
               "id":"141631"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"CHILIBROSTE",
            "id":"14182090000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Chilibroste",
               "id":"14182090"
            },
            "centroide":{
               "lat":-32.3325969254244,
               "lon":-62.5135995097258
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cintra",
               "id":"141638"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"CINTRA",
            "id":"14182100000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Cintra",
               "id":"14182100"
            },
            "centroide":{
               "lat":-32.3081476332499,
               "lon":-62.6531184151953
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Bismarck",
               "id":"141645"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"COLONIA BISMARCK",
            "id":"14182110000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Bismarck",
               "id":"14182110"
            },
            "centroide":{
               "lat":-33.3003017093034,
               "lon":-62.7132986409449
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Bremen",
               "id":"143239"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"COLONIA BREMEN",
            "id":"14182120000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Colonia Bremen",
               "id":"14182120"
            },
            "centroide":{
               "lat":-33.4634972191589,
               "lon":-62.7323851196295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Idiazabal",
               "id":"141652"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"IDIAZABAL",
            "id":"14182130000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Idiazabal",
               "id":"14182130"
            },
            "centroide":{
               "lat":-32.813142666622,
               "lon":-63.0329101219103
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Justiniano Posse",
               "id":"141659"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"JUSTINIANO POSSE",
            "id":"14182140000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Justiniano Posse",
               "id":"14182140"
            },
            "centroide":{
               "lat":-32.883580112369,
               "lon":-62.6802767037884
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laborde",
               "id":"141666"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"LABORDE",
            "id":"14182150000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Laborde",
               "id":"14182150"
            },
            "centroide":{
               "lat":-33.1529891830182,
               "lon":-62.8560737781526
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Leña",
               "id":"141673"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"MONTE LEÑA",
            "id":"14182160000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Monte Leña",
               "id":"14182160"
            },
            "centroide":{
               "lat":-32.6112897765277,
               "lon":-62.5903410387947
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Maíz",
               "id":"141680"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"MONTE MAIZ",
            "id":"14182170000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Monte Maíz",
               "id":"14182170"
            },
            "centroide":{
               "lat":-33.2045620901114,
               "lon":-62.601248151355
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Morrison",
               "id":"141687"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"MORRISON",
            "id":"14182180000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Morrison",
               "id":"14182180"
            },
            "centroide":{
               "lat":-32.5936423075986,
               "lon":-62.8360084604177
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Noetinger",
               "id":"141694"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"NOETINGER",
            "id":"14182190000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Noetinger",
               "id":"14182190"
            },
            "centroide":{
               "lat":-32.3667416426436,
               "lon":-62.312071766995
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ordóñez",
               "id":"141701"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"ORDOÑEZ",
            "id":"14182200000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ordoñez",
               "id":"14182200"
            },
            "centroide":{
               "lat":-32.8412715334934,
               "lon":-62.866054921116
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pascanas",
               "id":"141708"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"PASCANAS",
            "id":"14182210000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pascanas",
               "id":"14182210"
            },
            "centroide":{
               "lat":-33.1249872931372,
               "lon":-63.0426957377869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Italiano",
               "id":"141715"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"PUEBLO ITALIANO",
            "id":"14182220000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Pueblo Italiano",
               "id":"14182220"
            },
            "centroide":{
               "lat":-33.880722767451,
               "lon":-62.8407250305139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"RAMON J. CARCANO",
            "id":"14182230000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Ramón J. Cárcano",
               "id":"14182230"
            },
            "centroide":{
               "lat":-32.4911089118919,
               "lon":-63.1028075144056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Litín",
               "id":"141722"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"SAN ANTONIO DE LITIN",
            "id":"14182240000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Antonio de Litín",
               "id":"14182240"
            },
            "centroide":{
               "lat":-32.2128605395629,
               "lon":-62.6330068495678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Marcos Sud",
               "id":"141729"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"SAN MARCOS",
            "id":"14182250000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Marcos",
               "id":"14182250"
            },
            "centroide":{
               "lat":-32.6303275526998,
               "lon":-62.4819712615844
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"SAN SEVERO",
            "id":"14182260000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"San Severo",
               "id":"14182260"
            },
            "centroide":{
               "lat":-33.5383254710795,
               "lon":-63.0479276785208
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Viamonte",
               "id":"141736"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"VIAMONTE",
            "id":"14182270000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Viamonte",
               "id":"14182270"
            },
            "centroide":{
               "lat":-33.7470669728167,
               "lon":-63.0989062265371
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa los Patos",
               "id":"143246"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"VILLA LOS PATOS",
            "id":"14182280000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Villa Los Patos",
               "id":"14182280"
            },
            "centroide":{
               "lat":-32.763745038404,
               "lon":-62.7277954886855
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Wenceslao Escalante",
               "id":"141743"
            },
            "departamento":{
               "nombre":"Unión",
               "id":"14182"
            },
            "nombre":"WENCESLAO ESCALANTE",
            "id":"14182290000",
            "provincia":{
               "nombre":"Córdoba",
               "id":"14"
            },
            "localidad_censal":{
               "nombre":"Wenceslao Escalante",
               "id":"14182290"
            },
            "centroide":{
               "lat":-33.1720228484368,
               "lon":-62.7700003268955
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bella Vista",
               "id":"180007"
            },
            "departamento":{
               "nombre":"Bella Vista",
               "id":"18007"
            },
            "nombre":"BELLA VISTA",
            "id":"18007010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Bella Vista",
               "id":"18007010"
            },
            "centroide":{
               "lat":-28.507677249655,
               "lon":-59.04433283502
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berón de Astrada",
               "id":"180014"
            },
            "departamento":{
               "nombre":"Berón de Astrada",
               "id":"18014"
            },
            "nombre":"BERON DE ASTRADA",
            "id":"18014010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Berón de Astrada",
               "id":"18014010"
            },
            "centroide":{
               "lat":-27.5506233626678,
               "lon":-57.5376245501398
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berón de Astrada",
               "id":"180014"
            },
            "departamento":{
               "nombre":"Berón de Astrada",
               "id":"18014"
            },
            "nombre":"YAHAPE",
            "id":"18014020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Yahapé",
               "id":"18014020"
            },
            "centroide":{
               "lat":-27.3704269216251,
               "lon":-57.6552249966379
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corrientes",
               "id":"180021"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"18021"
            },
            "nombre":"CORRIENTES",
            "id":"18021020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Corrientes",
               "id":"18021020"
            },
            "centroide":{
               "lat":-27.4632821641043,
               "lon":-58.8392333481757
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corrientes",
               "id":"180021"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"18021"
            },
            "nombre":"LAGUNA BRAVA",
            "id":"18021030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Laguna Brava",
               "id":"18021030"
            },
            "centroide":{
               "lat":-27.492827539732,
               "lon":-58.7167656641361
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Riachuelo",
               "id":"180028"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"18021"
            },
            "nombre":"RIACHUELO",
            "id":"18021040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Riachuelo",
               "id":"18021040"
            },
            "centroide":{
               "lat":-27.5811452851025,
               "lon":-58.7419831223036
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Riachuelo",
               "id":"180028"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"18021"
            },
            "nombre":"SAN CAYETANO",
            "id":"18021050000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Cayetano",
               "id":"18021050"
            },
            "centroide":{
               "lat":-27.5712103716288,
               "lon":-58.6958799100026
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Concepción",
               "id":"180042"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"18028"
            },
            "nombre":"CONCEPCION",
            "id":"18028010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Concepción",
               "id":"18028010"
            },
            "centroide":{
               "lat":-28.3924910085097,
               "lon":-57.8866807708097
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Santa Rosa",
               "id":"180035"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"18028"
            },
            "nombre":"SANTA ROSA",
            "id":"18028020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa",
               "id":"18028020"
            },
            "centroide":{
               "lat":-28.2674256970164,
               "lon":-58.1220612497619
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tabay",
               "id":"180049"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"18028"
            },
            "nombre":"TABAY",
            "id":"18028030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Tabay",
               "id":"18028030"
            },
            "centroide":{
               "lat":-28.3062678179647,
               "lon":-58.2862966985736
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tatacuá",
               "id":"180056"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"18028"
            },
            "nombre":"TATACUA",
            "id":"18028040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Tatacua",
               "id":"18028040"
            },
            "centroide":{
               "lat":-28.3720748439642,
               "lon":-58.3252896634835
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Curuzú Cuatiá",
               "id":"180063"
            },
            "departamento":{
               "nombre":"Curuzu Cuatia",
               "id":"18035"
            },
            "nombre":"CAZADORES CORRENTINOS",
            "id":"18035010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Cazadores Correntinos",
               "id":"18035010"
            },
            "centroide":{
               "lat":-29.9787827263917,
               "lon":-58.3024356720991
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Curuzú Cuatiá",
               "id":"180063"
            },
            "departamento":{
               "nombre":"Curuzu Cuatia",
               "id":"18035"
            },
            "nombre":"CURUZU CUATIA",
            "id":"18035020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Curuzú Cuatiá",
               "id":"18035020"
            },
            "centroide":{
               "lat":-29.7915233554051,
               "lon":-58.049945341682
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Perugorría",
               "id":"180070"
            },
            "departamento":{
               "nombre":"Curuzu Cuatia",
               "id":"18035"
            },
            "nombre":"PERUGORRIA",
            "id":"18035030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Perugorría",
               "id":"18035030"
            },
            "centroide":{
               "lat":-29.3404796154785,
               "lon":-58.6080890462901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Empedrado",
               "id":"180077"
            },
            "departamento":{
               "nombre":"Empedrado",
               "id":"18042"
            },
            "nombre":"EL SOMBRERO",
            "id":"18042010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"El Sombrero",
               "id":"18042010"
            },
            "centroide":{
               "lat":-27.7035440870919,
               "lon":-58.7686060583955
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Empedrado",
               "id":"180077"
            },
            "departamento":{
               "nombre":"Empedrado",
               "id":"18042"
            },
            "nombre":"EMPEDRADO",
            "id":"18042020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Empedrado",
               "id":"18042020"
            },
            "centroide":{
               "lat":-27.9524556932224,
               "lon":-58.8074763893306
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esquina",
               "id":"180084"
            },
            "departamento":{
               "nombre":"Esquina",
               "id":"18049"
            },
            "nombre":"ESQUINA",
            "id":"18049010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Esquina",
               "id":"18049010"
            },
            "centroide":{
               "lat":-30.0159284150174,
               "lon":-59.5309812132883
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Libertador",
               "id":"180091"
            },
            "departamento":{
               "nombre":"Esquina",
               "id":"18049"
            },
            "nombre":"PUEBLO LIBERTADOR",
            "id":"18049020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Pueblo Libertador",
               "id":"18049020"
            },
            "centroide":{
               "lat":-30.2195813152624,
               "lon":-59.3906028568407
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alvear",
               "id":"180098"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"18056"
            },
            "nombre":"ALVEAR",
            "id":"18056010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Alvear",
               "id":"18056010"
            },
            "centroide":{
               "lat":-29.0985990956679,
               "lon":-56.5521631297617
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Torrent",
               "id":"180105"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"18056"
            },
            "nombre":"ESTACION TORRENT",
            "id":"18056020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Estación Torrent",
               "id":"18056020"
            },
            "centroide":{
               "lat":-28.8266239157245,
               "lon":-56.469525963544
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Itá Ibaté",
               "id":"180119"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"18063"
            },
            "nombre":"ITA IBATE",
            "id":"18063010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Itá Ibaté",
               "id":"18063010"
            },
            "centroide":{
               "lat":-27.4250661277127,
               "lon":-57.3376993601456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lomas de Vallejos",
               "id":"180126"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"18063"
            },
            "nombre":"LOMAS DE VALLEJOS",
            "id":"18063020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Lomas de Vallejos",
               "id":"18063020"
            },
            "centroide":{
               "lat":-27.7329656323888,
               "lon":-57.9193852921423
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caá Catí",
               "id":"180112"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"18063"
            },
            "nombre":"NUESTRA SEÑORA DEL ROSARIO DE CAA CATI",
            "id":"18063030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Nuestra Señora del Rosario de Caá Catí",
               "id":"18063030"
            },
            "centroide":{
               "lat":-27.751995828158,
               "lon":-57.6225136238666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palmar Grande",
               "id":"180133"
            },
            "departamento":{
               "nombre":"General Paz",
               "id":"18063"
            },
            "nombre":"PALMAR GRANDE",
            "id":"18063040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Palmar Grande",
               "id":"18063040"
            },
            "centroide":{
               "lat":-27.9407694115665,
               "lon":-57.9009156630285
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carolina",
               "id":"180137"
            },
            "departamento":{
               "nombre":"Goya",
               "id":"18070"
            },
            "nombre":"COLONIA CAROLINA",
            "id":"18070010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Carolina",
               "id":"18070010"
            },
            "centroide":{
               "lat":-29.1457706796124,
               "lon":-59.1820838282047
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Goya",
               "id":"180140"
            },
            "departamento":{
               "nombre":"Goya",
               "id":"18070"
            },
            "nombre":"GOYA",
            "id":"18070020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Goya",
               "id":"18070020"
            },
            "centroide":{
               "lat":-29.1413439330685,
               "lon":-59.2605311638707
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Itatí",
               "id":"180147"
            },
            "departamento":{
               "nombre":"Itatí",
               "id":"18077"
            },
            "nombre":"ITATI",
            "id":"18077010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Itatí",
               "id":"18077010"
            },
            "centroide":{
               "lat":-27.2693038533077,
               "lon":-58.2434782721969
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramada Paso",
               "id":"180154"
            },
            "departamento":{
               "nombre":"Itatí",
               "id":"18077"
            },
            "nombre":"RAMADA PASO",
            "id":"18077020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Ramada Paso",
               "id":"18077020"
            },
            "centroide":{
               "lat":-27.3659289314983,
               "lon":-58.3003087514316
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Liebig'S",
               "id":"180161"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"18084"
            },
            "nombre":"COLONIA LIEBIG'S",
            "id":"18084010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Colonia Liebig's",
               "id":"18084010"
            },
            "centroide":{
               "lat":-27.9156591543749,
               "lon":-55.8233285990026
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ituzaingó",
               "id":"180168"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"18084"
            },
            "nombre":"ITUZAINGO",
            "id":"18084020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Ituzaingó",
               "id":"18084020"
            },
            "centroide":{
               "lat":-27.5910429413741,
               "lon":-56.7039739448011
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"180175"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"18084"
            },
            "nombre":"SAN ANTONIO",
            "id":"18084030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"18084030"
            },
            "centroide":{
               "lat":-27.5073854602181,
               "lon":-56.7411670700869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"180182"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"18084"
            },
            "nombre":"SAN CARLOS",
            "id":"18084040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Carlos",
               "id":"18084040"
            },
            "centroide":{
               "lat":-27.745785086345,
               "lon":-55.9000479407003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Olivari",
               "id":"180186"
            },
            "departamento":{
               "nombre":"Ituzaingó",
               "id":"18084"
            },
            "nombre":"VILLA OLIVARI",
            "id":"18084050000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Villa Olivari",
               "id":"18084050"
            },
            "centroide":{
               "lat":-27.6329473311043,
               "lon":-56.9062014827101
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cruz de los Milagros",
               "id":"180189"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"18091"
            },
            "nombre":"CRUZ DE LOS MILAGROS",
            "id":"18091010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Cruz de los Milagros",
               "id":"18091010"
            },
            "centroide":{
               "lat":-28.8357350715385,
               "lon":-59.0068578339405
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Martínez",
               "id":"180196"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"18091"
            },
            "nombre":"GOBERNADOR JUAN E. MARTINEZ",
            "id":"18091020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Gobernador Juan E. Martínez",
               "id":"18091020"
            },
            "centroide":{
               "lat":-28.9108688742576,
               "lon":-58.9359577546112
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"180203"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"18091"
            },
            "nombre":"LAVALLE",
            "id":"18091030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Lavalle",
               "id":"18091030"
            },
            "centroide":{
               "lat":-29.0249812495642,
               "lon":-59.1818570100087
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Lucía",
               "id":"180210"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"18091"
            },
            "nombre":"SANTA LUCIA",
            "id":"18091040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Santa Lucía",
               "id":"18091040"
            },
            "centroide":{
               "lat":-28.9847966992846,
               "lon":-59.1017576429994
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Lucía",
               "id":"180210"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"18091"
            },
            "nombre":"VILLA CORDOBA",
            "id":"18091050000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Villa Córdoba",
               "id":"18091050"
            },
            "centroide":{
               "lat":-28.9940335487267,
               "lon":-59.0774380130144
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yataytí Calle",
               "id":"180217"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"18091"
            },
            "nombre":"YATAYTI CALLE",
            "id":"18091060000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Yatayti Calle",
               "id":"18091060"
            },
            "centroide":{
               "lat":-29.0295902166451,
               "lon":-58.9097321884449
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mburucuyá",
               "id":"180224"
            },
            "departamento":{
               "nombre":"Mburucuyá",
               "id":"18098"
            },
            "nombre":"MBURUCUYA",
            "id":"18098010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Mburucuyá",
               "id":"18098010"
            },
            "centroide":{
               "lat":-28.0460572855636,
               "lon":-58.224984006822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Felipe Yofré",
               "id":"180231"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"18105"
            },
            "nombre":"FELIPE YOFRE",
            "id":"18105010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Felipe Yofré",
               "id":"18105010"
            },
            "centroide":{
               "lat":-29.105919764542,
               "lon":-58.3424746747141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mariano I. Loza",
               "id":"180238"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"18105"
            },
            "nombre":"MARIANO I. LOZA",
            "id":"18105020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Mariano I. Loza",
               "id":"18105020"
            },
            "centroide":{
               "lat":-29.3763665588886,
               "lon":-58.1960231004583
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mercedes",
               "id":"180245"
            },
            "departamento":{
               "nombre":"Mercedes",
               "id":"18105"
            },
            "nombre":"MERCEDES",
            "id":"18105030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Mercedes",
               "id":"18105030"
            },
            "centroide":{
               "lat":-29.1833885579996,
               "lon":-58.0742364424806
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Libertad",
               "id":"180252"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"COLONIA LIBERTAD",
            "id":"18112010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Colonia Libertad",
               "id":"18112010"
            },
            "centroide":{
               "lat":-30.0439760349315,
               "lon":-57.8235193980386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Libertad",
               "id":"180252"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"ESTACION LIBERTAD",
            "id":"18112020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Estación Libertad",
               "id":"18112020"
            },
            "centroide":{
               "lat":-30.0125285200771,
               "lon":-57.8591295180443
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juan Pujol",
               "id":"180259"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"JUAN PUJOL",
            "id":"18112030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Juan Pujol",
               "id":"18112030"
            },
            "centroide":{
               "lat":-30.4178964519869,
               "lon":-57.8560694385919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mocoretá",
               "id":"180266"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"MOCORETA",
            "id":"18112040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Mocoretá",
               "id":"18112040"
            },
            "centroide":{
               "lat":-30.6176804049267,
               "lon":-57.9628254585589
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Caseros",
               "id":"180273"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"MONTE CASEROS",
            "id":"18112050000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Monte Caseros",
               "id":"18112050"
            },
            "centroide":{
               "lat":-30.2515527236356,
               "lon":-57.6388140007913
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Libertad",
               "id":"180252"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"PARADA ACUÑA",
            "id":"18112060000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Parada Acuña",
               "id":"18112060"
            },
            "centroide":{
               "lat":-29.9084817138799,
               "lon":-57.9581770148793
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Caseros",
               "id":"180273"
            },
            "departamento":{
               "nombre":"Monte Caseros",
               "id":"18112"
            },
            "nombre":"PARADA LABOUGLE",
            "id":"18112070000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Parada Labougle",
               "id":"18112070"
            },
            "centroide":{
               "lat":-30.3190400199696,
               "lon":-57.7289868202181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bonpland",
               "id":"180280"
            },
            "departamento":{
               "nombre":"Paso de los Libres",
               "id":"18119"
            },
            "nombre":"BONPLAND",
            "id":"18119010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Bonpland",
               "id":"18119010"
            },
            "centroide":{
               "lat":-29.8203735190576,
               "lon":-57.4296479379825
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Parada Pucheta",
               "id":"180287"
            },
            "departamento":{
               "nombre":"Paso de los Libres",
               "id":"18119"
            },
            "nombre":"PARADA PUCHETA",
            "id":"18119020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Parada Pucheta",
               "id":"18119020"
            },
            "centroide":{
               "lat":-29.9053454011034,
               "lon":-57.5743693513377
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso de los Libres",
               "id":"180294"
            },
            "departamento":{
               "nombre":"Paso de los Libres",
               "id":"18119"
            },
            "nombre":"PASO DE LOS LIBRES",
            "id":"18119030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Paso de los Libres",
               "id":"18119030"
            },
            "centroide":{
               "lat":-29.7116998596983,
               "lon":-57.0877441027999
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tapebicuá",
               "id":"180301"
            },
            "departamento":{
               "nombre":"Paso de los Libres",
               "id":"18119"
            },
            "nombre":"TAPEBICUA",
            "id":"18119040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Tapebicuá",
               "id":"18119040"
            },
            "centroide":{
               "lat":-29.5043114402955,
               "lon":-56.9760306946844
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladas",
               "id":"180308"
            },
            "departamento":{
               "nombre":"Saladas",
               "id":"18126"
            },
            "nombre":"SALADAS",
            "id":"18126010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Saladas",
               "id":"18126010"
            },
            "centroide":{
               "lat":-28.2553374256059,
               "lon":-58.6238010038908
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"180315"
            },
            "departamento":{
               "nombre":"Saladas",
               "id":"18126"
            },
            "nombre":"SAN LORENZO",
            "id":"18126020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Lorenzo",
               "id":"18126020"
            },
            "centroide":{
               "lat":-28.1314603295453,
               "lon":-58.7668321022746
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"180336"
            },
            "departamento":{
               "nombre":"San Cosme",
               "id":"18133"
            },
            "nombre":"INGENIO PRIMER CORRENTINO",
            "id":"18133010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Ingenio Primer Correntino",
               "id":"18133010"
            },
            "centroide":{
               "lat":-27.4336721947021,
               "lon":-58.624327887603
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso de la Patria",
               "id":"180322"
            },
            "departamento":{
               "nombre":"San Cosme",
               "id":"18133"
            },
            "nombre":"PASO DE LA PATRIA",
            "id":"18133020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Paso de la Patria",
               "id":"18133020"
            },
            "centroide":{
               "lat":-27.3150060127599,
               "lon":-58.5720142980631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Cosme",
               "id":"180329"
            },
            "departamento":{
               "nombre":"San Cosme",
               "id":"18133"
            },
            "nombre":"SAN COSME",
            "id":"18133030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Cosme",
               "id":"18133030"
            },
            "centroide":{
               "lat":-27.3711801624716,
               "lon":-58.5115291113063
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"180336"
            },
            "departamento":{
               "nombre":"San Cosme",
               "id":"18133"
            },
            "nombre":"SANTA ANA",
            "id":"18133040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Santa Ana",
               "id":"18133040"
            },
            "centroide":{
               "lat":-27.455091402543,
               "lon":-58.6530388459631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Luis del Palmar",
               "id":"180350"
            },
            "departamento":{
               "nombre":"San Luis del Palmar",
               "id":"18140"
            },
            "nombre":"SAN LUIS DEL PALMAR",
            "id":"18140010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Luis del Palmar",
               "id":"18140010"
            },
            "centroide":{
               "lat":-27.5081078409756,
               "lon":-58.5554744159758
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Carlos Pellegrini",
               "id":"180357"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"18147"
            },
            "nombre":"COLONIA CARLOS PELLEGRINI",
            "id":"18147010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Colonia Carlos Pellegrini",
               "id":"18147010"
            },
            "centroide":{
               "lat":-28.5373400329858,
               "lon":-57.1712310407172
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaviraví",
               "id":"180364"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"18147"
            },
            "nombre":"GUAVIRAVI",
            "id":"18147020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Guaviraví",
               "id":"18147020"
            },
            "centroide":{
               "lat":-29.3669865639555,
               "lon":-56.8292389472207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cruz",
               "id":"180371"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"18147"
            },
            "nombre":"LA CRUZ",
            "id":"18147030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"La Cruz",
               "id":"18147030"
            },
            "centroide":{
               "lat":-29.1736584106926,
               "lon":-56.6450161726372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yapeyú",
               "id":"180378"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"18147"
            },
            "nombre":"YAPEYU",
            "id":"18147040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Yapeyú",
               "id":"18147040"
            },
            "centroide":{
               "lat":-29.4706323042238,
               "lon":-56.8158358918793
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Loreto",
               "id":"180385"
            },
            "departamento":{
               "nombre":"San Miguel",
               "id":"18154"
            },
            "nombre":"LORETO",
            "id":"18154010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Loreto",
               "id":"18154010"
            },
            "centroide":{
               "lat":-27.7684514067067,
               "lon":-57.274839083927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Miguel",
               "id":"180392"
            },
            "departamento":{
               "nombre":"San Miguel",
               "id":"18154"
            },
            "nombre":"SAN MIGUEL",
            "id":"18154020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"18154020"
            },
            "centroide":{
               "lat":-27.9947735055436,
               "lon":-57.5919539782996
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chavarría",
               "id":"180413"
            },
            "departamento":{
               "nombre":"San Roque",
               "id":"18161"
            },
            "nombre":"CHAVARRIA",
            "id":"18161010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Chavarría",
               "id":"18161010"
            },
            "centroide":{
               "lat":-28.9549218176889,
               "lon":-58.5716489831846
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Pando",
               "id":"180417"
            },
            "departamento":{
               "nombre":"San Roque",
               "id":"18161"
            },
            "nombre":"COLONIA PANDO",
            "id":"18161020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Colonia Pando",
               "id":"18161020"
            },
            "centroide":{
               "lat":-28.5252148833089,
               "lon":-58.4869345494056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"180399"
            },
            "departamento":{
               "nombre":"San Roque",
               "id":"18161"
            },
            "nombre":"9 DE JULIO",
            "id":"18161030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"9 de Julio",
               "id":"18161030"
            },
            "centroide":{
               "lat":-28.8418348631681,
               "lon":-58.8280463835778
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pedro R. Fernández",
               "id":"180406"
            },
            "departamento":{
               "nombre":"San Roque",
               "id":"18161"
            },
            "nombre":"PEDRO R. FERNANDEZ",
            "id":"18161040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Pedro R. Fernández",
               "id":"18161040"
            },
            "centroide":{
               "lat":-28.7505433489253,
               "lon":-58.6545368651224
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Roque",
               "id":"180420"
            },
            "departamento":{
               "nombre":"San Roque",
               "id":"18161"
            },
            "nombre":"SAN ROQUE",
            "id":"18161050000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"San Roque",
               "id":"18161050"
            },
            "centroide":{
               "lat":-28.5732308799035,
               "lon":-58.7100567505645
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jose Rafael Gomez",
               "id":"180441"
            },
            "departamento":{
               "nombre":"Santo Tomé",
               "id":"18168"
            },
            "nombre":"JOSE R. GOMEZ",
            "id":"18168010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"José Rafael Gómez",
               "id":"18168010"
            },
            "centroide":{
               "lat":-28.2260143622167,
               "lon":-55.7844092003668
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garruchos",
               "id":"180427"
            },
            "departamento":{
               "nombre":"Santo Tomé",
               "id":"18168"
            },
            "nombre":"GARRUCHOS",
            "id":"18168020000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Garruchos",
               "id":"18168020"
            },
            "centroide":{
               "lat":-28.1729435857266,
               "lon":-55.6513324031087
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Ing. Valentín Virasoro",
               "id":"180434"
            },
            "departamento":{
               "nombre":"Santo Tomé",
               "id":"18168"
            },
            "nombre":"GOBERNADOR IGR.VALENTIN VIRASORO",
            "id":"18168030000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Gobernador Igr. Valentín Virasoro",
               "id":"18168030"
            },
            "centroide":{
               "lat":-28.0455566790186,
               "lon":-56.0190197167242
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santo Tomé",
               "id":"180448"
            },
            "departamento":{
               "nombre":"Santo Tomé",
               "id":"18168"
            },
            "nombre":"SANTO TOME",
            "id":"18168040000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Santo Tomé",
               "id":"18168040"
            },
            "centroide":{
               "lat":-28.5511588178368,
               "lon":-56.0420862814163
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sauce",
               "id":"180455"
            },
            "departamento":{
               "nombre":"Sauce",
               "id":"18175"
            },
            "nombre":"SAUCE",
            "id":"18175010000",
            "provincia":{
               "nombre":"Corrientes",
               "id":"18"
            },
            "localidad_censal":{
               "nombre":"Sauce",
               "id":"18175010"
            },
            "centroide":{
               "lat":-30.0867528651818,
               "lon":-58.7879617250662
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Concepción del Bermejo",
               "id":"220007"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"22007"
            },
            "nombre":"CONCEPCION DEL BERMEJO",
            "id":"22007010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Concepción del Bermejo",
               "id":"22007010"
            },
            "centroide":{
               "lat":-26.602273964006,
               "lon":-60.9492636999567
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Frentones",
               "id":"220014"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"22007"
            },
            "nombre":"LOS FRENTONES",
            "id":"22007020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Los Frentones",
               "id":"22007020"
            },
            "centroide":{
               "lat":-26.4082976307567,
               "lon":-61.4134271371597
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampa del Infierno",
               "id":"220021"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"22007"
            },
            "nombre":"PAMPA DEL INFIERNO",
            "id":"22007030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Pampa del Infierno",
               "id":"22007030"
            },
            "centroide":{
               "lat":-26.5063974406178,
               "lon":-61.1764901971888
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Frentones",
               "id":"220014"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"22007"
            },
            "nombre":"RIO MUERTO",
            "id":"22007040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Río Muerto",
               "id":"22007040"
            },
            "centroide":{
               "lat":-26.3078818113872,
               "lon":-61.6540440649869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Taco Pozo",
               "id":"220028"
            },
            "departamento":{
               "nombre":"Almirante Brown",
               "id":"22007"
            },
            "nombre":"TACO POZO",
            "id":"22007050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Taco Pozo",
               "id":"22007050"
            },
            "centroide":{
               "lat":-25.6156598937138,
               "lon":-63.2692964997065
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Vedia",
               "id":"220035"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"GENERAL VEDIA",
            "id":"22014010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"General Vedia",
               "id":"22014010"
            },
            "centroide":{
               "lat":-26.9349378591637,
               "lon":-58.6612848328678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Isla del Cerrito",
               "id":"220042"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"ISLA DEL CERRITO",
            "id":"22014020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Isla del Cerrito",
               "id":"22014020"
            },
            "centroide":{
               "lat":-27.2927852739485,
               "lon":-58.6178377359072
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Leonesa",
               "id":"220049"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"LA LEONESA",
            "id":"22014030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Leonesa",
               "id":"22014030"
            },
            "centroide":{
               "lat":-27.0379596283785,
               "lon":-58.7069377183708
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Palmas",
               "id":"220056"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"LAS PALMAS",
            "id":"22014040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Las Palmas",
               "id":"22014040"
            },
            "centroide":{
               "lat":-27.0478975207771,
               "lon":-58.6795739267563
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Bermejo",
               "id":"220063"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"PUERTO BERMEJO NUEVO",
            "id":"22014050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Puerto Bermejo Nuevo",
               "id":"22014050"
            },
            "centroide":{
               "lat":-26.9073753724695,
               "lon":-58.5428316633796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Bermejo",
               "id":"220063"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"PUERTO BERMEJO VIEJO",
            "id":"22014060000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Puerto Bermejo Viejo",
               "id":"22014060"
            },
            "centroide":{
               "lat":-26.9285481385672,
               "lon":-58.5063036590574
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Eva Perón",
               "id":"220070"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"22014"
            },
            "nombre":"PUERTO EVA PERON",
            "id":"22014070000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Puerto Eva Perón",
               "id":"22014070"
            },
            "centroide":{
               "lat":-26.661480092829,
               "lon":-58.6355818176747
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidencia Roque Sáenz Peña",
               "id":"220077"
            },
            "departamento":{
               "nombre":"Comandante Fernández",
               "id":"22021"
            },
            "nombre":"PRESIDENCIA ROQUE SAENZ PENA",
            "id":"22021010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Presidencia Roque Sáenz Peña",
               "id":"22021010"
            },
            "centroide":{
               "lat":-26.7916058929378,
               "lon":-60.4421462814095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charata",
               "id":"220084"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"22028"
            },
            "nombre":"CHARATA",
            "id":"22028010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Charata",
               "id":"22028010"
            },
            "centroide":{
               "lat":-27.2200458502784,
               "lon":-61.1915854495204
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gancedo",
               "id":"220091"
            },
            "departamento":{
               "nombre":"12 de Octubre",
               "id":"22036"
            },
            "nombre":"GANCEDO",
            "id":"22036010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Gancedo",
               "id":"22036010"
            },
            "centroide":{
               "lat":-27.4896487904756,
               "lon":-61.6738771708385
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Capdevila",
               "id":"220098"
            },
            "departamento":{
               "nombre":"12 de Octubre",
               "id":"22036"
            },
            "nombre":"GENERAL CAPDEVILA",
            "id":"22036020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"General Capdevila",
               "id":"22036020"
            },
            "centroide":{
               "lat":-27.4231812966856,
               "lon":-61.4765845551068
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinedo",
               "id":"220105"
            },
            "departamento":{
               "nombre":"12 de Octubre",
               "id":"22036"
            },
            "nombre":"GENERAL PINEDO",
            "id":"22036030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"General Pinedo",
               "id":"22036030"
            },
            "centroide":{
               "lat":-27.3252979421708,
               "lon":-61.282210419911
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinedo",
               "id":"220105"
            },
            "departamento":{
               "nombre":"12 de Octubre",
               "id":"22036"
            },
            "nombre":"MESON DE FIERRO",
            "id":"22036040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Mesón de Fierro",
               "id":"22036040"
            },
            "centroide":{
               "lat":-27.4312285767758,
               "lon":-61.0177584158265
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pinedo",
               "id":"220105"
            },
            "departamento":{
               "nombre":"12 de Octubre",
               "id":"22036"
            },
            "nombre":"PAMPA LANDRIEL",
            "id":"22036050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Pampa Landriel",
               "id":"22036050"
            },
            "centroide":{
               "lat":-27.3957449255406,
               "lon":-61.1029787390413
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hermoso Campo",
               "id":"220112"
            },
            "departamento":{
               "nombre":"2 de Abril",
               "id":"22039"
            },
            "nombre":"HERMOSO CAMPO",
            "id":"22039010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Hermoso Campo",
               "id":"22039010"
            },
            "centroide":{
               "lat":-27.610539212014,
               "lon":-61.344844244037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hermoso Campo",
               "id":"220112"
            },
            "departamento":{
               "nombre":"2 de Abril",
               "id":"22039"
            },
            "nombre":"ITIN",
            "id":"22039020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Itín",
               "id":"22039020"
            },
            "centroide":{
               "lat":-27.4876216159572,
               "lon":-61.3238818419592
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chorotis",
               "id":"220119"
            },
            "departamento":{
               "nombre":"Fray Justo Santa María de Oro",
               "id":"22043"
            },
            "nombre":"CHOROTIS",
            "id":"22043010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Chorotis",
               "id":"22043010"
            },
            "centroide":{
               "lat":-27.9175852858656,
               "lon":-61.3995694680726
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Sylvina",
               "id":"220126"
            },
            "departamento":{
               "nombre":"Fray Justo Santa María de Oro",
               "id":"22043"
            },
            "nombre":"SANTA SYLVINA",
            "id":"22043020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Santa Sylvina",
               "id":"22043020"
            },
            "centroide":{
               "lat":-27.8356008575455,
               "lon":-61.1361742191871
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chorotis",
               "id":"220119"
            },
            "departamento":{
               "nombre":"Fray Justo Santa María de Oro",
               "id":"22043"
            },
            "nombre":"VENADOS GRANDES",
            "id":"22043030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Venados Grandes",
               "id":"22043030"
            },
            "centroide":{
               "lat":-27.8181539157701,
               "lon":-61.3815664200405
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corzuela",
               "id":"220113"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"22049"
            },
            "nombre":"CORZUELA",
            "id":"22049010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Corzuela",
               "id":"22049010"
            },
            "centroide":{
               "lat":-26.9556760087163,
               "lon":-60.9707574059512
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Escondida",
               "id":"220140"
            },
            "departamento":{
               "nombre":"General Donovan",
               "id":"22056"
            },
            "nombre":"LA ESCONDIDA",
            "id":"22056010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Escondida",
               "id":"22056010"
            },
            "centroide":{
               "lat":-27.1073248470025,
               "lon":-59.4475325100431
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Verde",
               "id":"220154"
            },
            "departamento":{
               "nombre":"General Donovan",
               "id":"22056"
            },
            "nombre":"LA VERDE",
            "id":"22056020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Verde",
               "id":"22056020"
            },
            "centroide":{
               "lat":-27.1297934337807,
               "lon":-59.3774619894492
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lapachito",
               "id":"220147"
            },
            "departamento":{
               "nombre":"General Donovan",
               "id":"22056"
            },
            "nombre":"LAPACHITO",
            "id":"22056030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Lapachito",
               "id":"22056030"
            },
            "centroide":{
               "lat":-27.1587903465778,
               "lon":-59.3903975007013
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Makallé",
               "id":"220161"
            },
            "departamento":{
               "nombre":"General Donovan",
               "id":"22056"
            },
            "nombre":"MAKALLE",
            "id":"22056040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Makallé",
               "id":"22056040"
            },
            "centroide":{
               "lat":-27.211618544441,
               "lon":-59.2883662132688
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Río Bermejito",
               "id":"220203"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"EL ESPINILLO",
            "id":"22063010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"El Espinillo",
               "id":"22063010"
            },
            "centroide":{
               "lat":-25.4176698636006,
               "lon":-60.4139677023547
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Sauzalito",
               "id":"220168"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"EL SAUZAL",
            "id":"22063020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"El Sauzal",
               "id":"22063020"
            },
            "centroide":{
               "lat":-24.579149375301,
               "lon":-61.5461702165927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Sauzalito",
               "id":"220168"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"EL SAUZALITO",
            "id":"22063030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"El Sauzalito",
               "id":"22063030"
            },
            "centroide":{
               "lat":-24.4345499393074,
               "lon":-61.6813716975806
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Río Bermejito",
               "id":"220203"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"FORTIN LAVALLE",
            "id":"22063040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Fortín Lavalle",
               "id":"22063040"
            },
            "centroide":{
               "lat":-25.7056308542578,
               "lon":-60.2037478681132
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fuerte Esperanza",
               "id":"220175"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"FUERTE ESPERANZA",
            "id":"22063050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Fuerte Esperanza",
               "id":"22063050"
            },
            "centroide":{
               "lat":-25.1560600566745,
               "lon":-61.8424104268078
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juan José Castelli",
               "id":"220182"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"JUAN JOSE CASTELLI",
            "id":"22063060000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Juan José Castelli",
               "id":"22063060"
            },
            "centroide":{
               "lat":-25.9504150377594,
               "lon":-60.6243211030425
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Misión Nueva Pompeya",
               "id":"220169"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"NUEVA POMPEYA",
            "id":"22063080000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Nueva Pompeya",
               "id":"22063080"
            },
            "centroide":{
               "lat":-24.9334418837191,
               "lon":-61.4846998398146
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Río Bermejito",
               "id":"220203"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"VILLA RIO BERMEJITO",
            "id":"22063100000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Villa Río Bermejito",
               "id":"22063100"
            },
            "centroide":{
               "lat":-25.6424413894079,
               "lon":-60.2629438807651
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Sauzalito",
               "id":"220168"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"WICHI",
            "id":"22063110000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Wichi",
               "id":"22063110"
            },
            "centroide":{
               "lat":-24.6913977720534,
               "lon":-61.4304454802334
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juan José Castelli",
               "id":"220182"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"ZAPARINQUI",
            "id":"22063120000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Zaparinqui",
               "id":"22063120"
            },
            "centroide":{
               "lat":-26.0673623556601,
               "lon":-60.5633873019222
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avia Terai",
               "id":"220210"
            },
            "departamento":{
               "nombre":"Independencia",
               "id":"22070"
            },
            "nombre":"AVIA TERAI",
            "id":"22070010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Avia Terai",
               "id":"22070010"
            },
            "centroide":{
               "lat":-26.6904269113097,
               "lon":-60.7309487066584
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Largo",
               "id":"220217"
            },
            "departamento":{
               "nombre":"Independencia",
               "id":"22070"
            },
            "nombre":"CAMPO LARGO",
            "id":"22070020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Campo Largo",
               "id":"22070020"
            },
            "centroide":{
               "lat":-26.8038644067827,
               "lon":-60.8423032131182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Largo",
               "id":"220217"
            },
            "departamento":{
               "nombre":"Independencia",
               "id":"22070"
            },
            "nombre":"FORTIN LAS CHUÑAS",
            "id":"22070030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Fortín Las Chuñas",
               "id":"22070030"
            },
            "centroide":{
               "lat":-26.8891042551114,
               "lon":-60.908152214423
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Napenay",
               "id":"220224"
            },
            "departamento":{
               "nombre":"Independencia",
               "id":"22070"
            },
            "nombre":"NAPENAY",
            "id":"22070040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Napenay",
               "id":"22070040"
            },
            "centroide":{
               "lat":-26.7314687114399,
               "lon":-60.6190285151237
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Popular",
               "id":"220231"
            },
            "departamento":{
               "nombre":"Libertad",
               "id":"22077"
            },
            "nombre":"COLONIA POPULAR",
            "id":"22077010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Colonia Popular",
               "id":"22077010"
            },
            "centroide":{
               "lat":-27.2759309408353,
               "lon":-59.1523781564389
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Tirol",
               "id":"220254"
            },
            "departamento":{
               "nombre":"Libertad",
               "id":"22077"
            },
            "nombre":"ESTACION GENERAL OBLIGADO",
            "id":"22077020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Estación General Obligado",
               "id":"22077020"
            },
            "centroide":{
               "lat":-27.4128679939929,
               "lon":-59.4201579706051
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Blanca",
               "id":"220238"
            },
            "departamento":{
               "nombre":"Libertad",
               "id":"22077"
            },
            "nombre":"LAGUNA BLANCA",
            "id":"22077030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Laguna Blanca",
               "id":"22077030"
            },
            "centroide":{
               "lat":-27.2572195505018,
               "lon":-59.2340486127892
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Tirol",
               "id":"220254"
            },
            "departamento":{
               "nombre":"Libertad",
               "id":"22077"
            },
            "nombre":"PUERTO TIROL",
            "id":"22077040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Puerto Tirol",
               "id":"22077040"
            },
            "centroide":{
               "lat":-27.3745125614532,
               "lon":-59.0953159917862
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ciervo Petiso",
               "id":"220252"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"CIERVO PETISO",
            "id":"22084010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Ciervo Petiso",
               "id":"22084010"
            },
            "centroide":{
               "lat":-26.5815849580294,
               "lon":-59.6329803967089
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. San Martengeneral San Martín",
               "id":"220259"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"GENERAL JOSE DE SAN MARTIN",
            "id":"22084020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"General José de San Martín",
               "id":"22084020"
            },
            "centroide":{
               "lat":-26.5340730622708,
               "lon":-59.334711358946
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Eduvigis",
               "id":"220266"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"LA EDUVIGIS",
            "id":"22084030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Eduvigis",
               "id":"22084030"
            },
            "centroide":{
               "lat":-26.8374120457599,
               "lon":-59.0641175796047
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Limpia",
               "id":"220273"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"LAGUNA LIMPIA",
            "id":"22084040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Laguna Limpia",
               "id":"22084040"
            },
            "centroide":{
               "lat":-26.4967013914467,
               "lon":-59.6799372880349
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampa Almirón",
               "id":"220280"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"PAMPA ALMIRON",
            "id":"22084050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Pampa Almirón",
               "id":"22084050"
            },
            "centroide":{
               "lat":-26.702247857191,
               "lon":-59.1237850343206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampa del Indio",
               "id":"220287"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"PAMPA DEL INDIO",
            "id":"22084060000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Pampa del Indio",
               "id":"22084060"
            },
            "centroide":{
               "lat":-26.050714758193,
               "lon":-59.9412241077467
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidencia Roca",
               "id":"220294"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"PRESIDENCIA ROCA",
            "id":"22084070000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Presidencia Roca",
               "id":"22084070"
            },
            "centroide":{
               "lat":-26.1402007626997,
               "lon":-59.5968452905131
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Eduvigis",
               "id":"220266"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"22084"
            },
            "nombre":"SELVAS DEL RIO DE ORO",
            "id":"22084080000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Selvas del Río de Oro",
               "id":"22084080"
            },
            "centroide":{
               "lat":-26.8044755779716,
               "lon":-58.9585454452623
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Isletas",
               "id":"220301"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"22091"
            },
            "nombre":"TRES ISLETAS",
            "id":"22091010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Tres Isletas",
               "id":"22091010"
            },
            "centroide":{
               "lat":-26.3378349285309,
               "lon":-60.4299349548752
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Du Graty",
               "id":"220308"
            },
            "departamento":{
               "nombre":"Mayor Luis J. Fontana",
               "id":"22098"
            },
            "nombre":"CORONEL DU GRATY",
            "id":"22098010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Coronel Du Graty",
               "id":"22098010"
            },
            "centroide":{
               "lat":-27.682571155615,
               "lon":-60.9091956314644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Enrique Urien",
               "id":"220315"
            },
            "departamento":{
               "nombre":"Mayor Luis J. Fontana",
               "id":"22098"
            },
            "nombre":"ENRIQUE URIEN",
            "id":"22098020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Enrique Urien",
               "id":"22098020"
            },
            "centroide":{
               "lat":-27.5587251493486,
               "lon":-60.5259907543736
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ángela",
               "id":"220322"
            },
            "departamento":{
               "nombre":"Mayor Luis J. Fontana",
               "id":"22098"
            },
            "nombre":"VILLA ANGELA",
            "id":"22098030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Villa Angela",
               "id":"22098030"
            },
            "centroide":{
               "lat":-27.5788592432947,
               "lon":-60.7141120066309
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Breñas",
               "id":"220329"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"22105"
            },
            "nombre":"LAS BREÑAS",
            "id":"22105010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Las Breñas",
               "id":"22105010"
            },
            "centroide":{
               "lat":-27.0885167236059,
               "lon":-61.0836993088858
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Clotilde",
               "id":"220336"
            },
            "departamento":{
               "nombre":"O'Higgins",
               "id":"22112"
            },
            "nombre":"LA CLOTILDE",
            "id":"22112010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Clotilde",
               "id":"22112010"
            },
            "centroide":{
               "lat":-27.1781993384686,
               "lon":-60.6315741848516
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Tigra",
               "id":"220343"
            },
            "departamento":{
               "nombre":"O'Higgins",
               "id":"22112"
            },
            "nombre":"LA TIGRA",
            "id":"22112020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Tigra",
               "id":"22112020"
            },
            "centroide":{
               "lat":-27.1157899755692,
               "lon":-60.5898941741226
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Bernardo",
               "id":"220350"
            },
            "departamento":{
               "nombre":"O'Higgins",
               "id":"22112"
            },
            "nombre":"SAN BERNARDO",
            "id":"22112030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"San Bernardo",
               "id":"22112030"
            },
            "centroide":{
               "lat":-27.2904384132605,
               "lon":-60.7149736332962
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidencia de la Plaza",
               "id":"220357"
            },
            "departamento":{
               "nombre":"Presidencia de la Plaza",
               "id":"22119"
            },
            "nombre":"PRESIDENCIA DE LA PLAZA",
            "id":"22119010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Presidencia de la Plaza",
               "id":"22119010"
            },
            "centroide":{
               "lat":-27.0029714359347,
               "lon":-59.847600385659
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Benitez",
               "id":"220364"
            },
            "departamento":{
               "nombre":"1° de Mayo",
               "id":"22126"
            },
            "nombre":"BARRIO DE LOS PESCADORES",
            "id":"22126010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Barrio de los Pescadores",
               "id":"22126010"
            },
            "centroide":{
               "lat":-27.4480426212168,
               "lon":-58.8551013275047
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Benitez",
               "id":"220364"
            },
            "departamento":{
               "nombre":"1° de Mayo",
               "id":"22126"
            },
            "nombre":"COLONIA BENITEZ",
            "id":"22126020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Colonia Benítez",
               "id":"22126020"
            },
            "centroide":{
               "lat":-27.3305884379906,
               "lon":-58.9450102746869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Margarita Belén",
               "id":"220371"
            },
            "departamento":{
               "nombre":"1° de Mayo",
               "id":"22126"
            },
            "nombre":"MARGARITA BELEN",
            "id":"22126030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Margarita Belén",
               "id":"22126030"
            },
            "centroide":{
               "lat":-27.2616473234151,
               "lon":-58.9741473116095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quitilipi",
               "id":"220378"
            },
            "departamento":{
               "nombre":"Quitilipi",
               "id":"22133"
            },
            "nombre":"QUITILIPI",
            "id":"22133010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Quitilipi",
               "id":"22133010"
            },
            "centroide":{
               "lat":-26.8732071732978,
               "lon":-60.2185241226399
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quitilipi",
               "id":"220378"
            },
            "departamento":{
               "nombre":"Quitilipi",
               "id":"22133"
            },
            "nombre":"VILLA EL PALMAR",
            "id":"22133020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Villa El Palmar",
               "id":"22133020"
            },
            "centroide":{
               "lat":-26.4551186226266,
               "lon":-60.1646165913523
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Barranqueras",
               "id":"220385"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"22140"
            },
            "nombre":"BARRANQUERAS",
            "id":"22140010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Barranqueras",
               "id":"22140010"
            },
            "centroide":{
               "lat":-27.4877739289761,
               "lon":-58.9327416886365
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Basail",
               "id":"220392"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"22140"
            },
            "nombre":"BASAIL",
            "id":"22140020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Basail",
               "id":"22140020"
            },
            "centroide":{
               "lat":-27.8868655966917,
               "lon":-59.2791003619491
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Resistencia",
               "id":"220413"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"22140"
            },
            "nombre":"COLONIA BARANDA",
            "id":"22140030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Colonia Baranda",
               "id":"22140030"
            },
            "centroide":{
               "lat":-27.5620449432874,
               "lon":-59.3096911774268
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fontana",
               "id":"220399"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"22140"
            },
            "nombre":"FONTANA",
            "id":"22140040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Fontana",
               "id":"22140040"
            },
            "centroide":{
               "lat":-27.4167127425858,
               "lon":-59.04393778912
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Vilelas",
               "id":"220406"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"22140"
            },
            "nombre":"PUERTO VILELAS",
            "id":"22140050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Puerto Vilelas",
               "id":"22140050"
            },
            "centroide":{
               "lat":-27.5106090846354,
               "lon":-58.938994465923
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Resistencia",
               "id":"220413"
            },
            "departamento":{
               "nombre":"San Fernando",
               "id":"22140"
            },
            "nombre":"RESISTENCIA",
            "id":"22140060000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Resistencia",
               "id":"22140060"
            },
            "centroide":{
               "lat":-27.4521194584549,
               "lon":-58.9876174408016
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Samuhú",
               "id":"220420"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"22147"
            },
            "nombre":"SAMUHU",
            "id":"22147010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Samuhú",
               "id":"22147010"
            },
            "centroide":{
               "lat":-27.5222254234698,
               "lon":-60.3941746057505
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Berthet",
               "id":"220427"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"22147"
            },
            "nombre":"VILLA BERTHET",
            "id":"22147020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Villa Berthet",
               "id":"22147020"
            },
            "centroide":{
               "lat":-27.2895492409257,
               "lon":-60.4155962080604
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capitán Solari",
               "id":"220434"
            },
            "departamento":{
               "nombre":"Sargento Cabral",
               "id":"22154"
            },
            "nombre":"CAPITAN SOLARI",
            "id":"22154010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Capitán Solari",
               "id":"22154010"
            },
            "centroide":{
               "lat":-26.8050732181614,
               "lon":-59.5596523744138
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonias Elisa",
               "id":"220441"
            },
            "departamento":{
               "nombre":"Sargento Cabral",
               "id":"22154"
            },
            "nombre":"COLONIA ELISA",
            "id":"22154020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Colonia Elisa",
               "id":"22154020"
            },
            "centroide":{
               "lat":-26.9324550960675,
               "lon":-59.5204851339372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonias Unidas",
               "id":"220448"
            },
            "departamento":{
               "nombre":"Sargento Cabral",
               "id":"22154"
            },
            "nombre":"COLONIAS UNIDAS",
            "id":"22154030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Colonias Unidas",
               "id":"22154030"
            },
            "centroide":{
               "lat":-26.7000971775462,
               "lon":-59.6277421824927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonias Elisa",
               "id":"220441"
            },
            "departamento":{
               "nombre":"Sargento Cabral",
               "id":"22154"
            },
            "nombre":"INGENIERO BARBET",
            "id":"22154040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Barbet",
               "id":"22154040"
            },
            "centroide":{
               "lat":-27.0032121063146,
               "lon":-59.4825136520473
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Garcitas",
               "id":"220455"
            },
            "departamento":{
               "nombre":"Sargento Cabral",
               "id":"22154"
            },
            "nombre":"LAS GARCITAS",
            "id":"22154050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Las Garcitas",
               "id":"22154050"
            },
            "centroide":{
               "lat":-26.6193460346699,
               "lon":-59.8042763819042
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charadai",
               "id":"220469"
            },
            "departamento":{
               "nombre":"Tapenagá",
               "id":"22161"
            },
            "nombre":"CHARADAI",
            "id":"22161010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Charadai",
               "id":"22161010"
            },
            "centroide":{
               "lat":-27.6553304709616,
               "lon":-59.8624460245382
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cote Lai",
               "id":"220462"
            },
            "departamento":{
               "nombre":"Tapenagá",
               "id":"22161"
            },
            "nombre":"COTE LAI",
            "id":"22161020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Cote Lai",
               "id":"22161020"
            },
            "centroide":{
               "lat":-27.5303116601138,
               "lon":-59.5765171513905
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charadai",
               "id":"220469"
            },
            "departamento":{
               "nombre":"Tapenagá",
               "id":"22161"
            },
            "nombre":"HAUMONIA",
            "id":"22161030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Haumonia",
               "id":"22161030"
            },
            "centroide":{
               "lat":-27.5077849029352,
               "lon":-60.1635277537413
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charadai",
               "id":"220469"
            },
            "departamento":{
               "nombre":"Tapenagá",
               "id":"22161"
            },
            "nombre":"HORQUILLA",
            "id":"22161040000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Horquilla",
               "id":"22161040"
            },
            "centroide":{
               "lat":-27.5427589336639,
               "lon":-59.9578541039413
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Charadai",
               "id":"220469"
            },
            "departamento":{
               "nombre":"Tapenagá",
               "id":"22161"
            },
            "nombre":"LA SABANA",
            "id":"22161050000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"La Sabana",
               "id":"22161050"
            },
            "centroide":{
               "lat":-27.8738009106984,
               "lon":-59.939569589112
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Machagai",
               "id":"220476"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"22168"
            },
            "nombre":"COLONIA ABORIGEN",
            "id":"22168010000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Colonia Aborigen",
               "id":"22168010"
            },
            "centroide":{
               "lat":-26.9583436350996,
               "lon":-60.1902370459592
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Machagai",
               "id":"220476"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"22168"
            },
            "nombre":"MACHAGAI",
            "id":"22168020000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Machagai",
               "id":"22168020"
            },
            "centroide":{
               "lat":-26.9287335504143,
               "lon":-60.0477196130638
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Machagai",
               "id":"220476"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"22168"
            },
            "nombre":"NAPALPI",
            "id":"22168030000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Napalpí",
               "id":"22168030"
            },
            "centroide":{
               "lat":-26.9032617040064,
               "lon":-60.1173839257014
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Biedma",
               "id":"26007"
            },
            "nombre":"ARROYO VERDE",
            "id":"26007010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Arroyo Verde",
               "id":"26007010"
            },
            "centroide":{
               "lat":-42.0119886969656,
               "lon":-65.3064500926092
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Madryn",
               "id":"260007"
            },
            "departamento":{
               "nombre":"Biedma",
               "id":"26007"
            },
            "nombre":"PUERTO MADRYN",
            "id":"26007020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Puerto Madryn",
               "id":"26007020"
            },
            "centroide":{
               "lat":-42.7550996783449,
               "lon":-65.0422298178163
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Biedma",
               "id":"26007"
            },
            "nombre":"PUERTO PIRAMIDE",
            "id":"26007030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Puerto Pirámides",
               "id":"26007030"
            },
            "centroide":{
               "lat":-42.5736171931039,
               "lon":-64.2836893467339
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Madryn",
               "id":"260007"
            },
            "departamento":{
               "nombre":"Biedma",
               "id":"26007"
            },
            "nombre":"QUINTA EL MIRADOR",
            "id":"26007040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Quintas El Mirador",
               "id":"26007040"
            },
            "centroide":{
               "lat":-42.811590013144,
               "lon":-65.051417803805
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Madryn",
               "id":"260007"
            },
            "departamento":{
               "nombre":"Biedma",
               "id":"26007"
            },
            "nombre":"RESERVA AREA PROTEGIDA EL DORADILLO",
            "id":"26007050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Reserva Area Protegida El Doradillo",
               "id":"26007050"
            },
            "centroide":{
               "lat":-42.6456158957863,
               "lon":-65.0647229672572
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Maitén",
               "id":"260028"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"BUENOS AIRES CHICO",
            "id":"26014010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Buenos Aires Chico",
               "id":"26014010"
            },
            "centroide":{
               "lat":-42.0687961260085,
               "lon":-71.2158343137726
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cholila",
               "id":"260014"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"CHOLILA",
            "id":"26014020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Cholila",
               "id":"26014020"
            },
            "centroide":{
               "lat":-42.5105313648355,
               "lon":-71.4351548288659
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"COSTA DE CHUBUT",
            "id":"26014025000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Costa del Chubut",
               "id":"26014025"
            },
            "centroide":{
               "lat":-42.6016255836041,
               "lon":-70.4575737273538
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cushamen",
               "id":"268014"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"CUSHAMEN CENTRO",
            "id":"26014030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Cushamen Centro",
               "id":"26014030"
            },
            "centroide":{
               "lat":-42.1766611028137,
               "lon":-70.6626188429181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Hoyo",
               "id":"260021"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"EL HOYO",
            "id":"26014040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"El Hoyo",
               "id":"26014040"
            },
            "centroide":{
               "lat":-42.0679666637038,
               "lon":-71.5206708370904
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Maitén",
               "id":"260028"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"EL MAITEN",
            "id":"26014050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"El Maitén",
               "id":"26014050"
            },
            "centroide":{
               "lat":-42.0542854358373,
               "lon":-71.1673153308386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Epuyén",
               "id":"260035"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"EPUYEN",
            "id":"26014060000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Epuyén",
               "id":"26014060"
            },
            "centroide":{
               "lat":-42.2329247123472,
               "lon":-71.3695066579513
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"FOFO CAHUEL",
            "id":"26014065000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Fofo Cahuel",
               "id":"26014065"
            },
            "centroide":{
               "lat":-42.3910620005992,
               "lon":-70.5780784399414
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gualjaina",
               "id":"265007"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"GUALJAINA",
            "id":"26014070000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Gualjaina",
               "id":"26014070"
            },
            "centroide":{
               "lat":-42.7267578946733,
               "lon":-70.5354876126711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Epuyén",
               "id":"260035"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"LAGO EPUYEN",
            "id":"26014080000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Lago Epuyén",
               "id":"26014080"
            },
            "centroide":{
               "lat":-42.2135356830147,
               "lon":-71.4296625433553
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lago Puelo",
               "id":"260042"
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"LAGO PUELO",
            "id":"26014090000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Lago Puelo",
               "id":"26014090"
            },
            "centroide":{
               "lat":-42.0672923130861,
               "lon":-71.5981575625236
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Cushamen",
               "id":"26014"
            },
            "nombre":"LELEQUE",
            "id":"26014100000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Leleque",
               "id":"26014100"
            },
            "centroide":{
               "lat":-42.4288626216315,
               "lon":-71.0683951630343
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"ASTRA",
            "id":"26021010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Astra",
               "id":"26021010"
            },
            "centroide":{
               "lat":-45.7366075458208,
               "lon":-67.485214728265
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BAHIA BUSTAMANTE",
            "id":"26021020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Bahía Bustamante",
               "id":"26021020"
            },
            "centroide":{
               "lat":-45.1143552826119,
               "lon":-66.5349166167103
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"ACCESO NORTE",
            "id":"26021030001",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.782807331545,
               "lon":-67.5033367870766
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO CALETA CORDOVA",
            "id":"26021030002",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7482508559011,
               "lon":-67.3786501769463
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO CALETA OLIVARES",
            "id":"26021030003",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7757274867383,
               "lon":-67.3895609391029
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO CASTELLI",
            "id":"26021030004",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8180472017947,
               "lon":-67.4820283811333
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO CIUDADELA",
            "id":"26021030005",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7969335159784,
               "lon":-67.5038537987439
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO GASODUCTO",
            "id":"26021030006",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7935756857887,
               "lon":-67.5039634957522
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO GÜEMES",
            "id":"26021030007",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8217123319455,
               "lon":-67.4921204994732
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO LAPRIDA",
            "id":"26021030008",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8286806171316,
               "lon":-67.5429175806012
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO MANANTIAL ROSALES",
            "id":"26021030009",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8171962363307,
               "lon":-67.5384037096511
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO MILITAR - AEROPUERTO MILITAR",
            "id":"26021030010",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7880202044185,
               "lon":-67.4666859492816
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO PROSPERO PALAZZO",
            "id":"26021030011",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7954205855384,
               "lon":-67.4728320436648
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO RESTINGA ALI",
            "id":"26021030012",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7933095274399,
               "lon":-67.4097861221166
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO RODRIGUEZ PEÑA",
            "id":"26021030013",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8042084863804,
               "lon":-67.4855016639952
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO SAAVEDRA",
            "id":"26021030014",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8455307615478,
               "lon":-67.5145852993993
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO SARMIENTO",
            "id":"26021030015",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8191333517328,
               "lon":-67.502587140919
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO 25 DE MAYO",
            "id":"26021030016",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8248454635041,
               "lon":-67.4616495988676
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"BARRIO VILLA S.U.P.E.",
            "id":"26021030017",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8367380263826,
               "lon":-67.5021112026914
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"COMODORO RIVADAVIA",
            "id":"26021030018",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8729846778063,
               "lon":-67.5430559979427
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"KM. 5 - PRESIDENTE ORTIZ",
            "id":"26021030019",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7875522789201,
               "lon":-67.4695736235974
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"KM. 8 - DON BOSCO",
            "id":"26021030020",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7988982385959,
               "lon":-67.4314970230436
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"KM. 11 - CUARTELES",
            "id":"26021030021",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.7707679612545,
               "lon":-67.4358046744231
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"KM. 3 - GENERAL MOSCONI",
            "id":"26021030022",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Comodoro Rivadavia",
               "id":"26021030"
            },
            "centroide":{
               "lat":-45.8318281251079,
               "lon":-67.4917096424434
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comodoro Rivadavia",
               "id":"260049"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"DIADEMA ARGENTINA",
            "id":"26021040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Diadema Argentina",
               "id":"26021040"
            },
            "centroide":{
               "lat":-45.7906089158021,
               "lon":-67.6738925726169
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rada Tilly",
               "id":"260056"
            },
            "departamento":{
               "nombre":"Escalante",
               "id":"26021"
            },
            "nombre":"RADA TILLY",
            "id":"26021050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Rada Tilly",
               "id":"26021050"
            },
            "centroide":{
               "lat":-45.9368019185633,
               "lon":-67.5653960236573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Camarones",
               "id":"260063"
            },
            "departamento":{
               "nombre":"Florentino Ameghino",
               "id":"26028"
            },
            "nombre":"CAMARONES",
            "id":"26028010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Camarones",
               "id":"26028010"
            },
            "centroide":{
               "lat":-44.79829087317,
               "lon":-65.7105992934951
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Florentino Ameghino",
               "id":"26028"
            },
            "nombre":"GARAYALDE",
            "id":"26028020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Garayalde",
               "id":"26028020"
            },
            "centroide":{
               "lat":-44.6795783728661,
               "lon":-66.6097364550235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trevelín",
               "id":"260084"
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"ALDEA ESCOLAR",
            "id":"26035010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Aldea Escolar (Los Rápidos)",
               "id":"26035010"
            },
            "centroide":{
               "lat":-43.1198187614025,
               "lon":-71.5588967458005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corcovado",
               "id":"260070"
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"CORCOVADO",
            "id":"26035020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Corcovado",
               "id":"26035020"
            },
            "centroide":{
               "lat":-43.5380021657326,
               "lon":-71.4659616215841
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esquel",
               "id":"260077"
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"ESQUEL",
            "id":"26035030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Esquel",
               "id":"26035030"
            },
            "centroide":{
               "lat":-42.9133238858291,
               "lon":-71.3185116319277
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trevelín",
               "id":"260084"
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"LAGO ROSARIO",
            "id":"26035040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Lago Rosario",
               "id":"26035040"
            },
            "centroide":{
               "lat":-43.2500168518714,
               "lon":-71.3512318442886
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trevelín",
               "id":"260084"
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"LOS CIPRESES",
            "id":"26035050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Los Cipreses",
               "id":"26035050"
            },
            "centroide":{
               "lat":-43.1847146210617,
               "lon":-71.6413919295155
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trevelín",
               "id":"260084"
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"TREVELIN",
            "id":"26035060000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Trevelín",
               "id":"26035060"
            },
            "centroide":{
               "lat":-43.0908711905976,
               "lon":-71.4654853699539
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Futaleufú",
               "id":"26035"
            },
            "nombre":"VILLA FUTALAUFQUEN",
            "id":"26035070000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Villa Futalaufquen",
               "id":"26035070"
            },
            "centroide":{
               "lat":-42.9006375858067,
               "lon":-71.6061535113717
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dique Florentino Ameghino",
               "id":"268028"
            },
            "departamento":{
               "nombre":"Gaiman",
               "id":"26042"
            },
            "nombre":"DIQUE FLORENTINO AMEGHINO",
            "id":"26042010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Dique Florentino Ameghino",
               "id":"26042010"
            },
            "centroide":{
               "lat":-43.7032847513538,
               "lon":-66.479400323535
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dolavon",
               "id":"260091"
            },
            "departamento":{
               "nombre":"Gaiman",
               "id":"26042"
            },
            "nombre":"DOLAVON",
            "id":"26042020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Dolavon",
               "id":"26042020"
            },
            "centroide":{
               "lat":-43.309297578868,
               "lon":-65.7087388385691
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gaiman",
               "id":"260098"
            },
            "departamento":{
               "nombre":"Gaiman",
               "id":"26042"
            },
            "nombre":"GAIMAN",
            "id":"26042030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Gaiman",
               "id":"26042030"
            },
            "centroide":{
               "lat":-43.288807480447,
               "lon":-65.4924135000744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"28 de Julio",
               "id":"265014"
            },
            "departamento":{
               "nombre":"Gaiman",
               "id":"26042"
            },
            "nombre":"28 DE JULIO",
            "id":"26042040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"28 de Julio",
               "id":"26042040"
            },
            "centroide":{
               "lat":-43.3809771295585,
               "lon":-65.8386877428492
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gastre",
               "id":"26049"
            },
            "nombre":"BLANCUNTRE",
            "id":"26049010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Blancuntre",
               "id":"26049010"
            },
            "centroide":{
               "lat":-42.5683892291525,
               "lon":-68.9190916237139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gastre",
               "id":"26049"
            },
            "nombre":"EL ESCORIAL",
            "id":"26049020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"El Escorial",
               "id":"26049020"
            },
            "centroide":{
               "lat":-43.098070041959,
               "lon":-68.5596033354947
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gastre",
               "id":"268035"
            },
            "departamento":{
               "nombre":"Gastre",
               "id":"26049"
            },
            "nombre":"GASTRE",
            "id":"26049030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Gastre",
               "id":"26049030"
            },
            "centroide":{
               "lat":-42.2656204565429,
               "lon":-69.2210978977662
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gastre",
               "id":"26049"
            },
            "nombre":"LAGUNITA SALADA",
            "id":"26049040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Lagunita Salada",
               "id":"26049040"
            },
            "centroide":{
               "lat":-42.7167792838299,
               "lon":-69.1884723935703
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gastre",
               "id":"26049"
            },
            "nombre":"YALA LAUBAT",
            "id":"26049050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Yala Laubat",
               "id":"26049050"
            },
            "centroide":{
               "lat":-42.7671354457615,
               "lon":-68.875421794475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Languiñeo",
               "id":"26056"
            },
            "nombre":"ALDEA EPULEF",
            "id":"26056010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Aldea Epulef",
               "id":"26056010"
            },
            "centroide":{
               "lat":-43.2341165952859,
               "lon":-69.7112937770404
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carrenleufú",
               "id":"268056"
            },
            "departamento":{
               "nombre":"Languiñeo",
               "id":"26056"
            },
            "nombre":"CARRENLEUFU",
            "id":"26056020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Carrenleufú",
               "id":"26056020"
            },
            "centroide":{
               "lat":-43.5856885437306,
               "lon":-71.7009020245021
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colan Conhué",
               "id":"268063"
            },
            "departamento":{
               "nombre":"Languiñeo",
               "id":"26056"
            },
            "nombre":"COLAN CONHUE",
            "id":"26056030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Colan Conhué",
               "id":"26056030"
            },
            "centroide":{
               "lat":-43.2425977048335,
               "lon":-69.9302181856069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso del Sapo",
               "id":"268070"
            },
            "departamento":{
               "nombre":"Languiñeo",
               "id":"26056"
            },
            "nombre":"PASO DEL SAPO",
            "id":"26056040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Paso del Sapo",
               "id":"26056040"
            },
            "centroide":{
               "lat":-42.7371109950872,
               "lon":-69.6110020875554
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tecka",
               "id":"260105"
            },
            "departamento":{
               "nombre":"Languiñeo",
               "id":"26056"
            },
            "nombre":"TECKA",
            "id":"26056050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Tecka",
               "id":"26056050"
            },
            "centroide":{
               "lat":-43.4935820815258,
               "lon":-70.8135608321009
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Mártires",
               "id":"26063"
            },
            "nombre":"EL MIRASOL",
            "id":"26063010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"El Mirasol",
               "id":"26063010"
            },
            "centroide":{
               "lat":-43.2852932712541,
               "lon":-67.7603113819649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Plumas",
               "id":"268077"
            },
            "departamento":{
               "nombre":"Mártires",
               "id":"26063"
            },
            "nombre":"LAS PLUMAS",
            "id":"26063020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Las Plumas",
               "id":"26063020"
            },
            "centroide":{
               "lat":-43.7223979636171,
               "lon":-67.2869956666141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paso de Indios",
               "id":"26070"
            },
            "nombre":"CERRO CONDOR",
            "id":"26070010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Cerro Cóndor",
               "id":"26070010"
            },
            "centroide":{
               "lat":-43.4233198653988,
               "lon":-69.1640923476749
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Altares",
               "id":"268084"
            },
            "departamento":{
               "nombre":"Paso de Indios",
               "id":"26070"
            },
            "nombre":"LOS ALTARES",
            "id":"26070020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Los Altares",
               "id":"26070020"
            },
            "centroide":{
               "lat":-43.8711929981581,
               "lon":-68.4301168776514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso de Indios",
               "id":"265021"
            },
            "departamento":{
               "nombre":"Paso de Indios",
               "id":"26070"
            },
            "nombre":"PASO DE INDIOS",
            "id":"26070030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Paso de Indios",
               "id":"26070030"
            },
            "centroide":{
               "lat":-43.8663054117658,
               "lon":-69.0448870902309
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"260112"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"26077"
            },
            "nombre":"PLAYA MAGAGNA",
            "id":"26077010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Playa Magagna",
               "id":"26077010"
            },
            "centroide":{
               "lat":-43.3821208618862,
               "lon":-65.0449325683674
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"260112"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"26077"
            },
            "nombre":"PLAYA UNION",
            "id":"26077020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Playa Unión",
               "id":"26077020"
            },
            "centroide":{
               "lat":-43.3219408530217,
               "lon":-65.0475987350977
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"260112"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"26077"
            },
            "nombre":"RAWSON",
            "id":"26077030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Rawson",
               "id":"26077030"
            },
            "centroide":{
               "lat":-43.3010516778945,
               "lon":-65.0955202340428
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trelew",
               "id":"260119"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"26077"
            },
            "nombre":"TRELEW",
            "id":"26077040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Trelew",
               "id":"26077040"
            },
            "centroide":{
               "lat":-43.2483538570309,
               "lon":-65.3103813319694
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alto Río Senguer",
               "id":"260126"
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"ALDEA APELEG",
            "id":"26084010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Aldea Apeleg",
               "id":"26084010"
            },
            "centroide":{
               "lat":-44.7052521139583,
               "lon":-70.8460010136593
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aldea Beleiro",
               "id":"268091"
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"ALDEA BELEIRO",
            "id":"26084020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Aldea Beleiro",
               "id":"26084020"
            },
            "centroide":{
               "lat":-45.5614644681207,
               "lon":-71.5181726162484
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alto Río Senguer",
               "id":"260126"
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"ALTO RIO SENGUER",
            "id":"26084030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Alto Río Senguer",
               "id":"26084030"
            },
            "centroide":{
               "lat":-45.0476417520383,
               "lon":-70.8227552077123
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Doctor Ricardo Rojas",
               "id":"268098"
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"DOCTOR RICARDO ROJAS",
            "id":"26084040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Doctor Ricardo Rojas",
               "id":"26084040"
            },
            "centroide":{
               "lat":-45.5870084306305,
               "lon":-71.0290968422414
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Facundo",
               "id":"268105"
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"FACUNDO",
            "id":"26084050000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Facundo",
               "id":"26084050"
            },
            "centroide":{
               "lat":-45.3192710383686,
               "lon":-69.9721127349678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"LAGO BLANCO",
            "id":"26084060000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Lago Blanco",
               "id":"26084060"
            },
            "centroide":{
               "lat":-45.9468594190828,
               "lon":-71.2641106643113
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Mayo",
               "id":"260133"
            },
            "departamento":{
               "nombre":"Río Senguer",
               "id":"26084"
            },
            "nombre":"RIO MAYO",
            "id":"26084070000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Río Mayo",
               "id":"26084070"
            },
            "centroide":{
               "lat":-45.6964538171738,
               "lon":-70.2559804482547
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Buen Pasto",
               "id":"268119"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"26091"
            },
            "nombre":"BUEN PASTO",
            "id":"26091010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Buen Pasto",
               "id":"26091010"
            },
            "centroide":{
               "lat":-45.0804225633305,
               "lon":-69.4497777721279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"260140"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"26091"
            },
            "nombre":"SARMIENTO",
            "id":"26091020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Sarmiento",
               "id":"26091020"
            },
            "centroide":{
               "lat":-45.590519720018,
               "lon":-69.0706825167205
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dr. Atilio Oscar Viglione",
               "id":"268147"
            },
            "departamento":{
               "nombre":"Tehuelches",
               "id":"26098"
            },
            "nombre":"FRONTERA DE RIO PICO",
            "id":"26098010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Doctor Oscar Atilio Viglione (Frontera de Río Pico)",
               "id":"26098010"
            },
            "centroide":{
               "lat":-44.1988556500986,
               "lon":-71.6666681641388
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Costa",
               "id":"260147"
            },
            "departamento":{
               "nombre":"Tehuelches",
               "id":"26098"
            },
            "nombre":"GOBERNADOR COSTA",
            "id":"26098020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Gobernador Costa",
               "id":"26098020"
            },
            "centroide":{
               "lat":-44.0529826063177,
               "lon":-70.597920256446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"José de San Martín",
               "id":"260154"
            },
            "departamento":{
               "nombre":"Tehuelches",
               "id":"26098"
            },
            "nombre":"JOSE DE SAN MARTIN",
            "id":"26098030000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"José de San Martín",
               "id":"26098030"
            },
            "centroide":{
               "lat":-44.0545030861499,
               "lon":-70.4698423450266
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Pico",
               "id":"260161"
            },
            "departamento":{
               "nombre":"Tehuelches",
               "id":"26098"
            },
            "nombre":"RIO PICO",
            "id":"26098040000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Río Pico",
               "id":"26098040"
            },
            "centroide":{
               "lat":-44.1829932413496,
               "lon":-71.3700565360866
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gan Gan",
               "id":"268126"
            },
            "departamento":{
               "nombre":"Telsen",
               "id":"26105"
            },
            "nombre":"GAN GAN",
            "id":"26105010000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Gan Gan",
               "id":"26105010"
            },
            "centroide":{
               "lat":-42.5232330288476,
               "lon":-68.2828542288869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Telsen",
               "id":"26105"
            },
            "nombre":"TELSEN",
            "id":"26105020000",
            "provincia":{
               "nombre":"Chubut",
               "id":"26"
            },
            "localidad_censal":{
               "nombre":"Telsen",
               "id":"26105020"
            },
            "centroide":{
               "lat":-42.4387511614213,
               "lon":-66.941427996482
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"ARROYO BARU",
            "id":"30008010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Arroyo Barú",
               "id":"30008010"
            },
            "centroide":{
               "lat":-31.8673630759862,
               "lon":-58.445702857466
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"COLON",
            "id":"30008020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colón",
               "id":"30008020"
            },
            "centroide":{
               "lat":-32.2232180852093,
               "lon":-58.1419674849484
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"COLONIA HUGUES",
            "id":"30008030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Hugues",
               "id":"30008030"
            },
            "centroide":{
               "lat":-32.2963657704418,
               "lon":-58.2329681221353
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"HAMBIS",
            "id":"30008040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Hambis",
               "id":"30008040"
            },
            "centroide":{
               "lat":-31.9629513486876,
               "lon":-58.5082059489741
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"HOCKER",
            "id":"30008050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Hocker",
               "id":"30008050"
            },
            "centroide":{
               "lat":-32.0874958453901,
               "lon":-58.3417058444451
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"LA CLARITA",
            "id":"30008060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"La Clarita",
               "id":"30008060"
            },
            "centroide":{
               "lat":-31.9771375050248,
               "lon":-58.3917628888627
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"PUEBLO CAZES",
            "id":"30008070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pueblo Cazes",
               "id":"30008070"
            },
            "centroide":{
               "lat":-32.0030492517654,
               "lon":-58.4934832589352
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"PUEBLO LIEBIG'S",
            "id":"30008080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pueblo Liebig's",
               "id":"30008080"
            },
            "centroide":{
               "lat":-32.1570049045312,
               "lon":-58.1976616890773
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"EL BRILLANTE",
            "id":"30008090001",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"30008090"
            },
            "centroide":{
               "lat":-32.1797669801643,
               "lon":-58.2122328834059
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"EL COLORADO",
            "id":"30008090002",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"30008090"
            },
            "centroide":{
               "lat":-32.1819017485871,
               "lon":-58.1918561863469
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"SAN JOSE",
            "id":"30008090003",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"30008090"
            },
            "centroide":{
               "lat":-32.2021613289668,
               "lon":-58.2095931555666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"UBAJAY",
            "id":"30008100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Ubajay",
               "id":"30008100"
            },
            "centroide":{
               "lat":-31.7925728023428,
               "lon":-58.3158517356
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Colón",
               "id":"30008"
            },
            "nombre":"VILLA ELISA",
            "id":"30008110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Elisa",
               "id":"30008110"
            },
            "centroide":{
               "lat":-32.1606202225925,
               "lon":-58.4037483450693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"CALABACILLA",
            "id":"30015010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Calabacilla",
               "id":"30015010"
            },
            "centroide":{
               "lat":-31.507626297952,
               "lon":-58.1334346597166
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"CLODOMIRO LEDESMA",
            "id":"30015020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Clodomiro Ledesma",
               "id":"30015020"
            },
            "centroide":{
               "lat":-31.5794028169306,
               "lon":-58.1813678170213
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"COLONIA AYUI",
            "id":"30015030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Ayuí",
               "id":"30015030"
            },
            "centroide":{
               "lat":-31.2050901632153,
               "lon":-58.0338899223591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"COLONIA GENERAL ROCA",
            "id":"30015040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia General Roca",
               "id":"30015040"
            },
            "centroide":{
               "lat":-31.3244065210985,
               "lon":-58.1214096714805
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"BENITO LEGEREN",
            "id":"30015060001",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Concordia",
               "id":"30015060"
            },
            "centroide":{
               "lat":-31.4288786267036,
               "lon":-58.0768061386463
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"CONCORDIA",
            "id":"30015060002",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Concordia",
               "id":"30015060"
            },
            "centroide":{
               "lat":-31.3687044068789,
               "lon":-58.0450288431718
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"LAS TEJAS",
            "id":"30015060003",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Concordia",
               "id":"30015060"
            },
            "centroide":{
               "lat":-31.4119399624827,
               "lon":-58.0740082494429
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"VILLA ADELA",
            "id":"30015060004",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Concordia",
               "id":"30015060"
            },
            "centroide":{
               "lat":-31.4037426459166,
               "lon":-58.0394128271546
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"VILLA ZORRAQUIN",
            "id":"30015060005",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Concordia",
               "id":"30015060"
            },
            "centroide":{
               "lat":-31.3299511832527,
               "lon":-58.0174848453444
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"ESTACION YERUA",
            "id":"30015080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Yeruá",
               "id":"30015080"
            },
            "centroide":{
               "lat":-31.469433434517,
               "lon":-58.2636681024626
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"ESTACION YUQUERI",
            "id":"30015083000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Yuquerí",
               "id":"30015083"
            },
            "centroide":{
               "lat":-31.3963812386954,
               "lon":-58.1611535438718
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"ESTANCIA GRANDE",
            "id":"30015087000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estancia Grande",
               "id":"30015087"
            },
            "centroide":{
               "lat":-31.4400282680775,
               "lon":-58.1246631255854
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"LA CRIOLLA",
            "id":"30015090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"La Criolla",
               "id":"30015090"
            },
            "centroide":{
               "lat":-31.2693712880357,
               "lon":-58.1060425422369
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"LOS CHARRUAS",
            "id":"30015100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Los Charrúas",
               "id":"30015100"
            },
            "centroide":{
               "lat":-31.1707840748941,
               "lon":-58.1873842951633
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"NUEVA ESCOCIA",
            "id":"30015110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Nueva Escocia",
               "id":"30015110"
            },
            "centroide":{
               "lat":-31.6473163855612,
               "lon":-58.0158126069679
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"PEDERNAL",
            "id":"30015130000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pedernal",
               "id":"30015130"
            },
            "centroide":{
               "lat":-31.6714729659455,
               "lon":-58.2307912741944
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Concordia",
               "id":"30015"
            },
            "nombre":"PUERTO YERUA",
            "id":"30015140000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Puerto Yeruá",
               "id":"30015140"
            },
            "centroide":{
               "lat":-31.530833509471,
               "lon":-58.0128947749058
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA BRASILERA",
            "id":"30021010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Brasilera",
               "id":"30021010"
            },
            "centroide":{
               "lat":-31.8922864518526,
               "lon":-60.591797746941
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA GRAPSCHENTAL",
            "id":"30021015000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Grapschental",
               "id":"30021015"
            },
            "centroide":{
               "lat":-31.9563723140333,
               "lon":-60.4961051979678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA PROTESTANTE",
            "id":"30021020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Protestante",
               "id":"30021020"
            },
            "centroide":{
               "lat":-32.0308328904873,
               "lon":-60.5643359117847
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA SALTO",
            "id":"30021030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Salto",
               "id":"30021030"
            },
            "centroide":{
               "lat":-31.9260358583332,
               "lon":-60.5483852721123
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA SAN FRANCISCO",
            "id":"30021040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea San Francisco",
               "id":"30021040"
            },
            "centroide":{
               "lat":-31.9625681226479,
               "lon":-60.635713481626
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA SPATZENKUTTER",
            "id":"30021050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Spatzenkutter",
               "id":"30021050"
            },
            "centroide":{
               "lat":-31.9485292712546,
               "lon":-60.5803966416062
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ALDEA VALLE MARIA",
            "id":"30021060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Valle María",
               "id":"30021060"
            },
            "centroide":{
               "lat":-31.9922440087904,
               "lon":-60.5878502274446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"COLONIA ENSAYO",
            "id":"30021070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Ensayo",
               "id":"30021070"
            },
            "centroide":{
               "lat":-31.8672572611199,
               "lon":-60.5752901869571
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"DIAMANTE",
            "id":"30021080001",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Diamante",
               "id":"30021080"
            },
            "centroide":{
               "lat":-32.0671549441222,
               "lon":-60.6377365188118
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"STROBEL",
            "id":"30021080002",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Diamante",
               "id":"30021080"
            },
            "centroide":{
               "lat":-32.0585362988015,
               "lon":-60.6108078415946
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ESTACION CAMPS",
            "id":"30021090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Camps",
               "id":"30021090"
            },
            "centroide":{
               "lat":-32.1133207438253,
               "lon":-60.2299017967384
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"GENERAL ALVEAR",
            "id":"30021100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"General Alvear",
               "id":"30021100"
            },
            "centroide":{
               "lat":-31.9494541080354,
               "lon":-60.6779497259324
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"GENERAL RACEDO",
            "id":"30021110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"General Racedo (El Carmen)",
               "id":"30021110"
            },
            "centroide":{
               "lat":-31.9840164487261,
               "lon":-60.4083912285494
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"GENERAL RAMIREZ",
            "id":"30021120000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"General Ramírez",
               "id":"30021120"
            },
            "centroide":{
               "lat":-32.1772714430842,
               "lon":-60.1985605811767
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"LA JUANITA",
            "id":"30021123000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"La Juanita",
               "id":"30021123"
            },
            "centroide":{
               "lat":-31.8753768018271,
               "lon":-60.649121973368
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"LA JAULA",
            "id":"30021127000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Las Jaulas",
               "id":"30021127"
            },
            "centroide":{
               "lat":-31.8327184836082,
               "lon":-60.6048471558247
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"PARAJE LA VIRGEN",
            "id":"30021130000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Paraje La Virgen",
               "id":"30021130"
            },
            "centroide":{
               "lat":-31.917362571918,
               "lon":-60.6531968809921
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"PUERTO LAS CUEVAS",
            "id":"30021140000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Puerto Las Cuevas",
               "id":"30021140"
            },
            "centroide":{
               "lat":-32.3342284848162,
               "lon":-60.4872902471305
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"ESTACION PUIGGARI",
            "id":"30021150001",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Libertador San Martín",
               "id":"30021150"
            },
            "centroide":{
               "lat":-32.0602299925107,
               "lon":-60.4435729044204
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Diamante",
               "id":"30021"
            },
            "nombre":"VILLA LIBERTADOR SAN MARTIN",
            "id":"30021150002",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Libertador San Martín",
               "id":"30021150"
            },
            "centroide":{
               "lat":-32.0697561751369,
               "lon":-60.4624463940416
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"CHAJARI",
            "id":"30028010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Chajarí",
               "id":"30028010"
            },
            "centroide":{
               "lat":-30.7540759107669,
               "lon":-57.9841625878637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"COLONIA ALEMANA",
            "id":"30028020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Alemana",
               "id":"30028020"
            },
            "centroide":{
               "lat":-30.896158506292,
               "lon":-58.0221576342783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"COLONIA PEÑA",
            "id":"30028050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Peña",
               "id":"30028050"
            },
            "centroide":{
               "lat":-30.7974915997552,
               "lon":-58.1756384659062
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"FEDERACION",
            "id":"30028070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Federación",
               "id":"30028070"
            },
            "centroide":{
               "lat":-30.990072444489,
               "lon":-57.9183475208406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"LOS CONQUISTADORES",
            "id":"30028080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Los Conquistadores",
               "id":"30028080"
            },
            "centroide":{
               "lat":-30.5944074579969,
               "lon":-58.4682627057869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"SAN JAIME DE LA FRONTERA",
            "id":"30028090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Jaime de la Frontera",
               "id":"30028090"
            },
            "centroide":{
               "lat":-30.3396273493524,
               "lon":-58.3080915973688
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"SAN PEDRO",
            "id":"30028100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"30028100"
            },
            "centroide":{
               "lat":-30.7571887237222,
               "lon":-58.0818713823153
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"SAN RAMON",
            "id":"30028105000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Ramón",
               "id":"30028105"
            },
            "centroide":{
               "lat":-30.8091644325467,
               "lon":-58.2191269101013
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"SANTA ANA",
            "id":"30028110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Santa Ana",
               "id":"30028110"
            },
            "centroide":{
               "lat":-30.9004970305733,
               "lon":-57.9310809529514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federación",
               "id":"30028"
            },
            "nombre":"VILLA DEL ROSARIO",
            "id":"30028120000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa del Rosario",
               "id":"30028120"
            },
            "centroide":{
               "lat":-30.7968318611671,
               "lon":-57.9115719165535
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federal",
               "id":"30035"
            },
            "nombre":"CONSCRIPTO BERNARDI",
            "id":"30035010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Conscripto Bernardi",
               "id":"30035010"
            },
            "centroide":{
               "lat":-31.0477778936084,
               "lon":-59.0867391405808
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federal",
               "id":"30035"
            },
            "nombre":"EL CIMARRON",
            "id":"30035020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea San Isidro (El Cimarrón)",
               "id":"30035020"
            },
            "centroide":{
               "lat":-30.9868629826869,
               "lon":-58.9777569104134
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federal",
               "id":"30035"
            },
            "nombre":"FEDERAL",
            "id":"30035030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Federal",
               "id":"30035030"
            },
            "centroide":{
               "lat":-30.9513342178715,
               "lon":-58.7851418943148
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federal",
               "id":"30035"
            },
            "nombre":"NUEVA VIZCAYA",
            "id":"30035040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Nueva Vizcaya",
               "id":"30035040"
            },
            "centroide":{
               "lat":-30.9685149773302,
               "lon":-58.6127479285248
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Federal",
               "id":"30035"
            },
            "nombre":"SAUCE DE LUNA",
            "id":"30035050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Sauce de Luna",
               "id":"30035050"
            },
            "centroide":{
               "lat":-31.2384740579796,
               "lon":-59.2202829564802
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Feliciano",
               "id":"30042"
            },
            "nombre":"SAN JOSE DE FELICIANO",
            "id":"30042010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San José de Feliciano",
               "id":"30042010"
            },
            "centroide":{
               "lat":-30.3852713114912,
               "lon":-58.7515719602207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Feliciano",
               "id":"30042"
            },
            "nombre":"SAN VICTOR",
            "id":"30042020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Víctor",
               "id":"30042020"
            },
            "centroide":{
               "lat":-30.4869283596556,
               "lon":-59.0316519541684
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguay",
               "id":"30049"
            },
            "nombre":"ALDEA ASUNCION",
            "id":"30049010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Asunción",
               "id":"30049010"
            },
            "centroide":{
               "lat":-32.825171529429,
               "lon":-59.2312379231862
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguay",
               "id":"30049"
            },
            "nombre":"ESTACION LAZO",
            "id":"30049020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Lazo",
               "id":"30049020"
            },
            "centroide":{
               "lat":-32.8718397608117,
               "lon":-59.4223491853546
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguay",
               "id":"30049"
            },
            "nombre":"GENERAL GALARZA",
            "id":"30049030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"General Galarza",
               "id":"30049030"
            },
            "centroide":{
               "lat":-32.7212713061819,
               "lon":-59.3958887031115
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguay",
               "id":"30049"
            },
            "nombre":"GUALEGUAY",
            "id":"30049040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Gualeguay",
               "id":"30049040"
            },
            "centroide":{
               "lat":-33.1485556234267,
               "lon":-59.3150160418816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguay",
               "id":"30049"
            },
            "nombre":"PUERTO RUIZ",
            "id":"30049050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Puerto Ruiz",
               "id":"30049050"
            },
            "centroide":{
               "lat":-33.2198401773573,
               "lon":-59.3630338659967
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"ALDEA SAN ANTONIO",
            "id":"30056010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea San Antonio",
               "id":"30056010"
            },
            "centroide":{
               "lat":-32.6253281653841,
               "lon":-58.703196864174
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"ALDEA SAN JUAN",
            "id":"30056020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea San Juan",
               "id":"30056020"
            },
            "centroide":{
               "lat":-32.703788501225,
               "lon":-58.7790625010106
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"ENRIQUE CARBO",
            "id":"30056030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Enrique Carbó",
               "id":"30056030"
            },
            "centroide":{
               "lat":-33.1478483564668,
               "lon":-59.2089401138297
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"ESTACION ESCRIÑA",
            "id":"30056035000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Escriña",
               "id":"30056035"
            },
            "centroide":{
               "lat":-32.5932358304352,
               "lon":-58.9037416509557
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"FAUSTINO M. PARERA",
            "id":"30056040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Faustino M. Parera",
               "id":"30056040"
            },
            "centroide":{
               "lat":-32.8044314283598,
               "lon":-58.8826333992369
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"GENERAL ALMADA",
            "id":"30056050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"General Almada",
               "id":"30056050"
            },
            "centroide":{
               "lat":-32.8376334754538,
               "lon":-58.8038160467091
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"GILBERT",
            "id":"30056060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Gilbert",
               "id":"30056060"
            },
            "centroide":{
               "lat":-32.5306030521064,
               "lon":-58.9339533000982
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"GUALEGUAYCHU",
            "id":"30056070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Gualeguaychú",
               "id":"30056070"
            },
            "centroide":{
               "lat":-33.0100328267382,
               "lon":-58.5164257946352
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"IRAZUSTA",
            "id":"30056080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Irazusta",
               "id":"30056080"
            },
            "centroide":{
               "lat":-32.9269373297111,
               "lon":-58.9534834763678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"LARROQUE",
            "id":"30056090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Larroque",
               "id":"30056090"
            },
            "centroide":{
               "lat":-33.0354242763647,
               "lon":-59.001853656082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"PASTOR BRITOS",
            "id":"30056095000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pastor Britos",
               "id":"30056095"
            },
            "centroide":{
               "lat":-32.7697426128614,
               "lon":-58.9070200410514
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"PUEBLO GENERAL BELGRANO",
            "id":"30056100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pueblo General Belgrano",
               "id":"30056100"
            },
            "centroide":{
               "lat":-33.0162888459347,
               "lon":-58.476043760345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gualeguaychú",
               "id":"30056"
            },
            "nombre":"URDINARRAIN",
            "id":"30056110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Urdinarrain",
               "id":"30056110"
            },
            "centroide":{
               "lat":-32.6881311554333,
               "lon":-58.888994553976
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Islas del Ibicuy",
               "id":"30063"
            },
            "nombre":"CEIBAS",
            "id":"30063020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Ceibas",
               "id":"30063020"
            },
            "centroide":{
               "lat":-33.4994124052794,
               "lon":-58.8003541709834
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Islas del Ibicuy",
               "id":"30063"
            },
            "nombre":"IBICUY",
            "id":"30063030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Ibicuy",
               "id":"30063030"
            },
            "centroide":{
               "lat":-33.7437863303553,
               "lon":-59.1550366922315
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Islas del Ibicuy",
               "id":"30063"
            },
            "nombre":"MEDANOS",
            "id":"30063040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Médanos",
               "id":"30063040"
            },
            "centroide":{
               "lat":-33.4360995740998,
               "lon":-59.0675952112197
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Islas del Ibicuy",
               "id":"30063"
            },
            "nombre":"VILLA PARANACITO",
            "id":"30063060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Paranacito",
               "id":"30063060"
            },
            "centroide":{
               "lat":-33.7156244223002,
               "lon":-58.6597631447303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"ALCARAZ",
            "id":"30070005000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Alcaraz",
               "id":"30070005"
            },
            "centroide":{
               "lat":-31.4565889671918,
               "lon":-59.5991388684545
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"BOVRIL",
            "id":"30070010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Bovril",
               "id":"30070010"
            },
            "centroide":{
               "lat":-31.3428914409424,
               "lon":-59.4467309737899
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"COLONIA AVIGDOR",
            "id":"30070020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Avigdor",
               "id":"30070020"
            },
            "centroide":{
               "lat":-31.1853384671792,
               "lon":-59.4079822910182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"EL SOLAR",
            "id":"30070030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"El Solar",
               "id":"30070030"
            },
            "centroide":{
               "lat":-31.1810124666205,
               "lon":-59.7328844569416
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"LA PAZ",
            "id":"30070040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"La Paz",
               "id":"30070040"
            },
            "centroide":{
               "lat":-30.741541104376,
               "lon":-59.6433535241897
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"PIEDRAS BLANCAS",
            "id":"30070050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Piedras Blancas",
               "id":"30070050"
            },
            "centroide":{
               "lat":-31.1859575955508,
               "lon":-59.9521591252044
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"SAN GUSTAVO",
            "id":"30070070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Gustavo",
               "id":"30070070"
            },
            "centroide":{
               "lat":-30.6905067150943,
               "lon":-59.3994540133514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"SANTA ELENA",
            "id":"30070080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Santa Elena",
               "id":"30070080"
            },
            "centroide":{
               "lat":-30.9466589584952,
               "lon":-59.7882850384119
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"30070"
            },
            "nombre":"SIR LEONARD",
            "id":"30070090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Sir Leonard",
               "id":"30070090"
            },
            "centroide":{
               "lat":-31.3896679471814,
               "lon":-59.5032123815164
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"ARANGUREN",
            "id":"30077010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aranguren",
               "id":"30077010"
            },
            "centroide":{
               "lat":-32.2443130059944,
               "lon":-60.1627988186884
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"BETBEDER",
            "id":"30077020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Betbeder",
               "id":"30077020"
            },
            "centroide":{
               "lat":-32.374100868006,
               "lon":-59.9380818002549
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"DON CRISTOBAL",
            "id":"30077030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Don Cristóbal",
               "id":"30077030"
            },
            "centroide":{
               "lat":-32.0752594049271,
               "lon":-59.9943230195983
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"FEBRE",
            "id":"30077040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Febré",
               "id":"30077040"
            },
            "centroide":{
               "lat":-32.4774515995652,
               "lon":-59.9217117300676
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"HERNANDEZ",
            "id":"30077050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Hernández",
               "id":"30077050"
            },
            "centroide":{
               "lat":-32.3380692970598,
               "lon":-60.0301404781048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"LUCAS GONZALEZ",
            "id":"30077060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Lucas González",
               "id":"30077060"
            },
            "centroide":{
               "lat":-32.3859289481651,
               "lon":-59.531300464703
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"NOGOYA",
            "id":"30077070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Nogoyá",
               "id":"30077070"
            },
            "centroide":{
               "lat":-32.3956416421841,
               "lon":-59.7880764157544
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Nogoyá",
               "id":"30077"
            },
            "nombre":"XX DE SETIEMBRE",
            "id":"30077080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"XX de Setiembre",
               "id":"30077080"
            },
            "centroide":{
               "lat":-32.3866241398753,
               "lon":-59.6630736582897
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"ALDEA MARIA LUISA",
            "id":"30084010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea María Luisa",
               "id":"30084010"
            },
            "centroide":{
               "lat":-31.8847292300203,
               "lon":-60.4087456869806
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"ALDEA SAN JUAN",
            "id":"30084015000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea San Juan",
               "id":"30084015"
            },
            "centroide":{
               "lat":-31.9982237404715,
               "lon":-60.3583819889242
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"ALDEA SAN RAFAEL",
            "id":"30084020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea San Rafael",
               "id":"30084020"
            },
            "centroide":{
               "lat":-31.9598299451355,
               "lon":-60.2558755485286
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"ALDEA SANTA MARIA",
            "id":"30084030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Santa María",
               "id":"30084030"
            },
            "centroide":{
               "lat":-31.6128701885629,
               "lon":-60.0070721806963
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"ALDEA SANTA ROSA",
            "id":"30084040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Aldea Santa Rosa",
               "id":"30084040"
            },
            "centroide":{
               "lat":-32.0164544602725,
               "lon":-60.2411073474122
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"CERRITO",
            "id":"30084050001",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Cerrito",
               "id":"30084050"
            },
            "centroide":{
               "lat":-31.5821711222967,
               "lon":-60.0740223148253
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"PUEBLO MORENO",
            "id":"30084050002",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Cerrito",
               "id":"30084050"
            },
            "centroide":{
               "lat":-31.5884913847857,
               "lon":-60.0648929343735
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"COLONIA AVELLANEDA",
            "id":"30084060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Avellaneda",
               "id":"30084060"
            },
            "centroide":{
               "lat":-31.7680690511493,
               "lon":-60.4050220629258
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"COLONIA CRESPO",
            "id":"30084065000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Crespo",
               "id":"30084065"
            },
            "centroide":{
               "lat":-31.6777727008412,
               "lon":-60.2436880885317
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"CRESPO",
            "id":"30084070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Crespo",
               "id":"30084070"
            },
            "centroide":{
               "lat":-32.0346491846505,
               "lon":-60.3107514290928
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"EL PALENQUE",
            "id":"30084080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"El Palenque",
               "id":"30084080"
            },
            "centroide":{
               "lat":-31.6625748086746,
               "lon":-60.1751144821058
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"EL PINGO",
            "id":"30084090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"El Pingo",
               "id":"30084090"
            },
            "centroide":{
               "lat":-31.5838497016925,
               "lon":-59.8938069366259
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"EL RAMBLON",
            "id":"30084095000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"El Ramblón",
               "id":"30084095"
            },
            "centroide":{
               "lat":-31.8480473768248,
               "lon":-60.0927487719843
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"HASENKAMP",
            "id":"30084100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Hasenkamp",
               "id":"30084100"
            },
            "centroide":{
               "lat":-31.5133052410614,
               "lon":-59.83696962349
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"HERNANDARIAS",
            "id":"30084110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Hernandarias",
               "id":"30084110"
            },
            "centroide":{
               "lat":-31.2324500702018,
               "lon":-59.9872576438234
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"LA PICADA",
            "id":"30084120000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"La Picada",
               "id":"30084120"
            },
            "centroide":{
               "lat":-31.7267100818149,
               "lon":-60.2971793004448
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"LAS TUNAS",
            "id":"30084130000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Las Tunas",
               "id":"30084130"
            },
            "centroide":{
               "lat":-31.8706095724666,
               "lon":-59.7324361083485
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"MARIA GRANDE",
            "id":"30084140000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"María Grande",
               "id":"30084140"
            },
            "centroide":{
               "lat":-31.6660715037636,
               "lon":-59.9044379252459
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"ORO VERDE",
            "id":"30084150000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Oro Verde",
               "id":"30084150"
            },
            "centroide":{
               "lat":-31.8257355466309,
               "lon":-60.5182728163031
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"PARANA",
            "id":"30084160000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Paraná",
               "id":"30084160"
            },
            "centroide":{
               "lat":-31.7415676426411,
               "lon":-60.5284145917589
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"PUEBLO BELLOCQ",
            "id":"30084170000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pueblo Bellocq (Las Garzas)",
               "id":"30084170"
            },
            "centroide":{
               "lat":-31.4287140394702,
               "lon":-59.7457885927193
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"PUEBLO BRUGO",
            "id":"30084180000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pueblo Brugo",
               "id":"30084180"
            },
            "centroide":{
               "lat":-31.3883015926493,
               "lon":-60.0938334043815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"PUEBLO GENERAL SAN MARTIN",
            "id":"30084190000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pueblo General San Martín",
               "id":"30084190"
            },
            "centroide":{
               "lat":-31.4636064114686,
               "lon":-60.1679618601982
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"SAN BENITO",
            "id":"30084200000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Benito",
               "id":"30084200"
            },
            "centroide":{
               "lat":-31.7815218098022,
               "lon":-60.4419739913356
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"SAUCE MONTRULL",
            "id":"30084210000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Sauce Montrull",
               "id":"30084210"
            },
            "centroide":{
               "lat":-31.7463194997657,
               "lon":-60.3552297078863
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"SAUCE PINTO",
            "id":"30084220000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Sauce Pinto",
               "id":"30084220"
            },
            "centroide":{
               "lat":-31.8442990730164,
               "lon":-60.3747483050584
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"SEGUI",
            "id":"30084230000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Seguí",
               "id":"30084230"
            },
            "centroide":{
               "lat":-31.9588750821276,
               "lon":-60.1269829104478
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"SOSA",
            "id":"30084240000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Sosa",
               "id":"30084240"
            },
            "centroide":{
               "lat":-31.7385742726697,
               "lon":-59.9115081436489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"TABOSSI",
            "id":"30084250000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Tabossi",
               "id":"30084250"
            },
            "centroide":{
               "lat":-31.8047077554997,
               "lon":-59.9379151601641
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"TEZANOS PINTO",
            "id":"30084260000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Tezanos Pinto",
               "id":"30084260"
            },
            "centroide":{
               "lat":-31.8735456198919,
               "lon":-60.4970235321195
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"VIALE",
            "id":"30084270000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Viale",
               "id":"30084270"
            },
            "centroide":{
               "lat":-31.8714330822777,
               "lon":-60.0099225269048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"VILLA FONTANA",
            "id":"30084280000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Fontana",
               "id":"30084280"
            },
            "centroide":{
               "lat":-31.908647496665,
               "lon":-60.468352081106
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"VILLA GOBERNADOR LUIS F. ETCHEVEHERE",
            "id":"30084290000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Gdor. Luis F. Etchevehere",
               "id":"30084290"
            },
            "centroide":{
               "lat":-31.93428563973,
               "lon":-60.4279737732991
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Paraná",
               "id":"30084"
            },
            "nombre":"VILLA URQUIZA",
            "id":"30084300000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Urquiza",
               "id":"30084300"
            },
            "centroide":{
               "lat":-31.6507506100701,
               "lon":-60.3775855159669
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Salvador",
               "id":"30088"
            },
            "nombre":"GENERAL CAMPOS",
            "id":"30088010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"General Campos",
               "id":"30088010"
            },
            "centroide":{
               "lat":-31.5246078338723,
               "lon":-58.4047735134447
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Salvador",
               "id":"30088"
            },
            "nombre":"SAN SALVADOR",
            "id":"30088020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"San Salvador",
               "id":"30088020"
            },
            "centroide":{
               "lat":-31.6255225523021,
               "lon":-58.5040738167896
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"ALTAMIRANO SUR",
            "id":"30091010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Altamirano Sur",
               "id":"30091010"
            },
            "centroide":{
               "lat":-32.0888342815838,
               "lon":-59.1740705545307
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"DURAZNO",
            "id":"30091020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Durazno",
               "id":"30091020"
            },
            "centroide":{
               "lat":-31.9865854200416,
               "lon":-59.2804623070462
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"ESTACION ARROYO CLE",
            "id":"30091030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Arroyo Clé",
               "id":"30091030"
            },
            "centroide":{
               "lat":-32.6338905298532,
               "lon":-59.4016108911944
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"GOBERNADOR ECHAGUE",
            "id":"30091040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Gobernador Echagüe",
               "id":"30091040"
            },
            "centroide":{
               "lat":-32.3933701328502,
               "lon":-59.2758886814116
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"GOBERNADOR MANSILLA",
            "id":"30091050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Gobernador Mansilla",
               "id":"30091050"
            },
            "centroide":{
               "lat":-32.5464913620505,
               "lon":-59.3561472624329
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"GOBERNADOR SOLA",
            "id":"30091060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Gobernador Solá",
               "id":"30091060"
            },
            "centroide":{
               "lat":-32.3372059083888,
               "lon":-59.3710906213647
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"GUARDAMONTE",
            "id":"30091070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Guardamonte",
               "id":"30091070"
            },
            "centroide":{
               "lat":-32.0826358698135,
               "lon":-59.3082772238093
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"LAS GUACHAS",
            "id":"30091080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Las Guachas",
               "id":"30091080"
            },
            "centroide":{
               "lat":-32.4700075443165,
               "lon":-59.1726625031425
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"MACIA",
            "id":"30091090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Maciá",
               "id":"30091090"
            },
            "centroide":{
               "lat":-32.1724413881098,
               "lon":-59.3986787086128
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Tala",
               "id":"30091"
            },
            "nombre":"ROSARIO DEL TALA",
            "id":"30091100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Rosario del Tala",
               "id":"30091100"
            },
            "centroide":{
               "lat":-32.3026339163322,
               "lon":-59.1445792095518
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"BASAVILBASO",
            "id":"30098010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Basavilbaso",
               "id":"30098010"
            },
            "centroide":{
               "lat":-32.3725729351065,
               "lon":-58.8785609437917
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"CASEROS",
            "id":"30098020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Caseros",
               "id":"30098020"
            },
            "centroide":{
               "lat":-32.4642772562474,
               "lon":-58.4780718727049
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"COLONIA ELIA",
            "id":"30098030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Colonia Elía",
               "id":"30098030"
            },
            "centroide":{
               "lat":-32.6723717845853,
               "lon":-58.325119237234
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"CONCEPCION DEL URUGUAY",
            "id":"30098040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Concepción del Uruguay",
               "id":"30098040"
            },
            "centroide":{
               "lat":-32.4853601673728,
               "lon":-58.2320517380782
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"HERRERA",
            "id":"30098060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Herrera",
               "id":"30098060"
            },
            "centroide":{
               "lat":-32.434613979879,
               "lon":-58.6330137118779
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"LAS MOSCAS",
            "id":"30098070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Las Moscas",
               "id":"30098070"
            },
            "centroide":{
               "lat":-32.0926666859344,
               "lon":-58.9575923552082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"LIBAROS",
            "id":"30098080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Líbaros",
               "id":"30098080"
            },
            "centroide":{
               "lat":-32.2629479332194,
               "lon":-58.9066108120783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"1 DE MAYO",
            "id":"30098090000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"1º de Mayo",
               "id":"30098090"
            },
            "centroide":{
               "lat":-32.2570116072535,
               "lon":-58.422728628517
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"PRONUNCIAMIENTO",
            "id":"30098100000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Pronunciamiento",
               "id":"30098100"
            },
            "centroide":{
               "lat":-32.3456507831337,
               "lon":-58.4438591145895
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"ROCAMORA",
            "id":"30098110000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Rocamora",
               "id":"30098110"
            },
            "centroide":{
               "lat":-32.3459729612346,
               "lon":-58.9697454142681
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"SANTA ANITA",
            "id":"30098120000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Santa Anita",
               "id":"30098120"
            },
            "centroide":{
               "lat":-32.1762466320214,
               "lon":-58.7864822456477
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"VILLA MANTERO",
            "id":"30098130000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Mantero",
               "id":"30098130"
            },
            "centroide":{
               "lat":-32.3987091260597,
               "lon":-58.7440638984338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"VILLA SAN JUSTO",
            "id":"30098140000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa San Justo",
               "id":"30098140"
            },
            "centroide":{
               "lat":-32.446319897702,
               "lon":-58.4359138343848
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Uruguay",
               "id":"30098"
            },
            "nombre":"VILLA SAN MARCIAL",
            "id":"30098150000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa San Marcial (Est. Gobernador Urquiza)",
               "id":"30098150"
            },
            "centroide":{
               "lat":-32.1800624013874,
               "lon":-58.9301272035017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Victoria",
               "id":"30105"
            },
            "nombre":"ANTELO",
            "id":"30105010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Antelo",
               "id":"30105010"
            },
            "centroide":{
               "lat":-32.5338102554011,
               "lon":-60.0356966118049
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Victoria",
               "id":"30105"
            },
            "nombre":"MOLINO DOLL",
            "id":"30105040000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Molino Doll",
               "id":"30105040"
            },
            "centroide":{
               "lat":-32.3100409280054,
               "lon":-60.4202063551195
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Victoria",
               "id":"30105"
            },
            "nombre":"VICTORIA",
            "id":"30105060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Victoria",
               "id":"30105060"
            },
            "centroide":{
               "lat":-32.6205968368011,
               "lon":-60.1533126380173
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"ESTACION RAICES",
            "id":"30113010000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Estación Raíces",
               "id":"30113010"
            },
            "centroide":{
               "lat":-31.908248192653,
               "lon":-59.2606926522048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"INGENIERO MIGUEL SAJAROFF",
            "id":"30113020000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Miguel Sajaroff",
               "id":"30113020"
            },
            "centroide":{
               "lat":-31.9569336301554,
               "lon":-58.8491174614017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"JUBILEO",
            "id":"30113030000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Jubileo",
               "id":"30113030"
            },
            "centroide":{
               "lat":-31.7336733349136,
               "lon":-58.634038044354
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"PASO DE LA LAGUNA",
            "id":"30113050000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Paso de la Laguna",
               "id":"30113050"
            },
            "centroide":{
               "lat":-31.8057649065599,
               "lon":-59.1651613714524
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"VILLA CLARA",
            "id":"30113060000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Clara",
               "id":"30113060"
            },
            "centroide":{
               "lat":-31.8301004555882,
               "lon":-58.8233903183527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"VILLA DOMINGUEZ",
            "id":"30113070000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villa Domínguez",
               "id":"30113070"
            },
            "centroide":{
               "lat":-31.9870146859479,
               "lon":-58.9634179145279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Villaguay",
               "id":"30113"
            },
            "nombre":"VILLAGUAY",
            "id":"30113080000",
            "provincia":{
               "nombre":"Entre Ríos",
               "id":"30"
            },
            "localidad_censal":{
               "nombre":"Villaguay",
               "id":"30113080"
            },
            "centroide":{
               "lat":-31.8654413738655,
               "lon":-59.0290628351274
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"LA SOLEDAD",
            "id":"34007003000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Fortín Soledad",
               "id":"34007003"
            },
            "centroide":{
               "lat":-24.1490607210153,
               "lon":-60.692324164757
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"GUADALCAZAR",
            "id":"34007005000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Guadalcazar",
               "id":"34007005"
            },
            "centroide":{
               "lat":-23.6823938545951,
               "lon":-61.1611089476784
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"LA RINCONADA",
            "id":"34007007000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"La Rinconada",
               "id":"34007007"
            },
            "centroide":{
               "lat":-23.4942751905754,
               "lon":-61.5763888694601
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Yema",
               "id":"340007"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"LAGUNA YEMA",
            "id":"34007010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Laguna Yema",
               "id":"34007010"
            },
            "centroide":{
               "lat":-24.2554647197914,
               "lon":-61.2439980075436
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"LAMADRID",
            "id":"34007015000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Lamadrid",
               "id":"34007015"
            },
            "centroide":{
               "lat":-23.9376982637237,
               "lon":-60.7400985635583
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Chiriguanos",
               "id":"345007"
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"LOS CHIRIGUANOS",
            "id":"34007020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Los Chiriguanos",
               "id":"34007020"
            },
            "centroide":{
               "lat":-24.0986835294011,
               "lon":-61.4675867695849
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"POZO DE MAZA",
            "id":"34007030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Pozo de Maza",
               "id":"34007030"
            },
            "centroide":{
               "lat":-23.5691832483278,
               "lon":-61.7055334817331
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"POZO DEL MORTERO",
            "id":"34007040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Pozo del Mortero",
               "id":"34007040"
            },
            "centroide":{
               "lat":-24.4064403681891,
               "lon":-61.031313748118
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bermejo",
               "id":"34007"
            },
            "nombre":"VACA PERDIDA",
            "id":"34007050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Vaca Perdida",
               "id":"34007050"
            },
            "centroide":{
               "lat":-23.4948646893285,
               "lon":-61.6499982689001
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Pastoril",
               "id":"345014"
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"COLONIA PASTORIL",
            "id":"34014010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Colonia Pastoril",
               "id":"34014010"
            },
            "centroide":{
               "lat":-25.6701404606648,
               "lon":-58.2626302834096
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Formosa",
               "id":"340028"
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"FORMOSA",
            "id":"34014020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Formosa",
               "id":"34014020"
            },
            "centroide":{
               "lat":-26.1828223055764,
               "lon":-58.1733930549121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gran Guardia",
               "id":"345021"
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"GRAN GUARDIA",
            "id":"34014030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Gran Guardia",
               "id":"34014030"
            },
            "centroide":{
               "lat":-25.8628496341956,
               "lon":-58.8859915019649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"MARIANO BOEDO",
            "id":"34014040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Mariano Boedo",
               "id":"34014040"
            },
            "centroide":{
               "lat":-26.1106357408727,
               "lon":-58.4884270518243
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"MOJON DE FIERRO",
            "id":"34014050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Mojón de Fierro",
               "id":"34014050"
            },
            "centroide":{
               "lat":-26.0345119719636,
               "lon":-58.0499307616902
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Hilario",
               "id":"345028"
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"SAN HILARIO",
            "id":"34014060000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"San Hilario",
               "id":"34014060"
            },
            "centroide":{
               "lat":-26.0248676792093,
               "lon":-58.6500726228772
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Formosa",
               "id":"340028"
            },
            "departamento":{
               "nombre":"Formosa",
               "id":"34014"
            },
            "nombre":"VILLA DEL CARMEN",
            "id":"34014070000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Villa del Carmen",
               "id":"34014070"
            },
            "centroide":{
               "lat":-26.2522590977589,
               "lon":-58.252552869015
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Laishí",
               "id":"34021"
            },
            "nombre":"BANCO PAYAGUA",
            "id":"34021010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Banco Payaguá",
               "id":"34021010"
            },
            "centroide":{
               "lat":-26.7071297025561,
               "lon":-58.3390745827256
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Lucio Victorio Mansilla",
               "id":"340049"
            },
            "departamento":{
               "nombre":"Laishí",
               "id":"34021"
            },
            "nombre":"GENERAL LUCIO V MANSILLA",
            "id":"34021020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"General Lucio V. Mansilla",
               "id":"34021020"
            },
            "centroide":{
               "lat":-26.654180225538,
               "lon":-58.6293214756534
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Herradura",
               "id":"340056"
            },
            "departamento":{
               "nombre":"Laishí",
               "id":"34021"
            },
            "nombre":"HERRADURA",
            "id":"34021030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Herradura",
               "id":"34021030"
            },
            "centroide":{
               "lat":-26.4874646396691,
               "lon":-58.3122424597995
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Misión San Francisco de Laishí",
               "id":"340063"
            },
            "departamento":{
               "nombre":"Laishí",
               "id":"34021"
            },
            "nombre":"SAN FRANCISCO DE LAISHI",
            "id":"34021040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"San Francisco de Laishi",
               "id":"34021040"
            },
            "centroide":{
               "lat":-26.2423010252437,
               "lon":-58.6300642415106
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Laishí",
               "id":"34021"
            },
            "nombre":"TATANE",
            "id":"34021050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Tatané",
               "id":"34021050"
            },
            "centroide":{
               "lat":-26.3987186827879,
               "lon":-58.3575600493034
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Escolar",
               "id":"340070"
            },
            "departamento":{
               "nombre":"Laishí",
               "id":"34021"
            },
            "nombre":"VILLA ESCOLAR",
            "id":"34021060000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Villa Escolar",
               "id":"34021060"
            },
            "centroide":{
               "lat":-26.6211844819298,
               "lon":-58.6718336940894
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ingeniero Guillermo N. Juárez",
               "id":"340077"
            },
            "departamento":{
               "nombre":"Matacos",
               "id":"34028"
            },
            "nombre":"INGENIERO GUILLERMO N. JUAREZ",
            "id":"34028010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Guillermo N. Juárez",
               "id":"34028010"
            },
            "centroide":{
               "lat":-23.8976004129254,
               "lon":-61.8538220483946
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"BARTOLOME DE LAS CASAS",
            "id":"34035010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Bartolomé de las Casas",
               "id":"34035010"
            },
            "centroide":{
               "lat":-25.3474022268881,
               "lon":-59.6181476016645
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"BARTOLOME DE LAS CASAS",
            "id":"34035010001",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Bartolomé de las Casas",
               "id":"34035010"
            },
            "centroide":{
               "lat":-25.4941218117267,
               "lon":-59.6120583687409
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"COMUNIDAD ABORIGEN BARTOLOME DE LAS CASAS",
            "id":"34035010002",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Bartolomé de las Casas",
               "id":"34035010"
            },
            "centroide":{
               "lat":-25.3482562961789,
               "lon":-59.6147617547837
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"COLONIA SARMIENTO",
            "id":"34035020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Colonia Sarmiento",
               "id":"34035020"
            },
            "centroide":{
               "lat":-24.6444789665474,
               "lon":-59.4332808273711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comandante Fontana",
               "id":"340091"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"COMANDANTE FONTANA",
            "id":"34035030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Comandante Fontana",
               "id":"34035030"
            },
            "centroide":{
               "lat":-25.3347934740298,
               "lon":-59.6828071882955
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"EL RECREO",
            "id":"34035040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"El Recreo",
               "id":"34035040"
            },
            "centroide":{
               "lat":-25.0663152545433,
               "lon":-59.2513514627224
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estanislao del Campo",
               "id":"340098"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"ESTANISLAO DEL CAMPO",
            "id":"34035050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Estanislao del Campo",
               "id":"34035050"
            },
            "centroide":{
               "lat":-25.0531922647351,
               "lon":-60.0939271832043
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fortín Lugones",
               "id":"345042"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"FORTIN CABO 1° LUGONES",
            "id":"34035060000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Fortín Cabo 1º Lugones",
               "id":"34035060"
            },
            "centroide":{
               "lat":-24.2854864616665,
               "lon":-59.8282499173384
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"FORTIN SARGENTO 1° LEYES",
            "id":"34035070000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Fortín Sargento 1º Leyes",
               "id":"34035070"
            },
            "centroide":{
               "lat":-24.5503048524758,
               "lon":-59.390271557059
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ibarreta",
               "id":"340126"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"IBARRETA",
            "id":"34035080000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Ibarreta",
               "id":"34035080"
            },
            "centroide":{
               "lat":-25.2113191232301,
               "lon":-59.8570398440914
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"JUAN G. BAZAN",
            "id":"34035090000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Juan G. Bazán",
               "id":"34035090"
            },
            "centroide":{
               "lat":-24.5418137126365,
               "lon":-60.8341896995338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Lomitas",
               "id":"340133"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"LAS LOMITAS",
            "id":"34035100000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Las Lomitas",
               "id":"34035100"
            },
            "centroide":{
               "lat":-24.7105776136691,
               "lon":-60.5937892586839
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"POSTA CAMBIO ZALAZAR",
            "id":"34035110000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Posta Cambio Zalazar",
               "id":"34035110"
            },
            "centroide":{
               "lat":-24.2115848629554,
               "lon":-60.198666969414
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pozo del Tigre",
               "id":"340140"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"POZO DEL TIGRE",
            "id":"34035120000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Pozo del Tigre",
               "id":"34035120"
            },
            "centroide":{
               "lat":-24.8975997693171,
               "lon":-60.3187890218463
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"SAN MARTIN I",
            "id":"34035130000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"San Martín I",
               "id":"34035130"
            },
            "centroide":{
               "lat":-24.5319053014019,
               "lon":-59.902051229705
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín Dos",
               "id":"340147"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"SAN MARTIN II",
            "id":"34035140000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"San Martín II",
               "id":"34035140"
            },
            "centroide":{
               "lat":-24.4331529800639,
               "lon":-59.656351890373
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Subteniente Perín",
               "id":"345049"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"SUBTENIENTE PERIN",
            "id":"34035150000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Subteniente Perín",
               "id":"34035150"
            },
            "centroide":{
               "lat":-25.5350397293764,
               "lon":-60.0190827630116
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Güemes",
               "id":"340119"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"VILLA GENERAL GUEMES",
            "id":"34035160000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Villa General Güemes",
               "id":"34035160"
            },
            "centroide":{
               "lat":-24.7529456908497,
               "lon":-59.4916405606824
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"340112"
            },
            "departamento":{
               "nombre":"Patiño",
               "id":"34035"
            },
            "nombre":"VILLA GENERAL MANUEL BELGRANO",
            "id":"34035170000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Villa General Manuel Belgrano",
               "id":"34035170"
            },
            "centroide":{
               "lat":-24.9388283555888,
               "lon":-59.0290877277901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Buena Vista",
               "id":"345056"
            },
            "departamento":{
               "nombre":"Pilagás",
               "id":"34042"
            },
            "nombre":"BUENA VISTA",
            "id":"34042010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Buena Vista",
               "id":"34042010"
            },
            "centroide":{
               "lat":-25.0712122663672,
               "lon":-58.3867799081037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Espinillo",
               "id":"340168"
            },
            "departamento":{
               "nombre":"Pilagás",
               "id":"34042"
            },
            "nombre":"EL ESPINILLO",
            "id":"34042020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"El Espinillo",
               "id":"34042020"
            },
            "centroide":{
               "lat":-24.9799888263685,
               "lon":-58.5537877142505
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pilagás",
               "id":"34042"
            },
            "nombre":"LAGUNA GALLO",
            "id":"34042030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Laguna Gallo",
               "id":"34042030"
            },
            "centroide":{
               "lat":-25.2677752833691,
               "lon":-58.7427948347143
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Misión Taacaglé",
               "id":"340175"
            },
            "departamento":{
               "nombre":"Pilagás",
               "id":"34042"
            },
            "nombre":"MISION TACAAGLE",
            "id":"34042040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Misión Tacaaglé",
               "id":"34042040"
            },
            "centroide":{
               "lat":-24.979650567657,
               "lon":-58.8227434439732
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Portón Negro",
               "id":"349105"
            },
            "departamento":{
               "nombre":"Pilagás",
               "id":"34042"
            },
            "nombre":"PORTON NEGRO",
            "id":"34042050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Portón Negro",
               "id":"34042050"
            },
            "centroide":{
               "lat":-24.9663598975615,
               "lon":-58.7419749100008
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Lagunas",
               "id":"345063"
            },
            "departamento":{
               "nombre":"Pilagás",
               "id":"34042"
            },
            "nombre":"TRES LAGUNAS",
            "id":"34042060000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Tres Lagunas",
               "id":"34042060"
            },
            "centroide":{
               "lat":-25.2153644371658,
               "lon":-58.5132694906705
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Clorinda",
               "id":"340189"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"CLORINDA",
            "id":"34049010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Clorinda",
               "id":"34049010"
            },
            "centroide":{
               "lat":-25.2921612747741,
               "lon":-57.7178091082588
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Blanca",
               "id":"340169"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"LAGUNA BLANCA",
            "id":"34049020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Laguna Blanca",
               "id":"34049020"
            },
            "centroide":{
               "lat":-25.1302522059542,
               "lon":-58.2458430258241
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Naik Neck",
               "id":"340203"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"LAGUNA NAICK-NECK",
            "id":"34049030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Laguna Naick-Neck",
               "id":"34049030"
            },
            "centroide":{
               "lat":-25.2193910506962,
               "lon":-58.1230791260523
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"PALMA SOLA",
            "id":"34049040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Palma Sola",
               "id":"34049040"
            },
            "centroide":{
               "lat":-25.2475512345955,
               "lon":-57.9768403788083
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Clorinda",
               "id":"340189"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"PUERTO PILCOMAYO",
            "id":"34049050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Puerto Pilcomayo",
               "id":"34049050"
            },
            "centroide":{
               "lat":-25.3688131700972,
               "lon":-57.6524943008573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Riacho He-He",
               "id":"340210"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"RIACHO HE-HE",
            "id":"34049060000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Riacho He-He",
               "id":"34049060"
            },
            "centroide":{
               "lat":-25.3617117019838,
               "lon":-58.2782518825352
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Clorinda",
               "id":"340189"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"RIACHO NEGRO",
            "id":"34049070000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Riacho Negro",
               "id":"34049070"
            },
            "centroide":{
               "lat":-25.4224320225992,
               "lon":-57.7914545139812
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Siete Palmas",
               "id":"345070"
            },
            "departamento":{
               "nombre":"Pilcomayo",
               "id":"34049"
            },
            "nombre":"SIETE PALMAS",
            "id":"34049080000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Siete Palmas",
               "id":"34049080"
            },
            "centroide":{
               "lat":-25.2015076619063,
               "lon":-58.330676377416
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mayor Vicente E. Villafañe",
               "id":"340231"
            },
            "departamento":{
               "nombre":"Pirané",
               "id":"34056"
            },
            "nombre":"COLONIA CAMPO VILLAFAÑE",
            "id":"34056010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Colonia Campo Villafañe",
               "id":"34056010"
            },
            "centroide":{
               "lat":-26.2048681517277,
               "lon":-59.0780331803401
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Colorado",
               "id":"340224"
            },
            "departamento":{
               "nombre":"Pirané",
               "id":"34056"
            },
            "nombre":"EL COLORADO",
            "id":"34056020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"El Colorado",
               "id":"34056020"
            },
            "centroide":{
               "lat":-26.3117354640006,
               "lon":-59.3684434678409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palo Santo",
               "id":"340238"
            },
            "departamento":{
               "nombre":"Pirané",
               "id":"34056"
            },
            "nombre":"PALO SANTO",
            "id":"34056030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Palo Santo",
               "id":"34056030"
            },
            "centroide":{
               "lat":-25.5639063797961,
               "lon":-59.3360059836081
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pirané",
               "id":"340245"
            },
            "departamento":{
               "nombre":"Pirané",
               "id":"34056"
            },
            "nombre":"PIRANE",
            "id":"34056040000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Pirané",
               "id":"34056040"
            },
            "centroide":{
               "lat":-25.7306417902346,
               "lon":-59.1075205904775
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Dos Trece",
               "id":"340252"
            },
            "departamento":{
               "nombre":"Pirané",
               "id":"34056"
            },
            "nombre":"VILLA KILOMETRO 213",
            "id":"34056050000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"Villa Kilómetro 213",
               "id":"34056050"
            },
            "centroide":{
               "lat":-26.1864626491659,
               "lon":-59.3683950578867
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ramón Lista",
               "id":"34063"
            },
            "nombre":"EL POTRILLO",
            "id":"34063010000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"El Potrillo",
               "id":"34063010"
            },
            "centroide":{
               "lat":-23.1607663192891,
               "lon":-62.0107787024286
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"340259"
            },
            "departamento":{
               "nombre":"Ramón Lista",
               "id":"34063"
            },
            "nombre":"GENERAL MOSCONI",
            "id":"34063020000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"General Mosconi",
               "id":"34063020"
            },
            "centroide":{
               "lat":-23.1827186246626,
               "lon":-62.304068544241
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ramón Lista",
               "id":"34063"
            },
            "nombre":"EL QUEBRACHO",
            "id":"34063030000",
            "provincia":{
               "nombre":"Formosa",
               "id":"34"
            },
            "localidad_censal":{
               "nombre":"El Quebracho",
               "id":"34063030"
            },
            "centroide":{
               "lat":-23.3394869143818,
               "lon":-61.8734659544108
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abdón Castro Tolay",
               "id":"386007"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"ABDON CASTRO TOLAY",
            "id":"38007010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Abdón Castro Tolay",
               "id":"38007010"
            },
            "centroide":{
               "lat":-23.3419368713503,
               "lon":-66.0901014487309
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abra Pampa",
               "id":"380014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"ABRA PAMPA",
            "id":"38007020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Abra Pampa",
               "id":"38007020"
            },
            "centroide":{
               "lat":-22.7223526298672,
               "lon":-65.6965045525232
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abralaite",
               "id":"386014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"ABRALAITE",
            "id":"38007030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Abralaite",
               "id":"38007030"
            },
            "centroide":{
               "lat":-23.1692063444784,
               "lon":-65.7944004862785
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abralaite",
               "id":"386014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"AGUA DE CASTILLA",
            "id":"38007035000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Agua de Castilla",
               "id":"38007035"
            },
            "centroide":{
               "lat":-23.2167374833476,
               "lon":-65.8082738847531
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abra Pampa",
               "id":"380014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"CASABINDO",
            "id":"38007040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Casabindo",
               "id":"38007040"
            },
            "centroide":{
               "lat":-22.9850219724833,
               "lon":-66.0328013633041
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abra Pampa",
               "id":"380014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"COCHINOCA",
            "id":"38007050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Cochinoca",
               "id":"38007050"
            },
            "centroide":{
               "lat":-22.744265021174,
               "lon":-65.8951221139841
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puesto del Marqués",
               "id":"386021"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"LA REDONDA",
            "id":"38007055000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Redonda",
               "id":"38007055"
            },
            "centroide":{
               "lat":-22.5294608585997,
               "lon":-65.5222832606241
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puesto del Marqués",
               "id":"386021"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"PUESTO DEL MARQUEZ",
            "id":"38007060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Puesto del Marquéz",
               "id":"38007060"
            },
            "centroide":{
               "lat":-22.5384288906185,
               "lon":-65.6978923774993
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abralaite",
               "id":"386014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"QUEBRALEÑA",
            "id":"38007063000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Quebraleña",
               "id":"38007063"
            },
            "centroide":{
               "lat":-23.2857891128733,
               "lon":-65.7701494793729
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abralaite",
               "id":"386014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"QUERA",
            "id":"38007067000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Quera",
               "id":"38007067"
            },
            "centroide":{
               "lat":-23.100830176506,
               "lon":-65.7627638222671
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abdón Castro Tolay",
               "id":"386007"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"RINCONADILLAS",
            "id":"38007070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Rinconadillas",
               "id":"38007070"
            },
            "centroide":{
               "lat":-23.3846029198326,
               "lon":-65.9573754338264
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abdón Castro Tolay",
               "id":"386007"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"SAN FRANCISCO DE ALFARCITO",
            "id":"38007080000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Francisco de Alfarcito",
               "id":"38007080"
            },
            "centroide":{
               "lat":-23.3198701399198,
               "lon":-65.9744058851841
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abdón Castro Tolay",
               "id":"386007"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"SANTA ANA DE LA PUNA",
            "id":"38007085000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Santa Ana de la Puna",
               "id":"38007085"
            },
            "centroide":{
               "lat":-23.1248833823314,
               "lon":-66.05216103631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abdón Castro Tolay",
               "id":"386007"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"SANTUARIO DE TRES POZOS",
            "id":"38007090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Santuario de Tres Pozos",
               "id":"38007090"
            },
            "centroide":{
               "lat":-23.5332448464011,
               "lon":-65.9632841858024
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abra Pampa",
               "id":"380014"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"TAMBILLOS",
            "id":"38007095000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Tambillos",
               "id":"38007095"
            },
            "centroide":{
               "lat":-22.896870936112,
               "lon":-65.9620225137437
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abdón Castro Tolay",
               "id":"386007"
            },
            "departamento":{
               "nombre":"Cochinoca",
               "id":"38007"
            },
            "nombre":"TUSAQUILLAS",
            "id":"38007100000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Tusaquillas",
               "id":"38007100"
            },
            "centroide":{
               "lat":-23.1901128134571,
               "lon":-65.9890162373631
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguas Calientes",
               "id":"386035"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"AGUAS CALIENTES",
            "id":"38014010001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Aguas Calientes",
               "id":"38014010"
            },
            "centroide":{
               "lat":-24.5838759624546,
               "lon":-64.9235272487523
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguas Calientes",
               "id":"386035"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"FLEMING",
            "id":"38014010002",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Aguas Calientes",
               "id":"38014010"
            },
            "centroide":{
               "lat":-24.587376342521,
               "lon":-64.924057792361
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguas Calientes",
               "id":"386035"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"PILA PARDO",
            "id":"38014010003",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Aguas Calientes",
               "id":"38014010"
            },
            "centroide":{
               "lat":-24.5598827881681,
               "lon":-64.9141975517301
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monterrico",
               "id":"380063"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"BARRIO EL MILAGRO",
            "id":"38014020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Barrio El Milagro",
               "id":"38014020"
            },
            "centroide":{
               "lat":-24.4610014305425,
               "lon":-65.1190583597652
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Perico",
               "id":"380077"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"BARRIO LA UNION",
            "id":"38014030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Barrio La Unión",
               "id":"38014030"
            },
            "centroide":{
               "lat":-24.4043300097514,
               "lon":-65.0697401829896
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Carmen",
               "id":"380056"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"EL CARMEN",
            "id":"38014040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Carmen",
               "id":"38014040"
            },
            "centroide":{
               "lat":-24.3891095885225,
               "lon":-65.2605453068598
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monterrico",
               "id":"380063"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"LOS LAPACHOS",
            "id":"38014050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Los Lapachos",
               "id":"38014050"
            },
            "centroide":{
               "lat":-24.4759200783409,
               "lon":-65.0776012050617
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monterrico",
               "id":"380063"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"SAN VICENTE",
            "id":"38014055000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Loteo San Vicente",
               "id":"38014055"
            },
            "centroide":{
               "lat":-24.4293248380693,
               "lon":-65.1843971543033
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puesto Viejo",
               "id":"386049"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"MANANTIALES",
            "id":"38014060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Manantiales",
               "id":"38014060"
            },
            "centroide":{
               "lat":-24.5336970280067,
               "lon":-64.9771546670321
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monterrico",
               "id":"380063"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"MONTERRICO",
            "id":"38014070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Monterrico",
               "id":"38014070"
            },
            "centroide":{
               "lat":-24.4421177051028,
               "lon":-65.1622505537209
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampa Blanca",
               "id":"386042"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"PAMPA BLANCA",
            "id":"38014080000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Pampa Blanca",
               "id":"38014080"
            },
            "centroide":{
               "lat":-24.5325323540548,
               "lon":-65.0742522158109
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Perico",
               "id":"380077"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"PERICO",
            "id":"38014090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Perico",
               "id":"38014090"
            },
            "centroide":{
               "lat":-24.3807802774746,
               "lon":-65.1162073560982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puesto Viejo",
               "id":"386049"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"PUESTO VIEJO",
            "id":"38014100000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Puesto Viejo",
               "id":"38014100"
            },
            "centroide":{
               "lat":-24.485003513907,
               "lon":-64.9670262152006
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguas Calientes",
               "id":"386035"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"SAN ISIDRO",
            "id":"38014110000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"38014110"
            },
            "centroide":{
               "lat":-24.5556368462513,
               "lon":-64.9424207944567
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Perico",
               "id":"380077"
            },
            "departamento":{
               "nombre":"El Carmen",
               "id":"38014"
            },
            "nombre":"SAN JUANCITO",
            "id":"38014120000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Juancito",
               "id":"38014120"
            },
            "centroide":{
               "lat":-24.3843856940085,
               "lon":-64.9937381095943
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Salvador de Jujuy",
               "id":"380035"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"GUERRERO",
            "id":"38021010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Guerrero",
               "id":"38021010"
            },
            "centroide":{
               "lat":-24.1859719091941,
               "lon":-65.4487115603426
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Salvador de Jujuy",
               "id":"380035"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"LA ALMONA",
            "id":"38021020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Almona",
               "id":"38021020"
            },
            "centroide":{
               "lat":-24.2656799176145,
               "lon":-65.3972038835293
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"LEON",
            "id":"38021030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"León",
               "id":"38021030"
            },
            "centroide":{
               "lat":-24.0395227299023,
               "lon":-65.4309577730475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"LOZANO",
            "id":"38021040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Lozano",
               "id":"38021040"
            },
            "centroide":{
               "lat":-24.0820553565565,
               "lon":-65.4037790153252
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"OCLOYAS",
            "id":"38021050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Ocloyas",
               "id":"38021050"
            },
            "centroide":{
               "lat":-23.9440584396018,
               "lon":-65.2322154882925
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Salvador de Jujuy",
               "id":"380035"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"SAN SALVADOR DE JUJUY",
            "id":"38021060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Salvador de Jujuy",
               "id":"38021060"
            },
            "centroide":{
               "lat":-24.1844139008337,
               "lon":-65.3039986701092
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"TESORERO",
            "id":"38021065000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Tesorero",
               "id":"38021065"
            },
            "centroide":{
               "lat":-23.9486188183993,
               "lon":-65.2985111585154
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"LOS NOGALES",
            "id":"38021070001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yala",
               "id":"38021070"
            },
            "centroide":{
               "lat":-24.0840270539675,
               "lon":-65.4760846258348
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"SAN PABLO DE REYES",
            "id":"38021070002",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yala",
               "id":"38021070"
            },
            "centroide":{
               "lat":-24.1436588948106,
               "lon":-65.482919981479
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yala",
               "id":"386028"
            },
            "departamento":{
               "nombre":"Dr. Manuel Belgrano",
               "id":"38021"
            },
            "nombre":"YALA",
            "id":"38021070003",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yala",
               "id":"38021070"
            },
            "centroide":{
               "lat":-24.1217546163942,
               "lon":-65.4145726152103
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"APARZO",
            "id":"38028003000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Aparzo",
               "id":"38028003"
            },
            "centroide":{
               "lat":-23.0992150250753,
               "lon":-65.1865385997584
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"CIANZO",
            "id":"38028007000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Cianzo",
               "id":"38028007"
            },
            "centroide":{
               "lat":-23.1732331272464,
               "lon":-65.1578176759527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"COCTACA",
            "id":"38028010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Coctaca",
               "id":"38028010"
            },
            "centroide":{
               "lat":-23.1543348556287,
               "lon":-65.2936644075552
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Aguilar",
               "id":"380091"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"EL AGUILAR",
            "id":"38028020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Aguilar",
               "id":"38028020"
            },
            "centroide":{
               "lat":-23.2139846798174,
               "lon":-65.6788754303526
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Yrigoyen",
               "id":"386056"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"HIPOLITO YRIGOYEN",
            "id":"38028030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Hipólito Yrigoyen",
               "id":"38028030"
            },
            "centroide":{
               "lat":-22.9790987916947,
               "lon":-65.3527979015935
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"HUMAHUACA",
            "id":"38028040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Humahuaca",
               "id":"38028040"
            },
            "centroide":{
               "lat":-23.2118256384836,
               "lon":-65.3507419495251
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"PALCA DE APARZO",
            "id":"38028043000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Palca de Aparzo",
               "id":"38028043"
            },
            "centroide":{
               "lat":-23.1220406414082,
               "lon":-65.134806337775
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"PALCA DE VARAS",
            "id":"38028045000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Palca de Varas",
               "id":"38028045"
            },
            "centroide":{
               "lat":-23.1018612642193,
               "lon":-65.112920847023
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"RODERO",
            "id":"38028047000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Rodero",
               "id":"38028047"
            },
            "centroide":{
               "lat":-23.0621839336364,
               "lon":-65.3260425196353
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Cruces",
               "id":"386063"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"TRES CRUCES",
            "id":"38028050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Tres Cruces",
               "id":"38028050"
            },
            "centroide":{
               "lat":-22.9169380002989,
               "lon":-65.5878327366614
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humahuaca",
               "id":"380105"
            },
            "departamento":{
               "nombre":"Humahuaca",
               "id":"38028"
            },
            "nombre":"UQUIA",
            "id":"38028060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Uquía",
               "id":"38028060"
            },
            "centroide":{
               "lat":-23.3041905968327,
               "lon":-65.356558164038
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yuto",
               "id":"380147"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"BANANAL",
            "id":"38035010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Bananal",
               "id":"38035010"
            },
            "centroide":{
               "lat":-23.565439613584,
               "lon":-64.5011631978961
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calilegua",
               "id":"380126"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"BERMEJITO",
            "id":"38035020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Bermejito",
               "id":"38035020"
            },
            "centroide":{
               "lat":-23.7881113454008,
               "lon":-64.73005792215
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caimancito",
               "id":"380119"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"CAIMANCITO",
            "id":"38035030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Caimancito",
               "id":"38035030"
            },
            "centroide":{
               "lat":-23.7390273358675,
               "lon":-64.5927674367986
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calilegua",
               "id":"380126"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"CALILEGUA",
            "id":"38035040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Calilegua",
               "id":"38035040"
            },
            "centroide":{
               "lat":-23.77580785145,
               "lon":-64.7714502012243
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fraile Pintado",
               "id":"380133"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"CHALICAN",
            "id":"38035050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Chalicán",
               "id":"38035050"
            },
            "centroide":{
               "lat":-24.0701319470485,
               "lon":-64.8068636697633
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fraile Pintado",
               "id":"380133"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"FRAILE PINTADO",
            "id":"38035060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Fraile Pintado",
               "id":"38035060"
            },
            "centroide":{
               "lat":-23.9420214191485,
               "lon":-64.8029446573235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calilegua",
               "id":"380126"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"LIBERTAD",
            "id":"38035070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Libertad",
               "id":"38035070"
            },
            "centroide":{
               "lat":-23.7623858141283,
               "lon":-64.7259945367992
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Libertador General San Martín",
               "id":"380140"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"LIBERTADOR GENERAL SAN MARTIN",
            "id":"38035080000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Libertador General San Martín",
               "id":"38035080"
            },
            "centroide":{
               "lat":-23.8091072493712,
               "lon":-64.7927821518223
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Libertador General San Martín",
               "id":"380140"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"LIBERTADOR GENERAL SAN MARTIN",
            "id":"38035080001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Libertador General San Martín",
               "id":"38035080"
            },
            "centroide":{
               "lat":-23.8226030728583,
               "lon":-64.9000065559959
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Libertador General San Martín",
               "id":"380140"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"PUEBLO LEDESMA",
            "id":"38035080002",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Libertador General San Martín",
               "id":"38035080"
            },
            "centroide":{
               "lat":-23.8273072049676,
               "lon":-64.791360563704
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fraile Pintado",
               "id":"380133"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"MAIZ NEGRO",
            "id":"38035090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Maíz Negro",
               "id":"38035090"
            },
            "centroide":{
               "lat":-23.9506323797642,
               "lon":-64.7736232797616
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Libertador General San Martín",
               "id":"380140"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"PAULINA",
            "id":"38035100000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Paulina",
               "id":"38035100"
            },
            "centroide":{
               "lat":-23.8445381270952,
               "lon":-64.7389846268417
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yuto",
               "id":"380147"
            },
            "departamento":{
               "nombre":"Ledesma",
               "id":"38035"
            },
            "nombre":"YUTO",
            "id":"38035110000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yuto",
               "id":"38035110"
            },
            "centroide":{
               "lat":-23.6473910528443,
               "lon":-64.4683378490048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palpalá",
               "id":"380154"
            },
            "departamento":{
               "nombre":"Palpalá",
               "id":"38042"
            },
            "nombre":"CARAHUNCO",
            "id":"38042010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Carahunco",
               "id":"38042010"
            },
            "centroide":{
               "lat":-24.3076479727425,
               "lon":-65.0834468981217
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palpalá",
               "id":"380154"
            },
            "departamento":{
               "nombre":"Palpalá",
               "id":"38042"
            },
            "nombre":"CENTRO FORESTAL",
            "id":"38042020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Centro Forestal",
               "id":"38042020"
            },
            "centroide":{
               "lat":-24.2287296415511,
               "lon":-65.1776468508489
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palpalá",
               "id":"380154"
            },
            "departamento":{
               "nombre":"Palpalá",
               "id":"38042"
            },
            "nombre":"PALPALA",
            "id":"38042040001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Palpalá",
               "id":"38042040"
            },
            "centroide":{
               "lat":-24.2660503354304,
               "lon":-65.2120271030598
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palpalá",
               "id":"380154"
            },
            "departamento":{
               "nombre":"Palpalá",
               "id":"38042"
            },
            "nombre":"RIO BLANCO",
            "id":"38042040002",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Palpalá",
               "id":"38042040"
            },
            "centroide":{
               "lat":-24.2342342593785,
               "lon":-65.2300747773282
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rinconada",
               "id":"386077"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"CASA COLORADA",
            "id":"38049003000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Casa Colorada",
               "id":"38049003"
            },
            "centroide":{
               "lat":-22.3253946072654,
               "lon":-66.2837654791341
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mina Pirquitas",
               "id":"386070"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"COYAGUAIMA",
            "id":"38049007000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Coyaguaima",
               "id":"38049007"
            },
            "centroide":{
               "lat":-22.7704435619992,
               "lon":-66.440426993556
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cusi Cusi",
               "id":"386140"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"LAGUNILLAS DE FARALLON",
            "id":"38049010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Lagunillas de Farallón",
               "id":"38049010"
            },
            "centroide":{
               "lat":-22.4621492240335,
               "lon":-66.6281060495323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mina Pirquitas",
               "id":"386070"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"LIVIARA",
            "id":"38049020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Liviara",
               "id":"38049020"
            },
            "centroide":{
               "lat":-22.5205427089875,
               "lon":-66.3370942259576
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mina Pirquitas",
               "id":"386070"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"LOMA BLANCA",
            "id":"38049025000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Loma Blanca",
               "id":"38049025"
            },
            "centroide":{
               "lat":-22.4715310141327,
               "lon":-66.4842002316312
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mina Pirquitas",
               "id":"386070"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"NUEVO PIRQUITAS",
            "id":"38049030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Nuevo Pirquitas",
               "id":"38049030"
            },
            "centroide":{
               "lat":-22.6879554218994,
               "lon":-66.4563756677863
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mina Pirquitas",
               "id":"386070"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"OROSMAYO",
            "id":"38049035000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Orosmayo",
               "id":"38049035"
            },
            "centroide":{
               "lat":-22.5570555914003,
               "lon":-66.3563627312909
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rinconada",
               "id":"386077"
            },
            "departamento":{
               "nombre":"Rinconada",
               "id":"38049"
            },
            "nombre":"RINCONADA",
            "id":"38049040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Rinconada",
               "id":"38049040"
            },
            "centroide":{
               "lat":-22.4403477816105,
               "lon":-66.1673735179392
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"386084"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"38056"
            },
            "nombre":"EL CEIBAL",
            "id":"38056010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Ceibal",
               "id":"38056010"
            },
            "centroide":{
               "lat":-24.3013918074401,
               "lon":-65.2794378200693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"386084"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"38056"
            },
            "nombre":"LOS ALISOS",
            "id":"38056017000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Los Alisos",
               "id":"38056017"
            },
            "centroide":{
               "lat":-24.2694294471429,
               "lon":-65.3038293509073
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"386084"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"38056"
            },
            "nombre":"LOTEO NAVEA",
            "id":"38056020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Loteo Navea",
               "id":"38056020"
            },
            "centroide":{
               "lat":-24.2701433897522,
               "lon":-65.2740648045537
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"386084"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"38056"
            },
            "nombre":"NUESTRA SE?ORA DEL ROSARIO",
            "id":"38056025000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Nuestra Señora del Rosario",
               "id":"38056025"
            },
            "centroide":{
               "lat":-24.3184045981955,
               "lon":-65.400922691233
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"386084"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"38056"
            },
            "nombre":"SAN ANTONIO",
            "id":"38056030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"38056030"
            },
            "centroide":{
               "lat":-24.3672400343176,
               "lon":-65.3377007673345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrayanal",
               "id":"386091"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"ARRAYANAL",
            "id":"38063010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Arrayanal",
               "id":"38063010"
            },
            "centroide":{
               "lat":-24.1768075323298,
               "lon":-64.8403333563822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro de Jujuy",
               "id":"380224"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"ARROYO COLORADO",
            "id":"38063020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Arroyo Colorado",
               "id":"38063020"
            },
            "centroide":{
               "lat":-24.3365556568725,
               "lon":-64.6635805195007
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de Río Grande",
               "id":"386112"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"DON EMILIO",
            "id":"38063030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Don Emilio",
               "id":"38063030"
            },
            "centroide":{
               "lat":-24.3167328574824,
               "lon":-64.9078893331684
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro de Jujuy",
               "id":"380224"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"EL ACHERAL",
            "id":"38063040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Acheral",
               "id":"38063040"
            },
            "centroide":{
               "lat":-24.4035872902004,
               "lon":-64.8010495450204
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Esperanza",
               "id":"380196"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"EL PUESTO",
            "id":"38063050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Puesto",
               "id":"38063050"
            },
            "centroide":{
               "lat":-24.1989198465405,
               "lon":-64.7892891441781
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrayanal",
               "id":"386091"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"EL QUEMADO",
            "id":"38063060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Quemado",
               "id":"38063060"
            },
            "centroide":{
               "lat":-24.1058565563838,
               "lon":-64.823062158756
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Esperanza",
               "id":"380196"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"LA ESPERANZA",
            "id":"38063070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Esperanza",
               "id":"38063070"
            },
            "centroide":{
               "lat":-24.2237083446708,
               "lon":-64.8373717906107
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrayanal",
               "id":"386091"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"LA ESPERANZA",
            "id":"38063070001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Esperanza",
               "id":"38063070"
            },
            "centroide":{
               "lat":-24.1691296094135,
               "lon":-64.7828725492928
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrayanal",
               "id":"386091"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"LA MANGA",
            "id":"38063080000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Manga",
               "id":"38063080"
            },
            "centroide":{
               "lat":-24.191027398496,
               "lon":-64.847140304232
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Mendieta",
               "id":"380203"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"LA MENDIETA",
            "id":"38063090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Mendieta",
               "id":"38063090"
            },
            "centroide":{
               "lat":-24.3164922129456,
               "lon":-64.9670192886888
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Esperanza",
               "id":"380196"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"MIRAFLORES",
            "id":"38063100000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Miraflores",
               "id":"38063100"
            },
            "centroide":{
               "lat":-24.2247852710015,
               "lon":-64.8201208754985
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de Río Grande",
               "id":"386112"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"PALOS BLANCOS",
            "id":"38063110000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Palos Blancos",
               "id":"38063110"
            },
            "centroide":{
               "lat":-24.3269093157963,
               "lon":-64.9472509634032
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Esperanza",
               "id":"380196"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"PARAPETI",
            "id":"38063120000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Parapetí",
               "id":"38063120"
            },
            "centroide":{
               "lat":-24.236443947266,
               "lon":-64.8448511951718
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de Río Grande",
               "id":"386112"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"PIEDRITAS",
            "id":"38063130000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Piedritas",
               "id":"38063130"
            },
            "centroide":{
               "lat":-24.2961714666534,
               "lon":-64.8821863479764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rodeíto",
               "id":"386105"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"RODEITO",
            "id":"38063140000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Rodeito",
               "id":"38063140"
            },
            "centroide":{
               "lat":-24.2716140097952,
               "lon":-64.768970964685
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de Río Grande",
               "id":"386112"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"ROSARIO DE RIO GRANDE",
            "id":"38063150000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Rosario de Río Grande (ex Barro Negro)",
               "id":"38063150"
            },
            "centroide":{
               "lat":-24.3085245850739,
               "lon":-64.9316446809993
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Esperanza",
               "id":"380196"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"SAN ANTONIO",
            "id":"38063160000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"38063160"
            },
            "centroide":{
               "lat":-24.219671559876,
               "lon":-64.8045172910273
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rodeíto",
               "id":"386105"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"SAN LUCAS",
            "id":"38063170000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Lucas",
               "id":"38063170"
            },
            "centroide":{
               "lat":-24.2685961573007,
               "lon":-64.8642893202695
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro de Jujuy",
               "id":"380224"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"SAN PEDRO",
            "id":"38063180000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"38063180"
            },
            "centroide":{
               "lat":-24.2313021129282,
               "lon":-64.8681693070463
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Mendieta",
               "id":"380203"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"38063"
            },
            "nombre":"SAUZAL",
            "id":"38063190000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Sauzal",
               "id":"38063190"
            },
            "centroide":{
               "lat":-24.2987583243037,
               "lon":-64.9571556712013
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Fuerte",
               "id":"386098"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"EL FUERTE",
            "id":"38070010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Fuerte",
               "id":"38070010"
            },
            "centroide":{
               "lat":-24.260560497029,
               "lon":-64.4155132039794
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Piquete",
               "id":"386119"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"EL PIQUETE",
            "id":"38070020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Piquete",
               "id":"38070020"
            },
            "centroide":{
               "lat":-24.1791481712823,
               "lon":-64.6776330087428
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Talar",
               "id":"380238"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"EL TALAR",
            "id":"38070030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Talar",
               "id":"38070030"
            },
            "centroide":{
               "lat":-23.5555611045113,
               "lon":-64.3643099072085
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palma Sola",
               "id":"380245"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"PALMA SOLA",
            "id":"38070040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Palma Sola",
               "id":"38070040"
            },
            "centroide":{
               "lat":-23.9678426401893,
               "lon":-64.3034683351392
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Clara",
               "id":"380252"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"PUENTE LAVAYEN",
            "id":"38070050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Puente Lavayén",
               "id":"38070050"
            },
            "centroide":{
               "lat":-24.2725269338812,
               "lon":-64.7161865903699
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Clara",
               "id":"380252"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"SANTA CLARA",
            "id":"38070060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Santa Clara",
               "id":"38070060"
            },
            "centroide":{
               "lat":-24.3078151766845,
               "lon":-64.6610648577922
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vinalito",
               "id":"386126"
            },
            "departamento":{
               "nombre":"Santa Bárbara",
               "id":"38070"
            },
            "nombre":"VINALITO",
            "id":"38070070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Vinalito",
               "id":"38070070"
            },
            "centroide":{
               "lat":-23.6681883228337,
               "lon":-64.4131178374229
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cieneguillas",
               "id":"386133"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"CASIRA",
            "id":"38077010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Casira",
               "id":"38077010"
            },
            "centroide":{
               "lat":-21.9788187103851,
               "lon":-65.8958558436093
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cusi Cusi",
               "id":"386140"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"CIENEGA",
            "id":"38077020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Ciénega de Paicone",
               "id":"38077020"
            },
            "centroide":{
               "lat":-22.1769689683858,
               "lon":-66.4139607069096
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cieneguillas",
               "id":"386133"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"CIENEGUILLAS",
            "id":"38077030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Cieneguillas",
               "id":"38077030"
            },
            "centroide":{
               "lat":-22.1009002170764,
               "lon":-65.867209693443
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cusi Cusi",
               "id":"386140"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"CUSI CUSI",
            "id":"38077040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Cusi Cusi",
               "id":"38077040"
            },
            "centroide":{
               "lat":-22.3402234558008,
               "lon":-66.4926397388327
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Catalina",
               "id":"386147"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"EL ANGOSTO",
            "id":"38077045000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Angosto",
               "id":"38077045"
            },
            "centroide":{
               "lat":-21.8764898712784,
               "lon":-66.1886427089451
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Catalina",
               "id":"386147"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"LA CIENEGA",
            "id":"38077050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Ciénega",
               "id":"38077050"
            },
            "centroide":{
               "lat":-21.9606332568994,
               "lon":-66.263084676456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cusi Cusi",
               "id":"386140"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"MISARRUMI",
            "id":"38077060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Misarrumi",
               "id":"38077060"
            },
            "centroide":{
               "lat":-22.2593865040609,
               "lon":-66.3668608819379
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Catalina",
               "id":"386147"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"ORATORIO",
            "id":"38077070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Oratorio",
               "id":"38077070"
            },
            "centroide":{
               "lat":-22.0991710039884,
               "lon":-66.1325561087962
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cusi Cusi",
               "id":"386140"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"PAICONE",
            "id":"38077080000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Paicone",
               "id":"38077080"
            },
            "centroide":{
               "lat":-22.2193202745887,
               "lon":-66.4252604137157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cusi Cusi",
               "id":"386140"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"SAN JUAN DE OROS",
            "id":"38077090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Juan de Oros",
               "id":"38077090"
            },
            "centroide":{
               "lat":-22.2243859011206,
               "lon":-66.2489981842614
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Catalina",
               "id":"386147"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"SANTA CATALINA",
            "id":"38077100000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Santa Catalina",
               "id":"38077100"
            },
            "centroide":{
               "lat":-21.9457402673178,
               "lon":-66.0524460482788
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cieneguillas",
               "id":"386133"
            },
            "departamento":{
               "nombre":"Santa Catalina",
               "id":"38077"
            },
            "nombre":"YOSCABA",
            "id":"38077110000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yoscaba",
               "id":"38077110"
            },
            "centroide":{
               "lat":-22.1671932548934,
               "lon":-66.0247356350832
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Catua",
               "id":"386154"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"CATUA",
            "id":"38084010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Catua",
               "id":"38084010"
            },
            "centroide":{
               "lat":-23.8697878292198,
               "lon":-67.0052751921339
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coranzuli",
               "id":"386161"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"CORANZULI",
            "id":"38084020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Coranzuli",
               "id":"38084020"
            },
            "centroide":{
               "lat":-23.0145482730193,
               "lon":-66.3666374411625
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coranzuli",
               "id":"386161"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"EL TORO",
            "id":"38084030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Toro",
               "id":"38084030"
            },
            "centroide":{
               "lat":-23.0836526940797,
               "lon":-66.7015910904293
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susques",
               "id":"386168"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"HUANCAR",
            "id":"38084040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Huáncar",
               "id":"38084040"
            },
            "centroide":{
               "lat":-23.5638430752997,
               "lon":-66.4093156495626
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coranzuli",
               "id":"386161"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"JAMA",
            "id":"38084045000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Jama",
               "id":"38084045"
            },
            "centroide":{
               "lat":-23.2381696240536,
               "lon":-67.0272646457146
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susques",
               "id":"386168"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"MINA PROVIDENCIA",
            "id":"38084050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Mina Providencia",
               "id":"38084050"
            },
            "centroide":{
               "lat":-23.2654412559675,
               "lon":-66.6556621241901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susques",
               "id":"386168"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"OLACAPATO",
            "id":"38084055000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Olacapato",
               "id":"38084055"
            },
            "centroide":{
               "lat":-24.1152805507803,
               "lon":-66.7186508694002
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coranzuli",
               "id":"386161"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"OLAROZ CHICO",
            "id":"38084060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Olaroz Chico",
               "id":"38084060"
            },
            "centroide":{
               "lat":-23.3934649138842,
               "lon":-66.8017391151812
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susques",
               "id":"386168"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"PASTOS CHICOS",
            "id":"38084070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Pastos Chicos",
               "id":"38084070"
            },
            "centroide":{
               "lat":-23.7667799522763,
               "lon":-66.4362241723231
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susques",
               "id":"386168"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"PUESTO SEY",
            "id":"38084080000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Puesto Sey",
               "id":"38084080"
            },
            "centroide":{
               "lat":-23.9450800745364,
               "lon":-66.4880705176388
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coranzuli",
               "id":"386161"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"SAN JUAN DE QUILLAQUES",
            "id":"38084090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Juan de Quillaqués",
               "id":"38084090"
            },
            "centroide":{
               "lat":-23.2303525084842,
               "lon":-66.3437115747179
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coranzuli",
               "id":"386161"
            },
            "departamento":{
               "nombre":"Susques",
               "id":"38084"
            },
            "nombre":"SUSQUES",
            "id":"38084100000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Susques",
               "id":"38084100"
            },
            "centroide":{
               "lat":-23.3993487788994,
               "lon":-66.3663509046119
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huacalera",
               "id":"386175"
            },
            "departamento":{
               "nombre":"Tilcara",
               "id":"38094"
            },
            "nombre":"COLONIA SAN JOSE",
            "id":"38094010001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Colonia San José",
               "id":"38094010"
            },
            "centroide":{
               "lat":-23.4023544678311,
               "lon":-65.3910451552268
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huacalera",
               "id":"386175"
            },
            "departamento":{
               "nombre":"Tilcara",
               "id":"38094"
            },
            "nombre":"YACORAITE",
            "id":"38094010002",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Colonia San José",
               "id":"38094010"
            },
            "centroide":{
               "lat":-23.4023544678311,
               "lon":-65.3910451552268
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huacalera",
               "id":"386175"
            },
            "departamento":{
               "nombre":"Tilcara",
               "id":"38094"
            },
            "nombre":"HUACALERA",
            "id":"38094020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Huacalera",
               "id":"38094020"
            },
            "centroide":{
               "lat":-23.4369569456923,
               "lon":-65.3498233567527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tilcara",
               "id":"380329"
            },
            "departamento":{
               "nombre":"Tilcara",
               "id":"38094"
            },
            "nombre":"JUELLA",
            "id":"38094030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Juella",
               "id":"38094030"
            },
            "centroide":{
               "lat":-23.5175106247741,
               "lon":-65.4081712327054
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maimará",
               "id":"386182"
            },
            "departamento":{
               "nombre":"Tilcara",
               "id":"38094"
            },
            "nombre":"MAIMARA",
            "id":"38094040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Maimará",
               "id":"38094040"
            },
            "centroide":{
               "lat":-23.6251650641141,
               "lon":-65.4090898914489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tilcara",
               "id":"380329"
            },
            "departamento":{
               "nombre":"Tilcara",
               "id":"38094"
            },
            "nombre":"TILCARA",
            "id":"38094050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Tilcara",
               "id":"38094050"
            },
            "centroide":{
               "lat":-23.5758626116635,
               "lon":-65.3936940685486
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Volcán",
               "id":"386203"
            },
            "departamento":{
               "nombre":"Tumbaya",
               "id":"38098"
            },
            "nombre":"BARCENA",
            "id":"38098010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Bárcena",
               "id":"38098010"
            },
            "centroide":{
               "lat":-23.9826097875936,
               "lon":-65.4550931589626
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Purmamarca",
               "id":"386189"
            },
            "departamento":{
               "nombre":"Tumbaya",
               "id":"38098"
            },
            "nombre":"EL MORENO",
            "id":"38098020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Moreno",
               "id":"38098020"
            },
            "centroide":{
               "lat":-23.8509115379981,
               "lon":-65.8316510262887
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Purmamarca",
               "id":"386189"
            },
            "departamento":{
               "nombre":"Tumbaya",
               "id":"38098"
            },
            "nombre":"PUERTA DE COLORADOS",
            "id":"38098025000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Puerta de Colorados",
               "id":"38098025"
            },
            "centroide":{
               "lat":-23.5715104796996,
               "lon":-65.698000202945
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Purmamarca",
               "id":"386189"
            },
            "departamento":{
               "nombre":"Tumbaya",
               "id":"38098"
            },
            "nombre":"PURMAMARCA",
            "id":"38098030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Purmamarca",
               "id":"38098030"
            },
            "centroide":{
               "lat":-23.7450544260753,
               "lon":-65.5003903899528
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tumbaya",
               "id":"386196"
            },
            "departamento":{
               "nombre":"Tumbaya",
               "id":"38098"
            },
            "nombre":"TUMBAYA",
            "id":"38098040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Tumbaya",
               "id":"38098040"
            },
            "centroide":{
               "lat":-23.8561961637236,
               "lon":-65.4663650677457
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Volcán",
               "id":"386203"
            },
            "departamento":{
               "nombre":"Tumbaya",
               "id":"38098"
            },
            "nombre":"VOLCAN",
            "id":"38098050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Volcán",
               "id":"38098050"
            },
            "centroide":{
               "lat":-23.9165986512201,
               "lon":-65.4655058903446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caspalá",
               "id":"386210"
            },
            "departamento":{
               "nombre":"Valle Grande",
               "id":"38105"
            },
            "nombre":"CASPALA",
            "id":"38105010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Caspalá",
               "id":"38105010"
            },
            "centroide":{
               "lat":-23.3626169910686,
               "lon":-65.0914951382906
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pampichuela",
               "id":"386217"
            },
            "departamento":{
               "nombre":"Valle Grande",
               "id":"38105"
            },
            "nombre":"PAMPICHUELA",
            "id":"38105020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Pampichuela",
               "id":"38105020"
            },
            "centroide":{
               "lat":-23.5548845300142,
               "lon":-65.0066887909796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Francisco",
               "id":"386224"
            },
            "departamento":{
               "nombre":"Valle Grande",
               "id":"38105"
            },
            "nombre":"SAN FRANCISCO",
            "id":"38105030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"San Francisco",
               "id":"38105030"
            },
            "centroide":{
               "lat":-23.6213393482903,
               "lon":-64.9501640840905
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"386231"
            },
            "departamento":{
               "nombre":"Valle Grande",
               "id":"38105"
            },
            "nombre":"SANTA ANA",
            "id":"38105040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Santa Ana",
               "id":"38105040"
            },
            "centroide":{
               "lat":-23.3564321535763,
               "lon":-64.9877247699547
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"386231"
            },
            "departamento":{
               "nombre":"Valle Grande",
               "id":"38105"
            },
            "nombre":"VALLE COLORADO",
            "id":"38105050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Valle Colorado",
               "id":"38105050"
            },
            "centroide":{
               "lat":-23.4139544374567,
               "lon":-64.93377255055
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Grande",
               "id":"386238"
            },
            "departamento":{
               "nombre":"Valle Grande",
               "id":"38105"
            },
            "nombre":"VALLE GRANDE",
            "id":"38105060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Valle Grande",
               "id":"38105060"
            },
            "centroide":{
               "lat":-23.4758465611018,
               "lon":-64.9469047343163
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Barrios",
               "id":"386245"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"BARRIOS",
            "id":"38112010000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Barrios",
               "id":"38112010"
            },
            "centroide":{
               "lat":-22.2477578344269,
               "lon":-65.529042968307
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cangrejillos",
               "id":"386252"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"CANGREJILLOS",
            "id":"38112020000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Cangrejillos",
               "id":"38112020"
            },
            "centroide":{
               "lat":-22.423791437897,
               "lon":-65.579872144671
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Cóndor",
               "id":"386259"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"EL CONDOR",
            "id":"38112030000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"El Cóndor",
               "id":"38112030"
            },
            "centroide":{
               "lat":-22.3866192862254,
               "lon":-65.3395952168589
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pumahuasi",
               "id":"386266"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"LA INTERMEDIA",
            "id":"38112040000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Intermedia",
               "id":"38112040"
            },
            "centroide":{
               "lat":-22.4230485492938,
               "lon":-65.7004227358985
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Quiaca",
               "id":"380406"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"LA QUIACA",
            "id":"38112050000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"La Quiaca",
               "id":"38112050"
            },
            "centroide":{
               "lat":-22.1064369293997,
               "lon":-65.5957257876868
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puesto del Marqués",
               "id":"386021"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"LLULLUCHAYOC",
            "id":"38112060000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Llulluchayoc",
               "id":"38112060"
            },
            "centroide":{
               "lat":-22.5507612616611,
               "lon":-65.3872645925388
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pumahuasi",
               "id":"386266"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"PUMAHUASI",
            "id":"38112070000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Pumahuasi",
               "id":"38112070"
            },
            "centroide":{
               "lat":-22.2899363240929,
               "lon":-65.6798827753743
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yavi",
               "id":"386273"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"SAN JOSE",
            "id":"38112080001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yavi",
               "id":"38112080"
            },
            "centroide":{
               "lat":-22.1378271107898,
               "lon":-65.3097886156379
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Yavi",
               "id":"386273"
            },
            "departamento":{
               "nombre":"Yaví",
               "id":"38112"
            },
            "nombre":"YAVI CHICO",
            "id":"38112090000",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Yavi Chico",
               "id":"38112090"
            },
            "centroide":{
               "lat":-22.0983777844843,
               "lon":-65.4559189939558
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Doblas",
               "id":"420112"
            },
            "departamento":{
               "nombre":"Atreucó",
               "id":"42007"
            },
            "nombre":"DOBLAS",
            "id":"42007010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Doblas",
               "id":"42007010"
            },
            "centroide":{
               "lat":-37.1521838816608,
               "lon":-64.0140550927574
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Macachín",
               "id":"420231"
            },
            "departamento":{
               "nombre":"Atreucó",
               "id":"42007"
            },
            "nombre":"MACACHIN",
            "id":"42007020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Macachín",
               "id":"42007020"
            },
            "centroide":{
               "lat":-37.1369555361687,
               "lon":-63.667141835534
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Miguel Riglos",
               "id":"420259"
            },
            "departamento":{
               "nombre":"Atreucó",
               "id":"42007"
            },
            "nombre":"MIGUEL RIGLOS",
            "id":"42007030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Miguel Riglos",
               "id":"42007030"
            },
            "centroide":{
               "lat":-36.8538749058938,
               "lon":-63.6881846143541
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rolón",
               "id":"420322"
            },
            "departamento":{
               "nombre":"Atreucó",
               "id":"42007"
            },
            "nombre":"ROLON",
            "id":"42007040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Rolón",
               "id":"42007040"
            },
            "centroide":{
               "lat":-37.166824214775,
               "lon":-63.4171582009534
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tomás Manuel Anchorena",
               "id":"420364"
            },
            "departamento":{
               "nombre":"Atreucó",
               "id":"42007"
            },
            "nombre":"TOMAS M. ANCHORENA",
            "id":"42007050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Tomás M. Anchorena",
               "id":"42007050"
            },
            "centroide":{
               "lat":-36.8406606874148,
               "lon":-63.5205021645149
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Adela",
               "id":"420189"
            },
            "departamento":{
               "nombre":"Caleu Caleu",
               "id":"42014"
            },
            "nombre":"ANZOATEGUI",
            "id":"42014010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Anzoátegui",
               "id":"42014010"
            },
            "centroide":{
               "lat":-38.9630526765691,
               "lon":-63.8608882456791
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Adela",
               "id":"420189"
            },
            "departamento":{
               "nombre":"Caleu Caleu",
               "id":"42014"
            },
            "nombre":"LA ADELA",
            "id":"42014020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"La Adela",
               "id":"42014020"
            },
            "centroide":{
               "lat":-38.9739618507806,
               "lon":-64.0893166949512
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Anguil",
               "id":"420028"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"42021"
            },
            "nombre":"ANGUIL",
            "id":"42021010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Anguil",
               "id":"42021010"
            },
            "centroide":{
               "lat":-36.527922625355,
               "lon":-64.010555897973
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"420336"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"42021"
            },
            "nombre":"SANTA ROSA",
            "id":"42021020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa",
               "id":"42021020"
            },
            "centroide":{
               "lat":-36.61828979857,
               "lon":-64.2916770389461
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Catriló",
               "id":"420077"
            },
            "departamento":{
               "nombre":"Catriló",
               "id":"42028"
            },
            "nombre":"CATRILO",
            "id":"42028010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Catriló",
               "id":"42028010"
            },
            "centroide":{
               "lat":-36.4077407331528,
               "lon":-63.4231236479695
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Uriburu",
               "id":"420378"
            },
            "departamento":{
               "nombre":"Catriló",
               "id":"42028"
            },
            "nombre":"LA GLORIA",
            "id":"42028020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"La Gloria",
               "id":"42028020"
            },
            "centroide":{
               "lat":-36.5079177159884,
               "lon":-63.7444083139952
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lonquimay",
               "id":"420217"
            },
            "departamento":{
               "nombre":"Catriló",
               "id":"42028"
            },
            "nombre":"LONQUIMAY",
            "id":"42028030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Lonquimay",
               "id":"42028030"
            },
            "centroide":{
               "lat":-36.4676263417811,
               "lon":-63.6237223449231
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Uriburu",
               "id":"420378"
            },
            "departamento":{
               "nombre":"Catriló",
               "id":"42028"
            },
            "nombre":"URIBURU",
            "id":"42028040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Uriburu",
               "id":"42028040"
            },
            "centroide":{
               "lat":-36.5077574745038,
               "lon":-63.8626307655783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Conhelo",
               "id":"420098"
            },
            "departamento":{
               "nombre":"Conhelo",
               "id":"42035"
            },
            "nombre":"CONHELO",
            "id":"42035010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Conhelo",
               "id":"42035010"
            },
            "centroide":{
               "lat":-35.9994878462166,
               "lon":-64.5959233383525
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eduardo Castex",
               "id":"420119"
            },
            "departamento":{
               "nombre":"Conhelo",
               "id":"42035"
            },
            "nombre":"EDUARDO CASTEX",
            "id":"42035020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Eduardo Castex",
               "id":"42035020"
            },
            "centroide":{
               "lat":-35.916589710926,
               "lon":-64.2945868368038
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mauricio Mayer",
               "id":"420238"
            },
            "departamento":{
               "nombre":"Conhelo",
               "id":"42035"
            },
            "nombre":"MAURICIO MAYER",
            "id":"42035030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Mauricio Mayer",
               "id":"42035030"
            },
            "centroide":{
               "lat":-36.2106631640542,
               "lon":-64.0335412257945
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Nievas",
               "id":"420266"
            },
            "departamento":{
               "nombre":"Conhelo",
               "id":"42035"
            },
            "nombre":"MONTE NIEVAS",
            "id":"42035040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Monte Nievas",
               "id":"42035040"
            },
            "centroide":{
               "lat":-35.8617464058389,
               "lon":-64.1569675208376
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rucanelo",
               "id":"425133"
            },
            "departamento":{
               "nombre":"Conhelo",
               "id":"42035"
            },
            "nombre":"RUCANELO",
            "id":"42035050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Rucanelo",
               "id":"42035050"
            },
            "centroide":{
               "lat":-36.0429376779565,
               "lon":-64.8353214769324
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Winifreda",
               "id":"420413"
            },
            "departamento":{
               "nombre":"Conhelo",
               "id":"42035"
            },
            "nombre":"WINIFREDA",
            "id":"42035060000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Winifreda",
               "id":"42035060"
            },
            "centroide":{
               "lat":-36.2272741396174,
               "lon":-64.2348767956417
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Duval",
               "id":"425070"
            },
            "departamento":{
               "nombre":"Curacó",
               "id":"42042"
            },
            "nombre":"GOBERNADOR DUVAL",
            "id":"42042010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Gobernador Duval",
               "id":"42042010"
            },
            "centroide":{
               "lat":-38.7413407663938,
               "lon":-66.4360653362481
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puelches",
               "id":"420280"
            },
            "departamento":{
               "nombre":"Curacó",
               "id":"42042"
            },
            "nombre":"PUELCHES",
            "id":"42042020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Puelches",
               "id":"42042020"
            },
            "centroide":{
               "lat":-38.1437722310811,
               "lon":-65.9102790812435
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Isabel",
               "id":"420329"
            },
            "departamento":{
               "nombre":"Chalileo",
               "id":"42049"
            },
            "nombre":"SANTA ISABEL",
            "id":"42049010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Santa Isabel",
               "id":"42049010"
            },
            "centroide":{
               "lat":-36.2298585551973,
               "lon":-66.9416696902043
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bernardo Larroude",
               "id":"420049"
            },
            "departamento":{
               "nombre":"Chapaleufú",
               "id":"42056"
            },
            "nombre":"BERNARDO LARROUDE",
            "id":"42056010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Bernardo Larroude",
               "id":"42056010"
            },
            "centroide":{
               "lat":-35.0240766140268,
               "lon":-63.5814786778188
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ceballos",
               "id":"420084"
            },
            "departamento":{
               "nombre":"Chapaleufú",
               "id":"42056"
            },
            "nombre":"CEBALLOS",
            "id":"42056020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Ceballos",
               "id":"42056020"
            },
            "centroide":{
               "lat":-35.2904849982905,
               "lon":-63.77586064487
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Hilario Lagos",
               "id":"420105"
            },
            "departamento":{
               "nombre":"Chapaleufú",
               "id":"42056"
            },
            "nombre":"CORONEL HILARIO LAGOS",
            "id":"42056030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Coronel Hilario Lagos",
               "id":"42056030"
            },
            "centroide":{
               "lat":-35.0223098759754,
               "lon":-63.9123145859878
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Intendente Alvear",
               "id":"420175"
            },
            "departamento":{
               "nombre":"Chapaleufú",
               "id":"42056"
            },
            "nombre":"INTENDENTE ALVEAR",
            "id":"42056040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Intendente Alvear",
               "id":"42056040"
            },
            "centroide":{
               "lat":-35.2368693365619,
               "lon":-63.5923274522769
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarah",
               "id":"425140"
            },
            "departamento":{
               "nombre":"Chapaleufú",
               "id":"42056"
            },
            "nombre":"SARAH",
            "id":"42056050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Sarah",
               "id":"42056050"
            },
            "centroide":{
               "lat":-35.0246923056998,
               "lon":-63.686962377693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vértiz",
               "id":"420392"
            },
            "departamento":{
               "nombre":"Chapaleufú",
               "id":"42056"
            },
            "nombre":"VERTIZ",
            "id":"42056060000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Vértiz",
               "id":"42056060"
            },
            "centroide":{
               "lat":-35.4262411079929,
               "lon":-63.9063646669037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Algarrobo del Águila",
               "id":"420011"
            },
            "departamento":{
               "nombre":"Chical Co",
               "id":"42063"
            },
            "nombre":"ALGARROBO DEL AGUILA",
            "id":"42063010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Algarrobo del Águila",
               "id":"42063010"
            },
            "centroide":{
               "lat":-36.4011467185658,
               "lon":-67.1484015946157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Humada",
               "id":"420196"
            },
            "departamento":{
               "nombre":"Chical Co",
               "id":"42063"
            },
            "nombre":"LA HUMADA",
            "id":"42063020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"La Humada",
               "id":"42063020"
            },
            "centroide":{
               "lat":-36.3500276379509,
               "lon":-68.0141950027482
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alpachiri",
               "id":"420014"
            },
            "departamento":{
               "nombre":"Guatraché",
               "id":"42070"
            },
            "nombre":"ALPACHIRI",
            "id":"42070010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Alpachiri",
               "id":"42070010"
            },
            "centroide":{
               "lat":-37.3758811926005,
               "lon":-63.7738735909085
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Campos",
               "id":"420140"
            },
            "departamento":{
               "nombre":"Guatraché",
               "id":"42070"
            },
            "nombre":"GENERAL MANUEL J. CAMPOS",
            "id":"42070020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"General Manuel J. Campos",
               "id":"42070020"
            },
            "centroide":{
               "lat":-37.4589894326191,
               "lon":-63.5872408548874
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guatraché",
               "id":"420161"
            },
            "departamento":{
               "nombre":"Guatraché",
               "id":"42070"
            },
            "nombre":"GUATRACHE",
            "id":"42070030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Guatraché",
               "id":"42070030"
            },
            "centroide":{
               "lat":-37.6669652272263,
               "lon":-63.5415367289386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Perú",
               "id":"425105"
            },
            "departamento":{
               "nombre":"Guatraché",
               "id":"42070"
            },
            "nombre":"PERU",
            "id":"42070040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Perú",
               "id":"42070040"
            },
            "centroide":{
               "lat":-37.6623849438401,
               "lon":-64.1373213494442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Teresa",
               "id":"420343"
            },
            "departamento":{
               "nombre":"Guatraché",
               "id":"42070"
            },
            "nombre":"SANTA TERESA",
            "id":"42070050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Santa Teresa",
               "id":"42070050"
            },
            "centroide":{
               "lat":-37.5749339058948,
               "lon":-63.4328864695669
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abramo",
               "id":"420007"
            },
            "departamento":{
               "nombre":"Hucal",
               "id":"42077"
            },
            "nombre":"ABRAMO",
            "id":"42077010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Abramo",
               "id":"42077010"
            },
            "centroide":{
               "lat":-37.8943313295474,
               "lon":-63.8515025914131
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bernasconi",
               "id":"420056"
            },
            "departamento":{
               "nombre":"Hucal",
               "id":"42077"
            },
            "nombre":"BERNASCONI",
            "id":"42077020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Bernasconi",
               "id":"42077020"
            },
            "centroide":{
               "lat":-37.9020681912485,
               "lon":-63.7450793526802
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"420154"
            },
            "departamento":{
               "nombre":"Hucal",
               "id":"42077"
            },
            "nombre":"GENERAL SAN MARTIN",
            "id":"42077030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"General San Martín",
               "id":"42077030"
            },
            "centroide":{
               "lat":-37.9785301335454,
               "lon":-63.6065854529977
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Abramo",
               "id":"420007"
            },
            "departamento":{
               "nombre":"Hucal",
               "id":"42077"
            },
            "nombre":"HUCAL",
            "id":"42077040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Hucal",
               "id":"42077040"
            },
            "centroide":{
               "lat":-37.7849629183535,
               "lon":-64.0306677804584
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jacinto Arauz",
               "id":"420182"
            },
            "departamento":{
               "nombre":"Hucal",
               "id":"42077"
            },
            "nombre":"JACINTO ARAUZ",
            "id":"42077050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Jacinto Aráuz",
               "id":"42077050"
            },
            "centroide":{
               "lat":-38.0837587172211,
               "lon":-63.4321816643775
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cuchillo Có",
               "id":"425042"
            },
            "departamento":{
               "nombre":"Lihuel Calel",
               "id":"42084"
            },
            "nombre":"CUCHILLO CO",
            "id":"42084010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Cuchillo Co",
               "id":"42084010"
            },
            "centroide":{
               "lat":-38.3336784745408,
               "lon":-64.6422526958764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Reforma",
               "id":"425077"
            },
            "departamento":{
               "nombre":"Limay Mahuida",
               "id":"42091"
            },
            "nombre":"LA REFORMA",
            "id":"42091010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"La Reforma",
               "id":"42091010"
            },
            "centroide":{
               "lat":-37.5520049423349,
               "lon":-66.2268538972728
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Limay Mahuida",
               "id":"425084"
            },
            "departamento":{
               "nombre":"Limay Mahuida",
               "id":"42091"
            },
            "nombre":"LIMAY MAHUIDA",
            "id":"42091020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Limay Mahuida",
               "id":"42091020"
            },
            "centroide":{
               "lat":-37.1597120530545,
               "lon":-66.6745400356471
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carro Quemado",
               "id":"420070"
            },
            "departamento":{
               "nombre":"Loventué",
               "id":"42098"
            },
            "nombre":"CARRO QUEMADO",
            "id":"42098010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Carro Quemado",
               "id":"42098010"
            },
            "centroide":{
               "lat":-36.47556498406,
               "lon":-65.3429160249895
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Loventué",
               "id":"425091"
            },
            "departamento":{
               "nombre":"Loventué",
               "id":"42098"
            },
            "nombre":"LOVENTUE",
            "id":"42098020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Loventué",
               "id":"42098020"
            },
            "centroide":{
               "lat":-36.1887459763907,
               "lon":-65.2899702728719
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luan Toro",
               "id":"420224"
            },
            "departamento":{
               "nombre":"Loventué",
               "id":"42098"
            },
            "nombre":"LUAN TORO",
            "id":"42098030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Luan Toro",
               "id":"42098030"
            },
            "centroide":{
               "lat":-36.2016818772905,
               "lon":-65.0972782402489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Telén",
               "id":"420350"
            },
            "departamento":{
               "nombre":"Loventué",
               "id":"42098"
            },
            "nombre":"TELEN",
            "id":"42098040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Telén",
               "id":"42098040"
            },
            "centroide":{
               "lat":-36.2640564040186,
               "lon":-65.5097731802402
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Victorica",
               "id":"420399"
            },
            "departamento":{
               "nombre":"Loventué",
               "id":"42098"
            },
            "nombre":"VICTORICA",
            "id":"42098050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Victorica",
               "id":"42098050"
            },
            "centroide":{
               "lat":-36.2048593679219,
               "lon":-65.4403752727095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Agustoni",
               "id":"425014"
            },
            "departamento":{
               "nombre":"Maracó",
               "id":"42105"
            },
            "nombre":"AGUSTONI",
            "id":"42105010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Agustoni",
               "id":"42105010"
            },
            "centroide":{
               "lat":-35.7811018772957,
               "lon":-63.3932493460357
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dorila",
               "id":"420116"
            },
            "departamento":{
               "nombre":"Maracó",
               "id":"42105"
            },
            "nombre":"DORILA",
            "id":"42105020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Dorila",
               "id":"42105020"
            },
            "centroide":{
               "lat":-35.7763229509013,
               "lon":-63.716840693317
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pico",
               "id":"420147"
            },
            "departamento":{
               "nombre":"Maracó",
               "id":"42105"
            },
            "nombre":"GENERAL PICO",
            "id":"42105030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"General Pico",
               "id":"42105030"
            },
            "centroide":{
               "lat":-35.6633805937206,
               "lon":-63.760929267739
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Speluzzi",
               "id":"425147"
            },
            "departamento":{
               "nombre":"Maracó",
               "id":"42105"
            },
            "nombre":"SPELUZZI",
            "id":"42105040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Speluzzi",
               "id":"42105040"
            },
            "centroide":{
               "lat":-35.547293621716,
               "lon":-63.8200403343494
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pico",
               "id":"420147"
            },
            "departamento":{
               "nombre":"Maracó",
               "id":"42105"
            },
            "nombre":"TREBOLARES",
            "id":"42105050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Trebolares",
               "id":"42105050"
            },
            "centroide":{
               "lat":-35.5855840986961,
               "lon":-63.5887521403772
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Casa de Piedra",
               "id":"422007"
            },
            "departamento":{
               "nombre":"Puelén",
               "id":"42112"
            },
            "nombre":"CASA DE PIEDRA",
            "id":"42112005000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Casa de Piedra",
               "id":"42112005"
            },
            "centroide":{
               "lat":-38.2230515311115,
               "lon":-67.1709263654639
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puelén",
               "id":"420287"
            },
            "departamento":{
               "nombre":"Puelén",
               "id":"42112"
            },
            "nombre":"PUELEN",
            "id":"42112010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Puelén",
               "id":"42112010"
            },
            "centroide":{
               "lat":-37.3400655492209,
               "lon":-67.6218500509991
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Veinticinco de Mayo",
               "id":"420385"
            },
            "departamento":{
               "nombre":"Puelén",
               "id":"42112"
            },
            "nombre":"25 DE MAYO",
            "id":"42112020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"25 de Mayo",
               "id":"42112020"
            },
            "centroide":{
               "lat":-37.7697053748681,
               "lon":-67.7172958236186
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Barón",
               "id":"420091"
            },
            "departamento":{
               "nombre":"Quemú Quemú",
               "id":"42119"
            },
            "nombre":"COLONIA BARON",
            "id":"42119010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Colonia Barón",
               "id":"42119010"
            },
            "centroide":{
               "lat":-36.1513889624763,
               "lon":-63.8541959060258
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Barón",
               "id":"420091"
            },
            "departamento":{
               "nombre":"Quemú Quemú",
               "id":"42119"
            },
            "nombre":"COLONIA SAN JOSE",
            "id":"42119020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Colonia San José",
               "id":"42119020"
            },
            "centroide":{
               "lat":-36.1170514464671,
               "lon":-63.9040708614432
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Miguel Cané",
               "id":"420252"
            },
            "departamento":{
               "nombre":"Quemú Quemú",
               "id":"42119"
            },
            "nombre":"MIGUEL CANE",
            "id":"42119030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Miguel Cané",
               "id":"42119030"
            },
            "centroide":{
               "lat":-36.1549581794141,
               "lon":-63.5121543730908
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quemú Quemú",
               "id":"420301"
            },
            "departamento":{
               "nombre":"Quemú Quemú",
               "id":"42119"
            },
            "nombre":"QUEMU QUEMU",
            "id":"42119040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Quemú Quemú",
               "id":"42119040"
            },
            "centroide":{
               "lat":-36.053677480752,
               "lon":-63.5632432919433
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Relmo",
               "id":"425126"
            },
            "departamento":{
               "nombre":"Quemú Quemú",
               "id":"42119"
            },
            "nombre":"RELMO",
            "id":"42119050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Relmo",
               "id":"42119050"
            },
            "centroide":{
               "lat":-36.2573837868412,
               "lon":-63.4481302030919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Mirasol",
               "id":"420406"
            },
            "departamento":{
               "nombre":"Quemú Quemú",
               "id":"42119"
            },
            "nombre":"VILLA MIRASOL",
            "id":"42119060000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Villa Mirasol",
               "id":"42119060"
            },
            "centroide":{
               "lat":-36.0757513459043,
               "lon":-63.8875837941037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caleufú",
               "id":"420063"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"CALEUFU",
            "id":"42126010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Caleufú",
               "id":"42126010"
            },
            "centroide":{
               "lat":-35.5928646334622,
               "lon":-64.5586842581366
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Maruja",
               "id":"420210"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"INGENIERO FOSTER",
            "id":"42126020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Foster",
               "id":"42126020"
            },
            "centroide":{
               "lat":-35.7014889174834,
               "lon":-65.1025535202338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Maruja",
               "id":"420210"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"LA MARUJA",
            "id":"42126030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"La Maruja",
               "id":"42126030"
            },
            "centroide":{
               "lat":-35.671688678266,
               "lon":-64.940569826581
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Parera",
               "id":"420273"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"PARERA",
            "id":"42126040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Parera",
               "id":"42126040"
            },
            "centroide":{
               "lat":-35.1462930750947,
               "lon":-64.5006491231723
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pichi Huinca",
               "id":"425112"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"PICHI HUINCA",
            "id":"42126050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Pichi Huinca",
               "id":"42126050"
            },
            "centroide":{
               "lat":-35.6473423932767,
               "lon":-64.7695280662632
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quetrequén",
               "id":"425119"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"QUETREQUEN",
            "id":"42126060000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Quetrequén",
               "id":"42126060"
            },
            "centroide":{
               "lat":-35.0548439319726,
               "lon":-64.5213693396743
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rancul",
               "id":"420308"
            },
            "departamento":{
               "nombre":"Rancul",
               "id":"42126"
            },
            "nombre":"RANCUL",
            "id":"42126070000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Rancul",
               "id":"42126070"
            },
            "centroide":{
               "lat":-35.0661985558406,
               "lon":-64.683114985952
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Van Praet",
               "id":"425007"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"ADOLFO VAN PRAET",
            "id":"42133010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Adolfo Van Praet",
               "id":"42133010"
            },
            "centroide":{
               "lat":-35.0179130302315,
               "lon":-64.035117861781
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alta Italia",
               "id":"420021"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"ALTA ITALIA",
            "id":"42133020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Alta Italia",
               "id":"42133020"
            },
            "centroide":{
               "lat":-35.3338595551664,
               "lon":-64.1182405361085
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maisonnave",
               "id":"425098"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"DAMIAN MAISONAVE",
            "id":"42133030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Damián Maisonave",
               "id":"42133030"
            },
            "centroide":{
               "lat":-35.0415558502239,
               "lon":-64.3847126106129
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embajador Martini",
               "id":"420126"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"EMBAJADOR MARTINI",
            "id":"42133040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Embajador Martini",
               "id":"42133040"
            },
            "centroide":{
               "lat":-35.3861854808904,
               "lon":-64.2836857257322
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Falucho",
               "id":"425063"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"FALUCHO",
            "id":"42133050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Falucho",
               "id":"42133050"
            },
            "centroide":{
               "lat":-35.1890814426625,
               "lon":-64.1038732251149
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ingeniero Luiggi",
               "id":"420168"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"INGENIERO LUIGGI",
            "id":"42133060000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Luiggi",
               "id":"42133060"
            },
            "centroide":{
               "lat":-35.3837504384968,
               "lon":-64.4685685991593
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alta Italia",
               "id":"420021"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"OJEDA",
            "id":"42133070000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Ojeda",
               "id":"42133070"
            },
            "centroide":{
               "lat":-35.3068277686526,
               "lon":-64.0054725550363
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Realicó",
               "id":"420315"
            },
            "departamento":{
               "nombre":"Realicó",
               "id":"42133"
            },
            "nombre":"REALICO",
            "id":"42133080000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Realicó",
               "id":"42133080"
            },
            "centroide":{
               "lat":-35.0368857536117,
               "lon":-64.2454209673953
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Toay",
               "id":"420357"
            },
            "departamento":{
               "nombre":"Toay",
               "id":"42140"
            },
            "nombre":"CACHIRULO",
            "id":"42140005000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Cachirulo",
               "id":"42140005"
            },
            "centroide":{
               "lat":-36.7473961516096,
               "lon":-64.3673907018325
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ataliva Roca",
               "id":"420042"
            },
            "departamento":{
               "nombre":"Toay",
               "id":"42140"
            },
            "nombre":"NAICO",
            "id":"42140010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Naicó",
               "id":"42140010"
            },
            "centroide":{
               "lat":-36.9283865222889,
               "lon":-64.3952508587586
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Toay",
               "id":"420357"
            },
            "departamento":{
               "nombre":"Toay",
               "id":"42140"
            },
            "nombre":"TOAY",
            "id":"42140020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Toay",
               "id":"42140020"
            },
            "centroide":{
               "lat":-36.6751577652887,
               "lon":-64.3803253806581
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arata",
               "id":"420035"
            },
            "departamento":{
               "nombre":"Trenel",
               "id":"42147"
            },
            "nombre":"ARATA",
            "id":"42147010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Arata",
               "id":"42147010"
            },
            "centroide":{
               "lat":-35.6381231858073,
               "lon":-64.3574125644643
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Metileo",
               "id":"420245"
            },
            "departamento":{
               "nombre":"Trenel",
               "id":"42147"
            },
            "nombre":"METILEO",
            "id":"42147020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Metileo",
               "id":"42147020"
            },
            "centroide":{
               "lat":-35.7732438729278,
               "lon":-63.9428308886673
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trenel",
               "id":"420371"
            },
            "departamento":{
               "nombre":"Trenel",
               "id":"42147"
            },
            "nombre":"TRENEL",
            "id":"42147030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Trenel",
               "id":"42147030"
            },
            "centroide":{
               "lat":-35.6941743318846,
               "lon":-64.1358999676104
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ataliva Roca",
               "id":"420042"
            },
            "departamento":{
               "nombre":"Utracán",
               "id":"42154"
            },
            "nombre":"ATALIVA ROCA",
            "id":"42154010000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Ataliva Roca",
               "id":"42154010"
            },
            "centroide":{
               "lat":-37.0323315728651,
               "lon":-64.2865724433513
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chacharramendi",
               "id":"425049"
            },
            "departamento":{
               "nombre":"Utracán",
               "id":"42154"
            },
            "nombre":"CHACHARRAMENDI",
            "id":"42154020000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Chacharramendi",
               "id":"42154020"
            },
            "centroide":{
               "lat":-37.3318147762102,
               "lon":-65.650772794634
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Santa María",
               "id":"425028"
            },
            "departamento":{
               "nombre":"Utracán",
               "id":"42154"
            },
            "nombre":"COLONIA SANTA MARIA",
            "id":"42154030000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Colonia Santa María",
               "id":"42154030"
            },
            "centroide":{
               "lat":-37.4952435783299,
               "lon":-64.2248805481774
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Acha",
               "id":"420133"
            },
            "departamento":{
               "nombre":"Utracán",
               "id":"42154"
            },
            "nombre":"GENERAL ACHA",
            "id":"42154040000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"General Acha",
               "id":"42154040"
            },
            "centroide":{
               "lat":-37.373672668717,
               "lon":-64.6038964711256
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quehué",
               "id":"420294"
            },
            "departamento":{
               "nombre":"Utracán",
               "id":"42154"
            },
            "nombre":"QUEHUE",
            "id":"42154050000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Quehué",
               "id":"42154050"
            },
            "centroide":{
               "lat":-37.1212005028742,
               "lon":-64.5133661628312
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Unanue",
               "id":"425154"
            },
            "departamento":{
               "nombre":"Utracán",
               "id":"42154"
            },
            "nombre":"UNANUE",
            "id":"42154060000",
            "provincia":{
               "nombre":"La Pampa",
               "id":"42"
            },
            "localidad_censal":{
               "nombre":"Unanué",
               "id":"42154060"
            },
            "centroide":{
               "lat":-37.5439767222425,
               "lon":-64.3525554864311
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"AIMOGASTA",
            "id":"46007010001",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Aimogasta",
               "id":"46007010"
            },
            "centroide":{
               "lat":-28.5589898446523,
               "lon":-66.8272851017638
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"MACHIGASTA",
            "id":"46007010002",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Aimogasta",
               "id":"46007010"
            },
            "centroide":{
               "lat":-28.5410843774808,
               "lon":-66.8070296105313
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"SAN ANTONIO",
            "id":"46007010003",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Aimogasta",
               "id":"46007010"
            },
            "centroide":{
               "lat":-28.5553801376497,
               "lon":-66.8013856528183
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"ARAUCO",
            "id":"46007020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Arauco",
               "id":"46007020"
            },
            "centroide":{
               "lat":-28.5834031870202,
               "lon":-66.8036412937626
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"BAÑADO DE LOS PANTANOS",
            "id":"46007030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Bañado de los Pantanos",
               "id":"46007030"
            },
            "centroide":{
               "lat":-28.3871788034279,
               "lon":-66.8369122348367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"ESTACION MAZAN",
            "id":"46007040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Estación Mazán",
               "id":"46007040"
            },
            "centroide":{
               "lat":-28.6648262993633,
               "lon":-66.5166195609666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"TERMAS DE SANTA TERESITA",
            "id":"46007045000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Termas de Santa Teresita",
               "id":"46007045"
            },
            "centroide":{
               "lat":-28.591774966521,
               "lon":-66.5563885931113
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arauco",
               "id":"460007"
            },
            "departamento":{
               "nombre":"Arauco",
               "id":"46007"
            },
            "nombre":"VILLA MAZAN",
            "id":"46007050000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Villa Mazán",
               "id":"46007050"
            },
            "centroide":{
               "lat":-28.6586281834455,
               "lon":-66.5552758852995
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"460014"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"46014"
            },
            "nombre":"LA RIOJA",
            "id":"46014010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"La Rioja",
               "id":"46014010"
            },
            "centroide":{
               "lat":-29.4217827668094,
               "lon":-66.856675379963
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"AMINGA",
            "id":"46021010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Aminga",
               "id":"46021010"
            },
            "centroide":{
               "lat":-28.8554609845772,
               "lon":-66.9376617661779
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"ANILLACO",
            "id":"46021020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Anillaco",
               "id":"46021020"
            },
            "centroide":{
               "lat":-28.8155900262519,
               "lon":-66.9439245714904
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"ANJULLON",
            "id":"46021030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Anjullón",
               "id":"46021030"
            },
            "centroide":{
               "lat":-28.7272509428901,
               "lon":-66.9328944677286
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"CHUQUIS",
            "id":"46021040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chuquis",
               "id":"46021040"
            },
            "centroide":{
               "lat":-28.8979801822511,
               "lon":-66.9765194957537
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"LOS MOLINOS",
            "id":"46021050000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Los Molinos",
               "id":"46021050"
            },
            "centroide":{
               "lat":-28.7544152281739,
               "lon":-66.9389202939919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"PINCHAS",
            "id":"46021060000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Pinchas",
               "id":"46021060"
            },
            "centroide":{
               "lat":-28.9370760269909,
               "lon":-66.9665171496043
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"SAN PEDRO",
            "id":"46021070000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"46021070"
            },
            "centroide":{
               "lat":-28.6697090457958,
               "lon":-66.9281338379098
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castro Barros",
               "id":"460021"
            },
            "departamento":{
               "nombre":"Castro Barros",
               "id":"46021"
            },
            "nombre":"SANTA VERA CRUZ",
            "id":"46021080000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Santa Vera Cruz",
               "id":"46021080"
            },
            "centroide":{
               "lat":-28.683504219205,
               "lon":-66.9654939868506
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"AICUÑA",
            "id":"46028010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Aicuñá",
               "id":"46028010"
            },
            "centroide":{
               "lat":-29.4772470519152,
               "lon":-67.7729134098149
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"GUANDACOL",
            "id":"46028020001",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Guandacol",
               "id":"46028020"
            },
            "centroide":{
               "lat":-29.509859321691,
               "lon":-68.5522045398452
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"SANTA CLARA",
            "id":"46028020002",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Guandacol",
               "id":"46028020"
            },
            "centroide":{
               "lat":-29.361377257847,
               "lon":-68.6264098036917
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"LOS PALACIOS",
            "id":"46028030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Los Palacios",
               "id":"46028030"
            },
            "centroide":{
               "lat":-29.3713119289112,
               "lon":-68.2271328398885
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"PAGANCILLO",
            "id":"46028040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Pagancillo",
               "id":"46028040"
            },
            "centroide":{
               "lat":-29.5412536132819,
               "lon":-68.0970404734465
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"BANDA FLORIDA",
            "id":"46028050001",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Villa Unión",
               "id":"46028050"
            },
            "centroide":{
               "lat":-29.3032949590649,
               "lon":-68.2439876146285
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Felipe Varela",
               "id":"460028"
            },
            "departamento":{
               "nombre":"Coronel Felipe Varela",
               "id":"46028"
            },
            "nombre":"VILLA UNION",
            "id":"46028050002",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Villa Unión",
               "id":"46028050"
            },
            "centroide":{
               "lat":-29.3227046223336,
               "lon":-68.2230741292594
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chamical",
               "id":"460035"
            },
            "departamento":{
               "nombre":"Chamical",
               "id":"46035"
            },
            "nombre":"CHAMICAL",
            "id":"46035010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chamical",
               "id":"46035010"
            },
            "centroide":{
               "lat":-30.3772951613355,
               "lon":-66.3240368838483
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chamical",
               "id":"460035"
            },
            "departamento":{
               "nombre":"Chamical",
               "id":"46035"
            },
            "nombre":"POLCO",
            "id":"46035020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Polco",
               "id":"46035020"
            },
            "centroide":{
               "lat":-30.4293153810224,
               "lon":-66.3586422456665
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"ANGUINAN",
            "id":"46042010001",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chilecito",
               "id":"46042010"
            },
            "centroide":{
               "lat":-29.2060142258143,
               "lon":-67.4589845307511
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"CHILECITO",
            "id":"46042010002",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chilecito",
               "id":"46042010"
            },
            "centroide":{
               "lat":-29.1634978575196,
               "lon":-67.5074284990176
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"LA PUNTILLA",
            "id":"46042010003",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chilecito",
               "id":"46042010"
            },
            "centroide":{
               "lat":-29.1484698105368,
               "lon":-67.4191244361359
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"LOS SARMIENTOS",
            "id":"46042010004",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chilecito",
               "id":"46042010"
            },
            "centroide":{
               "lat":-29.1517467165263,
               "lon":-67.4826010369502
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"SAN MIGUEL",
            "id":"46042010005",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chilecito",
               "id":"46042010"
            },
            "centroide":{
               "lat":-29.184674818415,
               "lon":-67.4780826682806
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"COLONIA ANGUINAN",
            "id":"46042020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Colonia Anguinán",
               "id":"46042020"
            },
            "centroide":{
               "lat":-29.2540980171571,
               "lon":-67.426136513409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"COLONIA CATINZACO",
            "id":"46042030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Colonia Catinzaco",
               "id":"46042030"
            },
            "centroide":{
               "lat":-29.6731660020358,
               "lon":-67.3823669132229
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"COLONIA MALLIGASTA",
            "id":"46042040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Colonia Malligasta",
               "id":"46042040"
            },
            "centroide":{
               "lat":-29.2029666175252,
               "lon":-67.4003467202303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"COLONIA VICHIGASTA",
            "id":"46042050000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Colonia Vichigasta",
               "id":"46042050"
            },
            "centroide":{
               "lat":-29.4490716382323,
               "lon":-67.4914198732182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"GUANCHIN",
            "id":"46042060000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Guanchín",
               "id":"46042060"
            },
            "centroide":{
               "lat":-29.1927256872976,
               "lon":-67.6392754274079
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"MALLIGASTA",
            "id":"46042070000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Malligasta",
               "id":"46042070"
            },
            "centroide":{
               "lat":-29.1772024890585,
               "lon":-67.4408316312776
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"MIRANDA",
            "id":"46042080000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Miranda",
               "id":"46042080"
            },
            "centroide":{
               "lat":-29.3410380679447,
               "lon":-67.6618699588305
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"NONOGASTA",
            "id":"46042090000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Nonogasta",
               "id":"46042090"
            },
            "centroide":{
               "lat":-29.3040959439682,
               "lon":-67.5072784622018
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"SAN NICOLAS",
            "id":"46042100000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"San Nicolás",
               "id":"46042100"
            },
            "centroide":{
               "lat":-29.1217634888802,
               "lon":-67.475895961327
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"SANTA FLORENTINA",
            "id":"46042110000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Santa Florentina",
               "id":"46042110"
            },
            "centroide":{
               "lat":-29.1330615805931,
               "lon":-67.5576081781931
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"SAÑOGASTA",
            "id":"46042120000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Sañogasta",
               "id":"46042120"
            },
            "centroide":{
               "lat":-29.3203738957214,
               "lon":-67.6277959527272
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"TILIMUQUI",
            "id":"46042130000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Tilimuqui",
               "id":"46042130"
            },
            "centroide":{
               "lat":-29.1494438672162,
               "lon":-67.4296645756575
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chilecito",
               "id":"460042"
            },
            "departamento":{
               "nombre":"Chilecito",
               "id":"46042"
            },
            "nombre":"VICHIGASTA",
            "id":"46042140000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Vichigasta",
               "id":"46042140"
            },
            "centroide":{
               "lat":-29.4885973737241,
               "lon":-67.50228520978
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"ALTO CARRIZAL",
            "id":"46049010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Alto Carrizal",
               "id":"46049010"
            },
            "centroide":{
               "lat":-28.8736691237645,
               "lon":-67.5689979264386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"ANGULOS",
            "id":"46049020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Angulos",
               "id":"46049020"
            },
            "centroide":{
               "lat":-28.6595753462725,
               "lon":-67.6529605556859
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"ANTINACO",
            "id":"46049030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Antinaco",
               "id":"46049030"
            },
            "centroide":{
               "lat":-28.8250781693493,
               "lon":-67.3233196534937
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"BAJO CARRIZAL",
            "id":"46049040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Bajo Carrizal",
               "id":"46049040"
            },
            "centroide":{
               "lat":-28.8911007689177,
               "lon":-67.5667461563502
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"CAMPANAS",
            "id":"46049050000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Campanas",
               "id":"46049050"
            },
            "centroide":{
               "lat":-28.5560208289878,
               "lon":-67.6264406513676
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"CHAÑARMUYO",
            "id":"46049060000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chañarmuyo",
               "id":"46049060"
            },
            "centroide":{
               "lat":-28.6123449106977,
               "lon":-67.5874723425836
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"FAMATINA",
            "id":"46049070000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Famatina",
               "id":"46049070"
            },
            "centroide":{
               "lat":-28.9291000348798,
               "lon":-67.5207596756722
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"LA CUADRA",
            "id":"46049080000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"La Cuadra",
               "id":"46049080"
            },
            "centroide":{
               "lat":-28.4724817643376,
               "lon":-67.6918652569621
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"PITUIL",
            "id":"46049090000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Pituil",
               "id":"46049090"
            },
            "centroide":{
               "lat":-28.5815077306519,
               "lon":-67.4508379497994
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"PLAZA VIEJA",
            "id":"46049100000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Plaza Vieja",
               "id":"46049100"
            },
            "centroide":{
               "lat":-28.9731598381662,
               "lon":-67.5201315513043
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"SANTA CRUZ",
            "id":"46049110000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Santa Cruz",
               "id":"46049110"
            },
            "centroide":{
               "lat":-28.4907371198,
               "lon":-67.6899252647169
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Famatina",
               "id":"460049"
            },
            "departamento":{
               "nombre":"Famatina",
               "id":"46049"
            },
            "nombre":"SANTO DOMINGO",
            "id":"46049120000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Santo Domingo",
               "id":"46049120"
            },
            "centroide":{
               "lat":-28.5600668428725,
               "lon":-67.6855704208219
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ángel Vicente Peñaloza",
               "id":"460056"
            },
            "departamento":{
               "nombre":"General Ángel V. Peñaloza",
               "id":"46056"
            },
            "nombre":"PUNTA DE LOS LLANOS",
            "id":"46056010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Punta de los Llanos",
               "id":"46056010"
            },
            "centroide":{
               "lat":-30.165433857594,
               "lon":-66.556575245663
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ángel Vicente Peñaloza",
               "id":"460056"
            },
            "departamento":{
               "nombre":"General Ángel V. Peñaloza",
               "id":"46056"
            },
            "nombre":"TAMA",
            "id":"46056020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Tama",
               "id":"46056020"
            },
            "centroide":{
               "lat":-30.5173084388038,
               "lon":-66.5413792482074
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"460063"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"46063"
            },
            "nombre":"CASTRO BARROS",
            "id":"46063010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Castro Barros",
               "id":"46063010"
            },
            "centroide":{
               "lat":-30.5956924271806,
               "lon":-65.7419372748158
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"460063"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"46063"
            },
            "nombre":"CHAÑAR",
            "id":"46063020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chañar",
               "id":"46063020"
            },
            "centroide":{
               "lat":-30.5590304532238,
               "lon":-65.9735931205601
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"460063"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"46063"
            },
            "nombre":"LOMA BLANCA",
            "id":"46063030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Loma Blanca",
               "id":"46063030"
            },
            "centroide":{
               "lat":-30.6446137810742,
               "lon":-66.238741686799
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Belgrano",
               "id":"460063"
            },
            "departamento":{
               "nombre":"General Belgrano",
               "id":"46063"
            },
            "nombre":"OLTA",
            "id":"46063040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Olta",
               "id":"46063040"
            },
            "centroide":{
               "lat":-30.6399759885817,
               "lon":-66.278060412461
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Juan Facundo Quiroga",
               "id":"460070"
            },
            "departamento":{
               "nombre":"General Juan F. Quiroga",
               "id":"46070"
            },
            "nombre":"MALANZAN",
            "id":"46070010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Malanzán",
               "id":"46070010"
            },
            "centroide":{
               "lat":-30.815543887629,
               "lon":-66.6194814951279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Juan Facundo Quiroga",
               "id":"460070"
            },
            "departamento":{
               "nombre":"General Juan F. Quiroga",
               "id":"46070"
            },
            "nombre":"NACATE",
            "id":"46070020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Nácate",
               "id":"46070020"
            },
            "centroide":{
               "lat":-30.8640381344546,
               "lon":-66.4051056083957
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Juan Facundo Quiroga",
               "id":"460070"
            },
            "departamento":{
               "nombre":"General Juan F. Quiroga",
               "id":"46070"
            },
            "nombre":"PORTEZUELO",
            "id":"46070030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Portezuelo",
               "id":"46070030"
            },
            "centroide":{
               "lat":-30.847619186484,
               "lon":-66.7186798501751
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Juan Facundo Quiroga",
               "id":"460070"
            },
            "departamento":{
               "nombre":"General Juan F. Quiroga",
               "id":"46070"
            },
            "nombre":"SAN ANTONIO",
            "id":"46070040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"46070040"
            },
            "centroide":{
               "lat":-31.0900907205412,
               "lon":-66.7571869922571
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Lamadrid",
               "id":"460077"
            },
            "departamento":{
               "nombre":"General Lamadrid",
               "id":"46077"
            },
            "nombre":"VILLA CASTELLI",
            "id":"46077010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Villa Castelli",
               "id":"46077010"
            },
            "centroide":{
               "lat":-29.0207515543035,
               "lon":-68.2253713917754
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ocampo",
               "id":"460084"
            },
            "departamento":{
               "nombre":"General Ocampo",
               "id":"46084"
            },
            "nombre":"AMBIL",
            "id":"46084010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Ambil",
               "id":"46084010"
            },
            "centroide":{
               "lat":-31.1413359277957,
               "lon":-66.3603819377386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ocampo",
               "id":"460084"
            },
            "departamento":{
               "nombre":"General Ocampo",
               "id":"46084"
            },
            "nombre":"COLONIA ORTIZ DE OCAMPO",
            "id":"46084020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Colonia Ortiz de Ocampo",
               "id":"46084020"
            },
            "centroide":{
               "lat":-30.9752909491233,
               "lon":-66.1953883794961
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ocampo",
               "id":"460084"
            },
            "departamento":{
               "nombre":"General Ocampo",
               "id":"46084"
            },
            "nombre":"MILAGRO",
            "id":"46084030000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Milagro",
               "id":"46084030"
            },
            "centroide":{
               "lat":-31.0131717520524,
               "lon":-65.9882554540382
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ocampo",
               "id":"460084"
            },
            "departamento":{
               "nombre":"General Ocampo",
               "id":"46084"
            },
            "nombre":"OLPAS",
            "id":"46084040000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Olpas",
               "id":"46084040"
            },
            "centroide":{
               "lat":-30.8195242375591,
               "lon":-66.2483100669124
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ocampo",
               "id":"460084"
            },
            "departamento":{
               "nombre":"General Ocampo",
               "id":"46084"
            },
            "nombre":"SANTA RITA DE CATUNA",
            "id":"46084050000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Santa Rita de Catuna",
               "id":"46084050"
            },
            "centroide":{
               "lat":-30.9665198359206,
               "lon":-66.2356607957536
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General San Martín",
               "id":"460091"
            },
            "departamento":{
               "nombre":"General San Martín",
               "id":"46091"
            },
            "nombre":"ULAPES",
            "id":"46091010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Ulapes",
               "id":"46091010"
            },
            "centroide":{
               "lat":-31.582978877023,
               "lon":-66.2514026305989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vinchina",
               "id":"460098"
            },
            "departamento":{
               "nombre":"Vinchina",
               "id":"46098"
            },
            "nombre":"JAGUE",
            "id":"46098010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Jagüé",
               "id":"46098010"
            },
            "centroide":{
               "lat":-28.6604900543905,
               "lon":-68.3668741365882
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vinchina",
               "id":"460098"
            },
            "departamento":{
               "nombre":"Vinchina",
               "id":"46098"
            },
            "nombre":"VILLA SAN JOSE DE VINCHINA",
            "id":"46098020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Villa San José de Vinchina",
               "id":"46098020"
            },
            "centroide":{
               "lat":-28.7797111463416,
               "lon":-68.2060368408699
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Independencia",
               "id":"460105"
            },
            "departamento":{
               "nombre":"Independencia",
               "id":"46105"
            },
            "nombre":"AMANA",
            "id":"46105010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Amaná",
               "id":"46105010"
            },
            "centroide":{
               "lat":-30.0611119791655,
               "lon":-67.5084950612514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Independencia",
               "id":"460105"
            },
            "departamento":{
               "nombre":"Independencia",
               "id":"46105"
            },
            "nombre":"PATQUIA",
            "id":"46105020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Patquía",
               "id":"46105020"
            },
            "centroide":{
               "lat":-30.0522114405959,
               "lon":-66.8915886798437
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario Vera Peñaloza",
               "id":"460112"
            },
            "departamento":{
               "nombre":"Rosario Vera Peñaloza",
               "id":"46112"
            },
            "nombre":"CHEPES",
            "id":"46112010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Chepes",
               "id":"46112010"
            },
            "centroide":{
               "lat":-31.3506186314416,
               "lon":-66.6046417930744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario Vera Peñaloza",
               "id":"460112"
            },
            "departamento":{
               "nombre":"Rosario Vera Peñaloza",
               "id":"46112"
            },
            "nombre":"DESIDERIO TELLO",
            "id":"46112020000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Desiderio Tello",
               "id":"46112020"
            },
            "centroide":{
               "lat":-31.2240295500516,
               "lon":-66.3334915975105
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"ALPASINCHE",
            "id":"46119010001",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.3536422425472,
               "lon":-67.080433367311
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"AMUSCHINA",
            "id":"46119010002",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.5555816291232,
               "lon":-67.1592836516078
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"ANDOLUCAS",
            "id":"46119010003",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.5555816291232,
               "lon":-67.1592836516078
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"CHAUPIHUASI",
            "id":"46119010004",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.3536422425472,
               "lon":-67.080433367311
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"CUIPAN",
            "id":"46119010005",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.4517974906601,
               "lon":-67.1178978512143
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"LAS TALAS",
            "id":"46119010006",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.4517974906601,
               "lon":-67.1178978512143
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"LOS ROBLES",
            "id":"46119010007",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.4517974906601,
               "lon":-67.1178978512143
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"SALICAS",
            "id":"46119010008",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.407625810512,
               "lon":-67.0961546311905
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"SAN BLAS",
            "id":"46119010009",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.407625810512,
               "lon":-67.0961546311905
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"SHAQUI",
            "id":"46119010010",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.4812201375784,
               "lon":-67.128235105005
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"SURIYACO",
            "id":"46119010011",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.5555816291232,
               "lon":-67.1592836516078
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Blas de los Sauces",
               "id":"460119"
            },
            "departamento":{
               "nombre":"San Blas de Los Sauces",
               "id":"46119"
            },
            "nombre":"TUYUBIL",
            "id":"46119010012",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Salicas - San Blas",
               "id":"46119010"
            },
            "centroide":{
               "lat":-28.5555816291232,
               "lon":-67.1592836516078
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sanagasta",
               "id":"460126"
            },
            "departamento":{
               "nombre":"Sanagasta",
               "id":"46126"
            },
            "nombre":"VILLA SANAGASTA",
            "id":"46126010000",
            "provincia":{
               "nombre":"La Rioja",
               "id":"46"
            },
            "localidad_censal":{
               "nombre":"Villa Sanagasta",
               "id":"46126010"
            },
            "centroide":{
               "lat":-29.3064491456943,
               "lon":-67.0414097362521
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"1A. SECCION",
            "id":"50007010001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8774938474965,
               "lon":-68.852787067524
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"2A. SECCION",
            "id":"50007010002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8939366401965,
               "lon":-68.8548989896162
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"3A. SECCION",
            "id":"50007010003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8852234170157,
               "lon":-68.8445606513402
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"4A. SECCION",
            "id":"50007010004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8682492511423,
               "lon":-68.842426668283
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"5A. SECCION",
            "id":"50007010005",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8897126457887,
               "lon":-68.8671660840729
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"6A. SECCION",
            "id":"50007010006",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8699135628239,
               "lon":-68.8623511106204
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"7A. SECCION",
            "id":"50007010007",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8737448660325,
               "lon":-68.8758699221604
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"8A. SECCION",
            "id":"50007010008",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8663164516233,
               "lon":-68.8830572695415
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"9A. SECCION",
            "id":"50007010009",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8888290596505,
               "lon":-68.8943435432101
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"10A. SECCION",
            "id":"50007010010",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8762926131736,
               "lon":-68.935890793212
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"50007"
            },
            "nombre":"11A. SECCION",
            "id":"50007010011",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mendoza",
               "id":"50007010"
            },
            "centroide":{
               "lat":-32.8885015214853,
               "lon":-68.9052495878294
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvear",
               "id":"500014"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"50014"
            },
            "nombre":"BOWEN",
            "id":"50014010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Bowen",
               "id":"50014010"
            },
            "centroide":{
               "lat":-35.0009124317209,
               "lon":-67.5161818289008
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvear",
               "id":"500014"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"50014"
            },
            "nombre":"CARMENSA",
            "id":"50014020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Carmensa",
               "id":"50014020"
            },
            "centroide":{
               "lat":-35.1449057898791,
               "lon":-67.662955044003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvear",
               "id":"500014"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"50014"
            },
            "nombre":"GENERAL ALVEAR",
            "id":"50014030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"General Alvear",
               "id":"50014030"
            },
            "centroide":{
               "lat":-34.9804694766564,
               "lon":-67.7009950828176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Alvear",
               "id":"500014"
            },
            "departamento":{
               "nombre":"General Alvear",
               "id":"50014"
            },
            "nombre":"LOS COMPARTOS",
            "id":"50014040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Compartos",
               "id":"50014040"
            },
            "centroide":{
               "lat":-34.9792604557582,
               "lon":-67.6590893207424
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"GODOY CRUZ",
            "id":"50021010001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9215283150576,
               "lon":-68.8549231831208
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"GOBERNADOR BENEGAS",
            "id":"50021010002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9443750737376,
               "lon":-68.8651880637399
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"LAS TORTUGAS",
            "id":"50021010003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9453107154249,
               "lon":-68.8411734730396
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"PRESIDENTE SARMIENTO",
            "id":"50021010004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9171352448748,
               "lon":-68.9190971058586
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"SAN FRANCISCO DEL MONTE",
            "id":"50021010005",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9301248190877,
               "lon":-68.8333388830804
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"VILLA HIPODROMO",
            "id":"50021010006",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9085519196216,
               "lon":-68.8709990197701
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Godoy Cruz",
               "id":"50021"
            },
            "nombre":"VILLA MARINI",
            "id":"50021010007",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Godoy Cruz",
               "id":"50021010"
            },
            "centroide":{
               "lat":-32.9368994348648,
               "lon":-68.8625261619583
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"COLONIA SEGOVIA",
            "id":"50028010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Colonia Segovia",
               "id":"50028010"
            },
            "centroide":{
               "lat":-32.8445720739406,
               "lon":-68.7264015008637
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"BERMEJO",
            "id":"50028020001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.8674494392665,
               "lon":-68.8015983577909
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"BUENA NUEVA",
            "id":"50028020002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.887610360379,
               "lon":-68.7797113648865
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"CAPILLA DEL ROSARIO",
            "id":"50028020003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9057059086968,
               "lon":-68.7753107154796
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy Cruz",
               "id":"500021"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"DORREGO",
            "id":"50028020004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9079284079055,
               "lon":-68.8402487586896
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"EL SAUCE",
            "id":"50028020005",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.866493937014,
               "lon":-68.7583334039687
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"GENERAL BELGRANO",
            "id":"50028020006",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.8819026438146,
               "lon":-68.8087568548896
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"JESUS NAZARENO",
            "id":"50028020007",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9208013873904,
               "lon":-68.7868066982545
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"LAS CAÑAS",
            "id":"50028020008",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9057702142765,
               "lon":-68.8263412149075
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"GUAYMALLEN",
            "id":"50028020009",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.8925289639529,
               "lon":-68.820068537927
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"PEDRO MOLINA",
            "id":"50028020010",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.8749826070419,
               "lon":-68.8287743942338
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"RODEO DE LA CRUZ",
            "id":"50028020011",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9233876380597,
               "lon":-68.7484037679985
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"SAN FRANCISCO DEL MONTE",
            "id":"50028020012",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9176425793206,
               "lon":-68.8127477726945
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capital",
               "id":"500007"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"SAN JOSE",
            "id":"50028020013",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.887910080091,
               "lon":-68.8336077167937
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"VILLA NUEVA",
            "id":"50028020014",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Guaymallén",
               "id":"50028020"
            },
            "centroide":{
               "lat":-32.9010420892697,
               "lon":-68.8031458310547
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"LA PRIMAVERA",
            "id":"50028030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Primavera",
               "id":"50028030"
            },
            "centroide":{
               "lat":-32.9212115151732,
               "lon":-68.6797682708562
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"LOS CORRALITOS",
            "id":"50028040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Corralitos",
               "id":"50028040"
            },
            "centroide":{
               "lat":-32.8978664693342,
               "lon":-68.7059893326547
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Guaymallén",
               "id":"50028"
            },
            "nombre":"PUENTE DE HIERRO",
            "id":"50028050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Puente de Hierro",
               "id":"50028050"
            },
            "centroide":{
               "lat":-32.8596996528436,
               "lon":-68.6889630695891
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"INGENIERO GIAGNONI",
            "id":"50035010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Giagnoni",
               "id":"50035010"
            },
            "centroide":{
               "lat":-33.1247748459194,
               "lon":-68.4208925887498
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"JUNIN",
            "id":"50035020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Junín",
               "id":"50035020"
            },
            "centroide":{
               "lat":-33.1450869905894,
               "lon":-68.4921156012444
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"LA COLONIA",
            "id":"50035030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Colonia",
               "id":"50035030"
            },
            "centroide":{
               "lat":-33.0927994762934,
               "lon":-68.4874957269843
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"LOS BARRIALES",
            "id":"50035040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Barriales",
               "id":"50035040"
            },
            "centroide":{
               "lat":-33.1010405156356,
               "lon":-68.567527971482
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"MEDRANO",
            "id":"50035050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Medrano",
               "id":"50035050"
            },
            "centroide":{
               "lat":-33.1762912284126,
               "lon":-68.6231695474222
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"PHILLIPS",
            "id":"50035060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Phillips",
               "id":"50035060"
            },
            "centroide":{
               "lat":-33.2032507142375,
               "lon":-68.3832295478144
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"50035"
            },
            "nombre":"RODRIGUEZ PEÑA",
            "id":"50035070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Rodríguez Peña",
               "id":"50035070"
            },
            "centroide":{
               "lat":-33.1211519220358,
               "lon":-68.6046800215005
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"500042"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"50042"
            },
            "nombre":"DESAGUADERO",
            "id":"50042010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Desaguadero",
               "id":"50042010"
            },
            "centroide":{
               "lat":-33.4052265769401,
               "lon":-67.1641664357197
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"500042"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"50042"
            },
            "nombre":"LA PAZ",
            "id":"50042020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Paz",
               "id":"50042020"
            },
            "centroide":{
               "lat":-33.4610193184125,
               "lon":-67.5595785270435
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Paz",
               "id":"500042"
            },
            "departamento":{
               "nombre":"La Paz",
               "id":"50042"
            },
            "nombre":"VILLA ANTIGUA",
            "id":"50042030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Villa Antigua",
               "id":"50042030"
            },
            "centroide":{
               "lat":-33.4645156410531,
               "lon":-67.6055785043576
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"BLANCO ENCALADA",
            "id":"50049010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Blanco Encalada",
               "id":"50049010"
            },
            "centroide":{
               "lat":-33.0355485376289,
               "lon":-69.0065315897085
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"JOCOLI",
            "id":"50049030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Jocolí",
               "id":"50049030"
            },
            "centroide":{
               "lat":-32.5843190924112,
               "lon":-68.6808088140348
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"LAS CUEVAS",
            "id":"50049040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Cuevas",
               "id":"50049040"
            },
            "centroide":{
               "lat":-32.8079619437825,
               "lon":-70.0851824266087
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"CAPDEVILA",
            "id":"50049050001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.7248779288865,
               "lon":-68.9085584856171
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"LAS HERAS",
            "id":"50049050002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8499236875213,
               "lon":-68.8452567330483
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"EL ALGARROBAL",
            "id":"50049050003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8327487536079,
               "lon":-68.7957179005563
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"EL BORBOLLON",
            "id":"50049050004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8069208013923,
               "lon":-68.7772864976942
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"EL CHALLAO",
            "id":"50049050005",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8187507127469,
               "lon":-68.9164320667614
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"EL PLUMERILLO",
            "id":"50049050007",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8468358627701,
               "lon":-68.8191474531696
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"EL RESGUARDO",
            "id":"50049050008",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.7650499892594,
               "lon":-68.7913047281368
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"EL ZAPALLAR",
            "id":"50049050009",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8555537707294,
               "lon":-68.8280317752065
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"LA CIENEGUITA",
            "id":"50049050010",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8444580470853,
               "lon":-68.8646206504362
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"PANQUEHUA",
            "id":"50049050011",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.8349236425418,
               "lon":-68.8451652007894
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"SIERRAS DE ENCALADA",
            "id":"50049050012",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"50049050"
            },
            "centroide":{
               "lat":-32.9869460291704,
               "lon":-68.9485091767272
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"LOS PENITENTES",
            "id":"50049060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Penitentes",
               "id":"50049060"
            },
            "centroide":{
               "lat":-32.8369504266306,
               "lon":-69.853921289708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"POLVAREDAS",
            "id":"50049080000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Polvaredas",
               "id":"50049080"
            },
            "centroide":{
               "lat":-32.8190384814445,
               "lon":-69.7097346552796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"PUENTE DEL INCA",
            "id":"50049090000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Puente del Inca",
               "id":"50049090"
            },
            "centroide":{
               "lat":-32.8198835580194,
               "lon":-69.9250020499226
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"PUNTA DE VACAS",
            "id":"50049100000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Punta de Vacas",
               "id":"50049100"
            },
            "centroide":{
               "lat":-32.8488063589156,
               "lon":-69.7763007026825
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Las Heras",
               "id":"50049"
            },
            "nombre":"USPALLATA",
            "id":"50049110000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Uspallata",
               "id":"50049110"
            },
            "centroide":{
               "lat":-32.595534534037,
               "lon":-69.3582053741335
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO ALTO DEL OLVIDO",
            "id":"50056010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Alto del Olvido",
               "id":"50056010"
            },
            "centroide":{
               "lat":-32.6727794218396,
               "lon":-68.5922473767644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO JOCOLI II",
            "id":"50056020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Jocolí II",
               "id":"50056020"
            },
            "centroide":{
               "lat":-32.6740793089036,
               "lon":-68.6692696211749
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO LA PALMERA",
            "id":"50056030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio La Palmera",
               "id":"50056030"
            },
            "centroide":{
               "lat":-32.6969328275291,
               "lon":-68.5506213221534
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO LA PEGA",
            "id":"50056040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio La Pega",
               "id":"50056040"
            },
            "centroide":{
               "lat":-32.8128590043094,
               "lon":-68.6835373227479
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO LAGUNAS DE BARTOLUZZI",
            "id":"50056050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Lagunas de Bartoluzzi",
               "id":"50056050"
            },
            "centroide":{
               "lat":-32.6111088672137,
               "lon":-68.5714746331489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO LOS JARILLEROS",
            "id":"50056060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Los Jarilleros",
               "id":"50056060"
            },
            "centroide":{
               "lat":-32.7153965740662,
               "lon":-68.6584021819475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO LOS OLIVOS",
            "id":"50056070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Los Olivos",
               "id":"50056070"
            },
            "centroide":{
               "lat":-32.6972155730911,
               "lon":-68.3295580099939
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO VIRGEN DEL ROSARIO",
            "id":"50056075000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Virgen del Rosario",
               "id":"50056075"
            },
            "centroide":{
               "lat":-32.7027597161508,
               "lon":-68.3124921903406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"COSTA DE ARAUJO",
            "id":"50056080000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Costa de Araujo",
               "id":"50056080"
            },
            "centroide":{
               "lat":-32.7568943573314,
               "lon":-68.4085951866641
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"EL PARAMILLO",
            "id":"50056090000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Paramillo",
               "id":"50056090"
            },
            "centroide":{
               "lat":-32.7813088245414,
               "lon":-68.5336830930131
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"EL VERGEL",
            "id":"50056100000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Vergel",
               "id":"50056100"
            },
            "centroide":{
               "lat":-32.7623132216047,
               "lon":-68.6476011792189
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"BARRIO LA ESPERANZA",
            "id":"50056110001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Gustavo André",
               "id":"50056110"
            },
            "centroide":{
               "lat":-32.6735728612892,
               "lon":-68.3840802981371
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"INGENIERO GUSTAVO ANDRE",
            "id":"50056110002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Gustavo André",
               "id":"50056110"
            },
            "centroide":{
               "lat":-32.6824002043157,
               "lon":-68.3268969073829
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"JOCOLI",
            "id":"50056120000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Jocolí",
               "id":"50056120"
            },
            "centroide":{
               "lat":-32.6125410510042,
               "lon":-68.6790408967958
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"JOCOLI VIEJO",
            "id":"50056130000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Jocolí Viejo",
               "id":"50056130"
            },
            "centroide":{
               "lat":-32.7274623415005,
               "lon":-68.6602319575528
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"LAS VIOLETAS",
            "id":"50056140000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Violetas",
               "id":"50056140"
            },
            "centroide":{
               "lat":-32.8208151905733,
               "lon":-68.6164140763718
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"3 DE MAYO",
            "id":"50056150000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"3 de Mayo",
               "id":"50056150"
            },
            "centroide":{
               "lat":-32.675527945267,
               "lon":-68.6462113031921
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavalle",
               "id":"500056"
            },
            "departamento":{
               "nombre":"Lavalle",
               "id":"50056"
            },
            "nombre":"VILLA TULUMAYA",
            "id":"50056160000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Villa Tulumaya",
               "id":"50056160"
            },
            "centroide":{
               "lat":-32.7196142429269,
               "lon":-68.6030438537926
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"AGRELO",
            "id":"50063010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Agrelo",
               "id":"50063010"
            },
            "centroide":{
               "lat":-33.1173844439975,
               "lon":-68.8960235351828
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"BARRIO PERDRIEL IV",
            "id":"50063020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Perdriel IV",
               "id":"50063020"
            },
            "centroide":{
               "lat":-33.0753282280469,
               "lon":-68.9250404043338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"CACHEUTA",
            "id":"50063030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Cacheuta",
               "id":"50063030"
            },
            "centroide":{
               "lat":-33.0367104413315,
               "lon":-69.1159068278817
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"COSTA FLORES",
            "id":"50063040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Costa Flores",
               "id":"50063040"
            },
            "centroide":{
               "lat":-33.0705184504059,
               "lon":-68.9343214868201
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"EL CARRIZAL",
            "id":"50063050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Carrizal",
               "id":"50063050"
            },
            "centroide":{
               "lat":-33.3037433557192,
               "lon":-68.7554951968491
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"EL CARMELO",
            "id":"50063060001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Salto",
               "id":"50063060"
            },
            "centroide":{
               "lat":-32.9551111218674,
               "lon":-69.281192394514
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"EL SALTO",
            "id":"50063060002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Salto",
               "id":"50063060"
            },
            "centroide":{
               "lat":-32.8994781760003,
               "lon":-69.3336484431929
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"LAS CARDITAS",
            "id":"50063060003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Salto",
               "id":"50063060"
            },
            "centroide":{
               "lat":-32.9551111218674,
               "lon":-69.281192394514
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"LOS MANANTIALES",
            "id":"50063060004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Salto",
               "id":"50063060"
            },
            "centroide":{
               "lat":-32.8990128008468,
               "lon":-69.3340868979844
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"LAS COMPUERTAS",
            "id":"50063070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Compuertas",
               "id":"50063070"
            },
            "centroide":{
               "lat":-33.0343953638207,
               "lon":-68.9757201428593
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"LAS VEGAS",
            "id":"50063080001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Vegas",
               "id":"50063080"
            },
            "centroide":{
               "lat":-33.0058788620171,
               "lon":-69.2764002200338
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"PIEDRAS BLANCAS",
            "id":"50063080002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Vegas",
               "id":"50063080"
            },
            "centroide":{
               "lat":-33.0198804741995,
               "lon":-69.3030036808918
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"VALLE DEL SOL",
            "id":"50063080003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Vegas",
               "id":"50063080"
            },
            "centroide":{
               "lat":-33.0174255607471,
               "lon":-69.2972914626044
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"VILLA EL REFUGIO",
            "id":"50063080004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Vegas",
               "id":"50063080"
            },
            "centroide":{
               "lat":-32.9975296309707,
               "lon":-69.317680250878
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"CARRODILLA",
            "id":"50063090001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Luján de Cuyo",
               "id":"50063090"
            },
            "centroide":{
               "lat":-32.9776578285745,
               "lon":-68.8524394649607
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"CHACRAS DE CORIA",
            "id":"50063090002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Luján de Cuyo",
               "id":"50063090"
            },
            "centroide":{
               "lat":-32.9899032062811,
               "lon":-68.8913679185275
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"LUJAN DE CUYO",
            "id":"50063090003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Luján de Cuyo",
               "id":"50063090"
            },
            "centroide":{
               "lat":-33.0328665626243,
               "lon":-68.8912066261393
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Heras",
               "id":"500049"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"LA PUNTILLA",
            "id":"50063090004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Luján de Cuyo",
               "id":"50063090"
            },
            "centroide":{
               "lat":-32.9609713860174,
               "lon":-68.8723323876365
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"MAYOR DRUMMOND",
            "id":"50063090005",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Luján de Cuyo",
               "id":"50063090"
            },
            "centroide":{
               "lat":-33.0038391611615,
               "lon":-68.8612966964436
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"VISTALBA",
            "id":"50063090006",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Luján de Cuyo",
               "id":"50063090"
            },
            "centroide":{
               "lat":-33.0237918976098,
               "lon":-68.9247432402669
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"BARRIO ADINA I Y II",
            "id":"50063100001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Perdriel",
               "id":"50063100"
            },
            "centroide":{
               "lat":-33.0649388173829,
               "lon":-68.8877014932113
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"PERDRIEL",
            "id":"50063100002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Perdriel",
               "id":"50063100"
            },
            "centroide":{
               "lat":-33.0690096392441,
               "lon":-68.8986010698045
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"POTRERILLOS",
            "id":"50063110000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Potrerillos",
               "id":"50063110"
            },
            "centroide":{
               "lat":-32.945486814506,
               "lon":-69.2086449063504
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luján de Cuyo",
               "id":"500063"
            },
            "departamento":{
               "nombre":"Luján de Cuyo",
               "id":"50063"
            },
            "nombre":"UGARTECHE",
            "id":"50063120000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Ugarteche",
               "id":"50063120"
            },
            "centroide":{
               "lat":-33.2109451573216,
               "lon":-68.8972088041994
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"BARRANCAS",
            "id":"50070010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrancas",
               "id":"50070010"
            },
            "centroide":{
               "lat":-33.0833355400244,
               "lon":-68.7343792557021
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"BARRIO JESUS DE NAZARET",
            "id":"50070020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Jesús de Nazaret",
               "id":"50070020"
            },
            "centroide":{
               "lat":-33.0103244179699,
               "lon":-68.7265920537236
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"CRUZ DE PIEDRA",
            "id":"50070030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Cruz de Piedra",
               "id":"50070030"
            },
            "centroide":{
               "lat":-33.0293391100097,
               "lon":-68.7922412248266
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaymallén",
               "id":"500028"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"EL PEDREGAL",
            "id":"50070040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Pedregal",
               "id":"50070040"
            },
            "centroide":{
               "lat":-32.8558805014704,
               "lon":-68.6544793531012
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"FRAY LUIS BELTRAN",
            "id":"50070050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Fray Luis Beltrán",
               "id":"50070050"
            },
            "centroide":{
               "lat":-33.0049403047291,
               "lon":-68.6612377129718
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"COQUIMBITO",
            "id":"50070060001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Maipú",
               "id":"50070060"
            },
            "centroide":{
               "lat":-32.9792545593489,
               "lon":-68.8089838444183
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"GENERAL GUTIERREZ",
            "id":"50070060002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Maipú",
               "id":"50070060"
            },
            "centroide":{
               "lat":-32.9797721809011,
               "lon":-68.769429675373
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"LUZURIAGA",
            "id":"50070060003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Maipú",
               "id":"50070060"
            },
            "centroide":{
               "lat":-32.9500582112409,
               "lon":-68.8034450473205
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"MAIPU",
            "id":"50070060004",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Maipú",
               "id":"50070060"
            },
            "centroide":{
               "lat":-32.9394310178507,
               "lon":-68.8068438760291
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"RODEO DEL MEDIO",
            "id":"50070070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Rodeo del Medio",
               "id":"50070070"
            },
            "centroide":{
               "lat":-32.9880056754694,
               "lon":-68.7010652608485
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"RUSSELL",
            "id":"50070080000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Russell",
               "id":"50070080"
            },
            "centroide":{
               "lat":-33.0046770772729,
               "lon":-68.8012715093769
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"SAN ROQUE",
            "id":"50070090000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San Roque",
               "id":"50070090"
            },
            "centroide":{
               "lat":-33.0285638174611,
               "lon":-68.5981475648442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maipú",
               "id":"500070"
            },
            "departamento":{
               "nombre":"Maipú",
               "id":"50070"
            },
            "nombre":"VILLA TERESA",
            "id":"50070100000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Villa Teresa",
               "id":"50070100"
            },
            "centroide":{
               "lat":-33.0183178912335,
               "lon":-68.6288154157882
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malargüe",
               "id":"500077"
            },
            "departamento":{
               "nombre":"Malargüe",
               "id":"50077"
            },
            "nombre":"AGUA ESCONDIDA",
            "id":"50077010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Agua Escondida",
               "id":"50077010"
            },
            "centroide":{
               "lat":-36.1532390359812,
               "lon":-68.3048428543176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malargüe",
               "id":"500077"
            },
            "departamento":{
               "nombre":"Malargüe",
               "id":"50077"
            },
            "nombre":"LAS LEÑAS",
            "id":"50077030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Leñas",
               "id":"50077030"
            },
            "centroide":{
               "lat":-35.1539650781638,
               "lon":-70.0817910991633
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malargüe",
               "id":"500077"
            },
            "departamento":{
               "nombre":"Malargüe",
               "id":"50077"
            },
            "nombre":"MALARGUE",
            "id":"50077040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Malargüe",
               "id":"50077040"
            },
            "centroide":{
               "lat":-35.4770107120892,
               "lon":-69.5886491865783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"ANDRADE",
            "id":"50084010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Andrade",
               "id":"50084010"
            },
            "centroide":{
               "lat":-33.1624680014146,
               "lon":-68.506066911759
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"BARRIO COOPERATIVA LOS CAMPAMENTOS",
            "id":"50084020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Cooperativa Los Campamentos",
               "id":"50084020"
            },
            "centroide":{
               "lat":-33.2692489302548,
               "lon":-68.4383631651486
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"BARRIO RIVADAVIA",
            "id":"50084030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Rivadavia",
               "id":"50084030"
            },
            "centroide":{
               "lat":-33.2304643070476,
               "lon":-68.4837256054182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"EL MIRADOR",
            "id":"50084040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Mirador",
               "id":"50084040"
            },
            "centroide":{
               "lat":-33.2906467543213,
               "lon":-68.3475556585345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"LA CENTRAL",
            "id":"50084050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Central",
               "id":"50084050"
            },
            "centroide":{
               "lat":-33.2736424903477,
               "lon":-68.3224073182181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"LA ESPERANZA",
            "id":"50084060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Esperanza",
               "id":"50084060"
            },
            "centroide":{
               "lat":-33.3170659709211,
               "lon":-68.3343426790403
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"CUADRO ORTEGA",
            "id":"50084070001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Florida",
               "id":"50084070"
            },
            "centroide":{
               "lat":-33.282949271283,
               "lon":-68.4657441362603
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"LA FLORIDA",
            "id":"50084070002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Florida",
               "id":"50084070"
            },
            "centroide":{
               "lat":-33.2827922646265,
               "lon":-68.4657172961175
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"LA LIBERTAD",
            "id":"50084080000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Libertad",
               "id":"50084080"
            },
            "centroide":{
               "lat":-33.2163869013321,
               "lon":-68.5166268853637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"LOS ARBOLES",
            "id":"50084090000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Árboles",
               "id":"50084090"
            },
            "centroide":{
               "lat":-33.1813162523339,
               "lon":-68.5759160345244
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"LOS CAMPAMENTOS",
            "id":"50084100000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Campamentos",
               "id":"50084100"
            },
            "centroide":{
               "lat":-33.2763607628549,
               "lon":-68.4008493516637
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"MEDRANO",
            "id":"50084110000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Medrano",
               "id":"50084110"
            },
            "centroide":{
               "lat":-33.1787463382932,
               "lon":-68.6219271508795
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"MUNDO NUEVO",
            "id":"50084120000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Mundo Nuevo",
               "id":"50084120"
            },
            "centroide":{
               "lat":-33.1753415989261,
               "lon":-68.4371725862256
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"REDUCCION DE ABAJO",
            "id":"50084130000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Reducción de Abajo",
               "id":"50084130"
            },
            "centroide":{
               "lat":-33.2063088772501,
               "lon":-68.5835152115814
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"RIVADAVIA",
            "id":"50084140000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Rivadavia",
               "id":"50084140"
            },
            "centroide":{
               "lat":-33.19403215031,
               "lon":-68.4736188877526
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"500084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"50084"
            },
            "nombre":"SANTA MARIA DE ORO",
            "id":"50084150000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Santa María de Oro",
               "id":"50084150"
            },
            "centroide":{
               "lat":-33.2059806305116,
               "lon":-68.4335589109359
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"BARRIO CARRASCO",
            "id":"50091005000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Carrasco",
               "id":"50091005"
            },
            "centroide":{
               "lat":-33.8321066130502,
               "lon":-69.0490032074462
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"BARRIO EL CEPILLO",
            "id":"50091010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio El Cepillo",
               "id":"50091010"
            },
            "centroide":{
               "lat":-33.8386533809471,
               "lon":-69.1303497215532
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"CHILECITO",
            "id":"50091020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Chilecito",
               "id":"50091020"
            },
            "centroide":{
               "lat":-33.8934020968296,
               "lon":-69.0804633582269
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"EUGENIO BUSTOS",
            "id":"50091030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Eugenio Bustos",
               "id":"50091030"
            },
            "centroide":{
               "lat":-33.7850224488548,
               "lon":-69.0729045258216
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"LA CONSULTA",
            "id":"50091040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Consulta",
               "id":"50091040"
            },
            "centroide":{
               "lat":-33.7386757381913,
               "lon":-69.1281255649691
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"PAREDITAS",
            "id":"50091050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Pareditas",
               "id":"50091050"
            },
            "centroide":{
               "lat":-33.9452563037172,
               "lon":-69.0824859034273
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"500091"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"50091"
            },
            "nombre":"SAN CARLOS",
            "id":"50091060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San Carlos",
               "id":"50091060"
            },
            "centroide":{
               "lat":-33.7744494556708,
               "lon":-69.0490909178745
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"ALTO VERDE",
            "id":"50098020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Alto Verde",
               "id":"50098020"
            },
            "centroide":{
               "lat":-33.1195037386563,
               "lon":-68.4180218961221
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"BARRIO CHIVILCOY",
            "id":"50098030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Chivilcoy",
               "id":"50098030"
            },
            "centroide":{
               "lat":-33.0411467362082,
               "lon":-68.3541527366296
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"BARRIO EMANUEL",
            "id":"50098040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Emanuel",
               "id":"50098040"
            },
            "centroide":{
               "lat":-32.8468555962866,
               "lon":-68.4100199226668
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín",
               "id":"500035"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"BARRIO LA ESTACION",
            "id":"50098045000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio La Estación",
               "id":"50098045"
            },
            "centroide":{
               "lat":-33.155290161095,
               "lon":-68.3543621643557
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"BARRIO LOS CHARABONES",
            "id":"50098050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Los Charabones",
               "id":"50098050"
            },
            "centroide":{
               "lat":-32.9785115683367,
               "lon":-68.3051018239824
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"BARRIO NTRA. SRA. DE FATIMA",
            "id":"50098055000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Ntra. Sra. De Fátima",
               "id":"50098055"
            },
            "centroide":{
               "lat":-33.1309714824444,
               "lon":-68.351277983232
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"CHAPANAY",
            "id":"50098060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Chapanay",
               "id":"50098060"
            },
            "centroide":{
               "lat":-32.9801177295667,
               "lon":-68.4765253845307
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"CHIVILCOY",
            "id":"50098070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Chivilcoy",
               "id":"50098070"
            },
            "centroide":{
               "lat":-33.038335582925,
               "lon":-68.4594029398778
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"EL ESPINO",
            "id":"50098073000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Espino",
               "id":"50098073"
            },
            "centroide":{
               "lat":-33.077333062302,
               "lon":-68.3982838200909
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"EL RAMBLON",
            "id":"50098077000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Ramblón",
               "id":"50098077"
            },
            "centroide":{
               "lat":-33.1617709736953,
               "lon":-68.2954844260235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"MONTECASEROS",
            "id":"50098080000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Montecaseros",
               "id":"50098080"
            },
            "centroide":{
               "lat":-33.0109966347898,
               "lon":-68.3936801268915
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"NUEVA CALIFORNIA",
            "id":"50098090000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Nueva California",
               "id":"50098090"
            },
            "centroide":{
               "lat":-32.7450606095364,
               "lon":-68.335450804198
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"PALMIRA",
            "id":"50098100001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San Martín",
               "id":"50098100"
            },
            "centroide":{
               "lat":-33.0499932818968,
               "lon":-68.5490896779466
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"CIUDAD DE SAN MARTIN",
            "id":"50098100002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San Martín",
               "id":"50098100"
            },
            "centroide":{
               "lat":-33.0732178033808,
               "lon":-68.4605125505865
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"500098"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"50098"
            },
            "nombre":"TRES PORTEÑAS",
            "id":"50098110000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Tres Porteñas",
               "id":"50098110"
            },
            "centroide":{
               "lat":-32.8994801779561,
               "lon":-68.3990962886724
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO CAMPOS EL TOLEDANO",
            "id":"50105010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Campos El Toledano",
               "id":"50105010"
            },
            "centroide":{
               "lat":-34.573654040475,
               "lon":-68.3294341868083
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO EL NEVADO",
            "id":"50105020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio El Nevado",
               "id":"50105020"
            },
            "centroide":{
               "lat":-34.7898637210549,
               "lon":-67.9874651693673
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO EMPLEADOS DE COMERCIO",
            "id":"50105030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Empleados de Comercio",
               "id":"50105030"
            },
            "centroide":{
               "lat":-34.5522300096664,
               "lon":-68.3032401705615
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO INTENDENCIA",
            "id":"50105040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Intendencia",
               "id":"50105040"
            },
            "centroide":{
               "lat":-34.645100562517,
               "lon":-68.2754543992412
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"CAPITAN MONTOYA",
            "id":"50105050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Capitán Montoya",
               "id":"50105050"
            },
            "centroide":{
               "lat":-34.5835613146884,
               "lon":-68.4654391114406
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO ECHEVERRIA",
            "id":"50105060001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Cuadro Benegas",
               "id":"50105060"
            },
            "centroide":{
               "lat":-34.6278079094528,
               "lon":-68.4330730738277
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO LAS ROSAS",
            "id":"50105060002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Cuadro Benegas",
               "id":"50105060"
            },
            "centroide":{
               "lat":-34.6278079094528,
               "lon":-68.4330730738277
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"BARRIO PRIMAVERA",
            "id":"50105060003",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Cuadro Benegas",
               "id":"50105060"
            },
            "centroide":{
               "lat":-34.6298100948936,
               "lon":-68.5105923910427
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"EL NIHUIL",
            "id":"50105070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Nihuil",
               "id":"50105070"
            },
            "centroide":{
               "lat":-35.0353713864246,
               "lon":-68.6806149266928
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"EL SOSNEADO",
            "id":"50105080000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Sosneado",
               "id":"50105080"
            },
            "centroide":{
               "lat":-35.0821772227253,
               "lon":-69.5924127887533
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"EL TROPEZON",
            "id":"50105090000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Tropezón",
               "id":"50105090"
            },
            "centroide":{
               "lat":-34.6856449091453,
               "lon":-68.2855505604444
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"GOUDGE",
            "id":"50105100000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Goudge",
               "id":"50105100"
            },
            "centroide":{
               "lat":-34.6803228490363,
               "lon":-68.1358422919666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"JAIME PRATS",
            "id":"50105110000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Jaime Prats",
               "id":"50105110"
            },
            "centroide":{
               "lat":-34.9146831219286,
               "lon":-67.8191470107259
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"LA LLAVE NUEVA",
            "id":"50105120000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Llave Nueva",
               "id":"50105120"
            },
            "centroide":{
               "lat":-34.6449297466889,
               "lon":-68.0147704723126
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"LAS MALVINAS",
            "id":"50105130000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Malvinas",
               "id":"50105130"
            },
            "centroide":{
               "lat":-34.8384149976168,
               "lon":-68.2539362219611
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"LOS REYUNOS",
            "id":"50105140000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Reyunos",
               "id":"50105140"
            },
            "centroide":{
               "lat":-34.6099288763693,
               "lon":-68.6205430790631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"MONTE COMAN",
            "id":"50105150000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Monte Comán",
               "id":"50105150"
            },
            "centroide":{
               "lat":-34.5971809945468,
               "lon":-67.8842899055404
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"POBRE DIABLO",
            "id":"50105160000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Pobre Diablo",
               "id":"50105160"
            },
            "centroide":{
               "lat":-34.6703587937035,
               "lon":-68.3591845637218
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"PUNTA DEL AGUA",
            "id":"50105170000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Punta del Agua",
               "id":"50105170"
            },
            "centroide":{
               "lat":-35.530165567926,
               "lon":-68.0825092985548
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"RAMA CAIDA",
            "id":"50105180000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Rama Caída",
               "id":"50105180"
            },
            "centroide":{
               "lat":-34.7043760307316,
               "lon":-68.3708631047738
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"REAL DEL PADRE",
            "id":"50105190000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Real del Padre",
               "id":"50105190"
            },
            "centroide":{
               "lat":-34.8433149755939,
               "lon":-67.7674203749059
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"SALTO DE LAS ROSAS",
            "id":"50105200000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Salto de las Rosas",
               "id":"50105200"
            },
            "centroide":{
               "lat":-34.726958284268,
               "lon":-68.2329031579027
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"CUADRO NACIONAL",
            "id":"50105210001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San Rafael",
               "id":"50105210"
            },
            "centroide":{
               "lat":-34.6175050676523,
               "lon":-68.2817525680486
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"SAN RAFAEL",
            "id":"50105210002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San Rafael",
               "id":"50105210"
            },
            "centroide":{
               "lat":-34.6356104202068,
               "lon":-68.3310920132847
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"25 DE MAYO",
            "id":"50105220000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"25 de Mayo",
               "id":"50105220"
            },
            "centroide":{
               "lat":-34.5859138924719,
               "lon":-68.5495682219631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"VILLA ATUEL",
            "id":"50105230000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Villa Atuel",
               "id":"50105230"
            },
            "centroide":{
               "lat":-34.8345022006919,
               "lon":-67.9257905889932
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Rafael",
               "id":"500105"
            },
            "departamento":{
               "nombre":"San Rafael",
               "id":"50105"
            },
            "nombre":"VILLA ATUEL NORTE",
            "id":"50105240000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Villa Atuel Norte",
               "id":"50105240"
            },
            "centroide":{
               "lat":-34.7605998347692,
               "lon":-68.0374059028043
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"500112"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"50112"
            },
            "nombre":"BARRIO 12 DE OCTUBRE",
            "id":"50112010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio 12 de Octubre",
               "id":"50112010"
            },
            "centroide":{
               "lat":-33.1912015360595,
               "lon":-68.2809624398751
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"500112"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"50112"
            },
            "nombre":"BARRIO MARIA AUXILIADORA",
            "id":"50112020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio María Auxiliadora",
               "id":"50112020"
            },
            "centroide":{
               "lat":-33.2425136237648,
               "lon":-68.1826758332982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"500112"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"50112"
            },
            "nombre":"BARRIO MOLINA CABRERA",
            "id":"50112030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Molina Cabrera",
               "id":"50112030"
            },
            "centroide":{
               "lat":-33.1297119555186,
               "lon":-68.2037878560507
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"500112"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"50112"
            },
            "nombre":"LA DORMIDA",
            "id":"50112040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Dormida",
               "id":"50112040"
            },
            "centroide":{
               "lat":-33.3499243562079,
               "lon":-67.9160460628593
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"500112"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"50112"
            },
            "nombre":"LAS CATITAS",
            "id":"50112050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Las Catitas",
               "id":"50112050"
            },
            "centroide":{
               "lat":-33.3001058785583,
               "lon":-68.0532182373693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"500112"
            },
            "departamento":{
               "nombre":"Santa Rosa",
               "id":"50112"
            },
            "nombre":"SANTA ROSA",
            "id":"50112060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa",
               "id":"50112060"
            },
            "centroide":{
               "lat":-33.2544227888887,
               "lon":-68.1567438797332
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"BARRIO SAN CAYETANO",
            "id":"50119010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio San Cayetano",
               "id":"50119010"
            },
            "centroide":{
               "lat":-33.6339135898779,
               "lon":-69.1879023870713
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"CAMPO LOS ANDES",
            "id":"50119020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Campo Los Andes",
               "id":"50119020"
            },
            "centroide":{
               "lat":-33.707078744557,
               "lon":-69.1826331369796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"COLONIA LAS ROSAS",
            "id":"50119030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Colonia Las Rosas",
               "id":"50119030"
            },
            "centroide":{
               "lat":-33.6129364669522,
               "lon":-69.1126485401013
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"EL MANZANO",
            "id":"50119040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Manzano",
               "id":"50119040"
            },
            "centroide":{
               "lat":-33.6005741925646,
               "lon":-69.3350878551815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"LOS SAUCES",
            "id":"50119050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Los Sauces",
               "id":"50119050"
            },
            "centroide":{
               "lat":-33.5952101670008,
               "lon":-69.1840214848841
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"TUNUYAN",
            "id":"50119060000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Tunuyán",
               "id":"50119060"
            },
            "centroide":{
               "lat":-33.5775549686559,
               "lon":-69.0253759357637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tunuyán",
               "id":"500119"
            },
            "departamento":{
               "nombre":"Tunuyán",
               "id":"50119"
            },
            "nombre":"VISTA FLORES",
            "id":"50119070000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Vista Flores",
               "id":"50119070"
            },
            "centroide":{
               "lat":-33.651355463945,
               "lon":-69.1646922660824
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"BARRIO BELGRANO NORTE",
            "id":"50126010000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Barrio Belgrano Norte",
               "id":"50126010"
            },
            "centroide":{
               "lat":-33.3301032817585,
               "lon":-69.1359513617454
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"CORDON DEL PLATA",
            "id":"50126020000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Cordón del Plata",
               "id":"50126020"
            },
            "centroide":{
               "lat":-33.4689491349532,
               "lon":-69.1399451802858
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"EL PERAL",
            "id":"50126030000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Peral",
               "id":"50126030"
            },
            "centroide":{
               "lat":-33.3706550679362,
               "lon":-69.1923487111206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"EL ZAMPAL",
            "id":"50126035000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"El Zampal",
               "id":"50126035"
            },
            "centroide":{
               "lat":-33.3989500475535,
               "lon":-69.1057367245591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"LA ARBOLEDA",
            "id":"50126040000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"La Arboleda",
               "id":"50126040"
            },
            "centroide":{
               "lat":-33.3688267818118,
               "lon":-69.1247363670469
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"SAN JOSE",
            "id":"50126050000",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"50126050"
            },
            "centroide":{
               "lat":-33.3068450101282,
               "lon":-69.1645780862147
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"TUPUNGATO",
            "id":"50126060001",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Tupungato",
               "id":"50126060"
            },
            "centroide":{
               "lat":-33.3643638135734,
               "lon":-69.1513190958561
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tupungato",
               "id":"500126"
            },
            "departamento":{
               "nombre":"Tupungato",
               "id":"50126"
            },
            "nombre":"VILLA BASTIAS",
            "id":"50126060002",
            "provincia":{
               "nombre":"Mendoza",
               "id":"50"
            },
            "localidad_censal":{
               "nombre":"Tupungato",
               "id":"50126060"
            },
            "centroide":{
               "lat":-33.3420986445545,
               "lon":-69.1487758488674
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Apóstoles",
               "id":"540007"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"APOSTOLES",
            "id":"54007010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Apóstoles",
               "id":"54007010"
            },
            "centroide":{
               "lat":-27.909806877888,
               "lon":-55.7532126178864
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azara",
               "id":"540014"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"AZARA",
            "id":"54007020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Azara",
               "id":"54007020"
            },
            "centroide":{
               "lat":-28.0572512053051,
               "lon":-55.6767993766976
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Apóstoles",
               "id":"540007"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"BARRIO RURAL",
            "id":"54007025000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Rural",
               "id":"54007025"
            },
            "centroide":{
               "lat":-27.8839188226826,
               "lon":-55.7857809648065
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Apóstoles",
               "id":"540007"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"ESTACION APOSTOLES",
            "id":"54007030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Estación Apóstoles",
               "id":"54007030"
            },
            "centroide":{
               "lat":-27.9079594261468,
               "lon":-55.8079584118965
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"540021"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"PINDAPOY",
            "id":"54007040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Pindapoy",
               "id":"54007040"
            },
            "centroide":{
               "lat":-27.7474272299697,
               "lon":-55.7932485753782
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Azara",
               "id":"540014"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"RINCON DE AZARA",
            "id":"54007050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Rincón de Azara",
               "id":"54007050"
            },
            "centroide":{
               "lat":-28.108077909743,
               "lon":-55.6318879848456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José",
               "id":"540021"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"SAN JOSE",
            "id":"54007060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San José",
               "id":"54007060"
            },
            "centroide":{
               "lat":-27.7653179990542,
               "lon":-55.7746920758949
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tres Capones",
               "id":"540028"
            },
            "departamento":{
               "nombre":"Apóstoles",
               "id":"54007"
            },
            "nombre":"TRES CAPONES",
            "id":"54007070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Tres Capones",
               "id":"54007070"
            },
            "centroide":{
               "lat":-28.0000872703207,
               "lon":-55.608042877744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aristóbulo del Valle",
               "id":"540035"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"ARISTOBULO DEL VALLE",
            "id":"54014010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Aristóbulo del Valle",
               "id":"54014010"
            },
            "centroide":{
               "lat":-27.0952902000827,
               "lon":-54.8949054572105
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"540042"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"CAMPO GRANDE",
            "id":"54014020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Campo Grande",
               "id":"54014020"
            },
            "centroide":{
               "lat":-27.2062681295066,
               "lon":-54.979095503669
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dos de Mayo",
               "id":"540049"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"DOS DE MAYO NUCLEO I",
            "id":"54014030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Dos de Mayo",
               "id":"54014030"
            },
            "centroide":{
               "lat":-27.0205393332885,
               "lon":-54.6877327006159
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dos de Mayo",
               "id":"540049"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"NUCLEO I",
            "id":"54014030001",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Dos de Mayo",
               "id":"54014030"
            },
            "centroide":{
               "lat":-26.9808943053968,
               "lon":-54.7030774930753
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dos de Mayo",
               "id":"540049"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"NUCLEO II",
            "id":"54014030002",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Dos de Mayo",
               "id":"54014030"
            },
            "centroide":{
               "lat":-27.0234502899983,
               "lon":-54.6788256442778
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dos de Mayo",
               "id":"540049"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"DOS DE MAYO NUCLEO III",
            "id":"54014050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Dos de Mayo Nucleo III (Bº Bernardino Rivadavia)",
               "id":"54014050"
            },
            "centroide":{
               "lat":-27.0065768537216,
               "lon":-54.6123536244069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"540042"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"KILOMETRO 17 (RUTA 8)",
            "id":"54014055000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Kilómetro 17",
               "id":"54014055"
            },
            "centroide":{
               "lat":-27.3105260940585,
               "lon":-54.9030555649231
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"540042"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"1 DE MAYO",
            "id":"54014060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"1º de Mayo",
               "id":"54014060"
            },
            "centroide":{
               "lat":-27.1660848947707,
               "lon":-55.029165269622
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dos de Mayo",
               "id":"540049"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"PUEBLO ILLIA",
            "id":"54014070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Pueblo Illia",
               "id":"54014070"
            },
            "centroide":{
               "lat":-27.1485153432864,
               "lon":-54.5624940052185
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aristóbulo del Valle",
               "id":"540035"
            },
            "departamento":{
               "nombre":"Cainguás",
               "id":"54014"
            },
            "nombre":"SALTO ENCANTADO",
            "id":"54014080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Salto Encantado",
               "id":"54014080"
            },
            "centroide":{
               "lat":-27.0822797871022,
               "lon":-54.8334856092969
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Candelaria",
               "id":"540063"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"BARRIO DEL LAGO",
            "id":"54021005000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio del Lago",
               "id":"54021005"
            },
            "centroide":{
               "lat":-27.4636033945712,
               "lon":-55.8005801049338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bonpland",
               "id":"540056"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"BONPLAND",
            "id":"54021010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Bonpland",
               "id":"54021010"
            },
            "centroide":{
               "lat":-27.4820696887808,
               "lon":-55.4774200837194
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Candelaria",
               "id":"540063"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"CANDELARIA",
            "id":"54021020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Candelaria",
               "id":"54021020"
            },
            "centroide":{
               "lat":-27.4591653471738,
               "lon":-55.7430844145913
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerro Corá",
               "id":"540070"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"CERRO CORA",
            "id":"54021030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Cerro Corá",
               "id":"54021030"
            },
            "centroide":{
               "lat":-27.508609969523,
               "lon":-55.6038621436184
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Loreto",
               "id":"540077"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"LORETO",
            "id":"54021040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Loreto",
               "id":"54021040"
            },
            "centroide":{
               "lat":-27.3296012279697,
               "lon":-55.5228090005038
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mártires",
               "id":"540084"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"MARTIRES",
            "id":"54021050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Mártires",
               "id":"54021050"
            },
            "centroide":{
               "lat":-27.4188647592881,
               "lon":-55.3777936639947
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Profundidad",
               "id":"540091"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"PROFUNDIDAD",
            "id":"54021060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Profundidad",
               "id":"54021060"
            },
            "centroide":{
               "lat":-27.5585198137316,
               "lon":-55.7034474067364
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"540098"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"PUERTO SANTA ANA",
            "id":"54021070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Santa Ana",
               "id":"54021070"
            },
            "centroide":{
               "lat":-27.3330798818829,
               "lon":-55.5864347793367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"540098"
            },
            "departamento":{
               "nombre":"Candelaria",
               "id":"54021"
            },
            "nombre":"SANTA ANA",
            "id":"54021080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Santa Ana",
               "id":"54021080"
            },
            "centroide":{
               "lat":-27.3671212837411,
               "lon":-55.5805568560225
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garupá",
               "id":"540112"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"54028"
            },
            "nombre":"BARRIO NUEVO GARUPA",
            "id":"54028005000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Nuevo Garupa",
               "id":"54028005"
            },
            "centroide":{
               "lat":-27.4321009059125,
               "lon":-55.826138545257
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garupá",
               "id":"540112"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"54028"
            },
            "nombre":"GARUPA",
            "id":"54028010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Garupá",
               "id":"54028010"
            },
            "centroide":{
               "lat":-27.4788128514289,
               "lon":-55.8224411787639
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Posadas",
               "id":"540119"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"54028"
            },
            "nombre":"NEMESIO PARMA",
            "id":"54028020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Nemesio Parma",
               "id":"54028020"
            },
            "centroide":{
               "lat":-27.367003658843,
               "lon":-55.9982208456294
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Posadas",
               "id":"540119"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"54028"
            },
            "nombre":"POSADAS",
            "id":"54028030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Posadas",
               "id":"54028030"
            },
            "centroide":{
               "lat":-27.36621704276,
               "lon":-55.8940020980262
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Concepción de la Sierra",
               "id":"540126"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"54035"
            },
            "nombre":"BARRA CONCEPCION",
            "id":"54035010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barra Concepción",
               "id":"54035010"
            },
            "centroide":{
               "lat":-28.1109522025734,
               "lon":-55.5820048143948
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Concepción de la Sierra",
               "id":"540126"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"54035"
            },
            "nombre":"CONCEPCION DE LA SIERRA",
            "id":"54035020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Concepción de la Sierra",
               "id":"54035020"
            },
            "centroide":{
               "lat":-27.9813155596218,
               "lon":-55.5209343056683
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"540133"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"54035"
            },
            "nombre":"LA CORITA",
            "id":"54035030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"La Corita",
               "id":"54035030"
            },
            "centroide":{
               "lat":-27.8884115577679,
               "lon":-55.3552996953
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa María",
               "id":"540133"
            },
            "departamento":{
               "nombre":"Concepción",
               "id":"54035"
            },
            "nombre":"SANTA MARIA",
            "id":"54035040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Santa María",
               "id":"54035040"
            },
            "centroide":{
               "lat":-27.9292852570213,
               "lon":-55.4120566457096
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Victoria",
               "id":"540147"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"COLONIA VICTORIA",
            "id":"54042010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Colonia Victoria",
               "id":"54042010"
            },
            "centroide":{
               "lat":-26.3298887730818,
               "lon":-54.6215572258235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eldorado",
               "id":"540154"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"ELDORADO",
            "id":"54042020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Eldorado",
               "id":"54042020"
            },
            "centroide":{
               "lat":-26.4086211746541,
               "lon":-54.6238428075538
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Delicia",
               "id":"540140"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"MARIA MAGDALENA",
            "id":"54042030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"María Magdalena",
               "id":"54042030"
            },
            "centroide":{
               "lat":-26.2380924409231,
               "lon":-54.6018096123882
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Delicia",
               "id":"540140"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"NUEVA DELICIA",
            "id":"54042035000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Nueva Delicia",
               "id":"54042035"
            },
            "centroide":{
               "lat":-26.1791395472552,
               "lon":-54.5836572818887
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"540161"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"9 DE JULIO",
            "id":"54042040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"9 de Julio Kilómetro 28",
               "id":"54042040"
            },
            "centroide":{
               "lat":-26.4302810162412,
               "lon":-54.4664666896921
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"540161"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"9 DE JULIO KILOMETRO 20",
            "id":"54042050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"9 de Julio Kilómetro 20",
               "id":"54042050"
            },
            "centroide":{
               "lat":-26.4145333109522,
               "lon":-54.4976116231155
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Delicia",
               "id":"540140"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"PUEBLO NUEVO",
            "id":"54042055000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Pueblo Nuevo",
               "id":"54042055"
            },
            "centroide":{
               "lat":-26.2445413608016,
               "lon":-54.5904675610809
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Delicia",
               "id":"540140"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"PUERTO MADO",
            "id":"54042060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Mado",
               "id":"54042060"
            },
            "centroide":{
               "lat":-26.2310899121585,
               "lon":-54.6247146607981
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eldorado",
               "id":"540154"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"PUERTO PINARES",
            "id":"54042070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Pinares",
               "id":"54042070"
            },
            "centroide":{
               "lat":-26.4268174271826,
               "lon":-54.6857660084129
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santiago de Liniers",
               "id":"540168"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"SANTIAGO DE LINIERS",
            "id":"54042080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Santiago de Liniers",
               "id":"54042080"
            },
            "centroide":{
               "lat":-26.3905866143255,
               "lon":-54.3947099277232
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"540161"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"VALLE HERMOSO",
            "id":"54042090000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Valle Hermoso",
               "id":"54042090"
            },
            "centroide":{
               "lat":-26.382535288747,
               "lon":-54.4652848386631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eldorado",
               "id":"540154"
            },
            "departamento":{
               "nombre":"Eldorado",
               "id":"54042"
            },
            "nombre":"VILLA ROULET",
            "id":"54042100000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Roulet",
               "id":"54042100"
            },
            "centroide":{
               "lat":-26.4422594958772,
               "lon":-54.6405734856487
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comandante A. Guacurary",
               "id":"540182"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"ALMIRANTE BROWN",
            "id":"54049010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Comandante Andresito",
               "id":"54049010"
            },
            "centroide":{
               "lat":-25.6674361424553,
               "lon":-54.0456434709472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bernardo de Irigoyen",
               "id":"540175"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"BERNARDO DE IRIGOYEN",
            "id":"54049020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Bernardo de Irigoyen",
               "id":"54049020"
            },
            "centroide":{
               "lat":-26.2546761021204,
               "lon":-53.6472133435976
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comandante A. Guacurary",
               "id":"540182"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"CABUREI",
            "id":"54049025000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Caburei",
               "id":"54049025"
            },
            "centroide":{
               "lat":-25.6820367479588,
               "lon":-54.1426092989677
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bernardo de Irigoyen",
               "id":"540175"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"DOS HERMANAS",
            "id":"54049030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Dos Hermanas",
               "id":"54049030"
            },
            "centroide":{
               "lat":-26.2918653520361,
               "lon":-53.7575832277415
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comandante A. Guacurary",
               "id":"540182"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"INTEGRACION",
            "id":"54049040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Integración",
               "id":"54049040"
            },
            "centroide":{
               "lat":-25.7724726219691,
               "lon":-53.8522974773477
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"540189"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"PIÑALITO NORTE",
            "id":"54049043000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Piñalito Norte",
               "id":"54049043"
            },
            "centroide":{
               "lat":-25.926985370994,
               "lon":-53.9254399080528
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comandante A. Guacurary",
               "id":"540182"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"PUERTO ANDRESITO",
            "id":"54049045000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Andresito",
               "id":"54049045"
            },
            "centroide":{
               "lat":-25.5880676406692,
               "lon":-54.0084915753095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comandante A. Guacurary",
               "id":"540182"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"PUERTO DESEADO",
            "id":"54049047000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Deseado",
               "id":"54049047"
            },
            "centroide":{
               "lat":-25.7862399598743,
               "lon":-54.038267293845
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"540189"
            },
            "departamento":{
               "nombre":"General Manuel Belgrano",
               "id":"54049"
            },
            "nombre":"SAN ANTONIO",
            "id":"54049050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"54049050"
            },
            "centroide":{
               "lat":-26.0557838105078,
               "lon":-53.7339959536295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Soberbio",
               "id":"540196"
            },
            "departamento":{
               "nombre":"Guaraní",
               "id":"54056"
            },
            "nombre":"EL SOBERBIO",
            "id":"54056010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"El Soberbio",
               "id":"54056010"
            },
            "centroide":{
               "lat":-27.2908601020425,
               "lon":-54.2007777459323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"540203"
            },
            "departamento":{
               "nombre":"Guaraní",
               "id":"54056"
            },
            "nombre":"FRACRAN",
            "id":"54056020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Fracrán",
               "id":"54056020"
            },
            "centroide":{
               "lat":-26.740151400562,
               "lon":-54.3000439575816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"540203"
            },
            "departamento":{
               "nombre":"Guaraní",
               "id":"54056"
            },
            "nombre":"SAN VICENTE",
            "id":"54056030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Vicente",
               "id":"54056030"
            },
            "centroide":{
               "lat":-26.9953791755128,
               "lon":-54.4834965149084
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Esperanza",
               "id":"540210"
            },
            "departamento":{
               "nombre":"Iguazú",
               "id":"54063"
            },
            "nombre":"ESPERANZA",
            "id":"54063010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Esperanza",
               "id":"54063010"
            },
            "centroide":{
               "lat":-26.0232970897792,
               "lon":-54.6125092333468
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Libertad",
               "id":"540224"
            },
            "departamento":{
               "nombre":"Iguazú",
               "id":"54063"
            },
            "nombre":"LIBERTAD",
            "id":"54063020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Libertad",
               "id":"54063020"
            },
            "centroide":{
               "lat":-25.9216713372731,
               "lon":-54.5821209872029
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iguazú",
               "id":"540217"
            },
            "departamento":{
               "nombre":"Iguazú",
               "id":"54063"
            },
            "nombre":"PUERTO IGUAZU",
            "id":"54063030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Iguazú",
               "id":"54063030"
            },
            "centroide":{
               "lat":-25.6010431152667,
               "lon":-54.576736880982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Libertad",
               "id":"540224"
            },
            "departamento":{
               "nombre":"Iguazú",
               "id":"54063"
            },
            "nombre":"VILLA COOPERATIVA",
            "id":"54063035000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Cooperativa",
               "id":"54063035"
            },
            "centroide":{
               "lat":-25.935986701389,
               "lon":-54.5384663539082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Wanda",
               "id":"540231"
            },
            "departamento":{
               "nombre":"Iguazú",
               "id":"54063"
            },
            "nombre":"WANDA",
            "id":"54063040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Colonia Wanda",
               "id":"54063040"
            },
            "centroide":{
               "lat":-25.9713257064178,
               "lon":-54.5610255460819
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Almafuerte",
               "id":"540238"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"ALMAFUERTE",
            "id":"54070010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Almafuerte",
               "id":"54070010"
            },
            "centroide":{
               "lat":-27.5062138362368,
               "lon":-55.4018892063726
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo del Medio",
               "id":"540245"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"ARROYO DEL MEDIO",
            "id":"54070020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Arroyo del Medio",
               "id":"54070020"
            },
            "centroide":{
               "lat":-27.698002675484,
               "lon":-55.4064788856063
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caa Yarí",
               "id":"540252"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"CAA - YARI",
            "id":"54070030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Caá - Yarí",
               "id":"54070030"
            },
            "centroide":{
               "lat":-27.4807844367608,
               "lon":-55.2992348104351
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerro Azul",
               "id":"540259"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"CERRO AZUL",
            "id":"54070040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Cerro Azul",
               "id":"54070040"
            },
            "centroide":{
               "lat":-27.6310347455951,
               "lon":-55.4938681670811
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dos Arroyos",
               "id":"540266"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"DOS ARROYOS",
            "id":"54070050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Dos Arroyos",
               "id":"54070050"
            },
            "centroide":{
               "lat":-27.694171423305,
               "lon":-55.2587801693628
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador López",
               "id":"540273"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"GOBERNADOR LOPEZ",
            "id":"54070060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Gobernador López",
               "id":"54070060"
            },
            "centroide":{
               "lat":-27.6615245708492,
               "lon":-55.2124455595203
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leandro N. Alem",
               "id":"540280"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"LEANDRO N. ALEM",
            "id":"54070070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Leandro N. Alem",
               "id":"54070070"
            },
            "centroide":{
               "lat":-27.601867604082,
               "lon":-55.3264659218849
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Olegario V. Andrade",
               "id":"540287"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"OLEGARIO V. ANDRADE",
            "id":"54070080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Olegario V. Andrade",
               "id":"54070080"
            },
            "centroide":{
               "lat":-27.5658220357689,
               "lon":-55.5017254277441
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caa Yarí",
               "id":"540252"
            },
            "departamento":{
               "nombre":"Leandro N. Alem",
               "id":"54070"
            },
            "nombre":"VILLA LIBERTAD",
            "id":"54070090000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Libertad",
               "id":"54070090"
            },
            "centroide":{
               "lat":-27.5583422242545,
               "lon":-55.3160862221368
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capioví",
               "id":"540294"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"CAPIOVI",
            "id":"54077010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Capioví",
               "id":"54077010"
            },
            "centroide":{
               "lat":-26.9292033223206,
               "lon":-55.0569367980451
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Rico",
               "id":"540322"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"CAPIOVICIÑO",
            "id":"54077015000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Capioviciño",
               "id":"54077015"
            },
            "centroide":{
               "lat":-26.8795016704132,
               "lon":-55.0705133852003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alcázar",
               "id":"540301"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"EL ALCAZAR",
            "id":"54077020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"El Alcázar",
               "id":"54077020"
            },
            "centroide":{
               "lat":-26.7102586382645,
               "lon":-54.8161444547822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garuhapé",
               "id":"540308"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"GARUHAPE",
            "id":"54077030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Garuhapé",
               "id":"54077030"
            },
            "centroide":{
               "lat":-26.8186828823083,
               "lon":-54.9576120074303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Rico",
               "id":"540322"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"MBOPICUA",
            "id":"54077040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Mbopicuá",
               "id":"54077040"
            },
            "centroide":{
               "lat":-26.8615012222942,
               "lon":-55.0463438652711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leoni",
               "id":"540315"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"PUERTO LEONI",
            "id":"54077050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Leoni",
               "id":"54077050"
            },
            "centroide":{
               "lat":-26.9840910613646,
               "lon":-55.1694842635982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Rico",
               "id":"540322"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"PUERTO RICO",
            "id":"54077060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Rico",
               "id":"54077060"
            },
            "centroide":{
               "lat":-26.8148524027618,
               "lon":-55.0240728847562
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ruiz de Montoya",
               "id":"540329"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"RUIZ DE MONTOYA",
            "id":"54077070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Ruiz de Montoya",
               "id":"54077070"
            },
            "centroide":{
               "lat":-26.9665882832067,
               "lon":-55.05758992095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Rico",
               "id":"540322"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"SAN ALBERTO",
            "id":"54077080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Alberto",
               "id":"54077080"
            },
            "centroide":{
               "lat":-26.8048950436231,
               "lon":-54.9881508224082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capioví",
               "id":"540294"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"SAN GOTARDO",
            "id":"54077090000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Gotardo",
               "id":"54077090"
            },
            "centroide":{
               "lat":-26.9225122221192,
               "lon":-55.1238743204073
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garuhapé",
               "id":"540308"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"SAN MIGUEL",
            "id":"54077100000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Miguel",
               "id":"54077100"
            },
            "centroide":{
               "lat":-26.8533367010148,
               "lon":-54.8892924101276
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capioví",
               "id":"540294"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"VILLA AKERMAN",
            "id":"54077110000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Akerman",
               "id":"54077110"
            },
            "centroide":{
               "lat":-26.9412646689407,
               "lon":-55.0957711615406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Alcázar",
               "id":"540301"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"54077"
            },
            "nombre":"VILLA URRUTIA",
            "id":"54077120000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Urrutia",
               "id":"54077120"
            },
            "centroide":{
               "lat":-26.8462676092826,
               "lon":-54.739753574325
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montecarlo",
               "id":"540343"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"BARRIO CUATRO BOCAS",
            "id":"54084003000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Cuatro Bocas",
               "id":"54084003"
            },
            "centroide":{
               "lat":-26.5482209436331,
               "lon":-54.6748836684444
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montecarlo",
               "id":"540343"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"BARRIO GUATAMBU",
            "id":"54084005000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Guatambu",
               "id":"54084005"
            },
            "centroide":{
               "lat":-26.6027932981786,
               "lon":-54.6955807827058
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montecarlo",
               "id":"540343"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"BARIO ITA",
            "id":"54084007000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Bario Ita",
               "id":"54084007"
            },
            "centroide":{
               "lat":-26.5283257581417,
               "lon":-54.7089179903484
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caraguatay",
               "id":"540336"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"CARAGUATAY",
            "id":"54084010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Caraguatay",
               "id":"54084010"
            },
            "centroide":{
               "lat":-26.6563488324986,
               "lon":-54.7392272649556
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montecarlo",
               "id":"540343"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"LAHARRAGUE",
            "id":"54084020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Laharrague",
               "id":"54084020"
            },
            "centroide":{
               "lat":-26.532818826907,
               "lon":-54.6506736537237
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montecarlo",
               "id":"540343"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"MONTECARLO",
            "id":"54084030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Montecarlo",
               "id":"54084030"
            },
            "centroide":{
               "lat":-26.5661764915312,
               "lon":-54.7614267394945
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Piray",
               "id":"540350"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"PIRAY KILOMETRO 18",
            "id":"54084040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Piray Kilómetro 18",
               "id":"54084040"
            },
            "centroide":{
               "lat":-26.5237915925702,
               "lon":-54.5916547620688
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Puerto Piray",
               "id":"540350"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"PUERTO PIRAY",
            "id":"54084050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Puerto Piray",
               "id":"54084050"
            },
            "centroide":{
               "lat":-26.467494823767,
               "lon":-54.7136760762691
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caraguatay",
               "id":"540336"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"TARUMA",
            "id":"54084060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Tarumá",
               "id":"54084060"
            },
            "centroide":{
               "lat":-26.7282097915611,
               "lon":-54.7278209571683
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montecarlo",
               "id":"540343"
            },
            "departamento":{
               "nombre":"Montecarlo",
               "id":"54084"
            },
            "nombre":"VILLA PARODI",
            "id":"54084070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Parodi",
               "id":"54084070"
            },
            "centroide":{
               "lat":-26.4980184491189,
               "lon":-54.6804147225647
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Alberdi",
               "id":"540371"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"ALBERDI",
            "id":"54091010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Colonia Alberdi",
               "id":"54091010"
            },
            "centroide":{
               "lat":-27.3569655848168,
               "lon":-55.2326585711531
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Helechos",
               "id":"540392"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"BARRIO ESCUELA 461",
            "id":"54091013000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Escuela 461",
               "id":"54091013"
            },
            "centroide":{
               "lat":-27.616902888101,
               "lon":-55.0424750470795
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Ramón",
               "id":"540357"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"BARRIO ESCUELA 633",
            "id":"54091017000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Escuela 633",
               "id":"54091017"
            },
            "centroide":{
               "lat":-27.4689933468661,
               "lon":-55.0771065017179
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Ramón",
               "id":"540357"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"CAMPO RAMON",
            "id":"54091020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Campo Ramón",
               "id":"54091020"
            },
            "centroide":{
               "lat":-27.4522700869441,
               "lon":-55.0237352385306
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Viera",
               "id":"540364"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"CAMPO VIERA",
            "id":"54091030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Campo Viera",
               "id":"54091030"
            },
            "centroide":{
               "lat":-27.3310558767329,
               "lon":-55.0528548158544
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Oberá",
               "id":"540399"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"EL SALTO",
            "id":"54091040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"El Salto",
               "id":"54091040"
            },
            "centroide":{
               "lat":-27.4917334406127,
               "lon":-55.1989608083666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Alvear",
               "id":"540378"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"GENERAL ALVEAR",
            "id":"54091050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"General Alvear",
               "id":"54091050"
            },
            "centroide":{
               "lat":-27.4228667193793,
               "lon":-55.1702709710527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guaraní",
               "id":"540385"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"GUARANI",
            "id":"54091060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Guaraní",
               "id":"54091060"
            },
            "centroide":{
               "lat":-27.5203317456103,
               "lon":-55.161363476829
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Helechos",
               "id":"540392"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"LOS HELECHOS",
            "id":"54091070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Los Helechos",
               "id":"54091070"
            },
            "centroide":{
               "lat":-27.5531853202103,
               "lon":-55.0778227687919
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Oberá",
               "id":"540399"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"OBERA",
            "id":"54091080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Oberá",
               "id":"54091080"
            },
            "centroide":{
               "lat":-27.4816559378759,
               "lon":-55.1251546931471
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Panambí",
               "id":"540406"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"PANAMBI",
            "id":"54091090000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Panambí",
               "id":"54091090"
            },
            "centroide":{
               "lat":-27.7185151219435,
               "lon":-54.9183708210206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Panambí",
               "id":"540406"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"PANAMBI KILOMETRO 8",
            "id":"54091100000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Panambí Kilómetro 8",
               "id":"54091100"
            },
            "centroide":{
               "lat":-27.6631606292713,
               "lon":-54.9846517202885
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Panambí",
               "id":"540406"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"PANAMBI KILOMETRO 15",
            "id":"54091105000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Panambi Kilómetro 15",
               "id":"54091105"
            },
            "centroide":{
               "lat":-27.7043305063396,
               "lon":-54.9664319816326
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"540413"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"SAN MARTIN",
            "id":"54091110000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Martín",
               "id":"54091110"
            },
            "centroide":{
               "lat":-27.4618128955547,
               "lon":-55.2783440740601
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Ramón",
               "id":"540357"
            },
            "departamento":{
               "nombre":"Oberá",
               "id":"54091"
            },
            "nombre":"VILLA BONITA",
            "id":"54091120000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Villa Bonita",
               "id":"54091120"
            },
            "centroide":{
               "lat":-27.479699295829,
               "lon":-54.9639234038824
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santo Pipo",
               "id":"540469"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"BARRIO TUNGOIL",
            "id":"54098005000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Barrio Tungoil",
               "id":"54098005"
            },
            "centroide":{
               "lat":-27.1077568549388,
               "lon":-55.3881151371992
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Polana",
               "id":"540420"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"COLONIA POLANA",
            "id":"54098010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Colonia Polana",
               "id":"54098010"
            },
            "centroide":{
               "lat":-26.9810475025624,
               "lon":-55.3170685541177
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Corpus",
               "id":"540427"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"CORPUS",
            "id":"54098020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Corpus",
               "id":"54098020"
            },
            "centroide":{
               "lat":-27.1278038790718,
               "lon":-55.5094179289212
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Ignacio",
               "id":"540462"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"DOMINGO SAVIO",
            "id":"54098030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Domingo Savio",
               "id":"54098030"
            },
            "centroide":{
               "lat":-27.3546875776242,
               "lon":-55.3368344966029
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General J. J. Urquiza",
               "id":"540434"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"GENERAL URQUIZA",
            "id":"54098040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"General Urquiza",
               "id":"54098040"
            },
            "centroide":{
               "lat":-27.1077317789884,
               "lon":-55.3737660823345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Roca",
               "id":"540441"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"GOBERNADOR ROCA",
            "id":"54098050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Gobernador Roca",
               "id":"54098050"
            },
            "centroide":{
               "lat":-27.1910170928285,
               "lon":-55.4682802860195
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Irigoyen",
               "id":"540448"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"HELVECIA",
            "id":"54098060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Helvecia",
               "id":"54098060"
            },
            "centroide":{
               "lat":-27.110525539393,
               "lon":-55.3438282986148
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Irigoyen",
               "id":"540448"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"HIPOLITO YRIGOYEN",
            "id":"54098070000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Hipólito Yrigoyen",
               "id":"54098070"
            },
            "centroide":{
               "lat":-27.0900613258757,
               "lon":-55.2870716950334
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jardín América",
               "id":"540455"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"JARDIN AMERICA",
            "id":"54098080000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Jardín América",
               "id":"54098080"
            },
            "centroide":{
               "lat":-27.0410707861025,
               "lon":-55.2320858824009
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jardín América",
               "id":"540455"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"OASIS",
            "id":"54098090000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Oasis",
               "id":"54098090"
            },
            "centroide":{
               "lat":-26.9708359693882,
               "lon":-55.2491782114283
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Roca",
               "id":"540441"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"ROCA CHICA",
            "id":"54098100000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Roca Chica",
               "id":"54098100"
            },
            "centroide":{
               "lat":-27.2143687134287,
               "lon":-55.4202789802254
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Ignacio",
               "id":"540462"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"SAN IGNACIO",
            "id":"54098110000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Ignacio",
               "id":"54098110"
            },
            "centroide":{
               "lat":-27.2573496537482,
               "lon":-55.5397360869442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santo Pipo",
               "id":"540469"
            },
            "departamento":{
               "nombre":"San Ignacio",
               "id":"54098"
            },
            "nombre":"SANTO PIPO",
            "id":"54098120000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Santo Pipó",
               "id":"54098120"
            },
            "centroide":{
               "lat":-27.1418557045843,
               "lon":-55.4074695445505
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ameghino",
               "id":"540476"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"54105"
            },
            "nombre":"FLORENTINO AMEGHINO",
            "id":"54105010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Florentino Ameghino",
               "id":"54105010"
            },
            "centroide":{
               "lat":-27.6349575540286,
               "lon":-55.0877551421696
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Itacaruaré",
               "id":"540483"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"54105"
            },
            "nombre":"ITACARUARE",
            "id":"54105020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Itacaruaré",
               "id":"54105020"
            },
            "centroide":{
               "lat":-27.8655824463799,
               "lon":-55.2638525290793
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mojón Grande",
               "id":"540490"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"54105"
            },
            "nombre":"MOJON GRANDE",
            "id":"54105030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Mojón Grande",
               "id":"54105030"
            },
            "centroide":{
               "lat":-27.7077678291474,
               "lon":-55.1582021663854
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Javier",
               "id":"540497"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"54105"
            },
            "nombre":"SAN JAVIER",
            "id":"54105040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Javier",
               "id":"54105040"
            },
            "centroide":{
               "lat":-27.8653231566702,
               "lon":-55.1348376530446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"540504"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"54112"
            },
            "nombre":"CRUCE CABALLERO",
            "id":"54112010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Cruce Caballero",
               "id":"54112010"
            },
            "centroide":{
               "lat":-26.5379470287742,
               "lon":-53.9437324956095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"540504"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"54112"
            },
            "nombre":"PARAISO",
            "id":"54112020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Paraíso",
               "id":"54112020"
            },
            "centroide":{
               "lat":-26.6835663891035,
               "lon":-54.2050107484876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"540504"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"54112"
            },
            "nombre":"PIÑALITO SUR",
            "id":"54112030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Piñalito Sur",
               "id":"54112030"
            },
            "centroide":{
               "lat":-26.4160781340411,
               "lon":-53.8369134466205
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"540504"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"54112"
            },
            "nombre":"SAN PEDRO",
            "id":"54112040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"54112040"
            },
            "centroide":{
               "lat":-26.6197903071037,
               "lon":-54.1146818942839
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro",
               "id":"540504"
            },
            "departamento":{
               "nombre":"San Pedro",
               "id":"54112"
            },
            "nombre":"TOBUNA",
            "id":"54112050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Tobuna",
               "id":"54112050"
            },
            "centroide":{
               "lat":-26.4664417575785,
               "lon":-53.8919888142393
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alba Posse",
               "id":"540511"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"ALBA POSSE",
            "id":"54119010000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Alba Posse",
               "id":"54119010"
            },
            "centroide":{
               "lat":-27.5641504979908,
               "lon":-54.687011236345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Aurora",
               "id":"540518"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"COLONIA ALICIA",
            "id":"54119020000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Alicia Alta",
               "id":"54119020"
            },
            "centroide":{
               "lat":-27.3792090956035,
               "lon":-54.3672999680752
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Aurora",
               "id":"540518"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"ALICIA BAJA",
            "id":"54119025000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Alicia Baja",
               "id":"54119025"
            },
            "centroide":{
               "lat":-27.4285421255893,
               "lon":-54.3575081480921
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Aurora",
               "id":"540518"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"COLONIA AURORA",
            "id":"54119030000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Colonia Aurora",
               "id":"54119030"
            },
            "centroide":{
               "lat":-27.479557957166,
               "lon":-54.5154356645941
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alba Posse",
               "id":"540511"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"SAN FRANCISCO DE ASIS",
            "id":"54119040000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"San Francisco de Asís",
               "id":"54119040"
            },
            "centroide":{
               "lat":-27.4597786529494,
               "lon":-54.7478069154214
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alba Posse",
               "id":"540511"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"SANTA RITA",
            "id":"54119050000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"Santa Rita",
               "id":"54119050"
            },
            "centroide":{
               "lat":-27.5183529379611,
               "lon":-54.7305712624723
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"540525"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"54119"
            },
            "nombre":"25 DE MAYO",
            "id":"54119060000",
            "provincia":{
               "nombre":"Misiones",
               "id":"54"
            },
            "localidad_censal":{
               "nombre":"25 de Mayo",
               "id":"54119060"
            },
            "centroide":{
               "lat":-27.3715719938072,
               "lon":-54.7475224881518
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aluminé",
               "id":"580007"
            },
            "departamento":{
               "nombre":"Aluminé",
               "id":"58007"
            },
            "nombre":"ALUMINE",
            "id":"58007010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Aluminé",
               "id":"58007010"
            },
            "centroide":{
               "lat":-39.2395006622098,
               "lon":-70.9180131491474
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Pehuenia",
               "id":"580252"
            },
            "departamento":{
               "nombre":"Aluminé",
               "id":"58007"
            },
            "nombre":"MOQUEHUE",
            "id":"58007015000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Moquehue",
               "id":"58007015"
            },
            "centroide":{
               "lat":-38.9431841513779,
               "lon":-71.3282934446594
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Pehuenia",
               "id":"580252"
            },
            "departamento":{
               "nombre":"Aluminé",
               "id":"58007"
            },
            "nombre":"VILLA PEHUENIA",
            "id":"58007020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa Pehuenia",
               "id":"58007020"
            },
            "centroide":{
               "lat":-38.88385673767,
               "lon":-71.1721341418285
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguada San Roque",
               "id":"585014"
            },
            "departamento":{
               "nombre":"Añelo",
               "id":"58014"
            },
            "nombre":"AGUADA SAN ROQUE",
            "id":"58014005000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Aguada San Roque",
               "id":"58014005"
            },
            "centroide":{
               "lat":-37.9994791767967,
               "lon":-68.9231103975687
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Añelo",
               "id":"580014"
            },
            "departamento":{
               "nombre":"Añelo",
               "id":"58014"
            },
            "nombre":"AÑELO",
            "id":"58014010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Añelo",
               "id":"58014010"
            },
            "centroide":{
               "lat":-38.3514440221419,
               "lon":-68.7919715586172
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Patricio del Chañar",
               "id":"580021"
            },
            "departamento":{
               "nombre":"Añelo",
               "id":"58014"
            },
            "nombre":"SAN PATRICIO DEL CHAÑAR",
            "id":"58014020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"San Patricio del Chañar",
               "id":"58014020"
            },
            "centroide":{
               "lat":-38.6257824069657,
               "lon":-68.2986456816821
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Coloradas",
               "id":"580028"
            },
            "departamento":{
               "nombre":"Catán Lil",
               "id":"58021"
            },
            "nombre":"LAS COLORADAS",
            "id":"58021010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Las Coloradas",
               "id":"58021010"
            },
            "centroide":{
               "lat":-39.5578638822685,
               "lon":-70.5932894653763
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Piedra del Águila",
               "id":"580035"
            },
            "departamento":{
               "nombre":"Collón Curá",
               "id":"58028"
            },
            "nombre":"PIEDRA DEL AGUILA",
            "id":"58028010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Piedra del Águila",
               "id":"58028010"
            },
            "centroide":{
               "lat":-40.0465538638606,
               "lon":-70.077319578901
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santo Tomás",
               "id":"585035"
            },
            "departamento":{
               "nombre":"Collón Curá",
               "id":"58028"
            },
            "nombre":"SANTO TOMAS",
            "id":"58028020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Santo Tomás",
               "id":"58028020"
            },
            "centroide":{
               "lat":-39.8218007278609,
               "lon":-70.1028884849476
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Senillosa",
               "id":"580077"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"ARROYITO",
            "id":"58035010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Arroyito",
               "id":"58035010"
            },
            "centroide":{
               "lat":-39.0743997961308,
               "lon":-68.5703350642529
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vista Alegre",
               "id":"580091"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"BARRIO RUCA LUHE",
            "id":"58035020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Barrio Ruca Luhé",
               "id":"58035020"
            },
            "centroide":{
               "lat":-38.7543907123239,
               "lon":-68.1803399743279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Centenario",
               "id":"580042"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"CENTENARIO",
            "id":"58035030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Centenario",
               "id":"58035030"
            },
            "centroide":{
               "lat":-38.8275777081112,
               "lon":-68.1532191639297
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cutral Có",
               "id":"580049"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"CUTRAL CO",
            "id":"58035040000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Cutral Có",
               "id":"58035040"
            },
            "centroide":{
               "lat":-38.9366016135518,
               "lon":-69.2413390332884
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa el Chocón",
               "id":"580084"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"EL CHOCON",
            "id":"58035050000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"El Chocón",
               "id":"58035050"
            },
            "centroide":{
               "lat":-39.2589786425295,
               "lon":-68.8268723660918
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"MARI MENUCO",
            "id":"58035060000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Mari Menuco",
               "id":"58035060"
            },
            "centroide":{
               "lat":-38.5383043330689,
               "lon":-68.557547832041
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Neuquén",
               "id":"580056"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"NEUQUEN",
            "id":"58035070000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Neuquén",
               "id":"58035070"
            },
            "centroide":{
               "lat":-38.9492856796033,
               "lon":-68.0839057621977
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Centenario",
               "id":"580042"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"11 DE OCTUBRE",
            "id":"58035080000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"11 de Octubre",
               "id":"58035080"
            },
            "centroide":{
               "lat":-38.878684024658,
               "lon":-68.1001707201744
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Plaza Huincul",
               "id":"580063"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"PLAZA HUINCUL",
            "id":"58035090000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Plaza Huincul",
               "id":"58035090"
            },
            "centroide":{
               "lat":-38.9290709532125,
               "lon":-69.2021594435231
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Plottier",
               "id":"580070"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"PLOTTIER",
            "id":"58035100000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Plottier",
               "id":"58035100"
            },
            "centroide":{
               "lat":-38.9510561611213,
               "lon":-68.2478403865049
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Senillosa",
               "id":"580077"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"SENILLOSA",
            "id":"58035110000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Senillosa",
               "id":"58035110"
            },
            "centroide":{
               "lat":-39.0113034801537,
               "lon":-68.4333833911279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa el Chocón",
               "id":"580084"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"VILLA EL CHOCON",
            "id":"58035120000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa El Chocón",
               "id":"58035120"
            },
            "centroide":{
               "lat":-39.2610101785628,
               "lon":-68.7842564223565
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vista Alegre",
               "id":"580091"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"VISTA ALEGRE NORTE",
            "id":"58035130000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Vista Alegre Norte",
               "id":"58035130"
            },
            "centroide":{
               "lat":-38.7277123127679,
               "lon":-68.1721438862367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vista Alegre",
               "id":"580091"
            },
            "departamento":{
               "nombre":"Confluencia",
               "id":"58035"
            },
            "nombre":"VISTA ALEGRE SUR",
            "id":"58035140000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Vista Alegre Sur",
               "id":"58035140"
            },
            "centroide":{
               "lat":-38.7715537254998,
               "lon":-68.1369187198489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chos Malal",
               "id":"580098"
            },
            "departamento":{
               "nombre":"Chos Malal",
               "id":"58042"
            },
            "nombre":"CHOS MALAL",
            "id":"58042010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Chos Malal",
               "id":"58042010"
            },
            "centroide":{
               "lat":-37.3792566593514,
               "lon":-70.2723772223192
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tricao Malal",
               "id":"580105"
            },
            "departamento":{
               "nombre":"Chos Malal",
               "id":"58042"
            },
            "nombre":"TRICAO MALAL",
            "id":"58042020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Tricao Malal",
               "id":"58042020"
            },
            "centroide":{
               "lat":-37.0428490399834,
               "lon":-70.3347175778926
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Curi Leuvú",
               "id":"585056"
            },
            "departamento":{
               "nombre":"Chos Malal",
               "id":"58042"
            },
            "nombre":"VILLA DEL CURI LEUVU",
            "id":"58042030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa del Curi Leuvú",
               "id":"58042030"
            },
            "centroide":{
               "lat":-37.1337365202592,
               "lon":-70.3969231250344
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Junín de los Andes",
               "id":"580112"
            },
            "departamento":{
               "nombre":"Huiliches",
               "id":"58049"
            },
            "nombre":"JUNIN DE LOS ANDES",
            "id":"58049010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Junín de los Andes",
               "id":"58049010"
            },
            "centroide":{
               "lat":-39.9494720138355,
               "lon":-71.0703335609136
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín de los Andes",
               "id":"580119"
            },
            "departamento":{
               "nombre":"Lácar",
               "id":"58056"
            },
            "nombre":"SAN MARTIN DE LOS ANDES",
            "id":"58056010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"San Martín de los Andes",
               "id":"58056010"
            },
            "centroide":{
               "lat":-40.1537600653137,
               "lon":-71.3550098619322
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Lácar",
               "id":"58056"
            },
            "nombre":"LAGO MELIQUINA",
            "id":"58056020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa Lago Meliquina",
               "id":"58056020"
            },
            "centroide":{
               "lat":-40.3816875508143,
               "lon":-71.2616152793248
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chorriaca",
               "id":"585063"
            },
            "departamento":{
               "nombre":"Loncopué",
               "id":"58063"
            },
            "nombre":"CHORRIACA",
            "id":"58063010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Chorriaca",
               "id":"58063010"
            },
            "centroide":{
               "lat":-37.929624048638,
               "lon":-70.0562158162232
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Loncopué",
               "id":"580126"
            },
            "departamento":{
               "nombre":"Loncopué",
               "id":"58063"
            },
            "nombre":"LONCOPUE",
            "id":"58063020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Loncopué",
               "id":"58063020"
            },
            "centroide":{
               "lat":-38.0699432722928,
               "lon":-70.6124039046889
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa la Angostura",
               "id":"580133"
            },
            "departamento":{
               "nombre":"Los Lagos",
               "id":"58070"
            },
            "nombre":"VILLA LA ANGOSTURA",
            "id":"58070010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa La Angostura",
               "id":"58070010"
            },
            "centroide":{
               "lat":-40.7631760692681,
               "lon":-71.6451967512926
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Traful",
               "id":"585070"
            },
            "departamento":{
               "nombre":"Los Lagos",
               "id":"58070"
            },
            "nombre":"VILLA TRAFUL",
            "id":"58070020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa Traful",
               "id":"58070020"
            },
            "centroide":{
               "lat":-40.6515336234792,
               "lon":-71.4069733427812
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Andacollo",
               "id":"580140"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"ANDACOLLO",
            "id":"58077010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Andacollo",
               "id":"58077010"
            },
            "centroide":{
               "lat":-37.1814100578481,
               "lon":-70.6690765415839
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huinganco",
               "id":"580147"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"HUINGANCO",
            "id":"58077020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Huinganco",
               "id":"58077020"
            },
            "centroide":{
               "lat":-37.162253742004,
               "lon":-70.6240350766311
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Ovejas",
               "id":"580154"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"LAS OVEJAS",
            "id":"58077030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Las Ovejas",
               "id":"58077030"
            },
            "centroide":{
               "lat":-36.9881343331798,
               "lon":-70.7487001311443
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Miches",
               "id":"580161"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"LOS MICHES",
            "id":"58077040000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Los Miches",
               "id":"58077040"
            },
            "centroide":{
               "lat":-37.2079272879054,
               "lon":-70.8208608595818
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Manzano Amargo",
               "id":"585084"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"MANZANO AMARGO",
            "id":"58077050000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Manzano Amargo",
               "id":"58077050"
            },
            "centroide":{
               "lat":-36.7475538529054,
               "lon":-70.7652790008941
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Varvarco - Invernada Vieja",
               "id":"585091"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"VARVARCO",
            "id":"58077060000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Varvarco",
               "id":"58077060"
            },
            "centroide":{
               "lat":-36.8575631715293,
               "lon":-70.6784177984494
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Nahueve",
               "id":"585098"
            },
            "departamento":{
               "nombre":"Minas",
               "id":"58077"
            },
            "nombre":"VILLA DEL NAHUEVE",
            "id":"58077070000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Villa del Nahueve",
               "id":"58077070"
            },
            "centroide":{
               "lat":-37.1209838887878,
               "lon":-70.7687465935033
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caviahue-Copahue",
               "id":"580168"
            },
            "departamento":{
               "nombre":"Ñorquín",
               "id":"58084"
            },
            "nombre":"CAVIAHUE",
            "id":"58084010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Caviahue",
               "id":"58084010"
            },
            "centroide":{
               "lat":-37.8741211534774,
               "lon":-71.0537792468958
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caviahue-Copahue",
               "id":"580168"
            },
            "departamento":{
               "nombre":"Ñorquín",
               "id":"58084"
            },
            "nombre":"COPAHUE",
            "id":"58084020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Copahue",
               "id":"58084020"
            },
            "centroide":{
               "lat":-37.8191699254756,
               "lon":-71.0991107043764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Cholar",
               "id":"580175"
            },
            "departamento":{
               "nombre":"Ñorquín",
               "id":"58084"
            },
            "nombre":"EL CHOLAR",
            "id":"58084030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"El Cholar",
               "id":"58084030"
            },
            "centroide":{
               "lat":-37.4408647694624,
               "lon":-70.6441818646861
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Huecú",
               "id":"580182"
            },
            "departamento":{
               "nombre":"Ñorquín",
               "id":"58084"
            },
            "nombre":"EL HUECU",
            "id":"58084040000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"El Huecú",
               "id":"58084040"
            },
            "centroide":{
               "lat":-37.6415832578483,
               "lon":-70.5790877358744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ñorquín",
               "id":"58084"
            },
            "nombre":"TAQUIMILAN",
            "id":"58084050000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Taquimilán",
               "id":"58084050"
            },
            "centroide":{
               "lat":-37.5169103878888,
               "lon":-70.2503863712688
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Barrancas",
               "id":"580196"
            },
            "departamento":{
               "nombre":"Pehuenches",
               "id":"58091"
            },
            "nombre":"BARRANCAS",
            "id":"58091010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Barrancas",
               "id":"58091010"
            },
            "centroide":{
               "lat":-36.8247372962673,
               "lon":-69.9164782914754
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Buta Ranquil",
               "id":"580203"
            },
            "departamento":{
               "nombre":"Pehuenches",
               "id":"58091"
            },
            "nombre":"BUTA RANQUIL",
            "id":"58091020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Buta Ranquil",
               "id":"58091020"
            },
            "centroide":{
               "lat":-37.0507876062807,
               "lon":-69.873851086363
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Octavio Pico",
               "id":"585105"
            },
            "departamento":{
               "nombre":"Pehuenches",
               "id":"58091"
            },
            "nombre":"OCTAVIO PICO",
            "id":"58091030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Octavio Pico",
               "id":"58091030"
            },
            "centroide":{
               "lat":-37.5865104880568,
               "lon":-68.2686075936205
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rincón de los Sauces",
               "id":"580210"
            },
            "departamento":{
               "nombre":"Pehuenches",
               "id":"58091"
            },
            "nombre":"RINCON DE LOS SAUCES",
            "id":"58091040000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Rincón de los Sauces",
               "id":"58091040"
            },
            "centroide":{
               "lat":-37.3899075901743,
               "lon":-68.9309943279283
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Sauce",
               "id":"585112"
            },
            "departamento":{
               "nombre":"Picún Leufú",
               "id":"58098"
            },
            "nombre":"EL SAUCE",
            "id":"58098005000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"El Sauce",
               "id":"58098005"
            },
            "centroide":{
               "lat":-39.4750725824587,
               "lon":-69.5298228549207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso Aguerre",
               "id":"585119"
            },
            "departamento":{
               "nombre":"Picún Leufú",
               "id":"58098"
            },
            "nombre":"PASO AGUERRE",
            "id":"58098010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Paso Aguerre",
               "id":"58098010"
            },
            "centroide":{
               "lat":-39.3393978445221,
               "lon":-69.8442147950698
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Picún Leufú",
               "id":"580217"
            },
            "departamento":{
               "nombre":"Picún Leufú",
               "id":"58098"
            },
            "nombre":"PICUN LEUFU",
            "id":"58098020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Picún Leufú",
               "id":"58098020"
            },
            "centroide":{
               "lat":-39.5191361709356,
               "lon":-69.2962673114558
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bajada del Agrio",
               "id":"580224"
            },
            "departamento":{
               "nombre":"Picunches",
               "id":"58105"
            },
            "nombre":"BAJADA DEL AGRIO",
            "id":"58105010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Bajada del Agrio",
               "id":"58105010"
            },
            "centroide":{
               "lat":-38.4079798578262,
               "lon":-70.0276785984599
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Lajas",
               "id":"580231"
            },
            "departamento":{
               "nombre":"Picunches",
               "id":"58105"
            },
            "nombre":"LA BUITRERA",
            "id":"58105020000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"La Buitrera",
               "id":"58105020"
            },
            "centroide":{
               "lat":-38.5574702395959,
               "lon":-70.3665560313021
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Lajas",
               "id":"580231"
            },
            "departamento":{
               "nombre":"Picunches",
               "id":"58105"
            },
            "nombre":"LAS LAJAS",
            "id":"58105030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Las Lajas",
               "id":"58105030"
            },
            "centroide":{
               "lat":-38.5292612011937,
               "lon":-70.3689502898328
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quili Malal",
               "id":"585126"
            },
            "departamento":{
               "nombre":"Picunches",
               "id":"58105"
            },
            "nombre":"QUILI MALAL",
            "id":"58105040000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Quili Malal",
               "id":"58105040"
            },
            "centroide":{
               "lat":-38.3213178634179,
               "lon":-69.8143687558623
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Catutos",
               "id":"585140"
            },
            "departamento":{
               "nombre":"Zapala",
               "id":"58112"
            },
            "nombre":"LOS CATUTOS",
            "id":"58112010000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Los Catutos",
               "id":"58112010"
            },
            "centroide":{
               "lat":-38.8384807985925,
               "lon":-70.1962106815081
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mariano Moreno",
               "id":"580238"
            },
            "departamento":{
               "nombre":"Zapala",
               "id":"58112"
            },
            "nombre":"COVUNCO CENTRO",
            "id":"58112020001",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Mariano Moreno",
               "id":"58112020"
            },
            "centroide":{
               "lat":-38.7532470280083,
               "lon":-70.0275921805241
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mariano Moreno",
               "id":"580238"
            },
            "departamento":{
               "nombre":"Zapala",
               "id":"58112"
            },
            "nombre":"MARIANO MORENO",
            "id":"58112020002",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Mariano Moreno",
               "id":"58112020"
            },
            "centroide":{
               "lat":-38.7642591265553,
               "lon":-70.0386669795207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramón Castro",
               "id":"585147"
            },
            "departamento":{
               "nombre":"Zapala",
               "id":"58112"
            },
            "nombre":"RAMON M. CASTRO",
            "id":"58112030000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Ramón M. Castro",
               "id":"58112030"
            },
            "centroide":{
               "lat":-38.865500620555,
               "lon":-69.750265287383
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zapala",
               "id":"580245"
            },
            "departamento":{
               "nombre":"Zapala",
               "id":"58112"
            },
            "nombre":"ZAPALA",
            "id":"58112040000",
            "provincia":{
               "nombre":"Neuquén",
               "id":"58"
            },
            "localidad_censal":{
               "nombre":"Zapala",
               "id":"58112040"
            },
            "centroide":{
               "lat":-38.8961687323721,
               "lon":-70.0668545323772
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"BAHIA CREEK",
            "id":"62007010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Bahía Creek",
               "id":"62007010"
            },
            "centroide":{
               "lat":-41.0954235915941,
               "lon":-63.9092240856567
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Viedma",
               "id":"620014"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"EL CONDOR",
            "id":"62007020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Cóndor",
               "id":"62007020"
            },
            "centroide":{
               "lat":-41.043074401021,
               "lon":-62.8212339732456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Viedma",
               "id":"620014"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"EL JUNCAL",
            "id":"62007030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Juncal",
               "id":"62007030"
            },
            "centroide":{
               "lat":-40.8040464324129,
               "lon":-63.119258465235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guardia Mitre",
               "id":"620007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"GUARDIA MITRE",
            "id":"62007040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Guardia Mitre",
               "id":"62007040"
            },
            "centroide":{
               "lat":-40.4302951883973,
               "lon":-63.6719140103256
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"LA LOBERIA",
            "id":"62007050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"La Lobería",
               "id":"62007050"
            },
            "centroide":{
               "lat":-41.1541008885686,
               "lon":-63.1234800366543
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"LOTEO COSTA DE RIO",
            "id":"62007060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Loteo Costa de Río",
               "id":"62007060"
            },
            "centroide":{
               "lat":-40.8722977227525,
               "lon":-62.9146635826372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"POZO SALADO",
            "id":"62007070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Pozo Salado",
               "id":"62007070"
            },
            "centroide":{
               "lat":-41.0178488120515,
               "lon":-64.1403933053127
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Javier",
               "id":"625014"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"SAN JAVIER",
            "id":"62007080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"San Javier",
               "id":"62007080"
            },
            "centroide":{
               "lat":-40.7472509496074,
               "lon":-63.2646524967149
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Viedma",
               "id":"620014"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"62007"
            },
            "nombre":"VIEDMA",
            "id":"62007090000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Viedma",
               "id":"62007090"
            },
            "centroide":{
               "lat":-40.8093232712389,
               "lon":-62.9853203682712
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chimpay",
               "id":"620021"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"BARRIO UNION",
            "id":"62014010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Unión",
               "id":"62014010"
            },
            "centroide":{
               "lat":-39.1585373145235,
               "lon":-66.1856066477135
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"CHELFORO",
            "id":"62014020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Chelforó",
               "id":"62014020"
            },
            "centroide":{
               "lat":-39.0881631155042,
               "lon":-66.5209546613811
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chimpay",
               "id":"620021"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"CHIMPAY",
            "id":"62014030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Chimpay",
               "id":"62014030"
            },
            "centroide":{
               "lat":-39.1651527318617,
               "lon":-66.1447069274027
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Choele Choel",
               "id":"620028"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"CHOELE CHOEL",
            "id":"62014040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Choele Choel",
               "id":"62014040"
            },
            "centroide":{
               "lat":-39.2884543350453,
               "lon":-65.663280823108
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Belisle",
               "id":"620035"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"CORONEL BELISLE",
            "id":"62014050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Coronel Belisle",
               "id":"62014050"
            },
            "centroide":{
               "lat":-39.1858299915745,
               "lon":-65.9563191181266
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Darwin",
               "id":"620042"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"DARWIN",
            "id":"62014060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Darwin",
               "id":"62014060"
            },
            "centroide":{
               "lat":-39.2026912901608,
               "lon":-65.7409172296959
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lamarque",
               "id":"620049"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"LAMARQUE",
            "id":"62014070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Lamarque",
               "id":"62014070"
            },
            "centroide":{
               "lat":-39.4232652920959,
               "lon":-65.7014504976777
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luis Beltran",
               "id":"620056"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"LUIS BELTRAN",
            "id":"62014080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Luis Beltrán",
               "id":"62014080"
            },
            "centroide":{
               "lat":-39.3088800984468,
               "lon":-65.7648714193881
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pomona",
               "id":"620063"
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"62014"
            },
            "nombre":"POMONA",
            "id":"62014090000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Pomona",
               "id":"62014090"
            },
            "centroide":{
               "lat":-39.4841604573414,
               "lon":-65.6124600513947
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"VILLA ARELAUQUEN",
            "id":"62021005000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Arelauquen",
               "id":"62021005"
            },
            "centroide":{
               "lat":-41.1700881080441,
               "lon":-71.3851119262238
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"BARRIO EL PILAR",
            "id":"62021010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio El Pilar",
               "id":"62021010"
            },
            "centroide":{
               "lat":-41.1814203033089,
               "lon":-71.3493437707973
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"COLONIA SUIZA",
            "id":"62021020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Colonia Suiza",
               "id":"62021020"
            },
            "centroide":{
               "lat":-41.0947497360429,
               "lon":-71.505527376881
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Bolson",
               "id":"620070"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"EL BOLSON",
            "id":"62021030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Bolsón",
               "id":"62021030"
            },
            "centroide":{
               "lat":-41.9804859657332,
               "lon":-71.5336172136647
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Manso",
               "id":"625028"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"EL FOYEL",
            "id":"62021040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Foyel",
               "id":"62021040"
            },
            "centroide":{
               "lat":-41.6571199223738,
               "lon":-71.4592776287102
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Bolson",
               "id":"620070"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"MALLIN AHOGADO",
            "id":"62021047000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Mallín Ahogado",
               "id":"62021047"
            },
            "centroide":{
               "lat":-41.8412736734112,
               "lon":-71.5091292986442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Manso",
               "id":"625028"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"RIO VILLEGAS",
            "id":"62021050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Río Villegas",
               "id":"62021050"
            },
            "centroide":{
               "lat":-41.5822159155763,
               "lon":-71.5012934002795
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"SAN CARLOS DE BARILOCHE",
            "id":"62021060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"San Carlos de Bariloche",
               "id":"62021060"
            },
            "centroide":{
               "lat":-41.1369282850916,
               "lon":-71.2990645403112
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"SAN CARLOS DE BARILOCHE",
            "id":"62021060001",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"San Carlos de Bariloche",
               "id":"62021060"
            },
            "centroide":{
               "lat":-41.1192625668718,
               "lon":-71.351142488652
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"VILLA CAMPANARIO",
            "id":"62021060002",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"San Carlos de Bariloche",
               "id":"62021060"
            },
            "centroide":{
               "lat":-41.0649702714092,
               "lon":-71.4726736795373
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"VILLA LLAO LLAO",
            "id":"62021060003",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"San Carlos de Bariloche",
               "id":"62021060"
            },
            "centroide":{
               "lat":-41.0697604387258,
               "lon":-71.5396513534561
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"VILLA CATEDRAL",
            "id":"62021080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Catedral",
               "id":"62021080"
            },
            "centroide":{
               "lat":-41.1666351264313,
               "lon":-71.4375745404969
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos de Bariloche",
               "id":"620077"
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"VILLA LOS COIHUES",
            "id":"62021100000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Los Coihues",
               "id":"62021100"
            },
            "centroide":{
               "lat":-41.1575793746786,
               "lon":-71.4131558610974
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Bariloche",
               "id":"62021"
            },
            "nombre":"VILLA MASCARDI",
            "id":"62021110000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Mascardi",
               "id":"62021110"
            },
            "centroide":{
               "lat":-41.3495536441427,
               "lon":-71.5090416294131
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Conesa",
               "id":"620084"
            },
            "departamento":{
               "nombre":"Conesa",
               "id":"62028"
            },
            "nombre":"BARRIO COLONIA CONESA",
            "id":"62028010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Colonia Conesa",
               "id":"62028010"
            },
            "centroide":{
               "lat":-40.1405715071916,
               "lon":-64.3297427915782
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Conesa",
               "id":"620084"
            },
            "departamento":{
               "nombre":"Conesa",
               "id":"62028"
            },
            "nombre":"GENERAL CONESA",
            "id":"62028020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"General Conesa",
               "id":"62028020"
            },
            "centroide":{
               "lat":-40.1047354258125,
               "lon":-64.452961945825
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Conesa",
               "id":"62028"
            },
            "nombre":"BARRIO PLANTA COMPRESORA DE GAS",
            "id":"62028030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Planta Compresora de Gas",
               "id":"62028030"
            },
            "centroide":{
               "lat":-40.0564744924401,
               "lon":-64.4726087632632
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguada Guzman",
               "id":"625077"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"AGUADA GUZMAN",
            "id":"62035010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Aguada Guzmán",
               "id":"62035010"
            },
            "centroide":{
               "lat":-39.9787588388031,
               "lon":-68.8683979876685
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"CERRO POLICIA",
            "id":"62035020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cerro Policía",
               "id":"62035020"
            },
            "centroide":{
               "lat":-39.7252943733893,
               "lon":-68.4939533269128
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Cuy",
               "id":"625056"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"EL CUY",
            "id":"62035030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Cuy",
               "id":"62035030"
            },
            "centroide":{
               "lat":-39.9230912187031,
               "lon":-68.3369498427779
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"LAS PERLAS",
            "id":"62035040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Las Perlas",
               "id":"62035040"
            },
            "centroide":{
               "lat":-38.9840690690676,
               "lon":-68.1403501822625
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mencuen",
               "id":"625070"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"MENCUE",
            "id":"62035050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Mencué",
               "id":"62035050"
            },
            "centroide":{
               "lat":-40.4229029710507,
               "lon":-69.6143877108253
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Naupa Huen",
               "id":"625098"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"NAUPA HUEN",
            "id":"62035060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Naupa Huen",
               "id":"62035060"
            },
            "centroide":{
               "lat":-39.8282845926545,
               "lon":-69.5089726874799
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"PASO CORDOVA",
            "id":"62035070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Paso Córdova",
               "id":"62035070"
            },
            "centroide":{
               "lat":-39.1153330396607,
               "lon":-67.6264974744394
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Azul",
               "id":"625112"
            },
            "departamento":{
               "nombre":"El Cuy",
               "id":"62035"
            },
            "nombre":"VALLE AZUL",
            "id":"62035080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Valle Azul",
               "id":"62035080"
            },
            "centroide":{
               "lat":-39.1404827453952,
               "lon":-66.7285641693515
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"ALLEN",
            "id":"62042010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Allen",
               "id":"62042010"
            },
            "centroide":{
               "lat":-38.9795106570689,
               "lon":-67.828021398161
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BAJO SAN CAYETANO",
            "id":"62042020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Paraje Arroyón (Bajo San Cayetano)",
               "id":"62042020"
            },
            "centroide":{
               "lat":-38.7513229744846,
               "lon":-68.0085536061591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Contralmirante Cordero",
               "id":"620140"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARDA DEL MEDIO",
            "id":"62042030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barda del Medio",
               "id":"62042030"
            },
            "centroide":{
               "lat":-38.7246633358539,
               "lon":-68.1580560913419
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO BLANCO",
            "id":"62042040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Blanco",
               "id":"62042040"
            },
            "centroide":{
               "lat":-39.0309641335839,
               "lon":-67.7863228650653
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO CALLE CIEGA N? 10",
            "id":"62042050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Calle Ciega Nº 10",
               "id":"62042050"
            },
            "centroide":{
               "lat":-39.0230092190233,
               "lon":-67.8005036132986
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO CALLE CIEGA N? 6",
            "id":"62042060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Calle Ciega Nº 6",
               "id":"62042060"
            },
            "centroide":{
               "lat":-39.0430891464152,
               "lon":-67.7520658420811
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO CANALE",
            "id":"62042070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Canale",
               "id":"62042070"
            },
            "centroide":{
               "lat":-39.0682963331395,
               "lon":-67.6389236900117
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO CHACRA MONTE",
            "id":"62042080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Chacra Monte",
               "id":"62042080"
            },
            "centroide":{
               "lat":-39.0516494451284,
               "lon":-67.6345853523327
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO COSTA ESTE",
            "id":"62042090000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Costa Este",
               "id":"62042090"
            },
            "centroide":{
               "lat":-39.0418587269806,
               "lon":-67.8075970544366
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Fdez. Oro",
               "id":"620154"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO COSTA LINDA",
            "id":"62042100000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Costa Linda",
               "id":"62042100"
            },
            "centroide":{
               "lat":-38.9435967131876,
               "lon":-67.9095130600985
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO COSTA OESTE",
            "id":"62042110000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Costa Oeste",
               "id":"62042110"
            },
            "centroide":{
               "lat":-39.0280740237159,
               "lon":-67.8406950461568
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Peñas Blancas",
               "id":"625105"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO DESTACAMENTO",
            "id":"62042115000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Destacamento",
               "id":"62042115"
            },
            "centroide":{
               "lat":-37.688801948065,
               "lon":-67.8695487107459
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"620098"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO EL LABRADOR",
            "id":"62042120000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio El Labrador",
               "id":"62042120"
            },
            "centroide":{
               "lat":-38.6719231721601,
               "lon":-68.2343296924898
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO EL MARUCHITO",
            "id":"62042130000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio El Maruchito",
               "id":"62042130"
            },
            "centroide":{
               "lat":-38.9965483470472,
               "lon":-67.7600066651023
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO EL PETROLEO",
            "id":"62042140000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio El Petróleo",
               "id":"62042140"
            },
            "centroide":{
               "lat":-39.0639270176593,
               "lon":-67.5120162799717
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO EMERGENTE",
            "id":"62042143000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Emergente",
               "id":"62042143"
            },
            "centroide":{
               "lat":-39.001652251504,
               "lon":-67.8506842254546
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cervantes",
               "id":"620112"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"COLONIA FATIMA",
            "id":"62042147000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Fátima",
               "id":"62042147"
            },
            "centroide":{
               "lat":-39.0531680628778,
               "lon":-67.472967428649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO FRONTERA",
            "id":"62042150000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Frontera",
               "id":"62042150"
            },
            "centroide":{
               "lat":-39.071916321907,
               "lon":-67.7129100460388
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO GUERRICO",
            "id":"62042160000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Guerrico",
               "id":"62042160"
            },
            "centroide":{
               "lat":-39.0416838101557,
               "lon":-67.7340081423714
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Fdez. Oro",
               "id":"620154"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO ISLA 10",
            "id":"62042170000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Isla 10",
               "id":"62042170"
            },
            "centroide":{
               "lat":-38.9966443708843,
               "lon":-67.9181463726224
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ingeniero Huergo",
               "id":"620168"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA BARDA",
            "id":"62042180000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio La Barda",
               "id":"62042180"
            },
            "centroide":{
               "lat":-39.0494648024568,
               "lon":-67.217793659392
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA COSTA",
            "id":"62042190000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio La Costa",
               "id":"62042190"
            },
            "centroide":{
               "lat":-39.0753070627553,
               "lon":-67.5392261916358
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ingeniero Huergo",
               "id":"620168"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA COSTA",
            "id":"62042200000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio La Costa",
               "id":"62042200"
            },
            "centroide":{
               "lat":-39.0923450479727,
               "lon":-67.2007281871129
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cervantes",
               "id":"620112"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA DEFENSA",
            "id":"62042210000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio La Defensa",
               "id":"62042210"
            },
            "centroide":{
               "lat":-39.0331815789057,
               "lon":-67.3862477660724
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Allen",
               "id":"620091"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA HERRADURA",
            "id":"62042215000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio La Herradura",
               "id":"62042215"
            },
            "centroide":{
               "lat":-39.0370774236916,
               "lon":-67.7820594526764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA RIBERA - BARRIO APYCAR",
            "id":"62042230000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio La Ribera - Barrio APYCAR",
               "id":"62042230"
            },
            "centroide":{
               "lat":-39.0772588097086,
               "lon":-67.5794991279564
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LAS ANGUSTIAS",
            "id":"62042240000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Puente Cero",
               "id":"62042240"
            },
            "centroide":{
               "lat":-39.0298112121202,
               "lon":-67.5009911848666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mainque",
               "id":"620175"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LUISILLO",
            "id":"62042245000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Luisillo",
               "id":"62042245"
            },
            "centroide":{
               "lat":-39.0514047890722,
               "lon":-67.3468986066245
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO MAR DEL PLATA",
            "id":"62042250000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Mar del Plata",
               "id":"62042250"
            },
            "centroide":{
               "lat":-39.0827873095255,
               "lon":-67.6523985545637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO MARIA ELVIRA",
            "id":"62042260000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio María Elvira",
               "id":"62042260"
            },
            "centroide":{
               "lat":-38.9901442627179,
               "lon":-67.9613095600255
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chichinales",
               "id":"620119"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO MOÑO AZUL",
            "id":"62042265000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Moño Azul",
               "id":"62042265"
            },
            "centroide":{
               "lat":-39.1306874014844,
               "lon":-66.8941649716278
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO MOSCONI",
            "id":"62042270000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Mosconi",
               "id":"62042270"
            },
            "centroide":{
               "lat":-39.0907183953718,
               "lon":-67.5898603467619
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO NORTE",
            "id":"62042280000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Norte",
               "id":"62042280"
            },
            "centroide":{
               "lat":-38.8637279259729,
               "lon":-68.0231905596235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Peñas Blancas",
               "id":"625105"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO PINAR",
            "id":"62042297000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Pinar",
               "id":"62042297"
            },
            "centroide":{
               "lat":-37.6748275939008,
               "lon":-67.8736521306821
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cervantes",
               "id":"620112"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO PORVENIR",
            "id":"62042310000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Porvenir",
               "id":"62042310"
            },
            "centroide":{
               "lat":-39.0430283937958,
               "lon":-67.4642040039168
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO EL TREINTA",
            "id":"62042330001",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Puente 83",
               "id":"62042330"
            },
            "centroide":{
               "lat":-38.8937119363148,
               "lon":-67.971068167392
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO GORETTI",
            "id":"62042330002",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Puente 83",
               "id":"62042330"
            },
            "centroide":{
               "lat":-38.9655980210737,
               "lon":-67.9509550220406
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO PUENTE 83",
            "id":"62042330003",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Puente 83",
               "id":"62042330"
            },
            "centroide":{
               "lat":-38.9655980210737,
               "lon":-67.9509550220406
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO PUENTE DE MADERA",
            "id":"62042330004",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Puente 83",
               "id":"62042330"
            },
            "centroide":{
               "lat":-38.9589646944033,
               "lon":-67.9492414342193
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO TRES LUCES",
            "id":"62042330005",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Puente 83",
               "id":"62042330"
            },
            "centroide":{
               "lat":-38.9386794797604,
               "lon":-67.9440033559728
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"TRES LUCES",
            "id":"62042330006",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Puente 83",
               "id":"62042330"
            },
            "centroide":{
               "lat":-38.9655980210737,
               "lon":-67.9509550220406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mainque",
               "id":"620175"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"SANTA LUCIA",
            "id":"62042335000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Santa Lucia",
               "id":"62042335"
            },
            "centroide":{
               "lat":-39.0282517975929,
               "lon":-67.2984549123711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Regina",
               "id":"620182"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO SANTA RITA",
            "id":"62042340000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Santa Rita",
               "id":"62042340"
            },
            "centroide":{
               "lat":-39.1260785745275,
               "lon":-67.1035165050666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Fdez. Oro",
               "id":"620154"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO UNION",
            "id":"62042350000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Unión",
               "id":"62042350"
            },
            "centroide":{
               "lat":-38.979186876102,
               "lon":-67.941976373184
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Catriel",
               "id":"620105"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CATRIEL",
            "id":"62042360000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Catriel",
               "id":"62042360"
            },
            "centroide":{
               "lat":-37.8815288625891,
               "lon":-67.7945569701758
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cervantes",
               "id":"620112"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CERVANTES",
            "id":"62042370000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cervantes",
               "id":"62042370"
            },
            "centroide":{
               "lat":-39.0515785846632,
               "lon":-67.3930560378066
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chichinales",
               "id":"620119"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CHICHINALES",
            "id":"62042380000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Chichinales",
               "id":"62042380"
            },
            "centroide":{
               "lat":-39.1148814347412,
               "lon":-66.9425150029993
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cinco Saltos",
               "id":"620126"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CINCO SALTOS",
            "id":"62042390000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cinco Saltos",
               "id":"62042390"
            },
            "centroide":{
               "lat":-38.8275620197177,
               "lon":-68.0660962055201
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cinco Saltos",
               "id":"620126"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO PRESIDENTE PERON",
            "id":"62042390001",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cinco Saltos",
               "id":"62042390"
            },
            "centroide":{
               "lat":-38.7961848310008,
               "lon":-68.0734334344623
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cinco Saltos",
               "id":"620126"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CINCO SALTOS",
            "id":"62042390002",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cinco Saltos",
               "id":"62042390"
            },
            "centroide":{
               "lat":-38.8405541009144,
               "lon":-68.0623005797764
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CIPOLLETTI",
            "id":"62042400000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cipolletti",
               "id":"62042400"
            },
            "centroide":{
               "lat":-38.924558895075,
               "lon":-68.035384250397
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO LA LOR",
            "id":"62042400001",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cipolletti",
               "id":"62042400"
            },
            "centroide":{
               "lat":-38.9245848988383,
               "lon":-68.0307899442508
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CIPOLLETTI",
            "id":"62042400002",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cipolletti",
               "id":"62042400"
            },
            "centroide":{
               "lat":-38.9220596281776,
               "lon":-67.9955493313849
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Contralmirante Cordero",
               "id":"620140"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"CONTRALMIRANTE CORDERO",
            "id":"62042410000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Contralmirante Cordero",
               "id":"62042410"
            },
            "centroide":{
               "lat":-38.7570062611988,
               "lon":-68.0994972104866
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cipolletti",
               "id":"620133"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"FERRI",
            "id":"62042420000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ferri",
               "id":"62042420"
            },
            "centroide":{
               "lat":-38.8871754648156,
               "lon":-68.0068433988189
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Enrique Godoy",
               "id":"620147"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"GENERAL ENRIQUE GODOY",
            "id":"62042430000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"General Enrique Godoy",
               "id":"62042430"
            },
            "centroide":{
               "lat":-39.0795501222919,
               "lon":-67.1575508259755
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Fdez. Oro",
               "id":"620154"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"GENERAL FERNANDEZ ORO",
            "id":"62042440000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"General Fernández Oro",
               "id":"62042440"
            },
            "centroide":{
               "lat":-38.95436020459,
               "lon":-67.9251089784989
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"GENERAL ROCA",
            "id":"62042450000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"General Roca",
               "id":"62042450"
            },
            "centroide":{
               "lat":-39.0267025182087,
               "lon":-67.5748540962425
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"BARRIO PINO AZUL",
            "id":"62042450001",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"General Roca",
               "id":"62042450"
            },
            "centroide":{
               "lat":-39.055737401237,
               "lon":-67.5935301061017
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ingeniero Huergo",
               "id":"620168"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"INGENIERO LUIS A. HUERGO",
            "id":"62042460000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Luis A. Huergo",
               "id":"62042460"
            },
            "centroide":{
               "lat":-39.0711757468082,
               "lon":-67.2328876350348
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chichinales",
               "id":"620119"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"INGENIERO OTTO KRAUSE",
            "id":"62042470000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Otto Krause",
               "id":"62042470"
            },
            "centroide":{
               "lat":-39.1114281023989,
               "lon":-66.9939096262054
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mainque",
               "id":"620175"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"MAINQUE",
            "id":"62042480000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Mainqué",
               "id":"62042480"
            },
            "centroide":{
               "lat":-39.0637561999382,
               "lon":-67.3042811813575
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gral. Roca",
               "id":"620161"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"PASO CORDOVA",
            "id":"62042490000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Paso Córdova",
               "id":"62042490"
            },
            "centroide":{
               "lat":-39.1077504096641,
               "lon":-67.6277074998828
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"PENINSULA RUCA CO",
            "id":"62042500000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Península Ruca Co",
               "id":"62042500"
            },
            "centroide":{
               "lat":-38.7010665100453,
               "lon":-68.0277179698786
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"620098"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"SARGENTO VIDAL",
            "id":"62042520000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Sargento Vidal",
               "id":"62042520"
            },
            "centroide":{
               "lat":-38.6856409614896,
               "lon":-68.1580780282182
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Regina",
               "id":"620182"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"VILLA ALBERDI",
            "id":"62042530000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Alberdi",
               "id":"62042530"
            },
            "centroide":{
               "lat":-39.1283463654662,
               "lon":-67.0481125141998
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chichinales",
               "id":"620119"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"VILLA DEL PARQUE",
            "id":"62042540000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa del Parque",
               "id":"62042540"
            },
            "centroide":{
               "lat":-39.1258079817539,
               "lon":-66.9976300412367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"620098"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"VILLA MANZANO",
            "id":"62042550000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Manzano",
               "id":"62042550"
            },
            "centroide":{
               "lat":-38.6806041543676,
               "lon":-68.2157180851266
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Regina",
               "id":"620182"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"VILLA REGINA",
            "id":"62042560000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Regina",
               "id":"62042560"
            },
            "centroide":{
               "lat":-39.0962966627004,
               "lon":-67.0828092630939
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Grande",
               "id":"620098"
            },
            "departamento":{
               "nombre":"General Roca",
               "id":"62042"
            },
            "nombre":"VILLA SAN ISIDRO",
            "id":"62042570000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa San Isidro",
               "id":"62042570"
            },
            "centroide":{
               "lat":-38.7064851078187,
               "lon":-68.1737455217997
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comico",
               "id":"625210"
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"COMICO",
            "id":"62049010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Comicó",
               "id":"62049010"
            },
            "centroide":{
               "lat":-41.0632755118249,
               "lon":-67.5965600975269
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cona Niyeu",
               "id":"625161"
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"CONA NIYEU",
            "id":"62049020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cona Niyeu",
               "id":"62049020"
            },
            "centroide":{
               "lat":-41.8812021704501,
               "lon":-66.9407404270844
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ministro Ramos Mexia",
               "id":"620189"
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"MINISTRO RAMOS MEXIA",
            "id":"62049030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ministro Ramos Mexía",
               "id":"62049030"
            },
            "centroide":{
               "lat":-40.5085400133825,
               "lon":-67.2619207148835
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Prahuaniyeu",
               "id":"625259"
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"PRAHUANIYEU",
            "id":"62049040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Prahuaniyeu",
               "id":"62049040"
            },
            "centroide":{
               "lat":-41.3591519247092,
               "lon":-67.9314763422669
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sierra Colorada",
               "id":"620196"
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"SIERRA COLORADA",
            "id":"62049050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Sierra Colorada",
               "id":"62049050"
            },
            "centroide":{
               "lat":-40.5850993527615,
               "lon":-67.7555445864448
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rincon de Treneta",
               "id":"625119"
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"TRENETA",
            "id":"62049060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Treneta",
               "id":"62049060"
            },
            "centroide":{
               "lat":-40.8508277432449,
               "lon":-66.9816846927042
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"9 de julio",
               "id":"62049"
            },
            "nombre":"YAMINUE",
            "id":"62049070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Yaminué",
               "id":"62049070"
            },
            "centroide":{
               "lat":-40.8429274507992,
               "lon":-67.1922500656909
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilcaniyeu",
               "id":"620224"
            },
            "departamento":{
               "nombre":"Ñorquinco",
               "id":"62056"
            },
            "nombre":"LAS BAYAS",
            "id":"62056010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Las Bayas",
               "id":"62056010"
            },
            "centroide":{
               "lat":-41.4504057812872,
               "lon":-70.6827894626766
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Mamuel Choique",
               "id":"625238"
            },
            "departamento":{
               "nombre":"Ñorquinco",
               "id":"62056"
            },
            "nombre":"MAMUEL CHOIQUE",
            "id":"62056020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Mamuel Choique",
               "id":"62056020"
            },
            "centroide":{
               "lat":-41.7698416427745,
               "lon":-70.1708632216436
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ñorquinco",
               "id":"620203"
            },
            "departamento":{
               "nombre":"Ñorquinco",
               "id":"62056"
            },
            "nombre":"ÑORQUINCO",
            "id":"62056030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ñorquincó",
               "id":"62056030"
            },
            "centroide":{
               "lat":-41.843448611748,
               "lon":-70.8943932149944
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ojos de Agua",
               "id":"625245"
            },
            "departamento":{
               "nombre":"Ñorquinco",
               "id":"62056"
            },
            "nombre":"OJOS DE AGUA",
            "id":"62056040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ojos de Agua",
               "id":"62056040"
            },
            "centroide":{
               "lat":-41.534892227381,
               "lon":-69.854645970072
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rio Chico",
               "id":"625266"
            },
            "departamento":{
               "nombre":"Ñorquinco",
               "id":"62056"
            },
            "nombre":"RIO CHICO",
            "id":"62056050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Río Chico",
               "id":"62056050"
            },
            "centroide":{
               "lat":-41.7167227835394,
               "lon":-70.4710839014636
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rìo Colorado",
               "id":"620210"
            },
            "departamento":{
               "nombre":"Pichi Mahuida",
               "id":"62063"
            },
            "nombre":"BARRIO ESPERANZA",
            "id":"62063005000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Barrio Esperanza",
               "id":"62063005"
            },
            "centroide":{
               "lat":-39.041876855319,
               "lon":-63.9985907357656
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rìo Colorado",
               "id":"620210"
            },
            "departamento":{
               "nombre":"Pichi Mahuida",
               "id":"62063"
            },
            "nombre":"COLONIA JULIA Y ECHARREN",
            "id":"62063010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Colonia Juliá y Echarren",
               "id":"62063010"
            },
            "centroide":{
               "lat":-39.0361229259361,
               "lon":-64.0139390564756
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rìo Colorado",
               "id":"620210"
            },
            "departamento":{
               "nombre":"Pichi Mahuida",
               "id":"62063"
            },
            "nombre":"JUVENTUD UNIDA",
            "id":"62063013000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Juventud Unida",
               "id":"62063013"
            },
            "centroide":{
               "lat":-39.0125652171478,
               "lon":-64.0650447267437
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Adela",
               "id":"420189"
            },
            "departamento":{
               "nombre":"Pichi Mahuida",
               "id":"62063"
            },
            "nombre":"PICHI MAHUIDA",
            "id":"62063017000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Pichi Mahuida",
               "id":"62063017"
            },
            "centroide":{
               "lat":-38.8296136177488,
               "lon":-64.9374198062825
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rìo Colorado",
               "id":"620210"
            },
            "departamento":{
               "nombre":"Pichi Mahuida",
               "id":"62063"
            },
            "nombre":"RIO COLORADO",
            "id":"62063020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Río Colorado",
               "id":"62063020"
            },
            "centroide":{
               "lat":-38.9914136078743,
               "lon":-64.0874682295519
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pichi Mahuida",
               "id":"62063"
            },
            "nombre":"SALTO ANDERSEN",
            "id":"62063060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Salto Andersen",
               "id":"62063060"
            },
            "centroide":{
               "lat":-38.8229789232812,
               "lon":-64.8182778708068
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"CAÑADON CHILENO",
            "id":"62070005000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Cañadón Chileno",
               "id":"62070005"
            },
            "centroide":{
               "lat":-40.8843058831975,
               "lon":-70.0229634453504
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comallo",
               "id":"620217"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"COMALLO",
            "id":"62070010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Comallo",
               "id":"62070010"
            },
            "centroide":{
               "lat":-41.0298746753183,
               "lon":-70.269980649853
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dina Huapi",
               "id":"620273"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"DINA HUAPI",
            "id":"62070020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Dina Huapi",
               "id":"62070020"
            },
            "centroide":{
               "lat":-41.0691934226159,
               "lon":-71.16219332616
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Blanca",
               "id":"625231"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"LAGUNA BLANCA",
            "id":"62070030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Laguna Blanca",
               "id":"62070030"
            },
            "centroide":{
               "lat":-40.7925954846977,
               "lon":-69.8810399581108
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Dina Huapi",
               "id":"620273"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"ÑIRIHUAU",
            "id":"62070040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ñirihuau",
               "id":"62070040"
            },
            "centroide":{
               "lat":-41.0885592834836,
               "lon":-71.1369133751581
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilcaniyeu",
               "id":"620224"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"PILCANIYEU",
            "id":"62070060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Pilcaniyeu",
               "id":"62070060"
            },
            "centroide":{
               "lat":-41.1252901833754,
               "lon":-70.7216943906912
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilquiniyeu del Limay",
               "id":"625140"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"PILQUINIYEU DEL LIMAY",
            "id":"62070070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Pilquiniyeu del Limay",
               "id":"62070070"
            },
            "centroide":{
               "lat":-40.5448524836234,
               "lon":-70.0532314661515
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Llanquin",
               "id":"625042"
            },
            "departamento":{
               "nombre":"Pilcaniyeu",
               "id":"62070"
            },
            "nombre":"VILLA LLANQUIN",
            "id":"62070080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Villa Llanquín",
               "id":"62070080"
            },
            "centroide":{
               "lat":-40.9239102047089,
               "lon":-71.0338579770764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio Oeste",
               "id":"620231"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"ESTACION EMPALME",
            "id":"62077005000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Empalme",
               "id":"62077005"
            },
            "centroide":{
               "lat":-40.706602544179,
               "lon":-65.0030743715644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"LAS GRUTAS",
            "id":"62077010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Las Grutas",
               "id":"62077010"
            },
            "centroide":{
               "lat":-40.806093320236,
               "lon":-65.0847019757487
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sierra Grande",
               "id":"620238"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"PLAYAS DORADAS",
            "id":"62077020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Playas Doradas",
               "id":"62077020"
            },
            "centroide":{
               "lat":-41.6276787888305,
               "lon":-65.0236746017627
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio Oeste",
               "id":"620231"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"PUERTO SAN ANTONIO ESTE",
            "id":"62077030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Puerto San Antonio Este",
               "id":"62077030"
            },
            "centroide":{
               "lat":-40.8008815532575,
               "lon":-64.8778974161853
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sierra Grande",
               "id":"620238"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"PUNTA COLORADA",
            "id":"62077040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Punta Colorada",
               "id":"62077040"
            },
            "centroide":{
               "lat":-41.6941221979021,
               "lon":-65.0245573411298
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio Oeste",
               "id":"620231"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"SACO VIEJO",
            "id":"62077045000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Saco Viejo",
               "id":"62077045"
            },
            "centroide":{
               "lat":-40.811004684265,
               "lon":-64.7581129382783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio Oeste",
               "id":"620231"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"SAN ANTONIO OESTE",
            "id":"62077050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"San Antonio Oeste",
               "id":"62077050"
            },
            "centroide":{
               "lat":-40.7312945275013,
               "lon":-64.9552941307172
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sierra Grande",
               "id":"620238"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"62077"
            },
            "nombre":"SIERRA GRANDE",
            "id":"62077060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Sierra Grande",
               "id":"62077060"
            },
            "centroide":{
               "lat":-41.6071863315513,
               "lon":-65.3534017406091
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguada Cecilio",
               "id":"625168"
            },
            "departamento":{
               "nombre":"Valcheta",
               "id":"62084"
            },
            "nombre":"AGUADA CECILIO",
            "id":"62084010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Aguada Cecilio",
               "id":"62084010"
            },
            "centroide":{
               "lat":-40.8478737807916,
               "lon":-65.8393274592303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo los Berros",
               "id":"625147"
            },
            "departamento":{
               "nombre":"Valcheta",
               "id":"62084"
            },
            "nombre":"ARROYO LOS BERROS",
            "id":"62084020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Arroyo Los Berros",
               "id":"62084020"
            },
            "centroide":{
               "lat":-41.4372536416486,
               "lon":-66.0950773167367
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Ventana",
               "id":"625154"
            },
            "departamento":{
               "nombre":"Valcheta",
               "id":"62084"
            },
            "nombre":"ARROYO VENTANA",
            "id":"62084030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Arroyo Ventana",
               "id":"62084030"
            },
            "centroide":{
               "lat":-41.6653937822189,
               "lon":-66.0860135550914
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nahuel Niyeu",
               "id":"625182"
            },
            "departamento":{
               "nombre":"Valcheta",
               "id":"62084"
            },
            "nombre":"NAHUEL NIYEU",
            "id":"62084040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Nahuel Niyeu",
               "id":"62084040"
            },
            "centroide":{
               "lat":-40.504198321368,
               "lon":-66.5657529968209
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sierra Paileman",
               "id":"625189"
            },
            "departamento":{
               "nombre":"Valcheta",
               "id":"62084"
            },
            "nombre":"SIERRA PAILEMAN",
            "id":"62084050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Sierra Pailemán",
               "id":"62084050"
            },
            "centroide":{
               "lat":-41.1812714523245,
               "lon":-65.9614292512301
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valcheta",
               "id":"620245"
            },
            "departamento":{
               "nombre":"Valcheta",
               "id":"62084"
            },
            "nombre":"VALCHETA",
            "id":"62084060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Valcheta",
               "id":"62084060"
            },
            "centroide":{
               "lat":-40.6778617788507,
               "lon":-66.1653261356888
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguada de Guerra",
               "id":"625196"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"AGUADA DE GUERRA",
            "id":"62091010000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Aguada de Guerra",
               "id":"62091010"
            },
            "centroide":{
               "lat":-41.0639017102391,
               "lon":-68.3843810353051
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Clemente Onelli",
               "id":"625217"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"CLEMENTE ONELLI",
            "id":"62091020000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Clemente Onelli",
               "id":"62091020"
            },
            "centroide":{
               "lat":-41.2448350046555,
               "lon":-70.0342029410146
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colan Conhué",
               "id":"625203"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"COLAN CONUE",
            "id":"62091030000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Colan Conhue",
               "id":"62091030"
            },
            "centroide":{
               "lat":-40.6706084126156,
               "lon":-69.1112545579992
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Cain",
               "id":"625224"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"EL CAIN",
            "id":"62091040000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"El Caín",
               "id":"62091040"
            },
            "centroide":{
               "lat":-41.8182874251641,
               "lon":-68.0774152377244
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ing. Jacobacci",
               "id":"620252"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"INGENIERO JACOBACCI",
            "id":"62091050000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Jacobacci",
               "id":"62091050"
            },
            "centroide":{
               "lat":-41.3268134240327,
               "lon":-69.5441263890306
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Menucos",
               "id":"620259"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"LOS MENUCOS",
            "id":"62091060000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Los Menucos",
               "id":"62091060"
            },
            "centroide":{
               "lat":-40.8451911426273,
               "lon":-68.0832025061279
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maquinchao",
               "id":"620266"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"MAQUINCHAO",
            "id":"62091070000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Maquinchao",
               "id":"62091070"
            },
            "centroide":{
               "lat":-41.2475165638608,
               "lon":-68.7003410687453
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"MINA SANTA TERESITA",
            "id":"62091080000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Mina Santa Teresita",
               "id":"62091080"
            },
            "centroide":{
               "lat":-40.9343435582627,
               "lon":-69.411658784618
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilquiniyeu",
               "id":"625252"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"62091"
            },
            "nombre":"PILQUINIYEU",
            "id":"62091090000",
            "provincia":{
               "nombre":"Río Negro",
               "id":"62"
            },
            "localidad_censal":{
               "nombre":"Pilquiniyeu",
               "id":"62091090"
            },
            "centroide":{
               "lat":-41.9083865405739,
               "lon":-68.3424732450183
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Apolinario Saravia",
               "id":"660007"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"APOLINARIO SARAVIA",
            "id":"66007010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Apolinario Saravia",
               "id":"66007010"
            },
            "centroide":{
               "lat":-24.4402117565864,
               "lon":-64.0003361370349
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Joaquín V. González",
               "id":"660028"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"CEIBALITO",
            "id":"66007020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Ceibalito",
               "id":"66007020"
            },
            "centroide":{
               "lat":-25.1278603615752,
               "lon":-64.2896735059411
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Joaquín V. González",
               "id":"660028"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"CENTRO 25 DE JUNIO",
            "id":"66007030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Centro 25 de Junio",
               "id":"66007030"
            },
            "centroide":{
               "lat":-24.9773189675124,
               "lon":-63.8698564395644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Apolinario Saravia",
               "id":"660007"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"CORONEL MOLLINEDO",
            "id":"66007040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Coronel Mollinedo",
               "id":"66007040"
            },
            "centroide":{
               "lat":-24.5136418287603,
               "lon":-64.0648836625582
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Joaquín V. González",
               "id":"660028"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"CORONEL OLLEROS",
            "id":"66007050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Coronel Olleros",
               "id":"66007050"
            },
            "centroide":{
               "lat":-25.1146923148948,
               "lon":-64.2258664425772
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Quebrachal",
               "id":"660014"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"EL QUEBRACHAL",
            "id":"66007060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Quebrachal",
               "id":"66007060"
            },
            "centroide":{
               "lat":-25.3519821824981,
               "lon":-64.0287963887805
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Quebrachal",
               "id":"660014"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"GAONA",
            "id":"66007070000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Gaona",
               "id":"66007070"
            },
            "centroide":{
               "lat":-25.2581750325612,
               "lon":-64.0470243002426
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pizarro",
               "id":"660021"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"GENERAL PIZARRO",
            "id":"66007080000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"General Pizarro",
               "id":"66007080"
            },
            "centroide":{
               "lat":-24.2342248497577,
               "lon":-63.9910915395849
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Joaquín V. González",
               "id":"660028"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"JOAQUIN V. GONZALEZ",
            "id":"66007090000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Joaquín V. González",
               "id":"66007090"
            },
            "centroide":{
               "lat":-25.1294324707809,
               "lon":-64.1385893694805
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Lajitas",
               "id":"660035"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"LAS LAJITAS",
            "id":"66007100000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Las Lajitas",
               "id":"66007100"
            },
            "centroide":{
               "lat":-24.7331988444238,
               "lon":-64.1994831202005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Pizarro",
               "id":"660021"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"LUIS BURELA",
            "id":"66007110000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Luis Burela",
               "id":"66007110"
            },
            "centroide":{
               "lat":-24.397713500488,
               "lon":-63.9961108041489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Quebrachal",
               "id":"660014"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"MACAPILLO",
            "id":"66007120000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Macapillo",
               "id":"66007120"
            },
            "centroide":{
               "lat":-25.421569027557,
               "lon":-63.9896049019406
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Quebrachal",
               "id":"660014"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"NUESTRA SEÑORA DE TALAVERA",
            "id":"66007130000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Nuestra Señora de Talavera",
               "id":"66007130"
            },
            "centroide":{
               "lat":-25.481072557645,
               "lon":-63.7880008281707
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Joaquín V. González",
               "id":"660028"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"PIQUETE CABADO",
            "id":"66007140000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Piquete Cabado",
               "id":"66007140"
            },
            "centroide":{
               "lat":-24.8260406674686,
               "lon":-64.1855790667322
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Lajitas",
               "id":"660035"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"RIO DEL VALLE",
            "id":"66007150000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Río del Valle",
               "id":"66007150"
            },
            "centroide":{
               "lat":-24.6839685116395,
               "lon":-64.2017888257331
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Quebrachal",
               "id":"660014"
            },
            "departamento":{
               "nombre":"Anta",
               "id":"66007"
            },
            "nombre":"TOLLOCHE",
            "id":"66007160000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Tolloche",
               "id":"66007160"
            },
            "centroide":{
               "lat":-25.5466456710139,
               "lon":-63.5342918601747
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cachi",
               "id":"660042"
            },
            "departamento":{
               "nombre":"Cachi",
               "id":"66014"
            },
            "nombre":"CACHI",
            "id":"66014010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Cachi",
               "id":"66014010"
            },
            "centroide":{
               "lat":-25.1201640072396,
               "lon":-66.1679494412163
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Payogasta",
               "id":"660049"
            },
            "departamento":{
               "nombre":"Cachi",
               "id":"66014"
            },
            "nombre":"PAYOGASTA",
            "id":"66014020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Payogasta",
               "id":"66014020"
            },
            "centroide":{
               "lat":-25.0489479804199,
               "lon":-66.1027102397315
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cafayate",
               "id":"660056"
            },
            "departamento":{
               "nombre":"Cafayate",
               "id":"66021"
            },
            "nombre":"CAFAYATE",
            "id":"66021010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Cafayate",
               "id":"66021010"
            },
            "centroide":{
               "lat":-26.0765384358548,
               "lon":-65.9862897320293
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cafayate",
               "id":"660056"
            },
            "departamento":{
               "nombre":"Cafayate",
               "id":"66021"
            },
            "nombre":"TOLOMBOM",
            "id":"66021020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Tolombón",
               "id":"66021020"
            },
            "centroide":{
               "lat":-26.2029574679842,
               "lon":-65.9467957704698
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"660070"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"ATOCHA",
            "id":"66028010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Atocha",
               "id":"66028010"
            },
            "centroide":{
               "lat":-24.8166716062155,
               "lon":-65.4787180407276
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"660070"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"BARRIO SAN RAFAEL",
            "id":"66028030001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Ciénaga y Barrio San Rafael",
               "id":"66028030"
            },
            "centroide":{
               "lat":-24.8141965630247,
               "lon":-65.4579729482216
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"660070"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"LA CIENAGA",
            "id":"66028030002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Ciénaga y Barrio San Rafael",
               "id":"66028030"
            },
            "centroide":{
               "lat":-24.8086153228208,
               "lon":-65.5127341551445
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"660070"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"LAS COSTAS",
            "id":"66028040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Las Costas",
               "id":"66028040"
            },
            "centroide":{
               "lat":-24.7690498139422,
               "lon":-65.4860333610823
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salta",
               "id":"660063"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"SALTA",
            "id":"66028050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Salta",
               "id":"66028050"
            },
            "centroide":{
               "lat":-24.7823766403156,
               "lon":-65.4141329991055
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salta",
               "id":"660063"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"CC EL TIPAL",
            "id":"66028050001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Salta",
               "id":"66028050"
            },
            "centroide":{
               "lat":-24.7656882287643,
               "lon":-65.4762993863652
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salta",
               "id":"660063"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"CC LA ALMUDENA",
            "id":"66028050002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Salta",
               "id":"66028050"
            },
            "centroide":{
               "lat":-24.763544418349,
               "lon":-65.4639762070424
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salta",
               "id":"660063"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"SALTA",
            "id":"66028050003",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Salta",
               "id":"66028050"
            },
            "centroide":{
               "lat":-24.7865548806711,
               "lon":-65.3976354202705
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"660070"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"66028"
            },
            "nombre":"VILLA SAN LORENZO",
            "id":"66028060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa San Lorenzo",
               "id":"66028060"
            },
            "centroide":{
               "lat":-24.7333009865645,
               "lon":-65.4847891426227
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"CERRILLOS",
            "id":"66035010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Cerrillos",
               "id":"66035010"
            },
            "centroide":{
               "lat":-24.899748268594,
               "lon":-65.4884058419647
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Merced",
               "id":"660084"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"LA MERCED",
            "id":"66035020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Merced",
               "id":"66035020"
            },
            "centroide":{
               "lat":-24.9660896493444,
               "lon":-65.4895901036815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Merced",
               "id":"660084"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"SAN AGUSTIN",
            "id":"66035030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San Agustín",
               "id":"66035030"
            },
            "centroide":{
               "lat":-24.9967896088099,
               "lon":-65.4377599331623
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"VILLA LOS ALAMOS",
            "id":"66035040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
               "id":"66035040"
            },
            "centroide":{
               "lat":-24.8630942974132,
               "lon":-65.4594268472934
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"BARRIO EL CONGRESO",
            "id":"66035040001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
               "id":"66035040"
            },
            "centroide":{
               "lat":-24.8774314408788,
               "lon":-65.478464215737
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"BARRIO LAS TUNAS",
            "id":"66035040002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
               "id":"66035040"
            },
            "centroide":{
               "lat":-24.8581686105385,
               "lon":-65.3991341395925
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"BARRIO LOS OLMOS",
            "id":"66035040003",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
               "id":"66035040"
            },
            "centroide":{
               "lat":-24.8784306645047,
               "lon":-65.466289926351
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"BARRIO LOS PINARES",
            "id":"66035040004",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
               "id":"66035040"
            },
            "centroide":{
               "lat":-24.8581686105385,
               "lon":-65.3991341395925
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cerrillos",
               "id":"660077"
            },
            "departamento":{
               "nombre":"Cerrillos",
               "id":"66035"
            },
            "nombre":"VILLA LOS ALAMOS",
            "id":"66035040005",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
               "id":"66035040"
            },
            "centroide":{
               "lat":-24.8640287340554,
               "lon":-65.4641823560161
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chicoana",
               "id":"660091"
            },
            "departamento":{
               "nombre":"Chicoana",
               "id":"66042"
            },
            "nombre":"LA MAROMA",
            "id":"66042003000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Barrio Finca La Maroma",
               "id":"66042003"
            },
            "centroide":{
               "lat":-25.1512347050013,
               "lon":-65.4426106862489
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chicoana",
               "id":"660091"
            },
            "departamento":{
               "nombre":"Chicoana",
               "id":"66042"
            },
            "nombre":"BARRIO LA ROTONDA",
            "id":"66042005000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Barrio La Rotonda",
               "id":"66042005"
            },
            "centroide":{
               "lat":-25.0825653440585,
               "lon":-65.5369944152078
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chicoana",
               "id":"660091"
            },
            "departamento":{
               "nombre":"Chicoana",
               "id":"66042"
            },
            "nombre":"BARRIO SANTA TERESITA",
            "id":"66042007000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Barrio Santa Teresita",
               "id":"66042007"
            },
            "centroide":{
               "lat":-25.0370260259242,
               "lon":-65.5726459572587
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chicoana",
               "id":"660091"
            },
            "departamento":{
               "nombre":"Chicoana",
               "id":"66042"
            },
            "nombre":"CHICOANA",
            "id":"66042010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Chicoana",
               "id":"66042010"
            },
            "centroide":{
               "lat":-25.1056023710125,
               "lon":-65.5368903535961
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Carril",
               "id":"660098"
            },
            "departamento":{
               "nombre":"Chicoana",
               "id":"66042"
            },
            "nombre":"EL CARRIL",
            "id":"66042020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Carril",
               "id":"66042020"
            },
            "centroide":{
               "lat":-25.0768547028405,
               "lon":-65.4938473919709
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Santo",
               "id":"660105"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"66049"
            },
            "nombre":"CAMPO SANTO",
            "id":"66049010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Campo Santo",
               "id":"66049010"
            },
            "centroide":{
               "lat":-24.6839924533229,
               "lon":-65.102855681603
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Santo",
               "id":"660105"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"66049"
            },
            "nombre":"COBOS",
            "id":"66049020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Cobos",
               "id":"66049020"
            },
            "centroide":{
               "lat":-24.7414709076566,
               "lon":-65.0839324652619
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Bordo",
               "id":"660112"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"66049"
            },
            "nombre":"EL BORDO",
            "id":"66049030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Bordo",
               "id":"66049030"
            },
            "centroide":{
               "lat":-24.6610743291372,
               "lon":-65.1054298866391
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Güemes",
               "id":"660119"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"66049"
            },
            "nombre":"GENERAL GUEMES",
            "id":"66049040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"General Güemes",
               "id":"66049040"
            },
            "centroide":{
               "lat":-24.6684436198384,
               "lon":-65.0493527755239
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguaray",
               "id":"660126"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"AGUARAY",
            "id":"66056010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Aguaray",
               "id":"66056010"
            },
            "centroide":{
               "lat":-22.2388143093458,
               "lon":-63.7283889210813
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"660147"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"CAMPAMENTO VESPUCIO",
            "id":"66056020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Campamento Vespucio",
               "id":"66056020"
            },
            "centroide":{
               "lat":-22.5800471662206,
               "lon":-63.8523231844895
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"CAMPICHUELO",
            "id":"66056030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Campichuelo",
               "id":"66056030"
            },
            "centroide":{
               "lat":-23.1062412034464,
               "lon":-63.9953139246427
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Profesor Salvador Mazza",
               "id":"660154"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"CAMPO DURAN",
            "id":"66056040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Campo Durán",
               "id":"66056040"
            },
            "centroide":{
               "lat":-22.1925191184959,
               "lon":-63.6556166497527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguaray",
               "id":"660126"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"CAPIAZUTI",
            "id":"66056050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Capiazuti",
               "id":"66056050"
            },
            "centroide":{
               "lat":-22.1673388747523,
               "lon":-63.7086209918975
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"CARBONCITO",
            "id":"66056060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Carboncito",
               "id":"66056060"
            },
            "centroide":{
               "lat":-23.2595016179972,
               "lon":-63.8026685275306
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"660147"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"CORONEL CORNEJO",
            "id":"66056070000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Coronel Cornejo",
               "id":"66056070"
            },
            "centroide":{
               "lat":-22.736606161274,
               "lon":-63.8212340428559
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"DRAGONES",
            "id":"66056080000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Dragones",
               "id":"66056080"
            },
            "centroide":{
               "lat":-23.2581867482105,
               "lon":-63.3390555232417
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"EMBARCACION",
            "id":"66056090000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Embarcación",
               "id":"66056090"
            },
            "centroide":{
               "lat":-23.2042135269827,
               "lon":-64.0900817936338
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"EMBARCACION",
            "id":"66056090001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Embarcación",
               "id":"66056090"
            },
            "centroide":{
               "lat":-23.2350868335657,
               "lon":-64.0122926146674
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"MISION TIERRAS FISCALES",
            "id":"66056090002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Embarcación",
               "id":"66056090"
            },
            "centroide":{
               "lat":-23.2644560588445,
               "lon":-64.0052653883187
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Ballivián",
               "id":"660140"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"GENERAL BALLIVIAN",
            "id":"66056100000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"General Ballivián",
               "id":"66056100"
            },
            "centroide":{
               "lat":-22.9276105225425,
               "lon":-63.8522934424036
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"660147"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"GENERAL MOSCONI",
            "id":"66056110000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"General Mosconi",
               "id":"66056110"
            },
            "centroide":{
               "lat":-22.5872984531874,
               "lon":-63.8075098662841
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"660147"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"GENERAL MOSCONI",
            "id":"66056110001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"General Mosconi",
               "id":"66056110"
            },
            "centroide":{
               "lat":-22.5888626468449,
               "lon":-63.8118898123133
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"660147"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"RECAREDO",
            "id":"66056110002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"General Mosconi",
               "id":"66056110"
            },
            "centroide":{
               "lat":-22.4497652091167,
               "lon":-63.8856192155364
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"HICKMAN",
            "id":"66056120000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Hickman",
               "id":"66056120"
            },
            "centroide":{
               "lat":-23.2174884897188,
               "lon":-63.5643624737058
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"MISION CHAQUEÑA",
            "id":"66056130000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Misión Chaqueña",
               "id":"66056130"
            },
            "centroide":{
               "lat":-23.2761412353834,
               "lon":-63.7361800240654
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"BARRIO EL MILAGRO",
            "id":"66056140001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Misión El Cruce - El Milagro - El Jardín de San Martín",
               "id":"66056140"
            },
            "centroide":{
               "lat":-22.5413249097327,
               "lon":-63.7927888759365
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Mosconi",
               "id":"660147"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"BARRIO EL JARDIN DE SAN MARTIN",
            "id":"66056140002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Misión El Cruce - El Milagro - El Jardín de San Martín",
               "id":"66056140"
            },
            "centroide":{
               "lat":-22.4497652091167,
               "lon":-63.8856192155364
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"MISION EL CRUCE",
            "id":"66056140003",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Misión El Cruce - El Milagro - El Jardín de San Martín",
               "id":"66056140"
            },
            "centroide":{
               "lat":-22.5413249097327,
               "lon":-63.7927888759365
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"MISION KILOMETRO 6",
            "id":"66056150000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Misión Kilómetro 6",
               "id":"66056150"
            },
            "centroide":{
               "lat":-22.5060228278102,
               "lon":-63.7399721901134
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"PACARA",
            "id":"66056170000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Pacará",
               "id":"66056170"
            },
            "centroide":{
               "lat":-22.4452723425454,
               "lon":-63.43543212618
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Embarcación",
               "id":"660133"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"PADRE LOZANO",
            "id":"66056180000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Padre Lozano",
               "id":"66056180"
            },
            "centroide":{
               "lat":-23.215724803759,
               "lon":-63.8426467906727
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguaray",
               "id":"660126"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"PIQUIRENDA",
            "id":"66056190000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Piquirenda",
               "id":"66056190"
            },
            "centroide":{
               "lat":-22.3335490812005,
               "lon":-63.7596847135861
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Profesor Salvador Mazza",
               "id":"660154"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"PROFESOR SALVADOR MAZZA",
            "id":"66056200000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Profesor Salvador Mazza",
               "id":"66056200"
            },
            "centroide":{
               "lat":-22.0542634419098,
               "lon":-63.6872829649674
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"TARTAGAL",
            "id":"66056220000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Tartagal",
               "id":"66056220"
            },
            "centroide":{
               "lat":-22.5098645099451,
               "lon":-63.7970472996098
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguaray",
               "id":"660126"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"TOBANTIRENDA",
            "id":"66056230000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Tobantirenda",
               "id":"66056230"
            },
            "centroide":{
               "lat":-22.13833247169,
               "lon":-63.7061819675909
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"TRANQUITAS",
            "id":"66056240000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Tranquitas",
               "id":"66056240"
            },
            "centroide":{
               "lat":-22.4076174158757,
               "lon":-63.7681399387898
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"660161"
            },
            "departamento":{
               "nombre":"General José de San Martín",
               "id":"66056"
            },
            "nombre":"YACUY",
            "id":"66056250000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Yacuy",
               "id":"66056250"
            },
            "centroide":{
               "lat":-22.3765769868638,
               "lon":-63.7654304903786
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guachipas",
               "id":"660168"
            },
            "departamento":{
               "nombre":"Guachipas",
               "id":"66063"
            },
            "nombre":"GUACHIPAS",
            "id":"66063010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Guachipas",
               "id":"66063010"
            },
            "centroide":{
               "lat":-25.5235484724461,
               "lon":-65.5187644501144
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iruya",
               "id":"660175"
            },
            "departamento":{
               "nombre":"Iruya",
               "id":"66070"
            },
            "nombre":"IRUYA",
            "id":"66070010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Iruya",
               "id":"66070010"
            },
            "centroide":{
               "lat":-22.7789158765788,
               "lon":-65.2063598136374
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Isla de Cañas",
               "id":"660182"
            },
            "departamento":{
               "nombre":"Iruya",
               "id":"66070"
            },
            "nombre":"ISLA DE CAÑAS",
            "id":"66070020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Isla de Cañas",
               "id":"66070020"
            },
            "centroide":{
               "lat":-22.8864200252899,
               "lon":-64.6573757192202
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iruya",
               "id":"660175"
            },
            "departamento":{
               "nombre":"Iruya",
               "id":"66070"
            },
            "nombre":"PUEBLO VIEJO",
            "id":"66070030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Pueblo Viejo",
               "id":"66070030"
            },
            "centroide":{
               "lat":-22.8263430234688,
               "lon":-65.2018356755618
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Caldera",
               "id":"660189"
            },
            "departamento":{
               "nombre":"La Caldera",
               "id":"66077"
            },
            "nombre":"LA CALDERA",
            "id":"66077010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Caldera",
               "id":"66077010"
            },
            "centroide":{
               "lat":-24.6049486234217,
               "lon":-65.3823377302988
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vaqueros",
               "id":"660196"
            },
            "departamento":{
               "nombre":"La Caldera",
               "id":"66077"
            },
            "nombre":"VAQUEROS",
            "id":"66077020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Vaqueros",
               "id":"66077020"
            },
            "centroide":{
               "lat":-24.6945211920197,
               "lon":-65.4026944187147
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Jardín",
               "id":"660203"
            },
            "departamento":{
               "nombre":"La Candelaria",
               "id":"66084"
            },
            "nombre":"EL JARDIN",
            "id":"66084010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Jardín",
               "id":"66084010"
            },
            "centroide":{
               "lat":-26.0938834199791,
               "lon":-65.41384156239
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Tala",
               "id":"660210"
            },
            "departamento":{
               "nombre":"La Candelaria",
               "id":"66084"
            },
            "nombre":"EL TALA",
            "id":"66084020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Tala",
               "id":"66084020"
            },
            "centroide":{
               "lat":-26.1208917578309,
               "lon":-65.2873058787689
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Candelaria",
               "id":"660217"
            },
            "departamento":{
               "nombre":"La Candelaria",
               "id":"66084"
            },
            "nombre":"LA CANDELARIA",
            "id":"66084030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Candelaria",
               "id":"66084030"
            },
            "centroide":{
               "lat":-26.0961790089843,
               "lon":-65.0610119525095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Poma",
               "id":"660224"
            },
            "departamento":{
               "nombre":"La Poma",
               "id":"66091"
            },
            "nombre":"COBRES",
            "id":"66091010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Cobres",
               "id":"66091010"
            },
            "centroide":{
               "lat":-23.6391998500842,
               "lon":-66.2684039729566
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Poma",
               "id":"660224"
            },
            "departamento":{
               "nombre":"La Poma",
               "id":"66091"
            },
            "nombre":"LA POMA",
            "id":"66091020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Poma",
               "id":"66091020"
            },
            "centroide":{
               "lat":-24.7129010493055,
               "lon":-66.1997385176463
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Viña",
               "id":"660238"
            },
            "departamento":{
               "nombre":"La Viña",
               "id":"66098"
            },
            "nombre":"AMPASCACHI",
            "id":"66098010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Ampascachi",
               "id":"66098010"
            },
            "centroide":{
               "lat":-25.355455514438,
               "lon":-65.5323044668329
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guachipas",
               "id":"660168"
            },
            "departamento":{
               "nombre":"La Viña",
               "id":"66098"
            },
            "nombre":"CABRA CORRAL",
            "id":"66098020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Cabra Corral",
               "id":"66098020"
            },
            "centroide":{
               "lat":-25.290036707535,
               "lon":-65.38778537012
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Moldes",
               "id":"660231"
            },
            "departamento":{
               "nombre":"La Viña",
               "id":"66098"
            },
            "nombre":"CORONEL MOLDES",
            "id":"66098030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Coronel Moldes",
               "id":"66098030"
            },
            "centroide":{
               "lat":-25.2889404681421,
               "lon":-65.4745373510335
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Viña",
               "id":"660238"
            },
            "departamento":{
               "nombre":"La Viña",
               "id":"66098"
            },
            "nombre":"LA VIÑA",
            "id":"66098040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Viña",
               "id":"66098040"
            },
            "centroide":{
               "lat":-25.4719724619835,
               "lon":-65.5719838890076
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Viña",
               "id":"660238"
            },
            "departamento":{
               "nombre":"La Viña",
               "id":"66098"
            },
            "nombre":"TALAPAMPA",
            "id":"66098050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Talapampa",
               "id":"66098050"
            },
            "centroide":{
               "lat":-25.5463746287562,
               "lon":-65.559672881754
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susques",
               "id":"386168"
            },
            "departamento":{
               "nombre":"Los Andes",
               "id":"66105"
            },
            "nombre":"OLACAPATO",
            "id":"66105010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Olacapato",
               "id":"66105010"
            },
            "centroide":{
               "lat":-24.1197745515994,
               "lon":-66.7142594000385
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de los Cobres",
               "id":"660245"
            },
            "departamento":{
               "nombre":"Los Andes",
               "id":"66105"
            },
            "nombre":"SAN ANTONIO DE LOS COBRES",
            "id":"66105020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San Antonio de los Cobres",
               "id":"66105020"
            },
            "centroide":{
               "lat":-24.2099310029638,
               "lon":-66.315435998094
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de los Cobres",
               "id":"660245"
            },
            "departamento":{
               "nombre":"Los Andes",
               "id":"66105"
            },
            "nombre":"SANTA ROSA DE LOS PASTOS GRANDES",
            "id":"66105030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de los Pastos Grandes",
               "id":"66105030"
            },
            "centroide":{
               "lat":-24.478105777316,
               "lon":-66.6785400667592
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tolar Grande",
               "id":"660252"
            },
            "departamento":{
               "nombre":"Los Andes",
               "id":"66105"
            },
            "nombre":"TOLAR GRANDE",
            "id":"66105040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Tolar Grande",
               "id":"66105040"
            },
            "centroide":{
               "lat":-24.5579851944765,
               "lon":-67.4369653675359
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Galpón",
               "id":"660259"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"EL GALPON",
            "id":"66112010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Galpón",
               "id":"66112010"
            },
            "centroide":{
               "lat":-25.3915864503967,
               "lon":-64.6595062933993
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Galpón",
               "id":"660259"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"EL TUNAL",
            "id":"66112020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Tunal",
               "id":"66112020"
            },
            "centroide":{
               "lat":-25.2652645771513,
               "lon":-64.4061042227155
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Piedras",
               "id":"660280"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"LUMBRERAS",
            "id":"66112030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Lumbreras",
               "id":"66112030"
            },
            "centroide":{
               "lat":-25.2172575790264,
               "lon":-64.9306861458005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Metán",
               "id":"660266"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"SAN JOSE DE METAN",
            "id":"66112040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San José de Metán (Est. Metán)",
               "id":"66112040"
            },
            "centroide":{
               "lat":-25.5077205377148,
               "lon":-64.9821245409304
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Metán",
               "id":"660266"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"METAN VIEJO",
            "id":"66112050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Metán Viejo",
               "id":"66112050"
            },
            "centroide":{
               "lat":-25.5418039517985,
               "lon":-64.9849385087879
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Piedras",
               "id":"660280"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"RIO PIEDRAS",
            "id":"66112070000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Río Piedras",
               "id":"66112070"
            },
            "centroide":{
               "lat":-25.3211818761179,
               "lon":-64.917329424853
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Galpón",
               "id":"660259"
            },
            "departamento":{
               "nombre":"Metán",
               "id":"66112"
            },
            "nombre":"SAN JOSE DE ORQUERA",
            "id":"66112080000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San José de Orquera",
               "id":"66112080"
            },
            "centroide":{
               "lat":-25.2783094165373,
               "lon":-64.0850561648465
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Molinos",
               "id":"660273"
            },
            "departamento":{
               "nombre":"Molinos",
               "id":"66119"
            },
            "nombre":"LA PUERTA",
            "id":"66119010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Puerta",
               "id":"66119010"
            },
            "centroide":{
               "lat":-25.2804538149509,
               "lon":-66.4508396566503
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Molinos",
               "id":"660273"
            },
            "departamento":{
               "nombre":"Molinos",
               "id":"66119"
            },
            "nombre":"MOLINOS",
            "id":"66119020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Molinos",
               "id":"66119020"
            },
            "centroide":{
               "lat":-25.4442432937572,
               "lon":-66.3088500894253
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Seclantás",
               "id":"660287"
            },
            "departamento":{
               "nombre":"Molinos",
               "id":"66119"
            },
            "nombre":"SECLANTAS",
            "id":"66119030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Seclantás",
               "id":"66119030"
            },
            "centroide":{
               "lat":-25.3305099574445,
               "lon":-66.2484466228606
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"AGUAS BLANCAS",
            "id":"66126010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Aguas Blancas",
               "id":"66126010"
            },
            "centroide":{
               "lat":-22.7245611902241,
               "lon":-64.346319220401
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Santa Rosa",
               "id":"660294"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"COLONIA SANTA ROSA",
            "id":"66126020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Colonia Santa Rosa",
               "id":"66126020"
            },
            "centroide":{
               "lat":-23.3885957203176,
               "lon":-64.4234333229489
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Santa Rosa",
               "id":"660294"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"COLONIA SANTA ROSA",
            "id":"66126020001",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Colonia Santa Rosa",
               "id":"66126020"
            },
            "centroide":{
               "lat":-23.3664492093477,
               "lon":-64.3524071790769
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Santa Rosa",
               "id":"660294"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"LA MISION",
            "id":"66126020002",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Colonia Santa Rosa",
               "id":"66126020"
            },
            "centroide":{
               "lat":-23.3154629033465,
               "lon":-64.4077092056574
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Yrigoyen",
               "id":"660301"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"EL TABACAL",
            "id":"66126030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Tabacal",
               "id":"66126030"
            },
            "centroide":{
               "lat":-23.2500270361093,
               "lon":-64.2445058938392
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipólito Yrigoyen",
               "id":"660301"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"HIPOLITO YRIGOYEN",
            "id":"66126040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Hipólito Yrigoyen",
               "id":"66126040"
            },
            "centroide":{
               "lat":-23.2382702479068,
               "lon":-64.2718125360107
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pichanal",
               "id":"660308"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"PICHANAL",
            "id":"66126060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Pichanal",
               "id":"66126060"
            },
            "centroide":{
               "lat":-23.3133896525098,
               "lon":-64.219751841333
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Ramón de la Nueva Orán",
               "id":"660315"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"SAN RAMON DE LA NUEVA ORAN",
            "id":"66126070000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San Ramón de la Nueva Orán",
               "id":"66126070"
            },
            "centroide":{
               "lat":-23.1298138873917,
               "lon":-64.3185884747041
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Urundel",
               "id":"660322"
            },
            "departamento":{
               "nombre":"Orán",
               "id":"66126"
            },
            "nombre":"URUNDEL",
            "id":"66126080000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Urundel",
               "id":"66126080"
            },
            "centroide":{
               "lat":-23.5516314679633,
               "lon":-64.3965188048038
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Victoria Este",
               "id":"660343"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"ALTO DE LA SIERRA",
            "id":"66133010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Alto de la Sierra",
               "id":"66133010"
            },
            "centroide":{
               "lat":-22.6893772924132,
               "lon":-62.4527756437584
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Norte",
               "id":"660329"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"CAPITAN JUAN PAGE",
            "id":"66133020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Capitán Juan Pagé",
               "id":"66133020"
            },
            "centroide":{
               "lat":-23.7084876711103,
               "lon":-62.3817949445121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Norte",
               "id":"660329"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"CORONEL JUAN SOLA",
            "id":"66133030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Coronel Juan Solá",
               "id":"66133030"
            },
            "centroide":{
               "lat":-23.4836432078231,
               "lon":-62.8913108733338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Victoria Este",
               "id":"660343"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"HITO 1",
            "id":"66133035000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Hito 1",
               "id":"66133035"
            },
            "centroide":{
               "lat":-21.9997109682413,
               "lon":-62.8237382800005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Sur",
               "id":"660336"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"LA UNION",
            "id":"66133040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Unión",
               "id":"66133040"
            },
            "centroide":{
               "lat":-23.9462046990241,
               "lon":-63.1119727442095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Norte",
               "id":"660329"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"LOS BLANCOS",
            "id":"66133050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Los Blancos",
               "id":"66133050"
            },
            "centroide":{
               "lat":-23.630147809494,
               "lon":-62.5972373009179
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Norte",
               "id":"660329"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"PLUMA DE PATO",
            "id":"66133060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Pluma de Pato",
               "id":"66133060"
            },
            "centroide":{
               "lat":-23.3808426324425,
               "lon":-63.0980556496311
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Sur",
               "id":"660336"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"RIVADAVIA",
            "id":"66133070000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Rivadavia",
               "id":"66133070"
            },
            "centroide":{
               "lat":-24.1928479299753,
               "lon":-62.8855846188739
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Victoria Este",
               "id":"660343"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"SANTA MARIA",
            "id":"66133080000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Santa María",
               "id":"66133080"
            },
            "centroide":{
               "lat":-22.1394975256357,
               "lon":-62.8383245890232
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia Banda Sur",
               "id":"660336"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"SANTA ROSA",
            "id":"66133090000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa",
               "id":"66133090"
            },
            "centroide":{
               "lat":-24.0763765853123,
               "lon":-63.1236773681358
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Victoria Este",
               "id":"660343"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"66133"
            },
            "nombre":"SANTA VICTORIA ESTE",
            "id":"66133100000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Santa Victoria Este",
               "id":"66133100"
            },
            "centroide":{
               "lat":-22.2772903307188,
               "lon":-62.7043719454645
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Potrero",
               "id":"660350"
            },
            "departamento":{
               "nombre":"Rosario de la Frontera",
               "id":"66140"
            },
            "nombre":"ANTILLA",
            "id":"66140010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Antillá",
               "id":"66140010"
            },
            "centroide":{
               "lat":-26.1367474431656,
               "lon":-64.6079955159401
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Potrero",
               "id":"660350"
            },
            "departamento":{
               "nombre":"Rosario de la Frontera",
               "id":"66140"
            },
            "nombre":"COPO QUILE",
            "id":"66140020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Copo Quile",
               "id":"66140020"
            },
            "centroide":{
               "lat":-26.0280256948964,
               "lon":-64.6831914609089
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de la Frontera",
               "id":"660357"
            },
            "departamento":{
               "nombre":"Rosario de la Frontera",
               "id":"66140"
            },
            "nombre":"EL NARANJO",
            "id":"66140030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Naranjo",
               "id":"66140030"
            },
            "centroide":{
               "lat":-25.7376092528727,
               "lon":-65.0197887857072
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Potrero",
               "id":"660350"
            },
            "departamento":{
               "nombre":"Rosario de la Frontera",
               "id":"66140"
            },
            "nombre":"EL POTRERO",
            "id":"66140040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Potrero",
               "id":"66140040"
            },
            "centroide":{
               "lat":-26.0638952332339,
               "lon":-64.656831424558
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de la Frontera",
               "id":"660357"
            },
            "departamento":{
               "nombre":"Rosario de la Frontera",
               "id":"66140"
            },
            "nombre":"ROSARIO DE LA FRONTERA",
            "id":"66140050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Rosario de la Frontera",
               "id":"66140050"
            },
            "centroide":{
               "lat":-25.8088971820865,
               "lon":-64.9840618242784
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de la Frontera",
               "id":"660357"
            },
            "departamento":{
               "nombre":"Rosario de la Frontera",
               "id":"66140"
            },
            "nombre":"SAN FELIPE",
            "id":"66140060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San Felipe",
               "id":"66140060"
            },
            "centroide":{
               "lat":-25.7231125127283,
               "lon":-64.8260626677768
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Quijano",
               "id":"660364"
            },
            "departamento":{
               "nombre":"Rosario de Lerma",
               "id":"66147"
            },
            "nombre":"CAMPO QUIJANO",
            "id":"66147010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Campo Quijano",
               "id":"66147010"
            },
            "centroide":{
               "lat":-24.9095603902622,
               "lon":-65.6395050258431
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de Lerma",
               "id":"660371"
            },
            "departamento":{
               "nombre":"Rosario de Lerma",
               "id":"66147"
            },
            "nombre":"LA MERCED DEL ENCON",
            "id":"66147015000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Merced del Encón",
               "id":"66147015"
            },
            "centroide":{
               "lat":-24.8709646124106,
               "lon":-65.5607604731238
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Quijano",
               "id":"660364"
            },
            "departamento":{
               "nombre":"Rosario de Lerma",
               "id":"66147"
            },
            "nombre":"LA SILLETA",
            "id":"66147020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"La Silleta",
               "id":"66147020"
            },
            "centroide":{
               "lat":-24.8784643256455,
               "lon":-65.5901794974296
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rosario de Lerma",
               "id":"660371"
            },
            "departamento":{
               "nombre":"Rosario de Lerma",
               "id":"66147"
            },
            "nombre":"ROSARIO DE LERMA",
            "id":"66147030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Rosario de Lerma",
               "id":"66147030"
            },
            "centroide":{
               "lat":-24.9780005506773,
               "lon":-65.5804316181731
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Angastaco",
               "id":"660378"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"66154"
            },
            "nombre":"ANGASTACO",
            "id":"66154010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Angastaco",
               "id":"66154010"
            },
            "centroide":{
               "lat":-25.6837320095857,
               "lon":-66.1630084648275
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Animaná",
               "id":"660385"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"66154"
            },
            "nombre":"ANIMANA",
            "id":"66154020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Animaná",
               "id":"66154020"
            },
            "centroide":{
               "lat":-25.9250695350422,
               "lon":-65.9634886394429
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Animaná",
               "id":"660385"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"66154"
            },
            "nombre":"EL BARRIAL",
            "id":"66154030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"El Barrial",
               "id":"66154030"
            },
            "centroide":{
               "lat":-25.9119189620255,
               "lon":-65.9509642786695
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos",
               "id":"660392"
            },
            "departamento":{
               "nombre":"San Carlos",
               "id":"66154"
            },
            "nombre":"SAN CARLOS",
            "id":"66154040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San Carlos",
               "id":"66154040"
            },
            "centroide":{
               "lat":-25.8954496537353,
               "lon":-65.9374115460195
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Victoria Oeste",
               "id":"660413"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"ACOYTE",
            "id":"66161010000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Acoyte",
               "id":"66161010"
            },
            "centroide":{
               "lat":-22.2625708057672,
               "lon":-64.9993425915289
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nazareno",
               "id":"660406"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"CAMPO LA CRUZ",
            "id":"66161020000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Campo La Cruz",
               "id":"66161020"
            },
            "centroide":{
               "lat":-22.4282266803066,
               "lon":-65.1454049664521
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Toldos",
               "id":"660399"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"LOS TOLDOS",
            "id":"66161030000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Los Toldos",
               "id":"66161030"
            },
            "centroide":{
               "lat":-22.2528953049178,
               "lon":-64.6816676603736
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nazareno",
               "id":"660406"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"NAZARENO",
            "id":"66161040000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Nazareno",
               "id":"66161040"
            },
            "centroide":{
               "lat":-22.4818544506086,
               "lon":-65.0949733414157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nazareno",
               "id":"660406"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"POSCAYA",
            "id":"66161050000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Poscaya",
               "id":"66161050"
            },
            "centroide":{
               "lat":-22.4336266127546,
               "lon":-65.0678957138662
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nazareno",
               "id":"660406"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"SAN MARCOS",
            "id":"66161060000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"San Marcos",
               "id":"66161060"
            },
            "centroide":{
               "lat":-22.50900544813,
               "lon":-65.1012423839061
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Victoria Oeste",
               "id":"660413"
            },
            "departamento":{
               "nombre":"Santa Victoria",
               "id":"66161"
            },
            "nombre":"SANTA VICTORIA",
            "id":"66161070000",
            "provincia":{
               "nombre":"Salta",
               "id":"66"
            },
            "localidad_censal":{
               "nombre":"Santa Victoria",
               "id":"66161070"
            },
            "centroide":{
               "lat":-22.2294615004585,
               "lon":-64.9503255468472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Albardón",
               "id":"700007"
            },
            "departamento":{
               "nombre":"Albardón",
               "id":"70007"
            },
            "nombre":"EL RINCON",
            "id":"70007010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"El Rincón",
               "id":"70007010"
            },
            "centroide":{
               "lat":-31.4480943761332,
               "lon":-68.5425141343624
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Albardón",
               "id":"700007"
            },
            "departamento":{
               "nombre":"Albardón",
               "id":"70007"
            },
            "nombre":"CAMPO AFUERA",
            "id":"70007020001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa General San Martín - Campo Afuera",
               "id":"70007020"
            },
            "centroide":{
               "lat":-31.4165329806484,
               "lon":-68.5316143378439
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Albardón",
               "id":"700007"
            },
            "departamento":{
               "nombre":"Albardón",
               "id":"70007"
            },
            "nombre":"VILLA AMPACAMA",
            "id":"70007020003",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa General San Martín - Campo Afuera",
               "id":"70007020"
            },
            "centroide":{
               "lat":-31.3864085743851,
               "lon":-68.4572411115084
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Albardón",
               "id":"700007"
            },
            "departamento":{
               "nombre":"Albardón",
               "id":"70007"
            },
            "nombre":"VILLA GENERAL SAN MARTIN",
            "id":"70007020004",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa General San Martín - Campo Afuera",
               "id":"70007020"
            },
            "centroide":{
               "lat":-31.421071066377,
               "lon":-68.4907605706737
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Angaco",
               "id":"700014"
            },
            "departamento":{
               "nombre":"Angaco",
               "id":"70014"
            },
            "nombre":"LAS TAPIAS",
            "id":"70014010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Tapias",
               "id":"70014010"
            },
            "centroide":{
               "lat":-31.4084472492778,
               "lon":-68.3999429561259
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Angaco",
               "id":"700014"
            },
            "departamento":{
               "nombre":"Angaco",
               "id":"70014"
            },
            "nombre":"VILLA EL SALVADOR",
            "id":"70014020001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa El Salvador - Villa Sefair",
               "id":"70014020"
            },
            "centroide":{
               "lat":-31.4454020414681,
               "lon":-68.417640160613
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Angaco",
               "id":"700014"
            },
            "departamento":{
               "nombre":"Angaco",
               "id":"70014"
            },
            "nombre":"VILLA SEFAIR (TALACASTO)",
            "id":"70014020002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa El Salvador - Villa Sefair",
               "id":"70014020"
            },
            "centroide":{
               "lat":-31.4439397880938,
               "lon":-68.4217688171581
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calingasta",
               "id":"700021"
            },
            "departamento":{
               "nombre":"Calingasta",
               "id":"70021"
            },
            "nombre":"BARREAL",
            "id":"70021010001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Barreal - Villa Pituil",
               "id":"70021010"
            },
            "centroide":{
               "lat":-31.652328851699,
               "lon":-69.479673849861
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calingasta",
               "id":"700021"
            },
            "departamento":{
               "nombre":"Calingasta",
               "id":"70021"
            },
            "nombre":"VILLA PITUIL",
            "id":"70021010002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Barreal - Villa Pituil",
               "id":"70021010"
            },
            "centroide":{
               "lat":-31.6528323304108,
               "lon":-69.4815385910816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calingasta",
               "id":"700021"
            },
            "departamento":{
               "nombre":"Calingasta",
               "id":"70021"
            },
            "nombre":"CALINGASTA",
            "id":"70021020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Calingasta",
               "id":"70021020"
            },
            "centroide":{
               "lat":-31.335410441528,
               "lon":-69.4273817834109
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calingasta",
               "id":"700021"
            },
            "departamento":{
               "nombre":"Calingasta",
               "id":"70021"
            },
            "nombre":"TAMBERIAS",
            "id":"70021030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Tamberías",
               "id":"70021030"
            },
            "centroide":{
               "lat":-31.46037726198,
               "lon":-69.4229130996947
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Juan",
               "id":"700028"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"70028"
            },
            "nombre":"SAN JUAN",
            "id":"70028010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"San Juan",
               "id":"70028010"
            },
            "centroide":{
               "lat":-31.5371970378027,
               "lon":-68.5250183173793
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"BERMEJO",
            "id":"70035010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Bermejo",
               "id":"70035010"
            },
            "centroide":{
               "lat":-31.5918363542723,
               "lon":-67.6623620492559
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"BARRIO JUSTO P. CASTRO IV",
            "id":"70035015000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Barrio Justo P. Castro IV",
               "id":"70035015"
            },
            "centroide":{
               "lat":-31.6283025583976,
               "lon":-68.3008272782109
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"CAUCETE",
            "id":"70035020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Caucete",
               "id":"70035020"
            },
            "centroide":{
               "lat":-31.6514787868907,
               "lon":-68.2821404352408
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"EL RINCON",
            "id":"70035030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"El Rincón",
               "id":"70035030"
            },
            "centroide":{
               "lat":-31.6648793452851,
               "lon":-68.3211580078388
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"LAS TALAS",
            "id":"70035040001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Talas - Los Médanos",
               "id":"70035040"
            },
            "centroide":{
               "lat":-31.6009569892315,
               "lon":-68.2979743657121
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"LOS MEDANOS",
            "id":"70035040002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Talas - Los Médanos",
               "id":"70035040"
            },
            "centroide":{
               "lat":-31.6267504756331,
               "lon":-68.2618585178493
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"MARAYES",
            "id":"70035050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Marayes",
               "id":"70035050"
            },
            "centroide":{
               "lat":-31.4776695630337,
               "lon":-67.3093651877693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"PIE DE PALO",
            "id":"70035060000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Pie de Palo",
               "id":"70035060"
            },
            "centroide":{
               "lat":-31.6609347801137,
               "lon":-68.2204015492999
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"VALLECITO",
            "id":"70035070000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Vallecito",
               "id":"70035070"
            },
            "centroide":{
               "lat":-31.7403744670002,
               "lon":-67.9885245900589
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Caucete",
               "id":"700035"
            },
            "departamento":{
               "nombre":"Caucete",
               "id":"70035"
            },
            "nombre":"VILLA INDEPENDENCIA",
            "id":"70035080000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Independencia",
               "id":"70035080"
            },
            "centroide":{
               "lat":-31.6245292071939,
               "lon":-68.3138150331219
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chimbas",
               "id":"700042"
            },
            "departamento":{
               "nombre":"Chimbas",
               "id":"70042"
            },
            "nombre":"CHIMBAS",
            "id":"70042010001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Chimbas",
               "id":"70042010"
            },
            "centroide":{
               "lat":-31.4929011532856,
               "lon":-68.4840477499344
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chimbas",
               "id":"700042"
            },
            "departamento":{
               "nombre":"Chimbas",
               "id":"70042"
            },
            "nombre":"EL MOGOTE",
            "id":"70042010002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Chimbas",
               "id":"70042010"
            },
            "centroide":{
               "lat":-31.4800263304441,
               "lon":-68.4821821646732
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chimbas",
               "id":"700042"
            },
            "departamento":{
               "nombre":"Chimbas",
               "id":"70042"
            },
            "nombre":"VILLA PAULA ALBARRACIN",
            "id":"70042010003",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Chimbas",
               "id":"70042010"
            },
            "centroide":{
               "lat":-31.4936358990356,
               "lon":-68.5357082138994
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"ANGUALASTO",
            "id":"70049010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Angualasto",
               "id":"70049010"
            },
            "centroide":{
               "lat":-30.0536455973497,
               "lon":-69.1717079231376
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"BELLA VISTA",
            "id":"70049020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Bella Vista",
               "id":"70049020"
            },
            "centroide":{
               "lat":-30.4401531794669,
               "lon":-69.2447866603611
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"IGLESIA",
            "id":"70049030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Iglesia",
               "id":"70049030"
            },
            "centroide":{
               "lat":-30.4128218913916,
               "lon":-69.2051642049627
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"LAS FLORES",
            "id":"70049040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Flores",
               "id":"70049040"
            },
            "centroide":{
               "lat":-30.3242644504455,
               "lon":-69.2453069928074
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"PISMANTA",
            "id":"70049050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Pismanta",
               "id":"70049050"
            },
            "centroide":{
               "lat":-30.2709800462776,
               "lon":-69.2286104874715
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"RODEO",
            "id":"70049060000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Rodeo",
               "id":"70049060"
            },
            "centroide":{
               "lat":-30.209958054186,
               "lon":-69.1336132382893
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Iglesia",
               "id":"700049"
            },
            "departamento":{
               "nombre":"Iglesia",
               "id":"70049"
            },
            "nombre":"TUDCUM",
            "id":"70049070000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Tudcum",
               "id":"70049070"
            },
            "centroide":{
               "lat":-30.1883781131447,
               "lon":-69.2702835946583
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"EL MEDANO",
            "id":"70056010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"El Médano",
               "id":"70056010"
            },
            "centroide":{
               "lat":-30.1293832105379,
               "lon":-68.6791733044187
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"GRAN CHINA",
            "id":"70056020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Gran China",
               "id":"70056020"
            },
            "centroide":{
               "lat":-30.1225273246059,
               "lon":-68.7161999143951
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"HUACO",
            "id":"70056030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Huaco",
               "id":"70056030"
            },
            "centroide":{
               "lat":-30.1570667184839,
               "lon":-68.4809365791381
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"MOGNA",
            "id":"70056040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Mogna",
               "id":"70056040"
            },
            "centroide":{
               "lat":-30.6845100664473,
               "lon":-68.3775543569819
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"NIQUIVIL",
            "id":"70056050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Niquivil",
               "id":"70056050"
            },
            "centroide":{
               "lat":-30.4006750071532,
               "lon":-68.6910630973149
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"EL FISCAL",
            "id":"70056060001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Pampa Vieja",
               "id":"70056060"
            },
            "centroide":{
               "lat":-30.2202750575676,
               "lon":-68.6909967597496
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"LA FALDA",
            "id":"70056060002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Pampa Vieja",
               "id":"70056060"
            },
            "centroide":{
               "lat":-30.1628729670379,
               "lon":-68.6652633159112
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"PAMPA VIEJA",
            "id":"70056060003",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Pampa Vieja",
               "id":"70056060"
            },
            "centroide":{
               "lat":-30.2202750575676,
               "lon":-68.6909967597496
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"SAN ISIDRO",
            "id":"70056070000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"70056070"
            },
            "centroide":{
               "lat":-30.1488923473271,
               "lon":-68.7044945534412
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"SAN JOSE DE JACHAL",
            "id":"70056080000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"San José de Jáchal",
               "id":"70056080"
            },
            "centroide":{
               "lat":-30.2427672850044,
               "lon":-68.7454934559357
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"TAMBERIAS",
            "id":"70056090000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Tamberías",
               "id":"70056090"
            },
            "centroide":{
               "lat":-30.1822864487075,
               "lon":-68.7277857836372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"VILLA MALVINAS ARGENTINAS",
            "id":"70056100000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Malvinas Argentinas",
               "id":"70056100"
            },
            "centroide":{
               "lat":-30.2142413595614,
               "lon":-68.7165558327867
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jáchal",
               "id":"700056"
            },
            "departamento":{
               "nombre":"Jáchal",
               "id":"70056"
            },
            "nombre":"VILLA MERCEDES",
            "id":"70056110000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Mercedes",
               "id":"70056110"
            },
            "centroide":{
               "lat":-30.1088721898605,
               "lon":-68.7009429184891
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"700063"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"70063"
            },
            "nombre":"COLONIA FIORITO",
            "id":"70063020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Colonia Fiorito",
               "id":"70063020"
            },
            "centroide":{
               "lat":-31.5551785871028,
               "lon":-68.4205987445022
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"700063"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"70063"
            },
            "nombre":"LAS CHACRITAS",
            "id":"70063030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Chacritas",
               "id":"70063030"
            },
            "centroide":{
               "lat":-31.5936417922213,
               "lon":-68.4077196718125
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"9 de Julio",
               "id":"700063"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"70063"
            },
            "nombre":"9 DE JULIO",
            "id":"70063040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"9 de Julio",
               "id":"70063040"
            },
            "centroide":{
               "lat":-31.669750919971,
               "lon":-68.3900416378963
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"BARRIO MUNICIPAL",
            "id":"70070005000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Barrio Municipal",
               "id":"70070005"
            },
            "centroide":{
               "lat":-31.7523486776689,
               "lon":-68.5597213740804
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"BARRIO RUTA 40",
            "id":"70070010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Barrio Ruta 40",
               "id":"70070010"
            },
            "centroide":{
               "lat":-31.8595308229437,
               "lon":-68.5345166485174
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"CARPINTERIA",
            "id":"70070020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Carpintería",
               "id":"70070020"
            },
            "centroide":{
               "lat":-31.8301409850684,
               "lon":-68.5420989256398
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"LAS PIEDRITAS",
            "id":"70070025000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Piedritas",
               "id":"70070025"
            },
            "centroide":{
               "lat":-31.629474322189,
               "lon":-68.6080765638159
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"QUINTO CUARTEL",
            "id":"70070030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Quinto Cuartel",
               "id":"70070030"
            },
            "centroide":{
               "lat":-31.6140359426027,
               "lon":-68.6001745092873
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"LA RINCONADA",
            "id":"70070040001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Aberastain - La Rinconada",
               "id":"70070040"
            },
            "centroide":{
               "lat":-31.6952378674877,
               "lon":-68.5923025771378
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"VILLA ABERASTAIN",
            "id":"70070040002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Aberastain - La Rinconada",
               "id":"70070040"
            },
            "centroide":{
               "lat":-31.65701277213,
               "lon":-68.573608949336
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"VILLA BARBOZA",
            "id":"70070050001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Barboza - Villa Nacusi",
               "id":"70070050"
            },
            "centroide":{
               "lat":-31.5921799746835,
               "lon":-68.5723924425069
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"VILLA NACUSI",
            "id":"70070050002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Barboza - Villa Nacusi",
               "id":"70070050"
            },
            "centroide":{
               "lat":-31.6219383886537,
               "lon":-68.5359580395485
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pocito",
               "id":"700070"
            },
            "departamento":{
               "nombre":"Pocito",
               "id":"70070"
            },
            "nombre":"VILLA CENTENARIO",
            "id":"70070060000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Centenario",
               "id":"70070060"
            },
            "centroide":{
               "lat":-31.668402703149,
               "lon":-68.5222919302359
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"700077"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"70077"
            },
            "nombre":"EL MEDANITO",
            "id":"70077010001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Rawson",
               "id":"70077010"
            },
            "centroide":{
               "lat":-31.583482529045,
               "lon":-68.4697872532749
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"700077"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"70077"
            },
            "nombre":"RAWSON",
            "id":"70077010002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Rawson",
               "id":"70077010"
            },
            "centroide":{
               "lat":-31.5696190485463,
               "lon":-68.5577689018569
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"700077"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"70077"
            },
            "nombre":"VILLA KRAUSE",
            "id":"70077010003",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Rawson",
               "id":"70077010"
            },
            "centroide":{
               "lat":-31.583718555606,
               "lon":-68.5410817167198
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rawson",
               "id":"700077"
            },
            "departamento":{
               "nombre":"Rawson",
               "id":"70077"
            },
            "nombre":"VILLA BOLAÑOS",
            "id":"70077020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Bolaños (Médano de Oro)",
               "id":"70077020"
            },
            "centroide":{
               "lat":-31.6292970053945,
               "lon":-68.4706676954017
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rivadavia",
               "id":"700084"
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"70084"
            },
            "nombre":"RIVADAVIA",
            "id":"70084010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Rivadavia",
               "id":"70084010"
            },
            "centroide":{
               "lat":-31.5335200372447,
               "lon":-68.5920689785483
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"BARRIO SADOP",
            "id":"70091010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Barrio Sadop - Bella Vista",
               "id":"70091010"
            },
            "centroide":{
               "lat":-31.5367182711489,
               "lon":-68.3955676778174
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"DOS ACEQUIAS",
            "id":"70091020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Dos Acequias",
               "id":"70091020"
            },
            "centroide":{
               "lat":-31.4913855475125,
               "lon":-68.4185298472673
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"SAN ISIDRO",
            "id":"70091030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"San Isidro",
               "id":"70091030"
            },
            "centroide":{
               "lat":-31.4870993000717,
               "lon":-68.325134344897
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"VILLA EL SALVADOR",
            "id":"70091040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa del Salvador",
               "id":"70091040"
            },
            "centroide":{
               "lat":-31.4639044466924,
               "lon":-68.4095847372199
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"VILLA DOMINGUITO",
            "id":"70091050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Dominguito",
               "id":"70091050"
            },
            "centroide":{
               "lat":-31.55912095737,
               "lon":-68.2976446699079
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"VILLA DON BOSCO",
            "id":"70091060000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Don Bosco",
               "id":"70091060"
            },
            "centroide":{
               "lat":-31.5518882180598,
               "lon":-68.3390185136148
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"70091"
            },
            "nombre":"VILLA SAN MARTIN",
            "id":"70091070000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa San Martín",
               "id":"70091070"
            },
            "centroide":{
               "lat":-31.516761980837,
               "lon":-68.3526471246019
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martín",
               "id":"700091"
            },
            "departamento":{
               "nombre":"Santa Lucía",
               "id":"70098"
            },
            "nombre":"ALTO DE SIERRA",
            "id":"70098010001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Santa Lucía",
               "id":"70098010"
            },
            "centroide":{
               "lat":-31.5265278957606,
               "lon":-68.4106014181706
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Lucía",
               "id":"700098"
            },
            "departamento":{
               "nombre":"Santa Lucía",
               "id":"70098"
            },
            "nombre":"COLONIA GUTIERREZ",
            "id":"70098010002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Santa Lucía",
               "id":"70098010"
            },
            "centroide":{
               "lat":-31.5173968715659,
               "lon":-68.4546487927052
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Lucía",
               "id":"700098"
            },
            "departamento":{
               "nombre":"Santa Lucía",
               "id":"70098"
            },
            "nombre":"SANTA LUCIA",
            "id":"70098010003",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Santa Lucía",
               "id":"70098010"
            },
            "centroide":{
               "lat":-31.5130353688268,
               "lon":-68.4565988777122
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"CAÑADA HONDA",
            "id":"70105010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Cañada Honda",
               "id":"70105010"
            },
            "centroide":{
               "lat":-31.9850527438787,
               "lon":-68.5481911385325
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"CIENAGUITA",
            "id":"70105020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Cienaguita",
               "id":"70105020"
            },
            "centroide":{
               "lat":-32.0763381339971,
               "lon":-68.690762577729
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"COLONIA FISCAL",
            "id":"70105030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Colonia Fiscal",
               "id":"70105030"
            },
            "centroide":{
               "lat":-31.9027437264496,
               "lon":-68.4696588673219
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"DIVISADERO",
            "id":"70105040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Divisadero",
               "id":"70105040"
            },
            "centroide":{
               "lat":-32.0104984358581,
               "lon":-68.6904861018003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"GUANACACHE",
            "id":"70105050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Guanacache",
               "id":"70105050"
            },
            "centroide":{
               "lat":-32.0753813271385,
               "lon":-68.5856950572685
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"LAS LAGUNAS",
            "id":"70105060000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Las Lagunas",
               "id":"70105060"
            },
            "centroide":{
               "lat":-32.0443775419211,
               "lon":-68.3778990229646
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"LOS BERROS",
            "id":"70105070000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Los Berros",
               "id":"70105070"
            },
            "centroide":{
               "lat":-31.9514576383242,
               "lon":-68.6510941298035
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"PEDERNAL",
            "id":"70105080000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Pedernal",
               "id":"70105080"
            },
            "centroide":{
               "lat":-31.9950077029707,
               "lon":-68.7590874861116
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"PUNTA DEL MEDANO",
            "id":"70105090000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Punta del Médano",
               "id":"70105090"
            },
            "centroide":{
               "lat":-31.8946043291143,
               "lon":-68.4183104905607
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"700105"
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"70105"
            },
            "nombre":"VILLA MEDIA AGUA",
            "id":"70105100000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Media Agua",
               "id":"70105100"
            },
            "centroide":{
               "lat":-31.9810022624945,
               "lon":-68.4270029177237
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ullum",
               "id":"700112"
            },
            "departamento":{
               "nombre":"Ullum",
               "id":"70112"
            },
            "nombre":"VILLA IBAÑEZ",
            "id":"70112010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Ibáñez",
               "id":"70112010"
            },
            "centroide":{
               "lat":-31.4659385111811,
               "lon":-68.7353338028121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Fértil",
               "id":"700119"
            },
            "departamento":{
               "nombre":"Valle Fértil",
               "id":"70119"
            },
            "nombre":"ASTICA",
            "id":"70119010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Astica",
               "id":"70119010"
            },
            "centroide":{
               "lat":-30.9538850864023,
               "lon":-67.3006351406982
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Fértil",
               "id":"700119"
            },
            "departamento":{
               "nombre":"Valle Fértil",
               "id":"70119"
            },
            "nombre":"BALDE DEL ROSARIO",
            "id":"70119020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Balde del Rosario",
               "id":"70119020"
            },
            "centroide":{
               "lat":-30.3207151787316,
               "lon":-67.6951969177397
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Fértil",
               "id":"700119"
            },
            "departamento":{
               "nombre":"Valle Fértil",
               "id":"70119"
            },
            "nombre":"CHUCUMA",
            "id":"70119030000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Chucuma",
               "id":"70119030"
            },
            "centroide":{
               "lat":-31.0688861561951,
               "lon":-67.2788289085567
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Fértil",
               "id":"700119"
            },
            "departamento":{
               "nombre":"Valle Fértil",
               "id":"70119"
            },
            "nombre":"LOS BALDECITOS",
            "id":"70119040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Los Baldecitos",
               "id":"70119040"
            },
            "centroide":{
               "lat":-30.2241888429483,
               "lon":-67.7014210305429
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Fértil",
               "id":"700119"
            },
            "departamento":{
               "nombre":"Valle Fértil",
               "id":"70119"
            },
            "nombre":"USNO",
            "id":"70119050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Usno",
               "id":"70119050"
            },
            "centroide":{
               "lat":-30.5640053498102,
               "lon":-67.540327100629
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Valle Fértil",
               "id":"700119"
            },
            "departamento":{
               "nombre":"Valle Fértil",
               "id":"70119"
            },
            "nombre":"VILLA SAN AGUSTIN",
            "id":"70119060000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa San Agustín",
               "id":"70119060"
            },
            "centroide":{
               "lat":-30.6367084796207,
               "lon":-67.4650598603898
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"700126"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"70126"
            },
            "nombre":"EL ENCON",
            "id":"70126010000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"El Encón",
               "id":"70126010"
            },
            "centroide":{
               "lat":-32.2163733476113,
               "lon":-67.793057098676
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"700126"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"70126"
            },
            "nombre":"TUPELI",
            "id":"70126020000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Tupelí",
               "id":"70126020"
            },
            "centroide":{
               "lat":-31.8390315742989,
               "lon":-68.3573941216943
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"700126"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"70126"
            },
            "nombre":"LA CHIMBERA",
            "id":"70126030001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Borjas - La Chimbera",
               "id":"70126030"
            },
            "centroide":{
               "lat":-31.8505514541041,
               "lon":-68.309461754649
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"700126"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"70126"
            },
            "nombre":"VILLA BORJAS",
            "id":"70126030002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Borjas - La Chimbera",
               "id":"70126030"
            },
            "centroide":{
               "lat":-31.8100072529359,
               "lon":-68.3289623063813
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"700126"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"70126"
            },
            "nombre":"VILLA EL TANGO",
            "id":"70126040000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa El Tango",
               "id":"70126040"
            },
            "centroide":{
               "lat":-31.763268918716,
               "lon":-68.2222966354108
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"25 de Mayo",
               "id":"700126"
            },
            "departamento":{
               "nombre":"25 de Mayo",
               "id":"70126"
            },
            "nombre":"VILLA SANTA ROSA",
            "id":"70126050000",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Santa Rosa",
               "id":"70126050"
            },
            "centroide":{
               "lat":-31.7448085385712,
               "lon":-68.3142412332522
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zonda",
               "id":"700133"
            },
            "departamento":{
               "nombre":"Zonda",
               "id":"70133"
            },
            "nombre":"VILLA BASILIO NIEVAS",
            "id":"70133010001",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Basilio Nievas",
               "id":"70133010"
            },
            "centroide":{
               "lat":-31.543878931951,
               "lon":-68.7407758815646
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zonda",
               "id":"700133"
            },
            "departamento":{
               "nombre":"Zonda",
               "id":"70133"
            },
            "nombre":"VILLA TACU",
            "id":"70133010002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa Basilio Nievas",
               "id":"70133010"
            },
            "centroide":{
               "lat":-31.5293321449519,
               "lon":-68.7253410683189
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Candelaria",
               "id":"740007"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"74007"
            },
            "nombre":"CANDELARIA",
            "id":"74007010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Candelaria",
               "id":"74007010"
            },
            "centroide":{
               "lat":-32.0607491134468,
               "lon":-65.8276913716033
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"74007"
            },
            "nombre":"LEANDRO N. ALEM",
            "id":"74007030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Leandro N. Alem",
               "id":"74007030"
            },
            "centroide":{
               "lat":-32.1047821044922,
               "lon":-65.9621124267578
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lujan",
               "id":"740014"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"74007"
            },
            "nombre":"LUJAN",
            "id":"74007040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Luján",
               "id":"74007040"
            },
            "centroide":{
               "lat":-32.3660349058479,
               "lon":-65.9425439975458
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quines",
               "id":"740021"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"74007"
            },
            "nombre":"QUINES",
            "id":"74007050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Quines",
               "id":"74007050"
            },
            "centroide":{
               "lat":-32.2330822365033,
               "lon":-65.8056329969317
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Francisco",
               "id":"740028"
            },
            "departamento":{
               "nombre":"Ayacucho",
               "id":"74007"
            },
            "nombre":"SAN FRANCISCO DEL MONTE DE ORO",
            "id":"74007070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"San Francisco del Monte de Oro",
               "id":"74007070"
            },
            "centroide":{
               "lat":-32.6006392247112,
               "lon":-66.1273702635842
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Calera",
               "id":"746014"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"74014"
            },
            "nombre":"LA CALERA",
            "id":"74014010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Calera",
               "id":"74014010"
            },
            "centroide":{
               "lat":-32.8619918957825,
               "lon":-66.8506529764617
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nogoli",
               "id":"746021"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"74014"
            },
            "nombre":"NOGOLI",
            "id":"74014020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Nogolí",
               "id":"74014020"
            },
            "centroide":{
               "lat":-32.9173752831041,
               "lon":-66.3257602058349
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"V, de la Quebrada",
               "id":"746028"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"74014"
            },
            "nombre":"VILLA DE LA QUEBRADA",
            "id":"74014030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa de la Quebrada",
               "id":"74014030"
            },
            "centroide":{
               "lat":-33.0161741932684,
               "lon":-66.2920464806382
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"V, Gral, Roca",
               "id":"740035"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"74014"
            },
            "nombre":"VILLA GENERAL ROCA",
            "id":"74014040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa General Roca",
               "id":"74014040"
            },
            "centroide":{
               "lat":-32.6661925630759,
               "lon":-66.4524010178082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carolina",
               "id":"746035"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"CAROLINA",
            "id":"74021010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Carolina",
               "id":"74021010"
            },
            "centroide":{
               "lat":-32.8128571637146,
               "lon":-66.0932327577628
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Trapiche",
               "id":"746042"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"EL TRAPICHE",
            "id":"74021020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"El Trapiche",
               "id":"74021020"
            },
            "centroide":{
               "lat":-33.1082715280981,
               "lon":-66.063371211902
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estancia Grande",
               "id":"740038"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"ESTANCIA GRANDE",
            "id":"74021025000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Estancia Grande",
               "id":"74021025"
            },
            "centroide":{
               "lat":-33.1905465526598,
               "lon":-66.1527985566353
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fraga",
               "id":"746049"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"FRAGA",
            "id":"74021030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Fraga",
               "id":"74021030"
            },
            "centroide":{
               "lat":-33.502087069887,
               "lon":-65.7926477789763
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"LA BAJADA",
            "id":"74021040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Bajada",
               "id":"74021040"
            },
            "centroide":{
               "lat":-33.157161301266,
               "lon":-66.0131877714641
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Trapiche",
               "id":"746042"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"LA FLORIDA",
            "id":"74021050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Florida",
               "id":"74021050"
            },
            "centroide":{
               "lat":-33.1167587248711,
               "lon":-66.0020090018267
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Toma",
               "id":"740042"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"LA TOMA",
            "id":"74021060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Toma",
               "id":"74021060"
            },
            "centroide":{
               "lat":-33.0544376204479,
               "lon":-65.62269821015
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Trapiche",
               "id":"746042"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"RIOCITO",
            "id":"74021070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Riocito",
               "id":"74021070"
            },
            "centroide":{
               "lat":-33.0964304439761,
               "lon":-65.990838382791
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Trapiche",
               "id":"746042"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"RIO GRANDE",
            "id":"74021080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Río Grande",
               "id":"74021080"
            },
            "centroide":{
               "lat":-33.0485046022503,
               "lon":-66.0717543089664
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladillo",
               "id":"746063"
            },
            "departamento":{
               "nombre":"Coronel Pringles",
               "id":"74021"
            },
            "nombre":"SALADILLO",
            "id":"74021090000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Saladillo",
               "id":"74021090"
            },
            "centroide":{
               "lat":-33.2005664915694,
               "lon":-65.8531363375249
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Concaran",
               "id":"740049"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"CONCARAN",
            "id":"74028010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Concarán",
               "id":"74028010"
            },
            "centroide":{
               "lat":-32.5607275180287,
               "lon":-65.2452806076649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cortaderas",
               "id":"746070"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"CORTADERAS",
            "id":"74028020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Cortaderas",
               "id":"74028020"
            },
            "centroide":{
               "lat":-32.5076504245814,
               "lon":-64.9869655159434
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Naschel",
               "id":"740056"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"NASCHEL",
            "id":"74028030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Naschel",
               "id":"74028030"
            },
            "centroide":{
               "lat":-32.9168215937221,
               "lon":-65.3755476812695
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Papagayos",
               "id":"746077"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"PAPAGAYOS",
            "id":"74028040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Papagayos",
               "id":"74028040"
            },
            "centroide":{
               "lat":-32.6786405437002,
               "lon":-64.9881795223679
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Renca",
               "id":"746084"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"RENCA",
            "id":"74028050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Renca",
               "id":"74028050"
            },
            "centroide":{
               "lat":-32.7717680736652,
               "lon":-65.3637605007649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pablo",
               "id":"746091"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"SAN PABLO",
            "id":"74028060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"San Pablo",
               "id":"74028060"
            },
            "centroide":{
               "lat":-32.658342389353,
               "lon":-65.3080920411456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tilisarao",
               "id":"740063"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"TILISARAO",
            "id":"74028070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Tilisarao",
               "id":"74028070"
            },
            "centroide":{
               "lat":-32.7329281106581,
               "lon":-65.2915274383295
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa del Carmen",
               "id":"746098"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"VILLA DEL CARMEN",
            "id":"74028080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa del Carmen",
               "id":"74028080"
            },
            "centroide":{
               "lat":-32.9411267835778,
               "lon":-65.0394664526979
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Larca",
               "id":"746105"
            },
            "departamento":{
               "nombre":"Chacabuco",
               "id":"74028"
            },
            "nombre":"VILLA LARCA",
            "id":"74028090000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Larca",
               "id":"74028090"
            },
            "centroide":{
               "lat":-32.6175433008771,
               "lon":-64.9809087535547
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juan Jorba",
               "id":"749119"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"JUAN JORBA",
            "id":"74035010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juan Jorba",
               "id":"74035010"
            },
            "centroide":{
               "lat":-33.6132806299836,
               "lon":-65.2717502963014
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juan Llerena",
               "id":"746126"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"JUAN LLERENA",
            "id":"74035020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juan Llerena",
               "id":"74035020"
            },
            "centroide":{
               "lat":-33.2811132563767,
               "lon":-65.6145766804084
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Justo Daract",
               "id":"740070"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"JUSTO DARACT",
            "id":"74035030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Justo Daract",
               "id":"74035030"
            },
            "centroide":{
               "lat":-33.8585834476817,
               "lon":-65.1870408754728
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Punilla",
               "id":"746133"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"LA PUNILLA",
            "id":"74035040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Punilla",
               "id":"74035040"
            },
            "centroide":{
               "lat":-33.1429537099622,
               "lon":-65.0861306811866
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lavaisse",
               "id":"746140"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"LAVAISSE",
            "id":"74035050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Lavaisse",
               "id":"74035050"
            },
            "centroide":{
               "lat":-33.8221476840744,
               "lon":-65.4237934358157
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ranqueles",
               "id":"740073"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"NACION RANQUEL",
            "id":"74035055000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Nación Ranquel",
               "id":"74035055"
            },
            "centroide":{
               "lat":-34.6086190002894,
               "lon":-65.7348529983919
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jose del Morro",
               "id":"746112"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"SAN JOSE DEL MORRO",
            "id":"74035060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"San José del Morro",
               "id":"74035060"
            },
            "centroide":{
               "lat":-33.2119853031031,
               "lon":-65.4927334484497
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Mercedes",
               "id":"740077"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"VILLA MERCEDES",
            "id":"74035070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Mercedes",
               "id":"74035070"
            },
            "centroide":{
               "lat":-33.6738636408858,
               "lon":-65.4624765290195
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Mercedes",
               "id":"740077"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"LA RIBERA",
            "id":"74035070001",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Mercedes",
               "id":"74035070"
            },
            "centroide":{
               "lat":-33.6918122338202,
               "lon":-65.5007310600543
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Mercedes",
               "id":"740077"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"VILLA MERCEDES",
            "id":"74035070002",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Mercedes",
               "id":"74035070"
            },
            "centroide":{
               "lat":-33.6773697773889,
               "lon":-65.4731433656901
            }
         },
         {
            "categoria":"Localidad simple con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"VILLA REYNOLDS",
            "id":"74035080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Reynolds",
               "id":"74035080"
            },
            "centroide":{
               "lat":-33.722961850163,
               "lon":-65.3814163660133
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"COUNTRY CLUB LOS CALDENES",
            "id":"74035080001",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Reynolds",
               "id":"74035080"
            },
            "centroide":{
               "lat":-33.7036125779234,
               "lon":-65.3290340917624
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"5TA BRIGADA",
            "id":"74035080002",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Reynolds",
               "id":"74035080"
            },
            "centroide":{
               "lat":-33.7239942428561,
               "lon":-65.3810379035533
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Justo Daract",
               "id":"740070"
            },
            "departamento":{
               "nombre":"General Pedernera",
               "id":"74035"
            },
            "nombre":"VILLA SALLES",
            "id":"74035090000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa Salles",
               "id":"74035090"
            },
            "centroide":{
               "lat":-33.84292794921,
               "lon":-65.2147610955176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Anchorena",
               "id":"746147"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"ANCHORENA",
            "id":"74042010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Anchorena",
               "id":"74042010"
            },
            "centroide":{
               "lat":-35.6746450244219,
               "lon":-65.4244230509371
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arizona",
               "id":"746154"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"ARIZONA",
            "id":"74042020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Arizona",
               "id":"74042020"
            },
            "centroide":{
               "lat":-35.7229145031177,
               "lon":-65.3187210360081
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bagual",
               "id":"746161"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"BAGUAL",
            "id":"74042030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Bagual",
               "id":"74042030"
            },
            "centroide":{
               "lat":-35.1448484513353,
               "lon":-65.5676652450981
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Batavia",
               "id":"746168"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"BATAVIA",
            "id":"74042040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Batavia",
               "id":"74042040"
            },
            "centroide":{
               "lat":-34.7755332557227,
               "lon":-65.6862987148176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Buena Esperanza",
               "id":"740084"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"BUENA ESPERANZA",
            "id":"74042050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Buena Esperanza",
               "id":"74042050"
            },
            "centroide":{
               "lat":-34.7582787968944,
               "lon":-65.2503691694475
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fortin el Patria",
               "id":"749175"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"FORTIN EL PATRIA",
            "id":"74042060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Fortín El Patria",
               "id":"74042060"
            },
            "centroide":{
               "lat":-34.769756515675,
               "lon":-65.5229345175781
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fortuna",
               "id":"749182"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"FORTUNA",
            "id":"74042070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Fortuna",
               "id":"74042070"
            },
            "centroide":{
               "lat":-35.1282918635603,
               "lon":-65.3818218917708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"LA MAROMA",
            "id":"74042080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Maroma",
               "id":"74042080"
            },
            "centroide":{
               "lat":-35.2127003045126,
               "lon":-66.3264252245578
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"LOS OVEROS",
            "id":"74042090000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Los Overos",
               "id":"74042090"
            },
            "centroide":{
               "lat":-35.8808971047928,
               "lon":-66.4450142548098
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"MARTIN DE LOYOLA",
            "id":"74042100000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Martín de Loyola",
               "id":"74042100"
            },
            "centroide":{
               "lat":-35.7111681131223,
               "lon":-66.3525275844943
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"NAHUEL MAPA",
            "id":"74042110000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Nahuel Mapá",
               "id":"74042110"
            },
            "centroide":{
               "lat":-34.7833678660504,
               "lon":-66.1701498297947
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Navia",
               "id":"746189"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"NAVIA",
            "id":"74042120000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Navia",
               "id":"74042120"
            },
            "centroide":{
               "lat":-34.7726733665409,
               "lon":-66.5862788668642
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nueva Galia",
               "id":"746196"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"NUEVA GALIA",
            "id":"74042130000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Nueva Galia",
               "id":"74042130"
            },
            "centroide":{
               "lat":-35.1124747000615,
               "lon":-65.2532109154966
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Union",
               "id":"740091"
            },
            "departamento":{
               "nombre":"Gobernador Dupuy",
               "id":"74042"
            },
            "nombre":"UNION",
            "id":"74042140000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Unión",
               "id":"74042140"
            },
            "centroide":{
               "lat":-35.1549261061197,
               "lon":-65.9424468511458
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carpinteria",
               "id":"746203"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"CARPINTERIA",
            "id":"74049010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Carpintería",
               "id":"74049010"
            },
            "centroide":{
               "lat":-32.4102495120789,
               "lon":-65.0113926769819
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"740105"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"CERRO DE ORO",
            "id":"74049020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Cerro de Oro",
               "id":"74049020"
            },
            "centroide":{
               "lat":-32.3851963369856,
               "lon":-64.9859436108737
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lafinur",
               "id":"746210"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"LAFINUR",
            "id":"74049030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Lafinur",
               "id":"74049030"
            },
            "centroide":{
               "lat":-32.0621151953791,
               "lon":-65.3496815467824
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"LOS CAJONES",
            "id":"74049040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Los Cajones",
               "id":"74049040"
            },
            "centroide":{
               "lat":-32.0257513048361,
               "lon":-65.3749361476641
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Molles",
               "id":"746217"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"LOS MOLLES",
            "id":"74049050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Los Molles",
               "id":"74049050"
            },
            "centroide":{
               "lat":-32.4395447509138,
               "lon":-65.0106822684226
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Merlo",
               "id":"740105"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"MERLO",
            "id":"74049060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Merlo",
               "id":"74049060"
            },
            "centroide":{
               "lat":-32.3425391142336,
               "lon":-65.0141372032908
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sta Rosa del Conlara",
               "id":"740098"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"SANTA ROSA DEL CONLARA",
            "id":"74049070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa del Conlara",
               "id":"74049070"
            },
            "centroide":{
               "lat":-32.3423081509941,
               "lon":-65.2071206614116
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Talita",
               "id":"746224"
            },
            "departamento":{
               "nombre":"Junín",
               "id":"74049"
            },
            "nombre":"TALITA",
            "id":"74049080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Talita",
               "id":"74049080"
            },
            "centroide":{
               "lat":-32.2481203088315,
               "lon":-65.5838734087874
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"ALTO PELADO",
            "id":"74056010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Alto Pelado",
               "id":"74056010"
            },
            "centroide":{
               "lat":-33.8425029072214,
               "lon":-66.1375993056552
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alto Pencoso",
               "id":"746238"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"ALTO PENCOSO",
            "id":"74056020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Alto Pencoso",
               "id":"74056020"
            },
            "centroide":{
               "lat":-33.4306363842929,
               "lon":-66.9279669116981
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balde",
               "id":"746245"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"BALDE",
            "id":"74056030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Balde",
               "id":"74056030"
            },
            "centroide":{
               "lat":-33.3432695635018,
               "lon":-66.626047423873
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Beazley",
               "id":"746252"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"BEAZLEY",
            "id":"74056040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Beazley",
               "id":"74056040"
            },
            "centroide":{
               "lat":-33.757734110106,
               "lon":-66.6459600305752
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zanjitas",
               "id":"746280"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"CAZADOR",
            "id":"74056050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Cazador",
               "id":"74056050"
            },
            "centroide":{
               "lat":-33.8576584773376,
               "lon":-66.3696150667545
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"CHOSMES",
            "id":"74056060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Chosmes",
               "id":"74056060"
            },
            "centroide":{
               "lat":-33.3959968949714,
               "lon":-66.7463973832244
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Desaguadero",
               "id":"740108"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"DESAGUADERO",
            "id":"74056070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Desaguadero",
               "id":"74056070"
            },
            "centroide":{
               "lat":-33.4004585255775,
               "lon":-67.148082207766
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Volcan",
               "id":"746259"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"EL VOLCAN",
            "id":"74056080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"El Volcán",
               "id":"74056080"
            },
            "centroide":{
               "lat":-33.2512966067626,
               "lon":-66.1877022971966
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Desaguadero",
               "id":"740108"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"JARILLA",
            "id":"74056090000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Jarilla",
               "id":"74056090"
            },
            "centroide":{
               "lat":-33.398658341972,
               "lon":-67.0274295478727
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juana Koslay",
               "id":"740119"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"CERRO COLORADO",
            "id":"74056100001",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juana Koslay",
               "id":"74056100"
            },
            "centroide":{
               "lat":-33.2550955344668,
               "lon":-66.2286548309473
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juana Koslay",
               "id":"740119"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"CRUZ DE PIEDRA",
            "id":"74056100002",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juana Koslay",
               "id":"74056100"
            },
            "centroide":{
               "lat":-33.266266753132,
               "lon":-66.2171495179704
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juana Koslay",
               "id":"740119"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"EL CHORRILLO",
            "id":"74056100003",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juana Koslay",
               "id":"74056100"
            },
            "centroide":{
               "lat":-33.2889484546354,
               "lon":-66.2580406259221
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juana Koslay",
               "id":"740119"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"LAS CHACRAS",
            "id":"74056100004",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juana Koslay",
               "id":"74056100"
            },
            "centroide":{
               "lat":-33.2608224121538,
               "lon":-66.2381061398228
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juana Koslay",
               "id":"740119"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"SAN ROQUE",
            "id":"74056100005",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Juana Koslay",
               "id":"74056100"
            },
            "centroide":{
               "lat":-33.2720248979515,
               "lon":-66.2276619762377
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Punta",
               "id":"749007"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"CIUDAD DE LA PUNTA",
            "id":"74056105000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Punta",
               "id":"74056105"
            },
            "centroide":{
               "lat":-33.1816571259341,
               "lon":-66.313607690249
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"MOSMOTA",
            "id":"74056110000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Mosmota",
               "id":"74056110"
            },
            "centroide":{
               "lat":-33.6458915525894,
               "lon":-66.9934495323611
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"P. de los Funes",
               "id":"746266"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"POTRERO DE LOS FUNES",
            "id":"74056120000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Potrero de los Funes",
               "id":"74056120"
            },
            "centroide":{
               "lat":-33.2187787091432,
               "lon":-66.2307288183005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Balde",
               "id":"746245"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"SALINAS DEL BEBEDERO",
            "id":"74056130000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Salinas del Bebedero",
               "id":"74056130"
            },
            "centroide":{
               "lat":-33.501125039306,
               "lon":-66.6514406177997
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jeronimo",
               "id":"746273"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"SAN JERONIMO",
            "id":"74056140000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"San Jerónimo",
               "id":"74056140"
            },
            "centroide":{
               "lat":-33.1383053611749,
               "lon":-66.5166859326585
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Luis",
               "id":"740133"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"SAN LUIS",
            "id":"74056150000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"San Luis",
               "id":"74056150"
            },
            "centroide":{
               "lat":-33.3023139659883,
               "lon":-66.3360877357358
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zanjitas",
               "id":"746280"
            },
            "departamento":{
               "nombre":"Juan Martín de Pueyrredón",
               "id":"74056"
            },
            "nombre":"ZANJITAS",
            "id":"74056160000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Zanjitas",
               "id":"74056160"
            },
            "centroide":{
               "lat":-33.8022746844103,
               "lon":-66.415462549085
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Vertientes",
               "id":"746287"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"LA VERTIENTE",
            "id":"74063010000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"La Vertiente",
               "id":"74063010"
            },
            "centroide":{
               "lat":-32.7975027746268,
               "lon":-65.7568974316301
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Aguadas",
               "id":"746294"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"LAS AGUADAS",
            "id":"74063020000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Las Aguadas",
               "id":"74063020"
            },
            "centroide":{
               "lat":-32.3763580460129,
               "lon":-65.5012041382004
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Chacras",
               "id":"746301"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"LAS CHACRAS",
            "id":"74063030000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Las Chacras",
               "id":"74063030"
            },
            "centroide":{
               "lat":-32.5435132286539,
               "lon":-65.7446386901218
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Lagunas",
               "id":"746308"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"LAS LAGUNAS",
            "id":"74063040000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Las Lagunas",
               "id":"74063040"
            },
            "centroide":{
               "lat":-32.6298120296294,
               "lon":-65.551144128608
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Paso Grande",
               "id":"746315"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"PASO GRANDE",
            "id":"74063050000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Paso Grande",
               "id":"74063050"
            },
            "centroide":{
               "lat":-32.8769737284291,
               "lon":-65.6345301522078
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"POTRERILLO",
            "id":"74063060000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Potrerillo",
               "id":"74063060"
            },
            "centroide":{
               "lat":-32.6714012444881,
               "lon":-65.6626272217676
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Martin",
               "id":"740140"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"SAN MARTIN",
            "id":"74063070000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"San Martín",
               "id":"74063070"
            },
            "centroide":{
               "lat":-32.4132690463753,
               "lon":-65.6759306690121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"V, de Praga",
               "id":"746322"
            },
            "departamento":{
               "nombre":"Libertador General San Martín",
               "id":"74063"
            },
            "nombre":"VILLA DE PRAGA",
            "id":"74063080000",
            "provincia":{
               "nombre":"San Luis",
               "id":"74"
            },
            "localidad_censal":{
               "nombre":"Villa de Praga",
               "id":"74063080"
            },
            "centroide":{
               "lat":-32.5339640778182,
               "lon":-65.6477042790939
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Corpen Aike",
               "id":"78007"
            },
            "nombre":"COMANDANTE LUIS PIEDRABUENA",
            "id":"78007010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Comandante Luis Piedrabuena",
               "id":"78007010"
            },
            "centroide":{
               "lat":-49.9859909808201,
               "lon":-68.9130816915927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Corpen Aike",
               "id":"78007"
            },
            "nombre":"PUERTO SANTA CRUZ",
            "id":"78007020000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Puerto Santa Cruz",
               "id":"78007020"
            },
            "centroide":{
               "lat":-50.0171892721878,
               "lon":-68.5248246324655
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"CALETA OLIVIA",
            "id":"78014010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Caleta Olivia",
               "id":"78014010"
            },
            "centroide":{
               "lat":-46.4459492303195,
               "lon":-67.5251564969847
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"CAÑADON SECO",
            "id":"78014020000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Cañadón Seco",
               "id":"78014020"
            },
            "centroide":{
               "lat":-46.5588457703439,
               "lon":-67.616856368573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"FITZ ROY",
            "id":"78014030000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Fitz Roy",
               "id":"78014030"
            },
            "centroide":{
               "lat":-47.0257940308205,
               "lon":-67.2542856564871
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"JARAMILLO",
            "id":"78014040000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Jaramillo",
               "id":"78014040"
            },
            "centroide":{
               "lat":-47.1847032165887,
               "lon":-67.145582198474
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"KOLUEL KAIKE",
            "id":"78014050000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Koluel Kaike",
               "id":"78014050"
            },
            "centroide":{
               "lat":-46.7168267498228,
               "lon":-68.2279614588974
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"LAS HERAS",
            "id":"78014060000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Las Heras",
               "id":"78014060"
            },
            "centroide":{
               "lat":-46.5424553787867,
               "lon":-68.9341773229667
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"PICO TRUNCADO",
            "id":"78014070000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Pico Truncado",
               "id":"78014070"
            },
            "centroide":{
               "lat":-46.7938981244061,
               "lon":-67.9575704898943
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"PUERTO DESEADO",
            "id":"78014080000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Puerto Deseado",
               "id":"78014080"
            },
            "centroide":{
               "lat":-47.7514649275067,
               "lon":-65.9012043680086
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Deseado",
               "id":"78014"
            },
            "nombre":"TELLIER",
            "id":"78014090000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Tellier",
               "id":"78014090"
            },
            "centroide":{
               "lat":-47.6487686074581,
               "lon":-66.0446356831872
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"EL TURBIO",
            "id":"78021010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"El Turbio",
               "id":"78021010"
            },
            "centroide":{
               "lat":-51.6805338519685,
               "lon":-72.0874860687819
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"JULIA DUFOUR",
            "id":"78021020000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Julia Dufour",
               "id":"78021020"
            },
            "centroide":{
               "lat":-51.5406111767208,
               "lon":-72.2398967258576
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"MINA 3",
            "id":"78021030000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Mina 3",
               "id":"78021030"
            },
            "centroide":{
               "lat":-51.5487660455569,
               "lon":-72.2333909775383
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"RIO GALLEGOS",
            "id":"78021040000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Río Gallegos",
               "id":"78021040"
            },
            "centroide":{
               "lat":-51.6214349839165,
               "lon":-69.2290509293744
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"ROSPENTEK",
            "id":"78021050000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Rospentek",
               "id":"78021050"
            },
            "centroide":{
               "lat":-51.6639328934924,
               "lon":-72.1426988306974
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"28 DE NOVIEMBRE",
            "id":"78021060000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"28 de Noviembre",
               "id":"78021060"
            },
            "centroide":{
               "lat":-51.5787581525544,
               "lon":-72.2080410883792
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Güer Aike",
               "id":"78021"
            },
            "nombre":"YACIMIENTOS RIO TURBIO",
            "id":"78021070000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Yacimientos Río Turbio",
               "id":"78021070"
            },
            "centroide":{
               "lat":-51.5328383738253,
               "lon":-72.3341032077956
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Lago Argentino",
               "id":"78028"
            },
            "nombre":"EL CALAFATE",
            "id":"78028010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"El Calafate",
               "id":"78028010"
            },
            "centroide":{
               "lat":-50.3373208485428,
               "lon":-72.2619950698979
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Lago Argentino",
               "id":"78028"
            },
            "nombre":"EL CHALTEN",
            "id":"78028020000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"El Chaltén",
               "id":"78028020"
            },
            "centroide":{
               "lat":-49.3319731177032,
               "lon":-72.8916267088672
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Lago Argentino",
               "id":"78028"
            },
            "nombre":"TRES LAGOS",
            "id":"78028030000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Tres Lagos",
               "id":"78028030"
            },
            "centroide":{
               "lat":-49.5990148275349,
               "lon":-71.445802241684
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Lago Buenos Aires",
               "id":"78035"
            },
            "nombre":"LOS ANTIGUOS",
            "id":"78035010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Los Antiguos",
               "id":"78035010"
            },
            "centroide":{
               "lat":-46.5487484894558,
               "lon":-71.6274835082657
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Lago Buenos Aires",
               "id":"78035"
            },
            "nombre":"PERITO MORENO",
            "id":"78035020000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Perito Moreno",
               "id":"78035020"
            },
            "centroide":{
               "lat":-46.5921416878797,
               "lon":-70.9257278426639
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Magallanes",
               "id":"78042"
            },
            "nombre":"PUERTO SAN JULIAN",
            "id":"78042010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Puerto San Julián",
               "id":"78042010"
            },
            "centroide":{
               "lat":-49.307703595363,
               "lon":-67.7319702926075
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"78049"
            },
            "nombre":"BAJO CARACOLES",
            "id":"78049010000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Bajo Caracoles",
               "id":"78049010"
            },
            "centroide":{
               "lat":-47.4461490043983,
               "lon":-70.9285155605965
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"78049"
            },
            "nombre":"GOBERNADOR GREGORES",
            "id":"78049020000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Gobernador Gregores",
               "id":"78049020"
            },
            "centroide":{
               "lat":-48.7521223249696,
               "lon":-70.2442035901869
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"78049"
            },
            "nombre":"HIPOLITO YRIGOYEN",
            "id":"78049030000",
            "provincia":{
               "nombre":"Santa Cruz",
               "id":"78"
            },
            "localidad_censal":{
               "nombre":"Hipólito Yrigoyen",
               "id":"78049030"
            },
            "centroide":{
               "lat":-47.5667812360399,
               "lon":-71.7434756314323
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Armstrong",
               "id":"820007"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"82007"
            },
            "nombre":"ARMSTRONG",
            "id":"82007010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Armstrong",
               "id":"82007010"
            },
            "centroide":{
               "lat":-32.7846557002825,
               "lon":-61.605481877095
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bouquet",
               "id":"822007"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"82007"
            },
            "nombre":"BOUQUET",
            "id":"82007020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bouquet",
               "id":"82007020"
            },
            "centroide":{
               "lat":-32.4247998561473,
               "lon":-61.8903345863257
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Parejas",
               "id":"820014"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"82007"
            },
            "nombre":"LAS PAREJAS",
            "id":"82007030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Parejas",
               "id":"82007030"
            },
            "centroide":{
               "lat":-32.6826842550048,
               "lon":-61.5185798347876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Rosas",
               "id":"820021"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"82007"
            },
            "nombre":"LAS ROSAS",
            "id":"82007040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Rosas",
               "id":"82007040"
            },
            "centroide":{
               "lat":-32.4785768176046,
               "lon":-61.5748036822531
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montes de Oca",
               "id":"822014"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"82007"
            },
            "nombre":"MONTES DE OCA",
            "id":"82007050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Montes de Oca",
               "id":"82007050"
            },
            "centroide":{
               "lat":-32.567997020224,
               "lon":-61.7680762352029
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tortugas",
               "id":"822021"
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"82007"
            },
            "nombre":"TORTUGAS",
            "id":"82007060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Tortugas",
               "id":"82007060"
            },
            "centroide":{
               "lat":-32.7482982621557,
               "lon":-61.8203359267394
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arequito",
               "id":"822028"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"AREQUITO",
            "id":"82014010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arequito",
               "id":"82014010"
            },
            "centroide":{
               "lat":-33.1483008378636,
               "lon":-61.4713349349693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arteaga",
               "id":"822035"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"ARTEAGA",
            "id":"82014020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arteaga",
               "id":"82014020"
            },
            "centroide":{
               "lat":-33.0911311154911,
               "lon":-61.7917113876172
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berabevú",
               "id":"822042"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"BERAVEBU",
            "id":"82014030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Beravebú",
               "id":"82014030"
            },
            "centroide":{
               "lat":-33.3414152210784,
               "lon":-61.8622809808056
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bigand",
               "id":"822049"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"BIGAND",
            "id":"82014040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bigand",
               "id":"82014040"
            },
            "centroide":{
               "lat":-33.3761183026397,
               "lon":-61.185501310201
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Casilda",
               "id":"820028"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"CASILDA",
            "id":"82014050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Casilda",
               "id":"82014050"
            },
            "centroide":{
               "lat":-33.0424955546762,
               "lon":-61.1693311184421
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chabas",
               "id":"822056"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"CHABAS",
            "id":"82014060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Chabas",
               "id":"82014060"
            },
            "centroide":{
               "lat":-33.2470674643201,
               "lon":-61.3575935561826
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chañar Ladeado",
               "id":"822063"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"CHAÑAR LADEADO",
            "id":"82014070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Chañar Ladeado",
               "id":"82014070"
            },
            "centroide":{
               "lat":-33.3258409849243,
               "lon":-62.0386496702618
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gödeken",
               "id":"822070"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"GODEKEN",
            "id":"82014080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gödeken",
               "id":"82014080"
            },
            "centroide":{
               "lat":-33.402405547162,
               "lon":-61.8449306727075
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Molinos",
               "id":"822077"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"LOS MOLINOS",
            "id":"82014090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Molinos",
               "id":"82014090"
            },
            "centroide":{
               "lat":-33.1054899954916,
               "lon":-61.3265015329286
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José de la Esquina",
               "id":"822091"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"LOS NOGALES",
            "id":"82014100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Nogales",
               "id":"82014100"
            },
            "centroide":{
               "lat":-33.1430061971604,
               "lon":-61.6066128669784
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Quirquinchos",
               "id":"822084"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"LOS QUIRQUINCHOS",
            "id":"82014110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Quirquinchos",
               "id":"82014110"
            },
            "centroide":{
               "lat":-33.3769918276119,
               "lon":-61.7121943064881
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José de la Esquina",
               "id":"822091"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"SAN JOSE DE LA ESQUINA",
            "id":"82014120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San José de la Esquina",
               "id":"82014120"
            },
            "centroide":{
               "lat":-33.1140485294151,
               "lon":-61.703312346428
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sanford",
               "id":"822098"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"SANFORD",
            "id":"82014130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sanford",
               "id":"82014130"
            },
            "centroide":{
               "lat":-33.1477729818051,
               "lon":-61.2778572887319
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villada",
               "id":"822105"
            },
            "departamento":{
               "nombre":"Caseros",
               "id":"82014"
            },
            "nombre":"VILLADA",
            "id":"82014140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villada",
               "id":"82014140"
            },
            "centroide":{
               "lat":-33.3503085722913,
               "lon":-61.4460374000461
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Aldao",
               "id":"822147"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ALDAO",
            "id":"82021010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Aldao",
               "id":"82021010"
            },
            "centroide":{
               "lat":-30.9823474545911,
               "lon":-61.7439869348366
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Angélica",
               "id":"822112"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ANGELICA",
            "id":"82021020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Angélica",
               "id":"82021020"
            },
            "centroide":{
               "lat":-31.5526328296108,
               "lon":-61.5462650173096
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ataliva",
               "id":"822119"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ATALIVA",
            "id":"82021030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ataliva",
               "id":"82021030"
            },
            "centroide":{
               "lat":-30.9981187823937,
               "lon":-61.4325186889204
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aurelia",
               "id":"822126"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"AURELIA",
            "id":"82021040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Aurelia",
               "id":"82021040"
            },
            "centroide":{
               "lat":-31.4236023757354,
               "lon":-61.4244708809147
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Josefina",
               "id":"822280"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"BARRIOS ACAPULCO Y VERACRUZ",
            "id":"82021050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrios Acapulco y Veracruz",
               "id":"82021050"
            },
            "centroide":{
               "lat":-31.4192515021464,
               "lon":-62.059146376237
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bauer y Sigel",
               "id":"822133"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"BAUER Y SIGEL",
            "id":"82021060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bauer y Sigel",
               "id":"82021060"
            },
            "centroide":{
               "lat":-31.2731232182116,
               "lon":-61.9448854961658
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bella Italia",
               "id":"822140"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"BELLA ITALIA",
            "id":"82021070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bella Italia",
               "id":"82021070"
            },
            "centroide":{
               "lat":-31.2839795053037,
               "lon":-61.409342518123
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castellanos",
               "id":"822168"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"CASTELLANOS",
            "id":"82021080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Castellanos",
               "id":"82021080"
            },
            "centroide":{
               "lat":-31.2085396563705,
               "lon":-61.7255955564221
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Bicha",
               "id":"822154"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"COLONIA BICHA",
            "id":"82021090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Bicha",
               "id":"82021090"
            },
            "centroide":{
               "lat":-30.855781133807,
               "lon":-61.8509147420168
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Cello",
               "id":"822175"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"COLONIA CELLO",
            "id":"82021100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Cello",
               "id":"82021100"
            },
            "centroide":{
               "lat":-31.4338497252165,
               "lon":-61.8417021711297
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Margarita",
               "id":"822189"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"COLONIA MARGARITA",
            "id":"82021110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Margarita",
               "id":"82021110"
            },
            "centroide":{
               "lat":-31.6868334221829,
               "lon":-61.6492968757313
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Raquel",
               "id":"822196"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"COLONIA RAQUEL",
            "id":"82021120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Raquel",
               "id":"82021120"
            },
            "centroide":{
               "lat":-30.8392235567556,
               "lon":-61.4897111638226
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Fraga",
               "id":"822203"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"CORONEL FRAGA",
            "id":"82021130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Coronel Fraga",
               "id":"82021130"
            },
            "centroide":{
               "lat":-31.176158616875,
               "lon":-61.9194620018005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Egusquiza",
               "id":"822210"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"EGUSQUIZA",
            "id":"82021140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Egusquiza",
               "id":"82021140"
            },
            "centroide":{
               "lat":-31.097624747292,
               "lon":-61.6283124096055
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esmeralda",
               "id":"822217"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ESMERALDA",
            "id":"82021150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Esmeralda",
               "id":"82021150"
            },
            "centroide":{
               "lat":-31.6178586482318,
               "lon":-61.9329258733186
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estación Clucellas",
               "id":"822224"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ESTACION CLUCELLAS",
            "id":"82021160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Estación Clucellas",
               "id":"82021160"
            },
            "centroide":{
               "lat":-31.52416914925,
               "lon":-61.7206457133884
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saguier",
               "id":"822336"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ESTACION SAGUIER",
            "id":"82021170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Estación Saguier",
               "id":"82021170"
            },
            "centroide":{
               "lat":-31.3174603823906,
               "lon":-61.6940034650798
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eusebia y Carolina",
               "id":"822231"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"EUSEBIA Y CAROLINA",
            "id":"82021180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Eusebia y Carolina",
               "id":"82021180"
            },
            "centroide":{
               "lat":-30.9476135702694,
               "lon":-61.8577735272139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Eustolia",
               "id":"822238"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"EUSTOLIA",
            "id":"82021190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Eustolia",
               "id":"82021190"
            },
            "centroide":{
               "lat":-31.5777448514157,
               "lon":-61.7836661825715
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Frontera",
               "id":"820035"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"FRONTERA",
            "id":"82021200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Frontera",
               "id":"82021200"
            },
            "centroide":{
               "lat":-31.4313990849769,
               "lon":-62.0634917387843
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garibaldi",
               "id":"822259"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"GARIBALDI",
            "id":"82021210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Garibaldi",
               "id":"82021210"
            },
            "centroide":{
               "lat":-31.652027726261,
               "lon":-61.8053907511433
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humberto Primo",
               "id":"822273"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"HUMBERTO PRIMO",
            "id":"82021220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Humberto Primo",
               "id":"82021220"
            },
            "centroide":{
               "lat":-30.8702088524931,
               "lon":-61.3485902390711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Josefina",
               "id":"822280"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"JOSEFINA",
            "id":"82021230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Josefina",
               "id":"82021230"
            },
            "centroide":{
               "lat":-31.4079691369305,
               "lon":-61.9921847239399
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lehmann",
               "id":"822287"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"LEHMANN",
            "id":"82021240000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Lehmann",
               "id":"82021240"
            },
            "centroide":{
               "lat":-31.1272471029986,
               "lon":-61.4529569120506
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"María Juana",
               "id":"822294"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"MARIA JUANA",
            "id":"82021250000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"María Juana",
               "id":"82021250"
            },
            "centroide":{
               "lat":-31.676627573626,
               "lon":-61.7536009774385
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lehmann",
               "id":"822287"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"NUEVA LEHMANN",
            "id":"82021260000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Nueva Lehmann",
               "id":"82021260"
            },
            "centroide":{
               "lat":-31.1189046819859,
               "lon":-61.5151982929847
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Plaza Clucellas",
               "id":"822308"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"PLAZA CLUCELLAS",
            "id":"82021270000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Plaza Clucellas",
               "id":"82021270"
            },
            "centroide":{
               "lat":-31.4545505198837,
               "lon":-61.7074756228147
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saguier",
               "id":"822336"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"PLAZA SAGUIER",
            "id":"82021280000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Plaza Saguier",
               "id":"82021280"
            },
            "centroide":{
               "lat":-31.3251049515902,
               "lon":-61.6777373324018
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidente Roca",
               "id":"822315"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ESTACION PRESIDENTE ROCA",
            "id":"82021290001",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Presidente Roca",
               "id":"82021290"
            },
            "centroide":{
               "lat":-31.2319180453244,
               "lon":-61.6101076012158
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Presidente Roca",
               "id":"822315"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"PRESIDENTE ROCA",
            "id":"82021290002",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Presidente Roca",
               "id":"82021290"
            },
            "centroide":{
               "lat":-31.2296979058147,
               "lon":-61.6101036240639
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Marini",
               "id":"822322"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"PUEBLO MARINI",
            "id":"82021300000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pueblo Marini",
               "id":"82021300"
            },
            "centroide":{
               "lat":-31.0409745475723,
               "lon":-61.8898826370338
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rafaela",
               "id":"820042"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"RAFAELA",
            "id":"82021310000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Rafaela",
               "id":"82021310"
            },
            "centroide":{
               "lat":-31.2482482413205,
               "lon":-61.4998117939868
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramona",
               "id":"822329"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"RAMONA",
            "id":"82021320000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ramona",
               "id":"82021320"
            },
            "centroide":{
               "lat":-31.0937041766842,
               "lon":-61.9032318673573
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"822343"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"SAN ANTONIO",
            "id":"82021330000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Antonio",
               "id":"82021330"
            },
            "centroide":{
               "lat":-31.2128134181244,
               "lon":-61.7257200007794
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Vicente",
               "id":"822350"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"SAN VICENTE",
            "id":"82021340000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Vicente",
               "id":"82021340"
            },
            "centroide":{
               "lat":-31.6999505604131,
               "lon":-61.5688417180763
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Clara de Saguier",
               "id":"822357"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"SANTA CLARA DE SAGUIER",
            "id":"82021350000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Clara de Saguier",
               "id":"82021350"
            },
            "centroide":{
               "lat":-31.337359049521,
               "lon":-61.8181783780784
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sunchales",
               "id":"820049"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"SUNCHALES",
            "id":"82021360000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sunchales",
               "id":"82021360"
            },
            "centroide":{
               "lat":-30.9468555127161,
               "lon":-61.5612504315515
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Susana",
               "id":"822364"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"SUSANA",
            "id":"82021370000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Susana",
               "id":"82021370"
            },
            "centroide":{
               "lat":-31.3575963913649,
               "lon":-61.5164389963223
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tacural",
               "id":"822371"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"TACURAL",
            "id":"82021380000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Tacural",
               "id":"82021380"
            },
            "centroide":{
               "lat":-30.8481071493852,
               "lon":-61.5924018154956
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vila",
               "id":"822385"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"VILA",
            "id":"82021390000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Vila",
               "id":"82021390"
            },
            "centroide":{
               "lat":-31.1923707919053,
               "lon":-61.8336140985666
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Frontera",
               "id":"820035"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"VILLA JOSEFINA",
            "id":"82021400000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Josefina",
               "id":"82021400"
            },
            "centroide":{
               "lat":-31.4418599622731,
               "lon":-62.0297169711427
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa San José",
               "id":"822392"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"VILLA SAN JOSE",
            "id":"82021410000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa San José",
               "id":"82021410"
            },
            "centroide":{
               "lat":-31.3391346339244,
               "lon":-61.622588386296
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Virginia",
               "id":"822399"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"VIRGINIA",
            "id":"82021420000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Virginia",
               "id":"82021420"
            },
            "centroide":{
               "lat":-30.7402529602428,
               "lon":-61.3409665931228
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zenón Pereyra",
               "id":"822406"
            },
            "departamento":{
               "nombre":"Castellanos",
               "id":"82021"
            },
            "nombre":"ZENON PEREYRA",
            "id":"82021430000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Zenón Pereyra",
               "id":"82021430"
            },
            "centroide":{
               "lat":-31.5643972137494,
               "lon":-61.898425060781
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alcorta",
               "id":"822413"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"ALCORTA",
            "id":"82028010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Alcorta",
               "id":"82028010"
            },
            "centroide":{
               "lat":-33.5401979791092,
               "lon":-61.1246337163915
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Constitución",
               "id":"820056"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"BARRIO ARROYO DEL MEDIO",
            "id":"82028020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrio Arroyo del Medio",
               "id":"82028020"
            },
            "centroide":{
               "lat":-33.2860666179194,
               "lon":-60.2720977056001
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pavón",
               "id":"822490"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"BARRIO MITRE",
            "id":"82028030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrio Mitre",
               "id":"82028030"
            },
            "centroide":{
               "lat":-33.255594638469,
               "lon":-60.3897758707887
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bombal",
               "id":"822420"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"BOMBAL",
            "id":"82028040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bombal",
               "id":"82028040"
            },
            "centroide":{
               "lat":-33.4600615218579,
               "lon":-61.3189324391797
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada Rica",
               "id":"822427"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"CAÑADA RICA",
            "id":"82028050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cañada Rica",
               "id":"82028050"
            },
            "centroide":{
               "lat":-33.5174175561246,
               "lon":-60.6132968867711
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cepeda",
               "id":"822434"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"CEPEDA",
            "id":"82028060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cepeda",
               "id":"82028060"
            },
            "centroide":{
               "lat":-33.3984208661427,
               "lon":-60.6241114199013
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Empalme Villa Constitución",
               "id":"822441"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"EMPALME VILLA CONSTITUCION",
            "id":"82028070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Empalme Villa Constitución",
               "id":"82028070"
            },
            "centroide":{
               "lat":-33.2628625471248,
               "lon":-60.3804644639294
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Firmat",
               "id":"820063"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"FIRMAT",
            "id":"82028080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Firmat",
               "id":"82028080"
            },
            "centroide":{
               "lat":-33.441023527734,
               "lon":-61.473143421839
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Gelly",
               "id":"822448"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"GENERAL GELLY",
            "id":"82028090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"General Gelly",
               "id":"82028090"
            },
            "centroide":{
               "lat":-33.6018434911159,
               "lon":-60.5989383522422
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Godoy",
               "id":"822455"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"GODOY",
            "id":"82028100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Godoy",
               "id":"82028100"
            },
            "centroide":{
               "lat":-33.3697098731789,
               "lon":-60.5094130215383
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juan Bernabé Molina",
               "id":"822462"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"JUAN B. MOLINA",
            "id":"82028110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Juan B. Molina",
               "id":"82028110"
            },
            "centroide":{
               "lat":-33.496020689866,
               "lon":-60.5122133295946
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Juncal",
               "id":"822469"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"JUNCAL",
            "id":"82028120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Juncal",
               "id":"82028120"
            },
            "centroide":{
               "lat":-33.71761494002,
               "lon":-61.0500179215707
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Vanguardia",
               "id":"822476"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"LA VANGUARDIA",
            "id":"82028130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Vanguardia",
               "id":"82028130"
            },
            "centroide":{
               "lat":-33.3596257970974,
               "lon":-60.6584568664422
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"M9Ximo Paz",
               "id":"822483"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"MAXIMO PAZ",
            "id":"82028140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Máximo Paz",
               "id":"82028140"
            },
            "centroide":{
               "lat":-33.4851439474937,
               "lon":-60.9567885809825
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pavón",
               "id":"822490"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"PAVON",
            "id":"82028150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pavón",
               "id":"82028150"
            },
            "centroide":{
               "lat":-33.2430754805422,
               "lon":-60.4062757935089
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pavón Arriba",
               "id":"822497"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"PAVON ARRIBA",
            "id":"82028160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pavón Arriba",
               "id":"82028160"
            },
            "centroide":{
               "lat":-33.3134173933438,
               "lon":-60.8249758944661
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Peyrano",
               "id":"822504"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"PEYRANO",
            "id":"82028170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Peyrano",
               "id":"82028170"
            },
            "centroide":{
               "lat":-33.5411378598405,
               "lon":-60.804329558703
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rueda",
               "id":"822511"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"RUEDA",
            "id":"82028180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Rueda",
               "id":"82028180"
            },
            "centroide":{
               "lat":-33.335855095367,
               "lon":-60.4608740548372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Teresa",
               "id":"822518"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"SANTA TERESA",
            "id":"82028190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Teresa",
               "id":"82028190"
            },
            "centroide":{
               "lat":-33.4385471621789,
               "lon":-60.7911542772276
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sargento Cabral",
               "id":"822525"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"SARGENTO CABRAL",
            "id":"82028200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sargento Cabral",
               "id":"82028200"
            },
            "centroide":{
               "lat":-33.4330128597835,
               "lon":-60.6301906902003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cepeda",
               "id":"822434"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"STEPHENSON",
            "id":"82028210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Stephenson",
               "id":"82028210"
            },
            "centroide":{
               "lat":-33.4178567628002,
               "lon":-60.5572137324943
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Theobald",
               "id":"822532"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"THEOBALD",
            "id":"82028220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Theobald",
               "id":"82028220"
            },
            "centroide":{
               "lat":-33.3122172562755,
               "lon":-60.3120513587534
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Constitución",
               "id":"820056"
            },
            "departamento":{
               "nombre":"Villa Constitución",
               "id":"82028"
            },
            "nombre":"VILLA CONSTITUCION",
            "id":"82028230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Constitución",
               "id":"82028230"
            },
            "centroide":{
               "lat":-33.2324133911798,
               "lon":-60.3324988273466
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cayastá",
               "id":"822539"
            },
            "departamento":{
               "nombre":"Garay",
               "id":"82035"
            },
            "nombre":"CAYASTA",
            "id":"82035010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cayastá",
               "id":"82035010"
            },
            "centroide":{
               "lat":-31.2023253009306,
               "lon":-60.1614504193394
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Helvecia",
               "id":"822553"
            },
            "departamento":{
               "nombre":"Garay",
               "id":"82035"
            },
            "nombre":"HELVECIA",
            "id":"82035020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Helvecia",
               "id":"82035020"
            },
            "centroide":{
               "lat":-31.0992706781177,
               "lon":-60.0881512872483
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Calchines",
               "id":"822567"
            },
            "departamento":{
               "nombre":"Garay",
               "id":"82035"
            },
            "nombre":"LOS ZAPALLOS",
            "id":"82035030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Zapallos",
               "id":"82035030"
            },
            "centroide":{
               "lat":-31.4956365531425,
               "lon":-60.4286467714748
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Saladero Mariano Cabal",
               "id":"822560"
            },
            "departamento":{
               "nombre":"Garay",
               "id":"82035"
            },
            "nombre":"SALADERO MARIANO CABAL",
            "id":"82035040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Saladero Mariano Cabal",
               "id":"82035040"
            },
            "centroide":{
               "lat":-30.9229849971234,
               "lon":-60.0481154588667
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Calchines",
               "id":"822567"
            },
            "departamento":{
               "nombre":"Garay",
               "id":"82035"
            },
            "nombre":"SANTA ROSA DE CALCHINES",
            "id":"82035050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Calchines",
               "id":"82035050"
            },
            "centroide":{
               "lat":-31.422365592266,
               "lon":-60.3348940800468
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aarón Castellanos",
               "id":"822574"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"AARON CASTELLANOS",
            "id":"82042010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Aarón Castellanos",
               "id":"82042010"
            },
            "centroide":{
               "lat":-34.3345323396045,
               "lon":-62.3748007656351
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Amenábar",
               "id":"822581"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"AMENABAR",
            "id":"82042020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Amenábar",
               "id":"82042020"
            },
            "centroide":{
               "lat":-34.136118440775,
               "lon":-62.4229084851079
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cafferata",
               "id":"822588"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CAFFERATA",
            "id":"82042030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cafferata",
               "id":"82042030"
            },
            "centroide":{
               "lat":-33.4414158758935,
               "lon":-62.0868714692657
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada del Ucle",
               "id":"822595"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CAÑADA DEL UCLE",
            "id":"82042040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cañada del Ucle",
               "id":"82042040"
            },
            "centroide":{
               "lat":-33.4103440716357,
               "lon":-61.6070433975613
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carmen",
               "id":"822602"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CARMEN",
            "id":"82042050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Carmen",
               "id":"82042050"
            },
            "centroide":{
               "lat":-33.7327788629688,
               "lon":-61.7609271479418
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carreras",
               "id":"822609"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CARRERAS",
            "id":"82042060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Carreras",
               "id":"82042060"
            },
            "centroide":{
               "lat":-33.5982834566103,
               "lon":-61.3117684362059
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chapuy",
               "id":"822616"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CHAPUY",
            "id":"82042070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Chapuy",
               "id":"82042070"
            },
            "centroide":{
               "lat":-33.8005299368969,
               "lon":-61.7440181936851
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Chovet",
               "id":"822623"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CHOVET",
            "id":"82042080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Chovet",
               "id":"82042080"
            },
            "centroide":{
               "lat":-33.6007825133126,
               "lon":-61.6046646604986
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Christophersen",
               "id":"822630"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"CHRISTOPHERSEN",
            "id":"82042090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Christophersen",
               "id":"82042090"
            },
            "centroide":{
               "lat":-34.1846191181657,
               "lon":-62.0235323680591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Diego de Alvear",
               "id":"822637"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"DIEGO DE ALVEAR",
            "id":"82042100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Diego de Alvear",
               "id":"82042100"
            },
            "centroide":{
               "lat":-34.3743429234538,
               "lon":-62.1289273623308
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Elortondo",
               "id":"822644"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"ELORTONDO",
            "id":"82042110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Elortondo",
               "id":"82042110"
            },
            "centroide":{
               "lat":-33.7016560168056,
               "lon":-61.6163727056605
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Firmat",
               "id":"820063"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"FIRMAT",
            "id":"82042120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Firmat",
               "id":"82042120"
            },
            "centroide":{
               "lat":-33.4580496833225,
               "lon":-61.4914525089409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hughes",
               "id":"822651"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"HUGHES",
            "id":"82042130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Hughes",
               "id":"82042130"
            },
            "centroide":{
               "lat":-33.8028556653324,
               "lon":-61.3358033222625
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Chispa",
               "id":"822658"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"LA CHISPA",
            "id":"82042140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Chispa",
               "id":"82042140"
            },
            "centroide":{
               "lat":-33.5446704008322,
               "lon":-61.9736324283312
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Labordeboy",
               "id":"822665"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"LABORDEBOY",
            "id":"82042150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Labordeboy",
               "id":"82042150"
            },
            "centroide":{
               "lat":-33.7196527453715,
               "lon":-61.3149208168028
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lazzarino",
               "id":"822672"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"LAZZARINO",
            "id":"82042160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Lazzarino",
               "id":"82042160"
            },
            "centroide":{
               "lat":-34.1663295663381,
               "lon":-62.428038027595
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maggiolo",
               "id":"822679"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"MAGGIOLO",
            "id":"82042170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Maggiolo",
               "id":"82042170"
            },
            "centroide":{
               "lat":-33.7244236308417,
               "lon":-62.2478391856324
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"María Teresa",
               "id":"822686"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"MARIA TERESA",
            "id":"82042180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"María Teresa",
               "id":"82042180"
            },
            "centroide":{
               "lat":-34.0062849828557,
               "lon":-61.900439675435
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Melincué",
               "id":"822693"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"MELINCUE",
            "id":"82042190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Melincué",
               "id":"82042190"
            },
            "centroide":{
               "lat":-33.6619144262235,
               "lon":-61.4576887408087
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Miguel Torres",
               "id":"822700"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"MIGUEL TORRES",
            "id":"82042200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Miguel Torres",
               "id":"82042200"
            },
            "centroide":{
               "lat":-33.5299502620402,
               "lon":-61.4662068731284
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Murphy",
               "id":"822707"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"MURPHY",
            "id":"82042210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Murphy",
               "id":"82042210"
            },
            "centroide":{
               "lat":-33.6428623685969,
               "lon":-61.8577974691015
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rufino",
               "id":"820070"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"RUFINO",
            "id":"82042220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Rufino",
               "id":"82042220"
            },
            "centroide":{
               "lat":-34.2636098414032,
               "lon":-62.7117038844931
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Eduardo",
               "id":"822714"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"SAN EDUARDO",
            "id":"82042230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Eduardo",
               "id":"82042230"
            },
            "centroide":{
               "lat":-33.8723970130663,
               "lon":-62.0917158307714
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Francisco de Santa Fe",
               "id":"822721"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"SAN FRANCISCO DE SANTA FE",
            "id":"82042240000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Francisco de Santa Fe",
               "id":"82042240"
            },
            "centroide":{
               "lat":-33.5905400040189,
               "lon":-62.1244693933144
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Gregorio",
               "id":"822728"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"SAN GREGORIO",
            "id":"82042250000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Gregorio",
               "id":"82042250"
            },
            "centroide":{
               "lat":-34.3265186547551,
               "lon":-62.0379529693876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sancti Spiritu",
               "id":"822735"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"SANCTI SPIRITU",
            "id":"82042260000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sancti Spiritu",
               "id":"82042260"
            },
            "centroide":{
               "lat":-34.0095285599362,
               "lon":-62.2424503438155
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Isabel",
               "id":"822742"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"SANTA ISABEL",
            "id":"82042270000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Isabel",
               "id":"82042270"
            },
            "centroide":{
               "lat":-33.8894892658382,
               "lon":-61.6965906556385
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Teodelina",
               "id":"822749"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"TEODELINA",
            "id":"82042280000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Teodelina",
               "id":"82042280"
            },
            "centroide":{
               "lat":-34.1916130913817,
               "lon":-61.5272264570788
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Venado Tuerto",
               "id":"820277"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"VENADO TUERTO",
            "id":"82042290000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Venado Tuerto",
               "id":"82042290"
            },
            "centroide":{
               "lat":-33.747315292187,
               "lon":-61.9695358692001
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Cañás",
               "id":"820084"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"VILLA CAÑAS",
            "id":"82042300000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Cañás",
               "id":"82042300"
            },
            "centroide":{
               "lat":-34.0061339956012,
               "lon":-61.6063880455097
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Wheelwright",
               "id":"822756"
            },
            "departamento":{
               "nombre":"General López",
               "id":"82042"
            },
            "nombre":"WHEELWRIGHT",
            "id":"82042310000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Wheelwright",
               "id":"82042310"
            },
            "centroide":{
               "lat":-33.7942942799185,
               "lon":-61.2114409469748
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Ceibal",
               "id":"822763"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"ARROYO CEIBAL",
            "id":"82049010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arroyo Ceibal",
               "id":"82049010"
            },
            "centroide":{
               "lat":-28.7250869970621,
               "lon":-59.4804164807602
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Avellaneda",
               "id":"820091"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"AVELLANEDA",
            "id":"82049020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Avellaneda",
               "id":"82049020"
            },
            "centroide":{
               "lat":-29.1193659780886,
               "lon":-59.6592512444638
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Berna",
               "id":"822770"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"BERNA",
            "id":"82049030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Berna",
               "id":"82049030"
            },
            "centroide":{
               "lat":-29.2752681077911,
               "lon":-59.8472221836471
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Arazá",
               "id":"822777"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"EL ARAZA",
            "id":"82049040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"El Araza",
               "id":"82049040"
            },
            "centroide":{
               "lat":-29.1334936783763,
               "lon":-59.9473145917686
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Rabón",
               "id":"822784"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"EL RABON",
            "id":"82049050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"El Rabón",
               "id":"82049050"
            },
            "centroide":{
               "lat":-28.2302084157377,
               "lon":-59.2639844821107
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Florencia",
               "id":"822798"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"FLORENCIA",
            "id":"82049060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Florencia",
               "id":"82049060"
            },
            "centroide":{
               "lat":-28.0427359947317,
               "lon":-59.2187469070082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Guadalupe Norte",
               "id":"822805"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"GUADALUPE NORTE",
            "id":"82049070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Guadalupe Norte",
               "id":"82049070"
            },
            "centroide":{
               "lat":-28.9453022044499,
               "lon":-59.5634151929235
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ingeniero Chanourdie",
               "id":"822812"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"INGENIERO CHANOURDIE",
            "id":"82049080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Chanourdie",
               "id":"82049080"
            },
            "centroide":{
               "lat":-28.759813885955,
               "lon":-59.5772324985847
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"LA ISLETA",
            "id":"82049090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Isleta",
               "id":"82049090"
            },
            "centroide":{
               "lat":-28.493935731005,
               "lon":-59.2950745667521
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Sarita",
               "id":"822819"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"LA SARITA",
            "id":"82049100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Sarita",
               "id":"82049100"
            },
            "centroide":{
               "lat":-28.9728525913241,
               "lon":-59.8484991818248
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lanteri",
               "id":"822826"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"LANTERI",
            "id":"82049110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Lanteri",
               "id":"82049110"
            },
            "centroide":{
               "lat":-28.8429706211446,
               "lon":-59.6379287718138
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Garzas",
               "id":"822833"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"LAS GARZAS",
            "id":"82049120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Garzas",
               "id":"82049120"
            },
            "centroide":{
               "lat":-28.8490101824631,
               "lon":-59.5005781021303
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Toscas",
               "id":"820098"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"LAS TOSCAS",
            "id":"82049130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Toscas",
               "id":"82049130"
            },
            "centroide":{
               "lat":-28.3540749299597,
               "lon":-59.2595521000375
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Laureles",
               "id":"822840"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"LOS LAURELES",
            "id":"82049140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Laureles",
               "id":"82049140"
            },
            "centroide":{
               "lat":-29.3701211027814,
               "lon":-59.7378677770758
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Malabrigo",
               "id":"820105"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"MALABRIGO",
            "id":"82049150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Malabrigo",
               "id":"82049150"
            },
            "centroide":{
               "lat":-29.3506755184496,
               "lon":-59.9705883109423
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Sarita",
               "id":"822819"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"PARAJE SAN MANUEL",
            "id":"82049160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Paraje San Manuel",
               "id":"82049160"
            },
            "centroide":{
               "lat":-28.8716623885107,
               "lon":-59.86560439575
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Reconquista",
               "id":"820112"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"PUERTO RECONQUISTA",
            "id":"82049170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Puerto Reconquista",
               "id":"82049170"
            },
            "centroide":{
               "lat":-29.2349873699001,
               "lon":-59.5802691789886
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Reconquista",
               "id":"820112"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"RECONQUISTA",
            "id":"82049180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Reconquista",
               "id":"82049180"
            },
            "centroide":{
               "lat":-29.1451468389264,
               "lon":-59.6510730563582
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio de Obligado",
               "id":"822854"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"SAN ANTONIO DE OBLIGADO",
            "id":"82049190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Antonio de Obligado",
               "id":"82049190"
            },
            "centroide":{
               "lat":-28.3823470938865,
               "lon":-59.2645742897512
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tacuarendí",
               "id":"822861"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"TACUARENDI",
            "id":"82049200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Tacuarendí",
               "id":"82049200"
            },
            "centroide":{
               "lat":-28.4202062467088,
               "lon":-59.2556740044459
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Ana",
               "id":"822868"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"VILLA ANA",
            "id":"82049210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Ana",
               "id":"82049210"
            },
            "centroide":{
               "lat":-28.4933207178133,
               "lon":-59.6141214862397
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Guillermina",
               "id":"822875"
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"VILLA GUILLERMINA",
            "id":"82049220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Guillermina",
               "id":"82049220"
            },
            "centroide":{
               "lat":-28.2450754331528,
               "lon":-59.4547598305134
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Obligado",
               "id":"82049"
            },
            "nombre":"VILLA OCAMPO",
            "id":"82049230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Ocampo",
               "id":"82049230"
            },
            "centroide":{
               "lat":-28.4904509578913,
               "lon":-59.3587641281681
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Totoras",
               "id":"820133"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"BARRIO CICARELLI",
            "id":"82056010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrio Cicarelli",
               "id":"82056010"
            },
            "centroide":{
               "lat":-32.6103776107001,
               "lon":-61.3244409322018
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bustinza",
               "id":"822882"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"BUSTINZA",
            "id":"82056020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bustinza",
               "id":"82056020"
            },
            "centroide":{
               "lat":-32.7399961772877,
               "lon":-61.2915869736645
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada de Gómez",
               "id":"820126"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"CAÑADA DE GOMEZ",
            "id":"82056030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cañada de Gómez",
               "id":"82056030"
            },
            "centroide":{
               "lat":-32.8166867292145,
               "lon":-61.3899661468272
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carrizales",
               "id":"822889"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"CARRIZALES",
            "id":"82056040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Carrizales",
               "id":"82056040"
            },
            "centroide":{
               "lat":-32.5112871642714,
               "lon":-61.0305147885477
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Clason",
               "id":"822896"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"CLASSON",
            "id":"82056050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Classon",
               "id":"82056050"
            },
            "centroide":{
               "lat":-32.4634203095763,
               "lon":-61.2910434085442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Totoras",
               "id":"820133"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"COLONIA MEDICI",
            "id":"82056060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Médici",
               "id":"82056060"
            },
            "centroide":{
               "lat":-32.5988903254118,
               "lon":-61.3767121670301
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Correa",
               "id":"822903"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"CORREA",
            "id":"82056070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Correa",
               "id":"82056070"
            },
            "centroide":{
               "lat":-32.8494610803638,
               "lon":-61.2545569082818
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Totoras",
               "id":"820133"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"LARGUIA",
            "id":"82056080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Larguía",
               "id":"82056080"
            },
            "centroide":{
               "lat":-32.5539810535705,
               "lon":-61.2197995428986
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lucio V. López",
               "id":"822910"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"LUCIO V. LOPEZ",
            "id":"82056090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Lucio V. López",
               "id":"82056090"
            },
            "centroide":{
               "lat":-32.7147297231149,
               "lon":-61.0226073568773
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Oliveros",
               "id":"822917"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"OLIVEROS",
            "id":"82056100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Oliveros",
               "id":"82056100"
            },
            "centroide":{
               "lat":-32.5758215248523,
               "lon":-60.8553515331513
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Andino",
               "id":"822924"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"PUEBLO ANDINO",
            "id":"82056110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pueblo Andino",
               "id":"82056110"
            },
            "centroide":{
               "lat":-32.6717914164971,
               "lon":-60.8761806736397
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Salto Grande",
               "id":"822931"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"SALTO GRANDE",
            "id":"82056120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Salto Grande",
               "id":"82056120"
            },
            "centroide":{
               "lat":-32.6680220468782,
               "lon":-61.0890486524519
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Serodino",
               "id":"822938"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"SERODINO",
            "id":"82056130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Serodino",
               "id":"82056130"
            },
            "centroide":{
               "lat":-32.6055740792488,
               "lon":-60.9481762653391
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Totoras",
               "id":"820133"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"TOTORAS",
            "id":"82056140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Totoras",
               "id":"82056140"
            },
            "centroide":{
               "lat":-32.5863515505869,
               "lon":-61.1673292562769
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Eloísa",
               "id":"822945"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"VILLA ELOISA",
            "id":"82056150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Eloísa",
               "id":"82056150"
            },
            "centroide":{
               "lat":-32.9643241574442,
               "lon":-61.5478333256653
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Oliveros",
               "id":"822917"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"VILLA LA RIVERA - OLIVEROS",
            "id":"82056160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa La Rivera (Oliveros)",
               "id":"82056160"
            },
            "centroide":{
               "lat":-32.633431229414,
               "lon":-60.8208776739619
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Andino",
               "id":"822924"
            },
            "departamento":{
               "nombre":"Iriondo",
               "id":"82056"
            },
            "nombre":"VILLA LA RIVERA - PUEBLO ANDINO",
            "id":"82056170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa La Rivera (Pueblo Andino)",
               "id":"82056170"
            },
            "centroide":{
               "lat":-32.6413858836004,
               "lon":-60.8234183677953
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Vera",
               "id":"823001"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"ANGEL GALLARDO",
            "id":"82063010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Angel Gallardo",
               "id":"82063010"
            },
            "centroide":{
               "lat":-31.5551884917333,
               "lon":-60.6783108244693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Aguiar",
               "id":"822952"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"ARROYO AGUIAR",
            "id":"82063020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arroyo Aguiar",
               "id":"82063020"
            },
            "centroide":{
               "lat":-31.4322203707863,
               "lon":-60.6676588774069
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Leyes",
               "id":"822959"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"ARROYO LEYES",
            "id":"82063030001",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arroyo Leyes",
               "id":"82063030"
            },
            "centroide":{
               "lat":-31.5577005393669,
               "lon":-60.5166665613111
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Leyes",
               "id":"822959"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"RINCON NORTE",
            "id":"82063030002",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arroyo Leyes",
               "id":"82063030"
            },
            "centroide":{
               "lat":-31.5644326802115,
               "lon":-60.414954582383
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cabal",
               "id":"822966"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"CABAL",
            "id":"82063040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cabal",
               "id":"82063040"
            },
            "centroide":{
               "lat":-31.1039907773825,
               "lon":-60.7271526484511
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Campo Andino",
               "id":"822973"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"CAMPO ANDINO",
            "id":"82063050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Campo Andino",
               "id":"82063050"
            },
            "centroide":{
               "lat":-31.2410698890417,
               "lon":-60.531189241674
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Candioti",
               "id":"822980"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"CANDIOTI",
            "id":"82063060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Candioti",
               "id":"82063060"
            },
            "centroide":{
               "lat":-31.3995244031815,
               "lon":-60.7491149288676
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Emilia",
               "id":"822987"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"EMILIA",
            "id":"82063070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Emilia",
               "id":"82063070"
            },
            "centroide":{
               "lat":-31.0610951750412,
               "lon":-60.7464379477764
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Laguna Paiva",
               "id":"820140"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"LAGUNA PAIVA",
            "id":"82063080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Laguna Paiva",
               "id":"82063080"
            },
            "centroide":{
               "lat":-31.3092619266382,
               "lon":-60.6607816085928
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Llambi Campbell",
               "id":"822994"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"LLAMBI CAMPBELL",
            "id":"82063090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Llambi Campbell",
               "id":"82063090"
            },
            "centroide":{
               "lat":-31.1862574038904,
               "lon":-60.7484785939749
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Vera",
               "id":"823001"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"MONTE VERA",
            "id":"82063100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Monte Vera",
               "id":"82063100"
            },
            "centroide":{
               "lat":-31.5184864327594,
               "lon":-60.6780997937426
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nelson",
               "id":"823008"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"NELSON",
            "id":"82063110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Nelson",
               "id":"82063110"
            },
            "centroide":{
               "lat":-31.2670754515365,
               "lon":-60.7621355398529
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Vera",
               "id":"823001"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"PARAJE CHACO CHICO",
            "id":"82063120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Paraje Chaco Chico",
               "id":"82063120"
            },
            "centroide":{
               "lat":-31.567299963712,
               "lon":-60.6617466185791
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monte Vera",
               "id":"823001"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"PARAJE LA COSTA",
            "id":"82063130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Paraje La Costa",
               "id":"82063130"
            },
            "centroide":{
               "lat":-31.5177974435199,
               "lon":-60.6115160069236
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Recreo",
               "id":"820144"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"RECREO",
            "id":"82063140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Recreo",
               "id":"82063140"
            },
            "centroide":{
               "lat":-31.4935807560402,
               "lon":-60.735411088708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Leyes",
               "id":"822959"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"RINCON POTRERO",
            "id":"82063150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Rincón Potrero",
               "id":"82063150"
            },
            "centroide":{
               "lat":-31.5286179375841,
               "lon":-60.4756662346225
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San José del Rincón",
               "id":"823022"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"SAN JOSE DEL RINCON",
            "id":"82063160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San José del Rincón",
               "id":"82063160"
            },
            "centroide":{
               "lat":-31.6061471114691,
               "lon":-60.569631684875
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Fe",
               "id":"820147"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"SANTA FE",
            "id":"82063170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Fe",
               "id":"82063170"
            },
            "centroide":{
               "lat":-31.645164805431,
               "lon":-60.7093147118987
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santo Tomé",
               "id":"820154"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"SANTO TOME",
            "id":"82063180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santo Tomé",
               "id":"82063180"
            },
            "centroide":{
               "lat":-31.6648423299398,
               "lon":-60.7626399841519
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sauce Viejo",
               "id":"823029"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"SAUCE VIEJO",
            "id":"82063190001",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sauce Viejo",
               "id":"82063190"
            },
            "centroide":{
               "lat":-31.7630683465248,
               "lon":-60.8898606108667
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sauce Viejo",
               "id":"823029"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"VILLA ADELINA",
            "id":"82063190002",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sauce Viejo",
               "id":"82063190"
            },
            "centroide":{
               "lat":-31.7077603088951,
               "lon":-60.7883380397856
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Aguiar",
               "id":"822952"
            },
            "departamento":{
               "nombre":"La Capital",
               "id":"82063"
            },
            "nombre":"VILLA LAURA",
            "id":"82063200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Laura",
               "id":"82063200"
            },
            "centroide":{
               "lat":-31.3738964582973,
               "lon":-60.664717579411
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cavour",
               "id":"823036"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"CAVOUR",
            "id":"82070010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cavour",
               "id":"82070010"
            },
            "centroide":{
               "lat":-31.3666330323604,
               "lon":-61.0172254853249
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cululú",
               "id":"823050"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"CULULU",
            "id":"82070020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cululú",
               "id":"82070020"
            },
            "centroide":{
               "lat":-31.2053110846326,
               "lon":-60.9314003415917
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Elisa",
               "id":"823057"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"ELISA",
            "id":"82070030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Elisa",
               "id":"82070030"
            },
            "centroide":{
               "lat":-30.6980797094538,
               "lon":-61.0487593646417
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Empalme San Carlos",
               "id":"823064"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"EMPALME SAN CARLOS",
            "id":"82070040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Empalme San Carlos",
               "id":"82070040"
            },
            "centroide":{
               "lat":-31.5487780871674,
               "lon":-60.8127223355579
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esperanza",
               "id":"820161"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"ESPERANZA",
            "id":"82070050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Esperanza",
               "id":"82070050"
            },
            "centroide":{
               "lat":-31.4505966144136,
               "lon":-60.9310068119638
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Felicia",
               "id":"823071"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"FELICIA",
            "id":"82070060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Felicia",
               "id":"82070060"
            },
            "centroide":{
               "lat":-31.246393987915,
               "lon":-61.2128816673977
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Franck",
               "id":"823078"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"FRANCK",
            "id":"82070070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Franck",
               "id":"82070070"
            },
            "centroide":{
               "lat":-31.5888736174502,
               "lon":-60.9388945830321
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Grutly",
               "id":"823085"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"GRUTLY",
            "id":"82070080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Grutly",
               "id":"82070080"
            },
            "centroide":{
               "lat":-31.2705026899597,
               "lon":-61.0727542695818
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hipatía",
               "id":"823092"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"HIPATIA",
            "id":"82070090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Hipatía",
               "id":"82070090"
            },
            "centroide":{
               "lat":-31.1282518725208,
               "lon":-61.0327676754247
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Humboldt",
               "id":"823099"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"HUMBOLDT",
            "id":"82070100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Humboldt",
               "id":"82070100"
            },
            "centroide":{
               "lat":-31.4009210645041,
               "lon":-61.0825515659905
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Jacinto L. Arauz",
               "id":"823113"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"JACINTO L. ARAUZ",
            "id":"82070110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Jacinto L. Aráuz",
               "id":"82070110"
            },
            "centroide":{
               "lat":-30.7370340516557,
               "lon":-60.9759511253175
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Pelada",
               "id":"823120"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"LA PELADA",
            "id":"82070120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Pelada",
               "id":"82070120"
            },
            "centroide":{
               "lat":-30.8679477693336,
               "lon":-60.9718116807155
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Tunas",
               "id":"823127"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"LAS TUNAS",
            "id":"82070130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Tunas",
               "id":"82070130"
            },
            "centroide":{
               "lat":-31.5722370638803,
               "lon":-60.9959287992772
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"María Luisa",
               "id":"823134"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"MARIA LUISA",
            "id":"82070140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"María Luisa",
               "id":"82070140"
            },
            "centroide":{
               "lat":-31.0126440481519,
               "lon":-60.9114327492504
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Matilde",
               "id":"823141"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"MATILDE",
            "id":"82070150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Matilde",
               "id":"82070150"
            },
            "centroide":{
               "lat":-31.7932160880623,
               "lon":-60.9818059784844
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Nuevo Torino",
               "id":"823148"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"NUEVO TORINO",
            "id":"82070160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Nuevo Torino",
               "id":"82070160"
            },
            "centroide":{
               "lat":-31.3468823835409,
               "lon":-61.235725734005
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pilar",
               "id":"823155"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"PILAR",
            "id":"82070170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pilar",
               "id":"82070170"
            },
            "centroide":{
               "lat":-31.442015817123,
               "lon":-61.2600249614063
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Matilde",
               "id":"823141"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"PLAZA MATILDE",
            "id":"82070180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Plaza Matilde",
               "id":"82070180"
            },
            "centroide":{
               "lat":-31.7974834888763,
               "lon":-61.0111678232689
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Progreso",
               "id":"823162"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"PROGRESO",
            "id":"82070190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Progreso",
               "id":"82070190"
            },
            "centroide":{
               "lat":-31.1397689545802,
               "lon":-60.990254156931
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Providencia",
               "id":"823169"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"PROVIDENCIA",
            "id":"82070200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Providencia",
               "id":"82070200"
            },
            "centroide":{
               "lat":-30.9845868369063,
               "lon":-61.0218281498813
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sa Pereyra",
               "id":"823190"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SA PEREYRA",
            "id":"82070210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sa Pereyra",
               "id":"82070210"
            },
            "centroide":{
               "lat":-31.5721513951563,
               "lon":-61.3782231110791
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Agustín",
               "id":"823197"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN AGUSTIN",
            "id":"82070220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Agustín",
               "id":"82070220"
            },
            "centroide":{
               "lat":-31.6847778866246,
               "lon":-60.9413147450231
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos Centro",
               "id":"820168"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN CARLOS CENTRO",
            "id":"82070230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Carlos Centro",
               "id":"82070230"
            },
            "centroide":{
               "lat":-31.7284179989089,
               "lon":-61.0913957859145
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos Norte",
               "id":"823204"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN CARLOS NORTE",
            "id":"82070240000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Carlos Norte",
               "id":"82070240"
            },
            "centroide":{
               "lat":-31.6743105766747,
               "lon":-61.0762585195433
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Carlos Sud",
               "id":"823211"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN CARLOS SUD",
            "id":"82070250000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Carlos Sud",
               "id":"82070250"
            },
            "centroide":{
               "lat":-31.7575954064363,
               "lon":-61.1007514980914
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jerónimo del Sauce",
               "id":"823218"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN JERONIMO DEL SAUCE",
            "id":"82070260000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Jerónimo del Sauce",
               "id":"82070260"
            },
            "centroide":{
               "lat":-31.6112124759047,
               "lon":-61.1425180717315
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jerónimo Norte",
               "id":"823225"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN JERONIMO NORTE",
            "id":"82070270000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Jerónimo Norte",
               "id":"82070270"
            },
            "centroide":{
               "lat":-31.5545316682675,
               "lon":-61.078514153956
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Mariano",
               "id":"823232"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SAN MARIANO",
            "id":"82070280000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Mariano",
               "id":"82070280"
            },
            "centroide":{
               "lat":-31.6702105890861,
               "lon":-61.3480211476449
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Clara de Buena Vista",
               "id":"823239"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SANTA CLARA DE BUENA VISTA",
            "id":"82070290000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Clara de Buena Vista",
               "id":"82070290"
            },
            "centroide":{
               "lat":-31.7657963305203,
               "lon":-61.3210236384566
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santo Domingo",
               "id":"823260"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SANTO DOMINGO",
            "id":"82070300000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santo Domingo",
               "id":"82070300"
            },
            "centroide":{
               "lat":-31.1222768363914,
               "lon":-60.8888854567848
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sarmiento",
               "id":"823267"
            },
            "departamento":{
               "nombre":"Las Colonias",
               "id":"82070"
            },
            "nombre":"SARMIENTO",
            "id":"82070310000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sarmiento",
               "id":"82070310"
            },
            "centroide":{
               "lat":-31.0606024884219,
               "lon":-61.167889729979
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esteban Rams",
               "id":"823281"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"ESTEBAN RAMS",
            "id":"82077010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Esteban Rams",
               "id":"82077010"
            },
            "centroide":{
               "lat":-29.7726717957721,
               "lon":-61.4881512346811
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gato Colorado",
               "id":"823288"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"GATO COLORADO",
            "id":"82077020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gato Colorado",
               "id":"82077020"
            },
            "centroide":{
               "lat":-28.0245785095892,
               "lon":-61.1879373297868
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gregoria Pérez de Denis",
               "id":"823295"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"GREGORIA PEREZ DE DENIS",
            "id":"82077030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gregoria Pérez de Denis",
               "id":"82077030"
            },
            "centroide":{
               "lat":-28.2296230607257,
               "lon":-61.5297529930521
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Logroño",
               "id":"823309"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"LOGROÑO",
            "id":"82077040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Logroño",
               "id":"82077040"
            },
            "centroide":{
               "lat":-29.5042812797874,
               "lon":-61.6967327802837
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Montefiore",
               "id":"823316"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"MONTEFIORE",
            "id":"82077050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Montefiore",
               "id":"82077050"
            },
            "centroide":{
               "lat":-29.6678698226711,
               "lon":-61.867108287483
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pozo Borrado",
               "id":"823323"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"POZO BORRADO",
            "id":"82077060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pozo Borrado",
               "id":"82077060"
            },
            "centroide":{
               "lat":-28.9399423031181,
               "lon":-61.7055999743386
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Bernardo",
               "id":"823330"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"SAN BERNARDO",
            "id":"82077065000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Bernardo",
               "id":"82077065"
            },
            "centroide":{
               "lat":-28.6300342810186,
               "lon":-61.5069219893981
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Margarita",
               "id":"823337"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"SANTA MARGARITA",
            "id":"82077070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santa Margarita",
               "id":"82077070"
            },
            "centroide":{
               "lat":-28.3149549140447,
               "lon":-61.550329937355
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tostado",
               "id":"820175"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"TOSTADO",
            "id":"82077080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Tostado",
               "id":"82077080"
            },
            "centroide":{
               "lat":-29.2344732488739,
               "lon":-61.7719824163622
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Minetti",
               "id":"823344"
            },
            "departamento":{
               "nombre":"9 de Julio",
               "id":"82077"
            },
            "nombre":"VILLA MINETTI",
            "id":"82077090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Minetti",
               "id":"82077090"
            },
            "centroide":{
               "lat":-28.6247095403571,
               "lon":-61.6279859869967
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Acebal",
               "id":"823351"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ACEBAL",
            "id":"82084010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Acébal",
               "id":"82084010"
            },
            "centroide":{
               "lat":-33.2436505576999,
               "lon":-60.837195481459
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Albarellos",
               "id":"823358"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ALBARELLOS",
            "id":"82084020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Albarellos",
               "id":"82084020"
            },
            "centroide":{
               "lat":-33.2413031665306,
               "lon":-60.6365751407418
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Álvarez",
               "id":"823365"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ALVAREZ",
            "id":"82084030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Álvarez",
               "id":"82084030"
            },
            "centroide":{
               "lat":-33.1306253585247,
               "lon":-60.8039626859935
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alvear",
               "id":"823372"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ALVEAR",
            "id":"82084040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Alvear",
               "id":"82084040"
            },
            "centroide":{
               "lat":-33.0614584695641,
               "lon":-60.6159765613734
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alvear",
               "id":"823372"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ARBILLA",
            "id":"82084050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arbilla",
               "id":"82084050"
            },
            "centroide":{
               "lat":-33.0919516648506,
               "lon":-60.6993159324653
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arminda",
               "id":"823379"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ARMINDA",
            "id":"82084060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arminda",
               "id":"82084060"
            },
            "centroide":{
               "lat":-33.2658615254501,
               "lon":-60.9669188397385
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Seco",
               "id":"820182"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ARROYO SECO",
            "id":"82084070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arroyo Seco",
               "id":"82084070"
            },
            "centroide":{
               "lat":-33.1560225761175,
               "lon":-60.5101308080247
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carmen del Sauce",
               "id":"823386"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"CARMEN DEL SAUCE",
            "id":"82084080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Carmen del Sauce",
               "id":"82084080"
            },
            "centroide":{
               "lat":-33.2376879120566,
               "lon":-60.8118679160701
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Bogado",
               "id":"823393"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"CORONEL BOGADO",
            "id":"82084090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Coronel Bogado",
               "id":"82084090"
            },
            "centroide":{
               "lat":-33.3175115859048,
               "lon":-60.6036347878573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Domínguez",
               "id":"823470"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"CORONEL RODOLFO S. DOMINGUEZ",
            "id":"82084100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Coronel Rodolfo S. Domínguez",
               "id":"82084100"
            },
            "centroide":{
               "lat":-33.1854760061025,
               "lon":-60.7228633313771
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carmen del Sauce",
               "id":"823386"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"CUATRO ESQUINAS",
            "id":"82084110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cuatro Esquinas",
               "id":"82084110"
            },
            "centroide":{
               "lat":-33.2466228889596,
               "lon":-60.7649740219949
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Álvarez",
               "id":"823365"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"EL CARAMELO",
            "id":"82084120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"El Caramelo",
               "id":"82084120"
            },
            "centroide":{
               "lat":-33.1288733975401,
               "lon":-60.7130394393533
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fighiera",
               "id":"823400"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"FIGHIERA",
            "id":"82084130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Fighiera",
               "id":"82084130"
            },
            "centroide":{
               "lat":-33.1950187794417,
               "lon":-60.4706038463519
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Funes",
               "id":"820189"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"FUNES",
            "id":"82084140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Funes",
               "id":"82084140"
            },
            "centroide":{
               "lat":-32.922782783063,
               "lon":-60.8121802825957
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Lagos",
               "id":"823407"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"GENERAL LAGOS",
            "id":"82084150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"General Lagos",
               "id":"82084150"
            },
            "centroide":{
               "lat":-33.1121588884353,
               "lon":-60.5665737837063
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Granadero Baigorria",
               "id":"820196"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"GRANADERO BAIGORRIA",
            "id":"82084160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Granadero Baigorria",
               "id":"82084160"
            },
            "centroide":{
               "lat":-32.8613641656775,
               "lon":-60.7062159770827
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ibarlucea",
               "id":"823414"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"IBARLUCEA",
            "id":"82084170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ibarlucea",
               "id":"82084170"
            },
            "centroide":{
               "lat":-32.8512561304404,
               "lon":-60.7884936646076
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alvear",
               "id":"823372"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"KILOMETRO 101",
            "id":"82084180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Kilómetro 101",
               "id":"82084180"
            },
            "centroide":{
               "lat":-33.060584803082,
               "lon":-60.6856440118334
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Piñero",
               "id":"823421"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"LOS MUCHACHOS - LA ALBORADA",
            "id":"82084190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Muchachos - La Alborada",
               "id":"82084190"
            },
            "centroide":{
               "lat":-33.0915734104105,
               "lon":-60.7354254261055
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alvear",
               "id":"823372"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"MONTE FLORES",
            "id":"82084200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Monte Flores",
               "id":"82084200"
            },
            "centroide":{
               "lat":-33.0800073993365,
               "lon":-60.6355226681116
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pérez",
               "id":"820203"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"PEREZ",
            "id":"82084210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pérez",
               "id":"82084210"
            },
            "centroide":{
               "lat":-32.99881116903,
               "lon":-60.7721592101064
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Piñero",
               "id":"823421"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"PIÑERO",
            "id":"82084220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Piñero",
               "id":"82084220"
            },
            "centroide":{
               "lat":-33.1112071010784,
               "lon":-60.7964961630631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Esther",
               "id":"823428"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"PUEBLO ESTHER",
            "id":"82084230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pueblo Esther",
               "id":"82084230"
            },
            "centroide":{
               "lat":-33.0730969403696,
               "lon":-60.5789195429283
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Muñoz",
               "id":"823435"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"PUEBLO MUÑOZ",
            "id":"82084240000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pueblo Muñóz",
               "id":"82084240"
            },
            "centroide":{
               "lat":-33.1744833504332,
               "lon":-60.8971398400989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Uranga",
               "id":"823449"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"PUEBLO URANGA",
            "id":"82084250000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pueblo Uranga",
               "id":"82084250"
            },
            "centroide":{
               "lat":-33.2645299958204,
               "lon":-60.7083345859989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arroyo Seco",
               "id":"820182"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"PUERTO ARROYO SECO",
            "id":"82084260000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Puerto Arroyo Seco",
               "id":"82084260"
            },
            "centroide":{
               "lat":-33.1315113496399,
               "lon":-60.5078884705177
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ROSARIO",
            "id":"82084270000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Rosario",
               "id":"82084270"
            },
            "centroide":{
               "lat":-32.9538142575214,
               "lon":-60.6515379354516
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Soldini",
               "id":"823442"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"SOLDINI",
            "id":"82084280000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Soldini",
               "id":"82084280"
            },
            "centroide":{
               "lat":-33.0239868445344,
               "lon":-60.7561883192345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Amelia",
               "id":"823456"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"VILLA AMELIA",
            "id":"82084290000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Amelia",
               "id":"82084290"
            },
            "centroide":{
               "lat":-33.1771929125315,
               "lon":-60.6677297311463
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Amelia",
               "id":"823456"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"VILLA DEL PLATA",
            "id":"82084300000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa del Plata",
               "id":"82084300"
            },
            "centroide":{
               "lat":-33.1271769621356,
               "lon":-60.7090282248961
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Gobernador Gálvez",
               "id":"820217"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"VILLA GOBERNADOR GALVEZ",
            "id":"82084310000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Gobernador Gálvez",
               "id":"82084310"
            },
            "centroide":{
               "lat":-33.0224078611601,
               "lon":-60.6336422555152
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Zavalla",
               "id":"823463"
            },
            "departamento":{
               "nombre":"Rosario",
               "id":"82084"
            },
            "nombre":"ZAVALLA",
            "id":"82084320000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Zavalla",
               "id":"82084320"
            },
            "centroide":{
               "lat":-33.0215698285974,
               "lon":-60.879303488531
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aguará Grande",
               "id":"823477"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"AGUARA GRANDE",
            "id":"82091010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Aguará Grande",
               "id":"82091010"
            },
            "centroide":{
               "lat":-30.1093090599224,
               "lon":-60.9437539322397
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ambrosetti",
               "id":"823484"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"AMBROSETTI",
            "id":"82091020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ambrosetti",
               "id":"82091020"
            },
            "centroide":{
               "lat":-30.016999568893,
               "lon":-61.5765785011442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arrufo",
               "id":"823491"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"ARRUFO",
            "id":"82091030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arrufo",
               "id":"82091030"
            },
            "centroide":{
               "lat":-30.2341495301456,
               "lon":-61.7285807001253
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huanqueros",
               "id":"823554"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"BALNEARIO LA VERDE",
            "id":"82091040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Balneario La Verde",
               "id":"82091040"
            },
            "centroide":{
               "lat":-29.9827373481556,
               "lon":-61.2428659094376
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capivara",
               "id":"823498"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"CAPIVARA",
            "id":"82091050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Capivara",
               "id":"82091050"
            },
            "centroide":{
               "lat":-30.4615028173869,
               "lon":-61.2722735128607
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ceres",
               "id":"820224"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"CERES",
            "id":"82091060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ceres",
               "id":"82091060"
            },
            "centroide":{
               "lat":-29.8823371283479,
               "lon":-61.9452374270961
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Ana",
               "id":"823505"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"COLONIA ANA",
            "id":"82091070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Ana",
               "id":"82091070"
            },
            "centroide":{
               "lat":-30.1449998391803,
               "lon":-61.9147917462344
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Bossi",
               "id":"823512"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"COLONIA BOSSI",
            "id":"82091080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Bossi",
               "id":"82091080"
            },
            "centroide":{
               "lat":-30.6691245233432,
               "lon":-61.7896030300364
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Rosa",
               "id":"823519"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"COLONIA ROSA",
            "id":"82091090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Rosa",
               "id":"82091090"
            },
            "centroide":{
               "lat":-30.3022785889636,
               "lon":-61.9844913702309
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Constanza",
               "id":"823526"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"CONSTANZA",
            "id":"82091100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Constanza",
               "id":"82091100"
            },
            "centroide":{
               "lat":-30.664633909429,
               "lon":-61.3207552160733
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Curupaity",
               "id":"823533"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"CURUPAYTI",
            "id":"82091110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Curupaytí",
               "id":"82091110"
            },
            "centroide":{
               "lat":-30.397911889967,
               "lon":-61.6518044215673
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Hersilia",
               "id":"823547"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"HERSILIA",
            "id":"82091120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Hersilia",
               "id":"82091120"
            },
            "centroide":{
               "lat":-30.0056765366847,
               "lon":-61.8396467676821
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Huanqueros",
               "id":"823554"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"HUANQUEROS",
            "id":"82091130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Huanqueros",
               "id":"82091130"
            },
            "centroide":{
               "lat":-30.0136747533996,
               "lon":-61.2192947646911
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Cabral",
               "id":"823561"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"LA CABRAL",
            "id":"82091140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Cabral",
               "id":"82091140"
            },
            "centroide":{
               "lat":-30.0876438007142,
               "lon":-61.1797070550573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Lucila",
               "id":"823575"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"LA LUCILA",
            "id":"82091145000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Lucila",
               "id":"82091145"
            },
            "centroide":{
               "lat":-30.4196635334388,
               "lon":-61.0033073134897
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Rubia",
               "id":"823582"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"LA RUBIA",
            "id":"82091150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Rubia",
               "id":"82091150"
            },
            "centroide":{
               "lat":-30.1113966728017,
               "lon":-61.7927608545145
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Avispas",
               "id":"823589"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"LAS AVISPAS",
            "id":"82091160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Avispas",
               "id":"82091160"
            },
            "centroide":{
               "lat":-29.8953127955717,
               "lon":-61.2911992453769
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Palmeras",
               "id":"823596"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"LAS PALMERAS",
            "id":"82091170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Palmeras",
               "id":"82091170"
            },
            "centroide":{
               "lat":-30.6326345828984,
               "lon":-61.6277233886562
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Moisés Ville",
               "id":"823603"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"MOISES VILLE",
            "id":"82091180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Moisés Ville",
               "id":"82091180"
            },
            "centroide":{
               "lat":-30.7182008702613,
               "lon":-61.469140085345
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monigotes",
               "id":"823610"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"MONIGOTES",
            "id":"82091190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Monigotes",
               "id":"82091190"
            },
            "centroide":{
               "lat":-30.4901359922347,
               "lon":-61.6348224695165
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ñanducita",
               "id":"823624"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"ÑANDUCITA",
            "id":"82091200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ñanducita",
               "id":"82091200"
            },
            "centroide":{
               "lat":-30.37245992257,
               "lon":-61.1326015812096
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Palacios",
               "id":"823631"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"PALACIOS",
            "id":"82091210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Palacios",
               "id":"82091210"
            },
            "centroide":{
               "lat":-30.7106694051354,
               "lon":-61.6236952048733
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Cristóbal",
               "id":"820231"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"SAN CRISTOBAL",
            "id":"82091220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Cristóbal",
               "id":"82091220"
            },
            "centroide":{
               "lat":-30.311687011314,
               "lon":-61.2386444593771
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Guillermo",
               "id":"823645"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"SAN GUILLERMO",
            "id":"82091230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Guillermo",
               "id":"82091230"
            },
            "centroide":{
               "lat":-30.3602098529676,
               "lon":-61.9178272634037
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santurce",
               "id":"823652"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"SANTURCE",
            "id":"82091240000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Santurce",
               "id":"82091240"
            },
            "centroide":{
               "lat":-30.1866452738191,
               "lon":-61.178528021881
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Soledad",
               "id":"823659"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"SOLEDAD",
            "id":"82091250000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Soledad",
               "id":"82091250"
            },
            "centroide":{
               "lat":-30.6225538384456,
               "lon":-60.9166112630514
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Suardi",
               "id":"823666"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"SUARDI",
            "id":"82091260000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Suardi",
               "id":"82091260"
            },
            "centroide":{
               "lat":-30.5361453460922,
               "lon":-61.9616805839765
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Saralegui",
               "id":"823673"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"VILLA SARALEGUI",
            "id":"82091270000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Saralegui",
               "id":"82091270"
            },
            "centroide":{
               "lat":-30.5427027882285,
               "lon":-60.7477102618236
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Trinidad",
               "id":"823680"
            },
            "departamento":{
               "nombre":"San Cristóbal",
               "id":"82091"
            },
            "nombre":"VILLA TRINIDAD",
            "id":"82091280000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Trinidad",
               "id":"82091280"
            },
            "centroide":{
               "lat":-30.2176460355738,
               "lon":-61.877568026694
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alejandra",
               "id":"823687"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"82098"
            },
            "nombre":"ALEJANDRA",
            "id":"82098010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Alejandra",
               "id":"82098010"
            },
            "centroide":{
               "lat":-29.9103566747475,
               "lon":-59.8281612200693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cacique Ariacaiquín",
               "id":"823694"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"82098"
            },
            "nombre":"CACIQUE ARIACAIQUIN",
            "id":"82098020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cacique Ariacaiquín",
               "id":"82098020"
            },
            "centroide":{
               "lat":-30.6581364074941,
               "lon":-60.2307356488107
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Durán",
               "id":"823701"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"82098"
            },
            "nombre":"COLONIA DURAN",
            "id":"82098030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Durán",
               "id":"82098030"
            },
            "centroide":{
               "lat":-29.5607537201086,
               "lon":-59.9270693537131
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Brava",
               "id":"823708"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"82098"
            },
            "nombre":"LA BRAVA",
            "id":"82098040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Brava",
               "id":"82098040"
            },
            "centroide":{
               "lat":-30.4477825105448,
               "lon":-60.1409356882605
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Romang",
               "id":"823715"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"82098"
            },
            "nombre":"ROMANG",
            "id":"82098050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Romang",
               "id":"82098050"
            },
            "centroide":{
               "lat":-29.5018294527814,
               "lon":-59.7485934535059
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Javier",
               "id":"820238"
            },
            "departamento":{
               "nombre":"San Javier",
               "id":"82098"
            },
            "nombre":"SAN JAVIER",
            "id":"82098060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Javier",
               "id":"82098060"
            },
            "centroide":{
               "lat":-30.5822068409869,
               "lon":-59.9313985183809
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arocena",
               "id":"823722"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"AROCENA",
            "id":"82105010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Arocena",
               "id":"82105010"
            },
            "centroide":{
               "lat":-32.0800271942926,
               "lon":-60.9770049639573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monje",
               "id":"823827"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"BALNEARIO MONJE",
            "id":"82105020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Balneario Monje",
               "id":"82105020"
            },
            "centroide":{
               "lat":-32.3349560866515,
               "lon":-60.8760522539552
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Barrancas",
               "id":"823729"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"BARRANCAS",
            "id":"82105030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrancas",
               "id":"82105030"
            },
            "centroide":{
               "lat":-32.2366357816462,
               "lon":-60.9827401330363
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Desvío Arijón",
               "id":"823764"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"BARRIO CAIMA",
            "id":"82105040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrio Caima",
               "id":"82105040"
            },
            "centroide":{
               "lat":-31.8320800807618,
               "lon":-60.8719647067245
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arocena",
               "id":"823722"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"BARRIO EL PACAA - BARRIO COMIPINI",
            "id":"82105050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Barrio El Pacaá - Barrio Comipini",
               "id":"82105050"
            },
            "centroide":{
               "lat":-32.1313356998216,
               "lon":-60.9282685049176
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Bernardo de Irigoyen",
               "id":"823736"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"BERNARDO DE IRIGOYEN",
            "id":"82105060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Bernardo de Irigoyen",
               "id":"82105060"
            },
            "centroide":{
               "lat":-32.1709306189148,
               "lon":-61.1572748282837
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Casalegno",
               "id":"823750"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"CASALEGNO",
            "id":"82105070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Casalegno",
               "id":"82105070"
            },
            "centroide":{
               "lat":-32.2638306241466,
               "lon":-61.1261488657874
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Centeno",
               "id":"823757"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"CENTENO",
            "id":"82105080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Centeno",
               "id":"82105080"
            },
            "centroide":{
               "lat":-32.298023717348,
               "lon":-61.4107241466286
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronda",
               "id":"820245"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"CORONDA",
            "id":"82105090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Coronda",
               "id":"82105090"
            },
            "centroide":{
               "lat":-31.975646712145,
               "lon":-60.9201341188126
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Desvío Arijón",
               "id":"823764"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"DESVIO ARIJON",
            "id":"82105100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Desvío Arijón",
               "id":"82105100"
            },
            "centroide":{
               "lat":-31.8727393656094,
               "lon":-60.8896993521491
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Díaz",
               "id":"823771"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"DIAZ",
            "id":"82105110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Díaz",
               "id":"82105110"
            },
            "centroide":{
               "lat":-32.3750588242506,
               "lon":-61.091442512126
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gaboto",
               "id":"823778"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"GABOTO",
            "id":"82105120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gaboto",
               "id":"82105120"
            },
            "centroide":{
               "lat":-32.4343760818819,
               "lon":-60.8185390941723
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gálvez",
               "id":"820252"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"GALVEZ",
            "id":"82105130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gálvez",
               "id":"82105130"
            },
            "centroide":{
               "lat":-32.0326455920318,
               "lon":-61.2199610274248
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gessler",
               "id":"823785"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"GESSLER",
            "id":"82105140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gessler",
               "id":"82105140"
            },
            "centroide":{
               "lat":-31.8770449531764,
               "lon":-61.1288367653984
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pueblo Irigoyen",
               "id":"823792"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"IRIGOYEN",
            "id":"82105150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Irigoyen",
               "id":"82105150"
            },
            "centroide":{
               "lat":-32.16077341012,
               "lon":-61.1104258428642
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Larrechea",
               "id":"823799"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"LARRECHEA",
            "id":"82105160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Larrechea",
               "id":"82105160"
            },
            "centroide":{
               "lat":-31.9361305275575,
               "lon":-61.0477166860408
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Loma Alta",
               "id":"823806"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"LOMA ALTA",
            "id":"82105170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Loma Alta",
               "id":"82105170"
            },
            "centroide":{
               "lat":-31.9614431890621,
               "lon":-61.1783800456556
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"López",
               "id":"823813"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"LOPEZ",
            "id":"82105180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"López",
               "id":"82105180"
            },
            "centroide":{
               "lat":-31.9069668930749,
               "lon":-61.2799295377437
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Maciel",
               "id":"823820"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"MACIEL",
            "id":"82105190000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Maciel",
               "id":"82105190"
            },
            "centroide":{
               "lat":-32.4587652553057,
               "lon":-60.8931126475643
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monje",
               "id":"823827"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"MONJE",
            "id":"82105200000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Monje",
               "id":"82105200"
            },
            "centroide":{
               "lat":-32.358735658961,
               "lon":-60.9429043010117
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Barrancas",
               "id":"823729"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"PUERTO ARAGON",
            "id":"82105210000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Puerto Aragón",
               "id":"82105210"
            },
            "centroide":{
               "lat":-32.2447218281238,
               "lon":-60.9239839784214
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Eugenio",
               "id":"823834"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"SAN EUGENIO",
            "id":"82105220000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Eugenio",
               "id":"82105220"
            },
            "centroide":{
               "lat":-32.0768953861827,
               "lon":-61.1174234926527
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Fabián",
               "id":"823841"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"SAN FABIAN",
            "id":"82105230000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Fabián",
               "id":"82105230"
            },
            "centroide":{
               "lat":-32.1383718722873,
               "lon":-60.9832660246346
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Genaro",
               "id":"820256"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"SAN GENARO",
            "id":"82105240000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Genaro",
               "id":"82105240"
            },
            "centroide":{
               "lat":-32.3735383941398,
               "lon":-61.3606569211003
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Genaro",
               "id":"820256"
            },
            "departamento":{
               "nombre":"San Jerónimo",
               "id":"82105"
            },
            "nombre":"SAN GENARO NORTE",
            "id":"82105250000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Genaro Norte",
               "id":"82105250"
            },
            "centroide":{
               "lat":-32.3658073636946,
               "lon":-61.3401798286706
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Angeloni",
               "id":"823862"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"ANGELONI",
            "id":"82112010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Angeloni",
               "id":"82112010"
            },
            "centroide":{
               "lat":-30.8575177090903,
               "lon":-60.6486098681792
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cayastacito",
               "id":"823869"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"CAYASTACITO",
            "id":"82112020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cayastacito",
               "id":"82112020"
            },
            "centroide":{
               "lat":-31.1150071573313,
               "lon":-60.5814003944463
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Dolores",
               "id":"823876"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"COLONIA DOLORES",
            "id":"82112030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Dolores",
               "id":"82112030"
            },
            "centroide":{
               "lat":-30.3836825866932,
               "lon":-60.3307254883154
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Esther",
               "id":"823883"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"ESTHER",
            "id":"82112040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Esther",
               "id":"82112040"
            },
            "centroide":{
               "lat":-31.0434861128335,
               "lon":-60.6448661369776
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gobernador Crespo",
               "id":"823890"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"GOBERNADOR CRESPO",
            "id":"82112050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Gobernador Crespo",
               "id":"82112050"
            },
            "centroide":{
               "lat":-30.3648346894928,
               "lon":-60.4011684048308
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Criolla",
               "id":"823904"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"LA CRIOLLA",
            "id":"82112060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Criolla",
               "id":"82112060"
            },
            "centroide":{
               "lat":-30.2261579523496,
               "lon":-60.3664968225823
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Penca y Caraguatá",
               "id":"823911"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"LA PENCA Y CARAGUATA",
            "id":"82112070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Penca y Caraguatá",
               "id":"82112070"
            },
            "centroide":{
               "lat":-30.3484964424709,
               "lon":-60.5217853393141
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Marcelino Escalada",
               "id":"823918"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"MARCELINO ESCALADA",
            "id":"82112080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Marcelino Escalada",
               "id":"82112080"
            },
            "centroide":{
               "lat":-30.5819493055119,
               "lon":-60.4693860436357
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Naré",
               "id":"823925"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"NARE",
            "id":"82112090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Naré",
               "id":"82112090"
            },
            "centroide":{
               "lat":-30.951076187335,
               "lon":-60.4682102211441
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pedro Gómez Cello",
               "id":"823932"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"PEDRO GOMEZ CELLO",
            "id":"82112100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pedro Gómez Cello",
               "id":"82112100"
            },
            "centroide":{
               "lat":-30.0384743425904,
               "lon":-60.3152431288441
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ramayón",
               "id":"823939"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"RAMAYON",
            "id":"82112110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ramayón",
               "id":"82112110"
            },
            "centroide":{
               "lat":-30.6758153384973,
               "lon":-60.4995553794067
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Bernardo",
               "id":"823946"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"SAN BERNARDO",
            "id":"82112120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Bernardo",
               "id":"82112120"
            },
            "centroide":{
               "lat":-30.8905395009228,
               "lon":-60.5751464890853
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Justo",
               "id":"820259"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"SAN JUSTO",
            "id":"82112130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Justo",
               "id":"82112130"
            },
            "centroide":{
               "lat":-30.7908665162845,
               "lon":-60.5940368250334
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Mart",
               "id":"823953"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"SAN MARTIN NORTE",
            "id":"82112140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Martín Norte",
               "id":"82112140"
            },
            "centroide":{
               "lat":-30.3493004076432,
               "lon":-60.3040548934806
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Silva",
               "id":"823960"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"SILVA",
            "id":"82112150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Silva",
               "id":"82112150"
            },
            "centroide":{
               "lat":-30.4486399849806,
               "lon":-60.430070013139
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vera y Pintado",
               "id":"823967"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"VERA Y PINTADO",
            "id":"82112160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Vera y Pintado",
               "id":"82112160"
            },
            "centroide":{
               "lat":-30.1436425790617,
               "lon":-60.3373530550458
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Videla",
               "id":"823974"
            },
            "departamento":{
               "nombre":"San Justo",
               "id":"82112"
            },
            "nombre":"VIDELA",
            "id":"82112170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Videla",
               "id":"82112170"
            },
            "centroide":{
               "lat":-30.9465032627862,
               "lon":-60.6564412400796
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Aldao",
               "id":"823981"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"ALDAO",
            "id":"82119010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Aldao",
               "id":"82119010"
            },
            "centroide":{
               "lat":-32.7069853236032,
               "lon":-60.8179518202362
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capitán Bermúdez",
               "id":"820266"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"CAPITAN BERMUDEZ",
            "id":"82119020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Capitán Bermúdez",
               "id":"82119020"
            },
            "centroide":{
               "lat":-32.8282349449349,
               "lon":-60.7168207550494
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carcarañá",
               "id":"820273"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"CARCARAÑA",
            "id":"82119030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Carcarañá",
               "id":"82119030"
            },
            "centroide":{
               "lat":-32.8588281760163,
               "lon":-61.1523502197425
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Coronel Arnold",
               "id":"823988"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"CORONEL ARNOLD",
            "id":"82119040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Coronel Arnold",
               "id":"82119040"
            },
            "centroide":{
               "lat":-33.1066175391399,
               "lon":-60.9665015588484
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fray Luis Beltrán",
               "id":"820280"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"FRAY LUIS BELTRAN",
            "id":"82119050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Fray Luis Beltrán",
               "id":"82119050"
            },
            "centroide":{
               "lat":-32.7855581880336,
               "lon":-60.7291236444004
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fuentes",
               "id":"823995"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"FUENTES",
            "id":"82119060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Fuentes",
               "id":"82119060"
            },
            "centroide":{
               "lat":-33.1744416497872,
               "lon":-61.0750536976089
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Luis Palacios",
               "id":"824002"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"LUIS PALACIOS",
            "id":"82119070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Luis Palacios",
               "id":"82119070"
            },
            "centroide":{
               "lat":-32.784870422621,
               "lon":-60.9076379614075
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"PUERTO GENERAL SAN MARTIN",
            "id":"82119080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Puerto General San Martín",
               "id":"82119080"
            },
            "centroide":{
               "lat":-32.7190025958741,
               "lon":-60.7334925388318
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Pujato",
               "id":"824009"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"PUJATO",
            "id":"82119090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pujato",
               "id":"82119090"
            },
            "centroide":{
               "lat":-33.0195733543103,
               "lon":-61.0438316490822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ricardone",
               "id":"824016"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"RICARDONE",
            "id":"82119100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Ricardone",
               "id":"82119100"
            },
            "centroide":{
               "lat":-32.7736805491372,
               "lon":-60.786927658223
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Roldán",
               "id":"820294"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"ROLDAN",
            "id":"82119110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Roldán",
               "id":"82119110"
            },
            "centroide":{
               "lat":-32.9023879302416,
               "lon":-60.9108827950649
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jerónimo Sud",
               "id":"824023"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"SAN JERONIMO SUD",
            "id":"82119120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Jerónimo Sud",
               "id":"82119120"
            },
            "centroide":{
               "lat":-32.8787353332163,
               "lon":-61.0243903952404
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Lorenzo",
               "id":"820301"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"SAN LORENZO",
            "id":"82119130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Lorenzo",
               "id":"82119130"
            },
            "centroide":{
               "lat":-32.7523069549362,
               "lon":-60.7356209815072
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Timbúes",
               "id":"824030"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"TIMBUES",
            "id":"82119140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Timbúes",
               "id":"82119140"
            },
            "centroide":{
               "lat":-32.6696252682834,
               "lon":-60.7943548240758
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Timbúes",
               "id":"824030"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"VILLA ELVIRA",
            "id":"82119150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Elvira",
               "id":"82119150"
            },
            "centroide":{
               "lat":-32.6429185717722,
               "lon":-60.8177095118801
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Mugueta",
               "id":"824037"
            },
            "departamento":{
               "nombre":"San Lorenzo",
               "id":"82119"
            },
            "nombre":"VILLA MUGUETA",
            "id":"82119160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Villa Mugueta",
               "id":"82119160"
            },
            "centroide":{
               "lat":-33.3139349307156,
               "lon":-61.0570695285482
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada Rosquín",
               "id":"824044"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"CAÑADA ROSQUIN",
            "id":"82126010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cañada Rosquín",
               "id":"82126010"
            },
            "centroide":{
               "lat":-32.0562934036888,
               "lon":-61.6025429947826
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Carlos Pellegrini",
               "id":"824051"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"CARLOS PELLEGRINI",
            "id":"82126020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Carlos Pellegrini",
               "id":"82126020"
            },
            "centroide":{
               "lat":-32.0526642372536,
               "lon":-61.78894991246
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Casas",
               "id":"824058"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"CASAS",
            "id":"82126030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Casas",
               "id":"82126030"
            },
            "centroide":{
               "lat":-32.1279976039457,
               "lon":-61.5421282200809
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Castelar",
               "id":"824065"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"CASTELAR",
            "id":"82126040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Castelar",
               "id":"82126040"
            },
            "centroide":{
               "lat":-31.6691692674795,
               "lon":-62.0899867120516
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Belgrano",
               "id":"824072"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"COLONIA BELGRANO",
            "id":"82126050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colonia Belgrano",
               "id":"82126050"
            },
            "centroide":{
               "lat":-31.9118940838345,
               "lon":-61.4023835358505
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Crispi",
               "id":"824079"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"CRISPI",
            "id":"82126060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Crispi",
               "id":"82126060"
            },
            "centroide":{
               "lat":-31.7417252131656,
               "lon":-62.0378765362955
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Trébol",
               "id":"820308"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"EL TREBOL",
            "id":"82126070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"El Trébol",
               "id":"82126070"
            },
            "centroide":{
               "lat":-32.2030242455008,
               "lon":-61.7028944914559
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Landeta",
               "id":"824086"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"LANDETA",
            "id":"82126080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Landeta",
               "id":"82126080"
            },
            "centroide":{
               "lat":-32.0138147005941,
               "lon":-62.0611771301989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Bandurrias",
               "id":"824093"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"LAS BANDURRIAS",
            "id":"82126090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Bandurrias",
               "id":"82126090"
            },
            "centroide":{
               "lat":-32.1991899646564,
               "lon":-61.4927940449511
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Petacas",
               "id":"824100"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"LAS PETACAS",
            "id":"82126100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Las Petacas",
               "id":"82126100"
            },
            "centroide":{
               "lat":-31.8247278111096,
               "lon":-62.1089907898346
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Cardos",
               "id":"824107"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"LOS CARDOS",
            "id":"82126110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Cardos",
               "id":"82126110"
            },
            "centroide":{
               "lat":-32.3243031135987,
               "lon":-61.6321111218833
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"María Susana",
               "id":"824114"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"MARIA SUSANA",
            "id":"82126120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"María Susana",
               "id":"82126120"
            },
            "centroide":{
               "lat":-32.2654856687264,
               "lon":-61.9010253248989
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Piamonte",
               "id":"824121"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"PIAMONTE",
            "id":"82126130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Piamonte",
               "id":"82126130"
            },
            "centroide":{
               "lat":-32.1458665241358,
               "lon":-61.9811202181808
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jorge",
               "id":"820315"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"SAN JORGE",
            "id":"82126140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Jorge",
               "id":"82126140"
            },
            "centroide":{
               "lat":-31.898003817155,
               "lon":-61.8603287777206
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Mart",
               "id":"824128"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"SAN MARTIN DE LAS ESCOBAS",
            "id":"82126150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"San Martín de las Escobas",
               "id":"82126150"
            },
            "centroide":{
               "lat":-31.8596737471682,
               "lon":-61.5702157763693
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sastre",
               "id":"820322"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"SASTRE",
            "id":"82126160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Sastre",
               "id":"82126160"
            },
            "centroide":{
               "lat":-31.7695325241636,
               "lon":-61.8294529565696
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Traill",
               "id":"824135"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"TRAILL",
            "id":"82126170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Traill",
               "id":"82126170"
            },
            "centroide":{
               "lat":-31.9225991219581,
               "lon":-61.7024471962412
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colonia Belgrano",
               "id":"824072"
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"82126"
            },
            "nombre":"WILDERMUTH",
            "id":"82126180000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Wildermuth",
               "id":"82126180"
            },
            "centroide":{
               "lat":-31.9469900287545,
               "lon":-61.4025551450022
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Calchaquí",
               "id":"820329"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"CALCHAQUI",
            "id":"82133010000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Calchaquí",
               "id":"82133010"
            },
            "centroide":{
               "lat":-29.8905436040639,
               "lon":-60.285737676357
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cañada Ombú",
               "id":"824142"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"CAÑADA OMBU",
            "id":"82133020000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Cañada Ombú",
               "id":"82133020"
            },
            "centroide":{
               "lat":-28.31083042196,
               "lon":-59.9837166450891
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Intiyaco",
               "id":"824170"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"COLMENA",
            "id":"82133030000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Colmena",
               "id":"82133030"
            },
            "centroide":{
               "lat":-28.7658190432744,
               "lon":-60.0880421430925
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fortín Olmos",
               "id":"824149"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"FORTIN OLMOS",
            "id":"82133040000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Fortín Olmos",
               "id":"82133040"
            },
            "centroide":{
               "lat":-29.0560211845387,
               "lon":-60.4143945851651
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garabato",
               "id":"824156"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"GARABATO",
            "id":"82133050000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Garabato",
               "id":"82133050"
            },
            "centroide":{
               "lat":-28.9553447407242,
               "lon":-60.1384384332405
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Golondrina",
               "id":"824163"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"GOLONDRINA",
            "id":"82133060000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Golondrina",
               "id":"82133060"
            },
            "centroide":{
               "lat":-28.5588709585467,
               "lon":-60.0251919319472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Intiyaco",
               "id":"824170"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"INTIYACO",
            "id":"82133070000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Intiyaco",
               "id":"82133070"
            },
            "centroide":{
               "lat":-28.6779253668564,
               "lon":-60.0724684333926
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garabato",
               "id":"824156"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"KILOMETRO 115",
            "id":"82133080000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Kilómetro 115",
               "id":"82133080"
            },
            "centroide":{
               "lat":-28.8247510907656,
               "lon":-60.2256036446499
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Gallareta",
               "id":"824177"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"LA GALLARETA",
            "id":"82133090000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"La Gallareta",
               "id":"82133090"
            },
            "centroide":{
               "lat":-29.5854889982809,
               "lon":-60.3799393729758
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Amores",
               "id":"824184"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"LOS AMORES",
            "id":"82133100000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Los Amores",
               "id":"82133100"
            },
            "centroide":{
               "lat":-28.1063721680455,
               "lon":-59.9786648326772
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Margarita",
               "id":"824191"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"MARGARITA",
            "id":"82133110000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Margarita",
               "id":"82133110"
            },
            "centroide":{
               "lat":-29.6910879587641,
               "lon":-60.2524652684442
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Fortín Olmos",
               "id":"824149"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"PARAJE 29",
            "id":"82133120000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Paraje 29",
               "id":"82133120"
            },
            "centroide":{
               "lat":-29.1097652045099,
               "lon":-60.2391297738451
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garabato",
               "id":"824156"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"POZO DE LOS INDIOS",
            "id":"82133130000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pozo de los Indios",
               "id":"82133130"
            },
            "centroide":{
               "lat":-28.9451306017322,
               "lon":-60.2520035726174
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vera",
               "id":"820336"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"PUEBLO SANTA LUCIA",
            "id":"82133140000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Pueblo Santa Lucía",
               "id":"82133140"
            },
            "centroide":{
               "lat":-29.2838158063585,
               "lon":-60.4038564051536
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tartagal",
               "id":"824198"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"TARTAGAL",
            "id":"82133150000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Tartagal",
               "id":"82133150"
            },
            "centroide":{
               "lat":-28.6722104503175,
               "lon":-59.8468195282736
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Toba",
               "id":"824205"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"TOBA",
            "id":"82133160000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Toba",
               "id":"82133160"
            },
            "centroide":{
               "lat":-29.2675884279209,
               "lon":-60.1726559005792
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Vera",
               "id":"820336"
            },
            "departamento":{
               "nombre":"Vera",
               "id":"82133"
            },
            "nombre":"VERA",
            "id":"82133170000",
            "provincia":{
               "nombre":"Santa Fe",
               "id":"82"
            },
            "localidad_censal":{
               "nombre":"Vera",
               "id":"82133170"
            },
            "centroide":{
               "lat":-29.4629204507651,
               "lon":-60.2133477841634
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Aguirre",
               "id":"86007"
            },
            "nombre":"ARGENTINA",
            "id":"86007010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Argentina",
               "id":"86007010"
            },
            "centroide":{
               "lat":-29.5347584629763,
               "lon":-62.2668040651927
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Aguirre",
               "id":"86007"
            },
            "nombre":"CASARES",
            "id":"86007020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Casares",
               "id":"86007020"
            },
            "centroide":{
               "lat":-28.9529394000082,
               "lon":-62.8005346895448
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Aguirre",
               "id":"86007"
            },
            "nombre":"MALBRAN",
            "id":"86007030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Malbrán",
               "id":"86007030"
            },
            "centroide":{
               "lat":-29.3480281688774,
               "lon":-62.4374715654784
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Aguirre",
               "id":"86007"
            },
            "nombre":"VILLA GENERAL MITRE",
            "id":"86007040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa General Mitre",
               "id":"86007040"
            },
            "centroide":{
               "lat":-29.1446444838565,
               "lon":-62.6541996550291
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Alberdi",
               "id":"86014"
            },
            "nombre":"CAMPO GALLO",
            "id":"86014010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Campo Gallo",
               "id":"86014010"
            },
            "centroide":{
               "lat":-26.581588722034,
               "lon":-62.8521376221858
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Alberdi",
               "id":"86014"
            },
            "nombre":"CORONEL MANUEL L. RICO",
            "id":"86014020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Coronel Manuel L. Rico",
               "id":"86014020"
            },
            "centroide":{
               "lat":-26.3836121510521,
               "lon":-61.8096460345964
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Alberdi",
               "id":"86014"
            },
            "nombre":"DONADEU",
            "id":"86014030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Donadeu",
               "id":"86014030"
            },
            "centroide":{
               "lat":-26.7266820556935,
               "lon":-62.7208021510899
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Alberdi",
               "id":"86014"
            },
            "nombre":"SACHAYOJ",
            "id":"86014040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Sachayoj",
               "id":"86014040"
            },
            "centroide":{
               "lat":-26.671433914307,
               "lon":-61.8174308800717
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Alberdi",
               "id":"86014"
            },
            "nombre":"SANTOS LUGARES",
            "id":"86014050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Santos Lugares",
               "id":"86014050"
            },
            "centroide":{
               "lat":-26.6920901207094,
               "lon":-63.5584892838706
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Atamisqui",
               "id":"86021"
            },
            "nombre":"ESTACION ATAMISQUI",
            "id":"86021010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Estación Atamisqui",
               "id":"86021010"
            },
            "centroide":{
               "lat":-28.4946171651834,
               "lon":-63.9414267036415
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Atamisqui",
               "id":"86021"
            },
            "nombre":"MEDELLIN",
            "id":"86021020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Medellín",
               "id":"86021020"
            },
            "centroide":{
               "lat":-28.6499385815911,
               "lon":-63.787545374207
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Atamisqui",
               "id":"86021"
            },
            "nombre":"VILLA ATAMISQUI",
            "id":"86021030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Atamisqui",
               "id":"86021030"
            },
            "centroide":{
               "lat":-28.4939256027398,
               "lon":-63.8205160146802
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"86028"
            },
            "nombre":"COLONIA DORA",
            "id":"86028010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Colonia Dora",
               "id":"86028010"
            },
            "centroide":{
               "lat":-28.603116660495,
               "lon":-62.9510729035731
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"86028"
            },
            "nombre":"HERRERA",
            "id":"86028020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Herrera",
               "id":"86028020"
            },
            "centroide":{
               "lat":-28.4852360558267,
               "lon":-63.0697575257717
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"86028"
            },
            "nombre":"ICAÑO",
            "id":"86028030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Icaño",
               "id":"86028030"
            },
            "centroide":{
               "lat":-28.6784711687351,
               "lon":-62.8846263455873
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"86028"
            },
            "nombre":"LUGONES",
            "id":"86028040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Lugones",
               "id":"86028040"
            },
            "centroide":{
               "lat":-28.3346445647532,
               "lon":-63.3436023986024
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"86028"
            },
            "nombre":"REAL SAYANA",
            "id":"86028050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Real Sayana",
               "id":"86028050"
            },
            "centroide":{
               "lat":-28.8166325823721,
               "lon":-62.8455339441355
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Avellaneda",
               "id":"86028"
            },
            "nombre":"VILLA MAILIN",
            "id":"86028060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Mailín",
               "id":"86028060"
            },
            "centroide":{
               "lat":-28.4831042788773,
               "lon":-63.2790903120191
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"ABRA GRANDE",
            "id":"86035010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Abra Grande",
               "id":"86035010"
            },
            "centroide":{
               "lat":-27.2931336131427,
               "lon":-64.3790656722546
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"ANTAJE",
            "id":"86035020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Antajé",
               "id":"86035020"
            },
            "centroide":{
               "lat":-27.6273165422381,
               "lon":-64.252782204773
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"ARDILES",
            "id":"86035030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Ardiles",
               "id":"86035030"
            },
            "centroide":{
               "lat":-27.415006517686,
               "lon":-64.5005841049022
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"CAÑADA ESCOBAR",
            "id":"86035040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Cañada Escobar",
               "id":"86035040"
            },
            "centroide":{
               "lat":-27.7093508468277,
               "lon":-64.0345634947704
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"CHAUPI POZO",
            "id":"86035050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Chaupi Pozo",
               "id":"86035050"
            },
            "centroide":{
               "lat":-27.5090071798518,
               "lon":-64.4230093979117
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"CLODOMIRA",
            "id":"86035060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Clodomira",
               "id":"86035060"
            },
            "centroide":{
               "lat":-27.5762755617113,
               "lon":-64.1322398015217
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"HUYAMAMPA",
            "id":"86035070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Huyamampa",
               "id":"86035070"
            },
            "centroide":{
               "lat":-27.3875685641419,
               "lon":-64.296255139046
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"LA AURORA",
            "id":"86035080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Aurora",
               "id":"86035080"
            },
            "centroide":{
               "lat":-27.4964060720214,
               "lon":-64.2315605665017
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"LA BANDA",
            "id":"86035090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Banda",
               "id":"86035090"
            },
            "centroide":{
               "lat":-27.7339063576954,
               "lon":-64.2389609885924
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"LA DARSENA",
            "id":"86035100000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Dársena",
               "id":"86035100"
            },
            "centroide":{
               "lat":-27.6984439320512,
               "lon":-64.2891593020827
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"LOS QUIROGA",
            "id":"86035110000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Quiroga",
               "id":"86035110"
            },
            "centroide":{
               "lat":-27.6541808200018,
               "lon":-64.3550857252435
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"LOS SORIA",
            "id":"86035120000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Soria",
               "id":"86035120"
            },
            "centroide":{
               "lat":-27.6278438576024,
               "lon":-64.3665478243818
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"SIMBOLAR",
            "id":"86035130000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Simbolar",
               "id":"86035130"
            },
            "centroide":{
               "lat":-27.6504840077902,
               "lon":-64.1912416790425
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"TRAMO 16",
            "id":"86035140000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Tramo 16",
               "id":"86035140"
            },
            "centroide":{
               "lat":-27.6966791825107,
               "lon":-64.171980690304
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Banda",
               "id":"86035"
            },
            "nombre":"TRAMO 20",
            "id":"86035150000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Tramo 20",
               "id":"86035150"
            },
            "centroide":{
               "lat":-27.724169959056,
               "lon":-64.119953835997
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"86042"
            },
            "nombre":"BANDERA",
            "id":"86042010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Bandera",
               "id":"86042010"
            },
            "centroide":{
               "lat":-28.8825052182774,
               "lon":-62.2661992861707
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"86042"
            },
            "nombre":"CUATRO BOCAS",
            "id":"86042020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Cuatro Bocas",
               "id":"86042020"
            },
            "centroide":{
               "lat":-28.8813440964016,
               "lon":-61.8646065822
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"86042"
            },
            "nombre":"FORTIN INCA",
            "id":"86042030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Fortín Inca",
               "id":"86042030"
            },
            "centroide":{
               "lat":-29.1249938081928,
               "lon":-61.9377330722992
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Belgrano",
               "id":"86042"
            },
            "nombre":"GUARDIA ESCOLTA",
            "id":"86042040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Guardia Escolta",
               "id":"86042040"
            },
            "centroide":{
               "lat":-28.9901980198517,
               "lon":-62.1274458413758
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"EL DEAN",
            "id":"86049010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Deán",
               "id":"86049010"
            },
            "centroide":{
               "lat":-27.7173434793387,
               "lon":-64.3318930408752
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"EL MOJON",
            "id":"86049020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Mojón",
               "id":"86049020"
            },
            "centroide":{
               "lat":-27.9899319139286,
               "lon":-64.2133551714841
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"EL ZANJON",
            "id":"86049030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Zanjón",
               "id":"86049030"
            },
            "centroide":{
               "lat":-27.8750408217321,
               "lon":-64.2433909658935
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"LOS CARDOZOS",
            "id":"86049040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Cardozos",
               "id":"86049040"
            },
            "centroide":{
               "lat":-27.9125126696643,
               "lon":-64.1953803450085
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"MACO",
            "id":"86049050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Maco",
               "id":"86049050"
            },
            "centroide":{
               "lat":-27.8684490761323,
               "lon":-64.2176846841573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"MAQUITO",
            "id":"86049060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Maquito",
               "id":"86049060"
            },
            "centroide":{
               "lat":-27.883071657444,
               "lon":-64.2103645086341
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"MORALES",
            "id":"86049070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Morales",
               "id":"86049070"
            },
            "centroide":{
               "lat":-27.681136956593,
               "lon":-64.3628119718648
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"PUESTO DE SAN ANTONIO",
            "id":"86049080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Puesto de San Antonio",
               "id":"86049080"
            },
            "centroide":{
               "lat":-27.6663887276734,
               "lon":-64.3770291350394
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"SAN PEDRO",
            "id":"86049090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"86049090"
            },
            "centroide":{
               "lat":-27.9463558793645,
               "lon":-64.1638901235678
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"SANTA MARIA",
            "id":"86049100000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Santa María",
               "id":"86049100"
            },
            "centroide":{
               "lat":-27.9515163858504,
               "lon":-64.2196924435025
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"SANTIAGO DEL ESTERO",
            "id":"86049110000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Santiago del Estero",
               "id":"86049110"
            },
            "centroide":{
               "lat":-27.7906472093484,
               "lon":-64.2622741290181
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"VUELTA DE LA BARRANCA",
            "id":"86049120000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Vuelta de la Barranca",
               "id":"86049120"
            },
            "centroide":{
               "lat":-27.8762024322533,
               "lon":-64.1878789215783
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Capital",
               "id":"86049"
            },
            "nombre":"YANDA",
            "id":"86049130000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Yanda",
               "id":"86049130"
            },
            "centroide":{
               "lat":-27.9106239556579,
               "lon":-64.2300554961573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"EL CABURE",
            "id":"86056010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Caburé",
               "id":"86056010"
            },
            "centroide":{
               "lat":-26.0165513217409,
               "lon":-62.3330208736259
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"LA FIRMEZA",
            "id":"86056020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Firmeza",
               "id":"86056020"
            },
            "centroide":{
               "lat":-25.9734494403489,
               "lon":-63.121334820867
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"LOS PIRPINTOS",
            "id":"86056030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Pirpintos",
               "id":"86056030"
            },
            "centroide":{
               "lat":-26.1336342552401,
               "lon":-62.0623998821941
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"LOS TIGRES",
            "id":"86056040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Tigres",
               "id":"86056040"
            },
            "centroide":{
               "lat":-25.9094984239476,
               "lon":-62.5920547989978
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"MONTE QUEMADO",
            "id":"86056050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Monte Quemado",
               "id":"86056050"
            },
            "centroide":{
               "lat":-25.8055238661729,
               "lon":-62.8421851386474
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"NUEVA ESPERANZA",
            "id":"86056060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Nueva Esperanza",
               "id":"86056060"
            },
            "centroide":{
               "lat":-26.033847347527,
               "lon":-63.3180059411213
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"PAMPA DE LOS GUANACOS",
            "id":"86056070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Pampa de los Guanacos",
               "id":"86056070"
            },
            "centroide":{
               "lat":-26.2344065294949,
               "lon":-61.8376679266549
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"SAN JOSE DEL BOQUERON",
            "id":"86056080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"San José del Boquerón",
               "id":"86056080"
            },
            "centroide":{
               "lat":-26.1203999513037,
               "lon":-63.7053351206531
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Copo",
               "id":"86056"
            },
            "nombre":"URUTAU",
            "id":"86056090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Urutaú",
               "id":"86056090"
            },
            "centroide":{
               "lat":-25.7123774702614,
               "lon":-63.041276297827
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"ANCAJAN",
            "id":"86063010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Ancaján",
               "id":"86063010"
            },
            "centroide":{
               "lat":-28.4309064612063,
               "lon":-64.9233001766413
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"CHOYA",
            "id":"86063020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Choya",
               "id":"86063020"
            },
            "centroide":{
               "lat":-28.4942404547901,
               "lon":-64.8563028233751
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"ESTACION LA PUNTA",
            "id":"86063030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Estación La Punta",
               "id":"86063030"
            },
            "centroide":{
               "lat":-28.4119636214997,
               "lon":-64.7564290050951
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"FRIAS",
            "id":"86063040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Frías",
               "id":"86063040"
            },
            "centroide":{
               "lat":-28.6399178570768,
               "lon":-65.130637009131
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"LAPRIDA",
            "id":"86063050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Laprida",
               "id":"86063050"
            },
            "centroide":{
               "lat":-28.3756319929951,
               "lon":-64.5305517169124
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"SAN PEDRO",
            "id":"86063070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"86063070"
            },
            "centroide":{
               "lat":-28.4601109681789,
               "lon":-64.8665614531529
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":null,
               "id":null
            },
            "nombre":"CIUDAD DE BUENOS AIRES",
            "id":"02000010000",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6132917290185,
               "lon":-58.3887517365082
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"CARHUE",
            "id":"06007010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Carhué",
               "id":"06007010"
            },
            "centroide":{
               "lat":-37.1774801030509,
               "lon":-62.7578962604015
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"COLONIA SAN MIGUEL ARCANGEL",
            "id":"06007020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Colonia San Miguel Arcángel",
               "id":"06007020"
            },
            "centroide":{
               "lat":-37.4486186469627,
               "lon":-63.117609405389
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"DELFIN HUERGO",
            "id":"06007030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Delfín Huergo",
               "id":"06007030"
            },
            "centroide":{
               "lat":-37.3173292631958,
               "lon":-63.2331690622102
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"ESPARTILLAR",
            "id":"06007040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Espartillar",
               "id":"06007040"
            },
            "centroide":{
               "lat":-37.3563345638716,
               "lon":-62.4387637810214
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"ESTEBAN AGUSTIN GASCON",
            "id":"06007050000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Esteban Agustín Gascón",
               "id":"06007050"
            },
            "centroide":{
               "lat":-37.4544378016288,
               "lon":-63.2565950033782
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"LA PALA",
            "id":"06007060000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"La Pala",
               "id":"06007060"
            },
            "centroide":{
               "lat":-36.6613400852941,
               "lon":-63.3661281675236
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"MAZA",
            "id":"06007070000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Maza",
               "id":"06007070"
            },
            "centroide":{
               "lat":-36.7999468137731,
               "lon":-63.3385151506224
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"RIVERA",
            "id":"06007080000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Rivera",
               "id":"06007080"
            },
            "centroide":{
               "lat":-37.1583546113813,
               "lon":-63.2442194943605
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"VILLA MARGARITA",
            "id":"06007100000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Villa Margarita",
               "id":"06007100"
            },
            "centroide":{
               "lat":-37.4600992847959,
               "lon":-63.2405658599852
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Alsina",
               "id":"060007"
            },
            "departamento":{
               "nombre":"Adolfo Alsina",
               "id":"06007"
            },
            "nombre":"YUTUYACO",
            "id":"06007110000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Yutuyaco",
               "id":"06007110"
            },
            "centroide":{
               "lat":-36.9884309445004,
               "lon":-63.133808887439
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"060014"
            },
            "departamento":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"06014"
            },
            "nombre":"ADOLFO GONZALES CHAVES",
            "id":"06014010000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"06014010"
            },
            "centroide":{
               "lat":-38.0333995087596,
               "lon":-60.1003341740637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"060014"
            },
            "departamento":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"06014"
            },
            "nombre":"DE LA GARMA",
            "id":"06014020000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"De La Garma",
               "id":"06014020"
            },
            "centroide":{
               "lat":-37.9635021758562,
               "lon":-60.415659383962
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"060014"
            },
            "departamento":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"06014"
            },
            "nombre":"JUAN E. BARRA",
            "id":"06014030000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Juan E. Barra",
               "id":"06014030"
            },
            "centroide":{
               "lat":-37.8233871716044,
               "lon":-60.484639770555
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"060014"
            },
            "departamento":{
               "nombre":"Adolfo Gonzales Chaves",
               "id":"06014"
            },
            "nombre":"VASQUEZ",
            "id":"06014040000",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"Vásquez",
               "id":"06014040"
            },
            "centroide":{
               "lat":-38.1766861391835,
               "lon":-60.1708487303777
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"TAPSO",
            "id":"86063080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Tapso",
               "id":"86063080"
            },
            "centroide":{
               "lat":-28.4037795215625,
               "lon":-65.0964321177706
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Choya",
               "id":"86063"
            },
            "nombre":"VILLA LA PUNTA",
            "id":"86063090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa La Punta",
               "id":"86063090"
            },
            "centroide":{
               "lat":-28.371365281259,
               "lon":-64.7921192520059
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"BANDERA BAJADA",
            "id":"86070010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Bandera Bajada",
               "id":"86070010"
            },
            "centroide":{
               "lat":-27.2724258053036,
               "lon":-63.5140111288855
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"CASPI CORRAL",
            "id":"86070020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Caspi Corral",
               "id":"86070020"
            },
            "centroide":{
               "lat":-27.3907597837259,
               "lon":-63.5489564711117
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"COLONIA SAN JUAN",
            "id":"86070030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Colonia San Juan",
               "id":"86070030"
            },
            "centroide":{
               "lat":-27.617543825893,
               "lon":-63.301877607639
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"EL CRUCERO",
            "id":"86070040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Crucero",
               "id":"86070040"
            },
            "centroide":{
               "lat":-27.5779248328345,
               "lon":-63.3309305482062
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"KILOMETRO 30",
            "id":"86070050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Kilómetro 30",
               "id":"86070050"
            },
            "centroide":{
               "lat":-27.3845037887346,
               "lon":-63.5296772428548
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"LA CAÑADA",
            "id":"86070060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Cañada",
               "id":"86070060"
            },
            "centroide":{
               "lat":-27.7113503879057,
               "lon":-63.776465678451
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"LA INVERNADA",
            "id":"86070070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Invernada",
               "id":"86070070"
            },
            "centroide":{
               "lat":-27.3853384819687,
               "lon":-63.4863113485716
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"MINERVA",
            "id":"86070080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Minerva",
               "id":"86070080"
            },
            "centroide":{
               "lat":-27.5383379504139,
               "lon":-63.3844467629454
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"VACA HUAÑUNA",
            "id":"86070090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Vaca Huañuna",
               "id":"86070090"
            },
            "centroide":{
               "lat":-27.4744910479525,
               "lon":-63.4699371865895
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Figueroa",
               "id":"86070"
            },
            "nombre":"VILLA FIGUEROA",
            "id":"86070100000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Figueroa",
               "id":"86070100"
            },
            "centroide":{
               "lat":-27.7220223636284,
               "lon":-63.5077024611833
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Taboada",
               "id":"86077"
            },
            "nombre":"AÑATUYA",
            "id":"86077010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Añatuya",
               "id":"86077010"
            },
            "centroide":{
               "lat":-28.4645613476513,
               "lon":-62.8371553125604
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Taboada",
               "id":"86077"
            },
            "nombre":"AVERIAS",
            "id":"86077020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Averías",
               "id":"86077020"
            },
            "centroide":{
               "lat":-28.7471563597272,
               "lon":-62.4500332099209
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Taboada",
               "id":"86077"
            },
            "nombre":"ESTACION TACAÑITAS",
            "id":"86077030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Estación Tacañitas",
               "id":"86077030"
            },
            "centroide":{
               "lat":-28.625400373791,
               "lon":-62.6050049232664
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Taboada",
               "id":"86077"
            },
            "nombre":"LA NENA",
            "id":"86077040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Nena",
               "id":"86077040"
            },
            "centroide":{
               "lat":-28.4600119281847,
               "lon":-61.8401411379322
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Taboada",
               "id":"86077"
            },
            "nombre":"LOS JURIES",
            "id":"86077050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Juríes",
               "id":"86077050"
            },
            "centroide":{
               "lat":-28.4678989822603,
               "lon":-62.1097288458217
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"General Taboada",
               "id":"86077"
            },
            "nombre":"TOMAS YOUNG",
            "id":"86077060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Tomás Young",
               "id":"86077060"
            },
            "centroide":{
               "lat":-28.6023457181773,
               "lon":-62.1835145984131
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"100217"
            },
            "departamento":{
               "nombre":"Guasayán",
               "id":"86084"
            },
            "nombre":"LAVALLE",
            "id":"86084010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Lavalle",
               "id":"86084010"
            },
            "centroide":{
               "lat":-28.1989652184744,
               "lon":-65.1121667400926
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa",
               "id":"100217"
            },
            "departamento":{
               "nombre":"Guasayán",
               "id":"86084"
            },
            "nombre":"SAN PEDRO",
            "id":"86084020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"86084020"
            },
            "centroide":{
               "lat":-27.9573058480013,
               "lon":-65.1704506415815
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"EL ARENAL",
            "id":"86091005000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Arenal",
               "id":"86091005"
            },
            "centroide":{
               "lat":-26.7716728234233,
               "lon":-64.6015462356661
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"EL BOBADAL",
            "id":"86091010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Bobadal",
               "id":"86091010"
            },
            "centroide":{
               "lat":-26.7184011548025,
               "lon":-64.3982906362897
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"EL CHARCO",
            "id":"86091020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Charco",
               "id":"86091020"
            },
            "centroide":{
               "lat":-27.2257680209403,
               "lon":-64.7003027141644
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"EL RINCON",
            "id":"86091030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Rincón",
               "id":"86091030"
            },
            "centroide":{
               "lat":-26.7333694233426,
               "lon":-64.4768351977429
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"GRAMILLA",
            "id":"86091040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Gramilla",
               "id":"86091040"
            },
            "centroide":{
               "lat":-27.2971935237082,
               "lon":-64.61075364749
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"LILO VIEJO",
            "id":"86119070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Lilo Viejo",
               "id":"86119070"
            },
            "centroide":{
               "lat":-26.9408021196944,
               "lon":-62.9565689431121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Miraflores",
               "id":"220189"
            },
            "departamento":{
               "nombre":"General Güemes",
               "id":"22063"
            },
            "nombre":"MIRAFLORES",
            "id":"22063070000",
            "provincia":{
               "nombre":"Chaco",
               "id":"22"
            },
            "localidad_censal":{
               "nombre":"Miraflores",
               "id":"22063070"
            },
            "centroide":{
               "lat":-25.6489334968628,
               "lon":-60.9300401901344
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Albardón",
               "id":"700007"
            },
            "departamento":{
               "nombre":"Albardón",
               "id":"70007"
            },
            "nombre":"LA CAÑADA",
            "id":"70007020002",
            "provincia":{
               "nombre":"San Juan",
               "id":"70"
            },
            "localidad_censal":{
               "nombre":"Villa General San Martín - Campo Afuera",
               "id":"70007020"
            },
            "centroide":{
               "lat":-31.4475643930837,
               "lon":-68.4817213288378
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"ISCA YACU",
            "id":"86091050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Isca Yacu",
               "id":"86091050"
            },
            "centroide":{
               "lat":-27.0297706094062,
               "lon":-64.6107284533432
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"ISCA YACU SEMAUL",
            "id":"86091060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Isca Yacu Semaul",
               "id":"86091060"
            },
            "centroide":{
               "lat":-27.033281523993,
               "lon":-64.6122143434088
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"POZO HONDO",
            "id":"86091070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Pozo Hondo",
               "id":"86091070"
            },
            "centroide":{
               "lat":-27.164621631919,
               "lon":-64.483386426973
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Jiménez",
               "id":"86091"
            },
            "nombre":"SAN PEDRO",
            "id":"86091080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"San Pedro",
               "id":"86091080"
            },
            "centroide":{
               "lat":-26.7405309182605,
               "lon":-64.3965511764775
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan F. Ibarra",
               "id":"86098"
            },
            "nombre":"EL COLORADO",
            "id":"86098010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Colorado",
               "id":"86098010"
            },
            "centroide":{
               "lat":-27.916852444404,
               "lon":-62.1779644703931
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan F. Ibarra",
               "id":"86098"
            },
            "nombre":"EL CUADRADO",
            "id":"86098020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Cuadrado",
               "id":"86098020"
            },
            "centroide":{
               "lat":-28.2978508912148,
               "lon":-61.8013595132333
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan F. Ibarra",
               "id":"86098"
            },
            "nombre":"MATARA",
            "id":"86098030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Matará",
               "id":"86098030"
            },
            "centroide":{
               "lat":-28.110413912625,
               "lon":-63.1950712509572
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan F. Ibarra",
               "id":"86098"
            },
            "nombre":"SUNCHO CORRAL",
            "id":"86098040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Suncho Corral",
               "id":"86098040"
            },
            "centroide":{
               "lat":-27.9373441459042,
               "lon":-63.4286561024091
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan F. Ibarra",
               "id":"86098"
            },
            "nombre":"VILELAS",
            "id":"86098050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Vilelas",
               "id":"86098050"
            },
            "centroide":{
               "lat":-27.9593446946828,
               "lon":-62.6095801174635
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Juan F. Ibarra",
               "id":"86098"
            },
            "nombre":"YUCHAN",
            "id":"86098060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Yuchán",
               "id":"86098060"
            },
            "centroide":{
               "lat":-27.7812827220804,
               "lon":-62.9762834233876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Loreto",
               "id":"86105"
            },
            "nombre":"VILLA SAN MARTIN",
            "id":"86105010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa San Martín (Est. Loreto)",
               "id":"86105010"
            },
            "centroide":{
               "lat":-28.3039639606794,
               "lon":-64.1851457926154
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Mitre",
               "id":"86112"
            },
            "nombre":"VILLA UNION",
            "id":"86112010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Unión",
               "id":"86112010"
            },
            "centroide":{
               "lat":-29.4169970813328,
               "lon":-62.7903866994546
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"AEROLITO",
            "id":"86119010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Aerolito",
               "id":"86119010"
            },
            "centroide":{
               "lat":-27.2367908165246,
               "lon":-62.3796548631942
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"ALHUAMPA",
            "id":"86119020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Alhuampa",
               "id":"86119020"
            },
            "centroide":{
               "lat":-27.1327719671086,
               "lon":-62.5491667779808
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"HASSE",
            "id":"86119030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Hasse",
               "id":"86119030"
            },
            "centroide":{
               "lat":-27.0720923042891,
               "lon":-62.6462009108307
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"HERNAN MEJIA MIRAVAL",
            "id":"86119040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Hernán Mejía Miraval",
               "id":"86119040"
            },
            "centroide":{
               "lat":-27.1785919494814,
               "lon":-62.4685537207535
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"LAS TINAJAS",
            "id":"86119050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Las Tinajas",
               "id":"86119050"
            },
            "centroide":{
               "lat":-27.4618186135826,
               "lon":-62.9188082355465
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"LIBERTAD",
            "id":"86119060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Libertad",
               "id":"86119060"
            },
            "centroide":{
               "lat":-27.076215168082,
               "lon":-63.0708907011302
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"PATAY",
            "id":"86119080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Patay",
               "id":"86119080"
            },
            "centroide":{
               "lat":-26.843267415023,
               "lon":-62.9328920767785
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"PUEBLO PABLO TORELO",
            "id":"86119090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Pueblo Pablo Torelo",
               "id":"86119090"
            },
            "centroide":{
               "lat":-27.3309034121122,
               "lon":-62.2263991173581
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"QUIMILI",
            "id":"86119100000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Quimili",
               "id":"86119100"
            },
            "centroide":{
               "lat":-27.6502221618861,
               "lon":-62.4162413545379
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"ROVERSI",
            "id":"86119110000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Roversi",
               "id":"86119110"
            },
            "centroide":{
               "lat":-27.5934675544521,
               "lon":-61.9454404681047
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"TINTINA",
            "id":"86119120000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Tintina",
               "id":"86119120"
            },
            "centroide":{
               "lat":-27.0269428383778,
               "lon":-62.7014758111564
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Moreno",
               "id":"86119"
            },
            "nombre":"WEISBURD",
            "id":"86119130000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Weisburd",
               "id":"86119130"
            },
            "centroide":{
               "lat":-27.3177468248599,
               "lon":-62.5883450951591
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ojo de Agua",
               "id":"86126"
            },
            "nombre":"EL 49",
            "id":"86126010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El 49",
               "id":"86126010"
            },
            "centroide":{
               "lat":-29.0559761149567,
               "lon":-63.9559623784872
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ojo de Agua",
               "id":"86126"
            },
            "nombre":"SOL DE JULIO",
            "id":"86126020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Sol de Julio",
               "id":"86126020"
            },
            "centroide":{
               "lat":-29.562340331816,
               "lon":-63.4575916015313
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ojo de Agua",
               "id":"86126"
            },
            "nombre":"VILLA OJO DE AGUA",
            "id":"86126030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Ojo de Agua",
               "id":"86126030"
            },
            "centroide":{
               "lat":-29.5029027910505,
               "lon":-63.6939738398896
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"86133"
            },
            "nombre":"EL MOJON",
            "id":"86133010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Mojón",
               "id":"86133010"
            },
            "centroide":{
               "lat":-26.094177918867,
               "lon":-64.3077511775358
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"86133"
            },
            "nombre":"LAS DELICIAS",
            "id":"86133020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Las Delicias",
               "id":"86133020"
            },
            "centroide":{
               "lat":-26.6821239251425,
               "lon":-64.0011052202083
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"86133"
            },
            "nombre":"NUEVA ESPERANZA",
            "id":"86133030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Nueva Esperanza",
               "id":"86133030"
            },
            "centroide":{
               "lat":-26.2029493632974,
               "lon":-64.2386862818792
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"86133"
            },
            "nombre":"POZO BETBEDER",
            "id":"86133040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Pozo Betbeder",
               "id":"86133040"
            },
            "centroide":{
               "lat":-26.4117975451662,
               "lon":-64.3406016723268
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"86133"
            },
            "nombre":"RAPELLI",
            "id":"86133050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Rapelli",
               "id":"86133050"
            },
            "centroide":{
               "lat":-26.3973763982116,
               "lon":-64.5044626210659
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Pellegrini",
               "id":"86133"
            },
            "nombre":"SANTO DOMINGO",
            "id":"86133060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Santo Domingo",
               "id":"86133060"
            },
            "centroide":{
               "lat":-26.2295353888576,
               "lon":-63.7775041035997
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Quebrachos",
               "id":"86140"
            },
            "nombre":"RAMIREZ DE VELAZCO",
            "id":"86140010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Ramírez de Velazco",
               "id":"86140010"
            },
            "centroide":{
               "lat":-29.2346591274202,
               "lon":-63.4745902285707
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Quebrachos",
               "id":"86140"
            },
            "nombre":"SUMAMPA",
            "id":"86140020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Sumampa",
               "id":"86140020"
            },
            "centroide":{
               "lat":-29.3856768376799,
               "lon":-63.4739812028011
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Quebrachos",
               "id":"86140"
            },
            "nombre":"SUMAMPA VIEJO",
            "id":"86140030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Sumampa Viejo",
               "id":"86140030"
            },
            "centroide":{
               "lat":-29.3873857117046,
               "lon":-63.4413442606189
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"CHAÑAR POZO DE ABAJO",
            "id":"86147010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Chañar Pozo de Abajo",
               "id":"86147010"
            },
            "centroide":{
               "lat":-27.566815560625,
               "lon":-64.6802356354788
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"CHAUCHILLAS",
            "id":"86147020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Chauchillas",
               "id":"86147020"
            },
            "centroide":{
               "lat":-27.5214742780514,
               "lon":-64.5558862404344
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"COLONIA TINCO",
            "id":"86147030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Colonia Tinco",
               "id":"86147030"
            },
            "centroide":{
               "lat":-27.4319202445614,
               "lon":-64.9313037647628
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"EL CHARCO",
            "id":"86147040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Charco",
               "id":"86147040"
            },
            "centroide":{
               "lat":-27.2348881521517,
               "lon":-64.697822026062
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"GRAMILLA",
            "id":"86147050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Gramilla",
               "id":"86147050"
            },
            "centroide":{
               "lat":-27.3020072164497,
               "lon":-64.6130855140247
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"LA NUEVA DONOSA",
            "id":"86147060000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"La Nueva Donosa",
               "id":"86147060"
            },
            "centroide":{
               "lat":-27.4563912240441,
               "lon":-64.9277000920229
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"LOS MIRANDA",
            "id":"86147070000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Miranda",
               "id":"86147070"
            },
            "centroide":{
               "lat":-27.4719466367193,
               "lon":-64.615993605497
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"LOS NUÑEZ",
            "id":"86147080000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Núñez",
               "id":"86147080"
            },
            "centroide":{
               "lat":-27.5338396475268,
               "lon":-64.5310863310616
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"MANSUPA",
            "id":"86147090000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Mansupa",
               "id":"86147090"
            },
            "centroide":{
               "lat":-27.4611344607229,
               "lon":-64.9065546199272
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"POZUELOS",
            "id":"86147100000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Pozuelos",
               "id":"86147100"
            },
            "centroide":{
               "lat":-27.3041200176408,
               "lon":-64.7530797214091
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"RODEO DE VALDEZ",
            "id":"86147110000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Rodeo de Valdez",
               "id":"86147110"
            },
            "centroide":{
               "lat":-27.5527256617805,
               "lon":-64.5111174447533
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"SAUZAL",
            "id":"86147120000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"El Sauzal",
               "id":"86147120"
            },
            "centroide":{
               "lat":-27.491671176486,
               "lon":-64.6013992349245
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"TERMAS DE RIO HONDO",
            "id":"86147130000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Termas de Río Hondo",
               "id":"86147130"
            },
            "centroide":{
               "lat":-27.5012564484806,
               "lon":-64.8552586634364
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"VILLA GIMENEZ",
            "id":"86147140000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Giménez",
               "id":"86147140"
            },
            "centroide":{
               "lat":-27.574990170048,
               "lon":-64.4753061878465
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"VILLA RIO HONDO",
            "id":"86147150000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Río Hondo",
               "id":"86147150"
            },
            "centroide":{
               "lat":-27.5983201543603,
               "lon":-64.8739939701803
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"VILLA TURISTICA DEL EMBALSE",
            "id":"86147160000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Turística del Embalse de Rio Hondo",
               "id":"86147160"
            },
            "centroide":{
               "lat":-27.516849315862,
               "lon":-64.9027287200084
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Río Hondo",
               "id":"86147"
            },
            "nombre":"VINARA",
            "id":"86147170000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Vinará",
               "id":"86147170"
            },
            "centroide":{
               "lat":-27.379706365694,
               "lon":-64.7967473078135
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"86154"
            },
            "nombre":"COLONIA ALPINA",
            "id":"86154010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Colonia Alpina",
               "id":"86154010"
            },
            "centroide":{
               "lat":-30.0603796584752,
               "lon":-62.1051312621272
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"86154"
            },
            "nombre":"PALO NEGRO",
            "id":"86154020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Palo Negro",
               "id":"86154020"
            },
            "centroide":{
               "lat":-29.6773076972314,
               "lon":-62.1374427216007
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Rivadavia",
               "id":"86154"
            },
            "nombre":"SELVA",
            "id":"86154030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Selva",
               "id":"86154030"
            },
            "centroide":{
               "lat":-29.7604898000173,
               "lon":-62.0525063759069
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Robles",
               "id":"86161"
            },
            "nombre":"BELTRAN",
            "id":"86161010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Beltrán",
               "id":"86161010"
            },
            "centroide":{
               "lat":-27.8315913828113,
               "lon":-64.061379252439
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Robles",
               "id":"86161"
            },
            "nombre":"COLONIA EL SIMBOLAR",
            "id":"86161020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Colonia El Simbolar",
               "id":"86161020"
            },
            "centroide":{
               "lat":-27.7226465873966,
               "lon":-63.8598770739209
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Robles",
               "id":"86161"
            },
            "nombre":"FERNANDEZ",
            "id":"86161030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Fernández",
               "id":"86161030"
            },
            "centroide":{
               "lat":-27.9241249979993,
               "lon":-63.8937760389577
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Robles",
               "id":"86161"
            },
            "nombre":"INGENIERO FORRES",
            "id":"86161040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Ingeniero Forres",
               "id":"86161040"
            },
            "centroide":{
               "lat":-27.8774908793598,
               "lon":-63.9791359214751
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Robles",
               "id":"86161"
            },
            "nombre":"VILMER",
            "id":"86161050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Vilmer",
               "id":"86161050"
            },
            "centroide":{
               "lat":-27.7878023282581,
               "lon":-64.1510014560828
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Salavina",
               "id":"86168"
            },
            "nombre":"CHILCA JULIANA",
            "id":"86168010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Chilca Juliana",
               "id":"86168010"
            },
            "centroide":{
               "lat":-28.796459373074,
               "lon":-63.5791247989506
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Salavina",
               "id":"86168"
            },
            "nombre":"LOS TELARES",
            "id":"86168020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Los Telares",
               "id":"86168020"
            },
            "centroide":{
               "lat":-28.9858790620734,
               "lon":-63.4474428106687
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Salavina",
               "id":"86168"
            },
            "nombre":"VILLA SALAVINA",
            "id":"86168030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Salavina",
               "id":"86168030"
            },
            "centroide":{
               "lat":-28.804933488379,
               "lon":-63.4286953351842
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"86175"
            },
            "nombre":"BREA POZO",
            "id":"86175010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Brea Pozo",
               "id":"86175010"
            },
            "centroide":{
               "lat":-28.2433820721478,
               "lon":-63.9464098988816
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"86175"
            },
            "nombre":"ESTACION ROBLES",
            "id":"86175020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Estación Robles",
               "id":"86175020"
            },
            "centroide":{
               "lat":-28.0511950311521,
               "lon":-63.9907281128401
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"86175"
            },
            "nombre":"ESTACION TABOADA",
            "id":"86175030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Estación Taboada",
               "id":"86175030"
            },
            "centroide":{
               "lat":-28.0088248181919,
               "lon":-63.7454952959481
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"San Martín",
               "id":"86175"
            },
            "nombre":"VILLA NUEVA",
            "id":"86175040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Nueva",
               "id":"86175040"
            },
            "centroide":{
               "lat":-28.3143322879508,
               "lon":-63.9957666086631
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Sarmiento",
               "id":"86182"
            },
            "nombre":"GARZA",
            "id":"86182010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Garza",
               "id":"86182010"
            },
            "centroide":{
               "lat":-28.1529581822136,
               "lon":-63.5345160915478
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Silípica",
               "id":"86189"
            },
            "nombre":"ARRAGA",
            "id":"86189010000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Árraga",
               "id":"86189010"
            },
            "centroide":{
               "lat":-28.0530238668842,
               "lon":-64.2227939749583
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Silípica",
               "id":"86189"
            },
            "nombre":"NUEVA FRANCIA",
            "id":"86189020000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Nueva Francia",
               "id":"86189020"
            },
            "centroide":{
               "lat":-28.1833426920499,
               "lon":-64.1968111536257
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Silípica",
               "id":"86189"
            },
            "nombre":"SIMBOL",
            "id":"86189030000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Simbol",
               "id":"86189030"
            },
            "centroide":{
               "lat":-28.1030540788067,
               "lon":-64.2130484994317
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Silípica",
               "id":"86189"
            },
            "nombre":"SUMAMAO",
            "id":"86189040000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Sumamao",
               "id":"86189040"
            },
            "centroide":{
               "lat":-28.1717134352764,
               "lon":-64.101040971413
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Silípica",
               "id":"86189"
            },
            "nombre":"VILLA SILIPICA",
            "id":"86189050000",
            "provincia":{
               "nombre":"Santiago del Estero",
               "id":"86"
            },
            "localidad_censal":{
               "nombre":"Villa Silípica",
               "id":"86189050"
            },
            "centroide":{
               "lat":-28.1105007595588,
               "lon":-64.1476921495566
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Naranjo y el Sunchal",
               "id":"908021"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"BARRIO SAN JORGE",
            "id":"90007010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio San Jorge",
               "id":"90007010"
            },
            "centroide":{
               "lat":-26.6843396000243,
               "lon":-65.0481349066456
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Chañar",
               "id":"908014"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"EL CHAÑAR",
            "id":"90007020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Chañar",
               "id":"90007020"
            },
            "centroide":{
               "lat":-26.7583605607957,
               "lon":-65.0693888917573
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Naranjo y el Sunchal",
               "id":"908021"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"EL NARANJO",
            "id":"90007030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Naranjo",
               "id":"90007030"
            },
            "centroide":{
               "lat":-26.6571859218044,
               "lon":-65.0483602838482
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Garmendia",
               "id":"908042"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"GARMENDIA",
            "id":"90007040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Garmendia",
               "id":"90007040"
            },
            "centroide":{
               "lat":-26.5736893005766,
               "lon":-64.5576861101138
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Ramada y la Cruz",
               "id":"908056"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"LA RAMADA",
            "id":"90007050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"La Ramada",
               "id":"90007050"
            },
            "centroide":{
               "lat":-26.6881473308745,
               "lon":-64.9464369337093
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Chañar",
               "id":"908014"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"MACOMITAS",
            "id":"90007060000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Macomitas",
               "id":"90007060"
            },
            "centroide":{
               "lat":-26.7303436942447,
               "lon":-65.0113600491402
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Gdor. Piedrabuena",
               "id":"908049"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"PIEDRABUENA",
            "id":"90007070000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Piedrabuena",
               "id":"90007070"
            },
            "centroide":{
               "lat":-26.7398606901892,
               "lon":-64.6493040222716
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"7 de Abril",
               "id":"908007"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"7 DE ABRIL",
            "id":"90007080000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"7 de Abril",
               "id":"90007080"
            },
            "centroide":{
               "lat":-26.2918847472245,
               "lon":-64.5005128631092
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Benjamin Araoz y el Tajamar",
               "id":"908063"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"VILLA BENJAMIN ARAOZ",
            "id":"90007090000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Benjamín Aráoz",
               "id":"90007090"
            },
            "centroide":{
               "lat":-26.5561603640592,
               "lon":-64.7983838278956
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Burruyacu",
               "id":"900007"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"VILLA BURRUYACU",
            "id":"90007100000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Burruyacú",
               "id":"90007100"
            },
            "centroide":{
               "lat":-26.5003323203292,
               "lon":-64.7419938212453
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Padre Monti",
               "id":"908070"
            },
            "departamento":{
               "nombre":"Burruyacú",
               "id":"90007"
            },
            "nombre":"VILLA PADRE MONTI",
            "id":"90007110000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Padre Monti",
               "id":"90007110"
            },
            "centroide":{
               "lat":-26.5064025936102,
               "lon":-64.9998985377047
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Alderetes",
               "id":"900021"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"ALDERETES",
            "id":"90014010001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Alderetes",
               "id":"90014010"
            },
            "centroide":{
               "lat":-26.8220641277209,
               "lon":-65.1385924038502
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Alderetes",
               "id":"900021"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"EL CORTE",
            "id":"90014010002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Alderetes",
               "id":"90014010"
            },
            "centroide":{
               "lat":-26.8184460052552,
               "lon":-65.1556778939315
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Alderetes",
               "id":"900021"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"LOS GUTIERREZ",
            "id":"90014010003",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Alderetes",
               "id":"90014010"
            },
            "centroide":{
               "lat":-26.80204642135,
               "lon":-65.1258766860824
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Banda del Rio Sali",
               "id":"900028"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"BANDA DEL RIO SALI",
            "id":"90014020001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Banda del Río Salí",
               "id":"90014020"
            },
            "centroide":{
               "lat":-26.8438678881267,
               "lon":-65.1632837939025
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Banda del Rio Sali",
               "id":"900028"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"BARRIO AEROPUERTO",
            "id":"90014020002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Banda del Río Salí",
               "id":"90014020"
            },
            "centroide":{
               "lat":-26.8498779490703,
               "lon":-65.1293137289629
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Banda del Rio Sali",
               "id":"900028"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"LASTENIA",
            "id":"90014020003",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Banda del Río Salí",
               "id":"90014020"
            },
            "centroide":{
               "lat":-26.8621611908602,
               "lon":-65.1625825810305
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colombres",
               "id":"908077"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"COLOMBRES",
            "id":"90014040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Colombres",
               "id":"90014040"
            },
            "centroide":{
               "lat":-26.8835749144059,
               "lon":-65.0999411428557
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Perez",
               "id":"908133"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"COLONIA MAYO - BARRIO LA MILAGROSA",
            "id":"90014050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Colonia Mayo - Barrio La Milagrosa",
               "id":"90014050"
            },
            "centroide":{
               "lat":-26.8339561201266,
               "lon":-64.9893799370396
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Delfin Gallo",
               "id":"908084"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"EL PARAISO",
            "id":"90014060001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Delfín Gallo",
               "id":"90014060"
            },
            "centroide":{
               "lat":-26.8346473974393,
               "lon":-65.0955588658241
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Delfin Gallo",
               "id":"908084"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"EX INGENIO ESPERANZA",
            "id":"90014060002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Delfín Gallo",
               "id":"90014060"
            },
            "centroide":{
               "lat":-26.8440192084768,
               "lon":-65.0924758564779
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Delfin Gallo",
               "id":"908084"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"EX INGENIO LUJAN",
            "id":"90014060003",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Delfín Gallo",
               "id":"90014060"
            },
            "centroide":{
               "lat":-26.8541670602329,
               "lon":-65.0915199952372
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Bracho y el Cevilar",
               "id":"908091"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"EL BRACHO",
            "id":"90014070000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Bracho",
               "id":"90014070"
            },
            "centroide":{
               "lat":-26.9921019189366,
               "lon":-65.1812450251063
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Florida y Luisiana",
               "id":"908105"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"INGENIO LA FLORIDA",
            "id":"90014080001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"La Florida",
               "id":"90014080"
            },
            "centroide":{
               "lat":-26.8167179154155,
               "lon":-65.0802671019889
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"La Florida y Luisiana",
               "id":"908105"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"LA FLORIDA",
            "id":"90014080002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"La Florida",
               "id":"90014080"
            },
            "centroide":{
               "lat":-26.8190592540013,
               "lon":-65.0943210848409
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Las Cejas",
               "id":"908112"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"LAS CEJAS",
            "id":"90014090000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Las Cejas",
               "id":"90014090"
            },
            "centroide":{
               "lat":-26.8878173829847,
               "lon":-64.7430772509051
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Ralos",
               "id":"908140"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"EX INGENIO LOS RALOS",
            "id":"90014100001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Los Ralos",
               "id":"90014100"
            },
            "centroide":{
               "lat":-26.8770998455409,
               "lon":-64.9748987513019
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Ralos",
               "id":"908140"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"VILLA RECASTE",
            "id":"90014100002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Los Ralos",
               "id":"90014100"
            },
            "centroide":{
               "lat":-26.8868084047256,
               "lon":-65.0074356034814
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Ralos",
               "id":"908140"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"VILLA TERCERA",
            "id":"90014100003",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Los Ralos",
               "id":"90014100"
            },
            "centroide":{
               "lat":-26.8914692726412,
               "lon":-65.0131822116958
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Banda del Rio Sali",
               "id":"900028"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"PACARA",
            "id":"90014110000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Pacará",
               "id":"90014110"
            },
            "centroide":{
               "lat":-26.8992133086383,
               "lon":-65.1492861893342
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ranchillos y San Miguel",
               "id":"908147"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"RANCHILLOS",
            "id":"90014120000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Ranchillos",
               "id":"90014120"
            },
            "centroide":{
               "lat":-26.9547865827356,
               "lon":-65.0483776049689
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Andres",
               "id":"908154"
            },
            "departamento":{
               "nombre":"Cruz Alta",
               "id":"90014"
            },
            "nombre":"SAN ANDRES",
            "id":"90014130000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"San Andrés",
               "id":"90014130"
            },
            "centroide":{
               "lat":-26.8884969343068,
               "lon":-65.1963401762121
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alpachiri y el Molino",
               "id":"908161"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"ALPACHIRI",
            "id":"90021010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Alpachiri",
               "id":"90021010"
            },
            "centroide":{
               "lat":-27.3364644479185,
               "lon":-65.7560706396094
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Alto Verde y los Guchea",
               "id":"908168"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"ALTO VERDE",
            "id":"90021020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Alto Verde",
               "id":"90021020"
            },
            "centroide":{
               "lat":-27.3793992485609,
               "lon":-65.6079258666538
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arcadia",
               "id":"908175"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"ARCADIA",
            "id":"90021030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Arcadia",
               "id":"90021030"
            },
            "centroide":{
               "lat":-27.3069484835773,
               "lon":-65.576067487854
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Arcadia",
               "id":"908175"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"SAN ROQUE",
            "id":"90021040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio San Roque",
               "id":"90021040"
            },
            "centroide":{
               "lat":-27.3293616351221,
               "lon":-65.5823860081286
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Concepcion",
               "id":"900035"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"CONCEPCION",
            "id":"90021050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Concepción",
               "id":"90021050"
            },
            "centroide":{
               "lat":-27.3421785890325,
               "lon":-65.598557597657
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Concepcion",
               "id":"900035"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"ILTICO",
            "id":"90021060000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Iltico",
               "id":"90021060"
            },
            "centroide":{
               "lat":-27.3359615187055,
               "lon":-65.6527259102121
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Trinidad",
               "id":"908189"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"LA TRINIDAD",
            "id":"90021070000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"La Trinidad",
               "id":"90021070"
            },
            "centroide":{
               "lat":-27.413472537684,
               "lon":-65.5152314804134
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Medina",
               "id":"908196"
            },
            "departamento":{
               "nombre":"Chicligasta",
               "id":"90021"
            },
            "nombre":"MEDINA",
            "id":"90021080000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Medina",
               "id":"90021080"
            },
            "centroide":{
               "lat":-27.418836093134,
               "lon":-65.5333450199588
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Famailla",
               "id":"900042"
            },
            "departamento":{
               "nombre":"Famaillá",
               "id":"90028"
            },
            "nombre":"BARRIO CASA ROSADA",
            "id":"90028010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio Casa Rosada",
               "id":"90028010"
            },
            "centroide":{
               "lat":-27.0430593855029,
               "lon":-65.4291435588925
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Famailla",
               "id":"900042"
            },
            "departamento":{
               "nombre":"Famaillá",
               "id":"90028"
            },
            "nombre":"CAMPO DE HERRERA",
            "id":"90028020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Campo de Herrera",
               "id":"90028020"
            },
            "centroide":{
               "lat":-27.0256026652764,
               "lon":-65.3464718463273
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Famailla",
               "id":"900042"
            },
            "departamento":{
               "nombre":"Famaillá",
               "id":"90028"
            },
            "nombre":"EX INGENIO NUEVA BAVIERA",
            "id":"90028030001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Famaillá",
               "id":"90028030"
            },
            "centroide":{
               "lat":-27.0697371751242,
               "lon":-65.3999011552298
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Famailla",
               "id":"900042"
            },
            "departamento":{
               "nombre":"Famaillá",
               "id":"90028"
            },
            "nombre":"FAMAILLA",
            "id":"90028030002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Famaillá",
               "id":"90028030"
            },
            "centroide":{
               "lat":-27.0450456348935,
               "lon":-65.3956359866229
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Famailla",
               "id":"900042"
            },
            "departamento":{
               "nombre":"Famaillá",
               "id":"90028"
            },
            "nombre":"INGENIO FRONTERITA",
            "id":"90028040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Ingenio Fronterita",
               "id":"90028040"
            },
            "centroide":{
               "lat":-27.0354707281153,
               "lon":-65.455616412105
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Graneros",
               "id":"900049"
            },
            "departamento":{
               "nombre":"Graneros",
               "id":"90035"
            },
            "nombre":"GRANEROS",
            "id":"90035010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Graneros",
               "id":"90035010"
            },
            "centroide":{
               "lat":-27.6485245594157,
               "lon":-65.4389216777288
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Lamadrid",
               "id":"908203"
            },
            "departamento":{
               "nombre":"Graneros",
               "id":"90035"
            },
            "nombre":"LAMADRID",
            "id":"90035020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Lamadrid",
               "id":"90035020"
            },
            "centroide":{
               "lat":-27.6466143101473,
               "lon":-65.2504927223474
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Taco Ralo",
               "id":"908210"
            },
            "departamento":{
               "nombre":"Graneros",
               "id":"90035"
            },
            "nombre":"TACO RALO",
            "id":"90035030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Taco Ralo",
               "id":"90035030"
            },
            "centroide":{
               "lat":-27.8350683836867,
               "lon":-65.1946017699559
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Juan Bautista Alberdi",
               "id":"900056"
            },
            "departamento":{
               "nombre":"Juan Bautista Alberdi",
               "id":"90042"
            },
            "nombre":"JUAN BAUTISTA ALBERDI",
            "id":"90042010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Juan Bautista Alberdi",
               "id":"90042010"
            },
            "centroide":{
               "lat":-27.5859774039585,
               "lon":-65.620843677012
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Comuna Villa Belgrano",
               "id":"908224"
            },
            "departamento":{
               "nombre":"Juan Bautista Alberdi",
               "id":"90042"
            },
            "nombre":"VILLA BELGRANO",
            "id":"90042020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Belgrano",
               "id":"90042020"
            },
            "centroide":{
               "lat":-27.5271408525417,
               "lon":-65.614612836175
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de la Cocha",
               "id":"900063"
            },
            "departamento":{
               "nombre":"La Cocha",
               "id":"90049"
            },
            "nombre":"LA COCHA",
            "id":"90049010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"La Cocha",
               "id":"90049010"
            },
            "centroide":{
               "lat":-27.7730048354523,
               "lon":-65.5864568354594
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Jose de la Cocha",
               "id":"908259"
            },
            "departamento":{
               "nombre":"La Cocha",
               "id":"90049"
            },
            "nombre":"SAN JOSE DE LA COCHA",
            "id":"90049020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"San José de La Cocha",
               "id":"90049020"
            },
            "centroide":{
               "lat":-27.7315967012344,
               "lon":-65.5827595206013
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Bella Vista",
               "id":"900070"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"BELLA VISTA",
            "id":"90056010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Bella Vista",
               "id":"90056010"
            },
            "centroide":{
               "lat":-27.0310036528782,
               "lon":-65.3086376425668
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Estacion Araoz y Tacanas",
               "id":"908294"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"ESTACION ARAOZ",
            "id":"90056020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Estación Aráoz",
               "id":"90056020"
            },
            "centroide":{
               "lat":-27.0571152473018,
               "lon":-64.9218123993231
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Puestos",
               "id":"908315"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"LOS PUESTOS",
            "id":"90056030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Los Puestos",
               "id":"90056030"
            },
            "centroide":{
               "lat":-27.2808998221653,
               "lon":-65.0190299575107
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Manuel Garcia Fernandez",
               "id":"908322"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"MANUEL GARCIA FERNANDEZ",
            "id":"90056040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Manuel García Fernández",
               "id":"90056040"
            },
            "centroide":{
               "lat":-26.9557033913379,
               "lon":-65.272073063004
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes y los Sueldos",
               "id":"908329"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"PALA PALA",
            "id":"90056050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Pala Pala",
               "id":"90056050"
            },
            "centroide":{
               "lat":-27.0563442946496,
               "lon":-65.2181035623283
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rio Colorado",
               "id":"908336"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"RIO COLORADO",
            "id":"90056060000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Río Colorado",
               "id":"90056060"
            },
            "centroide":{
               "lat":-27.1496800217878,
               "lon":-65.3560513582608
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Rosa de Leales y L. Blanca",
               "id":"908343"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"SANTA ROSA DE LEALES",
            "id":"90056070000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Santa Rosa de Leales",
               "id":"90056070"
            },
            "centroide":{
               "lat":-27.1378928767222,
               "lon":-65.2614173877659
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Quilmes y los Sueldos",
               "id":"908329"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"VILLA FIAD - INGENIO LEALES",
            "id":"90056080000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Fiad - Ingenio Leales",
               "id":"90056080"
            },
            "centroide":{
               "lat":-27.0677472403134,
               "lon":-65.2354487523003
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de Leales",
               "id":"908350"
            },
            "departamento":{
               "nombre":"Leales",
               "id":"90056"
            },
            "nombre":"VILLA DE LEALES",
            "id":"90056090000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa de Leales",
               "id":"90056090"
            },
            "centroide":{
               "lat":-27.1942605268211,
               "lon":-65.3095561336322
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de San Miguel De Tucuman",
               "id":"900014"
            },
            "departamento":{
               "nombre":"Lules",
               "id":"90063"
            },
            "nombre":"BARRIO SAN FELIPE",
            "id":"90063010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio San Felipe",
               "id":"90063010"
            },
            "centroide":{
               "lat":-26.8749835532106,
               "lon":-65.2322262379924
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Manantial",
               "id":"908357"
            },
            "departamento":{
               "nombre":"Lules",
               "id":"90063"
            },
            "nombre":"BARRIO ARAUJO",
            "id":"90063020001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Manantial",
               "id":"90063020"
            },
            "centroide":{
               "lat":-26.8418175731977,
               "lon":-65.2753559359172
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Manantial",
               "id":"908357"
            },
            "departamento":{
               "nombre":"Lules",
               "id":"90063"
            },
            "nombre":"EL MANANTIAL",
            "id":"90063020002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Manantial",
               "id":"90063020"
            },
            "centroide":{
               "lat":-26.8445604430497,
               "lon":-65.2856690422125
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pablo y Villa Nougues",
               "id":"908371"
            },
            "departamento":{
               "nombre":"Lules",
               "id":"90063"
            },
            "nombre":"INGENIO SAN PABLO",
            "id":"90063030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Ingenio San Pablo",
               "id":"90063030"
            },
            "centroide":{
               "lat":-26.8739965593381,
               "lon":-65.3103499893471
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Lules",
               "id":"900077"
            },
            "departamento":{
               "nombre":"Lules",
               "id":"90063"
            },
            "nombre":"LA REDUCCION",
            "id":"90063040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"La Reducción",
               "id":"90063040"
            },
            "centroide":{
               "lat":-26.9601338991114,
               "lon":-65.3514727517897
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Lules",
               "id":"900077"
            },
            "departamento":{
               "nombre":"Lules",
               "id":"90063"
            },
            "nombre":"LULES",
            "id":"90063050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Lules",
               "id":"90063050"
            },
            "centroide":{
               "lat":-26.9239379177434,
               "lon":-65.3364017910576
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Acheral",
               "id":"908378"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"ACHERAL",
            "id":"90070010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Acheral",
               "id":"90070010"
            },
            "centroide":{
               "lat":-27.12072050474,
               "lon":-65.4705647177524
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Capitan Caceres",
               "id":"908392"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"CAPITAN CACERES",
            "id":"90070020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Capitán Cáceres",
               "id":"90070020"
            },
            "centroide":{
               "lat":-27.1895217713073,
               "lon":-65.6039648120293
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Monteros",
               "id":"900084"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"MONTEROS",
            "id":"90070030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Monteros",
               "id":"90070030"
            },
            "centroide":{
               "lat":-27.1674766637374,
               "lon":-65.4987774491472
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Leon Rouges y Santa Rosa",
               "id":"908406"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"PUEBLO INDEPENDENCIA",
            "id":"90070040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Pueblo Independencia",
               "id":"90070040"
            },
            "centroide":{
               "lat":-27.2207848342688,
               "lon":-65.527417311463
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rio Seco",
               "id":"908420"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"RIO SECO",
            "id":"90070050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Río Seco",
               "id":"90070050"
            },
            "centroide":{
               "lat":-27.2690482043801,
               "lon":-65.5593459528459
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Lucia",
               "id":"908427"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"SANTA LUCIA",
            "id":"90070060000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Santa Lucía",
               "id":"90070060"
            },
            "centroide":{
               "lat":-27.0930813575252,
               "lon":-65.5325624964532
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Sargento Moya",
               "id":"908434"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"SARGENTO MOYA",
            "id":"90070070000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Sargento Moya",
               "id":"90070070"
            },
            "centroide":{
               "lat":-27.2278200846423,
               "lon":-65.6598562004025
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Soldado Maldonado",
               "id":"908441"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"SOLDADO MALDONADO",
            "id":"90070080000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Soldado Maldonado",
               "id":"90070080"
            },
            "centroide":{
               "lat":-27.1423360079647,
               "lon":-65.5656822015637
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Teniente Berdina",
               "id":"908448"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"TENIENTE BERDINA",
            "id":"90070090000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Teniente Berdina",
               "id":"90070090"
            },
            "centroide":{
               "lat":-27.050034787094,
               "lon":-65.4875831419552
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa Quinteros",
               "id":"908455"
            },
            "departamento":{
               "nombre":"Monteros",
               "id":"90070"
            },
            "nombre":"VILLA QUINTEROS",
            "id":"90070100000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Quinteros",
               "id":"90070100"
            },
            "centroide":{
               "lat":-27.2536712257247,
               "lon":-65.5524720047212
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Aguilares",
               "id":"900091"
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"90077"
            },
            "nombre":"AGUILARES",
            "id":"90077010001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Aguilares",
               "id":"90077010"
            },
            "centroide":{
               "lat":-27.4298511184032,
               "lon":-65.6204066716154
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Aguilares",
               "id":"900091"
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"90077"
            },
            "nombre":"INGENIO SANTA BARBARA",
            "id":"90077010002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Aguilares",
               "id":"90077010"
            },
            "centroide":{
               "lat":-27.4557975105898,
               "lon":-65.6064955796251
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Sarmiento y la Tipa",
               "id":"908469"
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"90077"
            },
            "nombre":"LOS SARMIENTOS",
            "id":"90077020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Los Sarmientos",
               "id":"90077020"
            },
            "centroide":{
               "lat":-27.413720870217,
               "lon":-65.695439891394
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"908483"
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"90077"
            },
            "nombre":"RIO CHICO",
            "id":"90077030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Río Chico",
               "id":"90077030"
            },
            "centroide":{
               "lat":-27.4809961150586,
               "lon":-65.6214061319444
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"908483"
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"90077"
            },
            "nombre":"SANTA ANA",
            "id":"90077040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Santa Ana",
               "id":"90077040"
            },
            "centroide":{
               "lat":-27.4722321360222,
               "lon":-65.6841675424243
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Ana",
               "id":"908483"
            },
            "departamento":{
               "nombre":"Río Chico",
               "id":"90077"
            },
            "nombre":"VILLA CLODOMIRO HILERET",
            "id":"90077050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Clodomiro Hileret",
               "id":"90077050"
            },
            "centroide":{
               "lat":-27.4739466117854,
               "lon":-65.6592300213907
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de San Miguel De Tucuman",
               "id":"900014"
            },
            "departamento":{
               "nombre":"Capital",
               "id":"90084"
            },
            "nombre":"SAN MIGUEL DE TUCUMAN",
            "id":"90084010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"San Miguel de Tucumán",
               "id":"90084010"
            },
            "centroide":{
               "lat":-26.82900979033,
               "lon":-65.2105441811048
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Atahona",
               "id":"908490"
            },
            "departamento":{
               "nombre":"Simoca",
               "id":"90091"
            },
            "nombre":"ATAHONA",
            "id":"90091010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Atahona",
               "id":"90091010"
            },
            "centroide":{
               "lat":-27.4175004497156,
               "lon":-65.2878399932446
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Monteagudo",
               "id":"908518"
            },
            "departamento":{
               "nombre":"Simoca",
               "id":"90091"
            },
            "nombre":"MONTEAGUDO",
            "id":"90091020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Monteagudo",
               "id":"90091020"
            },
            "centroide":{
               "lat":-27.5109095164641,
               "lon":-65.2776930194393
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Rio Chico y Nueva Trinidad",
               "id":"908532"
            },
            "departamento":{
               "nombre":"Simoca",
               "id":"90091"
            },
            "nombre":"NUEVA TRINIDAD",
            "id":"90091030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Nueva Trinidad",
               "id":"90091030"
            },
            "centroide":{
               "lat":-27.4845113363814,
               "lon":-65.4912509331708
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Santa Cruz y la Tuna",
               "id":"908546"
            },
            "departamento":{
               "nombre":"Simoca",
               "id":"90091"
            },
            "nombre":"SANTA CRUZ",
            "id":"90091040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Santa Cruz",
               "id":"90091040"
            },
            "centroide":{
               "lat":-27.3865618797716,
               "lon":-65.4563894902029
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Simoca",
               "id":"900098"
            },
            "departamento":{
               "nombre":"Simoca",
               "id":"90091"
            },
            "nombre":"SIMOCA",
            "id":"90091050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Simoca",
               "id":"90091050"
            },
            "centroide":{
               "lat":-27.2624226707095,
               "lon":-65.3552894738936
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Villa de Chicligasta",
               "id":"908553"
            },
            "departamento":{
               "nombre":"Simoca",
               "id":"90091"
            },
            "nombre":"VILLA CHICLIGASTA",
            "id":"90091060000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Chicligasta",
               "id":"90091060"
            },
            "centroide":{
               "lat":-27.4352233904382,
               "lon":-65.163544429074
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Amaicha del Valle",
               "id":"908567"
            },
            "departamento":{
               "nombre":"Tafí del Valle",
               "id":"90098"
            },
            "nombre":"AMAICHA DEL VALLE",
            "id":"90098010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Amaicha del Valle",
               "id":"90098010"
            },
            "centroide":{
               "lat":-26.5936376610691,
               "lon":-65.9279885664724
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Colalao del Valle",
               "id":"908574"
            },
            "departamento":{
               "nombre":"Tafí del Valle",
               "id":"90098"
            },
            "nombre":"COLALAO DEL VALLE",
            "id":"90098020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Colalao del Valle",
               "id":"90098020"
            },
            "centroide":{
               "lat":-26.3605355069816,
               "lon":-65.9589195037969
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Mollar",
               "id":"908581"
            },
            "departamento":{
               "nombre":"Tafí del Valle",
               "id":"90098"
            },
            "nombre":"EL MOLLAR",
            "id":"90098030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Mollar",
               "id":"90098030"
            },
            "centroide":{
               "lat":-26.9392209221338,
               "lon":-65.7081542446876
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi del Valle",
               "id":"900105"
            },
            "departamento":{
               "nombre":"Tafí del Valle",
               "id":"90098"
            },
            "nombre":"TAFI DEL VALLE",
            "id":"90098040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Tafí del Valle",
               "id":"90098040"
            },
            "centroide":{
               "lat":-26.8527978993495,
               "lon":-65.7085573791718
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Nogales",
               "id":"908616"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"BARRIO EL CRUCE",
            "id":"90105010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio El Cruce",
               "id":"90105010"
            },
            "centroide":{
               "lat":-26.7081441784822,
               "lon":-65.2200815585159
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi Viejo",
               "id":"900119"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"BARRIO LOMAS DE TAFI",
            "id":"90105020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio Lomas de Tafí",
               "id":"90105020"
            },
            "centroide":{
               "lat":-26.7465814588322,
               "lon":-65.2334174034289
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Los Nogales",
               "id":"908616"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"BARRIO MUTUAL SAN MARTIN",
            "id":"90105030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio Mutual San Martín",
               "id":"90105030"
            },
            "centroide":{
               "lat":-26.7180167498877,
               "lon":-65.2249109374961
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi Viejo",
               "id":"900119"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"BARRIO PARADA 14",
            "id":"90105040000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio Parada 14",
               "id":"90105040"
            },
            "centroide":{
               "lat":-26.7527987012624,
               "lon":-65.248427031041
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi Viejo",
               "id":"900119"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"BARRIO U.T.A. II",
            "id":"90105050000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio U.T.A. II",
               "id":"90105050"
            },
            "centroide":{
               "lat":-26.756263713385,
               "lon":-65.2390248245137
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi Viejo",
               "id":"900119"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"DIAG. NORTE - LUZ Y FUERZA - LOS POCITOS",
            "id":"90105060000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Diagonal Norte - Luz y Fuerza - Los Pocitos - Villa Nueva Italia",
               "id":"90105060"
            },
            "centroide":{
               "lat":-26.7815251300034,
               "lon":-65.2193041804807
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"El Cadillal",
               "id":"908602"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"EL CADILLAL",
            "id":"90105070000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"El Cadillal",
               "id":"90105070"
            },
            "centroide":{
               "lat":-26.6325444298094,
               "lon":-65.2057408343078
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi Viejo",
               "id":"900119"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"TAFI VIEJO",
            "id":"90105080000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Tafí Viejo",
               "id":"90105080"
            },
            "centroide":{
               "lat":-26.7312682798666,
               "lon":-65.2558176904322
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Tafi Viejo",
               "id":"900119"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"VILLA LAS FLORES",
            "id":"90105090000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Las Flores",
               "id":"90105090"
            },
            "centroide":{
               "lat":-26.7695777143465,
               "lon":-65.233609939011
            }
         },
         {
            "categoria":"Componente de localidad compuesta",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de las Talitas",
               "id":"900112"
            },
            "departamento":{
               "nombre":"Tafí Viejo",
               "id":"90105"
            },
            "nombre":"VILLA MARIANO MORENO - EL COLMENAR",
            "id":"90105100000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Mariano Moreno - El Colmenar",
               "id":"90105100"
            },
            "centroide":{
               "lat":-26.7759821127859,
               "lon":-65.2019701714422
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Choromoro",
               "id":"908623"
            },
            "departamento":{
               "nombre":"Trancas",
               "id":"90112"
            },
            "nombre":"CHOROMORO",
            "id":"90112010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Choromoro",
               "id":"90112010"
            },
            "centroide":{
               "lat":-26.4107463918543,
               "lon":-65.3199726179681
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Pedro de Colalao",
               "id":"908630"
            },
            "departamento":{
               "nombre":"Trancas",
               "id":"90112"
            },
            "nombre":"SAN PEDRO DE COLALAO",
            "id":"90112020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"San Pedro de Colalao",
               "id":"90112020"
            },
            "centroide":{
               "lat":-26.2357654346434,
               "lon":-65.4938515563214
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Trancas",
               "id":"900126"
            },
            "departamento":{
               "nombre":"Trancas",
               "id":"90112"
            },
            "nombre":"VILLA DE TRANCAS",
            "id":"90112030000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa de Trancas",
               "id":"90112030"
            },
            "centroide":{
               "lat":-26.2307317035992,
               "lon":-65.285166490246
            }
         },
         {
            "categoria":"Componente de localidad compuesta con entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Yerba Buena",
               "id":"908560"
            },
            "departamento":{
               "nombre":"Yerba Buena",
               "id":"90119"
            },
            "nombre":"BARRIO SAN JOSE III",
            "id":"90119010000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio San José III",
               "id":"90119010"
            },
            "centroide":{
               "lat":-26.7964431224326,
               "lon":-65.2657159116952
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Yerba Buena",
               "id":"908560"
            },
            "departamento":{
               "nombre":"Yerba Buena",
               "id":"90119"
            },
            "nombre":"BARRIO SAN JOSE III",
            "id":"90119010001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio San José III",
               "id":"90119010"
            },
            "centroide":{
               "lat":-26.7954020539638,
               "lon":-65.2653910211364
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cevil Redondo",
               "id":"908644"
            },
            "departamento":{
               "nombre":"Yerba Buena",
               "id":"90119"
            },
            "nombre":"COUNTRY JOCKEY CLUB",
            "id":"90119010002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Barrio San José III",
               "id":"90119010"
            },
            "centroide":{
               "lat":-26.7629680018015,
               "lon":-65.3079476709365
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Cevil Redondo",
               "id":"908644"
            },
            "departamento":{
               "nombre":"Yerba Buena",
               "id":"90119"
            },
            "nombre":"VILLA CARMELA",
            "id":"90119020000",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Villa Carmela",
               "id":"90119020"
            },
            "centroide":{
               "lat":-26.7677751896138,
               "lon":-65.2708444093707
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Yerba Buena",
               "id":"908560"
            },
            "departamento":{
               "nombre":"Yerba Buena",
               "id":"90119"
            },
            "nombre":"EX INGENIO SAN JOSE",
            "id":"90119030001",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Yerba Buena - Marcos Paz",
               "id":"90119030"
            },
            "centroide":{
               "lat":-26.7992050305522,
               "lon":-65.2646764135868
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Municipalidad de Yerba Buena",
               "id":"908560"
            },
            "departamento":{
               "nombre":"Yerba Buena",
               "id":"90119"
            },
            "nombre":"YERBA BUENA - MARCOS PAZ",
            "id":"90119030002",
            "provincia":{
               "nombre":"Tucumán",
               "id":"90"
            },
            "localidad_censal":{
               "nombre":"Yerba Buena - Marcos Paz",
               "id":"90119030"
            },
            "centroide":{
               "lat":-26.8088452576169,
               "lon":-65.3106988268486
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 1",
               "id":"02007"
            },
            "nombre":"CONSTITUCION",
            "id":"02007010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6250478642294,
               "lon":-58.384387226424
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 1",
               "id":"02007"
            },
            "nombre":"MONSERRAT",
            "id":"02007010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6126880821742,
               "lon":-58.379652044818
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 1",
               "id":"02007"
            },
            "nombre":"PUERTO MADERO",
            "id":"02007010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6092161933037,
               "lon":-58.3563809151849
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 1",
               "id":"02007"
            },
            "nombre":"RETIRO",
            "id":"02007010004",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5884243429366,
               "lon":-58.3759359131827
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 1",
               "id":"02007"
            },
            "nombre":"SAN NICOLAS",
            "id":"02007010005",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6036683192305,
               "lon":-58.3805143256131
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 1",
               "id":"02007"
            },
            "nombre":"SAN TELMO",
            "id":"02007010006",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6215200115838,
               "lon":-58.3715453876304
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 2",
               "id":"02014"
            },
            "nombre":"RECOLETA",
            "id":"02014010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5858760078165,
               "lon":-58.3949961802632
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 3",
               "id":"02021"
            },
            "nombre":"BALVANERA",
            "id":"02021010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6090997738407,
               "lon":-58.4030625195747
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 3",
               "id":"02021"
            },
            "nombre":"SAN CRISTOBAL",
            "id":"02021010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.623864473461,
               "lon":-58.4018857979853
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 4",
               "id":"02028"
            },
            "nombre":"BARRACAS",
            "id":"02028010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6464135507847,
               "lon":-58.3842710319828
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 4",
               "id":"02028"
            },
            "nombre":"BOCA",
            "id":"02028010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6310700719721,
               "lon":-58.3568304109892
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 4",
               "id":"02028"
            },
            "nombre":"NUEVA POMPEYA",
            "id":"02028010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6505501963997,
               "lon":-58.4188550090093
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 4",
               "id":"02028"
            },
            "nombre":"PARQUE PATRICIOS",
            "id":"02028010004",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6375496406835,
               "lon":-58.4016756206755
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 5",
               "id":"02035"
            },
            "nombre":"ALMAGRO",
            "id":"02035010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6092276891202,
               "lon":-58.4217452253939
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 5",
               "id":"02035"
            },
            "nombre":"BOEDO",
            "id":"02035010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6299600080664,
               "lon":-58.4188403738383
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 6",
               "id":"02042"
            },
            "nombre":"CABALLITO",
            "id":"02042010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6168254996915,
               "lon":-58.443603110353
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 7",
               "id":"02049"
            },
            "nombre":"FLORES",
            "id":"02049010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6368070795903,
               "lon":-58.4582698515258
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 7",
               "id":"02049"
            },
            "nombre":"PARQUE CHACABUCO",
            "id":"02049010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6359392274734,
               "lon":-58.4376956019926
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 8",
               "id":"02056"
            },
            "nombre":"VILLA LUGANO",
            "id":"02056010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6749935792889,
               "lon":-58.4761629844706
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 8",
               "id":"02056"
            },
            "nombre":"VILLA RIACHUELO",
            "id":"02056010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6918955269685,
               "lon":-58.4632519393435
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 8",
               "id":"02056"
            },
            "nombre":"VILLA SOLDATI",
            "id":"02056010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6654688444976,
               "lon":-58.4465225253229
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 9",
               "id":"02063"
            },
            "nombre":"LINIERS",
            "id":"02063010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6437960835294,
               "lon":-58.5191298958228
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 9",
               "id":"02063"
            },
            "nombre":"MATADEROS",
            "id":"02063010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6583682885816,
               "lon":-58.5017319521437
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 9",
               "id":"02063"
            },
            "nombre":"PARQUE AVELLANEDA",
            "id":"02063010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.64864128675,
               "lon":-58.47645640345
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 10",
               "id":"02070"
            },
            "nombre":"FLORESTA",
            "id":"02070010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6276862905939,
               "lon":-58.4835872783595
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 10",
               "id":"02070"
            },
            "nombre":"MONTE CASTRO",
            "id":"02070010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6192974826904,
               "lon":-58.5065803391285
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 10",
               "id":"02070"
            },
            "nombre":"VELEZ SARSFIELD",
            "id":"02070010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6313610222,
               "lon":-58.493276996941
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 10",
               "id":"02070"
            },
            "nombre":"VERSALLES",
            "id":"02070010004",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6301234618064,
               "lon":-58.5224017483433
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 10",
               "id":"02070"
            },
            "nombre":"VILLA LURO",
            "id":"02070010005",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6364127783977,
               "lon":-58.5027288050931
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 10",
               "id":"02070"
            },
            "nombre":"VILLA REAL",
            "id":"02070010006",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6194831337073,
               "lon":-58.5259860729102
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 11",
               "id":"02077"
            },
            "nombre":"VILLA DEL PARQUE",
            "id":"02077010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6042463004493,
               "lon":-58.4906764324396
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 11",
               "id":"02077"
            },
            "nombre":"VILLA DEVOTO",
            "id":"02077010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6023803136553,
               "lon":-58.5142437101484
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 11",
               "id":"02077"
            },
            "nombre":"VILLA GENERAL MITRE",
            "id":"02077010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6100289507588,
               "lon":-58.4689425541216
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 11",
               "id":"02077"
            },
            "nombre":"VILLA SANTA RITA",
            "id":"02077010004",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.6161936201559,
               "lon":-58.4829573518477
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 12",
               "id":"02084"
            },
            "nombre":"COGHLAN",
            "id":"02084010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5606251344745,
               "lon":-58.4749447458843
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 12",
               "id":"02084"
            },
            "nombre":"SAAVEDRA",
            "id":"02084010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.553063289604,
               "lon":-58.4887266668384
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 12",
               "id":"02084"
            },
            "nombre":"VILLA PUEYRREDON",
            "id":"02084010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5821104495703,
               "lon":-58.5034848793052
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 12",
               "id":"02084"
            },
            "nombre":"VILLA URQUIZA",
            "id":"02084010004",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5715411303449,
               "lon":-58.4878560596649
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 13",
               "id":"02091"
            },
            "nombre":"BELGRANO",
            "id":"02091010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5548815240237,
               "lon":-58.4502890947349
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 13",
               "id":"02091"
            },
            "nombre":"COLEGIALES",
            "id":"02091010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5746427442082,
               "lon":-58.4509682045183
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 13",
               "id":"02091"
            },
            "nombre":"NUÑEZ",
            "id":"02091010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5437376606688,
               "lon":-58.4628575470422
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 14",
               "id":"02098"
            },
            "nombre":"PALERMO",
            "id":"02098010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5738999836423,
               "lon":-58.4224364523189
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 15",
               "id":"02105"
            },
            "nombre":"AGRONOMIA",
            "id":"02105010001",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5929442472726,
               "lon":-58.4886714155361
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 15",
               "id":"02105"
            },
            "nombre":"CHACARITA",
            "id":"02105010002",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5883730730472,
               "lon":-58.4541748568778
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 15",
               "id":"02105"
            },
            "nombre":"PARQUE CHAS",
            "id":"02105010003",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5855218978064,
               "lon":-58.4791226108546
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 15",
               "id":"02105"
            },
            "nombre":"PATERNAL",
            "id":"02105010004",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5974220425569,
               "lon":-58.4686652940674
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 15",
               "id":"02105"
            },
            "nombre":"VILLA CRESPO",
            "id":"02105010005",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5988344062647,
               "lon":-58.4427221412974
            }
         },
         {
            "categoria":"Entidad",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Comuna 15",
               "id":"02105"
            },
            "nombre":"VILLA ORTUZAR",
            "id":"02105010006",
            "provincia":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02"
            },
            "localidad_censal":{
               "nombre":"Ciudad Autónoma de Buenos Aires",
               "id":"02000010"
            },
            "centroide":{
               "lat":-34.5809741138535,
               "lon":-58.4676516664339
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"San Antonio",
               "id":"386084"
            },
            "departamento":{
               "nombre":"San Antonio",
               "id":"38056"
            },
            "nombre":"LA TOMA",
            "id":"38056025001",
            "provincia":{
               "nombre":"Jujuy",
               "id":"38"
            },
            "localidad_censal":{
               "nombre":"Nuestra Señora del Rosario",
               "id":"38056025"
            },
            "centroide":{
               "lat":-24.3374800000056,
               "lon":-65.36524
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"General Rodríguez",
               "id":"060364"
            },
            "departamento":{
               "nombre":"General Rodríguez",
               "id":"06364"
            },
            "nombre":"BARRIO RUTA 24 KILOMETRO 10",
            "id":"06364030005",
            "provincia":{
               "nombre":"Buenos Aires",
               "id":"06"
            },
            "localidad_censal":{
               "nombre":"General Rodríguez",
               "id":"06364030"
            },
            "centroide":{
               "lat":-34.6882159472973,
               "lon":-58.9574297137089
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Río Grande",
               "id":"940007"
            },
            "departamento":{
               "nombre":"Río Grande",
               "id":"94008"
            },
            "nombre":"RIO GRANDE",
            "id":"94008010000",
            "provincia":{
               "nombre":"Tierra del Fuego, Antártida e Islas del Atlántico Sur",
               "id":"94"
            },
            "localidad_censal":{
               "nombre":"Río Grande",
               "id":"94008010"
            },
            "centroide":{
               "lat":-53.7870473449159,
               "lon":-67.7132350718047
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Tolhuin",
               "id":"942007"
            },
            "departamento":{
               "nombre":"Río Grande",
               "id":"94008"
            },
            "nombre":"TOLHUIN",
            "id":"94008020000",
            "provincia":{
               "nombre":"Tierra del Fuego, Antártida e Islas del Atlántico Sur",
               "id":"94"
            },
            "localidad_censal":{
               "nombre":"Tolhuin",
               "id":"94008020"
            },
            "centroide":{
               "lat":-54.5113867546733,
               "lon":-67.195804463563
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":null,
               "id":null
            },
            "departamento":{
               "nombre":"Ushuaia",
               "id":"94015"
            },
            "nombre":"LAGUNA ESCONDIDA",
            "id":"94015010000",
            "provincia":{
               "nombre":"Tierra del Fuego, Antártida e Islas del Atlántico Sur",
               "id":"94"
            },
            "localidad_censal":{
               "nombre":"Laguna Escondida",
               "id":"94015010"
            },
            "centroide":{
               "lat":-54.6376860828691,
               "lon":-67.766940855265
            }
         },
         {
            "categoria":"Localidad simple",
            "fuente":"INDEC",
            "municipio":{
               "nombre":"Ushuaia",
               "id":"940014"
            },
            "departamento":{
               "nombre":"Ushuaia",
               "id":"94015"
            },
            "nombre":"USHUAIA",
            "id":"94015020000",
            "provincia":{
               "nombre":"Tierra del Fuego, Antártida e Islas del Atlántico Sur",
               "id":"94"
            },
            "localidad_censal":{
               "nombre":"Ushuaia",
               "id":"94015020"
            },
            "centroide":{
               "lat":-54.8036404601709,
               "lon":-68.3160624772532
            }
         }
      ],
      "total":4142,
      "cantidad":4142,
      "parametros":{
         
      },
      "inicio":0
   }
}
}
