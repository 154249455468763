import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LocalidadesService } from 'src/app/localidades.service';
import { ProvinciasService } from 'src/app/provincias.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  constructor(
    private firestore: AngularFirestore,
    private provinciasService : ProvinciasService,
    private localidadesService : LocalidadesService
    ){
    this.getProvincias()
    this.getLocalidades(); 
  }
  ngOnInit(): void {
  }
  ref = this.firestore.collection(environment.firestorePath)
  url;
  msg = "";
  selectFile(event) {
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Only images are supported";
			return;
		}
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
		reader.onload = (_event) => {
			this.msg = "";
			this.url = reader.result; 
		}
  }
  url1;
  msg1 = "";
  selectFile1(event) {
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg1 = 'You must select an image';
			return;
		}
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			this.msg1 = "Only images are supported";
			return;
		}
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
		reader.onload = (_event) => {
			this.msg1 = "";
			this.url1 = reader.result; 
		}
	}
  terms = new FormControl('');

  async getProvincias(){
    let prov = this.provinciasService.getProvincias().provincias.map(x => {return{value : x.id,label:x.nombre}}).sort((a,b) => a.label>b.label?1:-1)
    this.fields[8].templateOptions.options = prov
    this.fields[12].templateOptions.options = prov
  //  this.firestore.collection<any>("provincias").valueChanges().subscribe( values =>{  
  //   this.fields[8].templateOptions.options =  values.map(x => {return{value : x.id,label:x.nombre}}).sort((a,b) => a.label>b.label?1:-1)
  //   this.fields[12].templateOptions.options =  values.map(x => {return{value : x.id,label:x.nombre}}).sort((a,b) => a.label>b.label?1:-1)
  //   })

  }
  localidades;
  async getLocalidades(){
    this.localidades = this.localidadesService.getLocalidades().localidades;
    // this.firestore.collection<any>("localidades").valueChanges().subscribe( values =>{  
    //   this.localidades = values
    //   })
  }
  filterlocalidades(){
    this.fields[9].templateOptions.options = this.localidades.filter( x => x.provincia.id == this.fields[8].formControl.value).map(x => {return{value : x.id,label:x.nombre}}).sort((a,b) => a.label>b.label?1:-1)
  }
  filterlocalidadesDNI(){
    this.fields[13].templateOptions.options = this.localidades.filter( x => x.provincia.id == this.fields[12].formControl.value).map(x => {return{value : x.id,label:x.nombre}}).sort((a,b) => a.label>b.label?1:-1)
  }



  onSubmit(model) {
    if(this.terms.valid && this.url && this.url1){
      let image1 = this.url
      let image2 = this.url1
      this.ref.add({ ...model,imagedelantera : image1,imageposterior : image2}).then(
        data => {
          console.log(data)
          alert("Guardado")
        }
      )
    }
    
  }

  // startUpload() {
  //   // The storage path
  //   const name = `${Date.now()}_${this.file.name}`;

  //   // Reference to storage bucket
  //   const ref = this.storage.ref(name);

  //   // The main task
  //   this.task = this.storage.upload(name, this.file);

  //   // Progress monitoring
  //   this.percentage = this.task.percentageChanges();

  //   this.snapshot = this.task.snapshotChanges().pipe(
  //     tap(console.log),
  //     // The file's download URL
  //     finalize(async () => {
  //       console.log("Fetching url")
  //       this.url = await ref.getDownloadURL().toPromise();
  //       console.log("Done");
  //       console.log(this.url);
  //       this.onFinishUploading.emit(this.url);
  //     }),
  //   );
  // }

  
  form = new FormGroup({});
  model = {};
  fields: FormlyFieldConfig[] = [
    {
      template: '<div><h3><strong>Datos Personales:</strong></h3></div><hr />',
    },
    {
      key: 'nombre',
      type: 'input',
      templateOptions: {
        label: 'Nombre',
        placeholder: 'Nombre',
        required: true,
      }
    },
    {
      key: 'apellido',
      type: 'input',
      templateOptions: {
        label: 'Apellido',
        placeholder: 'Apellido',
        required: true,
      }
    },
    {
      key: 'dni',
      type: 'input',
      templateOptions: {
        label: 'DNI',
        placeholder: 'Nro. de DNI',
        required: true,
      }
    },
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email',
        placeholder: 'Ingrese su email',
        required: true,
      }
    },
    {
      key: 'telefono',
      type: 'input',
      templateOptions: {
        label: 'Telefono',
        placeholder: 'Nro. de Telefono',
        required: true,
      }
    },
    {
      key: 'fechadenacimiento',
      type: 'datepicker',
      templateOptions: {
        label: 'Fecha de Nacimiento',
        placeholder: 'Fecha de Nacimiento',
        description: 'Fecha de Nacimiento',
        required: true,
      }
    },
    {
      key: 'estadocivil',
      type: 'select',
      templateOptions: {
        label: 'Estado Civil',
        placeholder: 'Estado Civil',
        description: 'Estado Civil',
        required: true,
        options: [
          { value: 1, label: 'Soltero/a'  },
          { value: 2, label: 'Casado/a'  },
          { value: 3, label: 'Union Convivencial'  },
          { value: 4, label: 'Divorciado/a'  },
          { value: 5, label: 'Viudo/a'  },
        ],
      },
    },
    {
      key: 'provincia',
      type: 'select',
      templateOptions: {
        label: 'Provincia de Nacimiento',
        placeholder: 'Provincia',
        description: 'Provincia',
        required: true,
        change: (field, $event) => this.filterlocalidades(),
        options: [
        ],
      },
    },
    {
      key: 'localidad',
      type: 'select',
      templateOptions: {
        label: 'Localidad de Nacimiento',
        placeholder: 'Localidad',
        description: 'Localidad',
        required: true,
        options: [
        ],
      },
    },
    {
      key: 'oficio',
      type: 'input',
      templateOptions: {
        label: 'Oficio',
        placeholder: 'Oficio',
        required: true,
      }
    },
    {
      template: '<div><h3><strong>Domicilio segun DNI:</strong></h3></div><hr />',
    },
    {
      key: 'provinciaDNI',
      type: 'select',
      templateOptions: {
        label: 'Provincia',
        placeholder: 'Provincia',
        description: 'Provincia',
        required: true,
        change: (field, $event) => this.filterlocalidadesDNI(),
        options: [
        ],
      },
    },
    {
      key: 'localidadDNI',
      type: 'select',
      templateOptions: {
        label: 'Localidad',
        placeholder: 'Localidad',
        description: 'Localidad',
        required: true,
        options: [
        ],
      },
    },
    {
      key: 'domicilioDNI',
      type: 'input',
      templateOptions: {
        label: 'Domicilio',
        placeholder: 'Calle 1 Depto 2',
        description: 'Domicilio',
        required: true,
      },
    },
  ];


}
