import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module, RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ExportComponent } from './exporter/export/export.component';
import {MatTableModule} from '@angular/material/table';
import { ExportToCsv } from 'export-to-csv';
import { MainComponent } from './exporter/main/main.component';

@NgModule({
  declarations: [
    AppComponent,
    ExportComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true },validationMessages: [
      { name: 'required', message: 'Campo requerido' },
    ], }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatNativeDateModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'ptp'),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LcSrtIZAAAAANTvn8ZwYJnT-Hbzk_dge8rxnTni',
    } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent]
  // 6LcSrtIZAAAAAIlwiqqzTjs9FaMO1wtI92UQnaHh 
})
export class AppModule { }
